import React from "react";
import { useSelector } from "react-redux";
import ToolTip from "../../../components/common/CustomTooltip";

const FINISHED_EVENT_MESSAGE = "You cannot perform action on finished event";
const DEAFULT_ACCESS_REASON = "You do not have permission for this action";

const RFPActionController = ({
  children,
  toolTipText,
  disAllow = false, // For custom permissions
  disAllowReason = DEAFULT_ACCESS_REASON,
}) => {
  const { isFinished } = useSelector(
    (state) => state.productLaunch.fetchedRFPDetails
  );

  const appendDisability = (child) => {
    return React.cloneElement(child, {
      className: `${child.props.className} disabled`,
      disabled: true,
    });
  };

  return (
    <React.Fragment>
      {isFinished || disAllow ? (
        <ToolTip text={isFinished ? FINISHED_EVENT_MESSAGE : disAllowReason}>
          <span className="d-inline-block" style={{ cursor: "not-allowed" }}>
            {React.Children.map(children, appendDisability)}
          </span>
        </ToolTip>
      ) : (
        <React.Fragment>
          {toolTipText ? (
            <ToolTip text={toolTipText}>{children}</ToolTip>
          ) : (
            children
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default RFPActionController;
