import React, { useEffect, useState, useRef } from "react";
import FileBrowser from "react-keyed-file-browser";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { useAlert } from "react-alert";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import Loader from "../common/MiniLoader";
import ButtonWithToolTip from "../common/ButtonWithToolTip";
import CustomIcons from "./components/CustomIcons";
import UploadFileForm from "./components/UploadFileForm";
import FileDetailRenderer from "./components/FileDetailRenderer";
import FileDeleteRenderer from "./components/FileDeleteRenderer";
import {
  extractErrorMessage,
  filterSelectedDocuments,
  isFolderKey,
} from "./utils/helpers";
import { FILE, FOLDER } from "./utils/constants";
import "./styles/main.scss";

const fileUploadInitStatus = {
  isUploading: false,
  uploadedFilesCount: 0,
  totalFilesCount: 0,
};

function CustomFileBrowser({
  documents = [],
  onUploadDoc = () => {},
  onAddNewFiles = () => {},
  onCreateFolders = () => {},
  onRenameFolder = () => {},
  onRenameFile = () => {},
  onDeleteFolder = () => {},
  onDeleteFile = () => {},
  uploadButtonClassName = () => {},
  copyTextLabel = "Copy",
  onCopy = () => {},
  moveTextLabel = "Move",
  onMove = () => {},
  editDocumentOptions = {},
  showActionBar = true,
}) {
  const alert = useAlert();
  const fileBrowserRef = useRef(null);
  const { state = {} } = useLocation();
  const routedBrowserState = state.browser || {};
  const [fileUploadStatus, setFileUploadStatus] =
    useState(fileUploadInitStatus);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedFolderKeys, setSelectedFolderKeys] = useState([]);
  const [selectedFileKeys, setSelectedFileKeys] = useState([]);
  const [previewedDocument, setPreviewedDocument] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getBrowserState = () => {
    if (fileBrowserRef.current && fileBrowserRef.current.ref) {
      return fileBrowserRef.current.ref.current.state;
    }
    // Return default state
    return {
      openFolders: {},
      selection: [],
      activeAction: null,
      actionTargets: [],
    };
  };

  const setBrowserState = (newState = {}) => {
    if (fileBrowserRef.current && fileBrowserRef.current.ref) {
      fileBrowserRef.current.ref.current.setState((prevState) => ({
        ...prevState,
        ...newState,
      }));
    }
  };

  const resetSelection = () => {
    setSelectedFolderKeys([]);
    setSelectedFileKeys([]);
    setPreviewedDocument({});
  };

  useEffect(() => {
    // Setting default opened folders
    setBrowserState({
      ...routedBrowserState,
    });
  }, [routedBrowserState]);

  const toggleDetailsModal = () => setIsDetailsModalOpen(!isDetailsModalOpen);
  const toggleUploadModal = () => setIsUploadModalOpen(!isUploadModalOpen);

  const handleFileUpload = async (selectedFiles) => {
    const targetFiles = [...selectedFiles];
    const selectedFolderKey = selectedFolderKeys[0] || "";
    setFileUploadStatus((prevState) => ({
      ...prevState,
      isUploading: true,
      totalFilesCount: targetFiles.length,
    }));
    try {
      const uploadedFiles = await Promise.all(
        targetFiles.map((item) => onUploadDoc(item))
      );
      await onAddNewFiles(
        uploadedFiles.map((uploadedFile, index) => ({
          key: `${selectedFolderKey}${targetFiles[index].name}`,
          modified: new Date().getTime(),
          size: targetFiles[index].size,
          url: uploadedFile.file,
          doc_type: FILE,
        }))
      );
      setFileUploadStatus(fileUploadInitStatus);
      alert.success("File(s) added successfully");
    } catch (error) {
      alert.error(
        extractErrorMessage(error, "Something went wrong in adding file(s)")
      );
      setFileUploadStatus(fileUploadInitStatus);
    }
    toggleUploadModal();
  };

  const handleCreateFolder = async (tempKey) => {
    try {
      setIsLoading(true);
      // Just passing data in Array to handle creation of (files/folders) same
      // As we are handling multiple files (in Array) everytime
      await onCreateFolders([
        {
          key: tempKey,
          modified: new Date().getTime(),
          size: 0,
          url: "",
          doc_type: FOLDER,
        },
      ]);
      setIsLoading(false);
      alert.success("Folder added successfully");
    } catch (error) {
      setIsLoading(false);
      alert.error(
        extractErrorMessage(error, "Something went wrong in adding folder")
      );
    }
  };

  const handleRenameFolder = async (oldKey, newKey) => {
    try {
      setIsLoading(true);
      await onRenameFolder({
        prev_key: oldKey,
        new_key: newKey,
        modified_time: +moment(),
      });
      setIsLoading(false);
      alert.success("Folder updated successfully");
    } catch (error) {
      setIsLoading(false);
      alert.error(
        extractErrorMessage(error, "Something went wrong in updating folder")
      );
    }
  };

  const handleRenameFile = async (oldKey, newKey) => {
    try {
      setIsLoading(true);
      await onRenameFile({
        prev_key: oldKey,
        new_key: newKey,
        modified_time: +moment(),
      });
      setIsLoading(false);
      alert.success("File updated successfully");
    } catch (error) {
      setIsLoading(false);
      alert.error(
        extractErrorMessage(error, "Something went wrong in updating file")
      );
    }
  };

  const handleDeleteFolder = async (folderKeys) => {
    try {
      setIsLoading(true);
      await onDeleteFolder({ keys: folderKeys, is_folder: true });
      setIsLoading(false);
      alert.success("Folder(s) deleted successfully");
    } catch (error) {
      setIsLoading(false);
      alert.error(
        extractErrorMessage(error, "Something went wrong in deleting folder(s)")
      );
    }
  };

  const handleDeleteFile = async (fileKeys) => {
    try {
      setIsLoading(true);
      await onDeleteFile({ keys: fileKeys });
      setIsLoading(false);
      alert.success("File(s) deleted successfully");
    } catch (error) {
      setIsLoading(false);
      alert.error(
        extractErrorMessage(error, "Something went wrong in deleting file(s)")
      );
    }
  };

  const handlePreviewOpen = (data) => {
    setPreviewedDocument(documents.find((item) => item.key === data.key) || {});
    toggleDetailsModal();
  };

  const handleDocumentSelection = () => {
    const browserState = getBrowserState();

    const folderKeys = [];
    const fileKeys = [];

    // Separating file and folder keys
    browserState.selection.forEach((key) => {
      if (isFolderKey(key)) folderKeys.push(key);
      else fileKeys.push(key);
    });

    setSelectedFolderKeys(folderKeys);
    setSelectedFileKeys(fileKeys);
  };

  const handleCopyButtonClick = async () => {
    try {
      setIsLoading(true);
      const targetDocuments = filterSelectedDocuments(
        documents,
        selectedFolderKeys,
        selectedFileKeys
      );
      await onCopy(targetDocuments);
      resetSelection();
      setIsLoading(false);
      alert.success("Document(s) copied successfully");
    } catch (error) {
      setIsLoading(false);
      alert.error(
        extractErrorMessage(
          error,
          "Something went wrong in copying documents(s)"
        )
      );
    }
  };

  const handleMoveButtonClick = async () => {
    try {
      setIsLoading(true);
      const targetDocuments = filterSelectedDocuments(
        documents,
        selectedFolderKeys,
        selectedFileKeys
      );
      await onMove(targetDocuments);
      resetSelection();
      setIsLoading(false);
      alert.success("Document(s) moved successfully");
    } catch (error) {
      setIsLoading(false);
      alert.error(
        extractErrorMessage(
          error,
          "Something went wrong in moving documents(s)"
        )
      );
    }
  };

  // Embedding browser state and selected document data
  const modifiedEditDocumentOptions = { ...editDocumentOptions };
  modifiedEditDocumentOptions.saveParams = {
    ...(editDocumentOptions.saveParams || {}),
    ...previewedDocument,
  };
  modifiedEditDocumentOptions.returnData.state.browser = {
    ...(fileBrowserRef.current && fileBrowserRef.current.ref
      ? fileBrowserRef.current.ref.current.state
      : {}),
  };

  const selectedDocumentKeys = [...selectedFolderKeys, ...selectedFileKeys];

  return (
    <div className="custom-file-browser">
      {/* Loader */}
      <div
        className="justify-content-center"
        style={{ display: isLoading ? "flex" : "none" }}
      >
        <Loader />
      </div>
      <Modal show={isUploadModalOpen} onHide={toggleUploadModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UploadFileForm
            toggleModal={toggleUploadModal}
            onSubmit={handleFileUpload}
          />
        </Modal.Body>
      </Modal>
      {/* Conditional rendered using style to keep ref safe whenever an action is performed */}
      <div style={{ display: isLoading ? "none" : "block" }}>
        {/* Custom Toolbar */}
        {showActionBar && (
          <div className="text-right mb-7">
            <span>
              {selectedDocumentKeys.length > 0 && (
                <ButtonWithToolTip
                  className="primary-button mr-3"
                  tooltipText={copyTextLabel}
                  onClick={handleCopyButtonClick}
                >
                  <FileCopyIcon fontSize="small" />
                </ButtonWithToolTip>
              )}
              {selectedDocumentKeys.length > 0 && (
                <ButtonWithToolTip
                  className="primary-button mr-3"
                  tooltipText={moveTextLabel}
                  onClick={handleMoveButtonClick}
                >
                  <AssignmentReturnIcon fontSize="small" />
                </ButtonWithToolTip>
              )}
              {/* Display upload button when single or no folder is selected */}
              {selectedFolderKeys.length < 2 && (
                <ButtonWithToolTip
                  className={`primary-button ${uploadButtonClassName}`}
                  tooltipText="Upload File(s)"
                  onClick={
                    fileUploadStatus.isUploading ? null : toggleUploadModal
                  }
                >
                  {fileUploadStatus.isUploading ? (
                    <React.Fragment>
                      <span className="mr-2">
                        <Loader size={20} />
                      </span>
                      Uploading Files ({fileUploadStatus.uploadedFilesCount}/
                      {fileUploadStatus.totalFilesCount})
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <CloudUploadIcon fontSize="small" />
                    </React.Fragment>
                  )}
                </ButtonWithToolTip>
              )}
            </span>
          </div>
        )}
        <FileBrowser
          ref={fileBrowserRef}
          files={documents}
          icons={CustomIcons}
          onSelect={handleDocumentSelection}
          onCreateFolder={handleCreateFolder}
          onMoveFolder={handleRenameFolder}
          onMoveFile={handleRenameFile}
          onRenameFolder={handleRenameFolder}
          onRenameFile={handleRenameFile}
          onDeleteFolder={handleDeleteFolder}
          onDeleteFile={handleDeleteFile}
          onPreviewOpen={handlePreviewOpen}
          detailRenderer={FileDetailRenderer}
          detailRendererProps={{
            isDetailsModalOpen,
            toggleDetailsModal,
            editDocumentOptions: modifiedEditDocumentOptions,
          }}
          confirmDeletionRenderer={(props) => (
            <FileDeleteRenderer {...props} resetSelection={resetSelection} />
          )}
          confirmMultipleDeletionRenderer={(props) => (
            <FileDeleteRenderer {...props} resetSelection={resetSelection} />
          )}
          showActionBar={showActionBar}
          showFoldersOnFilter
        />
      </div>
    </div>
  );
}

export default CustomFileBrowser;
