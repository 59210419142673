import React from "react";
import { Modal } from "react-bootstrap";

const CustomModal = ({
  isOpen,
  toggle,
  heading,
  bodyClassName = "px-5 py-10",
  size = "md",
  children,
}) => {
  return (
    <Modal show={isOpen} onHide={toggle} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={bodyClassName}>{children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
