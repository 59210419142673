import React, { useMemo } from "react";
import moment from "moment";
import QuotationActionCell from "../components/QuotationActionCell";

function useQuotations() {
  const tableHeadings = useMemo(
    () => [
      {
        id: "id",
        label: "Quotation",
        Cell: ({ row }) => <QuotationActionCell quotation={row.original} />,
      },
      {
        id: "updated_date",
        label: "Date",
        Cell: ({ value }) => moment(value).format("DD MMM YYYY"),
      },
      {
        id: "updated_time",
        label: "Time",
        Cell: ({ value }) => moment(value, "HH:mm:ss").format("hh:mm A"),
      },
      {
        id: "operation_cancel_reason",
        label: "Reason",
        maxWidth: 250,
      },
    ],
    []
  );

  return { tableHeadings };
}

export default useQuotations;
