import React, { useEffect, useState, useRef } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const ToolbarOptions = {
  options: [
    "inline",
    "blockType",
    "list",
    "textAlign",
    "link",
    "emoji",
    "image",
    "history",
    "remove",
    "fontFamily",
    "colorPicker",
  ],
  inline: {
    options: ["bold", "italic", "underline"],
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
  },
  fontFamily: {
    options: [
      "Arial",
      "Georgia",
      "Impact",
      "Tahoma",
      "Times New Roman",
      "Verdana",
    ],
  },
  list: {
    options: ["unordered", "ordered", "indent", "outdent"],
  },
  textAlign: {
    options: ["left", "center", "right", "justify"],
  },
  colorPicker: {
    colors: [
      "rgb(97,189,109)",
      "rgb(26,188,156)",
      "rgb(84,172,210)",
      "rgb(44,130,201)",
      "rgb(147,101,184)",
      "rgb(71,85,119)",
      "rgb(204,204,204)",
      "rgb(65,168,95)",
      "rgb(0,168,133)",
      "rgb(61,142,185)",
      "rgb(41,105,176)",
      "rgb(85,57,130)",
      "rgb(40,50,78)",
      "rgb(0,0,0)",
      "rgb(247,218,100)",
      "rgb(251,160,38)",
      "rgb(235,107,86)",
      "rgb(226,80,65)",
      "rgb(163,143,132)",
      "rgb(239,239,239)",
      "rgb(255,255,255)",
      "rgb(250,197,28)",
      "rgb(243,121,52)",
      "rgb(209,72,65)",
      "rgb(184,49,47)",
      "rgb(124,112,107)",
      "rgb(209,213,216)",
    ],
  },
  link: {
    options: ["link", "unlink"],
  },
  history: {
    options: ["undo", "redo"],
  },
};

const SimpleEditor = ({
  value,
  wrapperClassName,
  toolbarClassName,
  editorClassname,
  onChange,
  onBlur,
  onEnter = () => null,
}) => {
  const editorRef = useRef();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // Handle on Enter event
  if (editorRef.current) {
    const existingHandler = editorRef.current.editor._onKeyDown;
    editorRef.current.editor._onKeyDown = (event) =>
      !event.shiftKey && event.key === "Enter"
        ? onEnter(event)
        : existingHandler(event);
  }

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    setEditorState(EditorState.createWithContent(contentState));
  }, [value]);

  const handleEditorStateChange = (modifiedEditorState) => {
    setEditorState(modifiedEditorState);
    if (onChange) {
      onChange(
        draftToHtml(convertToRaw(modifiedEditorState.getCurrentContent()))
      );
    }
  };

  const handleEditorBlur = () => {
    if (onBlur) {
      onBlur(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }
  };

  return (
    <div className="text-editor">
      <Editor
        ref={editorRef}
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        onBlur={handleEditorBlur}
        toolbar={ToolbarOptions}
        wrapperClassName={wrapperClassName}
        toolbarClassName={toolbarClassName}
        editorClassName={editorClassname}
      />
    </div>
  );
};

export default SimpleEditor;
