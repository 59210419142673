import React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import ConfirmationPopup from "../popups/ConfirmationPopup";

const DEFAULT_OPTIONS = {
  heading: "Confirmation!!!",
  message: "Are you sure?",
};

const useConfirmAlert = () => {
  const confirm = (options = DEFAULT_OPTIONS) => {
    const { heading, message } = {
      heading: options.heading || DEFAULT_OPTIONS.heading,
      message: options.message || DEFAULT_OPTIONS.message,
    };

    return new Promise((resolve, reject) => {
      let element = document.createElement("div");

      const unMountPopup = () => {
        unmountComponentAtNode(element);
        element = null;
      };

      const handleResolve = (result) => {
        unMountPopup();
        resolve(result);
      };

      const handleReject = (result) => {
        unMountPopup();
        reject(result);
      };

      render(
        <ConfirmationPopup
          isOpen
          heading={heading}
          message={message}
          onConfirm={handleResolve}
          toggle={handleReject}
          onCancel={handleReject}
          toggleOnConfirm={false}
          toggleOnCancel={false}
        />,
        element
      );
    });
  };

  return confirm;
};

export default useConfirmAlert;
