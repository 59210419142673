/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import { useField } from "formik";
import Select from "react-select";
import propTypes from "prop-types";

// STYLES & UTILITIES
import "../../../styles/components/formik-field.scss";
import {
  DEFAULT_STYLES,
  DEFAULT_COMPONENTS,
} from "../../../styles/components/select-styles";

const FormikModernSelect = ({
  name,
  options,
  className,
  disabled,
  placeholder,
  menuPosition,
  menuPlacement,
  menuShouldBlockScroll,
  formatOptionLabel,
  onChange,
  onBlur,
  label,
  isRequired,
  isGrid,
}) => {
  const [field, meta, helpers] = useField(name || "");
  const { setValue, setTouched } = helpers;

  const { onBlur: onFieldBlur, value } = field;
  const { touched, error } = meta;

  const handleChange = useCallback(
    (selectedOption) => {
      if (selectedOption) {
        setValue(selectedOption.value);

        if (onChange) onChange(selectedOption.value, name);
      }
    },
    [onChange]
  );

  const handleBlur = useCallback(
    (event) => {
      onFieldBlur(event);
      setTouched(true);

      if (onBlur) onBlur(event);
    },
    [onBlur]
  );

  const selectedOption = options.find((option) => option.value === value);
  const isErrorField = touched && error;

  return (
    <div
      className={`field-wrapper ${
        isGrid ? "col-grid" : "col-full"
      } ${className}`}
    >
      {label && (
        <div className="field-label">
          <p className={`${isRequired ? "required" : ""}`}>{label}</p>
        </div>
      )}

      <div className="input-wrapper">
        <Select
          components={DEFAULT_COMPONENTS}
          options={options}
          onChange={handleChange}
          onBlur={handleBlur}
          value={selectedOption || ""}
          classNamePrefix="react-select"
          isDisabled={disabled}
          disabled={disabled}
          placeholder={placeholder}
          menuPosition={menuPosition}
          menuPlacement={menuPlacement}
          menuShouldBlockScroll={menuShouldBlockScroll}
          formatOptionLabel={formatOptionLabel}
          styles={{
            ...DEFAULT_STYLES,
            control: (base) => ({
              ...base,
              opacity: 1,
              borderColor: isErrorField ? "#df0505" : "#ced4da",
              "&:hover": { borderColor: isErrorField ? "#df0505" : "#ced4da" },
            }),
          }}
        />
      </div>

      {touched && error && (
        <div className="error-wrapper">
          <p className="error">{error}</p>
        </div>
      )}
    </div>
  );
};

FormikModernSelect.propTypes = {
  name: propTypes.string.isRequired,
  options: propTypes.array,
  className: propTypes.string,
  disabled: propTypes.bool,
  placeholder: propTypes.string,
  menuPosition: propTypes.string,
  menuPlacement: propTypes.string,
  menuShouldBlockScroll: propTypes.bool,
  formatOptionLabel: propTypes.func,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  label: propTypes.string,
  isRequired: propTypes.bool,
  isGrid: propTypes.bool,
};

FormikModernSelect.defaultProps = {
  options: [],
  className: "",
  disabled: false,
  placeholder: "",
  menuPosition: "absolute",
  menuPlacement: "auto",
  menuShouldBlockScroll: false,
  formatOptionLabel: null,
  onChange: () => {},
  onBlur: () => {},
  label: "",
  isRequired: false,
  isGrid: false,
};

export default FormikModernSelect;
