import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import './index.scss';

const MainForm = ({ setTab }) => {
    const generalinquirys = [
        { for: "account_name", text: "ACCOUNT NAME:", type: "text", name: "account_name", placeholder: "PZ001" },
        { for: "email_address", text: "EMAIL ADDRESS:", type: "email", name: "email_address", placeholder: "OPERATIONS3@LUXURYEXPLORERSME.COM" },
        { for: "city", text: "CITY:", type: "text", name: "city", placeholder: "City Name" },
        { for: "country", text: "COUNTRY:", type: "text", name: "country", placeholder: "Country Name" },
        { for: "preferred_date", text: "PREFERRED DATE:", type: "text", name: "preferred_date" },
        { for: "alternate_date", text: "ALTERNATE DATE:", type: "text", name: "alternate_date" },
        { for: "budget", text: "BUDGET PER PERSON:", type: "text", name: "budget" },
        { for: "currency", text: "PREFERRED CURRENCY FOR THE QUOTE:", type: "text", name: "currency" },
    ]
    const rendergeneralinquiry =  (generalinquiry, index) => {
        return (
          <Row className="form_main" key={index}>
            <Col className="px-0" xl={3} lg={4} md={5}>
              <label htmlFor={generalinquiry.for}>{generalinquiry.text}</label>
            </Col>
            <Col xl={6} md={7} className="px-0">
              <input
                type={generalinquiry.type}
                name={generalinquiry.name}
                placeholder={generalinquiry.placeholder}
                value={generalinquiry.value}
                disabled = {( generalinquiry.placeholder == null ? null : 'disabled' )}
                // onChange={(e) =>
                //   props.setInput(generalform.name, e.target.value)
                // }
              />
            </Col>
          </Row>
        );
    }
  const setState = (e, tab) => {
    e.preventDefault()      
    setTab(tab)
  }
  return (
    <React.Fragment>
      <Form className="mt-12"> 
        {generalinquirys.map(rendergeneralinquiry)}
        <div className="float-right">
          <button className="Button" onClick={(e) => setState(e,"Flight")}>Save</button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default MainForm;
