import React from "react";
import { FieldArray } from "formik";
import { Table, Button } from "react-bootstrap";
import { MdRemoveCircle } from "react-icons/md";
import { useEventCoordinationContext } from "../../../context/EventCoordinationContext";
import FormikField from "../../../../../../shared/components/form/FormikField";
import FormikSelect from "../../../../../../shared/components/form/FormikSelect";
import ServiceQuestionController from "./ServiceQuestionController";
import TableData from "./TableData";
import { RFP_DETAILS_STATE_KEY } from "../../../utils/constants";
import { singleGroundTransportInitialValues } from "../../utils/initialValues";
import {
  transportCategoryOptions,
  serviceTypeOptions,
} from "../../utils/constants";

const GroundTransportation = () => {
  const {
    [RFP_DETAILS_STATE_KEY]: { isEditing },
  } = useEventCoordinationContext();

  return (
    <ServiceQuestionController
      questionText="Do you require Ground Transportation?"
      flag="ground_transportation_flag"
    >
      <FieldArray
        name="rfpgroundtransportation"
        render={({ form, push, remove }) => {
          return (
            <Table className="rfp-table">
              <thead>
                <tr>
                  <td width="33.33%">Category</td>
                  <td width="33.33%">Type Of Service</td>
                  <td width="33%.33">Remarks/Requirements</td>
                </tr>
              </thead>
              <tbody>
                <React.Fragment>
                  {form.values.rfpgroundtransportation.map((_, index) => (
                    <tr key={index}>
                      <TableData>
                        <FormikSelect
                          name={`rfpgroundtransportation[${index}].category`}
                          placeholder="Category"
                          options={transportCategoryOptions}
                          disabled={!isEditing}
                        />
                      </TableData>
                      <TableData>
                        <FormikSelect
                          name={`rfpgroundtransportation[${index}].type_of_service`}
                          placeholder="Service Type"
                          options={serviceTypeOptions}
                          disabled={!isEditing}
                        />
                      </TableData>
                      <TableData>
                        <FormikField
                          name={`rfpgroundtransportation[${index}].remarks`}
                          placeholder="Remarks"
                          disabled={!isEditing}
                        />
                      </TableData>
                      {form.values.rfpgroundtransportation.length > 1 &&
                        isEditing && (
                          <MdRemoveCircle
                            size={20}
                            className="remove-icon table-rows-remove-icon"
                            onClick={() => remove(index)}
                          />
                        )}
                    </tr>
                  ))}
                  <tr className="d-flex">
                    {isEditing && (
                      <Button
                        className="mt-5 button small-button"
                        onClick={() => push(singleGroundTransportInitialValues)}
                      >
                        + Add Ground Transport
                      </Button>
                    )}
                  </tr>
                </React.Fragment>
              </tbody>
            </Table>
          );
        }}
      />
    </ServiceQuestionController>
  );
};

export default GroundTransportation;
