import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import PreferredVenue from "./preferred_venue";
import { getVenueList } from "../../server/Filter";
import "./index.scss";

const EventNewtwo = () => {
  const [venueList, setVenueList] = useState([]);

  useEffect(() => {
    getVenueList()
      .then((data) => setVenueList(data))
      .catch((err) => console.log(err));
  }, []);

  const preferredVenues = venueList.map((listItem) => ({
    id: listItem.id,
    source:
      listItem.venues.length > 0
        ? listItem.venues[0].thumbnail?.smaller_image
        : "",
    heading: listItem.venue_list_name,
    text: `${listItem.venues?.length} Venue(s)`,
  }));

  const renderPreferredVenue = (preferredVenue) => {
    return (
      <Col
        xl={3}
        md={4}
        sm={6}
        className="px-0 px-sm-4"
        key={preferredVenue.id}
      >
        <Link
          to={`/venue_sourcing/preferred_venues/${preferredVenue.id}`}
          className="text-decoration-none"
        >
          <Image
            src={preferredVenue.source}
            className="preffered_venue_image"
            fluid
          />
          <p className="font-weight-bold text-lg-125 text-100 text-dark mt-3 mb-2">
            {preferredVenue.heading}
          </p>
          <p className="text-dark">{preferredVenue.text}</p>
        </Link>
      </Col>
    );
  };

  return (
    <React.Fragment>
      <Header />
      <Container fluid className="px-0">
        <Row className="inner_width_div">
          <Col xs={12} className="pl-sm-2 pl-5 pr-5 pr-sm-0">
            <PreferredVenue />
            <Row>
              {preferredVenues.length > 0 ? (
                preferredVenues.map(renderPreferredVenue)
              ) : (
                <p className="text-lg-125 text-100 mb-2 m-auto pb-5">
                  You don't have any Preferred Venue
                </p>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default EventNewtwo;
