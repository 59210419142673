import React, { useMemo } from "react";
import { useFormikContext } from "formik";

import SectionWrapper from "./SectionWrapper";
import EventDetailsForm from "./form/EventDetailsForm";
import PrefferredDestinations from "./form/PrefferredDestinations";
import PrefferredVenues from "./form/PrefferredVenues";
import Registration from "./form/Registration";
import FlightTicket from "./form/FlightTicket";
import VisaAssistance from "./form/VisaAssistance";
import Accommodation from "./form/Accommodation";
import MeetingRooms from "./form/MeetingRooms";
import GroundTransportation from "./form/GroundTransportation";
import FandBArrangement from "./form/FandBArrangement";
import TourEntertainment from "./form/TourEntertainment";
import OtherRequirements from "./form/OtherRequirements";
import Production from "./form/Production";
import { CONGRESS } from "../utils/constants";

const RFPForm = () => {
  const { values } = useFormikContext();

  const isTypeEvents = useMemo(() => values?.category !== CONGRESS, [values]);

  return (
    <div className="w-100 d-flex flex-column mt-10">
      <SectionWrapper heading="RFP Form" coverFullSection>
        <EventDetailsForm isTypeEvents={isTypeEvents} />
        {isTypeEvents && <PrefferredDestinations />}
        <PrefferredVenues />
        <Registration />
        <FlightTicket />
        <VisaAssistance />
        <Accommodation />
        <MeetingRooms />
        <GroundTransportation />
        <FandBArrangement />
        <TourEntertainment />
        <OtherRequirements />
        <Production />
      </SectionWrapper>
    </div>
  );
};

export default RFPForm;
