import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Modal, Row, Col, Button } from "react-bootstrap";
import FormikField from "../../../shared/components/form/FormikField";

const DataModal = ({ isOpen, toggle, title, onAdd }) => {
  return (
    <Modal show={isOpen} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ item: "" }}
          validationSchema={Yup.object().shape({
            item: Yup.string().required("Required"),
          })}
          onSubmit={(values, { resetForm }) => {
            onAdd(values.item);
            resetForm();
            toggle();
          }}
        >
          <Form className="rfp-sub-form mt-5">
            <Row className="px-0 d-flex align-items-center">
              <Col md={3}>
                <p className="text-100 mb-0 required">Name</p>
              </Col>
              <Col md={9} className="pl-0">
                <FormikField name="item" placeholder="Name" />
              </Col>
            </Row>
            <Row className="mt-7 d-flex justify-content-end">
              <Col md={2} className="px-0">
                <Button type="submit" className="primary-button">
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default DataModal;
