import React from "react";
import { Link } from "react-router-dom";
import { useFieldArray } from "react-hook-form";
import { Row, Col, Table, Image } from "react-bootstrap";
import EventServeiceButtons from "../event_service_buttons";

const EventEngagement = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
    control,
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "engagement",
  });

  const hotelsCards = [
    { source: "assets/pages/venue/Capture3.PNG", head1: "SD12425" },
    { source: "assets/pages/venue/Capture3.PNG", head1: "SD12425" },
    { source: "assets/pages/venue/Capture3.PNG", head1: "SD12425" },
  ];

  const disableEngagementType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      engagementDisabled: true,
    }));
  };

  const enableEngagementType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      engagementDisabled: false,
    }));
  };

  const renderhotelsCard = (hotelsCard, index) => {
    return (
      <Col xl={4} className="px-2" key={index}>
        <div className="event_accomodate_div">
          <Image
            src={hotelsCard.source}
            className="w-100"
            style={{ height: "90px" }}
            fluid
          />
          <p className="text-lg-125 text-100 mt-3 mb-1">{hotelsCard.head1}</p>
          <Row>
            <Col xl={6} className="pl-0 pr-2">
              <button
                onClick={(e) => e.preventDefault()}
                style={{ fontSize: "14px" }}
              >
                Add to RFP
              </button>
            </Col>
            <Col xl={6} className="pr-0 pl-2">
              <button
                onClick={(e) => e.preventDefault()}
                style={{ fontSize: "14px" }}
              >
                View Stage
              </button>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };
  return (
    <React.Fragment>
      <Row className="form_main m-0">
        <Col className="px-0" md={3}></Col>
        <Col className="px-0" xl={6} md={3}>
          <Table bordered hover>
            <thead className="text-center">
              <tr>
                <th style={{ width: "33.33%" }}>Sub Category</th>
                <th style={{ width: "33.33%" }}>Concept Design Code</th>
                <th style={{ width: "33.33%" }}>Remarks/Adjustments</th>
              </tr>
            </thead>
            <tbody>
              {fields.map(({ id }, index) => (
                <tr key={id} className="reg_table">
                  <td className="pl-0 pr-2 py-0">
                    <select
                      name={`engagement[${index}].sub_category`}
                      className="bg-white pl-2"
                    >
                      <option>Stage Design</option>
                      <option>Hospitality Desk Design</option>
                      <option>Venue Concept</option>
                      <option>Photo Booth</option>
                    </select>
                  </td>
                  <td className="p-0">
                    <input
                      type="text"
                      name={`engagement[${index}].concept_design_code`}
                      className="bg-white px-3"
                    />
                  </td>
                  <td className="p-0">
                    <input
                      type="text"
                      name={`engagement[${index}].remarks`}
                      className="bg-white px-3"
                    />
                    {index !== 0 && (
                      <div className="item-actions-container">
                        <div className="item-actions">
                          <button
                            className="Button_hide"
                            onClick={() => remove(index)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col md={3} />
        <Col md={6} className="px-0">
          <div className="event_form_addmore_btn">
            <button
              className="Button_hide m-0 mb-3"
              onClick={() =>
                append({
                  sub_category: "",
                  concept_design_code: "",
                  remarks: "",
                })
              }
            >
              Add More
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={3}></Col>
        <Col xl={6} md={3} className="px-0">
          <Row>{hotelsCards.map(renderhotelsCard)}</Row>
        </Col>
        <Col md={3} className="text-left my-auto">
          <Link
            className="text-dark text-decoration-none"
            to="/concept_designs/hospitality_desk_designs"
          >
            Explore More Stage Designs
          </Link>
        </Col>
      </Row>
      <EventServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableEngagementType}
        enableSelectedType={enableEngagementType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default EventEngagement;
