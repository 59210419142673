import React from "react";
import { useField, useFormikContext } from "formik";
import Select from "../Select";

const FormikSelect = ({
  name,
  options = [],
  customClass = "",
  disabled = false,
  placeholder = "",
  menuPosition = "absolute",
  menuPlacement = "auto",
  menuShouldBlockScroll = false,
  formatOptionLabel = null,
  onChange = null,
  onBlur = null,
  ...restProps
}) => {
  const [field, meta] = useField(name || "");
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const { onBlur: onFieldBlur, value, ...restFieldProps } = field;
  const { touched, error } = meta;

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      setFieldValue(name, selectedOption.value);
      if (onChange) onChange(selectedOption.value);
    }
  };

  const handleBlur = (event) => {
    onFieldBlur(event);
    setFieldTouched(name, true);
    if (onBlur) onBlur(event.target.value);
  };

  const selectedOption = options.find((option) => option.value === value);
  const isErrorField = touched && error;

  return (
    <div styles={{ height: 22 }} className={`w-100 ${customClass}`} id={name}>
      <Select
        {...restFieldProps}
        {...restProps}
        options={options}
        multi
        onChange={handleChange}
        onBlur={handleBlur}
        value={selectedOption || ""}
        classNamePrefix="react-select"
        isDisabled={disabled}
        disabled={disabled}
        placeholder={placeholder}
        menuPosition={menuPosition}
        menuPlacement={menuPlacement}
        menuShouldBlockScroll={menuShouldBlockScroll}
        formatOptionLabel={formatOptionLabel}
        styles={{
          control: (base) => ({
            ...base,
            opacity: 0.5,
            borderColor: isErrorField ? "#df0505" : "#c7c7c7",
            "&:hover": { borderColor: isErrorField ? "#df0505" : "#c7c7c7" },
          }),
        }}
      />
      {touched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
    </div>
  );
};

export default FormikSelect;
