import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Formik, Form } from "formik";
import { Row, Col } from "react-bootstrap";
import { useEventCoordinationContext } from "../../context/EventCoordinationContext";
import FormikSelect from "../../../../../shared/components/form/FormikSelect";
import LoadingButton from "../../../../../components/common/LoadingButton";
import FormRecordWrapper from "./FormRecordWrapper";
import {
  assignRFPEventRole,
  updateRFPEventRole,
} from "../../../../../server/Filter";
import { USERS_OPTIONS_STATE_KEY } from "../../utils/constants";
import { eventRoleFormInitialValues } from "../utils/initialValues";
import { eventRoleFormValidationSchema } from "../utils/validationSchema";
import { eventRoleOptions } from "../utils/contants";

const EventRoleForm = ({ selected, toggle }) => {
  const alert = useAlert();
  const { rfpID } = useParams();
  const { id: loggedInUserID } = useSelector((state) => state.auth.user);
  const {
    data: { [USERS_OPTIONS_STATE_KEY]: usersOptionsData },
    modifiers: { updateEventRoles },
  } = useEventCoordinationContext();
  const [initialValues, setInitialValues] = useState(
    eventRoleFormInitialValues
  );

  useEffect(() => {
    setInitialValues({
      event_role: selected ? selected.event_role : "",
      assigned_to: selected ? selected.assigned_to_id : "",
    });
  }, [selected]);

  const userOptions = useMemo(
    () =>
      usersOptionsData
        .filter((item) => item.id !== loggedInUserID)
        .map((item) => ({
          label: item.username,
          value: item.id,
        })),
    [loggedInUserID, usersOptionsData]
  );

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const isUpdateRequest = !!selected;
    const payload = isUpdateRequest
      ? { ...values, id: selected.id }
      : { ...values, rfp_id: rfpID }; // Add RFP ID for add request only

    try {
      const targetAction = isUpdateRequest
        ? updateRFPEventRole
        : assignRFPEventRole;
      await targetAction(payload);
      updateEventRoles();
      alert.success(
        `Event Role ${isUpdateRequest ? "updated" : "added"} successfully`
      );
      setSubmitting(false);
      toggle();
      resetForm();
    } catch (error) {
      setSubmitting(false);
      toggle();
      alert.error(
        `Something went wrong in ${
          isUpdateRequest ? "updating" : "adding"
        } event role`
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={eventRoleFormValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="custom-form">
          <FormRecordWrapper label="Event Role">
            <FormikSelect
              name="event_role"
              options={eventRoleOptions}
              placeholder="Select Event Role"
            />
          </FormRecordWrapper>
          <FormRecordWrapper label="Assigned To">
            <FormikSelect
              name="assigned_to"
              options={userOptions}
              placeholder="Select User"
            />
          </FormRecordWrapper>
          <Row className="mt-7">
            <Col className="d-flex justify-content-center">
              <LoadingButton
                type="submit"
                className="button primary-button"
                isLoading={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </LoadingButton>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default EventRoleForm;
