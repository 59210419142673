import React from "react";
import { Button } from "react-bootstrap";
import { MdRemoveCircle } from "react-icons/md";
import { useEventCoordinationContext } from "../../../context/EventCoordinationContext";
import { RFP_DETAILS_STATE_KEY } from "../../../utils/constants";

const DataCard = ({
  title,
  items = [],
  buttonTitle,
  onAddButtonClick,
  onDelete,
}) => {
  const {
    [RFP_DETAILS_STATE_KEY]: { isEditing },
  } = useEventCoordinationContext();

  return (
    <div className="w-100 d-flex align-items-center">
      <span className="w-5" />
      <div className="data-card w-60">
        <div className="d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          {isEditing && (
            <Button
              type="button"
              className="button small-button"
              onClick={onAddButtonClick}
            >
              {buttonTitle}
            </Button>
          )}
        </div>
        {items.length > 0 && (
          <div className="mt-5 list-items">
            {items.map((item) => (
              <div className="mb-5 list-item" key={item.title}>
                <div className="d-flex align-items-center">
                  <img
                    src={item.image || "/assets/images/img-placeholder.png"}
                    alt="data-item"
                  />
                  <p className="mb-0 ml-3">{item.title}</p>
                </div>
                {isEditing && (
                  <MdRemoveCircle
                    size={20}
                    className="remove-icon"
                    onClick={() => onDelete(item.placeID)}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DataCard;
