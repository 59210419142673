import React from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { BsFillCaretRightFill, BsFillCaretLeftFill } from "react-icons/bs";
import getFormatedDateRange from "../../../utils/getFormatedDateRange";
import "./index.scss";
import { sortRequests } from "../utilities/helpers";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="arrow arrowRight" onClick={onClick}>
      <BsFillCaretRightFill />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="arrow arrowLeft" onClick={onClick}>
      <BsFillCaretLeftFill />
    </div>
  );
}
const Requests = ({ requests = [] }) => {
  const history = useHistory();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: requests.length > 2 ? 3 : requests.length,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <React.Fragment>
      {requests.length > 0 ? (
        <div className="homePageSlider">
          <Slider {...settings}>
            {sortRequests(requests).map((item) => (
              <div key={item.id} className="navCardDiv">
                <div
                  className="navCard"
                  onClick={() => history.push(`/product_launch/${item.id}`)}
                >
                  <div className="date">
                    <h1>
                      {getFormatedDateRange(
                        item.required_date_start,
                        item.required_date_end
                      )}
                    </h1>
                  </div>
                  <div
                    style={{ backgroundColor: "#1a3666", color: "white" }}
                    id="pointer"
                  >
                    <p>{item.event_name}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <img src="/assets/images/home/icons/status.png" alt="" />
                    <p>Status: {item.status}</p>
                  </div>
                  <div className="attendess d-flex align-items-center">
                    <img src="/assets/images/home/icons/5.png" alt="" />
                    <p>Attendees: {item.no_of_attendee}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <p>No events found</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default Requests;
