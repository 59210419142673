import React from "react";
import { useFormikContext } from "formik";
import { useRFPFormContext } from "../context/RFPContext";
import FormikChoices from "../../../shared/components/form/FormikChoices";
import ServiceQuestionWrapper from "./ServiceQuestionWrapper";
import ServiceContentWrapper from "./ServiceContentWrapper";

const ServiceQuestionController = ({
  questionText,
  flag,
  stepNo,
  children,
}) => {
  const { values } = useFormikContext();
  const { changeStep } = useRFPFormContext();

  return (
    <React.Fragment>
      <ServiceQuestionWrapper label={questionText}>
        <FormikChoices name={flag} onChange={() => changeStep(stepNo)} />
      </ServiceQuestionWrapper>
      {values[flag] && (
        <ServiceContentWrapper>{children}</ServiceContentWrapper>
      )}
    </React.Fragment>
  );
};

export default ServiceQuestionController;
