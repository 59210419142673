import React from "react";
import { FieldArray } from "formik";
import { Table, Button } from "react-bootstrap";
import { MdRemoveCircle } from "react-icons/md";
import FormikField from "../../../shared/components/form/FormikField";
import ServiceQuestionController from "./ServiceQuestionController";
import { singleFlightTicketInitialValues } from "../utils/initialValues";

const FlightTicket = () => {
  return (
    <div className="w-100">
      <ServiceQuestionController
        questionText="Do you require flight ticket?"
        flag="flight_ticket_flag"
        stepNo={3}
      >
        <FieldArray
          name="rfpflightticket"
          render={({ form, push, remove }) => {
            return (
              <Table className="rfp-table">
                <thead>
                  <tr>
                    <td width="50%">Travelling From</td>
                    <td width="50%">Number of Passengers</td>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {form.values.rfpflightticket.map((_, index) => (
                      <tr key={index}>
                        <td>
                          <FormikField
                            name={`rfpflightticket[${index}].departure_place`}
                            placeholder="Travelling from"
                          />
                        </td>
                        <td>
                          <FormikField
                            type="number"
                            name={`rfpflightticket[${index}].number_of_pax`}
                            placeholder="Number of passengers"
                          />
                        </td>
                        {form.values.rfpflightticket.length > 1 && (
                          <MdRemoveCircle
                            size={20}
                            className="remove-icon table-rows-remove-icon"
                            onClick={() => remove(index)}
                          />
                        )}
                      </tr>
                    ))}
                    <tr className="d-flex">
                      <Button
                        className="mt-5 button small-button"
                        onClick={() => push(singleFlightTicketInitialValues)}
                      >
                        + Add Flight Ticket
                      </Button>
                    </tr>
                  </React.Fragment>
                </tbody>
              </Table>
            );
          }}
        />
      </ServiceQuestionController>
    </div>
  );
};

export default FlightTicket;
