import React from "react";
import { DescriptionOutlined } from "@material-ui/icons";
import { Col, Container, Row } from "react-bootstrap";

// COMPONENTS & UTLITIES
import VenuSpaceTable from "./VenuSpaceTable";
import { useGetVenueDetailContext } from "../custom-hooks/useGetVenueDetailContext";
import { getVenueMeetingSpaceDetailsQuery } from "../../../services/venue-details";
import SectionLoaderWrapper from "../../../components/common/SectionLoaderWrapper";
import useFetchRequest from "../../../components/common/custom-hooks/useFetchRequest";
import { STORAGE_URL } from "../../../constants";
import { downloadFileWithURL } from "../../../utils/helpers";

function VenueSpaceTabPanel() {
  const { venueId } = useGetVenueDetailContext();
  const { data, isLoading } = useFetchRequest(
    getVenueMeetingSpaceDetailsQuery,
    venueId
  );

  const handleDownloadFile = (file) => {
    console.log(STORAGE_URL + file?.document_path, file?.document_name);
    downloadFileWithURL(STORAGE_URL + file?.document_path, file?.document_name);
  };

  return (
    <SectionLoaderWrapper isLoading={isLoading}>
      <Container className="venue-space-container">
        <Row className="mb-4">
          <h6 className="heading">Capacity Chart</h6>
        </Row>

        <VenuSpaceTable data={data?.venue_meeting_rooms ?? []} />

        <div className="floor-plans-wrapper mt-4">
          <Row>
            <h6 className="heading">Floor Plans</h6>
          </Row>

          <Row className="floor-plans-row">
            {data?.venue_docs?.length > 0 &&
              data.venue_docs.map((item) => (
                <Col key={item?.id} md={4} className="d-flex">
                  <div
                    className="floor-plan-item"
                    onClick={() => handleDownloadFile(item)}
                  >
                    <DescriptionOutlined className="file-icon" />

                    <p>{item?.document_name}</p>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </Container>
    </SectionLoaderWrapper>
  );
}

export default VenueSpaceTabPanel;
