import React from 'react';

const Individual = () => {
    return (
        <React.Fragment>
            <select name='category' disabled>
                <option value="individual">Individual</option>
                <option value="group">Group</option>
            </select>
        </React.Fragment>
    )
}

export default Individual;
