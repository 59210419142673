import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone-uploader";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import Image from "./Image";
import { v4 as uuidv4 } from "uuid";
import "react-dropzone-uploader/dist/styles.css";

const InputContent = ({ maxFiles }) => (
  <div className="d-flex justify-content-center align-items-center flex-column">
    <p className="m-auto">
      Please upload image upto 15MB. Also, Image file type should be JPG, JPEG
      or PNG.
    </p>
    <div
      style={{
        marginTop: 10,
        padding: 10,
        color: "#fff",
        backgroundColor: "#071f4e",
      }}
    >
      {maxFiles === 1 ? "Choose Image" : "Choose Images"}
    </div>
  </div>
);

export default ({
  uploadUrl,
  onChange,
  deleteImage,
  multiple,
  maxFiles,
  defaultImages,
  info,
}) => {
  const alert = useAlert();
  const { token } = useSelector((state) => state.auth);
  const [keyId, setKeyId] = useState("");

  useEffect(() => {
    setKeyId(uuidv4());
  }, []);

  const [files, setFiles] = useState([]);
  const [filesWithMeta, setFilesWithMeta] = useState([]);
  const [arrayIndex, setArrayIndex] = useState(0);

  useEffect(() => {
    if (defaultImages && defaultImages.length > 0) {
      setFiles(defaultImages);
    }
  }, [defaultImages]);

  useEffect(() => {
    const fileIds = files.map((file) => ({ id: file.id }));
    onChange(fileIds);
  }, [files, onChange]);

  const deleteFile = (id) => {
    if (id) {
      const removedFile = files.find((file) => file.id === id);
      const { remove } = removedFile;
      if (remove) {
        remove();
      } else {
        setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
        deleteImage(id);
      }
    }
  };

  const getUploadParams = () => {
    return {
      url: uploadUrl,
      headers: { Authorization: `Token ${token}` },
    };
  };

  const handleChangeStatus = (fileWithMeta, status) => {
    const { meta, xhr, remove } = fileWithMeta;
    if (status === "preparing") {
      setFilesWithMeta((prevFiles) => [...prevFiles, fileWithMeta]);
    } else if (status === "ready") {
      if (arrayIndex === 0) {
        setTimeout(() => {
          filesWithMeta[arrayIndex].restart();
        }, 500);
        setArrayIndex((prevIndex) => prevIndex + 1);
      }
    } else if (status === "done") {
      if (maxFiles === 1) {
        setFiles([{ ...JSON.parse(xhr?.response), fileId: meta.id, remove }]);
      } else {
        setFiles((prevFiles) => [
          ...prevFiles,
          { ...JSON.parse(xhr?.response), fileId: meta.id, remove },
        ]);
      }
    } else if (status === "removed") {
      const removedMetaFileIndex = filesWithMeta.findIndex(
        (file) => file.meta.id === meta.id
      );
      if (removedMetaFileIndex >= arrayIndex) {
        setFilesWithMeta((prevFiles) =>
          prevFiles.filter((file) => file.meta.id !== meta.id)
        );
      }
      const removedFile = files.find((file) => file.fileId === meta.id);
      if (removedFile) {
        setFiles((prevFiles) =>
          prevFiles.filter((file) => file.id !== removedFile.id)
        );
        deleteImage(removedFile.id);
      }
    } else if (status === "error_file_size") {
      alert.show(
        `${meta.name} is too large.
        Image size must be less than 15 MB`,
        { type: "error" }
      );
      remove();
    } else if (status === "rejected_file_type") {
      alert.show(
        `${meta.name} format is not allowed.
        Please select image of type JPG, JPEG or PNG`,
        { type: "error" }
      );
      remove();
    } else if (status === "exception_upload" || status === "error_upload") {
      alert.show(
        `Sorry! ${meta.name} failed to upload.
        Please check your internet connection and try to upload again.`,
        { type: "error" }
      );
      remove();
    }

    if (
      status === "done" ||
      status === "aborted" ||
      status === "exception_upload" ||
      status === "error_upload"
    ) {
      if (arrayIndex !== filesWithMeta.length) {
        filesWithMeta[arrayIndex].restart();
        setArrayIndex((prevIndex) => prevIndex + 1);
      } else {
        setArrayIndex(0);
        setFilesWithMeta([]);
      }
    }
  };

  return (
    <>
      <Dropzone
        multiple={multiple}
        maxSizeBytes={15728640} // 15MB
        submitButtonContent={null}
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={maxFiles}
        autoUpload={false}
        canRestart={false}
        inputContent={<InputContent key={keyId} maxFiles={maxFiles} />}
        accept="image/jpg, image/jpeg, image/png"
        styles={{ dropzone: { minHeight: 300, maxHeight: 350 } }}
      />
      {files.length > 0 ? (
        <div className="mt-5">
          <h5>Uploaded Images Previews:</h5>
          <div className="row mt-5 py-3" style={{ backgroundColor: "#E9ECEF" }}>
            {files.map((file) => (
              <Image
                key={file.id}
                img={file}
                deleteImage={deleteFile}
                maxFiles={maxFiles}
              />
            ))}
          </div>
        </div>
      ) : (
        <small className="text-success">{info}</small>
      )}
    </>
  );
};
