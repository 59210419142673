import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams, useLocation, useHistory } from "react-router-dom";
import moment from "moment";

import "../styles/page-wrapper.scss";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import useGetCartHandlers from "../../../custom-hooks/useGetCartHandlers";
import { useMemo } from "react";
import { getEventRemainingDays } from "../../../utils/helpers";

const DEFAULT_BANNER_SRC = 'url("/assets/pages/design/Untitled-4.png")';

const PageWrapper = ({
  congresDetails,
  bannerImgSrc = DEFAULT_BANNER_SRC,
  bannerTitle = "",
  fluid = false,
  onBackButtonClick = null,
  children,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const { state } = useLocation();
  const { handleCongressCart } = useGetCartHandlers({ id });

  const handleAddToCart = () => {
    handleCongressCart(state?.imageURL);
  };

  const congressStartDate = useMemo(
    () => moment(congresDetails?.start_date).format("MMMM DD"),
    [congresDetails]
  );
  const congressEndDate = useMemo(
    () => moment(congresDetails?.end_date).format("MMMM DD , YYYY"),
    [congresDetails]
  );
  const congressDaysLeft = useMemo(
    () =>
      // moment(moment(congresDetails?.end_date).add("1", "day")).diff(
      //   moment(),
      //   "days"
      // ),
      getEventRemainingDays(congresDetails?.end_date),
    [congresDetails]
  );

  return (
    <div className="page-main-wrapper">
      <Header />

      <Container
        className="pageBanner"
        style={{
          background: `linear-gradient(rgba(18, 21, 56, 0.7), rgba(18, 21, 56, 0.7)), ${bannerImgSrc} center/cover `,
        }}
        fluid
      >
        <div className="page-content p-5">
          <p className="congress-banner-title">{bannerTitle}</p>

          <Row className="congress-banner-details">
            {congressStartDate && congressEndDate && (
              <Col className=" info-col">
                <img
                  src="/assets/images/congress-banner-icon/date.png"
                  alt="Calendar"
                />

                <div className="mt-2">{`${congressStartDate} - ${congressEndDate}`}</div>
              </Col>
            )}

            {congresDetails?.address && (
              <Col className="info-col">
                <img
                  src="/assets/images/congress-banner-icon/location.png"
                  alt="Location"
                />
                <div className="mt-2">{congresDetails?.address}</div>
              </Col>
            )}

            {congresDetails?.credits && (
              <Col className="info-col">
                <img
                  src="/assets/images/congress-banner-icon/cridet.png"
                  alt="Credit"
                />

                <div className="mt-2">{congresDetails?.credits}</div>
              </Col>
            )}
          </Row>

          <div className="time-left-wrapper info-col justify-content-end">
            <img
              src="/assets/images/congress-banner-icon/daysleft.png"
              alt=""
            />
            <p className="mt-4  float-right">
              {congressDaysLeft > 0 ? congressDaysLeft : 0} Days Left
            </p>
          </div>
        </div>
      </Container>

      <Container fluid={fluid} className="page-content-wrapper">
        <div className="d-flex align-items-center justify-content-between gap-1">
          <button
            className="back-button"
            onClick={() => {
              if (onBackButtonClick) onBackButtonClick();
              else history.goBack();
            }}
          >
            <img
              src="/assets/images/icon-back.png"
              className="icon_back mr-2"
              alt="Back Button"
            />
            Back
          </button>

          <button
            className="btn-border-secondary sm cart-btn"
            onClick={handleAddToCart}
          >
            Add to Cart
          </button>
        </div>

        <div className="page-content-main">{children}</div>
      </Container>

      <Footer />
    </div>
  );
};

export default PageWrapper;
