import React, {useState} from 'react';
import { Form, Row, Col, Container } from 'react-bootstrap';
import Individual from '../individual';
import Group from '../group';
import Logistic from '../../generalinquiryes/form/logistic';
import LogisticAdmin from '../../generalinquiryes/form/logisticadmin';
import './index.scss';

const GeneralForms = (props) => {
    const generalforms = [
        { for: "account_name", text: "ACCOUNT NAME:", type: "text", name: "account_name", placeholder: "PZ001" },
        { for: "email_address", text: "EMAIL ADDRESS:", type: "email", name: "email_address", placeholder: "OPERATIONS3@LUXURYEXPLORERSME.COM" },
        { for: "name", text: "NAME:", type: "text", name: "name" },
        { for: "contact_number", text: "CONTACT NUMBER:", type: "text", name: "contact_number" },
        { for: "conference_name", text: "CONFERENCE NAME:", type: "text", name: "conference_name" },
    ]
    const rendergeneralform =  (generalform, index) => {
        return (
          <Row className="form_main">
            <Col md={3}>
              <label for={generalform.for}>{generalform.text}</label>
            </Col>
            <Col md={6} key={index}>
              <input
                type={generalform.type}
                name={generalform.name}
                placeholder={generalform.placeholder}
                value={generalform.value}
                onChange={(e) =>
                  props.setInput(generalform.name, e.target.value)
                }
                disabled = {( generalform.placeholder == null ? null : 'disabled' )}
              />
            </Col>
            <Col md={3}></Col>
          </Row>
        );//
    }
    const [option, setOption] = useState(1)
    return (
        <React.Fragment>
            <Container fluid>
                <div className="booth_inner_div inner_width_div">
                    <Form className="form_header"> 
                        {generalforms.map(rendergeneralform)}
                        <Row className="form_main">
                            <Col md={3}>
                                <label for="number_of_delegates">
                                    NUMBER OF DELEGATES:
                                </label>
                            </Col>
                            <Col md={6}>
                                <input
                                    type="text"
                                    name="number_of_delegates"
                                    placeholder=""
                                    value={option}
                                    onChange={(e) => setOption(e.target.value)}
                                />
                            </Col>
                            <Col md={3}></Col>
                        </Row>
                        <Row className="form_main">
                            <Col md={3}>
                                <label for='category'>CATEGORY:</label>
                            </Col>
                            <Col md={6}>
                                { option <= 9 ? <Individual /> : <Group /> }
                            </Col>
                            <Col md={3}></Col>
                        </Row>
                    </Form>
                    { option <= 9 ? <Logistic /> : <LogisticAdmin /> }
                </div>
            </Container>
        </React.Fragment>
    )
}

export default GeneralForms;
