import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import PageWrapper from "../../../components/common/PageWrapper";
import { withFileHandler } from "../../../components/common/file-handler";
import ErrorAlert from "../common/ErrorAlert";
import SpreadsheetEditor from "./components/SpreadsheetEditor";
import DocumentEditor from "./components/DocumentEditor";

const GlobalDocumentEditor = () => {
  const history = useHistory();
  const { state } = useLocation();

  // State must be in below format
  // {
  //   fileData: {
  //     fileURL: 'https://documenturi.com'
  //     fileName: '' (Optional),
  //   },
  //   saveParams: {
  //     module: 'documents'
  //   },
  //   returnData: {  (Optional)
  //     url: 'https://example.com'
  //     state: {
  //       ... if have any state which needs to pass when going back
  //     }
  //   }
  // }

  if (!state || !state.fileData || !state.fileData.fileURL)
    return <ErrorAlert alertMessage="File data not found!!!!" />;

  const goBack = () => {
    if (state && state.returnData && state.returnData.url) {
      history.push(state.returnData.url, { ...state.returnData.state });
    } else {
      history.goBack();
    }
  };

  const renderDocumentEditor = () => {
    const fileType = state.fileData.fileURL.split("?")[0].split(".").pop();

    if (["xlsx", "xlx"].includes(fileType))
      return (
        <SpreadsheetEditor {...state.fileData} saveParams={state.saveParams} />
      );

    if (["docx", "doc"].includes(fileType))
      return (
        <DocumentEditor {...state.fileData} saveParams={state.saveParams} />
      );

    return (
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <h5>No document editor found against provided file type</h5>
      </div>
    );
  };

  return (
    <PageWrapper bannerTitle="Document Editor" onBackButtonClick={goBack} fluid>
      <div className="min-vh-70">{renderDocumentEditor()}</div>
    </PageWrapper>
  );
};

export default withFileHandler(GlobalDocumentEditor);
