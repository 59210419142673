import React, { createContext, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import MiniLoader from "../../../components/common/MiniLoader";
import ViewToolbar from "../components/ViewToolbar";
import {
  ACCOUNT_VIEWS,
  ADD_USER,
  ALL_USERS,
  CHANGE_PASSWORD,
  DEFAULT_VIEW,
  EDIT_USER,
  MY_ACCOUNT,
} from "../utils/constants";

export const ManageAccountContext = createContext({
  isLoading: true,
  viewMode: DEFAULT_VIEW,
  selectedUser: {},
  setSelectedUser: () => null,
  startLoading: () => null,
  stopLoading: () => null,
  switchToView: () => null,
  switchToMyAccountView: () => null,
  switchToAllUsersView: () => null,
  switchToAddUserView: () => null,
  switchToEditUserView: () => null,
  switchToChangePasswordView: () => null,
});

export const useManageAccountContext = () => useContext(ManageAccountContext);

const ManageAccountProvider = ({ children }) => {
  const [viewMode, setSelectedViewMode] = useState(DEFAULT_VIEW);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    // Dummy Loading for half second
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const switchToView = (targetViewMode) => {
    setIsLoading(true);
    setSelectedViewMode(
      ACCOUNT_VIEWS.includes(targetViewMode) ? targetViewMode : DEFAULT_VIEW
    );
    // Dummy Loading for 0.2 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  const switchToMyAccountView = () => switchToView(MY_ACCOUNT);
  const switchToAllUsersView = () => switchToView(ALL_USERS);
  const switchToAddUserView = () => switchToView(ADD_USER);
  const switchToEditUserView = () => switchToView(EDIT_USER);
  const switchToChangePasswordView = () => switchToView(CHANGE_PASSWORD);

  return (
    <ManageAccountContext.Provider
      value={{
        isLoading,
        viewMode,
        selectedUser,
        startLoading,
        stopLoading,
        setSelectedUser,
        switchToView,
        switchToMyAccountView,
        switchToAllUsersView,
        switchToAddUserView,
        switchToEditUserView,
        switchToChangePasswordView,
      }}
    >
      <ViewToolbar />
      {isLoading ? (
        <div className="my-7 d-flex justify-content-center">
          <MiniLoader />
        </div>
      ) : (
        <Container className="px-0" fluid>
          {children}
        </Container>
      )}
    </ManageAccountContext.Provider>
  );
};

export default ManageAccountProvider;
