/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  useTransformCitySuggestions,
  useTransformSearchSuggestions,
} from "../../../custom-hooks/useTransformers";
import { useTransformListToOptions } from "../../venues/custom-hooks/useTransformers";
import {
  getCitySuggestionsQuery,
  getLocationSuggestionsQuery,
  getVenueTypeOptionsQuery,
} from "../../../services/venue-sourcing";
import useLazyFetchRequest from "../../../components/common/custom-hooks/useLazyFetchRequest";
import useFetchRequest from "../../../components/common/custom-hooks/useFetchRequest";
import { getSearchParamObj } from "../../../utils/helpers";

const useFilterHandlers = (searchParams, searchParamsObj) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const hasParams = useMemo(
    () => Object.values(getSearchParamObj(searchParams))?.length > 0,
    [searchParams]
  );

  const [
    getLocationSuggestions,
    {
      data: locationData,
      isSuccess: isLocationSuccess,
      isLoading: isLocationLoading,
    },
  ] = useLazyFetchRequest(getLocationSuggestionsQuery);
  const [
    getCitySuggestions,
    { data: cityData, isLoading: isCityLoading, isSuccess: isCitySuccess },
  ] = useLazyFetchRequest(getCitySuggestionsQuery);
  const { data: venueTypeData } = useFetchRequest(getVenueTypeOptionsQuery);

  // TRANSFORMERS
  const locationOptions = useTransformSearchSuggestions(locationData);
  const cityOptions = useTransformCitySuggestions(cityData);
  const venueTypeOptions = useTransformListToOptions(venueTypeData);

  // HANDLERS
  const handleUpdateURL = () => {
    history.push({
      pathname,
      search: searchParams.toString(),
    });
  };

  const handleSelectLocation = useCallback(
    (item) => {
      const value = item[0]?.value || "";
      searchParams.set("search", value);
      handleUpdateURL();
    },
    [searchParams]
  );

  const handleSearchLocation = useCallback(async (value) => {
    await getLocationSuggestions(value);
  }, []);

  const handleSelectCity = useCallback(
    (item) => {
      const value = item[0]?.value || "";
      searchParams.set("city", value);
      handleUpdateURL();
    },
    [searchParams]
  );

  const handleSearchCity = useCallback(async (value) => {
    await getCitySuggestions(value);
  }, []);

  const handleResetParams = useCallback(() => {
    history.push(pathname);
  }, []);

  return {
    locationOptions,
    cityOptions,
    venueTypeOptions,
    isCityLoading,
    isCitySuccess,
    isLocationSuccess,
    isLocationLoading,
    handleSelectLocation,
    handleSearchLocation,
    handleSelectCity,
    handleSearchCity,
    hasParams,
    handleResetParams,
  };
};

export default useFilterHandlers;
