import React, { useEffect, useState, createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import MiniLoader from "../../../../components/common/MiniLoader";
import { getRFPProInvoices, getRFPInvoices } from "../../../../server/Filter";
import { getPromisesValues } from "../../../../utils/getPromiseValue";
import {
  INVOICES_STATE_KEY,
  PROFORMA_INVOICES_STATE_KEY,
} from "../utils/constants";

export const EventCloseOutContext = createContext(null);

export const useEventCloseOutContext = () => useContext(EventCloseOutContext);

const EventCloseOutProvider = ({ children }) => {
  const { rfpID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [closeOutData, setCloseoutData] = useState({});

  const fetchCloseoutData = async () => {
    try {
      const requestsWithArrayResponses = await Promise.allSettled([
        getRFPProInvoices(rfpID),
        getRFPInvoices(rfpID),
      ]);
      const [rfpProInvoicesResponse, rfpInvoicesResponse] = getPromisesValues(
        requestsWithArrayResponses
      );
      setCloseoutData({
        [PROFORMA_INVOICES_STATE_KEY]: rfpProInvoicesResponse,
        [INVOICES_STATE_KEY]: rfpInvoicesResponse,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCloseoutData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateRFPProInvoices = () => {
    getRFPProInvoices(rfpID).then((res) =>
      setCloseoutData((prevState) => ({
        ...prevState,
        [PROFORMA_INVOICES_STATE_KEY]: res,
      }))
    );
  };

  const updateRFPInvoices = () => {
    getRFPInvoices(rfpID).then((res) =>
      setCloseoutData((prevState) => ({
        ...prevState,
        [INVOICES_STATE_KEY]: res,
      }))
    );
  };

  return (
    <EventCloseOutContext.Provider
      value={{
        data: closeOutData,
        modifiers: {
          updateRFPProInvoices,
          updateRFPInvoices,
        },
      }}
    >
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <MiniLoader />
        </div>
      ) : (
        children
      )}
    </EventCloseOutContext.Provider>
  );
};

export default EventCloseOutProvider;
