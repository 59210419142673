import React, {useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BrandSearchBar from '../brandsearchbar';
import Text from '../../generalinquiry/text';

const Branding = ({ setTab }) => {
    const [showResult, setShowResult] = useState(false)
    const onClicks = (e) => {
        e.preventDefault()
        setShowResult(true)
    }
    const [showResults, setShowResults] = useState(false)
    const onClick = (e) => {
        e.preventDefault()
        setShowResults(true)
    }
    const [showbrandingsearch, setSShowbrandingsearch] = useState("")
    const onClickes = (e) => {
        e.preventDefault()
        setSShowbrandingsearch("BrandSearchBar")
    }
    return (
        <React.Fragment>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>BRANDING CODE</th>
                        <th>QUANTITY</th>
                        <th>REMARKS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>PN0001</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
            <Row>
                <Col xs={12} className="px-0 text-75">
                    <div className="float-right">
                        <button className="Button_hide gen_inquiry_func_buttons">
                            Cancel
                        </button>
                        <button 
                            className="Button_hide gen_inquiry_func_buttons" 
                            onClick={onClicks}
                        >
                            Save
                        </button>
                    </div>
                </Col>
            </Row>
            { showResult ? 
                <Row id="result">
                    <Col xs={12} className="px-0 text-75">
                        <div className="float-right w-100">
                            <button className="Button_hide gen_inquiry_func_buttons">
                                Edit
                            </button>                          
                            <button className="Button_hide gen_inquiry_func_buttons">
                                Delete
                            </button>
                            <Link to="/branding_materials">
                                <button className="Button_hide gen_inquiry_func_buttons">
                                    AddMore
                                </button>
                            </Link>
                        </div>
                    </Col>
                </Row> : null 
            }
            <Text heading="Requirements:" />
            <Row>
                <Col xs={12} className="px-0">
                    <div className="float-right">
                        <button 
                            className="Button" 
                            onClick={onClick}
                        >
                            Save
                        </button>
                    </div>
                </Col>
            </Row>
            { showResults ? 
                <Row id="results">
                    <Col xs={12} className="px-0">
                        <div className="float-right w-100">
                            <button className="Button gen_inquiry_func_buttons">Send Request</button>
                            <button className="Button gen_inquiry_func_buttons">Delete Service</button>                          
                            <button className="Button gen_inquiry_func_buttons" onClick={onClickes}>Add Service</button>
                            <button className="Button gen_inquiry_func_buttons">Edit</button>
                        </div>
                    </Col>
                </Row> : null 
            }
            { showbrandingsearch === "BrandSearchBar" ? <BrandSearchBar setTab={setTab} /> : null }
        </React.Fragment>
    )
}

export default Branding;
