import React, { useState } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import EventCart from "./EventCart";
import CongressCart from "./CongressCart";
import "./index.scss";

const Cart = () => {
  const history = useHistory();
  const [key, setKey] = useState("events");
  return (
    <React.Fragment>
      <Header />
      <Container
        className="event_banner px-0 position-relative"
        style={{
          backgroundImage: 'url("/assets/cart.png")',
        }}
        fluid
      >
        <div className="text-blocks">
          <p className="font-weight-bold text-center text-md-375 text-sm-300 text-250 mb-0 font-poppins">
            My Cart
          </p>
        </div>
      </Container>
      <Container fluid className="pr-0">
        <Row className="cartTabRow p-0 m-0">
          <Col md={12} className="cartTab pr-0">
            <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
              <Tab eventKey="events" title="Events Cart"></Tab>
              <Tab eventKey="congress" title="Congress & Exhibition Cart"></Tab>
            </Tabs>
          </Col>
        </Row>
        <Row className="">
          <Col md={6} className="myback_button mt-10" >
            <div className="back_button" onClick={() => history.goBack()}>
              <img src="/assets/images/icon-back.png" alt="" />
              Back
            </div>
          </Col>
        </Row>
      </Container>
      {key === "events" && <EventCart head="Events" />}
      {key === "congress" && <CongressCart head="Congress" />}
      <Footer />
    </React.Fragment>
  );
};

export default Cart;
