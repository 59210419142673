import React, { useState } from "react";
import EventFlight from "../event_flight";
import EventAccomodate from "../event_accomodate";
import EventMeetingRoom from "../event_meeting_room";
import EventProgram from "../event_program";
import EventBranding from "../event_branding";
import EventGifts from "../event_gifts";
import EventTransportation from "../event_transportation";
import EventFood from "../event_food";
import EventEngagement from "../event_engagement";
import EventOthers from "../event_others";
import EventServiceType from "../event_service_type";

export default function ({
  addServiceForms,
  deleteServiceForm,
  disabledServiceTypes,
  setDisabledServiceTypes,
  setStatus,
  register,
  control,
  errors,
}) {
  const [tab, setTab] = useState("EventMainForm");
  return (
    <div>
      <EventServiceType
        setTab={setTab}
        tab={tab}
        disabledServiceTypes={disabledServiceTypes}
      />
      {tab === "Flight" ? (
        <div>
          <EventFlight
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "Accomodate" ? (
        <div>
          <EventAccomodate
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "MeetingRoom" ? (
        <div>
          <EventMeetingRoom
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "Program" ? (
        <div>
          <EventProgram
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "Branding" ? (
        <div>
          <EventBranding
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "Gifts" ? (
        <div>
          <EventGifts
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "Transportation" ? (
        <div>
          <EventTransportation
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "Food" ? (
        <div>
          <EventFood
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "Engagement" ? (
        <div>
          <EventEngagement
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "Others" ? (
        <div>
          <EventOthers
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
