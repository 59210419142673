import { useState, useMemo } from "react";
import useLazyFetchRequest from "../../../../../components/common/custom-hooks/useLazyFetchRequest";
import useToggle from "../../../../../components/common/custom-hooks/useToggle";
import { getRFPBudgetDetails } from "../../../../../server/Filter";
import formatAmount from "../../../../../utils/formatAmount";
import { groupBudgetItemsByType } from "../utils/helpers";

function useBudgetDetails() {
  const {
    isOpen: isBudgetItemDetailModalOpen,
    toggle: toggleBudgetItemDetail,
  } = useToggle();
  const [
    fetchBudgetDetails,
    { isLoading: isBudgetDetailsLoading, data: budgetDetails = {} },
  ] = useLazyFetchRequest(getRFPBudgetDetails);
  const [selectedBudgetItem, setSelectedBudgetItem] = useState(null);
  const [showSubTotal, setShowSubTotal] = useState(false);

  const toggleBudgetItemDetailModal = (targetBudgetItem) => {
    setSelectedBudgetItem(targetBudgetItem);
    toggleBudgetItemDetail();
  };

  const formattedBudgetDetailsData = useMemo(() => {
    const modifiedData = (budgetDetails.budget_items || []).map(
      (budgetItem) => ({
        ...budgetItem,
        formatted_description: budgetItem.item_description || "-",
        formatted_currency: budgetItem.item_currency_label || "AED",
        formatted_estimated_budget: `${
          budgetItem.item_currency_label || "AED"
        }${formatAmount(budgetItem.estimated_total_unit_cost)}`,
        formatted_reconciled_budget: `${
          budgetItem.item_currency_label || "AED"
        }${formatAmount(budgetItem.reconciled_total_unit_cost)}`,
        formatted_savings: `${
          budgetItem.item_currency_label || "AED"
        }${formatAmount(budgetItem.savings)}`,
      })
    );

    return groupBudgetItemsByType(modifiedData, budgetDetails.currency);
  }, [budgetDetails]);

  const budgetItemsTotal = useMemo(
    () =>
      (budgetDetails.budget_items || []).reduce(
        (acc, item) => ({
          estimated: acc.estimated + item.estimated_total_unit_cost || 0,
          reconciled: acc.reconciled + item.reconciled_total_unit_cost || 0,
          savings: acc.savings + item.savings || 0,
        }),
        { estimated: 0, reconciled: 0, savings: 0 }
      ),
    [budgetDetails]
  );

  return {
    budgetDetails,
    selectedBudgetItem,
    setSelectedBudgetItem,
    showSubTotal,
    setShowSubTotal,
    isBudgetItemDetailModalOpen,
    toggleBudgetItemDetailModal,
    fetchBudgetDetails,
    isBudgetDetailsLoading,
    formattedBudgetDetailsData,
    budgetItemsTotal,
  };
}

export default useBudgetDetails;
