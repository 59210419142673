import { Box } from "@material-ui/core";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function CongressOverView({ congresDetails }) {
  return (
    <Container className="overview-container">
      <div>
        <h6 className="heading">OVERVIEW</h6>
      </div>
      <div dangerouslySetInnerHTML={{ __html: congresDetails?.description }} />
      <div>
        <Box>
          <h6 className="congress-overview-heading my-5 ">KEY DATES</h6>
        </Box>
        <Row className="keydates-Wrapper">
          <Col className="keydateflex-wrapper">
            <Row>
              <img
                src="/assets/images/medical-congress/start.svg"
                alt="key date"
                height={40}
                width={30}
              />
              <hr className="datehr" />
            </Row>
            <p className="keydate">
              {congresDetails?.registration_open_date || "No date available"}
            </p>
            <p className="keydate-heading">Registration Open</p>
          </Col>
          <Col className="keydateflex-wrapper">
            <Row>
              <img
                src="/assets/images/medical-congress/close.svg"
                alt="key date"
                height={40}
                width={30}
              />
              <hr className="datehr" />
            </Row>
            <p className="keydate">
              {congresDetails?.registration_close_date
                ? congresDetails?.registration_close_date
                : "No date available"}
            </p>
            <p className="keydate-heading">Registration End</p>
          </Col>
          <Col className="keydateflex-wrapper">
            <Row>
              <img
                src="/assets/images/medical-congress/event-start.svg"
                alt="key date"
                height={40}
                width={30}
              />
              <hr className="datehr" />
            </Row>
            <p className="keydate">
              {congresDetails?.start_date
                ? congresDetails?.start_date
                : "No date available"}
            </p>
            <p className="keydate-heading">Event Start Date</p>
          </Col>
          <Col className="keydateflex-wrapper">
            <Row>
              <img
                src="/assets/images/medical-congress/event-close.svg"
                alt="key date"
                height={40}
                width={30}
              />
            </Row>
            <p className="keydate">
              {congresDetails?.end_date
                ? congresDetails?.end_date
                : "No date available"}
            </p>

            <p className="keydate-heading">Event End Date</p>
          </Col>
        </Row>
      </div>
      {congresDetails?.target_audience?.length > 0 && (
        <div>
          <h6 className="congress-overview-heading my-4  mt-5">
            TARGET AUDIENCE
          </h6>
          <Row className="targetaudiences-wrapper">
            {congresDetails?.target_audience?.map((item, index) => (
              <li className="col-lg-4 my-1" key={index}>
                {item}
              </li>
            ))}
          </Row>
        </div>
      )}
      {congresDetails?.specialities?.length > 0 && (
        <div>
          <h6 className="congress-overview-heading my-5  mt-5">SPECIALITIES</h6>
          <Row className="targetaudiences-wrapper">
            {congresDetails?.specialities?.map((item, index) => (
              <li className="col-lg-4 my-1" key={index}>
                {item.speciality}
              </li>
            ))}
          </Row>
        </div>
      )}
      {congresDetails?.more_information_link && (
        <Row className="moreinfo-wrapper">
          <h6 className="moreinfo">FOR MORE INFORMATION:</h6>

          <div className="moreinfolink">
            <a href={congresDetails.more_information_link}>
              {congresDetails.more_information_link}
            </a>
          </div>
        </Row>
      )}
    </Container>
  );
}

export default CongressOverView;
