import React, { useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import EventServeiceButtons from "../event_service_buttons";

const EventFood = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
    control,
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "food_and_bevarages",
  });

  const [date, setDate] = useState("");

  const disableFoodType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      foodDisabled: true,
    }));
  };

  const enableFoodType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      foodDisabled: false,
    }));
  };

  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" xs={12}>
          <Table bordered hover>
            <thead className="text-center">
              <tr>
                <th style={{ width: "16.67%" }}>Sub Category</th>
                <th style={{ width: "16.67%" }}>Date</th>
                <th style={{ width: "16.67%" }}>Number of Attendees</th>
                <th style={{ width: "16.67%" }}>Preferred Time</th>
                <th style={{ width: "16.67%" }}>
                  Preferred Restaurants/venue(if any)
                </th>
                <th style={{ width: "16.67%" }}>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {fields.map(({ id }, index) => (
                <tr key={id} className="reg_table">
                  <td className="pl-0 pr-2 py-0">
                    <select
                      name={`food_and_bevarages[${index}].sub_category`}
                      className="bg-white pl-2"
                    >
                      <option>Breakfast</option>
                      <option>Launch</option>
                      <option>Dinner</option>
                      <option>Gala Dinner</option>
                      <option>Room Service</option>
                    </select>
                  </td>
                  <td className="p-0">
                    <DatePicker
                      placeholderText="Date"
                      className="CityField text-left bg-white px-3"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      name={`food_and_bevarages[${index}].date`}
                    />
                  </td>
                  <td className="p-0">
                    <input
                      type="number"
                      name={`food_and_bevarages[${index}].no_of_attendees`}
                      className="bg-white px-3"
                    />
                  </td>
                  <td className="p-0">
                    <input
                      type="time"
                      name={`food_and_bevarages[${index}].preferred_time`}
                      className="bg-white px-3"
                    />
                  </td>
                  <td className="p-0">
                    <input
                      type="text"
                      name={`food_and_bevarages[${index}].preferred_restaurant`}
                      className="bg-white px-3"
                    />
                  </td>
                  <td className="p-0">
                    <input
                      type="text"
                      name={`food_and_bevarages[${index}].remarks`}
                      className="bg-white px-3"
                    />
                    {index !== 0 && (
                      <div className="item-actions-container">
                        <div className="item-actions">
                          <button
                            className="Button_hide"
                            onClick={() => remove(index)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <div className="event_form_addmore_btn text-right">
          <button
            className="Button_hide"
            onClick={() =>
              append({
                sub_category: "",
                date: "",
                no_of_attendees: "",
                preferred_time: "",
                preferred_restaurant: "",
                remarks: "",
              })
            }
          >
            Add More
          </button>
        </div>
      </Row>
      <EventServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableFoodType}
        enableSelectedType={enableFoodType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default EventFood;
