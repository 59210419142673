import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import SearchBar from '../selectbar';

const ServeiceButtons = ({ setTab }) => {
    const [showResults, setShowResults] = useState(false)
    const onClick = (e) => {
        e.preventDefault()
        setShowResults(true)
    }
    const [showSearchBar, setShowSearchBar] = useState("")
    const onClicks = (e) => {
        e.preventDefault()
        setShowSearchBar("SearchBar")
    }
    return (
        <React.Fragment>
            <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="px-0">
                        <div className="float-right">
                            <button className="Button" onClick={onClick}>Save</button>
                        </div>
                    </Col>
                </Row>
                { showResults ? 
                    <Row id="results">
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="px-0">
                            <div className="float-right w-100">      
                                <button className="Button gen_inquiry_func_buttons">Send Request</button>     
                                <button className="Button gen_inquiry_func_buttons">Delete Service</button>             
                                <button className="Button gen_inquiry_func_buttons" onClick={onClicks}>Add Service</button>
                                <button className="Button gen_inquiry_func_buttons">Edit</button>  
                            </div>
                        </Col>
                    </Row> 
                    : null 
                }
                { showSearchBar === "SearchBar" ? <SearchBar setTab={setTab} /> : null }
        </React.Fragment>
    )
}

export default ServeiceButtons;
