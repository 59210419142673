/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import propTypes from "prop-types";
import { useAlert } from "react-alert";

// COMPONENTS & UTILITIES
import FormikModernField from "../../../shared/components/form/FormikModernField";
import {
  basicInfoInitValues,
  basicInfoValSchema,
} from "../utilities/formUtils";
import FormikSubmitBtn from "../../../shared/components/form/FormikSubmitBtn";
import FormikGalleryField from "../../../shared/components/form/FormikGalleryField/index.jsx";
import { getVenueBasicDetailsQuery } from "../../../services/venue-details";
import { updateBasicInfoMutation } from "../../../services/venue-management";
import { getUpdatedBasicInfoData } from "../utilities/helpers.js";
import { useGetFormContext } from "../context/FormContext.js";

function BasicInfoTabPanel({ setVenueName }) {
  const [initValues, setInitValues] = useState(basicInfoInitValues);
  const alert = useAlert();

  const { venueId, setCurrentTab, setPayload } = useGetFormContext();

  useEffect(() => {
    (async () => {
      if (venueId) {
        const basicDetailsResp = await getVenueBasicDetailsQuery(venueId);
        setVenueName(basicDetailsResp?.venue_name);

        const newValues = getUpdatedBasicInfoData(basicDetailsResp);

        setInitValues((prevState) => ({
          ...prevState,
          ...newValues,
        }));
      }
    })();
  }, [venueId]);

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={basicInfoValSchema}
      onSubmit={async (values, { setValues, setTouched }) => {
        if (venueId) {
          const updateDataResp = await updateBasicInfoMutation(venueId, values);

          if (updateDataResp.status === 400) {
            alert.error("Error Updating Basic Info");
            return;
          }

          const getUpdateResp = await getVenueBasicDetailsQuery(venueId);
          const newValues = getUpdatedBasicInfoData(getUpdateResp);

          setInitValues((prevState) => ({
            ...prevState,
            ...newValues,
          }));

          alert.success("Basic Info Updated Successfully");

          return;
        }

        setPayload((prevState) => ({ ...prevState, ...values }));
        setCurrentTab((prevState) => prevState + 1);
      }}
    >
      {({ setValues }) => (
        <Form className="d-flex align-items-start is-grid">
          <FormikGalleryField
            name="venue_images"
            multiple
            uploadURL="/sourcing/venues/manage-bucket/"
            deleteURL="/sourcing/venues/manage-bucket/"
          />

          <FormikModernField
            label="Venue Name"
            name="venue_name"
            isRequired
            isGrid
          />

          <FormikModernField label="Venue Type" name="venue_type" isGrid />

          <FormikModernField
            label="Address"
            name="venue_address"
            isRequired
            isGrid
          />

          <FormikModernField
            label="Rating"
            name="star_rating"
            isGrid
            type="number"
          />

          <FormikModernField label="Build Year" name="build_year" isGrid />

          <FormikModernField
            label="Total Guest Rooms"
            name="total_sleeping_rooms"
            isGrid
            type="number"
          />

          <FormikModernField
            label="Total Meeting Space"
            name="meeting_space"
            isGrid
            type="number"
          />

          <FormikModernField label="Chain Name" name="chain_name" isGrid />

          <FormikModernField label="Brand Name" name="brand_name" isGrid />

          <FormikModernField
            label="Renovated"
            name="renovation_year"
            isGrid
            type="number"
          />

          <FormikModernField
            label="Largest Meeting Room"
            name="largest_meeting_room"
            isGrid
            type="number"
          />

          <FormikModernField
            label="Second Largest Meeting Room"
            name="second_largest_meeting_room"
            isGrid
            type="number"
          />

          <FormikModernField
            label="Total Meeting Rooms"
            name="total_meeting_rooms"
            isGrid
            type="number"
          />

          <FormikSubmitBtn label={venueId ? "Submit" : "Next"} />
        </Form>
      )}
    </Formik>
  );
}

BasicInfoTabPanel.propTypes = {
  setVenueName: propTypes.func.isRequired,
};

export default BasicInfoTabPanel;
