import React from "react";
import { useEventCoordinationContext } from "../../context/EventCoordinationContext";
import SectionWrapper from "./SectionWrapper";
import RFPRecord from "./RFPRecord";
import { RFP_DETAILS_STATE_KEY } from "../../utils/constants";

const GeneralInformation = () => {
  const {
    data: { [RFP_DETAILS_STATE_KEY]: rfpDetails },
  } = useEventCoordinationContext();

  return (
    <SectionWrapper heading="General Information" isHalfSection>
      <RFPRecord label="RFP Type:">{rfpDetails.category}</RFPRecord>
      <RFPRecord label="RFP Status:">{rfpDetails.status}</RFPRecord>
      <RFPRecord label="RFP Code:">REQ {rfpDetails.id}</RFPRecord>
    </SectionWrapper>
  );
};

export default GeneralInformation;
