import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import useGetCartHandlers from "../../../custom-hooks/useGetCartHandlers";
import { getConferenceImage } from "../utilities/helpers";
import { getEventRemainingDays } from "../../../utils/helpers";

function Card({ conference }) {
  const [cardImage, setCardImage] = useState(null);
  const { handleCongressCart } = useGetCartHandlers({ id: conference?.id });

  const handleAddToCart = (e) => {
    e.stopPropagation();

    handleCongressCart(cardImage);
  };

  useEffect(() => {
    if (conference) {
      setCardImage(getConferenceImage(conference?.thumbnail));
    }
  }, [conference]);

  const congressStartDate = useMemo(
    () => moment(conference?.start_date).format("MMMM DD"),
    [conference]
  );
  const congressEndDate = useMemo(
    () => moment(conference?.end_date).format("MMMM DD , YYYY"),
    [conference]
  );
  const congressDaysLeft = useMemo(
    // () => moment(conference?.end_date).diff(moment(), "days"),
    () => getEventRemainingDays(conference?.end_date),
    [conference]
  );

  return (
    <div className="card-container">
      <div className="cart-icon" onClick={handleAddToCart}>
        <img src="/assets/images/exhibition/icon.png" alt="Cart" />
      </div>

      <Link
        to={`/medical_congress/conferences/${conference.id}`}
        state={{ imageURL: cardImage, id: conference.id }}
        className="congress-card"
      >
        <div className="img-wrapper">
          <img src={cardImage} alt="Card" />
        </div>

        <div className="card-content-wrapper">
          <h4 className="text-title m-0">{conference.conference_name}</h4>

          <Col className="details-content">
            <Row className="content-row">
              <img
                className="icon-img"
                src="/assets/images/congress-banner-icon/date.png"
                alt="Calendar"
              />

              <p>{`${congressStartDate} - ${congressEndDate}`}</p>
            </Row>

            <Row className="content-row">
              <img
                className="icon-img"
                src="/assets/images/congress-banner-icon/location.png"
                alt="Location"
              />

              <p>{conference?.address || "NA"}</p>
            </Row>

            {conference?.credits && (
              <Row className="content-row">
                <img
                  className="icon-img"
                  src="/assets/images/congress-banner-icon/cridet.png"
                  alt="Credits"
                />

                <p>{conference.credits}</p>
              </Row>
            )}

            <Row className="content-row justify-content-end">
              <img
                className="icon-img"
                src="/assets/images/congress-banner-icon/daysleft.png"
                alt="Clock"
              />

              <p>{congressDaysLeft > 0 ? congressDaysLeft : 0} Days Left</p>
            </Row>
          </Col>
        </div>
      </Link>
    </div>
  );
}

export default Card;
