import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams,useHistory } from "react-router-dom";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import parse from "html-react-parser";
import { useAlert } from "react-alert";
import Travelinfo from "../travel/Travelinfo";
import { addToCongressCart, getConferenceByName } from "../../server/Filter";
import { increaseCartCount } from "../../redux/actions/cartActions";
import "./index.scss";

function Conferencetab() {
  const dispatch = useDispatch();
  const alert = useAlert();
  let history = useHistory();
  const { conferenceName } = useParams();

  const [conference, setConference] = useState([]);

  useEffect(() => {
    getConferenceByName(conferenceName)
      .then((data) => setConference(data))
      .catch((err) => console.log(err));
  }, [conferenceName]);

  const handleCartIconClick = async () => {
    try {
      await addToCongressCart("Conference", conference.id);
      dispatch(increaseCartCount());
      alert.show(`${conference.conference_name} successfully added to cart`, {
        type: "success",
      });
    } catch (err) {
      if (err.response) {
        alert.show(err.response.data, {
          type: "error",
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Row className="BackBtn">
        <Col xl={12} className="myback_button">
          <div className="back_button" onClick={() => history.goBack() }>
            <img
              src="/assets/images/icon-back.png"
              className="icon_back"
              alt=""
            />{" "}
            Back
          </div>
        </Col>
      </Row>
      <div className="w-100">
        <Row className="inner_width_div">
          <Col xs={12}>
            <div
              className="cart-div cursor-pointer"
              onClick={handleCartIconClick}
            >
              <img src="/assets/images/exhibition/icon.png" alt="" />
            </div>
          </Col>
        </Row>
      </div>
      <Container className="tabsContainer plan-parentxxx CustomizeContainer">
        <div className="tabsRow">
          <Tabs
            defaultActiveKey="overview"
            transition={false}
            id="noanim-tab-example"
            className="conferenceTab"
          >
            <Tab
              eventKey="overview"
              title={
                <div className="mainTabDiv">
                  <Col xs={3} md={3}>
                    <img
                      src="/assets/pages/testConference/mCongress/puzzle.png"
                      alt=""
                    />
                  </Col>
                  <Col xs={9} md={9} className="text-left">
                    <p>Congress Overview</p>
                  </Col>
                </div>
              }
            >
              <div className="m-1 mt-10 conferenceDiv">
                {conference.description &&
                  parse(conference.description.toString())}
              </div>
            </Tab>
            <Tab
              eventKey="registration"
              title={
                <div className="mainTabDiv">
                  <Col xs={3} md={3}>
                    <img
                      src="/assets/pages/testConference/mCongress/registration.png"
                      alt=""
                    />
                  </Col>
                  <Col xs={9} md={9} className="text-left">
                    <p>Registration</p>
                  </Col>
                </div>
              }
            >
              <div className="m-1 mt-10 conferenceDiv">
                {conference.registration &&
                  parse(conference.registration.toString())}
              </div>
            </Tab>
            {!conference.is_virtual && (
              <Tab
                eventKey="travel"
                title={
                  <div className="mainTabDiv">
                    <Col xs={3} md={3}>
                      <img
                        src="/assets/pages/testConference/mCongress/passport.png"
                        alt=""
                      />
                    </Col>
                    <Col xs={9} md={9} className="text-left">
                      <p>Travel Information</p>
                    </Col>
                  </div>
                }
              >
                <Travelinfo conference={conference} />
              </Tab>
            )}
          </Tabs>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Conferencetab;
