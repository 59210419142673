import React from 'react';
import { Link } from 'react-router-dom';

const PreferredVenue = () => {
    return (
        <React.Fragment>
            <div className="d-flex my-5 pl-sm-4 px-0 pr-sm-4 w-100">
                <p className="font-weight-bold text-lg-150 text-125 mb-0">
                    My Preferred Venues
                </p>
                <Link to="/venue_sourcing/venues" className="back_button ml-auto">
                    &lt; Back
                </Link>
            </div>
        </React.Fragment>
    )
}

export default PreferredVenue;
