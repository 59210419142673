import { v4 } from "uuid";
import convertURLToFile from "../../../utils/converttURLToFile";
import { hotelInitObj, registrationFormInitObj } from "./formUtils";

export const getOverviewFormValues = async (data) => {
  let thumbnailImage = null;
  let bannerImage = null;

  const targetAudience = data.target_audience?.map((audience) => ({
    label: audience,
    value: audience,
  }));

  const specialities = data.specialities?.map((specialities) => ({
    label: specialities.speciality,
    value: specialities.speciality,
  }));

  if (data?.thumbnail) {
    const fileObj = await convertURLToFile(data?.thumbnail);
    thumbnailImage = [Object.assign(fileObj, { uid: v4() })];
  }

  if (data?.banner_image) {
    const fileObj = await convertURLToFile(data?.banner_image);
    bannerImage = [Object.assign(fileObj, { uid: v4() })];
  }

  return {
    conference_name: data.conference_name,
    registration_open_date: data.registration_open_date,
    registration_close_date: data.registration_close_date,
    start_date: data.start_date,
    end_date: data.end_date,
    target_audience: targetAudience,
    specialities: specialities,
    description: data.description,
    more_information_link: data.more_information_link,
    address: data.address,
    credits: data.credits,
    banner_image: bannerImage,
    thumbnail: thumbnailImage,
    country: data?.country?.id || "",
  };
};

export const getRegistrationFormValues = (data) => {
  const { conference_registration } = data || {};
  let conferenceRegistration = [registrationFormInitObj];

  if (conference_registration?.length > 0) {
    conferenceRegistration = conference_registration?.map((item) => ({
      registration_heading: item.registration_heading,
      registration_category: item.registration_category,
      registration_end_date: item.registration_end_date || null,
      registration_fee: item.registration_fee,
      id: item.id,
    }));
  }

  return {
    more_information: data?.more_information || "",
    conference_registration: conferenceRegistration,
  };
};

export const getTravelInfoFormValues = async (data) => {
  const { hotels } = data || {};

  if (hotels?.length > 0) {
    const hotelsInfo = await Promise.all(
      hotels.map(async (items) => {
        let logoImage = null;

        if (items?.logo) {
          const fileObj = await convertURLToFile(items?.logo);
          const updatedFile = Object.assign(fileObj, { uid: v4() });
          logoImage = [updatedFile];
        }

        return {
          hotel_name: items.hotel_name,
          distance: items.distance,
          star_rating: items.star_rating,
          hotel_image: [],
          hotel_id: items.hotel_id,
          logo_2: logoImage,
        };
      })
    );

    return { hotels: hotelsInfo };
  }

  return {
    hotels: [hotelInitObj],
  };
};
