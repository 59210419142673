import React from "react";
import Attachment from "./Attachment";

const ChatMessage = ({
  messageRef = null,
  messageContent,
  attachments = [],
  messageTime = "00:00",
  isMyMessage = false,
}) => {
  return (
    <div
      className={`w-100 d-flex ${
        isMyMessage ? "justify-content-end" : "justify-content-start"
      }`}
    >
      <div
        ref={messageRef}
        className={`message ${isMyMessage ? "my_message" : "friend_message"}`}
      >
        <div className="content">{messageContent}</div>
        {attachments.length > 0 && (
          <div className="attachments">
            {attachments.map((attachment) => (
              <Attachment {...attachment} />
            ))}
          </div>
        )}
        <div className="footer">{messageTime}</div>
      </div>
    </div>
  );
};

export default ChatMessage;
