import React, { useEffect, useState, createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import MiniLoader from "../../../../components/common/MiniLoader";
import {
  getRFPBudgets,
  getRFPPrivateDocs,
  getRFPSharedDocs,
  getRFPLogisticsDocument,
} from "../../../../server/Filter";
import {
  getPromisesValues,
  getPromiseValue,
} from "../../../../utils/getPromiseValue";
import {
  BUDGET_STATE_KEY,
  LOGISTICS_STATE_KEY,
  PRIVATE_DOCS_STATE_KEY,
  SHARED_DOCS_STATE_KEY,
} from "../utils/constants";

export const EventPlanningContext = createContext(null);

export const useEventPlanningContext = () => useContext(EventPlanningContext);

const EventPlanningProvider = ({ children }) => {
  const { rfpID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [planningData, setPlanningData] = useState({});

  const fetchPlanningData = async () => {
    try {
      // Separate calls are made just becuase rfpLogisticsResponse doesnot contain Array response whereas
      // all other responses does. getPromisesValues() function takes responsesArray as input and return
      // empty array against each one which is not resolved
      const [rfpLogisticsRequestResponse, ...requestsWithArrayResponses] =
        await Promise.allSettled([
          getRFPLogisticsDocument(rfpID),
          getRFPBudgets(rfpID),
          getRFPPrivateDocs(rfpID),
          getRFPSharedDocs(rfpID),
        ]);
      const rfpLogisticsResponse = getPromiseValue(
        rfpLogisticsRequestResponse,
        {}
      );
      const [
        rfpBudgetsResponse,
        rfpPrivateDocsResponse,
        rfpSharedDocsResponse,
      ] = getPromisesValues(requestsWithArrayResponses);
      setPlanningData({
        [BUDGET_STATE_KEY]: rfpBudgetsResponse,
        [PRIVATE_DOCS_STATE_KEY]: rfpPrivateDocsResponse,
        [SHARED_DOCS_STATE_KEY]: rfpSharedDocsResponse,
        [LOGISTICS_STATE_KEY]: rfpLogisticsResponse,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPlanningData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSharedDocs = () => {
    getRFPSharedDocs(rfpID).then((res) =>
      setPlanningData((prevState) => ({
        ...prevState,
        [SHARED_DOCS_STATE_KEY]: res,
      }))
    );
  };

  const updatePrivateDocs = () => {
    getRFPPrivateDocs(rfpID).then((res) =>
      setPlanningData((prevState) => ({
        ...prevState,
        [PRIVATE_DOCS_STATE_KEY]: res,
      }))
    );
  };

  return (
    <EventPlanningContext.Provider
      value={{
        data: planningData,
        modifiers: {
          updateSharedDocs,
          updatePrivateDocs,
        },
      }}
    >
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <MiniLoader />
        </div>
      ) : (
        children
      )}
    </EventPlanningContext.Provider>
  );
};

export default EventPlanningProvider;
