export const mainTableHeadings = [
  {
    id: "service_request_formatted_num",
    label: "Request No",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "status",
    label: "Status",
  },
];
