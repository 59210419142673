import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "../../components/header";
import Conferencehead from "../../components/conferencehead";
import Footer from "../../components/footer";
import Conferencetab from "../../components/conferencetab";
import { getConferenceByName } from "../../server/Filter";

const ConferenceDetails = () => {
  const { conferenceName } = useParams();
  const [conference, setConference] = useState([]);

  useEffect(() => {
    getConferenceByName(conferenceName)
      .then((data) => setConference(data))
      .catch((err) => console.log(err));
  }, [conferenceName]);

  return (
    <React.Fragment>
      <Header />
      <Conferencehead conference={conference} />
      <Container fluid className="px-0" style={{ minHeight: 700,overflowX:"hidden" }}>
        <Conferencetab />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default ConferenceDetails;
