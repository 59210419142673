import {
  CLEAR_PRODUCT_LAUNCH_DATA,
  SAVE_RFP_DETAILS,
} from "../actions/productLaunchActions";

const initialState = {
  fetchedRFPDetails: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_PRODUCT_LAUNCH_DATA:
      return {
        ...state,
        fetchedRFPDetails: {},
      };
    case SAVE_RFP_DETAILS:
      return {
        ...state,
        fetchedRFPDetails: action.payload,
      };
    default:
      return state;
  }
}
