import React from "react";
import { useFormikContext } from "formik";
import { useEventCoordinationContext } from "../../../context/EventCoordinationContext";
import FormikField from "../../../../../../shared/components/form/FormikField";
import ServiceQuestionController from "./ServiceQuestionController";
import RFPFormRecord from "../RFPFormRecord";
import { RFP_DETAILS_STATE_KEY } from "../../../utils/constants";

const OtherRequirements = () => {
  const { values } = useFormikContext();
  const {
    [RFP_DETAILS_STATE_KEY]: { isEditing },
  } = useEventCoordinationContext();

  return (
    <ServiceQuestionController
      questionText="Do you have other requirements?"
      flag="other_requirement_flag"
    >
      <RFPFormRecord
        label="Requirements"
        value={values.other_requirements}
        labelClassName="w-40"
        valueClassName="w-60"
        removeLeftSpace
      >
        <FormikField
          name="other_requirements"
          rows={5}
          textArea
          disabled={!isEditing}
        />
      </RFPFormRecord>
    </ServiceQuestionController>
  );
};

export default OtherRequirements;
