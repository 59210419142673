import {
  GET_CART_COUNT,
  INC_CART_COUNT,
  DEC_CART_COUNT,
  GET_EVENT_CART,
  GET_CONGRESS_CART,
} from "../actions/cartActions";

const initialState = {
  count: 0,
  eventCart: null,
  congressCart: null,
};

export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_CART_COUNT:
      return {
        ...state,
        count: actions.payload,
      };
    case INC_CART_COUNT:
      return {
        ...state,
        count: state.count + 1,
      };
    case DEC_CART_COUNT:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : state.count,
      };
    case GET_EVENT_CART:
      return {
        ...state,
        eventCart: actions.payload,
      };
    case GET_CONGRESS_CART:
      return {
        ...state,
        congressCart: actions.payload,
      };
    default:
      return state;
  }
}
