import React from "react";
import FormikField from "../../../shared/components/form/FormikField";
import ServiceQuestionController from "./ServiceQuestionController";
import RFPFieldWrapper from "./RFPFieldWrapper";

const VisaAssistance = () => {
  return (
    <div className="w-100">
      <ServiceQuestionController
        questionText="Do you require Visa Assistance?"
        flag="visa_assistance_flag"
        stepNo={4}
      >
        <RFPFieldWrapper label="Nationality">
          <FormikField
            name="rfpvisaassistance.nationality"
            placeholder="Nationality"
          />
        </RFPFieldWrapper>
        <RFPFieldWrapper label="Remarks">
          <FormikField
            name="rfpvisaassistance.remarks"
            placeholder="Remarks"
            textArea
          />
        </RFPFieldWrapper>
      </ServiceQuestionController>
    </div>
  );
};

export default VisaAssistance;
