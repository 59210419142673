import React, { useState, useMemo } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { useParams } from "react-router-dom";

// COMPONENTS & STYLES
import PageWrapper from "../../components/common/PageWrapper";
import TabPanel from "../../components/common/TabPanel";
import BasicInfoTabPanel from "./components/BasicInfoTabPanel";
import OverviewTabPanel from "./components/OverviewTabPanel";
import GuestRoomsTabPanel from "./components/GuestRoomsTabPanel";
import MeetingVenuesSpaceTabPanel from "./components/MeetingVenuesSpaceTabPanel";
import LocalAttractionsTabPanel from "./components/LocalAttractionsTabPanel";
import LocationTabPanel from "./components/LocationTabPanel";
import usePreventRefresh from "../../custom-hooks/usePreventRefresh";
import { FormContext } from "./context/FormContext";
import "./index.scss";

function VenuesManagementForm() {
  const [venueName, setVenueName] = useState("");
  const [payload, setPayload] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const { id } = useParams();

  usePreventRefresh();

  const handleChangeTab = (e, newValue) => {
    setCurrentTab(newValue);
  };

  const contextValue = useMemo(
    () => ({
      venueId: id,
      payload,
      setPayload,
      currentTab,
      setCurrentTab,
    }),
    [id, payload, currentTab]
  );

  return (
    <PageWrapper bannerTitle={venueName}>
      <div className="venue-management-wrapper">
        <Tabs
          className="tabs"
          value={currentTab}
          onChange={handleChangeTab}
          TabIndicatorProps={{ hidden: true }}
        >
          <Tab className="tab" label="Basic Info" wrapped />

          <Tab className="tab" label="Overview" wrapped />

          <Tab className="tab" label="Guest Rooms" wrapped />

          <Tab className="tab" label="Meeting Venues Space" wrapped />

          <Tab className="tab" label="Local Attractions" wrapped />

          <Tab className="tab" label="Location" wrapped />
        </Tabs>

        <FormContext.Provider value={contextValue}>
          <div className="tab-panel-wrapper">
            <TabPanel tabValue={currentTab} index={0}>
              <BasicInfoTabPanel setVenueName={setVenueName} />
            </TabPanel>

            <TabPanel tabValue={currentTab} index={1}>
              <OverviewTabPanel />
            </TabPanel>

            <TabPanel tabValue={currentTab} index={2}>
              <GuestRoomsTabPanel />
            </TabPanel>

            <TabPanel tabValue={currentTab} index={3}>
              <MeetingVenuesSpaceTabPanel />
            </TabPanel>

            <TabPanel tabValue={currentTab} index={4}>
              <LocalAttractionsTabPanel />
            </TabPanel>

            <TabPanel tabValue={currentTab} index={5}>
              <LocationTabPanel />
            </TabPanel>
          </div>
        </FormContext.Provider>
      </div>
    </PageWrapper>
  );
}

export default VenuesManagementForm;
