/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { useAlert } from "react-alert";
import propTypes from "prop-types";
import { useHistory } from "react-router-dom";

// import {
//   deleteURL,
//   uploadURL,
// } from "../../venue_management_form/utilities/constants";
import FormikModernField from "../../../shared/components/form/FormikModernField";
import FormikSubmitBtn from "../../../shared/components/form/FormikSubmitBtn";
import { hotelInitObj, travelInfoFormInitValues } from "../utilities/formUtils";
// import FormikGalleryField from "../../../shared/components/form/FormikGalleryField";
import {
  createNewCongress,
  getCongressBasicDetailsQuery,
  updateTravelInformationForm,
} from "../../../services/congress-details";
import { getTravelInfoFormValues } from "../utilities/helpers";
import { useCongressContext } from "../context/CongressContext";
import FormikFileGalleryField from "../../../shared/components/form/FormikFileGalleryField";

function TeavelInformationForm({ congressId }) {
  const alert = useAlert();
  const history = useHistory();
  const [initialValues, setInitValues] = useState(travelInfoFormInitValues);
  const { setPayload, isAddCongress, congressDetails, payload } =
    useCongressContext();

  useEffect(() => {
    (async () => {
      if (congressId && congressDetails) {
        const newValues = await getTravelInfoFormValues(congressDetails);

        setInitValues((prevState) => ({
          ...prevState,
          ...newValues,
        }));
      }
    })();
  }, [congressId, congressDetails]);

  return (
    <div className="congress-form-wrapper">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (!isAddCongress) {
            const updateDataResp = await updateTravelInformationForm(
              congressId,
              values
            );
            if (updateDataResp.status === 400) {
              alert.error("Error Updating Basic Info");
              return;
            }

            const getUpdateResp = await getCongressBasicDetailsQuery(
              congressId
            );
            const newValues = getTravelInfoFormValues(getUpdateResp);

            setInitValues((prevState) => ({
              ...prevState,
              ...newValues,
            }));

            alert.success(
              " Update Congress Travel InformationForm Successfully"
            );
            return;
          }
          const data = { ...payload, ...values };
          setPayload(data);
          const updateDataResp = await createNewCongress(data);

          if (updateDataResp.status === 400) {
            alert.error("Error Creating Congress!");
            return;
          }

          alert.success("Congress Added Successfully!");
          history.push("/congress_management");
        }}
      >
        {({ values }) => (
          <Form className="d-flex align-items-start mt-5">
            <div className="congress-content-label">Hotels</div>
            <FieldArray name="hotels">
              {({ remove, push }) =>
                values.hotels?.map((_, index, arr) => (
                  <div key={index} className="w-100 is-grid">
                    <FormikFileGalleryField
                      name={`hotels[${index}].logo_2`}
                      multiple={false}
                      className="mt-5"
                    />

                    <FormikModernField
                      label="Hotel Name"
                      name={`hotels[${index}].hotel_name`}
                      isRequired
                    />

                    <FormikModernField
                      label=" Distance"
                      name={`hotels[${index}].distance`}
                      type="number"
                      isRequired
                    />

                    <FormikModernField
                      label="Star Rating"
                      name={`hotels[${index}].star_rating`}
                      type="number"
                      isRequired
                    />

                    <div className="d-flex align-items-center gap-1">
                      {arr?.length > 1 && (
                        <button
                          type="button"
                          className="btn-main-secondary sm"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </button>
                      )}

                      {arr.length - 1 === index && (
                        <button
                          type="button"
                          className="btn-main-primary sm"
                          onClick={() => push(hotelInitObj)}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                ))
              }
            </FieldArray>

            <FormikSubmitBtn />
          </Form>
        )}
      </Formik>
    </div>
  );
}

TeavelInformationForm.propTypes = {
  congressId: propTypes.string,
};

TeavelInformationForm.defaultProps = {
  congressId: null,
};

export default TeavelInformationForm;
