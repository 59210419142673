import React, { useState, useEffect } from "react";
import useDimensions from "react-cool-dimensions";
import { Image } from "react-bootstrap";
import Slider from "react-slick";
import { css } from "emotion";

const PackagesSlider = ({ sliderImages }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "0px",
  };

  const [heights, setHeights] = useState("");
  const responsive = css`
    height: ${heights};
  `;

  const { ref } = useDimensions({
    onResize: ({ width }) => {
      var screenHeight = (width * 9) / 16 + "px";
      setHeights(screenHeight);
    },
  });

  return (
    <React.Fragment>
      <div className="App h-auto view_packages_slider">
        <div className="slider-wrapper">
          <Slider
            {...settingsMain}
            asNavFor={nav2}
            ref={(slider) => setSlider1(slider)}
          >
            {sliderImages.map((slide) => (
              <div className="slick-slide" key={slide.image}>
                <Image
                  className={"w-100 " + responsive}
                  src={slide.image}
                  fluid
                  ref={ref}
                />
              </div>
            ))}
          </Slider>
          <div
            className="thumbnail-slider-wrap mt-1 mt-sm-2 mt-xl-3 mx-auto"
            style={{ maxWidth: "50%" }}
          >
            <Slider
              {...settingsThumbs}
              asNavFor={nav1}
              ref={(slider) => setSlider2(slider)}
            >
              {sliderImages.map((slide) => (
                <div className="slick-slide" key={slide.image}>
                  <Image
                    className="slick-slide-image view_packages_slider_img w-90"
                    src={slide.image}
                    fluid
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PackagesSlider;
