import moment from "moment";
import convertURLToFile from "../../../../../utils/converttURLToFile";
import {
  registrationInitialValues,
  flightTicketInitialValues,
  visaAssistanceInitialValues,
  accommodationInitialValues,
  meetingRoomsInitialValues,
  groundTransportationInitialValues,
  fAndBArrangementInitialValues,
  tourEntertainmentInitialValues,
  otherRequirementsInitialValues,
  productionInitialValues,
} from "./initialValues";

export const formatDate = (date) =>
  date ? moment(date).format("YYYY-MM-DD") : undefined;

export const transformDataToInitialValues = async (data) => {
  const {
    rfpdestination,
    rfpcustomdestination,
    rfpvenue,
    rfpcustomvenue,
    rfpregistration,
    rfpflightticket,
    rfpvisaassistance,
    rfpaccomodation,
    rfpmeetingroom,
    rfpgroundtransportation,
    f_and_b_arrangement,
    tour_entertainment,
    other_requirements,
    rfpproduction,
  } = data;

  const modifiedDestinations = [];

  // Looping through system destinations
  rfpdestination.forEach((item) => {
    modifiedDestinations.push({
      placeID: item.id,
      image: item.thumbnail || null,
      title: item.dest_city + ", " + item.dest_country,
      is_custom: false,
    });
  });

  // Looping through custom destinations
  rfpcustomdestination.forEach((item) => {
    modifiedDestinations.push({
      placeID: item.id,
      image: null,
      title: item.destination,
      is_custom: true,
    });
  });

  const modifiedVenues = [];

  // Looping through system venues
  rfpvenue.forEach((item) => {
    modifiedVenues.push({
      placeID: item.id,
      image: item.thumbnail || null,
      title: item.venue_name,
      is_custom: false,
    });
  });

  // Looping through custom venues
  rfpcustomvenue.forEach((item) => {
    modifiedVenues.push({
      placeID: item.id,
      image: null,
      title: item.venue,
      is_custom: true,
    });
  });

  // Modified Meeting Rooms
  const modifiedMeetingRooms = rfpmeetingroom.map((room) => ({
    ...room,
    meeting_time_start: room.meeting_time_start || undefined,
    meeting_time_end: room.meeting_time_start || undefined,
  }));

  // Convert URL to File if exists
  let uploadedDesign = !!rfpproduction && rfpproduction.uploaded_design;
  if (uploadedDesign) {
    uploadedDesign = await convertURLToFile(uploadedDesign);
  }

  return {
    ...data,
    rfpdestination: modifiedDestinations,
    rfpvenue: modifiedVenues,
    rfpregistration:
      rfpregistration.length > 0
        ? rfpregistration
        : registrationInitialValues.rfpregistration,
    rfpflightticket:
      rfpflightticket.length > 0
        ? rfpflightticket
        : flightTicketInitialValues.rfpflightticket,
    rfpvisaassistance:
      rfpvisaassistance || visaAssistanceInitialValues.rfpvisaassistance,
    rfpaccomodation:
      rfpaccomodation || accommodationInitialValues.rfpaccomodation,
    rfpmeetingroom:
      rfpmeetingroom.length > 0
        ? modifiedMeetingRooms
        : meetingRoomsInitialValues.rfpmeetingroom,
    rfpgroundtransportation:
      rfpgroundtransportation.length > 0
        ? rfpgroundtransportation
        : groundTransportationInitialValues.rfpgroundtransportation,
    f_and_b_arrangement:
      f_and_b_arrangement.length > 0
        ? f_and_b_arrangement
        : fAndBArrangementInitialValues.f_and_b_arrangement,
    tour_entertainment:
      tour_entertainment.length > 0
        ? tour_entertainment
        : tourEntertainmentInitialValues.tour_entertainment,
    other_requirements:
      other_requirements || otherRequirementsInitialValues.other_requirements,
    rfpproduction: rfpproduction
      ? {
          ...rfpproduction,
          uploaded_design: uploadedDesign,
        }
      : productionInitialValues.rfpproduction,
  };
};

export const transformRFPPayload = (payload) => {
  const {
    alternative_date_start,
    alternative_date_end,
    registration_flag,
    rfpregistration,
    flight_ticket_flag,
    rfpflightticket,
    visa_assistance_flag,
    rfpvisaassistance,
    accomodation_flag,
    rfpaccomodation,
    meeting_rooms_flag,
    rfpmeetingroom,
    ground_transportation_flag,
    rfpgroundtransportation,
    f_and_b_arrangement_flag,
    f_and_b_arrangement,
    tour_entertainment_flag,
    tour_entertainment,
    other_requirement_flag,
    other_requirements,
    production_flag,
    rfpproduction,
  } = payload;

  return {
    ...payload,
    alternative_date_start: formatDate(alternative_date_start),
    alternative_date_end: formatDate(alternative_date_end),
    rfpregistration: registration_flag ? rfpregistration : undefined,
    rfpflightticket: flight_ticket_flag ? rfpflightticket : undefined,
    rfpvisaassistance: visa_assistance_flag ? rfpvisaassistance : undefined,
    rfpaccomodation: accomodation_flag ? rfpaccomodation : undefined,
    rfpmeetingroom: meeting_rooms_flag ? rfpmeetingroom : undefined,
    rfpgroundtransportation: ground_transportation_flag
      ? rfpgroundtransportation
      : undefined,
    f_and_b_arrangement: f_and_b_arrangement_flag
      ? f_and_b_arrangement
      : undefined,
    tour_entertainment: tour_entertainment_flag
      ? tour_entertainment
      : undefined,
    other_requirements: other_requirement_flag ? other_requirements : undefined,
    rfpproduction: production_flag ? rfpproduction : undefined,
  };
};
