import React, { useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import Datepick from "../../generalinquiryes/form/datepick";
import EventServeiceButtons from "../event_service_buttons";
import EventText from "../event_text";

const EventMeetingRoom = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
  } = props;

  const Meetings = [
    {
      for: "preffered_time",
      text: "Preffered Time:",
      type: "time",
      name: "preffered_time",
    },
    {
      for: "attendees",
      text: "Number of Attendees:",
      type: "text",
      name: "attendees",
    },
  ];

  const disableMeetingRoomType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      meetingRoomDisabled: true,
    }));
  };

  const enableMeetingRoomType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      meetingRoomDisabled: false,
    }));
  };

  const renderMeeting = (Meeting, index) => {
    return (
      <React.Fragment key={index}>
        <Row className="form_main">
          <Col className="px-0" md={3}>
            <label htmlFor={Meeting.for}>{Meeting.text}</label>
          </Col>
          <Col className="px-0" md={6}>
            <input name={Meeting.name} type={Meeting.type} />
          </Col>
          <Col md={3}></Col>
        </Row>
      </React.Fragment>
    );
  };
  const rooms = [
    { text: "0", value: "0" },
    { text: "1", value: "1" },
    { text: "2", value: "2" },
    { text: "3", value: "3" },
    { text: "4", value: "4" },
    { text: "5", value: "5" },
  ];
  const [tablerow, setTableRow] = useState("0");

  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" md={3} xs={6}>
          <label htmlFor="meeting_date">Meeting Date:</label>
          <img
            className="calendar float-right mr-1"
            src={"assets/images/download.jpg"}
            alt="Not Found"
          />
        </Col>
        <Col className="px-0" xs={6}>
          <Datepick name="meeting_date" />
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="meeting_type">Meeting Package:</label>
        </Col>
        <Col className="form_column_sel pl-0" md={6}>
          <select name="meeting_type">
            <option>2 Coffee Breaks and 1 Lunch/Dinner</option>
            <option>1 Coffee Breaks and 1 Lunch/Dinner</option>
          </select>
        </Col>
        <Col md={3}></Col>
      </Row>
      {Meetings.map(renderMeeting)}
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="meeting_type">Meeting Package:</label>
        </Col>
        <Col className="form_column_sel pl-0" md={6}>
          <select
            name="rooms"
            value={tablerow}
            onChange={(e) => setTableRow(e.target.value)}
          >
            {rooms.map((i, index) => (
              <option key={index} value={i.value}>
                {i.text}
              </option>
            ))}
          </select>
        </Col>
        <Col md={3}></Col>
        {tablerow === "0" ? null : (
          <Table bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Breakout Room</th>
                <th>Date</th>
                <th>Number of Delegates</th>
                <th>Meeting Room Set-up</th>
              </tr>
            </thead>
            <tbody>
              {rooms.map((x, index) => {
                if (x.value < tablerow) {
                  return (
                    <tr key={index} className="reg_table">
                      <td className="p-0">
                        <input type="text" className="bg-white px-3" />
                      </td>
                      <td className="p-0">
                        <input type="text" className="bg-white px-3" />
                      </td>
                      <td className="p-0">
                        <input type="text" className="bg-white px-3" />
                      </td>
                      <td className="p-0">
                        <input type="text" className="bg-white px-3" />
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </Table>
        )}
      </Row>
      <EventText heading="Other Requirements:" />
      <EventServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableMeetingRoomType}
        enableSelectedType={enableMeetingRoomType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default EventMeetingRoom;
