import React from "react";
import PageWrapper from "../../components/common/PageWrapper";

const HybridMeetings = () => {
  return (
    <PageWrapper
      bannerTitle="Hybrid Meetings"
      bannerImgSrc={`url("/assets/images/engagement/hybrid-meeting-banner.jpg")`}
    >
      <div className="text-center">No Data Found</div>
    </PageWrapper>
  );
};

export default HybridMeetings;
