import useWebSocket, { ReadyState } from "react-use-websocket";
import { useParams } from "react-router-dom";
import useAuth from "../../../../components/common/custom-hooks/useAuth";
import { CRM_SOCKET_URL } from "../../../../constants";

function useChatSocket() {
  const { token } = useAuth();
  const { requestID } = useParams();
  const socketOptions = useWebSocket(`${CRM_SOCKET_URL}/api/send/message/`, {
    queryParams: { system: "EMP", token, service_request_id: requestID },
  });

  const { readyState } = socketOptions;

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Connected",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  return { ...socketOptions, connectionStatus };
}

export default useChatSocket;
