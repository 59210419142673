/* eslint-disable eqeqeq */
import React from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import RestaurantOption from "./restaurant_option";
import OutletOption from "./outlet_option";
import { deleteRestaurant } from "../../../../server/Filter";

const initialTab = "#fff";

// const useRestaurant = (control) => {
//   const watchedValues = useWatch({ control, name: "restaurants" });
//   return watchedValues;
// };

const Restaurant = ({
  setStyle,
  hideComponent,
  register,
  control,
  property,
  setProperty,
  isSubmitting,
}) => {
  const selections = [
    { text: "Restaurant", value: "false" },
    { text: "Outlet Store", value: "true" },
  ];

  const { fields, append, remove } = useFieldArray({
    control,
    name: "restaurants",
  });

  const watchedValues = useWatch({ control, name: "restaurants" });

  const handleDeleteRestaurant = (id, index) => {
    if (property) {
      const filteredRestaurants = property?.restaurants.filter(
        (item) => item.restaurant_id != id
      );
      const modifiedProperty = {
        ...property,
        restaurants: filteredRestaurants,
      };
      setProperty(modifiedProperty);
      if (id && id != 0) {
        deleteRestaurant(id).catch((err) => console.log(err));
      }
    }
    remove(index);
  };

  return (
    <div className="my-7" style={{ display: hideComponent ? "none" : "" }}>
      {fields.map((value, index) => (
        <div key={value.id}>
          <div className="text-right">
            <CloseOutlined
              className="cursor-pointer"
              onClick={() => handleDeleteRestaurant(value.restaurant_id, index)}
            />
          </div>
          <div className="px-4 mb-4 add_property_room_type_sel">
            <select
              className="border-0 w-100 bg-transparent"
              style={{ height: "40px" }}
              name={`restaurants[${index}].is_outlet`}
              ref={register()}
              defaultValue={value.is_outlet}
            >
              {selections.map((o, index) => (
                <option key={index} value={o.value}>
                  {o.text}
                </option>
              ))}
            </select>
          </div>
          {watchedValues[index]?.is_outlet === "true" ? (
            <OutletOption
              register={register}
              control={control}
              value={value}
              index={index}
              property={property}
              setProperty={setProperty}
            />
          ) : (
            <RestaurantOption
              register={register}
              control={control}
              value={value}
              index={index}
              property={property}
              setProperty={setProperty}
            />
          )}
          <hr style={{ borderWidth: 3 }} />
        </div>
      ))}
      <div className="mt-5">
        <button
          type="button"
          className="Button_hide"
          onClick={(e) => {
            e.preventDefault();
            append({
              room_name: "",
              num_rooms: "",
              bed_type: "twin",
              description: "",
            });
          }}
        >
          + Add Restaurant/Outlet Store
        </button>
      </div>
      <div className="text-center mt-7">
        <hr />
        <p>
          <strong className="mr-2">Important Note! </strong>
          Before saving changes, Please make sure all the images must be
          uploaded successfully.
        </p>
        <hr />
      </div>
      <div className="w-100 d-flex justify-content-end">
        <button
          className="Button_continue mt-7 mb-4 mr-5"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Saving..." : "Save"}
        </button>
        <button
          className="Button_continue mt-7 mb-4"
          type="submit"
          onClick={() => {
            setStyle(
              initialTab,
              initialTab,
              initialTab,
              initialTab,
              "#FFAC33",
              "location"
            );
            window.scrollTo(0, 0);
          }}
        >
          Save & Continue
        </button>
      </div>
    </div>
  );
};

export default Restaurant;
