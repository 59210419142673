import React from "react";
import ReactSelect from "react-select";

const DEFAULT_COMPONENTS = { IndicatorSeparator: () => null };

const DEFAULT_STYLES = {
  valueContainer: (base) => ({
    ...base,
    height: "40px",
  }),
  control: (base) => ({
    ...base,
    opacity: 0.5,
  }),
  option: (base) => ({
    ...base,
    wordWrap: "break-word",
    "&:hover": { wordWrap: "break-word" },
  }),
};

const Select = ({ styles = {}, components = {}, ...restProps }) => {
  return (
    <ReactSelect
      {...restProps}
      components={{ ...DEFAULT_COMPONENTS, ...components }}
      styles={{ ...DEFAULT_STYLES, ...styles }}
    />
  );
};

export default Select;
