import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import FileHandler, {
  useFileHandlerContext,
  DOCUMENT,
  DOCX,
} from "../../../../components/common/file-handler";
import { saveGlobalDocument } from "../../../../server/Filter";
import { convertFileToSFDTFormat } from "../../../../components/common/file-handler/utils/helpers";
import { fetchDocumentBlob } from "../utils/helpers";
import { DOCUMENT_DOWNLOAD_AS_OPTIONS } from "../utils/constants";
import {
  downloadFileWithFileObject,
  injectSpinner,
  removeSpinner,
} from "../../../../utils/helpers";

const DOCUMENT_EDITOR_ID = "document-viewer-docx";

const DocumentEditor = ({ fileURL, fileName, saveParams }) => {
  const alert = useAlert();
  const history = useHistory();
  const { state } = useLocation();
  const { documentRef } = useFileHandlerContext();
  const [isActionAllowed, setIsActionAllowed] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (fileURL) {
      injectSpinner(`#${DOCUMENT_EDITOR_ID}`);
      fetchDocumentBlob(fileURL)
        .then((blob) => {
          const file = new File([blob], `${fileName}.docx`);
          convertFileToSFDTFormat(file)
            .then((res) => {
              if (
                documentRef.current &&
                documentRef.current.documentEditor &&
                documentRef.current.documentEditor.open
              ) {
                documentRef.current.documentEditor.open(JSON.stringify(res));
                setIsActionAllowed(true);
              }
            })
            .catch(() => {
              setIsActionAllowed(false);
              alert.error(
                "Something went wrong in converting file. Please try again!!!"
              );
            })
            .finally(() => removeSpinner());
        })
        .catch(() => {
          setIsActionAllowed(false);
          alert.error(
            "Something went wrong in opening file. Please try again!!!"
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileURL]);

  const goBack = () => {
    if (state && state.returnData && state.returnData.url) {
      history.push(state.returnData.url, { ...state.returnData.state });
    } else {
      history.goBack();
    }
  };

  const onSaveButtonClick = async () => {
    if (documentRef.current) {
      try {
        setIsSaving(true);
        const blob = await documentRef.current.documentEditor.saveAsBlob(DOCX);
        const updatedFile = new File([blob], `${fileName}.docx`);
        await saveGlobalDocument({ file: updatedFile, ...saveParams });
        setIsSaving(false);
        alert.success("Document updated successfully");
        goBack();
      } catch (error) {
        setIsSaving(false);
        alert.error("Something went wrong in saving file");
      }
    }
  };

  const handleDownload = async (downloadFileType) => {
    if (documentRef.current) {
      try {
        const blob = await documentRef.current.documentEditor.saveAsBlob(
          downloadFileType
        );
        const file = new File([blob], `${fileName}.docx`);
        downloadFileWithFileObject(file);
      } catch (error) {
        setIsSaving(false);
        alert.error("Something went wrong in downloading file");
      }
    }
  };

  return (
    <FileHandler
      id={DOCUMENT_EDITOR_ID}
      fileType={DOCUMENT}
      disableActions={!isActionAllowed}
      downloadedFileName={fileName}
      downloadAsOptions={DOCUMENT_DOWNLOAD_AS_OPTIONS}
      onDownload={handleDownload}
      onSaveButtonClick={onSaveButtonClick}
      isSaving={isSaving}
      height={800}
    />
  );
};

DocumentEditor.propTypes = {
  fileURL: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  saveParams: PropTypes.object,
};

DocumentEditor.defaultProps = {
  fileName: "Document",
  saveParams: {},
};

export default DocumentEditor;
