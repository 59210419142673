import React, { useState } from "react";
import moment from "moment";
import { NavDropdown } from "react-bootstrap";
import Linkify from "react-linkify";
import { useEventCoordinationContext } from "../context/EventCoordinationContext";
import useToggle from "../../../../components/common/custom-hooks/useToggle";
import Modal from "../../../../components/common/CustomModal";
import SimpleTable from "../../../../shared/components/table/SimpleTable";
import RFPActionController from "../../common/RFPActionController";
import SiteInspectionForm from "./components/SiteInspectionForm";
import formatString from "../../../../utils/formatString";
import { SITE_INSPECTION_STATE_KEY } from "../utils/constants";
import { tableHeadings } from "./utils/headings";
import {
  RESCHEDULED,
  APPROVE_MODE,
  CANCEL_MODE,
  DECLINE_MODE,
  RESCHEDULE_MODE,
  APPROVED,
  DECLINED,
  REQUESTED,
  REQUESTED_AGAIN,
} from "./utils/contants";

const SiteInspection = () => {
  const {
    data: { [SITE_INSPECTION_STATE_KEY]: rfpSiteInspections },
  } = useEventCoordinationContext();
  const { isOpen: isFormModalOpen, toggle: toggleForm } = useToggle();
  const [selectedRecord, setSelectedRecord] = useState(null);

  const toggleFormModal = (selected) => {
    setSelectedRecord(selected);
    toggleForm();
  };

  return (
    <div>
      <Modal
        isOpen={isFormModalOpen}
        toggle={() => toggleFormModal(null)}
        heading="Site Inspection Form"
        bodyClassName="p-5"
      >
        <SiteInspectionForm
          toggle={() => toggleFormModal(null)}
          selected={selectedRecord}
        />
      </Modal>
      <div className="mb-6">
        <RFPActionController>
          <button
            className="transparent-button rfp-action-button"
            onClick={() => toggleFormModal(null)}
          >
            Request for Site Inspection
          </button>
        </RFPActionController>
      </div>
      <SimpleTable
        headings={tableHeadings}
        data={rfpSiteInspections}
        alternateText="There are no site inspection requests"
        sortByField="id"
        rounded
        renderCustomRow={(item) => (
          <tr key={item.id}>
            <td>
              <div className="d-flex justify-content-between">
                <span>{item.venue_name}</span>
                <span>
                  {item.status !== RESCHEDULED && (
                    <RFPActionController>
                      <NavDropdown className="custom-dropdown" title="">
                        {item.is_admin_request ? (
                          <React.Fragment>
                            {item.status !== APPROVED && (
                              <NavDropdown.Item
                                onClick={() =>
                                  toggleFormModal({
                                    ...item,
                                    mode: APPROVE_MODE,
                                  })
                                }
                              >
                                Approve Request
                              </NavDropdown.Item>
                            )}
                            {item.status !== DECLINED && (
                              <NavDropdown.Item
                                onClick={() =>
                                  toggleFormModal({
                                    ...item,
                                    mode: DECLINE_MODE,
                                  })
                                }
                              >
                                Decline Request
                              </NavDropdown.Item>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {/* Edit/Cancel only when status is requested or requested again */}
                            {[REQUESTED, REQUESTED_AGAIN].includes(
                              item.status
                            ) && (
                              <React.Fragment>
                                <NavDropdown.Item
                                  onClick={() => toggleFormModal(item)}
                                >
                                  Edit Request
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                  onClick={() =>
                                    toggleFormModal({
                                      ...item,
                                      mode: CANCEL_MODE,
                                    })
                                  }
                                >
                                  Cancel Request
                                </NavDropdown.Item>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                        <NavDropdown.Item
                          onClick={() =>
                            toggleFormModal({
                              ...item,
                              mode: RESCHEDULE_MODE,
                            })
                          }
                        >
                          Reschedule Request
                        </NavDropdown.Item>
                      </NavDropdown>
                    </RFPActionController>
                  )}
                </span>
              </div>
            </td>
            <td>
              <div>
                <span className="font-weight-bold">Date :</span>
                <span className="pl-3">
                  {moment(item.inspection_date).format("DD MMM YYYY")}
                </span>
              </div>
              <div>
                <span className="font-weight-bold">Time :</span>
                <span className="pl-3">
                  {moment(item.inspection_time, "HH:mm:ss").format("hh:mm A")}
                </span>
              </div>
              <div>
                <span className="font-weight-bold">Category :</span>
                <span className="pl-3">
                  {formatString(item.inspection_category)}
                </span>
              </div>
            </td>
            <td>{formatString(item.status)}</td>
            <td className="large-text-wrapper">
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {item?.comments}
              </Linkify>
            </td>
          </tr>
        )}
      />
    </div>
  );
};

export default SiteInspection;
