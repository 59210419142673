import React from "react";
import { Row, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { BsFillCaretRightFill, BsFillCaretLeftFill } from "react-icons/bs";
import { addToSaved, moveToCart, removeFromCart } from "../../server/Filter";
import { decreaseCartCount } from "../../redux/actions/cartActions";

const CartTable = (props) => {
  const {
    title,
    fieldName,
    items,
    pathname,
    searchQuery,
    updateCartData,
    updateSavedCartList,
    register,
    setValue,
    getValues,
  } = props;
  const alert = useAlert();
  const dispatch = useDispatch();

  const handleDelete = async (item) => {
    try {
      await removeFromCart(item.modelName, item.modelID);
      dispatch(decreaseCartCount());
      if (item.is_saved) {
        await updateSavedCartList();
      } else {
        await updateCartData();
      }
      alert.show(`${item.descriptionFields?.title} deleted successfully`, {
        type: "success",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async (item) => {
    try {
      if (item.is_saved) {
        await moveToCart(item.modelName, item.modelID);
        alert.show(
          `${item.descriptionFields?.title} successfully moved to cart`,
          {
            type: "success",
          }
        );
      } else {
        await addToSaved(item.modelName, item.modelID);
        alert.show(
          `${item.descriptionFields?.title} successfully added to "Saved For Later" list`,
          {
            type: "success",
          }
        );
      }
      updateCartData();
      updateSavedCartList();
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 8: // Backspace
      case 9: // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        let regex = new RegExp(/^[0-9]+$/);
        let key = event.key;
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
        }
        break;
    }
  };

  const handleInput = (event, index) => {
    let regex = new RegExp(/^[1-9][0-9]*$/);
    if (!regex.test(event.target.value)) {
      setValue(`${fieldName}[${index}].quantity`, "");
    }
  };

  return (
    <React.Fragment>
      <Row className="mt-10 mb-2">
        <h4>{title}</h4>
      </Row>
      <Row>
        <Table responsive className="cardTable">
          <thead>
            <tr>
              <th style={{ borderTopLeftRadius: "15px" }}>Items</th>
              <th>Description</th>
              <th style={{ borderTopRightRadius: "15px" }}>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {items?.map((item, index) => (
              <tr key={item.id}>
                <td className="firstColumn">
                  <div className="d-flex">
                    <div>
                      <img src={item.descriptionFields?.image} alt="" />
                    </div>
                    <div className="img-data">
                      <h5 className="text-bold">
                        {item.descriptionFields?.title}
                      </h5>
                      <span
                        className="pl-0 cursor-pointer"
                        onClick={() => handleSave(item)}
                      >
                        {item.is_saved ? "Move to cart |" : "Save for later |"}
                      </span>
                      {!item.is_saved && (
                        <Link
                          to={{
                            pathname: pathname,
                            search: searchQuery,
                            state: item,
                          }}
                        >
                          <span className="cursor-pointer"> Replace |</span>
                        </Link>
                      )}
                      <span
                        className="cursor-pointer"
                        onClick={() => handleDelete(item)}
                      >
                        Delete
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="rightside1">
                    {item.descriptionFields?.description &&
                      parse(item.descriptionFields.description.toString())}
                  </div>
                </td>
                <td className="firstColumn pt_Quantity">
                  <div className="number">
                    <input
                      ref={register}
                      name={`${fieldName}[${index}].id`}
                      type="number"
                      className="d-none"
                      defaultValue={item.id}
                    />
                    <div className="d-flex">
                      <input
                        ref={register}
                        name={`${fieldName}[${index}].quantity`}
                        onKeyDown={handleKeyDown}
                        onInput={(event) => handleInput(event, index)}
                        type="text"
                        min="1"
                        defaultValue={item.quantity}
                        autoComplete="off"
                        disabled={item.is_saved}
                      />
                      <div>
                        <BsFillCaretRightFill
                          className="cart-inc-btn"
                          role="button"
                          onClick={() => {
                            if (!item.is_saved) {
                              const previousValue = getValues(
                                `${fieldName}[${index}].quantity`
                              );

                              setValue(
                                `${fieldName}[${index}].quantity`,
                                parseInt(previousValue) + 1
                              );
                            }
                          }}
                        />
                        <BsFillCaretLeftFill
                          className="cart-dec-btn"
                          role="button"
                          onClick={() => {
                            if (!item.is_saved) {
                              const previousValue = parseInt(
                                getValues(`${fieldName}[${index}].quantity`)
                              );

                              if (previousValue > 1) {
                                setValue(
                                  `${fieldName}[${index}].quantity`,
                                  previousValue - 1
                                );
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </React.Fragment>
  );
};

export default CartTable;
