import { createContext, useContext } from "react";

export const VenueContext = createContext({
  searchedVenueText: "",
  isMapBtnDisable: false,
  searchParams: new URLSearchParams(),
  searchParamsObj: {},
  showMap: false,
  handleCloseMap: () => {},
  handleShowMap: () => {},
});

export const useGetVenuesContext = () => {
  const venueContext = useContext(VenueContext);

  return venueContext;
};
