import { CSV, PDF, DOCX } from "../../../../components/common/file-handler";

export const SPREADHEET_DOWNLOAD_AS_OPTIONS = [
  {
    label: "PDF (.pdf)",
    value: PDF,
  },
  {
    label: "CSV (.csv)",
    value: CSV,
  },
];

export const DOCUMENT_DOWNLOAD_AS_OPTIONS = [
  {
    label: "Word (.docx)",
    value: DOCX,
  },
];
