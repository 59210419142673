import { useEffect, useState } from "react";

export const useTransformSearchSuggestions = (data) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data?.status === "OK" && data?.predictions?.length > 0) {
      const modifiedOptions = data?.predictions?.map((item) => ({
        id: item?.place_id,
        label: item?.description,
        value: item?.description,
      }));

      setOptions(modifiedOptions);
    }
  }, [data]);

  return options;
};

export const useTransformCitySuggestions = (suggestions) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (suggestions?.length > 0) {
      const transformedData = suggestions.map((item) => ({
        id: item,
        label: item,
        value: item,
      }));

      setData(transformedData);
    } else {
      setData([]);
    }
  }, [suggestions]);

  return data;
};
