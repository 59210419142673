/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";

// STYLES & COMPONENTS
import "../../../styles/components/formik-field.scss";
import TextEditor from "./SimpleEditor";

function FormikModernTextEditor({
  name,
  className,
  onChange,
  onBlur,
  onEnter,
  disabled,
  isGrid,
  label,
  isRequired,
}) {
  const [editorValue, setEditorValue] = useState("");
  const [{ value: fieldValue }, { touched, error }, { setValue }] = useField(
    name ?? ""
  );

  const handleChange = useCallback(
    (value) => {
      setEditorValue(value);

      if (onChange) onChange(value);
    },
    [onChange]
  );

  const handleBlur = useCallback((editorState) => {
    setValue(editorState);
    if (onBlur) onBlur(name, editorState);
  }, []);

  const handleEnterClick = useCallback(async (event) => {
    event.preventDefault();
    await setValue(editorValue);
    onEnter(editorValue);
  }, []);

  const isErrorField = useMemo(() => !!(touched && error), [touched, error]);

  return (
    <div
      className={`field-wrapper ${isGrid ? "col-grid" : "col-full"} ${
        className ?? ""
      }`}
    >
      {label && (
        <div className="field-label">
          <p className={`${isRequired ? "required" : ""}`}>{label}</p>
        </div>
      )}

      <div className="input-wrapper">
        <TextEditor
          wrapperClassName={`${isErrorField ? "error-field" : ""} ${
            className ?? ""
          }`}
          value={fieldValue}
          onChange={disabled ? undefined : handleChange}
          onBlur={handleBlur}
          onEnter={onEnter ? handleEnterClick : undefined}
        />
      </div>

      {isErrorField && (
        <div className="error-wrapper">
          <p className="error">{error}</p>
        </div>
      )}
    </div>
  );
}

FormikModernTextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onEnter: PropTypes.func,
  disabled: PropTypes.bool,
  isGrid: PropTypes.bool,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
};

FormikModernTextEditor.defaultProps = {
  className: "",
  onChange: () => {},
  onBlur: () => {},
  onEnter: null,
  disabled: false,
  isGrid: false,
  label: "",
  isRequired: false,
};

export default FormikModernTextEditor;
