import React, { useEffect, useState, useContext } from "react";
import useSearchParams from "../../../components/common/custom-hooks/useSearchParams";
import useFetchRequest from "../../../components/common/custom-hooks/useFetchRequest";
import PageWrapper from "../../../components/common/PageWrapper";
import MiniLoader from "../../../components/common/MiniLoader";
import {
  getCongressCartItems,
  getEventCartItems,
} from "../../../server/Filter";

const DEFAULT_STEP = 1;

export const RFPFormContext = React.createContext({
  isLoading: true,
  setIsLoading: () => null,
  currentStep: DEFAULT_STEP,
  changeStep: () => null,
  resetSteps: () => null,
  cart: {
    type: null,
    event: {},
    congress: {},
  },
});

export const useRFPFormContext = () => useContext(RFPFormContext);

const RFPFormProvider = ({ children }) => {
  const { cart_type: cartType } = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(DEFAULT_STEP);
  const { isLoading: isLoadingEventCart, data: eventCart = {} } =
    useFetchRequest(getEventCartItems);
  const { isLoading: isLoadingCongressCart, data: congressCart = {} } =
    useFetchRequest(getCongressCartItems);

  // Dummy Loading for 1 second
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const changeStep = (stepNumber) => {
    setCurrentStep((prevState) =>
      stepNumber > prevState ? stepNumber : prevState
    );
  };

  const resetSteps = () => {
    setCurrentStep(1);
  };

  return (
    <RFPFormContext.Provider
      value={{
        isLoading: isLoadingEventCart || isLoadingCongressCart || isLoading,
        setIsLoading,
        currentStep,
        changeStep,
        resetSteps,
        cart: {
          type: cartType,
          event: eventCart.results || {},
          congress: congressCart.results || {},
        },
      }}
    >
      <PageWrapper bannerTitle="Request Form">
        <div className="w-100 d-flex flex-column align-items-center">
          {isLoading ? <MiniLoader /> : children}
        </div>
      </PageWrapper>
    </RFPFormContext.Provider>
  );
};

export default RFPFormProvider;
