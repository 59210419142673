import React, { useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import propTypes from "prop-types";

import Header from "../header";
import Footer from "../footer";

const PageWrapper = ({
  bannerImgSrc,
  bannerTitle,
  fluid,
  onBackButtonClick,
  children,
  endBtnLink,
  endBtnLabel,
}) => {
  const history = useHistory();

  const handleBack = () => {
    if (onBackButtonClick) onBackButtonClick();
    else history.goBack();
  };

  const isEndBtn = useMemo(
    () => endBtnLink && endBtnLabel,
    [endBtnLabel, endBtnLink]
  );

  return (
    <div className="page-main-wrapper">
      <Header />

      <Container
        className="event_banner px-0 position-relative"
        style={{
          backgroundImage: bannerImgSrc,
          backgroundColor: "rgb(37 50 190 / 50%)",
        }}
        fluid
      >
        <div className="text-blocks p-5">
          <p className="font-weight-bold text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-250 mb-0 custom-banner-title">
            {bannerTitle}
          </p>
        </div>
      </Container>

      <Container fluid={fluid} className="page-content-wrapper">
        <div
          className={`d-flex align-items-center justify-content-between ${
            isEndBtn ? "mb-3" : ""
          }`}
        >
          <button className="back-button" onClick={handleBack}>
            <img
              src="/assets/images/icon-back.png"
              className="icon_back mr-2"
              alt="Back Button"
            />
            Back
          </button>

          {isEndBtn && (
            <Link to={endBtnLink}>
              <button className="btn-main-primary md">{endBtnLabel}</button>
            </Link>
          )}
        </div>

        <div className="page-content-main">{children}</div>
      </Container>

      <Footer />
    </div>
  );
};

PageWrapper.propTypes = {
  bannerImgSrc: propTypes.string,
  bannerTitle: propTypes.string,
  fluid: propTypes.bool,
  onBackButtonClick: propTypes.func,
  children: propTypes.node,
  endBtnLink: propTypes.string,
  endBtnLabel: propTypes.string,
};

PageWrapper.defaultProps = {
  bannerImgSrc: 'url("/assets/pages/design/Untitled-4.png")',
  bannerTitle: "",
  fluid: false,
  onBackButtonClick: null,
  children: null,
  endBtnLink: "",
  endBtnLabel: "",
};

export default PageWrapper;
