import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Datepick from '../../generalinquiryes/form/datepick'; 
import Text from '../../generalinquiry/text';

const Meeting = ({ setTab }) => {
    const Meetings = [
        { for: "meeting_package", text: "Meeting package:", type: "text", name: "meeting_package", placeholder: "2 Coffee Breaks and 1 Lunch/Dinner" },
        { for: "preffered_time", text: "Preffered Time:", type: "text", name: "preffered_time", placeholder: "09:00 - 17:00" },
        { for: "attendees", text: "Number of Attendees:", type: "text", name: "attendees", placeholder: "100" },
        { for: "breakout_rooms", text: "Breakout rooms:", type: "text", name: "breakout_rooms", placeholder: "0" }
    ]
    const renderMeeting =  (Meeting, index) => {
        return(
            <React.Fragment key={index}>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor={Meeting.for}>{Meeting.text}</label>
                    </Col>
                    <Col className="px-0" md={6}>
                        <input 
                            name={Meeting.name} 
                            placeholder={Meeting.placeholder}
                            disabled = {( Meeting.placeholder == null ? null : 'disabled' )}
                        />
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </React.Fragment>
        )
    }
    const [showResults, setShowResults] = useState(false)
    const onClick = (e) => {
        e.preventDefault()
        setShowResults(true)
    }
    return (
        <React.Fragment>
            <Row className="form_main">
                <Col className="px-0" md={3} xs={6}>
                    <label htmlFor="meeting_date">Meeting Date:</label>
                    <img
                        style={{ marginRight: '5px' }}
                        className="calendar float-right"
                        src={"assets/images/download.jpg"}
                        alt="Not Found"
                    />
                </Col>
                <Col className="px-0" xs={6}>
                    <Datepick name="meeting_date" />
                </Col>
                <Col md={3}></Col>
            </Row>
            {Meetings.map(renderMeeting)}
            <Text heading="Requirements:" />
            <Row>
                <Col xs={12} className="px-0">
                    <div className="float-right">
                        <button 
                            className="Button" 
                            onClick={onClick}
                        >
                            Save
                        </button>
                    </div>
                </Col>
            </Row>
            { showResults ? 
                <Row id="results">
                    <Col xs={12} className="px-0">
                        <div className="float-right w-100">
                            <button className="Button gen_inquiry_func_buttons">
                                Edit
                            </button>                          
                            <button className="Button gen_inquiry_func_buttons">
                                Delete Service
                            </button>
                        </div>
                    </Col>
                </Row> 
                : null 
            }
        </React.Fragment>
    )
}

export default Meeting;
