import React from "react";
import { Row } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import propTypes from "prop-types";

import { getDummyCongressImage } from "../../../utils/helpers";

function Card({ items, className }) {
  return (
    <div className={`congress-travelinfo-card ${className}`}>
      <div className="img-wrapper">
        <img src={getDummyCongressImage(items?.logo)} width="100%" alt="Card" />
      </div>

      <div className="content-div">
        <Row>
          <div className="col-8 venue-name p-0">
            <h4 className="text-title m-0">{items.hotel_name}</h4>
          </div>

          <div className="col-4 venue-rating-wrapper pr-0">
            {/* change: show dynamic rating  */}
            <StarRatings
              rating={items.star_rating || 0}
              starSpacing="0px"
              starDimension="15px"
              starRatedColor="#F2BB13"
              starEmptyColor="white"
            />
          </div>
        </Row>

        <Row className="info-details">
          <p> {items.distance} miles from venue</p>
        </Row>
      </div>
    </div>
  );
}

Card.propTypes = {
  items: propTypes.object.isRequired,
  className: propTypes.string,
};

Card.defaultProps = {
  className: "",
};

export default Card;
