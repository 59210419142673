import React from "react";
import {
  InsertDriveFile,
  Image,
  PictureAsPdf,
  Edit,
  Folder,
  FolderOpen,
  Delete,
  Autorenew,
} from "@material-ui/icons";

const CustomIcons = {
  File: <InsertDriveFile className="mr-1" />,
  Image: <Image className="mr-1" />,
  PDF: <PictureAsPdf className="mr-1" />,
  Rename: <Edit className="mr-1" />,
  Folder: <Folder className="mr-1" />,
  FolderOpen: <FolderOpen className="mr-1" />,
  Delete: <Delete className="mr-1" />,
  Loading: <Autorenew className="mr-1" />,
};

export default CustomIcons;
