import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Individual from "../../quotegeneral/individual";
import Group from "../../quotegeneral/group";
import Logistic from "./logistic";
import LogisticAdmin from "./logisticadmin";
import "./index.scss";
import { create_general_request_form } from "../../../server/Filter";

const Forms = (props) => {
  const [formData, setFormData] = useState({
    
    airport_transfer: false,
    congress_transfer: false,
    dinner_transfer: false,
    on_site_assistance: false,
    category: "individual",
  });

  const _handleSubmit = (logisticsData) => {
    console.log({ ...formData, ...logisticsData });
    create_general_request_form({
      ...formData,
      ...logisticsData,
    }).then((res) => {
      console.log(res);
    });
    alert("submitted");
  };
  const forms = [
    {
      for: "account_name",
      text: "ACCOUNT NAME:",
      type: "text",
      name: "account_name",
      placeholder: localStorage.getItem("account_name") || " ",
    },
    {
      for: "email_address",
      text: "EMAIL ADDRESS:",
      type: "email",
      name: "email_address",
      placeholder: localStorage.getItem("user_email") || " ",
    },
    {
      for: "name",
      text: "NAME:",
      type: "text",
      name: "name",

      placeholder: localStorage.getItem("name") || " ",
    },
    {
      for: "contact_number",
      text: "CONTACT NUMBER:",
      type: "text",
      name: "contact_number",
      placeholder: localStorage.getItem("contact") || " ",
    },
    {
      for: "conference_name",
      text: "CONFERENCE NAME:",
      type: "text",
      name: "conference",
      // placeholder: "ID WEEK 2020",
    },
  ];
  const renderform = (form, index) => {
    return (
      <Row className="form_main" key={index}>
        <Col className="px-0" lg={3} md={4}>
          <label htmlFor={form.for}>{form.text}</label>
        </Col>
        <Col lg={6} md={8} className="px-0">
          <input
            type={form.type}
            name={form.name}
            placeholder={form.placeholder}
            onChange={(e) => {
              var obj = {};
              obj[form.name] = e.target.value;

              setFormData({ ...formData, ...obj });
            }}
            disabled = {( form.placeholder == null ? null : 'disabled' )}
          />
        </Col>
        <Col lg={3}></Col>
      </Row>
    );
  };
  const [option, setOption] = useState(1);
  return (
    <React.Fragment>
      <div onSubmit={() => console.log("submitted")} className="mt-12">
        {forms.map(renderform)}
        <Row className="form_main">
          <Col className="px-0" lg={3} md={4}>
            <label htmlFor="number_of_delegates">NUMBER OF DELEGATES:</label>
          </Col>
          <Col lg={6} md={8} className="px-0">
            <input
              type="text"
              name="number_of_delegates"
              placeholder=""
              value={option}
              onChange={(e) => {
                setOption(e.target.value)
                var obj = {};
                obj["number_of_delegates"] = e.target.value;
                setFormData({ ...formData, ...obj });
              }}
            />
          </Col>
          <Col lg={3}></Col>
        </Row>
        <Row className="form_main">
          <Col className="px-0" lg={3} md={4}>
            <label htmlFor="category">CATEGORY:</label>
          </Col>
          <Col lg={6} md={8} className="px-0">
            {option <= 9 ? <Individual /> : <Group />}
            {/* <select
                name="category"
                onChange={(e) => props.setInput("category", e.target.value)} disabled
              >
                <option>Individual</option>
                <option>Group</option>
              </select> */}
          </Col>
          <Col lg={3}></Col>
        </Row>
        {option <= 9 ? (
          <Logistic onSubmit={_handleSubmit} />
        ) : (
          <LogisticAdmin onSubmit={_handleSubmit} />
        )}
      </div>
    </React.Fragment>
  );
};

export default Forms;
