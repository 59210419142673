import * as yup from "yup";

export const overviewFormInitValues = {
  conference_name: "",
  registration_open_date: "",
  registration_close_date: "",
  target_audience: "",
  specialities: "",
  description: "",
  more_information_link: "",
  address: "",
  thumbnail: [],
  banner_image: [],
  credits: "",
  country: "",
};

export const overviewFormValSchema = yup.object().shape({
  address: yup.string().max(200, "Maximum 200 characters are allowed!"),
});

export const registrationFormInitObj = {
  registration_heading: "",
  registration_category: "",
  registration_end_date: "",
  registration_fee: "",
};

export const registrationFormInitValues = {
  more_information: "",
  conference_registration: [registrationFormInitObj],
};

export const registrationFormValSchema = yup.object().shape({
  conference_registration: yup.array().of(
    yup.object({
      registration_heading: yup.string().required("Required"),
      registration_category: yup.string().required("Required"),
      registration_end_date: yup.string().nullable().required("Required"),
      registration_fee: yup.number().required("Required"),
    })
  ),
});

export const hotelInitObj = {
  hotel_name: "",
  distance: "",
  star_rating: "",
  hotel_image: [],
  logo_2: null,
};

export const travelInfoFormInitValues = {
  hotels: [hotelInitObj],
};
