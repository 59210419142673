import React, { useState, useEffect } from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import CartTable from "./CartTable";
import CartItems from "./CartItems";
import {
  getEventCartItems,
  getEventCartSavedList,
  updateCart,
} from "../../server/Filter";
import "./index.scss";

function EventCart(props) {
  const alert = useAlert();
  const [cartData, setCartData] = useState({});
  const [savedCartList, setSavedCartList] = useState({});

  const updateCartData = () => {
    getEventCartItems()
      .then((data) => setCartData(data.results))
      .catch((err) => console.log(err));
  };

  const updateSavedCartList = () => {
    getEventCartSavedList()
      .then((data) => setSavedCartList(data.results))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    updateCartData();
    updateSavedCartList();
  }, []);

  const { handleSubmit, register, getValues, setValue } = useForm();

  const onFormSubmit = async (values) => {
    const data = Object.values(values)
      .filter((value) => Boolean(value))
      .flatMap((value) => value);

    try {
      await updateCart(data);
      alert.show("Cart Updated Successfully!", { type: "success" });
    } catch (err) {
      console.log(err);
      alert.show("Something went wrong. Please try again.", { type: "error" });
    }
  };

  return (
    <div style={{ minHeight: "200px" }}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Container className="CustomizeContainer">
          {cartData.count === 0 && (
            <div className="w-100 text-center">
              <h5>Cart is empty!</h5>
            </div>
          )}
          {cartData.destination_list?.length > 0 && (
            <CartItems
              title="Destinations"
              fieldName="destination_list"
              items={cartData.destination_list}
              pathname="/destinations"
              updateCartData={updateCartData}
              updateSavedCartList={updateSavedCartList}
              register={register}
            />
          )}
          {cartData.venue_sourcing_list?.length > 0 && (
            <CartItems
              title="Venue Sourcing"
              fieldName="venue_sourcing_list"
              items={cartData.venue_sourcing_list}
              pathname="/venue_sourcing/venues"
              updateCartData={updateCartData}
              updateSavedCartList={updateSavedCartList}
              register={register}
            />
          )}
          {cartData.av_production_package_list?.length > 0 && (
            <CartTable
              title="A/V Packages"
              fieldName="av_production_package_list"
              items={cartData.av_production_package_list}
              pathname="/av_production"
              searchQuery="?category=AvPackages"
              updateCartData={updateCartData}
              updateSavedCartList={updateSavedCartList}
              register={register}
              setValue={setValue}
              getValues={getValues}
            />
          )}
          {cartData.production_equipment_list?.length > 0 && (
            <CartTable
              title="A/V Equipments"
              fieldName="production_equipment_list"
              items={cartData.production_equipment_list}
              pathname="/av_production"
              updateCartData={updateCartData}
              updateSavedCartList={updateSavedCartList}
              register={register}
              setValue={setValue}
              getValues={getValues}
            />
          )}
          {cartData.branding_material_list?.length > 0 && (
            <CartTable
              title="Branding Materials"
              fieldName="branding_material_list"
              items={cartData.branding_material_list}
              pathname="/branding_materials"
              updateCartData={updateCartData}
              updateSavedCartList={updateSavedCartList}
              register={register}
              setValue={setValue}
              getValues={getValues}
            />
          )}
          {cartData.branding_package_list?.length > 0 && (
            <CartTable
              title="Branding Packages"
              fieldName="branding_package_list"
              items={cartData.branding_package_list}
              pathname="/branding_materials"
              searchQuery="?category=BrandingPackages"
              updateCartData={updateCartData}
              updateSavedCartList={updateSavedCartList}
              register={register}
              setValue={setValue}
              getValues={getValues}
            />
          )}
          {cartData.activity_list?.length > 0 && (
            <CartTable
              title="Team Building Activities"
              fieldName="activity_list"
              items={cartData.activity_list}
              pathname="/team/activities"
              updateCartData={updateCartData}
              updateSavedCartList={updateSavedCartList}
              register={register}
              setValue={setValue}
              getValues={getValues}
            />
          )}
          {cartData.stage_design_list?.length > 0 && (
            <CartTable
              title="Stage Designs"
              fieldName="stage_design_list"
              items={cartData.stage_design_list}
              pathname="/concept_designs/stage_designs"
              updateCartData={updateCartData}
              updateSavedCartList={updateSavedCartList}
              register={register}
              setValue={setValue}
              getValues={getValues}
            />
          )}
          {cartData.concept_desk_list?.length > 0 && (
            <CartTable
              title="Hospitality Desk Designs"
              fieldName="concept_desk_list"
              items={cartData.concept_desk_list}
              pathname="/concept_designs/hospitality_desk_designs"
              updateCartData={updateCartData}
              updateSavedCartList={updateSavedCartList}
              register={register}
              setValue={setValue}
              getValues={getValues}
            />
          )}
          {cartData.venue_design_list?.length > 0 && (
            <CartTable
              title="Venue Designs"
              fieldName="venue_design_list"
              items={cartData.venue_design_list}
              pathname="/concept_designs/venue_concept_designs"
              updateCartData={updateCartData}
              updateSavedCartList={updateSavedCartList}
              register={register}
              setValue={setValue}
              getValues={getValues}
            />
          )}
          {cartData.photo_booth_list?.length > 0 && (
            <CartTable
              title="Photo Booths"
              fieldName="photo_booth_list"
              items={cartData.photo_booth_list}
              pathname="/concept_designs/photo_booth_ideas"
              updateCartData={updateCartData}
              updateSavedCartList={updateSavedCartList}
              register={register}
              setValue={setValue}
              getValues={getValues}
            />
          )}
          {cartData.count > 0 && (
            <Row className="alignment d-flex mt-7">
              <Link to={{ pathname: "/rfp", search: "cart_type=Events" }}>
                <Button className="tableButton1 btnCart">Request to RFP</Button>
              </Link>
              <Button type="submit" className="tableButton1 btnCart">
                Update Cart
              </Button>
            </Row>
          )}
        </Container>
      </form>
      <Container className="CustomizeContainer">
        {savedCartList.count > 0 && (
          <Row className="mt-10 mb-0">
            <h3 className="mb-0">Saved For Later</h3>
          </Row>
        )}
        {savedCartList.destination_list?.length > 0 && (
          <CartItems
            title="Destinations"
            items={savedCartList.destination_list}
            pathname="/destinations"
            updateCartData={updateCartData}
            updateSavedCartList={updateSavedCartList}
          />
        )}
        {savedCartList.venue_sourcing_list?.length > 0 && (
          <CartItems
            title="Venue Sourcing"
            items={savedCartList.venue_sourcing_list}
            pathname="/venue_sourcing"
            updateCartData={updateCartData}
            updateSavedCartList={updateSavedCartList}
          />
        )}
        {savedCartList.av_production_package_list?.length > 0 && (
          <CartTable
            title="A/V Packages"
            items={savedCartList.av_production_package_list}
            pathname="/av_production?category=AvPackages"
            updateCartData={updateCartData}
            updateSavedCartList={updateSavedCartList}
          />
        )}
        {savedCartList.production_equipment_list?.length > 0 && (
          <CartTable
            title="A/V Equipments"
            fieldName="production_equipment_list"
            items={savedCartList.production_equipment_list}
            pathname="/av_production"
            updateCartData={updateCartData}
            updateSavedCartList={updateSavedCartList}
          />
        )}
        {savedCartList.branding_material_list?.length > 0 && (
          <CartTable
            title="Branding Materials"
            items={savedCartList.branding_material_list}
            pathname="/branding_materials"
            updateCartData={updateCartData}
            updateSavedCartList={updateSavedCartList}
          />
        )}
        {savedCartList.branding_package_list?.length > 0 && (
          <CartTable
            title="Branding Packages"
            fieldName="branding_package_list"
            items={savedCartList.branding_package_list}
            pathname="/branding_materials?category=BrandingPackages"
            updateCartData={updateCartData}
            updateSavedCartList={updateSavedCartList}
          />
        )}
        {savedCartList.activity_list?.length > 0 && (
          <CartTable
            title="Team Building Activities"
            items={savedCartList.activity_list}
            pathname="/team/activities"
            updateCartData={updateCartData}
            updateSavedCartList={updateSavedCartList}
          />
        )}
        {savedCartList.stage_design_list?.length > 0 && (
          <CartTable
            title="Stage Designs"
            items={savedCartList.stage_design_list}
            pathname="/concept_designs/stage_designs"
            updateCartData={updateCartData}
            updateSavedCartList={updateSavedCartList}
          />
        )}
        {savedCartList.concept_desk_list?.length > 0 && (
          <CartTable
            title="Hospitality Desk Designs"
            items={savedCartList.concept_desk_list}
            pathname="/concept_designs/hospitality_desk_designs"
            updateCartData={updateCartData}
            updateSavedCartList={updateSavedCartList}
          />
        )}
        {savedCartList.venue_design_list?.length > 0 && (
          <CartTable
            title="Venue Designs"
            items={savedCartList.venue_design_list}
            pathname="/concept_designs/venue_concept_designs"
            updateCartData={updateCartData}
            updateSavedCartList={updateSavedCartList}
          />
        )}
        {savedCartList.photo_booth_list?.length > 0 && (
          <CartTable
            title="Photo Booths"
            items={savedCartList.photo_booth_list}
            pathname="/concept_designs/photo_booth_ideas"
            updateCartData={updateCartData}
            updateSavedCartList={updateSavedCartList}
          />
        )}
      </Container>
    </div>
  );
}

export default EventCart;
