import React from "react";
import { Formik, Form } from "formik";
import FormikField from "../../../shared/components/form/FormikField";
import LoadingButton from "../../../components/common/LoadingButton";
import FormFieldWrapper from "./FormFieldWrapper";
import { changePassword } from "../../../server/Filter";
import { changePasswordFormValidationSchema } from "../utils/validationSchema";
import { changePasswordFormInitialValues } from "../utils/initialValues";

const ChangePassword = () => {
  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, resetForm }
  ) => {
    try {
      await changePassword(values);
      resetForm();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrors(error.response.data);
      } else {
        alert.error("Something went wrong");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={changePasswordFormInitialValues}
      validationSchema={changePasswordFormValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="custom-user-form">
          <FormFieldWrapper label="Password" required>
            <FormikField name="password" type="password" />
          </FormFieldWrapper>
          <FormFieldWrapper label="Confirm Password" required>
            <FormikField name="confirm_password" type="password" />
          </FormFieldWrapper>
          <LoadingButton
            type="submit"
            className="button primary-button"
            isLoading={isSubmitting}
            loaderColor="#fff"
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePassword;
