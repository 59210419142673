import React from "react";
import { FieldArray } from "formik";
import { Table, Button } from "react-bootstrap";
import { MdRemoveCircle } from "react-icons/md";
import { useEventCoordinationContext } from "../../../context/EventCoordinationContext";
import FormikField from "../../../../../../shared/components/form/FormikField";
import ServiceQuestionController from "./ServiceQuestionController";
import TableData from "./TableData";
import { RFP_DETAILS_STATE_KEY } from "../../../utils/constants";
import { singleFlightTicketInitialValues } from "../../utils/initialValues";

const FlightTicket = () => {
  const {
    [RFP_DETAILS_STATE_KEY]: { isEditing },
  } = useEventCoordinationContext();

  return (
    <ServiceQuestionController
      questionText="Do you require flight ticket?"
      flag="flight_ticket_flag"
    >
      <FieldArray
        name="rfpflightticket"
        render={({ form, push, remove }) => {
          return (
            <Table className="rfp-table">
              <thead>
                <tr>
                  <td width="50%">Travelling From</td>
                  <td width="50%">Number of Passengers</td>
                </tr>
              </thead>
              <tbody>
                <React.Fragment>
                  {form.values.rfpflightticket.map((item, index) => (
                    <tr key={`flight-ticket-${index}`}>
                      <TableData>
                        <FormikField
                          name={`rfpflightticket[${index}].departure_place`}
                          placeholder="Travelling from"
                          disabled={!isEditing}
                        />
                      </TableData>
                      <TableData>
                        <FormikField
                          type="number"
                          name={`rfpflightticket[${index}].number_of_pax`}
                          placeholder="Number of passengers"
                          disabled={!isEditing}
                        />
                      </TableData>
                      {form.values.rfpflightticket.length > 1 && isEditing && (
                        <MdRemoveCircle
                          size={20}
                          className="remove-icon table-rows-remove-icon"
                          onClick={() => remove(index)}
                        />
                      )}
                    </tr>
                  ))}
                  <tr className="d-flex">
                    {isEditing && (
                      <Button
                        className="mt-5 button small-button"
                        onClick={() => push(singleFlightTicketInitialValues)}
                      >
                        + Add Flight Ticket
                      </Button>
                    )}
                  </tr>
                </React.Fragment>
              </tbody>
            </Table>
          );
        }}
      />
    </ServiceQuestionController>
  );
};

export default FlightTicket;
