import React, { useEffect, useRef } from "react";
import { useAlert } from "react-alert";
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import FormikField from "../../../shared/components/form/FormikField";
import FormikSelect from "../../../shared/components/form/FormikSelect";
import LoadingButton from "../../../components/common/LoadingButton";
import { useManageAccountContext } from "../context/ManageAccountContext";
import FormFieldWrapper from "./FormFieldWrapper";
import { createUser, updateUser } from "../../../server/Filter";
import { userFormValidationSchema } from "../utils/validationSchema";
import { userFormInitialValues } from "../utils/initialValues";
import { userRoleOptions } from "../utils/constants";

const UserForm = ({ selected }) => {
  const formikRef = useRef();
  const alert = useAlert();
  const isUpdatingUser = !!selected;
  const { user: loggedInUser = {} } = useSelector((state) => state.auth);
  const { switchToAllUsersView } = useManageAccountContext();

  // For setting logged in user name
  useEffect(() => {
    if (formikRef.current) {
      const { setFieldValue } = formikRef.current;
      setFieldValue("account_name", loggedInUser.profile.account);
    }
  }, [loggedInUser.profile]);

  // For updating selected user values
  useEffect(() => {
    if (formikRef.current && selected) {
      const { values, setValues } = formikRef.current;
      setValues({ ...values, ...selected });
    }
  }, [selected]);

  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, resetForm }
  ) => {
    try {
      if (isUpdatingUser) {
        await updateUser({ id: selected.id, ...values, password: undefined });
        alert.success("User updated successfully");
      } else {
        await createUser(values);
        alert.success("User added successfully");
      }
      resetForm();
      switchToAllUsersView();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrors(error.response.data);
      } else {
        alert.error("Something went wrong");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={userFormInitialValues}
      validationSchema={userFormValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="custom-user-form">
          <FormFieldWrapper label="Account Name">
            <FormikField name="account_name" disabled />
          </FormFieldWrapper>
          <FormFieldWrapper label="Username" required>
            <FormikField name="username" />
          </FormFieldWrapper>
          <FormFieldWrapper label="Email" required>
            <FormikField name="email" />
          </FormFieldWrapper>
          <FormFieldWrapper label="Full Name" required>
            <FormikField name="full_name" />
          </FormFieldWrapper>
          <FormFieldWrapper label="Profession" required>
            <FormikField name="profession" />
          </FormFieldWrapper>
          <FormFieldWrapper label="Contact Number" required>
            <FormikField name="contact" />
          </FormFieldWrapper>
          {/* Show password filed just while adding user */}
          {!isUpdatingUser && (
            <FormFieldWrapper label="Password" required>
              <FormikField name="password" type="password" />
            </FormFieldWrapper>
          )}
          <FormFieldWrapper label="Role" required>
            <FormikSelect
              name="role"
              options={userRoleOptions}
              placeholder="Select Role"
            />
          </FormFieldWrapper>
          <FormFieldWrapper label="Comments" required>
            <FormikField name="comment" rows={5} textArea />
          </FormFieldWrapper>
          <LoadingButton
            type="submit"
            className="button primary-button"
            isLoading={isSubmitting}
            loaderColor="#fff"
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
