import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  Card,
  Button,
  Form,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import { Link, useLocation, useHistory } from "react-router-dom";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Header from "../../components/header";
import FilterPrinting from "../../components/filter_printing";
import Footer from "../../components/footer";
import { Radio, RadioGroup } from "react-radio-group";
import "./index.scss";

import Gift from "./Gift";
import { getGiftCategories, getGifts } from "../../server/Filter";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Gifts = () => {
  const query = useQuery();
  const history = useHistory();

  const [giftCategories, setGiftCategories] = useState([]);
  const [gifts, setGifts] = useState([]);

  useEffect(() => {
    getGiftCategories()
      .then((data) => setGiftCategories(data))
      .catch((err) => console.log(err));
  }, []);

  const category = query.get("category");

  useEffect(() => {
    getGifts(category)
      .then((data) => setGifts(data))
      .catch((err) => console.log(err));
  }, [category]);

  const giftsArray = gifts.map((gift) => ({
    heading: gift.gift_name,
    code: gift.code,
    price: gift.price,
    description: gift.description,
    sliderImages: gift.slider_images,
  }));

  const handleCategoryClick = (categoryName) => {
    history.push({
      pathname: "/gifts",
      search: `category=${categoryName}`,
    });
  };

  const EventsDynamics = [
    { event_name: "Product Launch", date: "12-13 September" },
    { event_name: "Standalone Meeting Barcelona", date: "10-16 October" },
    { event_name: "Standalone Meeting Greece", date: "10-16 November" },
  ];

  const renderEventsDynamic = (EventsDynamic, index) => {
    return (
      <div className="d-flex" key={index}>
        <div className="mb-8 px-1 border-radio-darkBlue">
          <Radio value={EventsDynamic.event_name} />
        </div>
        <label className="ml-5 mb-0">
          Event Name: {EventsDynamic.event_name}
          <br />
          Date: {EventsDynamic.date}
        </label>
      </div>
    );
  };

  const [display, setDisplay] = useState(false);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);

  useEffect(
    () => {
      setResults(
        EventsDynamics.filter((result) => {
          return result.event_name.toLowerCase().includes(search.toLowerCase());
        })
      );
    },
    // eslint-disable-next-line
    [search]
  );

  const [arrow, setArrow] = useState("");

  const giftsArrayPerPage = 6;
  const [activePage, setCurrentPage] = useState(1);
  const indexOfLastgift = activePage * giftsArrayPerPage;
  const indexOfFirstgift = indexOfLastgift - giftsArrayPerPage;
  const currentgiftsArray = giftsArray.slice(indexOfFirstgift, indexOfLastgift);

  const handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    setCurrentPage(pageNumber);
  };

  return (
    <React.Fragment>
      <Header />
      <FilterPrinting />
      <Container fluid className="fluid_branding px-0">
        <Row className="inner_width_div">
          <Col md={3} sm={4} className="pl-sm-6 pl-5">
            <div className="border-top-default pt-3">
              <button
                className="Button_hide"
                style={{ fontWeight: category ? "" : "bold" }}
                onClick={() => history.push("/gifts")}
              >
                All
              </button>
              <br />
              <Accordion defaultActiveKey="0" className="mb-3">
                <Card className="border-0">
                  <Card.Header className="border-0 p-0 bg-transparent m-0">
                    <Accordion.Toggle
                      as={Button}
                      className="Button_hide text-lg-100 text-sm-75 text-decoration-none shadow-none"
                      variant="link"
                      eventKey="0"
                      onClick={() =>
                        setArrow((arrow) => (arrow === "" ? "ChangeArrow" : ""))
                      }
                    >
                      Souvenirs & Gifts Category
                      {arrow === "ChangeArrow" ? (
                        <ArrowDropDownIcon />
                      ) : (
                        <ArrowDropUpIcon />
                      )}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="pb-0">
                      {giftCategories.map((giftCategory) => (
                        <p
                          className="cursor-pointer"
                          style={{
                            fontWeight:
                              category === giftCategory.category_name
                                ? "bold"
                                : "",
                          }}
                          key={giftCategory.id}
                          onClick={() =>
                            handleCategoryClick(giftCategory.category_name)
                          }
                        >
                          {giftCategory.category_name}
                        </p>
                      ))}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </Col>
          <Col md={9} sm={8} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
            <Row>
              <Col md={5}></Col>
              <Col md={7}>
                {display === true ? (
                  <Form>
                    <input
                      type="text"
                      name="Conference"
                      className="w-100 my-5 pl-3 border-0 float-left rounded-5"
                      style={{
                        maxWidth: "400px",
                        height: "40px",
                        boxShadow: "0 0px 5px 3px #DDD",
                      }}
                      placeholder="Search Event name"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div
                      className="w-100 overflowY-scroll"
                      style={{ maxWidth: "400px", height: "140px" }}
                    >
                      <RadioGroup name="event_radio">
                        {results.map(renderEventsDynamic)}
                      </RadioGroup>
                    </div>
                    <div
                      className="text-center w-100"
                      style={{ maxWidth: "400px" }}
                    >
                      <Link to="/giftRFP">
                        <Button className="Button">Continue</Button>
                      </Link>
                    </div>
                  </Form>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            {giftsArray.length > 0 ? (
              currentgiftsArray.map((gift) => (
                <Gift gift={gift} setDisplay={setDisplay} />
              ))
            ) : (
              <p>No gifts or souvenirs found!</p>
            )}
            {giftsArray.length > 6 ? (
              <div className="paginations">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={6}
                  totalItemsCount={giftsArray.length}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Gifts;
