import { createContext, useContext } from "react";

export const VenueDetailContext = createContext({
  venueId: null,
});

export const useGetVenueDetailContext = () => {
  const contextData = useContext(VenueDetailContext);

  return contextData;
};
