import http from "./httpService";

// CONGRESS ENDPOINTS
export const getNonVerifiedCongress = async (params) => {
  const response = await http.get(`/search_conference/`, {
    params: {
      approved: false,
      page: params?.page || undefined,
      limit: params?.limit || undefined,
    },
    headers: http.getTokenHeaders(),
  });

  return response.data;
};
export const getVerifiedCongress = async (params) => {
  const response = await http.get(`/search_conference/`, {
    params: {
      approved: true,
      page: params?.page || undefined,
      limit: params?.limit || undefined,
    },
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const unverifyMultipleCongress = async (arr) => {
  const response = await http.put(
    `/conferences/bulk-manage/`,
    {
      ids: arr,
      is_approved: false,
    },
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const verifyMultipleCongress = async (arr) => {
  const response = await http.put(
    `/conferences/bulk-manage/`,
    {
      ids: arr,
      is_approved: true,
    },
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const deleteMultipleCongress = async (arr) => {
  const response = await http.post(
    `/conferences/bulk-manage/`,
    {
      ids: arr,
    },
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};
