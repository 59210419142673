import React from "react";
import { Modal, Row } from "react-bootstrap";

const ConfirmationPopup = ({
  isOpen,
  toggle,
  heading = "Confirmation!!!",
  message,
  onConfirm,
  onCancel,
  toggleOnConfirm = true,
  toggleOnCancel = true,
  passEventOnSuccess = false,
  passEventOnCancel = false,
}) => {
  const handleConfirm = (event) => {
    if (toggleOnConfirm) toggle();
    if (onConfirm) onConfirm(passEventOnSuccess ? event : undefined);
  };

  const handleCancel = (event) => {
    if (toggleOnCancel) toggle();
    if (onCancel) onCancel(passEventOnCancel ? event : undefined);
  };

  return (
    <Modal show={isOpen} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-start mb-10">
          <p className="text-100 mb-0">{message}</p>
        </Row>
        <Row className="mt-10">
          <button className="primary-button px-7 py-1" onClick={handleConfirm}>
            Yes
          </button>
          <button
            className="transparent-button ml-3 px-3 py-1"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationPopup;
