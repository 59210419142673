import React from "react";
import moment from "moment";
import { ReadyState } from "react-use-websocket";

export const transformSingleMessage = (message) => {
  return {
    ...message,
    isMyMessage: !message.is_admin_message || false,
    content: <span dangerouslySetInnerHTML={{ __html: message.content }} />,
    time: moment(message.created_at).format("YYYY-MM-DD hh:mm A"),
  };
};

export const transformMessages = (messages) => {
  return messages.map((item) => transformSingleMessage(item));
};

export const getConnectionColor = (status) => {
  if (status === ReadyState.OPEN) return "#04D976";
  if (status === ReadyState.CONNECTING) return "#FFA500";
  if (status === ReadyState.UNINSTANTIATED) return "#E5E5E5";
  return "#F70000";
};
