/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useCallback } from "react";
import { NavDropdown } from "react-bootstrap";
import { useAlert } from "react-alert";
import { FaCheckCircle, FaMinusCircle, FaFileInvoice } from "react-icons/fa";
import useConfirmAlert from "../../../../../components/common/custom-hooks/useConfirmAlert";
import useCustomReasonModal from "../../../../../components/common/custom-hooks/useCustomReasonModal";
import useToggle from "../../../../../components/common/custom-hooks/useToggle";
import SpinningCircles from "../../../../../shared/svg-icons/SpinningCircles";
import { DocumentViewerPopup } from "../../../../../components/common/document-viewer";
import Tooltip from "../../../../../components/common/CustomTooltip";
import { useEventCloseOutContext } from "../../context/EventCloseOutContext";
import RFPActionController from "../../../common/RFPActionController";
import { updateRFPProInvoiceStatus } from "../../../../../server/Filter";
import {
  ACCOUNTING_APPROVED,
  ACCOUNTING_CANCELLED,
  APPROVED,
  INVOICED,
  REQUESTED_CHANGES,
} from "../utils/constants";

const ProformaInvoiceActionCell = ({ proInvoice }) => {
  const alert = useAlert();
  const confirm = useConfirmAlert();
  const reason = useCustomReasonModal();
  const {
    modifiers: { updateRFPProInvoices },
  } = useEventCloseOutContext();
  const { isOpen: isPreviewModalOpen, toggle: togglePreviewModal } =
    useToggle();
  const [isLoading, setIsLoading] = useState(false);

  const {
    isProformaInvoiceApproved,
    isProformaInvoiceChangesRequested,
    isProformaInvoiced,
  } = useMemo(
    () => ({
      isProformaInvoiceApproved: proInvoice.operation_status === APPROVED,
      isProformaInvoiceChangesRequested:
        proInvoice.operation_status === REQUESTED_CHANGES,
      isProformaInvoiced: proInvoice.operation_status === INVOICED,
    }),
    [proInvoice]
  );

  const handleApproveProformaInvoice = useCallback(async () => {
    try {
      await confirm({
        message: "Are you sure you want to approve this proforma invoice?",
      });
      try {
        setIsLoading(true);
        await updateRFPProInvoiceStatus({
          proInvoiceID: proInvoice.id,
          rfp_id: proInvoice.rfp_id,
          pro_invoice_num: proInvoice.pro_invoice_formatted_number,
          pro_invoice_name: proInvoice.pro_invoice_name,
          pro_invoice: {
            status: ACCOUNTING_APPROVED,
            operation_status: APPROVED,
            operation_cancel_reason: "",
          },
        });
        alert.success("Proforma Invoice approved successfully");
        updateRFPProInvoices();
      } catch (error) {
        setIsLoading(false);
        alert.error("Something went wrong in approving proforma invoice");
      }
    } finally {
      setIsLoading(false);
    }
  }, [proInvoice]);

  const handleCancelProformaInvoice = useCallback(async () => {
    try {
      const providedReason = await reason();
      try {
        setIsLoading(true);
        await updateRFPProInvoiceStatus({
          proInvoiceID: proInvoice.id,
          rfp_id: proInvoice.rfp_id,
          pro_invoice_num: proInvoice.pro_invoice_formatted_number,
          pro_invoice_name: proInvoice.pro_invoice_name,
          pro_invoice: {
            status: ACCOUNTING_CANCELLED,
            operation_status: REQUESTED_CHANGES,
            operation_cancel_reason: providedReason,
          },
        });
        alert.success("Changes requested successfully");
        updateRFPProInvoices();
      } catch (error) {
        setIsLoading(false);
        alert.error("Something went wrong in requesting changes");
      }
    } finally {
      setIsLoading(false);
    }
  }, [proInvoice]);

  return (
    <React.Fragment>
      <DocumentViewerPopup
        isOpen={isPreviewModalOpen}
        toggle={togglePreviewModal}
        fileURL={proInvoice.pro_invoice_file_link}
        fileName={proInvoice.pro_invoice_name}
      />
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center gap-1">
          {isLoading ? (
            <SpinningCircles />
          ) : (
            <React.Fragment>
              {isProformaInvoiceApproved && (
                <Tooltip text="Approved">
                  <FaCheckCircle size={18} className="text-success" />
                </Tooltip>
              )}
              {isProformaInvoiced && (
                <Tooltip text="Invoiced">
                  <FaFileInvoice size={18} className="text-secondary" />
                </Tooltip>
              )}
              {isProformaInvoiceChangesRequested && (
                <Tooltip text="Requested Changes">
                  <FaMinusCircle size={18} className="text-secondary" />
                </Tooltip>
              )}
            </React.Fragment>
          )}
          <span
            className="text-primary cursor-pointer"
            onClick={togglePreviewModal}
          >
            {proInvoice.pro_invoice_formatted_number}
          </span>
        </div>
        {/* Menu */}
        {!isProformaInvoiced && (
          <RFPActionController>
            <NavDropdown className="custom-dropdown" title="">
              {!isProformaInvoiceApproved && (
                <NavDropdown.Item onClick={handleApproveProformaInvoice}>
                  Approve Proforma Invoice
                </NavDropdown.Item>
              )}
              {!isProformaInvoiceChangesRequested && (
                <NavDropdown.Item onClick={handleCancelProformaInvoice}>
                  Request Changes
                </NavDropdown.Item>
              )}
            </NavDropdown>
          </RFPActionController>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProformaInvoiceActionCell;
