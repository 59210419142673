/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useField } from "formik";
import propTypes from "prop-types";

// STYLES & COMPONENTS & SERVICES
import http from "../../../../services/httpService";
import "./index.scss";
import FileItem from "./components/FileItem";

function FormikModernFileField({
  name,
  onChange,
  multiple,
  disable,
  uploadURL,
  deleteURL,
}) {
  const inputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [field, meta, helpers] = useField(name);

  const { value, onBlur } = field;
  const { error, touched } = meta;
  const { setValue, setTouched } = helpers;

  useEffect(() => {
    if (value?.length > 0) {
      setFiles(value);
    }
  }, [value]);

  const handleClick = useCallback(() => {
    if (inputRef) {
      inputRef.current.click();
    }
  }, []);

  const handleChange = useCallback(
    async (e) => {
      const filesArray = [...e.target.files];

      if (filesArray.length > 0) {
        const loadingFiles = filesArray.map((file) => ({
          fileName: file.name,
          url: URL.createObjectURL(file),
          isLoading: true,
        }));

        setFiles([...files, ...loadingFiles]);

        const uploadPromises = filesArray.map(async (file, index) => {
          const formData = new FormData();
          formData.append("image_content", file);

          try {
            const uploadResp = await http.post(uploadURL, formData);

            const { data } = uploadResp;
            return {
              fileName: data?.file_name || "",
              image_name: data?.image_name || "",
              url: URL.createObjectURL(file),
            };
          } catch (error) {
            return null;
          }
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        const filteredFiles = uploadedFiles.filter((file) => file !== null);
        const newFiles = [...files, ...filteredFiles];

        setFiles(newFiles);
        setValue(newFiles);
      }

      setTouched(true);

      if (onChange) onChange(filesArray, name);
    },
    [value, files]
  );

  const handleDelete = useCallback(
    async (fileObj) => {
      if (!fileObj?.fileName) return;

      const deleteResp = await http.delete(deleteURL, {
        data: { image_name: fileObj.fileName, id: fileObj?.id || undefined },
      });

      if (deleteResp?.status === 202) {
        const newFiles = files.filter(
          (file) => file.fileName !== fileObj.fileName
        );

        setFiles(newFiles);
        setValue(newFiles);
      }
    },
    [files]
  );

  return (
    <div className="formik-file-container">
      {files.length === 0 && (
        <div
          className={`formik-box-wrapper ${disable ? "disabled" : ""} ${
            !multiple ? "small" : ""
          }`}
          onClick={!disable ? handleClick : undefined}
        >
          <p>
            Please upload files upto 15MB. Also, Image file type should be JPG,
            JPEG or PNG.
          </p>

          <button type="button" className="btn-main-primary">
            Choose File(s)
          </button>
        </div>
      )}

      {files?.length > 0 && (
        <div className={`files-container ${!multiple ? "small" : ""}`}>
          <div className="files-wrapper">
            {files.map((file, index) => (
              <FileItem
                file={file}
                index={index}
                key={index}
                isLoading={file?.isLoading}
                handleDelete={handleDelete}
              />
            ))}
          </div>

          {multiple && (
            <button
              type="button"
              className="btn-main-primary"
              onClick={handleClick}
            >
              Add File(s)
            </button>
          )}
        </div>
      )}

      <input
        ref={inputRef}
        type="file"
        name={name}
        hidden
        multiple={multiple}
        onChange={handleChange}
        onBlur={onBlur}
      />

      {touched && error && <div className="formik-error">{error}</div>}
    </div>
  );
}

FormikModernFileField.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func,
  multiple: propTypes.bool,
  disable: propTypes.bool,
  uploadURL: propTypes.string.isRequired,
  deleteURL: propTypes.string.isRequired,
};

FormikModernFileField.defaultProps = {
  onChange: () => {},
  multiple: true,
  disable: false,
};

export default memo(FormikModernFileField);
