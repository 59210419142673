import React, { useEffect, useState } from "react";
import { Image, Modal } from "react-bootstrap";
import Slider from "react-slick";
import PropTypes from "prop-types";

// COMPONENTS & STYLES
import NextArrow from "./components/NextArrow";
import PrevArrow from "./components/PrevArrow";
import "./index.scss";

function ModalCarousel({
  isOpen,
  toggle,
  sliderImages,
  selectedSlide,
  isSmallSlider,
}) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const mainSliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const gallarySliderSettings = {
    slidesToShow: sliderImages?.length < 4 ? sliderImages?.length : 4,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    arrows: false,
    centerMode: false,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
  };

  return (
    <Modal
      show={isOpen}
      onHide={toggle}
      animation={false}
      keyboard={true}
      className="venue_loc_modal_gallery central_modal"
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <div className="modalSliderContainer">
          <Slider
            {...mainSliderSettings}
            ref={(slider) => slider && setSlider1(slider)}
            asNavFor={nav2}
          >
            {sliderImages?.map((item) => (
              <div className="slick-slide" key={item.image}>
                <Image
                  className={`slider-main-image ${
                    isSmallSlider ? "has-small" : ""
                  } w-100`}
                  src={item.smaller_image || item.image}
                  fluid
                />
              </div>
            ))}
          </Slider>
        </div>

        {isSmallSlider && (
          <div className="gallarySliderContainer">
            <Slider
              {...gallarySliderSettings}
              ref={(slider) => slider && setSlider2(slider)}
              asNavFor={nav1}
            >
              {sliderImages?.map((item) => (
                <div className="slick-slide" key={item?.image}>
                  <Image
                    className="slider-image"
                    src={item?.smaller_image || item?.image}
                    fluid
                  />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

ModalCarousel.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  selectedSlide: PropTypes.number,
  sliderImages: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSmallSlider: PropTypes.bool,
};

ModalCarousel.defaultProps = {
  isOpen: false,
  toggle: () => {},
  sliderImages: [],
  selectedSlide: 0,
  isSmallSlider: true,
};

export default ModalCarousel;
