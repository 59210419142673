import React, { useEffect, useState, useMemo } from "react";
import { useFormikContext } from "formik";
import { Row, Col } from "react-bootstrap";
import { uniqBy } from "lodash";
import { useRFPFormContext } from "../context/RFPContext";
import DataModal from "./DataModal";
import DataCard from "./DataCard";
import { EVENTS, CONGRESS } from "../utils/constants";

const FIELD_KEY = "rfpvenue";

const PrefferredVenues = () => {
  const { values, setFieldValue } = useFormikContext();
  const {
    cart: {
      type: cartType,
      event: { venue_sourcing_list: eventCartVenues = [] },
      congress: { venue_sourcing_list: congressCartVenues = [] },
    },
  } = useRFPFormContext();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const toggleAddModal = () => setIsAddModalOpen((prevState) => !prevState);

  const cartVenues = useMemo(() => {
    if (cartType === EVENTS) return eventCartVenues;
    if (cartType === CONGRESS) return congressCartVenues;
    return uniqBy([...eventCartVenues, ...congressCartVenues], "modelID");
  }, [cartType, eventCartVenues, congressCartVenues]);

  useEffect(() => {
    const modifiedVenues = cartVenues?.map((item) => ({
      placeID: parseInt(item.modelID, 10),
      image: item.descriptionFields.image,
      title: item.descriptionFields.title,
      is_custom: false,
    }));
    setFieldValue(FIELD_KEY, modifiedVenues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartVenues]);

  const handleAddNewVenue = (venueName) => {
    const addedVenue = {
      placeID: Math.random(),
      image: null,
      title: venueName,
      is_custom: true,
    };
    setFieldValue(FIELD_KEY, [...values[FIELD_KEY], addedVenue]);
  };

  const handleDelete = (selectedItemID) => {
    const filteredVenues = [...values[FIELD_KEY]].filter(
      (item) => item.placeID !== selectedItemID
    );
    setFieldValue(FIELD_KEY, filteredVenues);
  };

  return (
    <Row className="w-100 px-0 mb-3">
      <DataModal
        title="Add Preferred Hotel"
        isOpen={isAddModalOpen}
        toggle={toggleAddModal}
        onAdd={handleAddNewVenue}
      />
      <Col md={12} className="px-0">
        <DataCard
          title="Preferred Hotels"
          items={values[FIELD_KEY]}
          buttonTitle="Add Hotel"
          onAddButtonClick={toggleAddModal}
          onDelete={handleDelete}
        />
      </Col>
    </Row>
  );
};

export default PrefferredVenues;
