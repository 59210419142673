import React from "react";
import { Dropdown } from "react-bootstrap";
import LoadingButton from "../LoadingButton";
import { useFileHandlerContext } from "./context/FileHandlerContext";
import withFileHandler from "./hoc/withFileHandler";
import SpreadsheetHandler from "./components/Spreadsheet";
import DocumentHandler from "./components/Document";
import { SPREADSHEET, DOCUMENT } from "./utils/constants";
import "./styles/main.scss";

const FileHandler = ({
  disableActions = true,
  onSaveButtonClick = null,
  isSaving = false,
  fileType = "Default",
  downloadAsOptions = [],
  downloadedFileName = "Document",
  onDownload = () => {},
  ...restProps
}) => {
  const { spreadSheetRef } = useFileHandlerContext();

  const handleDownloadAs = (saveType) => {
    if (fileType === SPREADSHEET && spreadSheetRef.current) {
      spreadSheetRef.current.save({
        fileName: downloadedFileName,
        saveType,
      });
    } else if (fileType === DOCUMENT) {
      onDownload(saveType);
    }
  };

  return (
    <div className="file-handler">
      <div className="d-flex justify-content-end mb-3">
        {downloadAsOptions.length > 0 && (
          <Dropdown className="custom-primary-dropdown">
            <Dropdown.Toggle variant="primary" disabled={disableActions}>
              Download As
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {downloadAsOptions.map((item) => (
                <Dropdown.Item
                  key={item.value}
                  disabled={disableActions}
                  onClick={() => handleDownloadAs(item.value)}
                >
                  {item.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {onSaveButtonClick && (
          <LoadingButton
            className="custom-primary-button ml-3"
            isLoading={isSaving}
            disabled={disableActions}
            onClick={onSaveButtonClick}
          >
            {isSaving ? "Saving..." : "Save"}
          </LoadingButton>
        )}
      </div>
      {fileType === SPREADSHEET && <SpreadsheetHandler {...restProps} />}
      {fileType === DOCUMENT && <DocumentHandler {...restProps} />}
    </div>
  );
};

// Exporting Context
export * from "./context/FileHandlerContext";

// Exporting HOC
export { withFileHandler };

// Exporting file types
export * from "./utils/constants";

export default FileHandler;
