import React from "react";
import { Container, Row } from "react-bootstrap";
import "./index.scss";

const Footer = () => {
  return (
    <React.Fragment>
      <Container fluid className="footer_fluid px-0">
        <Row>
          <div className="inner_width_div d-flex flex-column flex-sm-row pb-sm-0 newDiv">
            <div className="footer_col">
              <p className="text-white">
                &copy;2021
                <a
                  href="https://events-explorers.com/"
                  target="_blank"
                  className="text-white footer_link2 pl-3"
                  rel="noopener noreferrer"
                >
                  Events-Explorers.com
                </a>{" "}
                - All Rights Reserved.{" "}
                <a
                  href="https://events-explorers.com/privacy-policy/"
                  target="_blank"
                  className="ml-4 footer_link1 pr-3 text-white"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                <a
                  href="https://events-explorers.com/terms-and-conditions/"
                  target="_blank"
                  className="text-white footer_link2 pl-3"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>
              </p>
            </div>
          </div>

          <div className="footer-corner-container">
            <button
              id="footer-corner"
              onClick={() =>
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
            >
              ↑
            </button>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Footer;
