import { useState, useCallback } from "react";

function useLazyFetchRequest(requestHandler, defaultValues = {}) {
  const INITIAL_REQUEST_RESPONSE = {
    isLoading: defaultValues.isLoading || false,
    data: defaultValues.data || undefined,
    isSuccess: defaultValues.isSuccess || false,
    error: defaultValues.error || null,
    isError: defaultValues.isError || false,
  };
  const [requestResponse, setRequestResponse] = useState(
    INITIAL_REQUEST_RESPONSE
  );

  const fetchData = useCallback(async (args) => {
    setRequestResponse({
      ...INITIAL_REQUEST_RESPONSE,
      isLoading: true,
    });

    try {
      const response = await requestHandler(args);
      setRequestResponse((prevState) => ({
        ...prevState,
        isLoading: false,
        data: response,
        isSuccess: true,
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setRequestResponse((prevState) => ({
        ...prevState,
        isLoading: false,
        error,
        isError: Boolean(error),
      }));
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    fetchData,
    {
      ...requestResponse,
    },
  ];
}

export default useLazyFetchRequest;
