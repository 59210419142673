import React from "react";
import NewNav from "./new_nav";

const Header = () => {
  return (
    <React.Fragment>
      <NewNav />
    </React.Fragment>
  );
};

export default Header;
