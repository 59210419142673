import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";

// Components
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ToolbarOptions = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "list",
    "textAlign",
    "colorPicker",
    "emoji",
    "remove",
    "history",
  ],
  inline: {
    options: ["bold", "italic", "underline"],
  },
};

function TextEditor({ onChange, inputRef, defaultValue }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (defaultValue) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(defaultValue))
        )
      );
    }
  }, [defaultValue]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <div>
      <Editor
        ref={inputRef}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={ToolbarOptions}
      />
    </div>
  );
}

export default function ({ name, control, defaultValue }) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={(props) => (
        <TextEditor
          inputRef={props.ref}
          onChange={props.onChange}
          defaultValue={defaultValue}
        />
      )}
    />
  );
}
