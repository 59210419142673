import axios from "axios";
import store from "../store";
import { API_URL, CRM_API_URL } from "../constants";

export const getConfig = () => {
  // Get token from state
  const { getState } = store;
  const { token } = getState().auth;
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
};

export const getOperationsConfig = () => {
  // Get token from state
  const { getState } = store;
  const { token } = getState().auth;
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers.Authorization = `EMP ${token}`;
  }

  return config;
};

export const handleSearch = (name, city, date) =>
  new Promise((resolve, reject) => {
    let tempArr = [
      ["conference_name", name],
      ["city", city],
      ["date", date],
    ];
    let query = "";
    for (var x = 0; x < 3; ++x) {
      if (tempArr[x][1]) {
        query = query + "&" + tempArr[x][0] + "=" + tempArr[x][1];
      }
    }
    console.log(query);
    // if (name && city && date) {
    //   query = "conference_name=" + name + "&city=" + city + "&date=" + date;
    // }
    // else{

    // }
    fetch(API_URL + "/search_conference?" + query, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
    })
      .then((response) => {
        response.json().then((data) => {
          console.log(data);
          resolve(data);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });

export const handleSpeciality = async (speciality = "") => {
  const res = await axios.get(`${API_URL}/search_conference`, {
    params: { speciality },
  });
  return res.data;
};

export const handleCountry = async (country = "") => {
  const res = await axios.get(`${API_URL}/search_conference`, {
    params: { country },
  });
  return res.data;
};

export const create_general_request_form = (form) =>
  new Promise((resolve, reject) => {
    console.log("FINAL FORM IS: ", form);
    fetch(API_URL + "/create_general_request_form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
      body: JSON.stringify(form),
    }).then((response) => {
      response.json().then((data) => {
        console.log("Response: ", data);
        resolve(data);
      });
    });
  });

export const create_conference_request_form = (form) =>
  new Promise((resolve, reject) => {
    console.log(form);
    fetch(API_URL + "/create_conference_request_form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
      body: JSON.stringify(form),
    }).then((response) => {
      response.json().then((data) => {
        console.log("Response: ", data);
        resolve(data);
      });
    });
  });

export const get_conference = (conferenceId) =>
  new Promise((resolve, reject) => {
    // var data = {
    //   conferenceId: conferenceId,
    // };
    console.log(conferenceId);
    fetch(API_URL + "/get_conference", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
      body: JSON.stringify({
        conference_id: conferenceId,
      }),
    })
      .then((response) => {
        response.json().then((data) => {
          console.log("Response: ", data);
          resolve(data);
        });
      })
      .catch((err) => {
        console.log("error");
      });
  });

export const create_subuser = (form) =>
  new Promise((resolve, reject) => {
    fetch(API_URL + "/create_subuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
      body: JSON.stringify(form),
    }).then((response) => {
      response.json().then((data) => {
        console.log("Response: ", data);
        resolve(data);
      });
    });
  });

export const getSpeciality = () =>
  new Promise((resolve, reject) => {
    fetch(API_URL + "/get_speciality", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
    }).then((response) => {
      response.json().then((data) => {
        resolve(data);
      });
    });
  });

export const get_country = () =>
  new Promise((resolve, reject) => {
    fetch(API_URL + "/get_country", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
    }).then((response) => {
      response.json().then((data) => {
        resolve(data);
      });
    });
  });

export const search_booth = (stand_type, booth_size) =>
  new Promise((resolve, reject) => {
    fetch(
      API_URL +
        "/search_booth?stand_type=" +
        stand_type +
        "&booth_size=" +
        booth_size,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        mode: "cors",
      }
    ).then((response) => {
      response.json().then((data) => {
        console.log("Response: ", data);
        resolve(data);
      });
    });
  });
export const create_booth_request_form = (form) =>
  new Promise((resolve, reject) => {
    // var temp_form = {
    //   exhibition_booth: 1,
    //   account_name: "test",
    //   name: "name 2",
    //   date: "2020-10-02",
    //   country: "country",
    //   city: "city",
    //   stand_size: "stand_size",
    //   budget: "budget",
    //   logo: "logo",
    //   other_requirements: "other_requirements",
    // };
    fetch(API_URL + "/create_booth_request_form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
      body: JSON.stringify(form),
    }).then((response) => {
      response.json().then((data) => {
        console.log("Response: ", data);
        // window.ation = '/'
        resolve(data);
      });
    });
  });

export const get_booth_by_id = (booth_id) =>
  new Promise((resolve, reject) => {
    fetch(API_URL + "/get_booth_by_id?booth_id=" + booth_id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
    }).then((response) => {
      response.json().then((data) => {
        console.log("Response: ", data);
        resolve(data);
      });
    });
  });

export const searchConferences = async (
  page,
  congress_speciality,
  country_city,
  month,
  year
) => {
  const { data } = await axios.get(`${API_URL}/search_conference/`, {
    params: {
      page,
      congress_speciality,
      country_city,
      month,
      year,
    },
  });
  return data;
};

export const getAllConferences = async () => {
  const res = await axios.get(`${API_URL}/search_conference`);
  return res.data;
};

export const getFeaturedConferences = async () => {
  const res = await axios.get(`${API_URL}/search_conference`, {
    params: {
      featured: true,
    },
  });
  return res.data;
};

export const getConferenceByName = async (name) => {
  const res = await axios.get(`${API_URL}/conferences/search/`, {
    params: {
      name,
    },
  });
  return res && res.data;
};

export const getBoothSizeFilters = async () => {
  const res = await axios.get(`${API_URL}/boothSizeFilters/`);
  return res && res.data;
};

export const searchBooths = async (minSize = "", maxSize = "", standType) => {
  const res = await axios.get(`${API_URL}/search_booth`, {
    params: {
      min_size: minSize,
      max_size: maxSize,
      stand_type: standType,
    },
  });
  return res.data;
};

export const getExhibitionBoothById = async (id) => {
  const res = await axios.get(`${API_URL}/get_booth_by_id`, {
    params: {
      booth_id: id,
    },
  });
  return res.data && res.data[0];
};

export const searchVenues = async (
  search = "",
  featured = "",
  attendees = "",
  meetingRooms = "",
  guestRooms = "",
  venueTypes = "",
  starRatings = ""
) => {
  const res = await axios.get(`${API_URL}/search_venue`, {
    ...getConfig(),
    params: {
      search,
      featured,
      attendees,
      meeting_rooms: meetingRooms,
      guest_rooms: guestRooms,
      venue_types: venueTypes,
      star_ratings: starRatings,
    },
  });
  return res.data;
};

export const getVenueByName = async (name) => {
  const res = await axios.get(`${API_URL}/venues/search/`, {
    ...getConfig(),
    params: {
      name,
    },
  });
  return res && res.data;
};

export const searchDestinations = async (
  featured = "",
  search = "",
  month = ""
) => {
  const res = await axios.get(`${API_URL}/search_destination`, {
    params: {
      featured,
      search,
      month,
    },
  });
  return res && res.data;
};

export const getVenueList = async () => {
  const res = await axios.get(`${API_URL}/venueLists/`, getConfig());
  return res && res.data && res.data.results;
};

export const createVenueList = async (listName, venueId) => {
  const res = await axios.post(
    `${API_URL}/venueLists/`,
    { venue_list_name: listName, venue_id: venueId },
    getConfig()
  );
  return res && res.data;
};

export const addVenueInList = async (listId, venueId) => {
  const res = await axios.get(
    `${API_URL}/venueLists/${listId}/add/${venueId}`,
    getConfig()
  );
  return res && res.data;
};

export const removeVenueFromList = async (venueId) => {
  const res = await axios.get(
    `${API_URL}/venues/${venueId}/removeFromList`,
    getConfig()
  );
  return res && res.data;
};

export const getVenueListById = async (id) => {
  const res = await axios.get(`${API_URL}/venueLists/${id}/`, getConfig());
  return res && res.data;
};

export const getVenueCounts = async () => {
  const res = await axios.get(`${API_URL}/venue_counts`);
  return res && res.data;
};

export const getDestinationByName = async (name) => {
  const res = await axios.get(`${API_URL}/destinations/search/`, {
    params: {
      name,
    },
  });
  return res && res.data;
};

export const getDestinationById = async (id) => {
  const res = await axios.get(`${API_URL}/destinations/${id}/`, getConfig());
  return res && res.data;
};

export const searchVenueDestinations = async (search = "", featured = "") => {
  const res = await axios.get(`${API_URL}/search_venue_destination`, {
    params: {
      search,
      featured,
    },
  });
  return res && res.data;
};

export const getBrandingCategories = async () => {
  const res = await axios.get(`${API_URL}/search_branding_category`);
  return res && res.data;
};

export const getBrandingMaterials = async (category = "") => {
  const res = await axios.get(`${API_URL}/search_branding_material`, {
    params: {
      category,
    },
  });
  return res && res.data;
};

export const getBrandingPackages = async () => {
  const res = await axios.get(`${API_URL}/search_branding_package`);
  return res && res.data;
};

export const getGiftCategories = async () => {
  const res = await axios.get(`${API_URL}/search_gift_category`);
  return res && res.data;
};

export const getGifts = async (category = "") => {
  const res = await axios.get(`${API_URL}/search_gift`, {
    params: {
      category,
    },
  });
  return res && res.data;
};

export const submitExhibitionBoothForm = async (values) => {
  const config = getConfig();
  config.headers["Content-Type"] = "multipart/form-data";

  const formData = new FormData();
  formData.append("exhibition_name", values.exhibition_name);
  formData.append(
    "exhibition_date",
    new Date(values.exhibition_date).toLocaleDateString("en-CA")
  );
  formData.append("booth_code", values.booth_code);
  formData.append("booth_size", values.booth_size);
  formData.append("location", values.location);
  formData.append("stand_type", values.stand_type);
  formData.append(
    "exhibition_booth_requirements",
    values.exhibition_booth_requirements
  );
  formData.append("other_requirements", values.other_requirements);
  formData.append("attachment", values.attachment[0]);

  if (values.status) {
    formData.append("status", values.status);
  }

  const { data } = await axios.post(
    `${API_URL}/exhibitionBoothReqForms/`,
    formData,
    config
  );
  return data;
};

export const submitMedicalCongressForm = async (values, status) => {
  const medicalForm = {
    congress_name: values.congress_name,
    congress_date: new Date(values.congress_date).toLocaleDateString("en-CA"),
    num_delegates: values.num_delegates,
    registration: values.registration,
    transportation: values.transportation,
    food_and_beverages: values.food_and_beverages,
    others: values.others,
  };

  if (values.flight_ticket) {
    medicalForm.flight_ticket = {
      ...values.flight_ticket,
      departure_date: new Date(
        values.flight_ticket.departure_date
      ).toLocaleDateString("en-CA"),
      return_date: new Date(
        values.flight_ticket.return_date
      ).toLocaleDateString("en-CA"),
    };
  }

  if (values.accommodation) {
    medicalForm.accommodation = {
      ...values.accommodation,
      check_in_date: new Date(
        values.accommodation.check_in_date
      ).toLocaleDateString("en-CA"),
      check_out_date: new Date(
        values.accommodation.check_out_date
      ).toLocaleDateString("en-CA"),
    };
  }

  if (status) {
    medicalForm.status = status;
  }

  const { data } = await axios.post(
    `${API_URL}/medCongressReqForms/`,
    medicalForm,
    getConfig()
  );

  return data;
};

export const getAccountDetail = async () => {
  const { data } = await axios.get(`${API_URL}/auth/account`, getConfig());
  return data;
};

export const createUser = async (values) => {
  const { data } = await axios.post(
    `${API_URL}/auth/createUser`,
    values,
    getConfig()
  );

  return data;
};

export const updateUser = async ({ id, ...values }) => {
  const { data } = await axios.put(
    `${API_URL}/auth/updateUser/${id}/`,
    values,
    getConfig()
  );

  return data;
};

export const getUsers = async (params) => {
  const { data } = await axios.get(`${API_URL}/users/`, {
    ...getConfig(),
    params,
  });
  return data;
};

export const getUser = async (id) => {
  const { data } = await axios.get(`${API_URL}/users/${id}/`, getConfig());
  return data;
};

export const changePassword = async (values) => {
  const { data } = await axios.post(
    `${API_URL}/auth/changePassword`,
    { password: values.password },
    getConfig()
  );
  return data;
};

export const sendActivationRequest = async (values) => {
  const { data } = await axios.post(
    `${API_URL}/activationRequests/`,
    values,
    getConfig()
  );
  return data;
};

// Partner APIs Start
// *******************

export const addPartnerVenue = async (values) => {
  // const config = getConfig();
  // config.headers["Content-Type"] = "multipart/form-data";

  // const formData = new FormData();

  // formData.append("thumbnail", values.thumbnail[0]);

  // delete values.thumbnail;

  // values.slider.forEach((slide, index) => {
  //   formData.append("slider_" + index, slide);
  // });

  // delete values.slider;

  // values.meeting_room_images.forEach((image, index) => {
  //   formData.append("meeting_room_image_" + index, image);
  // });

  // delete values.meeting_room_images;

  // values.floor_plan_images.forEach((image, index) => {
  //   formData.append("floor_plan_image_" + index, image);
  // });

  // delete values.floor_plan_images;

  // if (values.restaurants) {
  //   let restIndex = 0;
  //   values.restaurants.forEach((restaurant, index) => {
  //     restaurant.restaurant_images.forEach((image) => {
  //       formData.append("restaurant_image-" + index + "_" + restIndex, image);
  //       restIndex++;
  //     });
  //     delete restaurant.restaurant_images;
  //   });
  // }

  // if (values.venue_guest_rooms) {
  //   let guestIndex = 0;
  //   values.venue_guest_rooms.forEach((room, index) => {
  //     room.guest_room_images.forEach((image) => {
  //       formData.append("guest_room_image-" + index + "_" + guestIndex, image);
  //       guestIndex++;
  //     });
  //     delete room.guest_room_images;
  //   });
  // }

  // for (const [key, value] of Object.entries(values)) {
  //   if (typeof value === "string") {
  //     formData.append(key, value);
  //   }
  //   if (Array.isArray(value)) {
  //     if (key === "venue_public_transports") {
  //       const transports = value.filter((item) => item.nearest_location);
  //       formData.append(key, JSON.stringify(transports));
  //     } else {
  //       formData.append(key, JSON.stringify(value));
  //     }
  //   }
  // }
  const property = {
    ...values,
    property_logo2:
      values.property_logo2.length > 0 ? values.property_logo2[0].id : null,
    thumbnail: values.thumbnail.length > 0 ? values.thumbnail[0].id : null,
  };

  if (!property.guest_rooms) {
    delete property.guest_rooms;
  }

  if (!property.meeting_rooms) {
    delete property.meeting_rooms;
  }

  if (!property.maximum_capacity) {
    delete property.maximum_capacity;
  }

  const { data } = await axios.post(
    `${API_URL}/partnerVenues/`,
    // formData,
    // config
    property,
    getConfig()
  );
  return data;
};

export const editPartnerVenue = async (values, id) => {
  // const config = getConfig();
  // config.headers["Content-Type"] = "multipart/form-data";

  // const formData = new FormData();

  // if (Array.isArray(values.thumbnail) && values.thumbnail.length > 0) {
  //   formData.append("thumbnail", values.thumbnail[0]);
  //   delete values.thumbnail;
  // }

  // values.slider.forEach((slide, index) => {
  //   formData.append("slider_" + index, slide);
  // });

  // delete values.slider;

  // values.meeting_room_images.forEach((image, index) => {
  //   formData.append("meeting_room_image_" + index, image);
  // });

  // delete values.meeting_room_images;

  // values.floor_plan_images.forEach((image, index) => {
  //   formData.append("floor_plan_image_" + index, image);
  // });

  // delete values.floor_plan_images;

  // if (values.restaurants) {
  //   let restIndex = 0;
  //   values.restaurants.forEach((restaurant, index) => {
  //     restaurant.restaurant_images.forEach((image) => {
  //       formData.append("restaurant_image-" + index + "_" + restIndex, image);
  //       restIndex++;
  //     });
  //     delete restaurant.restaurant_images;
  //   });
  // }

  // if (values.venue_guest_rooms) {
  //   let guestIndex = 0;
  //   values.venue_guest_rooms.forEach((room, index) => {
  //     room.guest_room_images.forEach((image) => {
  //       formData.append("guest_room_image-" + index + "_" + guestIndex, image);
  //       guestIndex++;
  //     });
  //     delete room.guest_room_images;
  //   });
  // }

  // for (const [key, value] of Object.entries(values)) {
  //   if (typeof value === "string") {
  //     formData.append(key, value);
  //   }

  //   if (Array.isArray(value)) {
  //     if (key === "venue_guest_rooms") {
  //       const guestRooms = value.map((item) => ({
  //         ...item,
  //         guest_room_id: parseInt(item.guest_room_id),
  //       }));
  //       formData.append(key, JSON.stringify(guestRooms));
  //     } else if (key === "restaurants") {
  //       const restaurants = value.map((item) => ({
  //         ...item,
  //         restaurant_id: parseInt(item.restaurant_id),
  //       }));
  //       formData.append(key, JSON.stringify(restaurants));
  //     } else if (key === "venue_public_transports") {
  //       const transports = value.filter((item) => item.nearest_location);
  //       formData.append(key, JSON.stringify(transports));
  //     } else {
  //       formData.append(key, JSON.stringify(value));
  //     }
  //   }
  // }

  const property = {
    ...values,
    property_logo2:
      values.property_logo2.length > 0 ? values.property_logo2[0].id : null,
    thumbnail: values.thumbnail.length > 0 ? values.thumbnail[0].id : null,
  };

  if (!property.guest_rooms) {
    delete property.guest_rooms;
  }

  if (!property.meeting_rooms) {
    delete property.meeting_rooms;
  }

  if (!property.maximum_capacity) {
    delete property.maximum_capacity;
  }

  property.venue_guest_rooms = property.venue_guest_rooms?.map((item) => ({
    ...item,
    guest_room_id: parseInt(item.guest_room_id),
  }));

  property.restaurants = property.restaurants?.map((item) => ({
    ...item,
    restaurant_id: parseInt(item.restaurant_id),
  }));

  const { data } = await axios.put(
    `${API_URL}/partnerVenues/${id}/`,
    property,
    getConfig()
  );
  return data;
};

export const getPartnerVenueById = async (id) => {
  const { data } = await axios.get(
    `${API_URL}/partnerVenues/${id}/`,
    getConfig()
  );
  return data;
};

export const getPartnerVenues = async () => {
  const { data } = await axios.get(`${API_URL}/partnerVenues/`, getConfig());
  return data.results;
};

export const deletePartnerVenue = async (id) => {
  const { data } = await axios.delete(
    `${API_URL}/partnerVenues/${id}/`,
    getConfig()
  );
  return data;
};

export const deleteVenueThumbnailImage = async (id) => {
  const { data } = await axios.delete(
    `${API_URL}/venueThumbnailImages/${id}/`,
    getConfig()
  );
  return data;
};

export const deleteSliderImage = async (id) => {
  const { data } = await axios.delete(
    `${API_URL}/venueSliderImages/${id}/`,
    getConfig()
  );
  return data;
};

export const deleteGuestRoomImage = async (id) => {
  const { data } = await axios.delete(
    `${API_URL}/guestRoomImages/${id}/`,
    getConfig()
  );
  return data;
};

export const deleteMeetingRoomImage = async (id) => {
  const { data } = await axios.delete(
    `${API_URL}/venueMeetingRoomImages/${id}/`,
    getConfig()
  );
  return data;
};

export const deleteFloorPlanImage = async (id) => {
  const { data } = await axios.delete(
    `${API_URL}/venueFloorPlanImages/${id}/`,
    getConfig()
  );
  return data;
};

export const deleteRestaurantImage = async (id) => {
  const { data } = await axios.delete(
    `${API_URL}/restaurantImages/${id}/`,
    getConfig()
  );
  return data;
};

export const deleteGuestRoom = async (id) => {
  const { data } = await axios.delete(
    `${API_URL}/guestRooms/${id}/`,
    getConfig()
  );
  return data;
};

export const deleteRestaurant = async (id) => {
  const { data } = await axios.delete(
    `${API_URL}/restaurants/${id}/`,
    getConfig()
  );
  return data;
};

export const uploadPartnerLogo = async (image) => {
  const config = getConfig();
  config.headers["Content-Type"] = "multipart/form-data";

  const formData = new FormData();
  formData.append("image", image);
  const { data } = await axios.post(
    `${API_URL}/partnerProfile/`,
    formData,
    config
  );

  return data;
};

export const getPartnerLogo = async () => {
  const { data } = await axios.get(`${API_URL}/partnerProfile/`, getConfig());
  return data;
};

export const updatePartnerLogo = async (id, image) => {
  const config = getConfig();
  config.headers["Content-Type"] = "multipart/form-data";

  const formData = new FormData();
  formData.append("image", image);

  const { data } = await axios.put(
    `${API_URL}/partnerProfile/${id}/`,
    formData,
    config
  );

  return data;
};

// Partner APIs End
// ****************

export const getActivities = async (page, type) => {
  const { data } = await axios.get(`${API_URL}/search_activity/`, {
    ...getConfig(),
    params: {
      page,
      activity_type: type,
    },
  });
  return data;
};

export const getActivityByName = async (name) => {
  const { data } = await axios.get(`${API_URL}/activities/search/`, {
    params: {
      name,
    },
  });
  return data;
};

export const getStageDesignCategory = async () => {
  const { data } = await axios.get(`${API_URL}/search_stage_design_category`);
  return data;
};

// export const getStageDesigns = async (category = "") => {
//   const { data } = await axios.get(`${API_URL}/search_stage_design`, {
//     ...getConfig(),
//     params: {
//       category,
//     },
//   });
//   return data;
// };

export const getStageDesignByCode = async (code) => {
  const { data } = await axios.get(`${API_URL}/stageDesigns/search/`, {
    ...getConfig(),
    params: {
      code,
    },
  });
  return data;
};

export const getDeskDesignCategory = async () => {
  const { data } = await axios.get(`${API_URL}/search_desk_design_category`);
  return data;
};

export const getDeskDesigns = async (category = "") => {
  const { data } = await axios.get(`${API_URL}/search_desk_design`, {
    ...getConfig(),
    params: {
      category,
    },
  });
  return data;
};

export const getDeskDesignByCode = async (code) => {
  const { data } = await axios.get(`${API_URL}/deskDesigns/search/`, {
    ...getConfig(),
    params: {
      code,
    },
  });
  return data;
};

export const getConceptDesignLists = async () => {
  const { data } = await axios.get(
    `${API_URL}/conceptDesignLists/`,
    getConfig()
  );
  return data.results;
};

export const createConceptDesignList = async (
  listName,
  designType,
  designId
) => {
  const { data } = await axios.post(
    `${API_URL}/conceptDesignLists/`,
    { list_name: listName, design_type: designType, design_id: designId },
    getConfig()
  );
  return data;
};

export const addDesignInList = async (listId, designType, designId) => {
  const res = await axios.get(
    `${API_URL}/conceptDesignLists/${listId}/add/${designType}/${designId}`,
    getConfig()
  );
  return res && res.data;
};

export const removeDesignFromList = async (designId, designType) => {
  const res = await axios.get(
    `${API_URL}/conceptDesigns/${designType}/${designId}/removeFromList`,
    getConfig()
  );
  return res && res.data;
};

export const getDesignListById = async (id) => {
  const res = await axios.get(
    `${API_URL}/conceptDesignLists/${id}/`,
    getConfig()
  );
  return res && res.data;
};

export const getVenueConceptList = async () => {
  const { data } = await axios.get(`${API_URL}/search_venue_design_concept`);
  return data;
};

// export const getVenueConceptDesigns = async (conceptName = "", city = "") => {
//   const { data } = await axios.get(
//     `${API_URL}/search_venue_design_concept_city`,
//     {
//       ...getConfig(),
//       params: {
//         concept: conceptName,
//         city,
//       },
//     }
//   );
//   return data;
// };

export const getVenueConceptDesignByCode = async (code) => {
  const { data } = await axios.get(`${API_URL}/venueDesigns/search/`, {
    ...getConfig(),
    params: {
      code,
    },
  });
  return data;
};

export const getDeluxeDesignCategories = async (category = "") => {
  const { data } = await axios.get(`${API_URL}/search_deluxe_design_category`, {
    params: {
      category,
    },
  });
  return data;
};

export const getDeluxeDesigns = async (category = "") => {
  const { data } = await axios.get(`${API_URL}/search_deluxe_design`, {
    ...getConfig(),
    params: {
      category,
    },
  });
  return data;
};

export const getDeluxeDesignByCode = async (code = "") => {
  const { data } = await axios.get(`${API_URL}/deluxeDesigns/search/`, {
    ...getConfig(),
    params: {
      code,
    },
  });
  return data;
};

export const getHomePageContent = async () => {
  const { data } = await axios.get(`${API_URL}/get_home_page_content`);
  return data;
};

export const getHomePageImages = async () => {
  const { data } = await axios.get(`${API_URL}/get_home_page_images`);
  return data;
};

export const getHomePageBannerImages = async () => {
  const { data } = await axios.get(`${API_URL}/get_home_page_banner_images`);
  return data;
};

export const upload = async (file) => {
  // const config = getConfig();
  // config.headers["Content-Type"] = "multipart/form-data";

  let formData = new FormData();

  formData.append("image", file);

  const { data } = await axios.post(`${API_URL}/venueSliderImages/`, formData, {
    // ...config,
  });

  return data;
};

export const getAvProductions = async () => {
  const { data } = await axios.get(`${API_URL}/search_production_package`);
  return data;
};

export const getHomeServices = async () => {
  const { data } = await axios.get(`${API_URL}/ourservices/`, getConfig());
  return data.results;
};

export const getHomeContent = async () => {
  const { data } = await axios.get(`${API_URL}/homepagecontents/`, getConfig());
  return data.results;
};

export const getExhibitionBooths = async (page, offset) => {
  const { data } = await axios.get(`${API_URL}/exhibitionBooths/`, {
    ...getConfig(),
    params: {
      limit: 6,
      offset,
      page,
    },
  });
  return data;
};

// CART APIs

export const addToCongressCart = async (modelName, itemId, thumbnailImage) => {
  const formData = new FormData();
  formData.append("modelName", modelName);
  formData.append("modelID", itemId);
  if (thumbnailImage) {
    formData.append("image_url", thumbnailImage);
  }
  const { data } = await axios.post(
    `${API_URL}/cart/congress/`,
    formData,
    getConfig()
  );

  return data;
};

export const addToEventCart = async (modelName, itemId) => {
  const formData = new FormData();
  formData.append("modelName", modelName);
  formData.append("modelID", itemId);
  const { data } = await axios.post(
    `${API_URL}/cart/events/`,
    formData,
    getConfig()
  );

  return data;
};

export const getCongressCartItems = async () => {
  const { data } = await axios.get(`${API_URL}/cart/congress/`, getConfig());
  return data;
};

export const getEventCartItems = async () => {
  const { data } = await axios.get(`${API_URL}/cart/events/`, getConfig());
  return data;
};

export const removeFromCart = async (modelName, modelID) => {
  const { data } = await axios.delete(
    `${API_URL}/cart/crud/${modelName}/${modelID}`,
    getConfig()
  );
  return data;
};

export const addToSaved = async (modelName, itemId) => {
  const formData = new FormData();
  formData.append("modelName", modelName);
  formData.append("modelID", itemId);

  const { data } = await axios.post(
    `${API_URL}/cartItemAddSaved/`,
    formData,
    getConfig()
  );
  return data;
};

export const moveToCart = async (modelName, itemId) => {
  const formData = new FormData();
  formData.append("modelName", modelName);
  formData.append("modelID", itemId);

  const { data } = await axios.post(
    `${API_URL}/cartItemRemoveSaved/`,
    formData,
    getConfig()
  );
  return data;
};

export const updateCart = async (values) => {
  const { data } = await axios.post(
    `${API_URL}/cart/update/list/ `,
    values,
    getConfig()
  );
  return data;
};

// CART APIs End
// =============

export const getEventCartSavedList = async () => {
  const { data } = await axios.get(
    `${API_URL}/cartItemSavedList/events/`,
    getConfig()
  );
  return data;
};

export const getCongressCartSavedList = async () => {
  const { data } = await axios.get(
    `${API_URL}/cartItemSavedList/congress/`,
    getConfig()
  );
  return data;
};

export const getAvEquipmentsCategories = async () => {
  const { data } = await axios.get(
    `${API_URL}/avEquipmentsCategory/`,
    getConfig()
  );
  return data.results;
};

export const getAvEquipments = async (page, category) => {
  const { data } = await axios.get(`${API_URL}/avproductionEquipments/`, {
    ...getConfig(),
    params: {
      page,
      category__category_name: category,
    },
  });
  return data;
};

export const getAvPackages = async () => {
  const { data } = await axios.get(
    `${API_URL}/productionPackages/`,
    getConfig()
  );
  return data.results;
};

export const getStageDesigns = async (page) => {
  const { data } = await axios.get(`${API_URL}/stageDesigns/`, {
    ...getConfig(),
    params: {
      page,
    },
  });
  return data;
};

export const getHospitalityDeskDesigns = async (page) => {
  const { data } = await axios.get(`${API_URL}/deskDesigns/`, {
    ...getConfig(),
    params: {
      page,
    },
  });
  return data;
};

export const getPhotoBoothDesigns = async (page) => {
  const { data } = await axios.get(`${API_URL}/conceptPhotoBoothViewset/`, {
    ...getConfig(),
    params: {
      page,
    },
  });
  return data;
};

export const getVenueConceptDesigns = async (page, city) => {
  const { data } = await axios.get(`${API_URL}/venueDesigns/`, {
    ...getConfig(),
    params: {
      page,
      city__city_name: city,
    },
  });
  return data;
};

export const getVenueConceptCities = async () => {
  const { data } = await axios.get(`${API_URL}/search_venue_design_city`);
  return data;
};

export const getNearByVenues = async (city) => {
  const { data } = await axios.get(`${API_URL}/nearby/lists/`, {
    params: {
      city,
    },
  });
  return data.data;
};

// Google Map APIs

export const getTopAttractions = async (latitude, longitude) => {
  const { data } = await axios.get(`${API_URL}/nearby/places/`, {
    ...getConfig(),
    params: {
      latitude,
      longitude,
    },
  });

  const filteredData = data?.data.results
    ?.filter((item) => item.business_status === "OPERATIONAL")
    .slice(0, 5);
  return filteredData;
};

export const getHotelRatings = async (placeId) => {
  const { data } = await axios.get(`${API_URL}/place/detail/`, {
    ...getConfig(),
    params: {
      place_id: placeId,
    },
  });

  return data?.data.result;
};

export const getVenueLogos = async () => {
  const { data } = await axios.get(
    `${API_URL}/venueSourcingLogo/`,
    getConfig()
  );
  return data.results;
};

export const getDistance = async (lat1, long1, lat2, long2) => {
  const { data } = await axios.get(`${API_URL}/distance/`, {
    ...getConfig(),
    params: {
      lat1,
      long1,
      lat2,
      long2,
    },
  });

  return data.data;
};

export const uploadRFPDesign = async (uploadedDesign) => {
  const config = getConfig();
  config.headers["Content-Type"] = "multipart/form-data";
  const formData = new FormData();

  formData.append("image", uploadedDesign);

  const { data } = await axios.post(`${API_URL}/rfpImage/`, formData, config);

  return data;
};

export const updateRFPDesign = async (id, uploadedDesign) => {
  const config = getConfig();
  config.headers["Content-Type"] = "multipart/form-data";
  const formData = new FormData();

  formData.append("image", uploadedDesign);

  const { data } = await axios.put(
    `${API_URL}/rfpImage/${id}/`,
    formData,
    config
  );

  return data;
};

export const submitRFPForm = async (form) => {
  const { data } = await axios.post(
    `${API_URL}/rfp_form_crm/`,
    form,
    getConfig()
  );

  return data;
};

export const updateRFPForm = async ({ id, ...payload }) => {
  const { data } = await axios.put(
    `${API_URL}/rfp_form_crm/${id}/`,
    payload,
    getConfig()
  );

  return data.results;
};

export const modifyRFPRequestStatus = async (id, values) => {
  const { data } = await axios.patch(
    `${API_URL}/rfp/${id}/update/status`,
    values,
    getConfig()
  );

  return data.results;
};

export const getRFPRequests = async (status) => {
  const { data } = await axios.get(`${API_URL}/rfp_form_crm/`, {
    ...getConfig(),
    params: {
      request_status: status,
    },
  });

  return data;
};

export const getRfpDetails = async (id) => {
  const { data } = await axios.get(
    `${API_URL}/rfp_form_crm/${id}/`,
    getConfig()
  );

  return data.results;
};

// RFP Quotations
export const getRFPQuotations = async (id) => {
  const { data } = await axios.get(`${API_URL}/rfp_quotations/`, {
    ...getConfig(),
    params: {
      rfp_id: id,
    },
  });
  return data.results;
};

// Modify RFP Quotation and RFP
export const modifyRFPQuotationAndRFP = async ({ quotationID, ...values }) => {
  const { data } = await axios.patch(
    `${API_URL}/rfp_quotations/${quotationID}/update/status`,
    values,
    getConfig()
  );
  return data;
};

// Site Inspection
export const getRFPSiteInspections = async (id) => {
  const { data } = await axios.get(`${API_URL}/rfp_site_inspection/`, {
    ...getConfig(),
    params: {
      rfp_id: id,
    },
  });
  return data;
};

export const requestRFPSiteInspection = async (payload) => {
  const { data } = await axios.post(
    `${API_URL}/rfp_site_inspection/`,
    payload,
    getConfig()
  );

  return data;
};

export const updateRFPSiteInspection = async ({ id, ...payload }) => {
  const { data } = await axios.put(
    `${API_URL}/rfp_site_inspection/${id}/`,
    payload,
    getConfig()
  );

  return data;
};

export const rescheduleSiteInspection = async (payload) => {
  const { data } = await axios.post(
    `${API_URL}/rfp_site_inspection/reschedule/`,
    payload,
    getConfig()
  );

  return data;
};

// RFP Roles
export const getRFPEventRoles = async (id) => {
  const { data } = await axios.get(
    `${API_URL}/rfp_roles/?rfp_id=${id}`,
    getConfig()
  );
  return data;
};

export const assignRFPEventRole = async (form) => {
  const { data } = await axios.post(`${API_URL}/rfp_roles/`, form, getConfig());

  return data;
};

export const updateRFPEventRole = async ({ id, ...payload }) => {
  const { data } = await axios.put(
    `${API_URL}/rfp_roles/${id}/`,
    payload,
    getConfig()
  );

  return data;
};

export const deleteRFPEventRole = async (id) => {
  const { data } = await axios.delete(
    `${API_URL}/rfp_roles/${id}/`,
    getConfig()
  );

  return data;
};

// RFP History
export const getRFPHistory = async (id) => {
  const { data } = await axios.get(
    `${API_URL}/rfp_logs_action/${id}/`,
    getConfig()
  );
  return data;
};

// Get all Users
export const getAllUsersOptionsData = async () => {
  const { data } = await axios.get(`${API_URL}/all/users/`, getConfig());
  return data;
};

// RFP Budgets
export const getRFPBudgets = async (id) => {
  const { data } = await axios.get(`${API_URL}/rfp_budget/`, {
    ...getConfig(),
    params: {
      rfp_id: id,
    },
  });
  return data;
};

// RFP Budget Details
export const getRFPBudgetDetails = async (budgetID) => {
  const { data } = await axios.get(
    `${API_URL}/rfp_budget/detail/${budgetID}/`,
    getConfig()
  );
  return data;
};

// Get RFP Shared Documents
export const getRFPSharedDocs = async (rfpId) => {
  const { data } = await axios.get(
    `${API_URL}/rfp/documents/shared/?rfp_id=${rfpId}`,
    getConfig()
  );
  return data;
};

// Get RFP Private Documents
export const getRFPPrivateDocs = async (rfpId) => {
  const { data } = await axios.get(
    `${API_URL}/rfp/documents/private/?rfp_id=${rfpId}`,
    getConfig()
  );
  return data.results;
};

// Upload RFP Document
export const uploadRFPDoc = async (docFile, extraOptions) => {
  const config = getConfig();
  config.headers["Content-Type"] = "multipart/form-data";
  const formData = new FormData();

  formData.append("file", docFile);

  const { data } = await axios.post(
    `${API_URL}/rfp/documents/upload/`,
    formData,
    {
      ...config,
      ...extraOptions,
    }
  );

  return data;
};

// Add RFP Shared Document
export const addRFPSharedDocs = async (payload) => {
  const { data } = await axios.post(
    `${API_URL}/rfp/documents/shared/`,
    payload,
    getConfig()
  );

  return data;
};

// Add RFP New Private Document
export const addRFPPrivateDocs = async (payload) => {
  const { data } = await axios.post(
    `${API_URL}/rfp/documents/private/`,
    payload,
    getConfig()
  );

  return data;
};

// Update RFP Shared Document
export const updateRFPSharedDoc = async (payload) => {
  const { data } = await axios.post(
    `${API_URL}/rfp/documents/shared/update`,
    payload,
    getConfig()
  );
  return data;
};

// Update RFP Private Document
export const updateRFPPrivateDoc = async (payload) => {
  const { data } = await axios.post(
    `${API_URL}/rfp/documents/private/update`,
    payload,
    getConfig()
  );
  return data;
};

// Delete RFP Shared Document
export const deleteRFPSharedDoc = async (payload) => {
  // Passing random ID just to satisfy the Backend Requirements
  const { data } = await axios.delete(`${API_URL}/rfp/documents/shared/1/`, {
    data: payload, // Passing data in body
    ...getConfig(),
  });

  return data;
};

// Delete RFP Private Document
export const deleteRFPPrivateDoc = async (payload) => {
  // Passing random ID just to satisfy the Backend Requirements
  const { data } = await axios.delete(`${API_URL}/rfp/documents/private/1/`, {
    data: payload, // Passing data in body
    ...getConfig(),
  });

  return data;
};

export const copyRFPDocuments = async (payload) => {
  const { data } = await axios.post(
    `${API_URL}/rfp/documents/copy/`,
    payload,
    getConfig()
  );

  return data;
};

export const moveRFPDocuments = async (payload) => {
  const { data } = await axios.post(
    `${API_URL}/rfp/documents/move/`,
    payload,
    getConfig()
  );

  return data;
};

export const updateRFPDocument = async (payload) => {
  const formData = new FormData();
  formData.append("id", payload.id);
  formData.append("file", payload.file);
  formData.append("type", payload.module_type);

  const config = getConfig();
  config.headers["Content-Type"] = "multipart/form-data";

  const { data } = await axios.post(
    `${API_URL}/rfp/documents/update/`,
    formData,
    getConfig()
  );

  return data;
};

// Get RFP Logistc Document
export const getRFPLogisticsDocument = async (rfpId) => {
  const { data } = await axios.get(
    `${API_URL}/rfp/logistic/document?rfp_id=${rfpId}`,
    getConfig()
  );
  return data.doc_link;
};

export const getRFPProInvoices = async (id) => {
  const { data } = await axios.get(`${API_URL}/rfp_pro_invoices/`, {
    ...getConfig(),
    params: {
      rfp_id: id,
    },
  });
  return data.results;
};

export const updateRFPProInvoiceStatus = async ({
  proInvoiceID,
  ...values
}) => {
  const { data } = await axios.patch(
    `${API_URL}/rfp_pro_invoice/${proInvoiceID}/update/status`,
    values,
    getConfig()
  );
  return data;
};

export const getRFPInvoices = async (id) => {
  const { data } = await axios.get(`${API_URL}/rfp_invoices/`, {
    ...getConfig(),
    params: {
      rfp_id: id,
    },
  });
  return data.results;
};

export const getAllServiceRequests = async (params) => {
  const { data } = await axios.get(
    `${CRM_API_URL}/api/support/service/request/`,
    { ...getOperationsConfig(), params }
  );

  return data;
};

export const getServiceRequestDetails = async (id) => {
  const { data } = await axios.get(
    `${CRM_API_URL}/api/support/service/request/${id}/detail`,
    getOperationsConfig()
  );

  return data;
};

export const createNewServiceRequest = async (payload) => {
  const { data } = await axios.post(
    `${CRM_API_URL}/api/support/service/request/`,
    payload,
    getOperationsConfig()
  );

  return data;
};

export const loadServiceRequestChatMessages = async (params) => {
  const { data } = await axios.get(`${CRM_API_URL}/api/support/message/`, {
    ...getOperationsConfig(),
    params,
  });

  return data;
};

export const saveGlobalDocument = ({ module, ...payload }) => {
  return new Promise(async (resolve, reject) => {
    if (!module) reject();
    if (module === "documents") {
      try {
        await updateRFPDocument(payload);
        resolve();
      } catch (error) {
        reject();
      }
    }
    reject();
  });
};
