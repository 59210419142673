import React from "react";
import { RiPhoneFindLine } from "react-icons/ri";
import PageWrapper from "../../components/common/PageWrapper";

const NotFound = () => {
  return (
    <PageWrapper bannerTitle="Not Found">
      <div className="min-vh-50 d-flex flex-column justify-content-center align-items-center">
        <RiPhoneFindLine className="text-danger" size={200} />
        <h3 className="pt-3">Page not found</h3>
      </div>
    </PageWrapper>
  );
};

export default NotFound;
