import axios from "axios";
import { STORAGE_URL } from "../constants";
import moment from "moment";

const INJECTED_SPINNER_ID = "injected-spinner";

export const getFileType = (fileName) => {
  return fileName ? fileName.split(".").pop() : "";
};

export const isImage = (fileName) => {
  const type = getFileType(fileName).toLowerCase();
  const imgExtensions = ["jpg", "jpeg", "png"];
  if (imgExtensions.includes(type)) return true;
  return false;
};

export const isPDF = (fileName) => {
  const type = getFileType(fileName).toLowerCase();
  if (type === "pdf") return true;
  return false;
};

export const downloadFileWithURL = (fileURL, fileName = "document") => {
  // Passing timestamp in every request just to bypass cache because it cause cross origin issue
  // and uses cache when download request is dispatched
  axios
    .get(`${fileURL}?timestamp=${new Date().getTime()}`, {
      responseType: "blob",
    })
    .then((res) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(res.data);
      link.download = fileName;
      link.target = "_blank";
      link.click();
    })
    .catch(() => {
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = fileName;
      link.target = "_blank";
      link.click();
    });
};

export const downloadFileWithFileObject = (fileObject = {}) => {
  try {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(fileObject);
    link.download = fileObject.name || "document";
    link.target = "_blank";
    link.click();
  } catch (error) {
    console.log(error);
  }
};
export function extractContentFromHTML(htmlString) {
  const span = document.createElement("span");
  span.innerHTML = htmlString;
  return span.textContent || span.innerText;
}

export const injectSpinner = (selector, customSpinner) => {
  if (!selector) return;
  try {
    const target = document.querySelector(selector);
    target.style.position = "relative";

    const spinnerNode = document.createElement("span");
    spinnerNode.id = INJECTED_SPINNER_ID;
    spinnerNode.style.position = "absolute";
    spinnerNode.style.top = "50%";
    spinnerNode.style.left = "50%";
    spinnerNode.style.transform = "translate(-50%,-50%)";
    spinnerNode.style.width = "100%";
    spinnerNode.style.height = "100%";
    spinnerNode.style.display = "flex";
    spinnerNode.style.alignItems = "center";
    spinnerNode.style.justifyContent = "center";
    spinnerNode.style.backgroundColor = "rgba(32, 32, 32, 0.650)";
    spinnerNode.style.zIndex = "99999";
    spinnerNode.innerHTML = customSpinner ? (
      // eslint-disable-next-line react/react-in-jsx-scope, react/jsx-filename-extension, react/no-danger
      <span dangerouslySetInnerHTML={{ __html: customSpinner }} />
    ) : (
      '<span class="h1 custom-text-primary fas fa-spinner fa-spin" />'
    );
    target.appendChild(spinnerNode);
  } catch (error) {
    console.log(error.message);
  }
};

export const removeSpinner = () => {
  try {
    const spinnerNode = document.getElementById(INJECTED_SPINNER_ID);
    if (spinnerNode) spinnerNode.remove();
  } catch (error) {
    console.log(error.message);
  }
};

export const transformSocketData = (socketData) => {
  if (socketData && socketData.status === "success") {
    return {
      data: socketData.data || null,
      isSuccess: true,
      isError: false,
    };
  }
  return {
    data: socketData.data || null,
    isSuccess: false,
    isError: true,
    errorMessage: socketData.data || "Something went wrong",
  };
};

export const getEventRemainingDays = (eventDate) => {
  return moment(moment(eventDate).add("1", "day")).diff(moment(), "days");
};

export const getDummyVenueImage = (image, isCard = false) => {
  if (image) return `${STORAGE_URL}${image}`;
  if (isCard) return "/assets/images/dummy-card-image.jpg";
  return "/assets/images/dummy-image.jpg";
};

export const getDummyCongressImage = (image, isCard = false) => {
  if (image) return image;
  if (isCard) return "/assets/images/dummy-card-image.jpg";
  return "/assets/images/dummy-image.jpg";
};

export const formatDecimalValue = (value, numbers = 2) => {
  if (value) {
    return parseFloat(value).toFixed(numbers);
  }

  return "0";
};

export const getSearchParamObj = (search = new URLSearchParams()) => {
  return Object.fromEntries(search);
};

export const getSearchParams = (searchObj) => {
  return new URLSearchParams(searchObj).toString();
};

export const formatUnderScoreText = (value = "") => {
  if (value) {
    return value?.split("_").join(" ")?.toLowerCase();
  }

  return "";
};

// map details
// const location = {
//   latitude: "25.184702",
//   longitude: "55.255016",
//   venue_name: "Hilton Dubai Al Habtoor City",
//   thumbnail: {
//     thumbnail: "/assets/images/home/banner-home.jpg",
//   },
// };
