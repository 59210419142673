import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import "./Login.scss";
import { login } from "../../redux/actions/authActions";

const Login = (props) => {
  const { doLogin, profile } = props;
  const { isAuthenticated } = props;
  const { location } = props;
  const { state } = location;
  const [account, setAccount] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [errors, setErrors] = useState({
    account: null,
    username: null,
    password: null,
  });

  function handleLogin(e) {
    e.preventDefault();
    if (!account)
      return setErrors((prevState) => ({
        ...prevState,
        account: "Please provide account name",
      }));
    if (!username)
      return setErrors((prevState) => ({
        ...prevState,
        username: "Please provide username",
      }));
    if (!password)
      return setErrors((prevState) => ({
        ...prevState,
        password: "Please provide password",
      }));

    doLogin({
      account,
      username,
      password,
    });
  }

  if (isAuthenticated && profile.account !== "partners") {
    return (
      <Redirect
        to={{
          pathname: state ? state.from.pathname + state.from.search : "/",
        }}
      />
    );
  } else {
    return (
      <div id="container">
        <div className="homeIcon">
          <img src="/assets/loginpage/icon-home.png" alt="" />
        </div>

        <div id="info">
          <img src={"assets/logo/logo.png"} alt="Not Found" id="img-discusso" />
          {/* <p>
            Login using social media to get quick access Login using social
            media to get quick access Login using social media to get quick
            access Login using social media to get quick access
          </p> */}
        </div>

        <div id="form">
          <Form className="d-flex flex-column" onSubmit={handleLogin}>
            <Form.Group controlId="formBasicAccname">
              <Form.Label>Account Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter account name"
                onChange={(e) => setAccount(e.target.value)}
                onFocus={() =>
                  setErrors((prevErrors) => ({ ...prevErrors, account: null }))
                }
                isInvalid={errors.account}
              />
              <Form.Control.Feedback type="invalid">
                {errors.account}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                onChange={(e) => setUsername(e.target.value)}
                onFocus={() =>
                  setErrors((prevErrors) => ({ ...prevErrors, username: null }))
                }
                isInvalid={errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {errors.username}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() =>
                  setErrors((prevErrors) => ({ ...prevErrors, password: null }))
                }
                isInvalid={errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            {/* <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Remember me" />
            </Form.Group> */}
            <Button className="loginBtn mt-5" variant="primary" type="submit">
              Login
            </Button>
          </Form>
        </div>
      </div>
    );
  }
};

Login.propTypes = {
  doLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }),
};

Login.defaultProps = {
  isAuthenticated: false,
  location: {
    state: {
      pathname: "",
      search: "",
    },
  },
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  profile: state?.auth?.user?.profile,
});

export default connect(mapStateToProps, {
  doLogin: login,
})(Login);
