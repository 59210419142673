import React from "react";
import moment from "moment";
import { Table, Button } from "react-bootstrap";
import { useFormikContext, FieldArray } from "formik";
import { MdRemoveCircle } from "react-icons/md";
import FormikField from "../../../shared/components/form/FormikField";
import FormikSelect from "../../../shared/components/form/FormikSelect";
import FormikDatePicker from "../../../shared/components/form/FormikDatePicker";
import FormikCheckboxes from "../../../shared/components/form/FormikCheckboxes";
import ServiceQuestionController from "./ServiceQuestionController";
import RFPFieldWrapper from "./RFPFieldWrapper";
import {
  hotelRatingOptions,
  occupancyOptions,
  mealPlanOptions,
} from "../utils/constants";
import { singleAccomodationRoom } from "../utils/initialValues";

const Accommodation = () => {
  const { values } = useFormikContext();

  return (
    <div className="w-100">
      <ServiceQuestionController
        questionText="Do you require Accommodation?"
        flag="accomodation_flag"
        stepNo={5}
      >
        <RFPFieldWrapper label="Hotel Rating">
          <FormikCheckboxes
            name="rfpaccomodation.hotel_rating"
            options={hotelRatingOptions}
          />
        </RFPFieldWrapper>
        <RFPFieldWrapper label="Check-in">
          <FormikDatePicker
            name="rfpaccomodation.check_in"
            placeholder="YYYY/MM/DD"
            minDate={moment().toDate()}
          />
        </RFPFieldWrapper>
        <RFPFieldWrapper label="Check-out">
          <FormikDatePicker
            name="rfpaccomodation.check_out"
            placeholder="YYYY/MM/DD"
            disabled={!values.rfpaccomodation.check_in}
            minDate={
              values.rfpaccomodation.check_in
                ? moment(values.rfpaccomodation.check_in).toDate()
                : moment().toDate()
            }
          />
        </RFPFieldWrapper>
        <FieldArray
          name="rfpaccomodation.accomodation_rooms"
          render={({ form, push, remove }) => {
            return (
              <Table className="rfp-table">
                <thead>
                  <tr>
                    <td width="25%">No. of Rooms</td>
                    <td width="25%">Occupancy</td>
                    <td width="25%">Meal Plan</td>
                    <td width="25%">Remarks</td>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {form.values.rfpaccomodation.accomodation_rooms.map(
                      (_, index) => (
                        <tr key={index}>
                          <td width="25%">
                            <FormikField
                              type="number"
                              name={`rfpaccomodation.accomodation_rooms[${index}].no_of_rooms`}
                              placeholder="Rooms"
                            />
                          </td>
                          <td width="25%">
                            <FormikSelect
                              name={`rfpaccomodation.accomodation_rooms[${index}].occupancy`}
                              options={occupancyOptions}
                              placeholder="Occupancy"
                            />
                          </td>
                          <td width="25%">
                            <FormikSelect
                              name={`rfpaccomodation.accomodation_rooms[${index}].meal_plan`}
                              options={mealPlanOptions}
                              placeholder="Meal Plan"
                            />
                          </td>
                          <td width="25%">
                            <FormikField
                              name={`rfpaccomodation.accomodation_rooms[${index}].remarks`}
                              placeholder="Remarks"
                            />
                          </td>
                          {form.values.rfpaccomodation.accomodation_rooms
                            .length > 1 && (
                            <MdRemoveCircle
                              size={20}
                              className="remove-icon table-rows-remove-icon"
                              onClick={() => remove(index)}
                            />
                          )}
                        </tr>
                      )
                    )}
                    <tr className="d-flex">
                      <Button
                        className="mt-5 button small-button"
                        onClick={() => push(singleAccomodationRoom)}
                      >
                        + Add Room
                      </Button>
                    </tr>
                  </React.Fragment>
                </tbody>
              </Table>
            );
          }}
        />
      </ServiceQuestionController>
    </div>
  );
};

export default Accommodation;
