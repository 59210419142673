import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

// COMPONENTS & UTILITIES
import useLazyFetchRequest from "../../components/common/custom-hooks/useLazyFetchRequest";
import {
  getCitySuggestionsQuery,
  getLocationSuggestionsQuery,
} from "../../services/venue-sourcing";
import { useTransformSearchSuggestions } from "../../custom-hooks/useTransformers";
import PageWrapper from "../../components/common/PageWrapper";
import "./index.scss";
import { useTransformCitySuggestions } from "./custom-hooks/useTransformers";

const Venue = () => {
  const history = useHistory();
  const [locationVal, setLocationVal] = useState("");
  const [cityVal, setCityVal] = useState("");

  // API HOOKS
  const [
    getLocationSuggestions,
    {
      data: locationData,
      isSuccess: isLocationSuccess,
      isLoading: isLocationLoading,
    },
  ] = useLazyFetchRequest(getLocationSuggestionsQuery);
  const [
    getCitySuggestions,
    { data: cityData, isLoading: isCityLoading, isSuccess: isCitySuccess },
  ] = useLazyFetchRequest(getCitySuggestionsQuery);
  const locationOptions = useTransformSearchSuggestions(locationData);
  const cityOptions = useTransformCitySuggestions(cityData);

  const handleFormSubmit = () => {
    if (locationVal || cityVal) {
      history.push(
        `/venue_sourcing/venues?search=${encodeURIComponent(
          locationVal
        )}&city=${cityVal}`
      );
    }
  };

  const handleSelectLocation = (item) => {
    setLocationVal(item[0]?.value || "");
  };

  const handleSearchLocation = async (string) => {
    await getLocationSuggestions(string);

    setLocationVal(string);
  };

  const handleSelectCity = (item) => {
    setCityVal(item[0]?.value || "");
  };

  const handleSearchCity = async (string) => {
    await getCitySuggestions(string);

    setCityVal(string);
  };

  return (
    <PageWrapper
      bannerTitle="Venue Sourcing"
      bannerImgSrc="url(/assets/images/events/venue-sourcing-banner.jpg)"
    >
      <Container className="venue-search-wrapper text-center CustomizeContainer">
        <div className="form-wrapper">
          <form
            className="row justify-content-center custom-form-row no-gutters"
            onSubmit={handleFormSubmit}
          >
            <div className="col-6 col-md-7">
              <AsyncTypeahead
                id="location"
                filterBy={() => true}
                isLoading={isLocationSuccess && isLocationLoading}
                onChange={handleSelectLocation}
                onSearch={handleSearchLocation}
                options={locationOptions}
                placeholder="Type Location/Venue..."
                minLength={2}
              />
            </div>

            <div className="col-6 col-md-3">
              <AsyncTypeahead
                id="city"
                filterBy={() => true}
                isLoading={isCityLoading && isCitySuccess}
                onChange={handleSelectCity}
                onSearch={handleSearchCity}
                options={cityOptions}
                placeholder="Type City..."
                minLength={2}
              />
            </div>

            <div className="col col-md-1 venue-search">
              <button className="searchBtn" type="submit">
                <img src="/assets/pages/congress/7.png" alt="Search-icon" />
              </button>
            </div>
          </form>
        </div>

        <div className="map mb-0" />
      </Container>
    </PageWrapper>
  );
};

export default Venue;
