import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Input from "../../../components/customInputField";

const MedOthers = ({ setStatus, register, errors }) => {
  const [showResults, setShowResults] = useState(false);
  const onClick = (e) => {
    e.preventDefault();
    setShowResults(true);
  };

  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="service">Service:</label>
        </Col>
        <Col className="px-0" md={6}>
          <Input
            name="others.service"
            inputRef={register({ required: true })}
            error={errors?.others?.requirements && "This field is required!"}
          />
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row>
        <Col xs={12} className="px-0">
          <div className="gen_inquiry_text">
            <p className="text-lg-150 text-125 mb-2">
              Requirements
            </p>
          </div>
          <Input
            textArea
            name="others.requirements"
            inputRef={register({ required: true })}
            error={errors?.others?.requirements && "This field is required!"}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="px-0">
          <div className="float-right">
            <button className="Button" onClick={onClick}>
              Save
            </button>
          </div>
        </Col>
      </Row>
      {showResults ? (
        <Row id="results">
          <Col sm={12} className="px-0">
            <div className="float-right w-100">
              <button
                onClick={() => setStatus("sent")}
                type="submit"
                className="Button gen_inquiry_func_buttons"
              >
                Send Request
              </button>
              <button
                onClick={() => setStatus("saved")}
                type="submit"
                className="Button gen_inquiry_func_buttons"
              >
                Send Later
              </button>
              <button className="Button gen_inquiry_func_buttons">Edit</button>
            </div>
          </Col>
        </Row>
      ) : null}
    </React.Fragment>
  );
};

export default MedOthers;
