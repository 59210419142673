import React from "react";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Form, Formik } from "formik";
import FormikField from "../../../shared/components/form/FormikField";
import LoadingButton from "../../../components/common/LoadingButton";
import FormFieldWrapper from "./FormFieldWrapper";
import { updateUser } from "../../../server/Filter";
import { myAccountFormValidationSchema } from "../utils/validationSchema";
import { myAccountFormInitialValues } from "../utils/initialValues";
import { USER } from "../utils/constants";

const MyAccount = () => {
  const alert = useAlert();
  const { user: loggedInUser = {} } = useSelector((state) => state.auth);

  const isStandardUser = loggedInUser.profile.role === USER;

  const modifiedInitialValues = {
    ...myAccountFormInitialValues,
    username: loggedInUser.username,
    email: loggedInUser.profile.email || myAccountFormInitialValues.email,
    full_name:
      loggedInUser.profile.full_name || myAccountFormInitialValues.full_name,
    profession:
      loggedInUser.profile.profession || myAccountFormInitialValues.profession,
    contact: loggedInUser.profile.contact || myAccountFormInitialValues.contact,
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await updateUser({ id: loggedInUser.id, ...values });
      alert.success("Your account details updated successfully");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrors(error.response.data);
      } else {
        alert.error("Something went wrong in updating your account details");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={modifiedInitialValues}
      validationSchema={myAccountFormValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="custom-user-form">
          <FormFieldWrapper label="Account Name">
            <input value={loggedInUser.profile.account || ""} disabled />
          </FormFieldWrapper>
          <FormFieldWrapper label="Username" required>
            <div>
              <FormikField name="username" disabled={isStandardUser} />
              {isStandardUser && (
                <small>Contact admin to change your details</small>
              )}
            </div>
          </FormFieldWrapper>
          <FormFieldWrapper label="Email" required>
            <div>
              <FormikField name="email" disabled={isStandardUser} />
              {isStandardUser && (
                <small>Contact admin to change your details</small>
              )}
            </div>
          </FormFieldWrapper>
          <FormFieldWrapper label="Full Name" required>
            <FormikField name="full_name" />
          </FormFieldWrapper>
          <FormFieldWrapper label="Profession" required>
            <FormikField name="profession" />
          </FormFieldWrapper>
          <FormFieldWrapper label="Contact Number" required>
            <FormikField name="contact" />
          </FormFieldWrapper>
          <LoadingButton
            type="submit"
            className="button primary-button"
            isLoading={isSubmitting}
            loaderColor="#fff"
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
};

export default MyAccount;
