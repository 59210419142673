import { getEventCartItems, getCongressCartItems } from "../../server/Filter";

export const GET_CART_COUNT = "GET_CART_COUNT";
export const DEC_CART_COUNT = "DEC_CART_COUNT";
export const INC_CART_COUNT = "INC_CART_COUNT";
export const GET_EVENT_CART = "GET_EVENT_CART";
export const GET_CONGRESS_CART = "GET_CONGRESS_CART";

export const getCartItems = () => async (dispatch) => {
  try {
    const eventCart = await getEventCartItems();
    const congressCart = await getCongressCartItems();
    const cartsCount = eventCart.results.count + congressCart.results.count;
    dispatch({ type: GET_CART_COUNT, payload: cartsCount });
    dispatch({ type: GET_EVENT_CART, payload: eventCart.results });
    dispatch({ type: GET_CONGRESS_CART, payload: congressCart.results });
  } catch (err) {
    console.log(err);
  }
};

export const increaseCartCount = () => ({ type: INC_CART_COUNT });

export const decreaseCartCount = () => ({ type: DEC_CART_COUNT });
