export const tableHeadings = [
  {
    id: "venue_name",
    label: "Property",
    align: "left",
  },
  {
    id: "details",
    label: "Site Inspection Details",
  },
  {
    id: "status",
    label: "Request Status",
  },
  {
    id: "comments",
    label: "Comments",
  },
];
