import React from "react";
import { BsFillCaretLeftFill } from "react-icons/bs";

function PrevArrow({ currentSlide, onClick }) {
  return (
    <div className="icons iconLeft" onClick={onClick}>
      <BsFillCaretLeftFill
        className={currentSlide === 0 ? "slider-disabled-button" : ""}
      />
    </div>
  );
}

export default PrevArrow;
