import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CartCount from "./components/cartCount";
import PrivateRoute from "./PrivateRoute";
import PartnersRoute from "./PartnersRoute";

import MyAccount from "./components/header/myaccount";
import Forbidden from "./pages/misc/Forbidden";
import NotFound from "./pages/misc/NotFound";

import Login from "./modules/login/Login";
import Home from "./pages/home";
import MedicalCongress from "./pages/medical_congress";
import Conferences from "./pages/conferences";
import Events from "./pages/events";
import Plan from "./pages/plan";
import DestinationDetails from "./pages/destinationdetails";
import Gifts from "./pages/gifts";
import Privacy from "./pages/Terms&Privacy/privacy";
import Terms from "./pages/Terms&Privacy/terms";
import ContactUs from "./pages/contactus";
import GeneralInquiryes from "./pages/generalinquiryes";
import RequestQuoteAdmin from "./pages/requestquoteadmin";
import ConferenceDetails from "./pages/condetails";
import Quotegeneral from "./pages/quotegeneral";
import ManageAccount from "./pages/manageaccount";
import ExibitionBooth from "./pages/exibitionbooth";
import BoothRequestQuote from "./pages/boothrequestquote";
import ExibitionBoothDetails from "./pages/exibitionboothdetails";
import GeneralInquiry from "./pages/generalinquiry";
import DestRequestQuote from "./pages/dest_request_quote";
import BrandingMaterial from "./pages/branding_material";
import HybridMeetings from "./pages/hybrid_meetings";
import ViewPackages from "./pages/view_packages";
import BrandingGeneralRequest from "./pages/branding_general_request";
import BrandingQuotation from "./pages/brandingQuotation";
import Congress from "./pages/congress";
import Production from "./pages/production";
import AVProduction from "./pages/av_production";
import Engagement from "./pages/engagement";
import Venue from "./pages/venue";
import Visa from "./pages/visa";
import Design from "./pages/concept_designs";
import Team from "./pages/team";
import PlanEvent from "./pages/plan_event";
import BrandingRFP from "./pages/brandingRFP";
import GiftRFP from "./pages/giftRFP";
import MedicalForm from "./pages/medical_form";
import EventForm from "./pages/event_form";
import ExhibitionForm from "./pages/exhibition_from";
import Venues from "./pages/venues";
import PreferredVenues from "./pages/preferred_venues";
import VenueDetail from "./pages/venue_details";
import PreferredVenueDetail from "./pages/preferred_venue_detail";
import StageDesign from "./pages/stage_design";
import HospitalityDeskDesign from "./pages/hospitality_desk_design";
import PhotoBoothIdeas from "./pages/photo_booth_idea";
// import StageDesignDetail from "./pages/stage_design_detail";
// import HospitalityDeskDetail from "./pages/hospitality_desk_detail";
import VenueConceptDesigns from "./pages/venue_concept_designs";
// import VenueConceptDesignDetail from "./pages/venue_concept_design_detail";
import DesignNewSix from "./pages/designnew6";
import Dashboard from "./pages/dashboard";
import LoginPage from "./pages/loginpage";
import Notification from "./pages/notifications";
import Requested from "./pages/requested";
import Confirmed from "./pages/confirmed";
import Finished from "./pages/finished";
import Cancelled from "./pages/cancelled";
import ProductLaunch from "./pages/product_launch";
import TeamNewOne from "./pages/team_detail";
import ScrollToTop from "./components/ScrollToTop";
import MyDesigns from "./pages/concept_designs/preferred_designs";
import MyDesignListItems from "./pages/concept_designs/preferred_design_list_items";
import Cart from "./pages/cart";
import RFP from "./pages/RFP";
import ServiceRequests from "./pages/service_requests/main";
import ServiceRequestDetails from "./pages/service_requests/details";
import GlobalDocumentPreviewer from "./pages/global_documents/preview";
import GlobalDocumentsEditor from "./pages/global_documents/edit";
import { getCartItems } from "./redux/actions/cartActions";
import RoomDetailPage from "./pages/venue_details/room_details_page";
import VenuesManagement from "./pages/venue_management";
import VenuesManagementForm from "./pages/venue_management_form";
import CongressManagmentForm from "./pages/congress_management_form";
import CongressManagement from "./pages/congress_management";
import MedicalCongresss from "./pages/congress_details";

const Pages = () => {
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/rfp" component={RFP} />
        <Route exact path="/service_requests" component={ServiceRequests} />
        <Route
          exact
          path="/service_requests/:requestID"
          component={ServiceRequestDetails}
        />
        <Route
          path="/destinations/:destinationName"
          component={DestinationDetails}
        />
        <Route path="/destinations" component={Plan} />
        <Route path="/cart" component={Cart} />
        <Route exact path="/medical_congress" component={MedicalCongress} />

        <Route
          // CHANGE ROUTE HERE
          path="/conferences/medical-congress"
          component={Conferences}
        />
        <Route
          path="/conferences/:conferenceName"
          component={ConferenceDetails}
        />
        <Route path="/events" component={Events} />
        <Route path="/gifts" component={Gifts} />
        <Route path="/generalinquiryes" component={GeneralInquiryes} />
        <Route path="/requestquoteadmin" component={RequestQuoteAdmin} />
        <Route path="/quotegeneral" component={Quotegeneral} />
        <Route path="/manageaccount" component={ManageAccount} />
        <Route
          path="/exhibition_booths/:id/:code"
          component={ExibitionBoothDetails}
        />

        <Route
          path="/venues-management/create"
          component={VenuesManagementForm}
        />

        <Route
          path="/venues-management/edit/:id"
          component={VenuesManagementForm}
        />
        <Route path="/venues-management" component={VenuesManagement} />

        <Route
          path="/congress-management/edit/:id"
          component={CongressManagmentForm}
        />
        <Route
          path="/congress-management/add"
          component={CongressManagmentForm}
        />

        <Route path="/exhibition_booths" component={ExibitionBooth} />
        <Route path="/boothrequestquote" component={BoothRequestQuote} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/terms" component={Terms} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/generalinquiry" component={GeneralInquiry} />
        <Route path="/dest_request_quote" component={DestRequestQuote} />
        <Route path="/branding_materials" component={BrandingMaterial} />
        <Route path="/hybrid_meetings" component={HybridMeetings} />
        <Route path="/view_packages" component={ViewPackages} />
        <Route
          path="/branding_general_request"
          component={BrandingGeneralRequest}
        />
        <Route path="/brandingQuotation" component={BrandingQuotation} />
        <Route path="/congress" component={Congress} />
        <Route path="/production" component={Production} />
        <Route path="/av_production" component={AVProduction} />
        <Route path="/engagement" component={Engagement} />
        <Route
          path="/venue_sourcing/preferred_venues/:id/:venueName"
          component={VenueDetail}
        />
        <Route
          path="/venue_sourcing/preferred_venues/:id"
          component={PreferredVenueDetail}
        />
        <Route
          path="/venue_sourcing/preferred_venues"
          component={PreferredVenues}
        />
        <Route path="/congress_management" component={CongressManagement} />
        <Route
          path="/medical_congress/conferences/:id"
          component={MedicalCongresss}
        />
        <Route
          path="/venue_sourcing/venues/:id/details/:roomId"
          component={RoomDetailPage}
        />
        <Route path="/venue_sourcing/venues/:id" component={VenueDetail} />
        <Route path="/venue_sourcing/venues" component={Venues} />
        <Route path="/venue_sourcing" component={Venue} />
        {/* <Route
          path="/concept_designs/stage_designs/:code"
          component={StageDesignDetail}
        /> */}
        <Route path="/concept_designs/stage_designs" component={StageDesign} />
        {/* <Route
          path="/concept_designs/hospitality_desk_designs/:code"
          component={HospitalityDeskDetail}
        /> */}
        <Route
          path="/concept_designs/hospitality_desk_designs"
          component={HospitalityDeskDesign}
        />
        <Route
          path="/concept_designs/photo_booth_ideas"
          component={PhotoBoothIdeas}
        />
        {/* <Route
          path="/concept_designs/venue_concept_designs/:code"
          component={VenueConceptDesignDetail}
        /> */}
        <Route
          path="/concept_designs/venue_concept_designs"
          component={VenueConceptDesigns}
        />
        <Route
          path="/concept_designs/my_designs/:id"
          component={MyDesignListItems}
        />
        <Route path="/concept_designs/my_designs" component={MyDesigns} />
        <Route path="/concept_designs" component={Design} />
        <Route path="/visa" component={Visa} />
        <Route path="/team/activities/:name" component={TeamNewOne} />
        <Route path="/team/activities" component={Team} />
        <Route path="/plan_event" component={PlanEvent} />
        <Route path="/brandingRFP" component={BrandingRFP} />
        <Route path="/giftRFP" component={GiftRFP} />
        <Route path="/medical_form" component={MedicalForm} />
        <Route path="/event_form" component={EventForm} />
        <Route path="/exhibition_from" component={ExhibitionForm} />
        <Route path="/photo_booth" component={DesignNewSix} />
        <Route path="/loginpage" component={LoginPage} />
        <Route path="/notifications" component={Notification} />
        <Route path="/requested" component={Requested} />
        <Route path="/confirmed" component={Confirmed} />
        <Route path="/finished" component={Finished} />
        <Route path="/cancelled" component={Cancelled} />
        <Route path="/product_launch/:rfpID" component={ProductLaunch} />
        <Route
          path="/global/documents/preview"
          component={GlobalDocumentPreviewer}
        />
        <Route
          path="/global/documents/edit"
          component={GlobalDocumentsEditor}
        />
        <Route path="*" component={NotFound} />
      </Switch>
      <div
        className="h-auto"
        onClick={() => {
          setDropdown(false);
        }}
      >
        {dropdown ? (
          <div
            className="position-fixed bg-dark w-100 h-100 popup_dropdown_overlay"
            style={{ opacity: "0.8" }}
          ></div>
        ) : null}
      </div>

      <div>
        <div className="humberger_container">
          <span>
            {dropdown ? (
              <CloseIcon
                className="closeIcon"
                onClick={() => {
                  setDropdown();
                }}
              />
            ) : (
              <>
                <CartCount />
                <MenuIcon
                  onClick={() => {
                    setDropdown(true);
                  }}
                />
              </>
            )}
          </span>
        </div>

        {dropdown ? <MyAccount setDropdown={setDropdown} /> : null}
      </div>
    </React.Fragment>
  );
};

const wrappedRoutes = () => <Route path="/" component={Pages} />;

const SingleRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/partners-login" component={LoginPage} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forbidden" component={Forbidden} />
        <PartnersRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/" component={wrappedRoutes} />
      </Switch>
    </Router>
  );
};

export default SingleRouter;
