import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Row } from "react-bootstrap";
import Pagination from "react-js-pagination";

import ProductionCategory from "../production_category";
import { getAvEquipments } from "../../../server/Filter";
import changeQueryParameters from "../../../utils/changeQueryParameters";
import "./index.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const equipmentsPerPage = 8;

const ProductionCategories = ({ cartState }) => {
  const query = useQuery();
  const history = useHistory();

  const [avEquipments, setAvEquipments] = useState(null);
  const [activePage, setCurrentPage] = useState(1);

  const category = query.get("category");
  const page = query.get("page");

  useEffect(() => {
    const pageNo = page ? parseInt(page) : 1;
    setCurrentPage(pageNo);
    getAvEquipments(pageNo, category)
      .then((data) => setAvEquipments(data))
      .catch((err) => console.log(err));
  }, [page, category]);

  const avEquipmentsArray =
    avEquipments?.results?.length > 0
      ? avEquipments.results.map((equipment) => ({
          id: equipment.id,
          image: `${equipment.thumbnail}`,
          title: equipment.equipment_title,
          code: equipment.code,
          price: equipment.price,
          description: equipment.description,
          sliderImages: equipment.slider_images,
        }))
      : [];

  const { state } = useLocation();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const queryParameters = changeQueryParameters(query, "page", pageNumber);
    if (pageNumber !== page) {
      history.push({
        pathname: "/av_production",
        search: queryParameters,
        state,
      });
    }
  };
  console.log(avEquipmentsArray);
  return (
    <React.Fragment>
      <Row className="avProduction_row">
        {avEquipmentsArray?.length > 0 &&
          avEquipmentsArray?.map((avEquipment) => (
            <ProductionCategory
              key={avEquipment.id}
              avEquipment={avEquipment}
              cartState={cartState}
            />
          ))}
        {avEquipmentsArray?.length === 0 && <h5 className="">No Item!</h5>}
      </Row>
      {avEquipmentsArray?.length > 8 && (
        <div className="paginations avProduction_paginations">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={equipmentsPerPage}
            totalItemsCount={avEquipments?.count}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ProductionCategories;
