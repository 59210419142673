/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useAlert } from "react-alert";

// COMPONENTS & UTILITIES
import FormikGalleryField from "../../../shared/components/form/FormikGalleryField";
import {
  meetingRoomDefaultValues,
  meetingVenueSpaceInitValues,
  meetingVenueSpaceValSchema,
} from "../utilities/formUtils";
import FormikCreatableSelect from "../../../shared/components/form/FormikCreatableSelect";
import FormikModernField from "../../../shared/components/form/FormikModernField";
import FormikSubmitBtn from "../../../shared/components/form/FormikSubmitBtn";
import { getVenueMeetingSpaceDetailsQuery } from "../../../services/venue-details";
import FormikModernFileField from "../../../shared/components/form/FormikModernFileField";
import { deleteURL, uploadURL } from "../utilities/constants";
import { updateVenueSpaceMutation } from "../../../services/venue-management";
import { getUpdatedMeetingVenuesData } from "../utilities/helpers";
import { useGetFormContext } from "../context/FormContext";

function MeetingVenuesSpaceTabPanel() {
  const alert = useAlert();
  const [initValues, setInitValues] = useState(meetingVenueSpaceInitValues);
  const { setCurrentTab, setPayload, venueId } = useGetFormContext();

  useEffect(() => {
    (async () => {
      if (venueId) {
        const resp = await getVenueMeetingSpaceDetailsQuery(venueId);

        if (resp) {
          const newValues = getUpdatedMeetingVenuesData(resp);

          setInitValues((prevState) => ({
            ...prevState,
            ...newValues,
          }));
        }
      }
    })();
  }, [venueId]);

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={meetingVenueSpaceValSchema}
      onSubmit={async (values) => {
        if (venueId) {
          const resp = await updateVenueSpaceMutation(venueId, values);

          if (resp.status === 400) {
            alert.error("Something went wrong");
            return;
          }

          const getUpdatedResp = await getVenueMeetingSpaceDetailsQuery(
            venueId
          );
          const newValues = getUpdatedMeetingVenuesData(getUpdatedResp);

          setInitValues((prevState) => ({
            ...prevState,
            ...newValues,
          }));

          alert.success("Updated Successfully");
          return;
        }

        setPayload((prevState) => ({ ...prevState, ...values }));
        setCurrentTab((prevState) => prevState + 1);
      }}
    >
      {({ values }) => (
        <Form>
          <div className="mb-5 pb-3">
            <p className="fw-bold">Floor Plans</p>
            <FormikModernFileField
              name="floor_plans"
              uploadURL={uploadURL}
              deleteURL={deleteURL}
            />
          </div>

          <div>
            <p className="fw-bold">Meeting Rooms</p>

            <FieldArray name="meeting_rooms">
              {({ remove, push }) => (
                <>
                  {values?.meeting_rooms?.map((room, index, arr) => (
                    <div
                      className="d-flex flex-column is-grid w-100 mb-2"
                      key={index}
                    >
                      <FormikGalleryField
                        name={`meeting_rooms[${index}].image`}
                        uploadURL={uploadURL}
                        deleteURL={deleteURL}
                        multiple={false}
                      />

                      <div className="d-flex align-items-start w-100 flex-wrap is-grid">
                        <FormikModernField
                          label="Room Name"
                          name={`meeting_rooms[${index}].room_name`}
                          isRequired
                          isGrid
                        />

                        <FormikModernField
                          label="Room Size"
                          name={`meeting_rooms[${index}].total_space`}
                          isRequired
                          isGrid
                        />

                        <div className="d-flex align-items-start is-grid w-100">
                          <FormikModernField
                            label="Room Length"
                            type="number"
                            name={`meeting_rooms[${index}].length`}
                            isRequired
                          />

                          <FormikModernField
                            label="Room Width"
                            type="number"
                            name={`meeting_rooms[${index}].width`}
                            isRequired
                          />

                          <FormikModernField
                            label="Room Height"
                            type="number"
                            name={`meeting_rooms[${index}].height`}
                            isRequired
                          />
                        </div>

                        <FormikModernField
                          label="Description"
                          name={`meeting_rooms[${index}].description`}
                          isRequired
                        />

                        <FormikCreatableSelect
                          name={`meeting_rooms[${index}].amenities`}
                          label="Amenities"
                        />

                        <TableContainer>
                          <Table style={{ minWidth: "1600px" }}>
                            <TableHead>
                              <TableRow>
                                <TableCell>Banquet Rounds</TableCell>
                                <TableCell>Board Room Conference</TableCell>
                                <TableCell>Class Room</TableCell>
                                <TableCell>Cocktail Rounds</TableCell>
                                <TableCell>Crescent Rounds</TableCell>
                                <TableCell>E-Shaped</TableCell>
                                <TableCell>Maximum Capacity</TableCell>
                                <TableCell>Hollow Square</TableCell>
                                <TableCell>Perimeter Seating</TableCell>
                                <TableCell>Royal Conference</TableCell>
                                <TableCell>Talk Show</TableCell>
                                <TableCell>Theater</TableCell>
                                <TableCell>T-Shaped</TableCell>
                                <TableCell>U-Shaped</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].banquet_rounds`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].board_room_conference`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].class_room`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].cocktail_rounds`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].crescent_rounds`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].e_shaped`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].maximum_capacity`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].hollow_square`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].perimeter_seating`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].royal_conference`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].talk_show`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].theater`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].t_shaped`}
                                  />
                                </TableCell>

                                <TableCell>
                                  <FormikModernField
                                    name={`meeting_rooms[${index}].u_shaped`}
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <div className="d-flex align-items-center gap-1">
                          {arr?.length > 1 && (
                            <button
                              type="button"
                              className="btn-main-secondary sm"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </button>
                          )}

                          {arr.length - 1 === index && (
                            <button
                              type="button"
                              className="btn-main-primary sm"
                              onClick={() => push(meetingRoomDefaultValues)}
                            >
                              Add
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </FieldArray>
          </div>

          <FormikSubmitBtn label={venueId ? "Submit" : "Next"} />
        </Form>
      )}
    </Formik>
  );
}

export default MeetingVenuesSpaceTabPanel;
