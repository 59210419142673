import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import DatePicker from "../../../components/customDatePicker";
import Input from "../../../components/customInputField";

const MedMainForm = ({ addServiceForms, register, control, errors }) => {
  const profile = useSelector((state) => state.auth.user.profile);

  const text = "Save";
  const newText = "Edit";
  // const [showSearchType, setShowSearchType] = useState("");
  const [edit, setEdit] = useState(text);
  const changeType = (e) => {
    e.preventDefault();
    setEdit((edit) => (edit === text ? newText : text));
    // setShowSearchType("MedServiceType");
    if (edit === text) {
      addServiceForms();
    }
  };
  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" xl={3} md={5}>
          <label htmlFor="account_name">ACCOUNT NAME:</label>
        </Col>
        <Col xl={6} md={5} className="px-0">
          <Input
            type="text"
            name="account_name"
            value={profile.account}
            disabled
          />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" xl={3} md={5}>
          <label htmlFor="email_address">EMAIL:</label>
        </Col>
        <Col xl={6} md={5} className="px-0">
          <Input
            type="email"
            name="email_address"
            value={profile.email}
            disabled
          />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" xl={3} md={5}>
          <label htmlFor="congress_name">CONGRESS NAME:</label>
        </Col>
        <Col xl={6} md={5} className="px-0">
          <Input
            type="text"
            name="congress_name"
            inputRef={register({ required: true })}
            error={errors.congress_name && "Congress Name is required!"}
          />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" xl={3} sm={5} xs={6}>
          <div className="d-flex">
            <label htmlFor="congress_date">CONGRESS DATE:</label>
            <img
              style={{ marginRight: "5px" }}
              className="calendar ml-auto"
              src={"assets/images/download.jpg"}
              alt="Not Found"
            />
          </div>
        </Col>
        <Col xl={6} md={5} sm={7} xs={6} className="px-0">
          <DatePicker name="congress_date" control={control} />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" xl={3} md={5}>
          <label htmlFor="num_delegates">NUMBER OF DELEGATES:</label>
        </Col>
        <Col xl={6} md={5} className="px-0">
          <Input
            type="text"
            name="num_delegates"
            inputRef={register({ required: true })}
            error={errors.num_delegates && "Number of Delegates is required!"}
          />
        </Col>
      </Row>
      <div className="float-right w-100 plan_event_div">
        <button
          className="Button gen_inquiry_func_buttons"
          onClick={changeType}
        >
          {edit}
        </button>
        {edit === text ? (
          <button className="Button gen_inquiry_func_buttons">
            Cancel RFP
          </button>
        ) : null}
      </div>
      {/* { showSearchType === "MedServiceType" ? <MedServiceType setTab={setTab} /> : null } */}
    </React.Fragment>
  );
};

export default MedMainForm;
