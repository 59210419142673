export const CLEAR_PRODUCT_LAUNCH_DATA = "CLEAR_PRODUCT_LAUNCH_DATA";
export const SAVE_RFP_DETAILS = "SAVE_RFP_DETAILS";

export const clearProductLaunchData = () => ({
  type: CLEAR_PRODUCT_LAUNCH_DATA,
});

export const saveRFPDetailsData = (details) => ({
  type: SAVE_RFP_DETAILS,
  payload: details,
});
