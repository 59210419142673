import React from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ButtonWithToolTip from "../ButtonWithToolTip";
import { downloadFileWithURL } from "../../../utils/helpers";
import { isSpreadSheetOrDocument } from "./utils/helpers";
import "./styles/main.scss";

const DocumentViewer = ({ fileURL, fileName = "Attachment" }) => {
  if (!fileURL) return null;

  return (
    <DocViewer
      className="document-viewer"
      pluginRenderers={DocViewerRenderers}
      documents={[
        {
          // Passing timestamp in every request just to bypass cache because it cause cross origin issue
          // and uses cache when download request is dispatched
          uri: `${fileURL}?timestamp=${new Date().getTime()}`,
          fileType: fileURL.split("?")[0].split(".").pop(),
          fileName,
        },
      ]}
      config={{
        header: {
          disableHeader: true,
          disableFileName: true,
        },
        pdfVerticalScrollByDefault: true,
      }}
      theme={{
        primary: "#183765",
        secondary: "#48B2E8",
        tertiary: "#183765",
        disableThemeScrollbar: false,
      }}
    />
  );
};

export const DocumentViewerPopup = ({
  isOpen,
  toggle,
  fileURL,
  fileName = "document",
  editDocumentOptions = {},
  allowDocumentEdit = false,
}) => {
  const history = useHistory();

  return (
    <Modal show={isOpen} onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        {fileURL && (
          <React.Fragment>
            <div className="d-flex justify-content-end mb-3">
              {/* Show edit button if fle is spreadsheet or document */}
              {allowDocumentEdit && isSpreadSheetOrDocument(fileURL) && (
                <ButtonWithToolTip
                  className="transparent-button mr-3"
                  tooltipText="Edit File"
                  onClick={() => {
                    history.push("/global/documents/edit", {
                      fileData: {
                        fileName,
                        fileURL,
                      },
                      ...editDocumentOptions,
                    });
                  }}
                >
                  <EditIcon />
                </ButtonWithToolTip>
              )}
              {/* Download Button */}
              <ButtonWithToolTip
                className="transparent-button"
                tooltipText="Download File"
                onClick={() => downloadFileWithURL(fileURL, fileName)}
              >
                <CloudDownloadIcon />
              </ButtonWithToolTip>
            </div>
            <DocumentViewer fileURL={fileURL} fileName={fileName} />
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DocumentViewer;
