import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import MedServeiceButtons from "../med_service_buttons";
import Input from "../../../components/customInputField";

const MedRegistration = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
    register,
    control,
    errors,
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "registration.categories",
  });

  const disableRegistrationType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      registrationDisabled: true,
    }));
  };

  const enableRegistrationType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      registrationDisabled: false,
    }));
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={3} sm={1} className="px-0"></Col>
        <Col lg={6} sm={10} className="px-0 form_main">
          <Table bordered hover>
            <thead>
              <tr>
                <th style={{ width: "50%" }}>CATEGORY</th>
                <th style={{ width: "50%" }}>NUMBER OF DELEGATES</th>
              </tr>
            </thead>
            <tbody>
              {fields.map(({ id, category, num_delegates }, index) => (
                <tr key={id} className="reg_table">
                  <td className="p-0">
                    <Input
                      // classes="w-100 border-0 bg-transparent text-center"
                      placeholder="Add Category Value"
                      inputRef={register({ required: true })}
                      name={`registration.categories[${index}].category`}
                      defaultValue={category}
                      error={
                        errors?.registration?.categories &&
                        errors?.registration?.categories[index]?.category &&
                        "Category is required!"
                      }
                    />
                  </td>
                  <td className="p-0">
                    <Input
                      // className="w-100 border-0 bg-transparent text-center"
                      placeholder="Add Quantity"
                      inputRef={register({ required: true })}
                      name={`registration.categories[${index}].num_delegates`}
                      defaultValue={num_delegates}
                      error={
                        errors?.registration?.categories &&
                        errors?.registration?.categories[index]
                          ?.num_delegates &&
                        "Number of delegates is required!"
                      }
                    />
                    {index !== 0 && (
                      <div className="item-actions-container">
                        <div className="item-actions">
                          <span
                            tabIndex="0"
                            role="button"
                            className="action"
                            onClick={() => remove(index)}
                          >
                            Delete
                          </span>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col lg={3} sm={12} className="mt-auto mb-3">
          <button
            className="Button_hide float-left"
            onClick={(e) => {
              e.preventDefault();
              append({ categories: "", num_delegates: "" });
            }}
          >
            +Add Category
          </button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="px-0">
          <div className="gen_inquiry_text">
            <p className="text-lg-150 text-125 mb-2">
              Remarks
            </p>
          </div>
          <Input
            textArea
            name="registration.remarks"
            inputRef={register({ required: true })}
            error={errors?.registration?.remarks && "Remarks is required!"}
          />
        </Col>
      </Row>
      <MedServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableRegistrationType}
        enableSelectedType={enableRegistrationType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default MedRegistration;
