export const COORDINATION = "COORDINATION";
export const PLANNING = "PLANNING";
export const CLOSEOUT = "CLOSEOUT";
export const SERVICE_REQUESTS = "SERVICE_REQUESTS";

export const RFP_DETAILS = "RFP_DETAILS";
export const QUOTATIONS = "QUOTATIONS";
export const SITE_INSPECTION = "SITE_INSPECTION";
export const EVENT_ROLES = "EVENT_ROLES";
export const HISTORY = "HISTORY";

export const ADMIN_ACCESS_ROLE = "admin";
export const OWNER_ACCESS_ROLE = "event-owner";
export const PLANNER_ACCESS_ROLE = "planner";
