import React from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import propTypes from "prop-types";

function ImageItem({ file, handleDelete }) {
  return (
    <div className="file-box">
      <img src={file?.src} alt={file?.name || ""} />

      <IconButton onClick={() => handleDelete(file)}>
        <Close />
      </IconButton>
    </div>
  );
}

ImageItem.propTypes = {
  file: propTypes.object.isRequired,
  handleDelete: propTypes.func.isRequired,
};

export default ImageItem;
