import { useMemo } from "react";
import { useSelector } from "react-redux";

function useRFPEventTableHeadings(headings = []) {
  const { user: loggedInUser = {} } = useSelector((state) => state.auth);

  const tableHeadings = useMemo(
    () =>
      ["Admin", "Sub Admin"].includes(loggedInUser.profile.role)
        ? [...headings, { label: "Created By", id: "account_name" }]
        : headings,
    [headings, loggedInUser.profile]
  );

  return { tableHeadings };
}

export default useRFPEventTableHeadings;
