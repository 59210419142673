import axios from 'axios';
import {} from './constants';

export const fetchDocumentBlob = async fileURL => {
  // passing timestamp just to bypass cache
  const { data } = await axios.get(
    `${fileURL}?timestamp=${new Date().getTime()}`,
    {
      responseType: 'blob',
    }
  );
  return data;
};

export const isSpreadSheetOrDocument = fileURL => {
  const fileExtension = fileURL.split('?')[0].split('.').pop();
  return ['docx', 'doc', 'xlsx', 'xlx'].includes(fileExtension);
};
