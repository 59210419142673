import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import PrivateDocuments from "./components/PrivateDocuments";
import SharedDocuments from "./components/SharedDocuments";
import { PRIVATE, SHARED } from "./utils/constants";
import "./styles/index.scss";

function Document() {
  const { state = {} } = useLocation();
  const [activeTabKey, setActiveTabKey] = useState(PRIVATE);

  useEffect(() => {
    if ([PRIVATE, SHARED].includes(state.subSubTab))
      setActiveTabKey(state.subSubTab);
  }, [state]);

  return (
    <React.Fragment>
      <Tabs
        id="product-launch-document-tabs"
        activeKey={activeTabKey}
        onSelect={setActiveTabKey}
        className="product-launch-document-tabs"
      >
        <Tab eventKey={PRIVATE} title="Private">
          <PrivateDocuments />
        </Tab>
        <Tab eventKey={SHARED} title="Shared">
          <SharedDocuments />
        </Tab>
      </Tabs>
    </React.Fragment>
  );
}

export default Document;
