/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";
import useDimensions from "react-cool-dimensions";
import { css } from "emotion";
import { useAlert } from "react-alert";
import Pagination from "react-js-pagination";
import Header from "../../components/header";
import Footer from "../../components/footer";
import SliderModal from "../../components/sliderModal";
import changeQueryParameters from "../../utils/changeQueryParameters";
import {
  addToCongressCart,
  addToEventCart,
  getActivities,
  removeFromCart,
} from "../../server/Filter";
import { increaseCartCount } from "../../redux/actions/cartActions";
import "./index.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const activitiesPerPage = 9;

const Team = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const alert = useAlert();

  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setwindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [heights, setHeights] = useState("");
  const responsive = css`
    height: 250px;
  `;

  const { ref } = useDimensions({
    onResize: ({ width }) => {
      var screenHeightLaptop = (((width - 96) / 3) * 9) / 14 + "px";
      var screenHeightTablet = (((width - 64) / 2) * 9) / 14 + "px";
      var screenHeightMobile = (width * 9) / 14 + "px";
      if (windowWidth > 992) {
        setHeights(screenHeightLaptop);
      } else if (windowWidth > 576 && windowWidth <= 992) {
        setHeights(screenHeightTablet);
      } else {
        setHeights(screenHeightMobile);
      }
    },
  });

  const [activities, setActivities] = useState({});
  const [activePage, setCurrentPage] = useState(1);

  const type = query.get("type");
  const page = query.get("page");

  useEffect(() => {
    const pageNo = page ? parseInt(page) : 1;
    setCurrentPage(pageNo);
    getActivities(pageNo, type)
      .then((data) => setActivities(data))
      .catch((err) => console.log(err));
  }, [page, type]);

  const activitiesList = activities.data?.map((activity) => {
    let activityType = "Indoor Activity";
    if (activity.activity_type?.includes("Indoor")) {
      activityType = "Indoor Activity";
    } else if (activity.activity_type?.includes("Outdoor")) {
      activityType = "Outdoor Activity";
    } else {
      activityType = "Virtual";
    }
    return {
      id: activity.id,
      image: activity.thumbnail,
      name: activity.activity_name,
      slider_images: activity.slider_images,
      type: activityType,
    };
  });

  const { state } = useLocation();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const queryString = changeQueryParameters(query, "page", pageNumber);
    if (pageNumber !== page) {
      history.push({
        pathname: "/team/activities",
        search: queryString,
        state,
      });
    }
  };

  const handleFilterChange = (type) => {
    history.push({
      pathname: "/team/activities",
      search: `?type=${type}`,
      state,
    });
  };

  const handleEventCartIconClick = async (activity) => {
    try {
      if (state && state.id) {
        await removeFromCart(state.modelName, state.modelID);
      }
      await addToEventCart("Activity", activity.id);
      if (state && state.id) {
        history.replace({
          ...history.location,
          state: null,
        });
        history.push("/cart");
        alert.show(
          `${state.descriptionFields.title} replaced with ${activity.name}`,
          {
            type: "success",
          }
        );
        history.push({
          ...history.location,
          state: null,
        });
      } else {
        dispatch(increaseCartCount());
        alert.show(`${activity.name} successfully added to events cart`, {
          type: "success",
        });
      }
    } catch (err) {
      if (err.response) {
        alert.show(err.response.data, {
          type: "error",
        });
      }
    }
  };

  const handleCongressCartIconClick = async (activity) => {
    try {
      if (state && state.id) {
        await removeFromCart(state.modelName, state.modelID);
      }
      await addToCongressCart("Activity", activity.id);
      if (state && state.id) {
        history.replace({
          ...history.location,
          state: null,
        });
        history.push("/cart");
        alert.show(
          `${state.descriptionFields.title} replaced with ${activity.name}`,
          {
            type: "success",
          }
        );
        history.push({
          ...history.location,
          state: null,
        });
      } else {
        dispatch(increaseCartCount());
        alert.show(`${activity.name} successfully added to congress cart`, {
          type: "success",
        });
      }
    } catch (err) {
      if (err.response) {
        alert.show(err.response.data, {
          type: "error",
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Container
        className="event_banner px-0 position-relative"
        style={{
          backgroundImage:
            'url("/assets/images/engagement/team-building-banner.jpg")',
        }}
        fluid
      >
        <div className="text-blocks">
          <p className="font-weight-bold text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-250 mb-0 font-poppins">
            Team Building Activity
          </p>
        </div>
      </Container>
      <Row className="BackBtn">
        <Col xl={12} className="myback_button">
          <Link to="/engagement" className="back_button">
            <img
              src="/assets/images/icon-back.png"
              className="icon_back"
              alt=""
            />{" "}
            Back
          </Link>
        </Col>
      </Row>
      <Container fluid className="resetFilterContainer">
        <Row className="">
          {type && (
            <Col xs={12} className="team-reset-filters">
              <Link to="/team/activities">Reset Filters</Link>
            </Col>
          )}
        </Row>
      </Container>
      <Container className="min-height-300 CustomizeContainer">
        <Row className="justify-content-center">
          <div className="d-flex mb-7 ">
            <button
              className={
                type === "Indoor"
                  ? "Button_hide pl-4 pr-4 font-weight-bold teamBuildingBtn"
                  : "Button_hide pl-4 pr-4"
              }
              style={{ color: "#071F4E", borderRight: "2px solid #071F4E" }}
              onClick={() => handleFilterChange("Indoor")}
            >
              Indoor
            </button>
            <button
              className={
                type === "Outdoor"
                  ? "Button_hide pl-4 pr-4 font-weight-bold teamBuildingBtn"
                  : "Button_hide pl-4 pr-4"
              }
              style={{ color: "#071F4E", borderRight: "2px solid #071F4E" }}
              onClick={() => handleFilterChange("Outdoor")}
            >
              Outdoor
            </button>
            <button
              className={
                type === "Virtual"
                  ? "Button_hide pl-4 font-weight-bold teamBuildingBtn"
                  : "Button_hide pl-4"
              }
              style={{ color: "#071F4E" }}
              onClick={() => handleFilterChange("Virtual")}
            >
              Virtual
            </button>
          </div>
          {/* <SliderModal show={show} setShow={setShow} /> */}
          <Row ref={ref} className="w-100">
            {activitiesList?.map((activity, index) => (
              <Col sm={6} lg={4} className="mb-7 activity-tile" key={index}>
                <div className="teamBuildingCustomization">
                  <SliderModal
                    sliderImages={activity.slider_images}
                    sliderClasses="teamBuildingSlider"
                    imageClasses="teamBuildingImage"
                  />
                  {/* <Slider {...settings} className="teamBuildingSlider">
                  {activity.slider_images?.map((item) => (
                    <Image
                      key={item.image}
                      src={item.image}
                      className={"w-100 teamBuildingImage "}
                      onDoubleClick={handleShow}
                    />
                  ))}
                </Slider> */}

                  <div className="d-flex flex-column justify-content-center activity-tile-bottom-detail pl-5">
                    <p className="font-weight-bold text-lg-120 text-100 mb-0">
                      {activity.name}
                    </p>
                    <p className="mb-0" style={{ fontSize: "0.7em" }}>
                      {activity.type}
                    </p>
                  </div>
                  <div className="teamSliderCartIcon">
                    <img src="/assets/images/exhibition/icon.png" alt="" />
                    <div className="hover_div">
                      <span
                        className="event_span cursor-pointer"
                        onClick={() => handleEventCartIconClick(activity)}
                      >
                        Events
                      </span>
                      <span
                        className="cursor-pointer"
                        onClick={() => handleCongressCartIconClick(activity)}
                      >
                        Congress
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Row>
      </Container>
      {activities?.count > activitiesPerPage && (
        <Container>
          <Row className="text-center pagination123">
            <div className="paginations">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={activitiesPerPage}
                totalItemsCount={activities.count}
                pageRangeDisplayed={4}
                onChange={handlePageChange}
              />
            </div>
          </Row>
        </Container>
      )}
      <Footer />
    </React.Fragment>
  );
};

export default Team;
