import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Pagination from "react-js-pagination";

import AvPackage from "../av_package";
import { getAvPackages } from "../../../server/Filter";
import "./index.scss";

const AvPackages = () => {
  const [avPackages, setAvPackages] = useState(null);

  useEffect(() => {
    getAvPackages()
      .then((data) => setAvPackages(data))
      .catch((err) => console.log(err));
  }, []);

  const avPackagesArray = avPackages?.map((avPackage) => ({
    id: avPackage.id,
    name: avPackage.package_name,
    sliderImages: avPackage.slider_images,
  }));

  const avPackagesArrayPerPage = 8;
  const [activePage, setCurrentPage] = useState(1);
  const indexOfLastAvPackage = activePage * avPackagesArrayPerPage;
  const indexOfFirstavPackage = indexOfLastAvPackage - avPackagesArrayPerPage;
  const currentAvPackagesArray = avPackagesArray?.slice(
    indexOfFirstavPackage,
    indexOfLastAvPackage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <React.Fragment>
      <Row className="branding_category_row">
        {avPackagesArray?.length > 0 &&
          currentAvPackagesArray.map((avPackage) => (
            <AvPackage key={avPackage.id} avPackage={avPackage} />
          ))}
        {avPackagesArray?.length < 1 && (
          <p className="text-lg-100 text-75">No package found!</p>
        )}
      </Row>
      {avPackagesArray?.length > 8 && (
        <div className="paginations branding_paginations">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={8}
            totalItemsCount={avPackagesArray?.length}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default AvPackages;
