import React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { Modal } from "react-bootstrap";
import ReasonForm from "../rfp/ReasonForm";

const DEFAULT_OPTIONS = {
  title: "Reason",
  fieldLabel: "Reason",
  modalProps: {},
  modalHeaderProps: {},
  modalBodyProps: {},
};

const useCustomReasonModal = () => {
  const reasonAlert = (options = DEFAULT_OPTIONS) => {
    const { title, fieldLabel, modalProps, modalHeaderProps, modalBodyProps } =
      {
        title: options.title || DEFAULT_OPTIONS.title,
        fieldLabel: options.fieldLabel || DEFAULT_OPTIONS.fieldLabel,
        modalProps: options.modalProps || DEFAULT_OPTIONS.modalProps,
        modalHeaderProps:
          options.modalHeaderProps || DEFAULT_OPTIONS.modalHeaderProps,
        modalBodyProps:
          options.modalBodyProps || DEFAULT_OPTIONS.modalBodyProps,
      };

    return new Promise((resolve, reject) => {
      let element = document.createElement("div");

      const unMountPopup = () => {
        unmountComponentAtNode(element);
        element = null;
      };

      const handleResolve = (result) => {
        unMountPopup();
        resolve(result);
      };

      const handleReject = (result) => {
        unMountPopup();
        reject(result);
      };

      // Here we can override props by passing from source
      render(
        <Modal show onHide={handleReject} {...modalProps}>
          <Modal.Header closeButton {...modalHeaderProps}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body {...modalBodyProps}>
            <ReasonForm label={fieldLabel} onSubmit={handleResolve} />
          </Modal.Body>
        </Modal>,
        element
      );
    });
  };

  return reasonAlert;
};

export default useCustomReasonModal;
