import React from "react";
import { Container } from "react-bootstrap";
import ChatModule from "../../../../components/chat_module";
import { withMultiFilePicker } from "../../../../components/common/multifile-picker";
import { useServiceRequestDetailsContext } from "../context/DetailsContext";
import ServiceRequestChatProvider, {
  ServiceRequestChatContext,
} from "../context/ChatContext";
import MessageForm from "./MessageForm";
import { getConnectionColor } from "../utils/helpers";

const ServiceRequestChatContainer = () => {
  const { isRequestClosed } = useServiceRequestDetailsContext();

  return (
    <Container className="p-0 mt-10">
      <ServiceRequestChatProvider>
        <ServiceRequestChatContext.Consumer>
          {({
            isLoading,
            containerRef,
            messages,
            readyState,
            connectionStatus,
            setLastMessageRef,
          }) => (
            <React.Fragment>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <h5 className="font-weight-bolder">Comment History</h5>
                <span className="d-flex align-items-center">
                  <span
                    className="socket-connection-badge"
                    style={{
                      backgroundColor: getConnectionColor(readyState),
                    }}
                  />
                  <h6 className="mb-0">{connectionStatus}</h6>
                </span>
              </div>
              <ChatModule
                isLoading={isLoading}
                messages={messages}
                messagesContainerRef={containerRef}
                setLastMessageRef={setLastMessageRef}
              />
              {/* Donot allow messages when request is closed */}
              {!isRequestClosed && <MessageForm />}
            </React.Fragment>
          )}
        </ServiceRequestChatContext.Consumer>
      </ServiceRequestChatProvider>
    </Container>
  );
};

export default withMultiFilePicker(ServiceRequestChatContainer);
