import { useEffect, useState } from "react";

export const useTransformCitySuggestions = (suggestions) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (suggestions?.length > 0) {
      const transformedData = suggestions.map((item) => ({
        id: item,
        label: item,
        value: item,
      }));

      setData(transformedData);
    } else {
      setData([]);
    }
  }, [suggestions]);

  return data;
};
