import React, { createContext, useContext, useRef } from "react";

export const FileHandlerContext = createContext({
  spreadSheetRef: null,
  documentRef: null,
  saveSpreadSheetRef: () => {},
  saveDocumentRef: () => {},
});

export const useFileHandlerContext = () => useContext(FileHandlerContext);

const FileHandlerProvider = ({ children }) => {
  // Separting file refs so that we can use more than 1 file handlers in one component
  const spreadSheetRef = useRef(null);
  const documentRef = useRef(null);

  const saveSpreadSheetRef = (ref) => {
    if (ref) spreadSheetRef.current = ref;
  };

  const saveDocumentRef = (ref) => {
    if (ref) documentRef.current = ref;
  };

  return (
    <FileHandlerContext.Provider
      value={{
        spreadSheetRef,
        documentRef,
        saveSpreadSheetRef,
        saveDocumentRef,
      }}
    >
      {children}
    </FileHandlerContext.Provider>
  );
};

export default FileHandlerProvider;
