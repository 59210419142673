import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../components/header";
import Forms from "./form/form";
import Footer from "../../components/footer";
import { create_general_request_form } from "../../server/Filter";
import { useState } from "react";

const GeneralInquiryes = () => {
  const [formData, setformData] = useState({});
  function _onSubmit(formDataLogistics) {
    console.log(formDataLogistics);
    setformData({ ...formData, formDataLogistics });
    console.log(formData);
    create_general_request_form({
      ...formData,
      ...formDataLogistics,
    }).then((res) => {
      console.log(res);
    });
    alert("submitted");
  }
  return (
    <React.Fragment>
      <Header />
      <Container fluid className="px-0">
        <Row className="inner_width_div">
          <Col md={12} className="pl-sm-6 pl-5">
            <Forms
              setInput={(name, val) => {
                console.log("INPUT: ", name, val);
                var obj = {};
                obj[name] = val;
                setformData({ ...formData, ...obj });
                console.log(formData);
              }}
              _handleSubmit={_onSubmit}
            />
            {/* <Logistic onSubmit={_onSubmit} /> */}
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
//
export default GeneralInquiryes;
