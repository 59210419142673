import React, { useState, useEffect } from "react";
import useDimensions from "react-cool-dimensions";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { css } from "emotion";
import parse from "html-react-parser";
import Slider from "react-slick";
import Header from "../../components/header";
import Footer from "../../components/footer";

import { getActivityByName } from "../../server/Filter";

const TeamNewOne = () => {
  const { name } = useParams();

  // const [readMore, setReadMore] = useState("");
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const [activity, setActivity] = useState({});

  useEffect(() => {
    if (name) {
      getActivityByName(name)
        .then((data) => setActivity(data))
        .catch((err) => console.log(err));
    }
  }, [name]);

  const teamSliders = activity.slider_images
    ? activity.slider_images.map((item) => ({
        image: item.image,
      }))
    : [];

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "0px",
  };

  const [heights, setHeights] = useState("");
  const [maxHeights, setMaxHeights] = useState("");
  const [maxHeight, setMaxHeight] = useState("");
  const responsive = css`
    height: ${heights};
  `;
  const responsives = css`
    max-height: ${maxHeights};
  `;
  const Maxheight = css`
    max-height: ${maxHeight};
  `;

  const { ref } = useDimensions({
    onResize: ({ width }) => {
      let screenHeight = (width * 9) / 16 + "px";
      setHeights(screenHeight);
      setMaxHeights((width * 9) / 16 + 120 + "px");
      setMaxHeight((width * 9) / 16 - 166 + "px");
    },
  });

  const teamCount = teamSliders.length;
  let team_row = [];
  let team_rows = [];
  if (teamCount === 1) {
    for (let i = 0; i < 5; i++) {
      team_row.push(
        <div className="slick-slide" key={i}>
          <Image
            className={"w-100 " + responsive}
            ref={ref}
            src={teamSliders.map((slide) => slide.image)}
            fluid
          />
        </div>
      );
      team_rows.push(
        <div className="slick-slide venue_thumb_slider" key={i}>
          <Image
            className="slick-slide-image meeting_room_gallery_img_thumbnail"
            src={teamSliders.map((slide) => slide.image)}
            fluid
          />
        </div>
      );
    }
  } else if (teamCount === 2 || teamCount === 3) {
    for (let i = 0; i < 2; i++) {
      team_row.push(
        teamSliders.map((slide) => (
          <div className="slick-slide" key={i}>
            <Image
              className={"w-100 " + responsive}
              ref={ref}
              src={slide.image}
              fluid
            />
          </div>
        ))
      );
      team_rows.push(
        teamSliders.map((slide) => (
          <div className="slick-slide venue_thumb_slider" key={i}>
            <Image
              className="slick-slide-image meeting_room_gallery_img_thumbnail"
              src={slide.image}
              fluid
            />
          </div>
        ))
      );
    }
  } else {
    team_row.push(
      teamSliders.map((slide, index) => (
        <div className="slick-slide" key={index}>
          <Image
            className={"w-100 " + responsive}
            ref={ref}
            src={slide.image}
          />
        </div>
      ))
    );
    team_rows.push(
      teamSliders.map((slide, index) => (
        <div className="slick-slide venue_thumb_slider" key={index}>
          <Image
            className="slick-slide-image meeting_room_gallery_img_thumbnail"
            src={slide.image}
            fluid
          />
        </div>
      ))
    );
  }

  const Words = [
    {
      text: "I'm a paragraph. Double click here or click Edit Teaxt to add some text of your own or to change the font. Tell your visitors a bit about your services. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
  ];

  // function WordCount(str) {
  //   return str.split(" ").length;
  // }
  // function truncate(str, no_words) {
  //   return str.split(" ").splice(0, no_words).join(" ");
  // }
  // const limit = 30;
  const renderWord = (word, index) => {
    return (
      <Col
        lg={4}
        key={index}
        className={"text-white py-7 px-0 " + responsives}
        style={{ backgroundColor: "#071F4E" }}
      >
        <p className="px-4 text-lg-150 text-125 mb-2">
          {activity.activity_name}
        </p>
        <p className="text-125 px-4">
          {activity.activity_type === "indoor"
            ? "Indoor Activity"
            : "Outdoor Activity"}
        </p>
        <div
          className="py-4"
          style={{
            borderTop: "3px solid #fff",
            borderBottom: "3px solid #fff",
          }}
        >
          <p className="mb-0 text-125 px-4">Description</p>
        </div>
        <div className="my-7">
          <div className={"px-4 " + Maxheight} style={{ overflowY: "auto" }}>
            {activity.description && parse(activity.description.toString())}
            {/* {readMore === "read_more" ? (
              <p className="mb-0">{word.text}</p>
            ) : (
              <p className="mb-0">{truncate(word.text, limit)}</p>
            )} */}
          </div>
          {/* <div className="px-4 pt-7 text-right">
            <button
              className="Button_hide text-white"
              onClick={() =>
                setReadMore((readMore) => (readMore === "" ? "read_more" : ""))
              }
            >
              {readMore === "read_more" ? "Read Less" : "Read More"}
            </button>
          </div> */}
        </div>
      </Col>
    );
  };

  return (
    <React.Fragment>
      <Header />
      <Container className="px-0" fluid>
        <Row className="inner_width_div">
          <Col className="pl-sm-6 pl-5 pr-5 pr-sm-4">
            <div className="my-7">
              <Link to="/team/activities">
                <button className="back_button">&lt;Back</button>
              </Link>
            </div>
            <div>
              <Row className="pb-7 border-bottom-default">
                <Col
                  lg={8}
                  className="venue_thumb_slider_column1 px-0 pr-lg-4 mb-5"
                >
                  <div className="App" style={{ height: "auto" }}>
                    <div className="slider-wrapper">
                      <Slider
                        {...settingsMain}
                        asNavFor={nav2}
                        ref={(sliders) => setSlider1(sliders)}
                      >
                        {team_row}
                      </Slider>
                      <div className="thumbnail-slider-wrap">
                        <Slider
                          {...settingsThumbs}
                          asNavFor={nav1}
                          ref={(sliders) => setSlider2(sliders)}
                        >
                          {team_rows}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </Col>
                {Words.map(renderWord)}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default TeamNewOne;
