import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import EventText from "../event_text";

const EventOthers = () => {
  const [showResults, setShowResults] = useState(false);
  const onClick = (e) => {
    e.preventDefault();
    setShowResults(true);
  };
  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="service">Service:</label>
        </Col>
        <Col className="px-0" md={6}>
          <input name="service" />
        </Col>
        <Col md={3}></Col>
      </Row>
      <EventText heading="Requirements:" />
      <Row>
        <Col xs={12} className="px-0">
          <div className="float-right">
            <button className="Button" onClick={onClick}>
              Save
            </button>
          </div>
        </Col>
      </Row>
      {showResults ? (
        <Row id="results">
          <Col xs={12} className="px-0">
            <div className="float-right w-100">
              <button className="Button gen_inquiry_func_buttons">
                Send Request
              </button>
              <button className="Button gen_inquiry_func_buttons">Edit</button>
            </div>
          </Col>
        </Row>
      ) : null}
    </React.Fragment>
  );
};

export default EventOthers;
