import React, { memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Map from "../../../components/map";
import HotelMapCard from "./HotelMapCard";
import { useGetVenuesContext } from "../custom-hooks/useGetVenuesContext";

function MapModal({ venues }) {
  const [selectedVenue, setSelectedVenue] = useState(null);
  const { showMap, handleCloseMap } = useGetVenuesContext();

  useEffect(() => {
    if (venues?.length > 0) setSelectedVenue(venues[0]);
  }, [venues]);

  // HANDLERS
  const handleSelectVenue = (ven) => setSelectedVenue(ven);

  return (
    <Modal
      show={showMap}
      onHide={handleCloseMap}
      animation={false}
      keyboard={true}
      // className="map-modal-container venue_loc_modal_gallery central_modal"
      className="map-modal-container"
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <Map
          zoom={13}
          specificLocation={selectedVenue}
          data={venues}
          searchOption={true}
          setVenue={handleSelectVenue}
        />

        <div className="location-cards">
          {venues?.map((item, index) => (
            <HotelMapCard
              key={index}
              venue={item}
              handleSelectVenue={handleSelectVenue}
            />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default memo(MapModal);
