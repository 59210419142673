/* eslint-disable */

import React, { useEffect } from "react";
import axios from "axios";
import Spacing from "./styles/spacing";
import Sizing from "./styles/sizing";
import Borders from "./styles/borders";
import Overflow from "./styles/overflow";
// import Text from './styles/text';
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import SingleRouter from "./SingleRouter";
import store from "./store";
import Alerts from "./components/layout/Alerts";
import { loadUser } from "./redux/actions/authActions";
import "./App.scss";

// Alert Options
// optional cofiguration
const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_LEFT,
  timeout: 5000,
  // offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    fontSize: 12,
    zIndex: 2,
  },
};

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status === 403 &&
    error.response.config.method === "get";

  if (expectedError) {
    window.location = "/forbidden";
  }

  return Promise.reject(error);
});

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <div className="App">
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Alerts />
        <SingleRouter />
      </AlertProvider>
    </div>
  );
}

export default App;
