/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { useAlert } from "react-alert";

// COMPONENTS & UTILITIES
import { deleteURL, uploadURL } from "../utilities/constants";
import FormikModernField from "../../../shared/components/form/FormikModernField";
import FormikSubmitBtn from "../../../shared/components/form/FormikSubmitBtn";
import {
  attractionDefaultObj,
  localAttractionsInitValues,
  localAttractionsValSchema,
} from "../utilities/formUtils";
import FormikGalleryField from "../../../shared/components/form/FormikGalleryField";
import { getVenueAttractionsDetailsQuery } from "../../../services/venue-details";
import { updateLocalAttractionsMutation } from "../../../services/venue-management";
import { useGetFormContext } from "../context/FormContext";
import { getUpdatedLocalAttractionsData } from "../utilities/helpers";

function LocalAttractionsTabPanel() {
  const alert = useAlert();
  const [initValues, setInitValues] = useState(localAttractionsInitValues);
  const { setCurrentTab, setPayload, venueId } = useGetFormContext();

  useEffect(() => {
    (async () => {
      if (venueId) {
        const resp = await getVenueAttractionsDetailsQuery(venueId);

        if (resp) {
          const newValues = getUpdatedLocalAttractionsData(resp);

          setInitValues((prevState) => ({
            ...prevState,
            ...newValues,
          }));
        }
      }
    })();
  }, [venueId]);

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={localAttractionsValSchema}
      onSubmit={async (values) => {
        if (venueId) {
          const resp = await updateLocalAttractionsMutation(venueId, values);

          if (resp?.status === 400) {
            alert.error("Error updating local attractions!");
            return;
          }

          const getUpdatedResp = await getVenueAttractionsDetailsQuery(venueId);
          const newValues = getUpdatedLocalAttractionsData(getUpdatedResp);

          setInitValues((prevState) => ({
            ...prevState,
            ...newValues,
          }));

          alert.success("Local attractions updated successfully!");
          return;
        }

        setCurrentTab((prevState) => prevState + 1);
        setPayload((prevState) => ({ ...prevState, ...values }));
      }}
    >
      {({ values }) => (
        <Form className="d-flex align-items-start flex-column is-grid grid-3">
          <FieldArray name="venue_attractions">
            {({ push, remove }) => (
              <>
                {values?.venue_attractions?.map((attraction, index, arr) => (
                  <div key={index} className="d-flex flex-column is-grid w-100">
                    <FormikGalleryField
                      name={`venue_attractions[${index}].attraction_images`}
                      multiple={false}
                      deleteURL={deleteURL}
                      uploadURL={uploadURL}
                    />

                    <div className="d-flex align-items-start flex-wrap is-grid">
                      <FormikModernField
                        label="Name"
                        name={`venue_attractions[${index}].name`}
                        isRequired
                        isGrid
                      />

                      <FormikModernField
                        label="Attraction Type"
                        name={`venue_attractions[${index}].type`}
                        isRequired
                        isGrid
                      />

                      <FormikModernField
                        label="Distance (km)"
                        name={`venue_attractions[${index}].distance`}
                        isRequired
                      />

                      <FormikModernField
                        label="Description"
                        name={`venue_attractions[${index}].description`}
                        isRequired
                        type="textarea"
                      />

                      <div className="d-flex align-items-center gap-1">
                        {arr?.length > 1 && (
                          <button
                            type="button"
                            className="btn-main-secondary sm"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </button>
                        )}

                        {arr?.length - 1 === index && (
                          <button
                            type="button"
                            className="btn-main-primary sm"
                            onClick={() => push(attractionDefaultObj)}
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </FieldArray>

          <FormikSubmitBtn label={venueId ? "Submit" : "Next"} />
        </Form>
      )}
    </Formik>
  );
}

export default LocalAttractionsTabPanel;
