import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.scss";

const Events = () => {
  const events_sub_pages = [
    {
      source: "/assets/images/events/destination-main.jpg",
      class: "events_card1",
      text1: "Desti",
      text2: "nation",
      link: "/destinations",
    },
    {
      source: "/assets/images/events/venue-sourcing-main.jpg",
      class: "events_card2",
      text1: "Venue",
      text2: "Sourcing",
      link: "/venue_sourcing",
    },
  ];
  const renderevents_sub_page = (events_sub_page, index) => {
    return (
      <Col
        md={6}
        className={"events_congress " + events_sub_page.class}
        key={index}
      >
        <Card style={{ width: "30rem", border: "none" }}>
          <Link to={events_sub_page.link}>
            <Card.Img variant="top" src={events_sub_page.source} />
          </Link>
          <Card.Body className="text-style123">
            <Card.Title className="events-title1">
              <span className="font-weight-bold  events-details1 mr-2 text2">
                {events_sub_page.text1}
              </span>
              <span className=" font-weight-bold  events-details cart-border1 text2">
                {events_sub_page.text2}
              </span>
            </Card.Title>
            <Link to={events_sub_page.link}>
              <button className="explore_button123 text-white events-details">
                Explore More
              </button>
            </Link>
          </Card.Body>
        </Card>
      </Col>
    );
  };
  return (
    <React.Fragment>
      <Header />
      <Container
        className="event_banner px-0 position-relative "
        style={{
          backgroundImage:
            'url("/assets/images/events/event-management-banner.jpg")',
          backgroundColor: "rgb(37 50 190 / 50%)",
        }}
        fluid
      >
        <div
          className="event_banner_image"
          // style={{ backgroundColor: "rgba(37, 50, 190, 0.4)" }}
        ></div>
        <div className="text-blocks">
          <p
            className="font-weight-bold  text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-200 mb-0"
            style={{ fontFamily: "'Poppins'" }}
          >
            Event Management
          </p>
        </div>
      </Container>
      <Container className="CustomizeContainer">
        <Row className="align-items-center text-center text2">
          {events_sub_pages.map(renderevents_sub_page)}
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Events;
