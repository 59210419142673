export const tableHeadings = [
  {
    id: "event_name",
    label: "Event Name",
    isLink: true,
    align: "left",
  },
  {
    id: "event_date",
    label: "Event Date",
  },
  {
    id: "status",
    label: "Status",
  },
];
