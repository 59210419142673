import http from "./httpService";

export const searchVenuesQuery = async (payload) => {
  const response = await http.get(`/sourcing/venues/venue-search/`, {
    params: { ...payload, search: decodeURIComponent(payload.search ?? "") },
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const getLocationSuggestionsQuery = async (search) => {
  const response = await http.get(`/sourcing/venues/suggestions/`, {
    params: {
      q: search || "",
    },
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const getCitySuggestionsQuery = async (search) => {
  const response = await http.get(`/sourcing/venues/cities-suggestion/`, {
    params: {
      q: search || "",
    },
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const getVenueAreaSuggestionsQuery = async (search) => {
  const response = await http.get(
    "/sourcing/venues/city-country-suggestions/",
    { params: { q: search || "" }, headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const getVenueTypeOptionsQuery = async () => {
  const response = await http.get("/sourcing/venues/get-venue-types/", {
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const getAmenitiesOptionsQuery = async () => {
  const response = await http.get("/sourcing/venues/amenities-list/", {
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const getBrandsOptionsQuery = async () => {
  const response = await http.get("/sourcing/venues/brands-list/", {
    headers: http.getTokenHeaders(),
  });

  return response.data;
};
