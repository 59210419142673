import React from "react";
import { Row, Col } from "react-bootstrap";
import DatePicker from "../../../components/customDatePicker";
import MedServeiceButtons from "../med_service_buttons";
import Input from "../../../components/customInputField";
import "./index.scss";

const MedAccomodate = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
    register,
    control,
    errors,
  } = props;

  const disableAccomodateType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      accomodateDisabled: true,
    }));
  };

  const enableAccomodateType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      accomodateDisabled: false,
    }));
  };

  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="preferred_hotel">Preffered Hotel:</label>
        </Col>
        <Col className="px-0" md={6}>
          <Input
            name="accommodation.preferred_hotel"
            inputRef={register({ required: true })}
            error={
              errors?.accommodation?.preferred_hotel &&
              "This field is required!"
            }
          />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3} xs={6}>
          <label htmlFor="check_in_date">Check-in Date:</label>
          <img
            style={{ marginRight: "5px" }}
            className="calendar float-right"
            src={"assets/images/download.jpg"}
            alt="Not Found"
          />
        </Col>
        <Col className="px-0" md={6} xs={6}>
          <DatePicker name="accommodation.check_in_date" control={control} />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3} xs={6}>
          <label htmlFor="check_out_date">Check-out Date:</label>
          <img
            style={{ marginRight: "5px" }}
            className="calendar float-right"
            src={"assets/images/download.jpg"}
            alt="Not Found"
          />
        </Col>
        <Col className="px-0" md={6} xs={6}>
          <DatePicker name="accommodation.check_out_date" control={control} />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="num_rooms">Number of rooms:</label>
        </Col>
        <Col className="px-0" md={6}>
          <Input
            name="accommodation.num_rooms"
            inputRef={register({ required: true })}
            error={
              errors?.accommodation?.num_rooms && "This field is required!"
            }
          />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="room_type">Room Type:</label>
        </Col>
        <Col className="form_column_sel pl-0" md={6}>
          <select
            name="accommodation.room_type"
            ref={register({ required: true })}
          >
            <option>Room Only</option>
            <option>Bed and Breakfast</option>
            <option>Half Board</option>
            <option>Full Board</option>
          </select>
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="occupancy_type">Occupancy Type:</label>
        </Col>
        <Col className="form_column_sel pl-0" md={6}>
          <select name="accommodation.occupancy_type" ref={register}>
            <option>Single Occupancy</option>
            <option>Double Occupancy</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="px-0">
          <div className="gen_inquiry_text">
            <p className="font-weight-bold text-lg-150 text-125 mb-2">
              Other Requirements
            </p>
          </div>
          <Input
            textArea
            name="accommodation.other_requirements"
            inputRef={register({ required: true })}
            error={
              errors?.accommodation?.other_requirements &&
              "This field is required!"
            }
          />
        </Col>
      </Row>
      <MedServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableAccomodateType}
        enableSelectedType={enableAccomodateType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default MedAccomodate;
