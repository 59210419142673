import React, { useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { MdError, MdCheckCircle, MdClose } from "react-icons/md";
import { AttachFile } from "@material-ui/icons";
import useToggle from "../custom-hooks/useToggle";
import Tooltip from "../CustomTooltip";
import { DocumentViewerPopup } from "../../common/document-viewer";
import withMultiFilePicker from "./hoc/withMultiFilePicker";
import { useMultiFilePickerContext } from "./context/MultiFilePickerContext";
import { uploadRFPDoc } from "../../../server/Filter";
import "./styles/multi-file-picker.scss";

// Uploaded File(s)
// {
//   id: 1,
//   file: FileObject,
//   src: "https://www.test.com",
// }

const MutiFilePicker = () => {
  const fileInputRef = useRef(null);
  const {
    selectedDocument,
    successfulFiles,
    errorFiles,
    uploadingFiles,
    setSelectedDocument,
    setSuccessfulFiles,
    setErrorFiles,
    setUploadingFiles,
  } = useMultiFilePickerContext();
  const { isOpen: isDocumentViewerOpen, toggle: toggleDocumentViewer } =
    useToggle();

  const handleFilesChange = async (event) => {
    const selectedFiles = [...event.target.files];
    event.target.value = null;
    const fileFilesToUpload = {};

    selectedFiles.forEach((file, index) => {
      fileFilesToUpload[index] = {
        id: uuidv4(),
        file,
        uploadPercent: 0,
      };
    });

    setUploadingFiles({ ...fileFilesToUpload });

    const uploadedFilesResponse = await Promise.allSettled(
      Object.entries(fileFilesToUpload).map(([index, fileItem]) =>
        uploadRFPDoc(fileItem.file, {
          onUploadProgress: ({ loaded, total }) => {
            const uploadPercent = Math.round((100 * loaded) / total);
            setUploadingFiles((prevState) => ({
              ...prevState,
              [index]: {
                ...prevState[index],
                uploadPercent,
              },
            }));
          },
        })
      )
    );

    // Task Finished (Success/Error)
    setUploadingFiles(null);

    const newSuccessFiles = [];
    const newErrorFiles = [];

    uploadedFilesResponse.forEach((item, index) => {
      if (item.status === "fulfilled" && item.value.file) {
        newSuccessFiles.push({
          id: fileFilesToUpload[index].id,
          file: fileFilesToUpload[index].file,
          src: item.value.file,
        });
      } else {
        newErrorFiles.push({
          id: fileFilesToUpload[index].id,
          file: fileFilesToUpload[index].file,
        });
      }
    });

    setSuccessfulFiles((prevState) => [...prevState, ...newSuccessFiles]);
    setErrorFiles((prevState) => [...prevState, ...newErrorFiles]);
  };

  const handleChooseFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const toggleDocumentViewerModal = (selected) => {
    setSelectedDocument({ ...selected });
    toggleDocumentViewer();
  };

  const removeSuccessFile = (targetFileItem) => {
    const filteredSuccessFiles = successfulFiles.filter(
      (item) => item.id !== targetFileItem.id
    );
    setSuccessfulFiles(filteredSuccessFiles);
  };

  const removeErrorFile = (targetFileItem) => {
    const filteredErrorFiles = errorFiles.filter(
      (item) => item.id !== targetFileItem.id
    );
    setErrorFiles(filteredErrorFiles);
  };

  return (
    <div className="w-100 multi-file-picker">
      <DocumentViewerPopup
        isOpen={isDocumentViewerOpen}
        toggle={() => toggleDocumentViewerModal(null)}
        fileURL={Boolean(selectedDocument) && selectedDocument.fileURL}
        fileName={Boolean(selectedDocument) && selectedDocument.fileName}
      />
      <input
        ref={fileInputRef}
        type="file"
        onChange={handleFilesChange}
        hidden
        multiple
      />
      <div>
        <Tooltip
          text={
            Boolean(uploadingFiles)
              ? "Please wait until pending files are uploaded"
              : "Click here to attach files"
          }
        >
          <span className="d-inline-block">
            <span
              className="cursor-pointer file-chooser-button"
              onClick={handleChooseFileClick}
              style={{
                pointerEvents: Boolean(uploadingFiles) ? "none" : "all",
                opacity: Boolean(uploadingFiles) ? "0.5" : "1",
              }}
            >
              Attach File(s) <AttachFile />
            </span>
          </span>
        </Tooltip>
      </div>
      <div className="mt-5">
        {/* Success Files */}
        {successfulFiles.map((fileItem) => (
          <div
            className="w-100 my-3 d-flex justify-content-between align-items-center"
            key={fileItem.id}
          >
            <span
              className="text-primary cursor-pointer"
              onClick={() =>
                toggleDocumentViewerModal({
                  fileURL: fileItem.src,
                  fileName: fileItem.file.name,
                })
              }
            >
              {fileItem.file.name}
              <MdCheckCircle className="ml-3" size={18} color="#009846" />
            </span>
            <MdClose
              className="cursor-pointer"
              onClick={() => removeSuccessFile(fileItem)}
              size={18}
            />
          </div>
        ))}
        {/* Error Files */}
        {errorFiles.map((fileItem) => (
          <div
            className="w-100 my-3 d-flex justify-content-between align-items-center"
            key={fileItem.id}
          >
            <span className="text-danger">
              {fileItem.file.name}
              <MdError className="ml-3" size={18} color="#df0505" />
            </span>
            <MdClose
              className="cursor-pointer"
              onClick={() => removeErrorFile(fileItem)}
              size={18}
            />
          </div>
        ))}
        {/* Uploading Files */}
        {Object.entries(uploadingFiles || {}).map(([fileIndex, fileItem]) => (
          <div
            className="w-100 my-3 d-flex justify-content-between align-items-center"
            key={fileIndex}
          >
            <span>{fileItem.file.name}</span>
            <span style={{ height: 40, width: 40 }}>
              <CircularProgressbar
                value={fileItem.uploadPercent}
                text={`${fileItem.uploadPercent}%`}
                styles={buildStyles({
                  textSize: "28px",
                })}
              />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

// Exporting Multifile Picker Context
export * from "./context/MultiFilePickerContext";

// Exporting Multifile Picker HOC
export { withMultiFilePicker };

export default MutiFilePicker;
