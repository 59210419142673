/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { RemoveCircle } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { useAlert } from "react-alert";

// COMPONENTS & UTILITIES
import FormikModernField from "../../../shared/components/form/FormikModernField";
import FormikCreatableSelect from "../../../shared/components/form/FormikCreatableSelect";
import { overviewInitValues } from "../utilities/formUtils";
import FormikSubmitBtn from "../../../shared/components/form/FormikSubmitBtn";
import { getVenueOverviewDetailsQuery } from "../../../services/venue-details";
import { updateOverviewDetailsMutation } from "../../../services/venue-management";
import { useGetFormContext } from "../context/FormContext";

function OverviewTabPanel() {
  const [initialValues, setInitValues] = useState(overviewInitValues);
  const alert = useAlert();
  const { venueId, setCurrentTab, setPayload } = useGetFormContext();

  useEffect(() => {
    (async () => {
      if (venueId) {
        const overviewDataResp = await getVenueOverviewDetailsQuery(venueId);

        if (overviewDataResp) {
          const venueAmenities = overviewDataResp?.venue_amenities?.map(
            (item) => ({
              amenity_type: item?.amenity_type,
              amenity_list: item?.amenity_list?.map((amenity) => ({
                label: amenity,
                value: amenity,
              })),
            })
          );

          setInitValues((prevState) => ({
            ...prevState,
            description: overviewDataResp?.venue_description,
            venue_amenities:
              venueAmenities?.length >= 1
                ? venueAmenities
                : [
                    {
                      amenity_type: "",
                      amenity_list: [],
                    },
                  ],
          }));
        }
      }
    })();
  }, [venueId]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        if (venueId) {
          const updateResp = await updateOverviewDetailsMutation(
            venueId,
            values
          );

          if (updateResp.status === 400) {
            alert.error("Error updating overview details!");
            return;
          }

          alert.success("Venue overview details updated successfully!");
          return;
        }

        setPayload((prevState) => ({ ...prevState, ...values }));
        setCurrentTab((prevState) => prevState + 1);
      }}
    >
      {({ values }) => (
        <Form className="d-flex align-items-start is-grid grid-3">
          <FormikModernField
            label="Description"
            name="description"
            type="textarea"
          />

          <FieldArray name="venue_amenities">
            {({ remove, push }) => (
              <>
                {values?.venue_amenities?.map((amenity, index, arr) => (
                  <div className="w-100" key={index}>
                    <div className="d-flex align-items-start is-grid">
                      <FormikModernField
                        label="Amenity Type"
                        name={`venue_amenities[${index}].amenity_type`}
                      />

                      <FormikCreatableSelect
                        label="Amenity List"
                        name={`venue_amenities[${index}].amenity_list`}
                      />

                      {arr?.length > 1 && (
                        <div>
                          <IconButton
                            className="btn-main-secondary sm"
                            onClick={() => remove(index)}
                          >
                            <RemoveCircle />
                          </IconButton>
                        </div>
                      )}
                    </div>

                    <div className="d-flex align-items-center gap-1 mt-1">
                      {arr.length - 1 === index && (
                        <button
                          type="button"
                          className="btn-main-primary sm"
                          onClick={() =>
                            push({ amenity_type: "", amenity_list: [] })
                          }
                        >
                          Add Amenity Type
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </FieldArray>

          <FormikSubmitBtn label={venueId ? "Submit" : "Next"} />
        </Form>
      )}
    </Formik>
  );
}

export default memo(OverviewTabPanel);
