import React from "react";
import Modal from "../CustomModal";
import ReasonForm from "./ReasonForm";

const ReasonModal = ({
  isOpen,
  toggle,
  heading = "Cancellation Reason",
  message,
  onSubmit,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} heading={heading}>
      <ReasonForm onSubmit={onSubmit} />
    </Modal>
  );
};

export default ReasonModal;
