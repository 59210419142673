export const ratingFieldOptions = [
  {
    label: "4 Star",
    value: "4",
  },
  {
    label: "5 Star",
    value: "5",
  },
];
