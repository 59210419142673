import { TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";

function RegistrationTableHead() {
  return (
    <TableHead className="table-head">
      <TableRow className="table-row">
        <TableCell className="wrap fw-bolder color" align="center" colSpan={5}>
          Registration Details
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="wrap" align="center">
          Registration Name
        </TableCell>
        <TableCell className="wrap" align="center">
          Category
        </TableCell>
        <TableCell className="wrap" align="center">
          Registration Closes
        </TableCell>
        <TableCell className="noWrap" align="center">
          Price
        </TableCell>
        <TableCell className="noWrap" align="center">
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default RegistrationTableHead;
