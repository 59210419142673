export const navbarMenuData = [
  {
    link: "/events",
    text: "Events Management",
    submenu: [
      { link: "/destinations", text: "Destinations" },
      { link: "/venue_sourcing", text: "Venue Sourcing" },
    ],
  },
  {
    link: "/congress",
    text: "Congress & Exhibition",
    submenu: [
      { link: "/medical_congress", text: "Medical Congress" },
      { link: "/exhibition_booths", text: "Exhibition Booth" },
    ],
  },
  {
    link: "/production",
    text: "Production",
    submenu: [
      { link: "/av_production", text: "A/V Production" },
      { link: "/branding_materials", text: "Branding Material" },
      { link: "/concept_designs", text: "Concept Designs" },
    ],
  },
  {
    link: "/engagement",
    text: "Engagement",
    submenu: [
      { link: "/team/activities", text: "Team Building Activity" },
      { link: "/hybrid_meetings", text: "Hybrid Meetings" },
    ],
  },
];
