import React from "react";
import propTypes from "prop-types";
import "./index.scss";

function TabPanel({ tabValue, index, children }) {
  return (
    <div
      key={index}
      className={`custom-tab-panel p-3 ${tabValue === index ? "active" : ""}`}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  tabValue: propTypes.number.isRequired,
  index: propTypes.number.isRequired,
  children: propTypes.node.isRequired,
};

export default TabPanel;
