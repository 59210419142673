import React from "react";
import { Container } from "react-bootstrap";
import getFormatedDateRange from "../../utils/getFormatedDateRange";

const Conferencehead = ({ conference }) => {
  return (
    <React.Fragment>
      <Container fluid className="px-0">
        {conference && (
          <div
            className="position-relative event_banner"
            style={{
              backgroundImage: `url(${conference.banner_image})`,
            }}
          >
            <div
              className="event_banner_image"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.75)" }}
            />
            <div className="text-block text-center">
              {/* <p className="font-weight-bold text-md-375 text-sm-300 mb-0 con-heading-1"> */}
              <p className="font-weight-bold text-md-300 text-sm-250 mb-0">
                {conference.conference_name}
              </p>
              <p className="text-md-225 text-sm-175 mb-0">
                {conference.detail_name}
              </p>
              {conference.is_virtual ? (
                <p className="text-md-200 text-sm-150 mb-0 con-heading-2">
                  Virtual Congress
                </p>
              ) : (
                conference.city &&
                conference.country?.name && (
                  <p className="text-md-200 text-sm-150 mb-0 con-heading-2">
                    {conference.city}, {conference.country?.name}
                  </p>
                )
              )}
              {conference.start_date && conference.end_date && (
                <p className="text-md-200 text-sm-150 mb-0 con-heading-2">
                  {getFormatedDateRange(
                    conference.start_date,
                    conference.end_date
                  )}
                </p>
              )}
            </div>
          </div>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Conferencehead;
