import React from "react";
import { Row, Col } from "react-bootstrap";
import Datepick from "../../../components/datepick";
import EventServeiceButtons from "../event_service_buttons";
import EventText from "../event_text";

const EventFlight = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
  } = props;

  const disableFlightType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      flightDisabled: true,
    }));
  };

  const enableFlightType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      flightDisabled: false,
    }));
  };

  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="pl-0" md={3}>
          <label htmlFor="airline">From:</label>
          <input name="from" />
        </Col>
        <Col className="pl-0" md={3}>
          <label htmlFor="airline">To:</label>
          <input name="to" />
        </Col>
        <Col className="pl-0" md={3}>
          <label htmlFor="airline">Departure:</label>
          <Datepick name="departure" />
          <img
            style={{
              bottom: "5px",
              right: "15px",
              zIndex: "-1",
            }}
            className="calendar float-right position-absolute mr-1"
            src={"assets/images/download.jpg"}
            alt="Not Found"
          />
        </Col>
        <Col className="pl-0" md={3}>
          <label htmlFor="airline">Return:</label>
          <Datepick name="return" />
          <img
            style={{
              bottom: "5px",
              right: "15px",
              zIndex: "-1",
            }}
            className="calendar float-right position-absolute mr-1"
            src={"assets/images/download.jpg"}
            alt="Not Found"
          />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="pl-0" md={3}>
          <label htmlFor="airline">Preffered Airline (if any):</label>
          <input name="airline" />
        </Col>
        <Col className="pl-0" md={3}>
          <label htmlFor="airline">Number of passengers:</label>
          <input name="passengers" />
        </Col>
        <Col md={6}></Col>
      </Row>
      <EventText heading="Requirements:" />
      <EventServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableFlightType}
        enableSelectedType={enableFlightType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default EventFlight;
