import React from "react";
import qs from "querystring";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import "./index.scss";
import PageWrapper from "../../components/common/PageWrapper";
import { months, years } from "./utilities/data";
import useLazyFetchRequest from "../../components/common/custom-hooks/useLazyFetchRequest";
import { getCountrySuggestionsQuery } from "../../services/congress-details";
import { useTransformCitySuggestions } from "../../custom-hooks/useTransformers";
import { Form, Formik } from "formik";
import FormikModernField from "../../shared/components/form/FormikModernField";
import FormikModernSelect from "../../shared/components/form/FormikModernSelect";
import { initValues } from "./utilities/formUtils";

const MedicalCongress = () => {
  const history = useHistory();

  const [
    getCitySuggestions,
    { data: cityData, isLoading: isCityLoading, isSuccess: isCitySuccess },
  ] = useLazyFetchRequest(getCountrySuggestionsQuery);
  const cityOptions = useTransformCitySuggestions(cityData);

  const handleSearch = (values) => {
    const { congress_speciality, country_city, month, year } = values;
    const query = {};
    if (congress_speciality) query.congress_speciality = congress_speciality;
    if (country_city) query.country_city = country_city;
    if (month) query.month = month;
    if (year) query.year = year;

    const queryString = qs.stringify(query);
    if (congress_speciality || country_city || month || year) {
      history.push({
        pathname: "/conferences/medical-congress",
        search: `?${queryString}`,
      });
    }
  };

  const handleSelectCity = (item, setFieldValue) => {
    setFieldValue("country_city", item[0]?.value || "");
  };

  const handleSearchCity = async (string, setFieldValue) => {
    await getCitySuggestions(string);
    setFieldValue("country_city", string);
  };

  return (
    <PageWrapper
      bannerTitle="Medical Management"
      bannerImgSrc="url(/assets/images/events/venue-sourcing-banner.jpg)"
    >
      <Container className="text-center map-parentx CustomizeContainer">
        <h1>
          Congresses Around{" "}
          <span className="h1_around_the_world_span">the world</span>
        </h1>

        <div className="map-parent">
          <Formik initialValues={initValues} onSubmit={handleSearch}>
            {({ setFieldValue }) => (
              <Form className="row search-form-wrapper">
                <div className="col-md-4 px-0">
                  <FormikModernField
                    placeholder="Congress Name/Specialty"
                    name="congress_speciality"
                  />
                </div>

                <div className="col-md-2 px-0">
                  <AsyncTypeahead
                    id="city"
                    filterBy={() => true}
                    isLoading={isCityLoading && isCitySuccess}
                    onChange={(value) => handleSelectCity(value, setFieldValue)}
                    onSearch={(value) => handleSearchCity(value, setFieldValue)}
                    options={cityOptions}
                    placeholder="Country"
                    minLength={2}
                    className="search-input"
                  />
                </div>

                <div className="col-md-2 col-6 px-0">
                  <FormikModernSelect
                    name="month"
                    placeholder="Month"
                    options={months}
                  />
                </div>

                <div className="col-md-2 col-6 px-0">
                  <FormikModernSelect
                    name="year"
                    placeholder="Year"
                    options={years}
                  />
                </div>

                <div className="col-md-1 px-0">
                  <button className="searchBtn" type="submit">
                    <img src="/assets/pages/congress/7.png" alt="Search-btn" />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="map-image-container" />
      </Container>
    </PageWrapper>
  );
};

export default MedicalCongress;
