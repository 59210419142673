/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from "react";
import Select from "react-select";
import propTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";

// STYLES & UTILITIES
import "../../../styles/components/formik-field.scss";
import {
  DEFAULT_STYLES,
  DEFAULT_COMPONENTS,
} from "../../../styles/components/select-styles";
import useQuery from "../../../custom-hooks/useQuery";

const FilterSelectField = ({
  name,
  options,
  className,
  disabled,
  placeholder,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const searchParams = useQuery();
  const value = useMemo(() => searchParams.get(name), [searchParams, name]);

  const handleChange = useCallback((selectedOption) => {
    if (selectedOption) {
      searchParams.delete("page");
      searchParams.set(name, selectedOption.value);

      history.push({
        pathname,
        search: searchParams.toString(),
      });
    }
  }, [searchParams]);

  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [value, options]
  );

  return (
    <div className={`field-wrapper ${className}`}>
      <div className="input-wrapper">
        <Select
          components={DEFAULT_COMPONENTS}
          options={options}
          onChange={handleChange}
          value={selectedOption || ""}
          classNamePrefix="react-select"
          disabled={disabled}
          placeholder={placeholder}
          menuPosition="absolute"
          menuPlacement="auto"
          menuShouldBlockScroll={false}
          styles={{
            ...DEFAULT_STYLES,
            control: (base) => ({
              ...base,
              opacity: 1,
              borderColor: "#ced4da",
              "&:hover": { borderColor: "#ced4da" },
            }),
          }}
        />
      </div>
    </div>
  );
};

FilterSelectField.propTypes = {
  name: propTypes.string.isRequired,
  options: propTypes.array,
  className: propTypes.string,
  placeholder: propTypes.string,
  label: propTypes.string,
};

FilterSelectField.defaultProps = {
  options: [],
  className: "",
  placeholder: "",
  label: "",
};

export default FilterSelectField;
