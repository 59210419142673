import React, { memo } from "react";
import { DocumentViewerPopup } from "../../common/document-viewer";

const FileDetailRenderer = ({
  file,
  isDetailsModalOpen,
  toggleDetailsModal,
  ...rest
}) => {
  return (
    <DocumentViewerPopup
      {...rest}
      isOpen={isDetailsModalOpen}
      toggle={toggleDetailsModal}
      fileURL={file.url}
      fileName={file.name.substring(0, file.name.lastIndexOf("."))} // Removing file extension from name
      allowDocumentEdit
    />
  );
};

export default memo(FileDetailRenderer);
