import React from "react";
import { useEventCloseOutContext } from "../context/EventCloseOutContext";
import useInvoiceActionHandler from "./custom-hooks/useInvoiceActionHandler";
import SimpleTable from "../../../../shared/components/table/SimpleTable";
import { DocumentViewerPopup } from "../../../../components/common/document-viewer";
import { tableHeadings } from "./utils/headings";
import { INVOICES_STATE_KEY } from "../utils/constants";

const Invoices = () => {
  const {
    data: { [INVOICES_STATE_KEY]: rfpInvoices },
  } = useEventCloseOutContext();

  const {
    modifiedData,
    selectedProInvoice,
    isPreviewModalOpen,
    togglePreviewModal,
  } = useInvoiceActionHandler(rfpInvoices);

  return (
    <div>
      <DocumentViewerPopup
        isOpen={isPreviewModalOpen}
        toggle={togglePreviewModal}
        fileURL={selectedProInvoice && selectedProInvoice.invoice_file_link}
        fileName={selectedProInvoice && selectedProInvoice.invoice_name}
      />
      <SimpleTable headings={tableHeadings} data={modifiedData} rounded />
    </div>
  );
};

export default Invoices;
