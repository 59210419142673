/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Row } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";

import "./index.scss";
import PageWrapper from "../../components/common/PageWrapper";
import { getCongressCardsQuery } from "../../services/congress-details";
import { getSearchParamObj, getSearchParams } from "../../utils/helpers";
import useQuery from "../../custom-hooks/useQuery";
import Card from "./components/Card";

const MedicalCongress = () => {
  const history = useHistory();
  const [data, setData] = useState(null);

  const query = useQuery();
  const searchParamsObj = useMemo(() => getSearchParamObj(query), [query]);

  const [activePage, setActivePage] = useState(1);
  const page = query.get("page");

  // SEARCH PARAMS
  const getAsyncVenues = async () => {
    const response = await getCongressCardsQuery(searchParamsObj);

    setData(response);
  };

  useEffect(() => {
    if (page) {
      setActivePage(parseInt(page));
    } else {
      setActivePage(1);
    }
  }, [page]);

  useEffect(() => {
    getAsyncVenues();
  }, [page]);

  const handlePageChange = (newPage) => {
    setActivePage(newPage);

    const queryParams = getSearchParams({
      ...searchParamsObj,
      page: newPage,
    });

    if (newPage !== activePage) {
      history.push({
        pathname: "/conferences/medical-congress",
        search: queryParams,
      });
    }
  };

  // CONSTANTS
  const cardsPerPage = useMemo(() => 10, []);
  const totalCount = useMemo(() => {
    return data?.count ?? 0;
  }, [data]);

  return (
    <PageWrapper
      bannerTitle="Medical Congress"
      bannerImgSrc="url(/assets/images/events/venue-sourcing-banner.jpg)"
    >
      <div className="cards-wrapper">
        {data?.results?.length === 0 && (
          <div className="mx-auto">No results found!</div>
        )}

        {data?.results?.map((conference) => (
          <Card key={conference?.id} conference={conference} />
        ))}
      </div>

      {data?.results?.length > 0 && (
        <Row className="pagination-wrapper justify-content-center mt-5">
          <div className="paginations">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={cardsPerPage}
              totalItemsCount={totalCount}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
            />
          </div>
        </Row>
      )}
    </PageWrapper>
  );
};

export default MedicalCongress;
