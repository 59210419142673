import * as Yup from "yup";

export const siteInspectionDetailsValidationSchema = {
  venue_name: Yup.string().trim().required("Required"),
  inspection_date: Yup.date().typeError("Invalid Date").required("Required"),
  inspection_time: Yup.string().required("Required"),
  inspection_category: Yup.string().required("Required"),
};

export const siteInspectionCommentsValidationSchema = {
  comments: Yup.string().required("Required"),
};
