import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Row, Col, Container, Button, Accordion, Card } from "react-bootstrap";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Header from "../../components/header";
import Footer from "../../components/footer";
import AvProductionCategories from "./production_categories";
import AvPackages from "./av_packages";
import All from "./All";
import { getAvEquipmentsCategories } from "../../server/Filter";
import "./index.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AvProduction = () => {
  const history = useHistory();
  const query = useQuery();

  const [categories, setCategories] = useState(null);

  useEffect(() => {
    getAvEquipmentsCategories()
      .then((data) => setCategories(data))
      .catch((err) => console.log(err));
  }, []);

  const { state } = useLocation();

  const handleCategoryClick = (categoryName) => {
    history.replace({
      pathname: "/av_production",
      search: `category=${categoryName}`,
      state,
    });
  };

  const category = query.get("category");

  const renderProductionCategories = (productionCategory) => {
    return (
      <p key={productionCategory.id}>
        <button
          className={
            category === productionCategory.category_name
              ? "Button_hide font-weight-bold"
              : "Button_hide"
          }
          onClick={() => handleCategoryClick(productionCategory.category_name)}
        >
          {productionCategory.category_name}
        </button>
      </p>
    );
  };

  const [arrow, setArrow] = useState("");

  return (
    <React.Fragment>
      <Header />
      <Container
        className="event_banner px-0 position-relative"
        style={{
          backgroundImage:
            'url("/assets/images/production/av-production-banner.jpg")',

          backgroundColor: "rgb(37 50 190 / 50%)",
        }}
        fluid
      >
        <div
        // className="event_banner_image"
        // style={{ backgroundColor: "rgba(37, 50, 190, 0.4)" }}
        ></div>
        <div className="text-blocks">
          <p
            className="font-weight-bold  text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-250 mb-0"
            style={{ fontFamily: "'Poppins'" }}
          >
            A/V & Production
          </p>
        </div>
      </Container>
      <Row className="BackBtn">
        <Col xl={12} className="myback_button">
          <Link
            to={category ? "/av_production" : "/production"}
            className="back_button"
          >
            <img
              src="/assets/images/icon-back.png"
              className="icon_back"
              alt=""
            />{" "}
            Back
          </Link>
        </Col>
      </Row>
      <Container className="avProduction_container CustomizeContainer">
        <Row className="avProductionRow mb-5 plan-parentxxx">
          <Col md={3} sm={4} className=" mb-5">
            <div className="avProduction_leftSide">
              <div className="mb-2 left_Heading">
                <p className="filter_heading text-lg-125 text-100 mb-0">
                  Filters by
                </p>
              </div>
              <button
                className={`Button_hide  Button_hide1 ${
                  !category ? "font-weight-bold" : ""
                }`}
                onClick={() => {
                  history.push("/av_production");
                }}
              >
                All
              </button>
              <br />
              <button
                className={`Button_hide Button_hide1 ${
                  category === "AvPackages" ? "font-weight-bold" : ""
                }`}
                onClick={() => {
                  history.push("/av_production?category=AvPackages");
                }}
              >
                A/V Packages
              </button>
              <br />
              <Accordion defaultActiveKey="0" className="mb-3">
                <Card className="border-0">
                  <Card.Header className="border-0 p-0 bg-transparent m-0">
                    <Accordion.Toggle
                      as={Button}
                      className="Button_hide Button_hide1 text-lg-100 text-sm-75 text-decoration-none shadow-none"
                      variant="link"
                      eventKey="0"
                      onClick={() =>
                        setArrow((arrow) => (arrow === "" ? "ChangeArrow" : ""))
                      }
                    >
                      A/V Equipments
                      {arrow === "ChangeArrow" ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className="pb-0 branding_body"
                      style={{ lineHeight: "1" }}
                    >
                      {categories?.map(renderProductionCategories)}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </Col>
          <Col md={9} sm={8} className="">
            {!category ? (
              <All categories={categories} cartState={state} />
            ) : category === "AvPackages" ? (
              <AvPackages />
            ) : (
              <AvProductionCategories />
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default AvProduction;
