import React from "react";
import { useEventCoordinationContext } from "../../../context/EventCoordinationContext";
import { RFP_DETAILS_STATE_KEY } from "../../../utils/constants";

const TableData = ({ children, ...rest }) => {
  const {
    [RFP_DETAILS_STATE_KEY]: { isEditing },
  } = useEventCoordinationContext();

  return (
    <td {...rest} className={isEditing ? "" : "disabled"}>
      {children}
    </td>
  );
};

export default TableData;
