import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

// UTILITIES
import { useGetVenueDetailContext } from "../custom-hooks/useGetVenueDetailContext";
import { getVenueGuestRoomsDetailsQuery } from "../../../services/venue-details";
import ModalCarousel from "../../../components/common/modal-carousel";
import SectionLoaderWrapper from "../../../components/common/SectionLoaderWrapper";
import useFetchRequest from "../../../components/common/custom-hooks/useFetchRequest";
import { useGetGuestRoomImages } from "../custom-hooks/useTransformers";

function GuestRoomsTabPanel() {
  const { venueId } = useGetVenueDetailContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const { data: details, isLoading } = useFetchRequest(
    getVenueGuestRoomsDetailsQuery,
    venueId
  );
  const { gridImages, isModalDisable, mainImage, sliderAllImages } =
    useGetGuestRoomImages(details);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <SectionLoaderWrapper isLoading={isLoading}>
      <Container className="guest-rooms-container">
        <ModalCarousel
          isOpen={isModalOpen}
          toggle={toggleModal}
          sliderImages={sliderAllImages}
        />

        <Row className="mb-4">
          <h6 className="heading">Guest Rooms</h6>
        </Row>

        <Row noGutters className="room-info-row">
          {details?.venue_guestroom?.guest_room_details?.map(
            (item) =>
              item?.room_count > 0 && (
                <Col key={item?.id} md={3}>
                  <p className="title">{item?.room_type}</p>

                  <p className="value">{item?.room_count}</p>
                </Col>
              )
          )}
        </Row>

        <Row noGutters className="gallery-box-wrapper">
          <Col className="grid-main-wrapper">
            <img src={mainImage} alt="guest-rooms" />
          </Col>

          <Col className="grid-box">
            <Row noGutters>
              {gridImages?.map((item, idx, arr) => (
                <Col key={item?.id} className="grid-image-wrapper">
                  {arr.length - 1 === idx && !isModalDisable && (
                    <div className="overlay-bg" onClick={toggleModal}>
                      See All...
                    </div>
                  )}

                  <img src={item?.image} alt="guest-rooms" />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionLoaderWrapper>
  );
}

export default GuestRoomsTabPanel;
