import {
  OWNER_ACCESS_ROLE,
  PLANNER_ACCESS_ROLE,
} from "../../../utils/constants";

export const eventRoleOptions = [
  {
    label: "Event Owner",
    value: OWNER_ACCESS_ROLE,
  },
  {
    label: "Planner",
    value: PLANNER_ACCESS_ROLE,
  },
];
