import React from "react";
import { Row, Col } from "react-bootstrap";

const ServiceQuestionWrapper = ({ label, children }) => {
  return (
    <Row className="w-100 px-0 justify-content-center align-items-center mb-6">
      <Col className="px-0" md={8}>
        <label className="mb-0">{label}</label>
      </Col>
      <Col md={4} className="px-0 d-flex justify-content-end">
        {children}
      </Col>
    </Row>
  );
};

export default ServiceQuestionWrapper;
