/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from "react";
import { useAlert } from "react-alert";
import { useGetPropertiesTableContext } from "../../../components/propertiesTable/context/PropertiesTableContext";

export const useGetTableHandlers = () => {
  const alert = useAlert();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    data,
    fetchHandler,
    statusHandler,
    invalidateHandler,
    deleteHandler,
  } = useGetPropertiesTableContext();

  const handleSelectRow = useCallback(
    async (event, id) => {
      if (event.target.checked) {
        setSelected([...selected, id]);
      } else {
        setSelected(selected.filter((selectedId) => selectedId !== id));
      }
    },
    [selected]
  );

  const handleSelectAll = useCallback(
    async (event) => {
      if (event.target.checked) {
        const newSelectedIds = data?.results?.map((row) => row.id);
        setSelected(newSelectedIds);
        return;
      }

      setSelected([]);
    },
    [selected, data]
  );

  const handleConfirmAll = useCallback(async () => {
    await statusHandler(selected);
    await Promise.all([
      fetchHandler({ page: page > 0 ? 0 : undefined, rowsPerPage }),
      invalidateHandler(),
    ]);
    alert.success("Operation Successfully!");
    setSelected([]);
  }, [selected, statusHandler, fetchHandler, page]);

  const handlePageChange = useCallback(
    async (e, newValue) => {
      await fetchHandler({
        page: newValue + 1,
        limit: rowsPerPage,
      });
      setPage(newValue);
    },
    [fetchHandler, page]
  );

  const handleRowsPerPageChange = useCallback(
    async (e) => {
      const newValue = e.target.value;

      if (newValue > data?.count) {
        return;
      }

      await fetchHandler({ limit: newValue });
      setRowsPerPage(newValue);
      setPage(0);
    },
    [rowsPerPage, data, fetchHandler]
  );

  const handleClearSelected = useCallback(() => {
    setSelected([]);
  }, [selected]);

  const handleDelete = useCallback(async () => {
    await deleteHandler(selected);
    await Promise.all([
      fetchHandler({ page: page > 0 ? 0 : undefined, rowsPerPage }),
      invalidateHandler(),
    ]);

    alert.success("Deleted Successfully!");
    setSelected([]);
  }, [selected, data, page]);

  return {
    handleSelectRow,
    handleSelectAll,
    page,
    handleConfirmAll,
    handlePageChange,
    handleRowsPerPageChange,
    data,
    rowsPerPage,
    selected,
    handleClearSelected,
    handleDelete,
  };
};
