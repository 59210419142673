import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useLazyFetchRequest from "../../../../components/common/custom-hooks/useLazyFetchRequest";
import { getAllServiceRequests } from "../../../../server/Filter";
import getSorting from "../../../../utils/getSorting";

export const ServiceRequestMainContext = createContext({
  isLoading: true,
  serviceRequests: [],
  updateServiceRequests: () => {},
});

export const useServiceRequestMainContext = () =>
  useContext(ServiceRequestMainContext);

const ServiceRequestMainProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [serviceRequests, setServiceRequests] = useState([]);
  const [
    fetchServiceRequests,
    { isLoading: isLoadingServiceRequests, data: serviceRequestsData },
  ] = useLazyFetchRequest(getAllServiceRequests, { data: [] });

  const transformServiceRequestsData = (newData) => {
    setIsLoading(true);
    const sortedData = [...newData].sort(getSorting("desc", "updated_at"));
    setServiceRequests(sortedData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchServiceRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    transformServiceRequestsData([...serviceRequestsData]);
  }, [serviceRequestsData]);

  const updateServiceRequests = () => {
    fetchServiceRequests();
  };

  const contextValue = useMemo(
    () => ({
      isLoading: isLoadingServiceRequests || isLoading,
      serviceRequests,
      updateServiceRequests,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serviceRequests, isLoadingServiceRequests]
  );

  return (
    <ServiceRequestMainContext.Provider value={contextValue}>
      <React.Fragment>{children}</React.Fragment>
    </ServiceRequestMainContext.Provider>
  );
};

export default ServiceRequestMainProvider;
