import React, { useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import propTypes from "prop-types";

// CUSTOM HOOKS & UTILITIES
import { useGetVenueSpaceTableData } from "../custom-hooks/useTransformers";
import { formatDecimalValue } from "../../../utils/helpers";

function VenuSpaceTable({ data }) {
  const initialRows = useMemo(() => 7, []);
  const [showRows, setShowRows] = useState(initialRows);

  const transformedData = useGetVenueSpaceTableData(data);

  const toggleShowRows = () => {
    if (showRows === initialRows) {
      setShowRows(data?.length);
    } else {
      setShowRows(initialRows);
    }
  };

  return (
    <div>
      <Table className="venue-table">
        <TableHead className="table-head">
          <TableRow className="table-row">
            <TableCell>Room Name</TableCell>

            <TableCell className="wrap" align="center">
              Room Size
            </TableCell>

            <TableCell className="wrap" align="center">
              Dimensions <br />
              (L * W * H)
            </TableCell>

            <TableCell className="noWrap" align="center">
              Banquet
            </TableCell>

            <TableCell className="noWrap" align="center">
              Theatre
            </TableCell>

            <TableCell className="noWrap" align="center">
              Class Room
            </TableCell>

            <TableCell className="noWrap" align="center">
              Board Room
            </TableCell>

            <TableCell className="noWrap" align="center">
              U Shape
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {transformedData?.slice(0, showRows)?.map((item) => {
            const dimensions = `${formatDecimalValue(
              item?.length
            )} m x ${formatDecimalValue(item?.width)} m x ${formatDecimalValue(
              item?.height
            )} m`;

            return (
              <TableRow key={item.id}>
                <TableCell className="room-title-cell">
                  <div className="d-flex align-items-start gap-1">
                    <img
                      className="property-image"
                      src={item?.image}
                      alt="Property-title-img"
                    />

                    <div>
                      <p className="mb-2 room-title">{item?.name || ""}</p>

                      <Link
                        to={`/venue_sourcing/venues/${item?.venue_id}/details/${item?.id}`}
                        className="link"
                      >
                        <p className="mb-0">View Details</p>
                      </Link>
                    </div>
                  </div>
                </TableCell>

                <TableCell align="center">
                  {`${formatDecimalValue(item?.total_space)} sq. m`}
                </TableCell>

                <TableCell align="center">{dimensions}</TableCell>

                <TableCell align="center">
                  {item?.banquetRounds || "-"}
                </TableCell>

                <TableCell align="center">{item?.theater || "-"}</TableCell>

                <TableCell align="center">{item?.classRoom || "-"}</TableCell>

                <TableCell align="center">{item?.boardRoom || "-"}</TableCell>

                <TableCell align="center">{item?.uShape || "-"}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <div className="text-center">
        <button className="show-all-btn" onClick={toggleShowRows}>
          {showRows === initialRows ? "Show All" : "Show Less"}
        </button>
      </div>
    </div>
  );
}

VenuSpaceTable.propTypes = {
  data: propTypes.array,
};

VenuSpaceTable.defaultProps = {
  data: [],
};

export default VenuSpaceTable;
