/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import PageWrapper from "../../components/common/PageWrapper";
import PropertiesTable from "../../components/propertiesTable";
import {
  getNonVerifiedCongress,
  getVerifiedCongress,
  unverifyMultipleCongress,
  verifyMultipleCongress,
  deleteMultipleCongress,
} from "../../services/congress-management";
import "./index.scss";
import { PropertiesTableContext } from "../../components/propertiesTable/context/PropertiesTableContext";

function CongressManagement() {
  const [verifiedData, setVerifiedData] = useState(null);
  const [unverifiedData, setUnverifiedData] = useState(null);

  useEffect(() => {
    (async () => {
      const [verifiedResp, unverifiedResp] = await Promise.all([
        getVerifiedCongress(),
        getNonVerifiedCongress(),
      ]);

      setVerifiedData(verifiedResp);
      setUnverifiedData(unverifiedResp);
    })();
  }, []);

  const handleGetVerfiedData = useCallback(
    async (...args) => {
      const resp = await getVerifiedCongress(...args);

      setVerifiedData(resp);
    },
    [verifiedData]
  );

  const handleGetUnverfiedData = useCallback(
    async (...args) => {
      const resp = await getNonVerifiedCongress(...args);

      setUnverifiedData(resp);
    },
    [unverifiedData]
  );

  const verifyTableContextValue = useMemo(
    () => ({
      data: verifiedData,
      setData: setVerifiedData,
      fetchHandler: handleGetVerfiedData,
      statusHandler: unverifyMultipleCongress,
      invalidateHandler: handleGetUnverfiedData,
    }),
    [verifiedData]
  );

  const unverifyTableContextValue = useMemo(
    () => ({
      data: unverifiedData,
      setData: setUnverifiedData,
      fetchHandler: handleGetUnverfiedData,
      statusHandler: verifyMultipleCongress,
      invalidateHandler: handleGetVerfiedData,
      deleteHandler: deleteMultipleCongress,
    }),
    [unverifiedData]
  );

  return (
    <PageWrapper
      bannerTitle="Congress Management"
      bannerImgSrc="url(/assets/images/events/venue-sourcing-banner.jpg)"
    >
      <Link to="/congress-management/add">
        <button className="btn btn-primary sm congress-btn">
          Add New Congress
        </button>
      </Link>

      <div className="properties-table-container">
        <PropertiesTableContext.Provider value={unverifyTableContextValue}>
          <PropertiesTable title="Non Verified" isCongress />
        </PropertiesTableContext.Provider>

        <PropertiesTableContext.Provider value={verifyTableContextValue}>
          <PropertiesTable title="Verified" isCongress isVerified />
        </PropertiesTableContext.Provider>
      </div>
    </PageWrapper>
  );
}

export default CongressManagement;
