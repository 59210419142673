import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Datepick from "./datepick";
import "./index.scss";

function LogisticAdmin(props) {
  const [formData, setformData] = useState({});
  const _handleSubmit = () => {
    props.onSubmit(formData);
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return (
    <React.Fragment>
      <div className="logistic_main">
        <p className="text-lg-125 text-100 mb-2">Logistics:</p>
        <Form onSubmit={_handleSubmit}>
          <label className="big_checkbox">
            <p className="text-lg-125 text-100 mb-2">Registration</p>
            <input type="checkbox" id="registration" name="registration" />
            <span className="big_checkmark"></span>
          </label>
          <Row>
            <Col md={1}></Col>
            <Col lg={2} md={3} xs={4}>
              <label className="small_checkbox">
                <p className="text-lg-100 text-75 mb-2">Member</p>
                <input
                  type="checkbox"
                  name="member"
                  onChange={(e) =>
                    setformData({
                      ...formData,
                      member_field: e.target.value,
                    })
                  }
                />
                <span className="small_checkmark"></span>
              </label>
            </Col>
            <Col xl={3} lg={4} md={5} xs={8}>
              <input type="text" className="input_text" />
            </Col>
            <Col xl={6} lg={5} md={3}></Col>
          </Row>
          <Row className="logistic_member">
            <Col md={1}></Col>
            <Col lg={2} md={3} xs={4} className="pr-0">
              <label className="small_checkbox">
                <p className="text-lg-100 text-75 mb-2">Non Member</p>
                <input
                  type="checkbox"
                  id="nonmember"
                  name="nonmember"
                  onChange={(e) =>
                    setformData({
                      ...formData,
                      member_field: e.target.value,
                    })
                  }
                />
                <span className="small_checkmark"></span>
              </label>
            </Col>
            <Col xl={3} lg={4} md={5} xs={8}>
              <input type="text" className="input_text" />
            </Col>
            <Col xl={6} lg={5} md={3}></Col>
          </Row>
          <Row>
            <Col lg={7} md={6} className="px-0">
              <label className="big_checkbox">
                <p className="text-lg-125 text-100 mb-2">Hotel Accomodation</p>
                <input type="checkbox" name="hotel_accomodation" />
                <span className="big_checkmark"></span>
              </label>
              <Row>
                <Col md={1}></Col>
                <Col xl={4} lg={3} md={4} xs={4} className="px-0">
                  <div className="d-flex">
                    <p className="text-lg-100 text-75 mb-2">Check-in Date:</p>
                    <img
                      className="calendar calender_admin ml-auto"
                      src={"assets/images/download.jpg"}
                      alt="Not Found"
                    />
                  </div>
                </Col>
                <Col md={6} xs={8} className="logisticadmin_padding_right">
                  <Datepick
                    onChangeDate={(date) => {
                      setformData({
                        ...formData,
                        hotel_check_in_date: formatDate(date),
                      });
                    }}
                  />
                </Col>
                <Col lg={4} md={1}></Col>
              </Row>
              <Row className="clander_row">
                <Col md={1}></Col>
                <Col xl={4} lg={3} md={4} xs={4} className="px-0">
                  <div className="d-flex">
                    <p className="text-lg-100 text-75 mb-2">Check-out Date:</p>
                    <img
                      className="calendar calender_admin ml-auto"
                      src={"assets/images/download.jpg"}
                      alt="Not Found"
                    />
                  </div>
                </Col>
                <Col md={6} xs={8} className="logisticadmin_padding_right">
                  <Datepick
                    onChangeDate={(date) => {
                      setformData({
                        ...formData,
                        hotel_check_out_date: formatDate(date),
                      });
                    }}
                  />
                </Col>
                <Col xl={1} md={2}></Col>
              </Row>
              <Row className="mt-5">
                <Col md={1}></Col>
                <Col lg={2} md={3}>
                  <p className="text-lg-100 text-75 mb-2">Type:</p>
                </Col>
                <Col md={7}>
                  <label className="small_checkbox">
                    <p className="text-lg-100 text-75 mb-2">Room Only</p>
                    <input
                      type="checkbox"
                      name="room"
                      value="room"
                      onChange={(e) =>
                        setformData({
                          ...formData,
                          hotel_type: e.target.value,
                        })
                      }
                    />
                    <span className="small_checkmark"></span>
                  </label>
                </Col>
                <Col lg={2} md={1}></Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col lg={2} md={3}></Col>
                <Col md={7}>
                  <label className="small_checkbox">
                    <p className="text-lg-100 text-75 mb-2">
                      Bed and Breakfast
                    </p>
                    <input
                      type="checkbox"
                      name="bed_breakfast"
                      value="bed_breakfast"
                      onChange={(e) =>
                        setformData({
                          ...formData,
                          hotel_type: e.target.value,
                        })
                      }
                    />
                    <span className="small_checkmark"></span>
                  </label>
                </Col>
                <Col lg={2} md={1}></Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col lg={2} md={3}></Col>
                <Col md={7}>
                  <label className="small_checkbox">
                    <p className="text-lg-100 text-75 mb-2">Half Board</p>
                    <input
                      type="checkbox"
                      name="half_board"
                      value="half_board"
                      onChange={(e) =>
                        setformData({
                          ...formData,
                          hotel_type: e.target.value,
                        })
                      }
                    />
                    <span className="small_checkmark"></span>
                  </label>
                </Col>
                <Col lg={2} md={1}></Col>
              </Row>
              <Row>
                <Col md={1}></Col>
                <Col lg={2} md={3}></Col>
                <Col md={7}>
                  <label className="small_checkbox">
                    <p className="text-lg-100 text-75 mb-2">Full Board</p>
                    <input
                      type="checkbox"
                      name="full_board"
                      value="full_board"
                      onChange={(e) =>
                        setformData({
                          ...formData,
                          hotel_type: e.target.value,
                        })
                      }
                    />
                    <span className="small_checkmark"></span>
                  </label>
                </Col>
                <Col lg={2} md={1}></Col>
              </Row>
              <Row className="mt-5">
                <Col md={1}></Col>
                <Col lg={2} md={3}>
                  <p className="text-lg-100 text-75 mb-2">Occupancy:</p>
                </Col>
                <Col md={7}>
                  <label className="small_checkbox">
                    <p className="text-lg-100 text-75 mb-2">Single Occupancy</p>
                    <input
                      type="checkbox"
                      name="single_occupancy"
                      value="single_occupancy"
                      onChange={(e) =>
                        setformData({
                          ...formData,
                          hotel_Occupancy: e.target.value,
                        })
                      }
                    />
                    <span className="small_checkmark"></span>
                  </label>
                </Col>
                <Col lg={2} md={1}></Col>
              </Row>
              <Row>
                <Col lg={1} md={1}></Col>
                <Col lg={2} md={3}></Col>
                <Col md={7}>
                  <label className="small_checkbox">
                    <p className="text-lg-100 text-75 mb-2">Double Occupancy</p>
                    <input
                      type="checkbox"
                      name="double_occupancy"
                      value="double_occupancy"
                      onChange={(e) =>
                        setformData({
                          ...formData,
                          hotel_Occupancy: e.target.value,
                        })
                      }
                    />
                    <span className="small_checkmark"></span>
                  </label>
                </Col>
                <Col lg={2} md={1}></Col>
              </Row>
            </Col>
            <Col lg={5} md={6} className="pr-0">
              <label className="big_checkbox">
                <p className="text-lg-125 text-100 mb-2">Meeting Room</p>
                <input type="checkbox" name="meeting_room" />
                <span className="big_checkmark"></span>
              </label>
              <Row>
                <Col md={3} xs={5} className="px-0">
                  <div className="d-flex">
                    <p className="text-lg-100 text-75 mb-2">Date:</p>
                    <img
                      className="calendar calender_admin ml-auto"
                      src={"assets/images/download.jpg"}
                      alt="Not Found"
                    />
                  </div>
                </Col>
                <Col md={9} xs={7} className="logisticadmin_padding_right">
                  <Datepick />
                </Col>
              </Row>
              <Row style={{ marginTop: "5px" }}>
                <Col md={6} xs={5} className="px-0">
                  <p className="text-lg-100 text-75 mb-2">
                    Number of Attendees:
                  </p>
                </Col>
                <Col md={6} xs={7} className="logisticadmin_padding_right">
                  <input
                    type="text"
                    className="input_text"
                    onChange={(e) =>
                      setformData({
                        ...formData,
                        meeting_number_of_attendees: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "5px" }}>
                <Col md={6} xs={5} className="px-0">
                  <p className="text-lg-100 text-75 mb-2">Time:</p>
                </Col>
                <Col md={6} xs={7} className="logisticadmin_padding_right">
                  <input
                    type="text"
                    className="input_text"
                    onChange={(e) =>
                      setformData({
                        ...formData,
                        meeting_time: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg={3} md={3} className="px-0">
                  <p className="text-lg-100 text-75 mb-2">Type:</p>
                </Col>
                <Col lg={9} md={9}>
                  <label className="small_checkbox">
                    <p className="text-lg-100 text-75 mb-2">Full Day</p>
                    <input
                      type="checkbox"
                      id="room"
                      name="room"
                      value="room"
                      onChange={(e) =>
                        setformData({
                          ...formData,
                          meeting_type: e.target.value,
                        })
                      }
                    />
                    <span className="small_checkmark"></span>
                  </label>
                </Col>
              </Row>
              <Row>
                <Col md={3}></Col>
                <Col md={9}>
                  <label className="small_checkbox">
                    <p className="text-lg-100 text-75 mb-2">Half Day</p>
                    <input
                      type="checkbox"
                      id="bed_breakfast"
                      name="bed_breakfast"
                      value="half day"
                      onChange={(e) =>
                        setformData({
                          ...formData,
                          meeting_type: e.target.value,
                        })
                      }
                    />
                    <span className="small_checkmark"></span>
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
          <label className="big_checkbox">
            <p className="text-lg-125 text-100 mb-2">Car Transfer</p>
            <input type="checkbox" name="car_transfer" />
            <span className="big_checkmark"></span>
          </label>
          <Row>
            <Col md={1}></Col>
            <Col md={3}>
              <label className="small_checkbox">
                <p className="text-lg-100 text-75 mb-2">Airport Transfer</p>
                <input
                  type="checkbox"
                  id="airport_transfer"
                  name="airport_transfer"
                  value="airport_transfer"
                  onChange={(e) => {
                    console.log(e);
                    setformData({
                      ...formData,
                      airport_transfer: true,
                    });
                  }}
                />
                <span className="small_checkmark"></span>
              </label>
            </Col>
            <Col md={8}></Col>
          </Row>
          <Row>
            <Col md={1}></Col>
            <Col md={3}>
              <label className="small_checkbox">
                <p className="text-lg-100 text-75 mb-2">Congress Transfer</p>
                <input
                  type="checkbox"
                  id="congress_transfer"
                  name="congress_transfer"
                  value="congress_transfer"
                  onChange={(e) => {
                    console.log(e);
                    setformData({
                      ...formData,
                      congress_transfer: true,
                    });
                  }}
                />
                <span className="small_checkmark"></span>
              </label>
            </Col>
            <Col md={8}></Col>
          </Row>
          <Row>
            <Col md={1}></Col>
            <Col md={3}>
              <label className="small_checkbox">
                <p className="text-lg-100 text-75 mb-2">Dinner Transfer</p>
                <input
                  type="checkbox"
                  id="dinner_transfer"
                  name="dinner_transfer"
                  onChange={(e) => {
                    console.log(e);
                    setformData({
                      ...formData,
                      dinner_transfer: true,
                    });
                  }}
                />
                <span className="small_checkmark"></span>
              </label>
            </Col>
            <Col md={8}></Col>
          </Row>
          <label className="big_checkbox">
            <p className="text-lg-125 text-100 mb-2">Meals</p>
            <input type="checkbox" name="meeting_room" />
            <span className="big_checkmark"></span>
          </label>
          <Row>
            <Col md={1}></Col>
            <Col md={3} xs={6}>
              <label className="small_checkbox">
                <div className="d-flex">
                  <p className="text-lg-100 text-75 mb-2">Lunch</p>
                  <p className="text-lg-100 text-75 mb-2 logistic_meal_headsix">
                    Dates:
                  </p>
                </div>
                <input type="checkbox" name="lunch" />
                <span className="small_checkmark"></span>
              </label>
            </Col>
            <Col md={4} xs={6}>
              <input type="text" className="input_text ml-4" />
            </Col>
            <Col md={4}></Col>
          </Row>
          <Row>
            <Col md={1}></Col>
            <Col lg={3} md={2}>
              <label className="small_checkbox">
                <p className="text-lg-100 text-75 mb-2">Dinner</p>
                <input type="checkbox" name="dinner" />
                <span className="small_checkmark"></span>
              </label>
            </Col>
            <Col md={4}></Col>
            <Col lg={4} md={5}></Col>
          </Row>
          <Row className="ml-5">
            <Col md={1}></Col>
            <Col md={3} xs={6}>
              <label className="small_checkbox">
                <div className="d-flex">
                  <p className="text-lg-100 text-75 mb-2">On-Site</p>
                  <p className="text-lg-100 text-75 mb-2 logistic_meal_headsix_dinner">
                    Dates:
                  </p>
                </div>
                <input type="checkbox" name="onsite" />
                <span className="small_checkmark"></span>
              </label>
            </Col>
            <Col md={4} xs={6}>
              <input type="text" className="input_text" />
            </Col>
            <Col md={4}></Col>
          </Row>
          <Row className="ml-5">
            <Col md={1}></Col>
            <Col md={3} xs={6}>
              <label className="small_checkbox">
                <div className="d-flex">
                  <p className="text-lg-100 text-75 mb-2">Off-Site</p>
                  <p className="text-lg-100 text-75 mb-2 logistic_meal_headsix_dinner">
                    Dates:
                  </p>
                </div>
                <input type="checkbox" id="offsite" name="offsite" />
                <span className="small_checkmark"></span>
              </label>
            </Col>
            <Col md={4} xs={6}>
              <input type="text" className="input_text" />
            </Col>
            <Col md={4}></Col>
          </Row>
          <label className="big_checkbox">
            <p className="text-lg-125 text-100 mb-2">On-site Assistance</p>
            <input type="checkbox" name="meeting_room" />
            <span className="big_checkmark"></span>
          </label>
          <Row>
            <Col xs={12} className="px-0">
              <textarea
                name="textarea"
                rows="7"
                className="text_area"
                value=""
                onChange={(e) =>
                  setformData({ ...formData, remarks: e.target.value })
                }
              >
                Remarks/Other Requirements:
              </textarea>
            </Col>
          </Row>
          <Row className="logistic_submit">
            <Col xs={12} className="px-0">
              <button className="Button">Submit</button>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
}

export default LogisticAdmin;
