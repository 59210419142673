import React, { useLayoutEffect, useEffect, useState } from "react";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import { useFileHandlerContext } from "../context/FileHandlerContext";
import {
  SYNCFUSION_DOCUMENT_SERVICES_URL,
  SYNCFUSION_DOCUMENT_TOOLBAR_OPTIONS,
} from "../utils/constants";
import { removeSyncfusionLicenseAlert } from "../utils/helpers";

DocumentEditorContainerComponent.Inject(Toolbar);

const DocumentHandler = ({
  onLoad = () => {},
  onSaveComplete = () => {},
  height = 600,
  ...restProps
}) => {
  const { documentRef, saveDocumentRef } = useFileHandlerContext();
  const [isDocumentCreated, setIsDocumentCreated] = useState(false);

  useLayoutEffect(() => {
    removeSyncfusionLicenseAlert();
  }, []);

  useEffect(() => {
    if (documentRef.current && isDocumentCreated) {
      onLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocumentCreated]);

  const handleDocumentCreated = () => {
    setIsDocumentCreated(true);
  };

  return (
    <DocumentEditorContainerComponent
      {...restProps}
      ref={saveDocumentRef}
      created={handleDocumentCreated}
      toolbarItems={SYNCFUSION_DOCUMENT_TOOLBAR_OPTIONS}
      serviceUrl={SYNCFUSION_DOCUMENT_SERVICES_URL}
      height={height}
      enableToolbar
    />
  );
};

export default DocumentHandler;
