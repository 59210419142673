import React, { memo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import FilterCounter from "../../../shared/components/FilterCounter";
import FilterCollapse from "./FilterCollapse";
import AsyncSearchField from "./AsyncSearchField";
import useFetchRequest from "../../../components/common/custom-hooks/useFetchRequest";
import {
  getAmenitiesOptionsQuery,
  getBrandsOptionsQuery,
  getVenueTypeOptionsQuery,
} from "../../../services/venue-sourcing";
import { useTransformListToOptions } from "../custom-hooks/useTransformers";
import { ratingFilterOptions } from "../utilities/data";
import SearchField from "./SearchField";
import SearchCollapse from "./SearchCollapse";

const VenueFilters = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  // API HOOKS & TRANSFORMERS
  const { data: venueTypeData } = useFetchRequest(getVenueTypeOptionsQuery);
  const { data: amenitiesData } = useFetchRequest(getAmenitiesOptionsQuery);
  const { data: brandsData } = useFetchRequest(getBrandsOptionsQuery);
  const venueTypeOptions = useTransformListToOptions(venueTypeData);
  const amenitiesOptions = useTransformListToOptions(amenitiesData);
  const brandsOptions = useTransformListToOptions(brandsData);

  const handleResetAllClick = () => {
    history.push(pathname);
  };

  return (
    <div className="filter-container">
      <div className="filter-header d-flex justify-content-between align-items-center py-2 ">
        <p className=" text-lg-100 text-75 mb-0">Advanced Search</p>

        <button className="reset-btn" onClick={handleResetAllClick}>
          Reset All
        </button>
      </div>

      <div className="filter-body">
        <AsyncSearchField />

        <SearchCollapse paramKey="city" title="Cities" />

        <FilterCollapse
          listOptions={venueTypeOptions}
          paramKey="venue_type"
          title="Venue Type"
          isSearch
        />

        <FilterCollapse
          listOptions={ratingFilterOptions}
          paramKey="star_rating"
          title="Rating"
        />

        <FilterCollapse
          listOptions={amenitiesOptions}
          paramKey="amenities"
          title="Amenities"
          isSearch
        />

        <FilterCollapse
          listOptions={brandsOptions}
          paramKey="brand"
          title="Brands/Chains/Hotels"
          isSearch
        />

        <SearchField
          paramKey="distance_from_airport"
          placeholder="Distance from airport"
          type="number"
        />

        <FilterCounter
          label="Guest Rooms"
          minParamKey="guest_rooms"
          maxParamKey="max_guest_rooms"
        />

        <FilterCounter
          label="Largest Meeting Room"
          minParamKey="largest_meeting_room"
          maxParamKey="max_largest_meeting_room"
        />

        <FilterCounter
          label="Second Largest Meeting Room"
          minParamKey="second_largest_meeting_room"
          maxParamKey="max_second_largest_meeting_room"
        />

        <FilterCounter
          label="Total Meeting space"
          minParamKey="total_meeting_space"
          maxParamKey="max_total_meeting_space"
        />

        <FilterCounter
          label="Meeting Rooms"
          minParamKey="total_meeting_room"
          maxParamKey="max_total_meeting_room"
        />

        <FilterCounter
          label="Ceiling Height"
          minParamKey="ceiling_height"
          maxParamKey="max_ceiling_height"
        />

        <FilterCounter
          label="Seating Capacity"
          minParamKey="seating_capacity"
          maxParamKey="max_seating_capacity"
        />

        <FilterCounter
          label="Standing Capacity"
          minParamKey="standing_capacity"
          maxParamKey="max_standing_capacity"
        />
      </div>

      {/* <Backdrop
        open
        style={{
          height: "100vh",
          width: "100%",
          zIndex: 999,
          overflow: "hidden",
        }}
      >
        <CircularProgress style={{ color: "#48b2e8" }} size={90} />
      </Backdrop> */}
    </div>
  );
};

export default memo(VenueFilters);
