import React from "react";
import SpinningCircles from "../../shared/svg-icons/SpinningCircles";

const LoadingButton = ({
  isLoading,
  disabled,
  children,
  className,
  loaderColor,
  ...restProps
}) => {
  return (
    <button
      {...restProps}
      className={`d-flex align-items-center gap-1 ${className}`}
      disabled={disabled || isLoading}
    >
      {isLoading && <SpinningCircles color={loaderColor} />}
      <span className="d-inline-block">{children}</span>
    </button>
  );
};

export default LoadingButton;
