import * as Yup from "yup";

export const myAccountFormValidationSchema = Yup.object().shape({
  username: Yup.string()
    .matches(
      /^[a-zA-Z0-9_ ]+$/,
      "Must include alphabets, numbers and underscore only"
    )
    .required("Required"),
  email: Yup.string().email("Invalid Email").required("Required"),
  full_name: Yup.string().required("Required"),
  profession: Yup.string().required("Required"),
  contact: Yup.number().required("Required"),
});

export const userFormValidationSchema = Yup.object().shape({
  account_name: Yup.string().required("Required"),
  username: Yup.string()
    .matches(
      /^[a-zA-Z0-9_ ]+$/,
      "Must include alphabets, numbers and underscore only"
    )
    .required("Required"),
  email: Yup.string().email("Invalid Email").required("Required"),
  full_name: Yup.string().required("Required"),
  profession: Yup.string().required("Required"),
  contact: Yup.number().required("Required"),
  password: Yup.string().when("id", {
    is: (value) => Boolean(value),
    then: Yup.string(),
    otherwise: Yup.string().required("Required"),
  }),
  role: Yup.string().required("Required"),
  comment: Yup.string().required("Required"),
});

export const changePasswordFormValidationSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords do not match"
  ),
});
