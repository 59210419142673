import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Pagination from "react-js-pagination";
import Header from "../../components/header";
import Footer from "../../components/footer";
import CardMedical from "./card_medical/index";
import { searchDestinations } from "../../server/Filter";
import "./index.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const destinationsPerPage = 9;

const Plan = () => {
  const search = useQuery().get("search");
  const month = useQuery().get("month");

  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    searchDestinations(null, search, month)
      .then((data) => setDestinations(data))
      .catch((err) => console.log(err));
  }, [search, month]);

  const [activePage, setCurrentPage] = useState(1);
  const indexOfLastDestinationImage = activePage * destinationsPerPage;
  const indexOfFirstDestinationImage =
    indexOfLastDestinationImage - destinationsPerPage;
  const currentDestinationImages = destinations.slice(
    indexOfFirstDestinationImage,
    indexOfLastDestinationImage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <React.Fragment>
      <Header />
      <Container
        className="event_banner px-0 position-relative"
        style={{
          backgroundImage:
            'url("/assets/images/events/destination-banner.jpg")',
        }}
        fluid
      >
        <div
          className="event_banner_image"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
        <div className="text-blocks">
          <p className="font-weight-bold text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-250 mb-0 font-poppins">
            Destinations
          </p>
        </div>
      </Container>
      <Row className="BackBtn">
        <Col xl={12} className="myback_button">
          <Link to="/events" className="back_button">
            <img
              src="/assets/images/icon-back.png"
              className="icon_back"
              alt=""
            />{" "}
            Back
          </Link>
        </Col>
      </Row>
      <Container
        style={{ position: "relative" }}
        className="text-center plan-parentxxx min-height-300 CustomizeContainer"
      >
        <div className=" text-left card-parent">
          <Row>
            {currentDestinationImages.map((item) => {
              return (
                <Col key={item.id} md={4} className="planConference">
                  <CardMedical data={item} />
                </Col>
              );
            })}
          </Row>
        </div>
        {destinations.length > destinationsPerPage && (
          <div className="paginations mt-6 mb-10">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={destinationsPerPage}
              totalItemsCount={destinations.length}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Plan;
