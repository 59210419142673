/* eslint-disable eqeqeq */
import React from "react";
import { Form, Row } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import TextEditor from "../../../../components/textEditor";
import ImagesUploader from "../../../../components/imageUploader";
import { API_URL } from "../../../../constants";
import {
  deleteGuestRoom,
  deleteGuestRoomImage,
} from "../../../../server/Filter";
import "./index.scss";

const initialTab = "#fff";

const GuestRoom = ({
  setStyle,
  hideComponent,
  register,
  control,
  setProperty,
  property,
  isSubmitting,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "venue_guest_rooms",
  });

  // const handleDeleteDefaultImage = (index, id) => {
  //   let guestRoom = property.venue_guest_rooms[index];
  //   const filteredImages = guestRoom.guest_room_images.filter(
  //     (item) => item.id !== id
  //   );
  //   guestRoom = { ...guestRoom, guest_room_images: filteredImages };
  //   const newValues = {
  //     ...property,
  //     venue_guest_rooms: property.venue_guest_rooms.map((room, roomIndex) =>
  //       roomIndex === index ? guestRoom : room
  //     ),
  //   };
  //   setProperty(newValues);
  //   deleteGuestRoomImage(id).catch((err) => console.log(err));
  // };

  const handleDeleteImage = (id) => {
    deleteGuestRoomImage(id).catch((err) => console.log(err));
  };

  const handleDeleteGuestRoom = (id, index) => {
    if (property) {
      const filteredGuestRoom = property?.venue_guest_rooms.filter(
        (item) => item.guest_room_id != id
      );
      const modifiedProperty = {
        ...property,
        venue_guest_rooms: filteredGuestRoom,
      };
      setProperty(modifiedProperty);
      if (id && id != 0) {
        deleteGuestRoom(id).catch((err) => console.log(err));
      }
    }
    remove(index);
  };

  return (
    <div className="my-7" style={{ display: hideComponent ? "none" : "" }}>
      {fields.map((value, index) => (
        <div key={value.id}>
          <div className="text-right">
            <CloseOutlined
              className="cursor-pointer"
              onClick={() => handleDeleteGuestRoom(value.guest_room_id, index)}
            />
          </div>
          <Form.Group controlId="formBasicText">
            <Form.Label>Room Category Name</Form.Label>
            {property?.venue_guest_rooms && (
              <Form.Control
                style={{ display: "none" }}
                name={`venue_guest_rooms[${index}].guest_room_id`}
                type="number"
                ref={register()}
                defaultValue={value.guest_room_id || 0}
              />
            )}
            <Form.Control
              name={`venue_guest_rooms[${index}].room_name`}
              type="text"
              ref={register()}
              defaultValue={value.room_name}
            />
          </Form.Group>
          <Form.Group controlId="formBasicText">
            <Form.Label>Number of Rooms</Form.Label>
            <Form.Control
              name={`venue_guest_rooms[${index}].num_rooms`}
              type="number"
              ref={register()}
              defaultValue={value.num_rooms}
            />
          </Form.Group>
          <div className="d-flex mb-4 align-items-center">
            <label className="pr-sm-5 mb-0 add_property_room_type_sel_label">
              Room Type:
            </label>
            <div className="px-4 add_property_room_type_sel">
              <select
                className="border-0 w-100 bg-transparent"
                style={{ height: "40px" }}
                name={`venue_guest_rooms[${index}].bed_type`}
                ref={register()}
                defaultValue={value.bed_type}
              >
                <option value="twin">Twin</option>
                <option value="king">King</option>
                <option value="queen">Queen</option>
                <option value="twin_and_king">Twin & King</option>
              </select>
            </div>
          </div>
          <Form.Group controlId="formBasicText" className="mb-2">
            <Form.Label className="w-100">
              Room Category Description:
            </Form.Label>
            <TextEditor
              name={`venue_guest_rooms[${index}].description`}
              control={control}
              defaultValue={
                property?.venue_guest_rooms &&
                property?.venue_guest_rooms[index]?.description
              }
            />
          </Form.Group>
          <Row>
            <small className="mb-7 text-success">
              <strong>Note:</strong> To paste the text inside text editor,
              Please use this command <strong>(ctrl + shift + v)</strong>
            </small>
          </Row>
          <Form.Group controlId="formBasicText">
            <Form.Label>Guest Room Images:</Form.Label>
            <ImagesUploader
              name={`venue_guest_rooms[${index}].guest_room_images`}
              label="Upload Guest Room Images"
              control={control}
              uploadUrl={API_URL + "/guestRoomImages/"}
              defaultImages={
                property?.venue_guest_rooms &&
                property?.venue_guest_rooms[index]?.guest_room_images
              }
              deleteImage={(id) => handleDeleteImage(id)}
            />
          </Form.Group>
          <hr style={{ borderWidth: 3 }} />
        </div>
      ))}
      <div className="mt-5">
        <button
          type="button"
          className="Button_hide"
          onClick={(e) => {
            e.preventDefault();
            append({
              room_name: "",
              num_rooms: "",
              bed_type: "twin",
              description: "",
            });
          }}
        >
          + Add Room Category
        </button>
      </div>
      <div className="text-center mt-7">
        <hr />
        <p>
          <strong className="mr-2">Important Note! </strong>
          Before saving changes, Please make sure all the images must be
          uploaded successfully.
        </p>
        <hr />
      </div>
      <div className="w-100 d-flex justify-content-end">
        <button
          className="Button_continue mt-7 mb-4 mr-5"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Saving..." : "Save"}
        </button>
        <button
          className="Button_continue mt-7 mb-4"
          type="submit"
          onClick={() => {
            setStyle(
              initialTab,
              initialTab,
              "#FFAC33",
              initialTab,
              initialTab,
              "venue"
            );
            window.scrollTo(0, 0);
          }}
        >
          Save & Continue
        </button>
      </div>
    </div>
  );
};

export default GuestRoom;
