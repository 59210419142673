import { colors } from "../colors";
export const DEFAULT_COMPONENTS = { IndicatorSeparator: () => null };

export const DEFAULT_STYLES = {
  valueContainer: (base) => ({
    ...base,
    height: "44px",
  }),
  control: (base) => ({
    ...base,
    opacity: 0.5,
  }),
  option: (base, state) => ({
    ...base,
    wordWrap: "break-word",
    backgroundColor: state.isFocused || state.isSelected ? colors.primary : "white",
    color: state.isFocused || state.isSelected  ? "white" : "black",
    "&:hover": { wordWrap: "break-word" },
  }),
};

export const multiSelectStyles = {
  valueContainer: (base) => ({
    ...base,
    minHeight: "46px",
  }),
  option: (base) => ({
    ...base,
    wordWrap: "break-word",
    "&:hover": { wordWrap: "break-word" },
  }),
};
