import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ServeiceButtons from '../service_buttons';
import Text from '../text';

const Transportation = ({ setTab }) => {
    return (
        <React.Fragment>
            <Form>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor="sub_category">Sub-category:</label>
                    </Col>
                    <Col className="px-0" md={6}>
                        <select name="sub_category">
                            <option>Airport Transfer</option>
                            <option>Dinner Transfer</option>
                            <option>Other Transportation</option>
                        </select>
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Text heading="Requirements:" />
                <ServeiceButtons setTab={setTab} />
            </Form>
        </React.Fragment>
    )
}

export default Transportation;
