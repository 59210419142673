import React from "react";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";

export default ({ name, control, ...rest }) => (
  <Controller
    {...rest}
    control={control}
    name={name}
    render={({ onChange, onBlur, value }) => (
      <DatePicker
        onChange={onChange}
        onBlur={onBlur}
        selected={value}
        onKeyDown={(e) => e.preventDefault()}
      />
    )}
    defaultValue={new Date()}
  />
);
