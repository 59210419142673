import React from "react";
import Image from "react-bootstrap/Image";
import { BsFillCaretDownFill } from "react-icons/bs";
import { Row, Container, Nav, Navbar } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import "./index.scss";
import { navbarMenuData } from "../utilities/data";

const logoSource = `${process.env.PUBLIC_URL}/assets/logo/logo.png`;

const NewNav = () => {
  const rendernavbar_new = (navbar_new, index) => {
    return (
      <React.Fragment key={index}>
        <div className="nav-dropdown dropdown">
          <NavLink
            to={navbar_new.link}
            className="navbar_link font-weight-bold navbar_new_text "
          >
            {navbar_new.text} <BsFillCaretDownFill className="dropdown-icon" />
          </NavLink>

          <div className="dropdown-content">
            {navbar_new.submenu.map((item, ind) => {
              return (
                <NavLink to={item.link} key={ind}>
                  {item.text}
                </NavLink>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Container fluid className="fluid_navbar">
      <Navbar expand="md" className="navbar_header p-0">
        <Row>
          <Link to="/" className="navbar-brand">
            <Image src={logoSource} fluid />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            style={{ alignSelf: "flex-end" }}
          >
            <Nav className="ml-auto">
              <div className="d-flex navbar-row">
                {navbarMenuData.map(rendernavbar_new)}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Row>
      </Navbar>
    </Container>
  );
};

export default NewNav;
