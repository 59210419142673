import React from "react";
import { Col, Container, Row } from "react-bootstrap";

// COMPONENTS & UTILITIES
import useFetchRequest from "../../../components/common/custom-hooks/useFetchRequest";
import { getVenueAttractionsDetailsQuery } from "../../../services/venue-details";
import { useGetVenueDetailContext } from "../custom-hooks/useGetVenueDetailContext";
import AttractionCardRow from "./AttractionCardRow";

function AttractionsTabPanel() {
  const { venueId } = useGetVenueDetailContext();
  const { data } = useFetchRequest(getVenueAttractionsDetailsQuery, venueId);

  return (
    <Container className="attractions-container">
      <Row className="mb-4">
        <h6 className="heading">Local Attractions</h6>
      </Row>

      <Row className="attractions-wrapper">
        {data?.venue_attractions?.length > 0 ? (
          data?.venue_attractions?.map((item) => (
            <AttractionCardRow key={item?.id} attraction={item} />
          ))
        ) : (
          <Col md={12} className="attraction">
            <p>No attractions found</p>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default AttractionsTabPanel;
