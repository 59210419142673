import React from "react";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Table } from "react-bootstrap";

function Registration(congressDetailes) {
  const { congresDetails } = congressDetailes;

  return (
    <div>
      <Table className="congress-table-container">
        <TableHead className="table-head congress-table  ">
          <TableRow className="table-row">
            <TableCell className="wrap" align="center">
              Registrations Name
            </TableCell>
            <TableCell className="wrap" align="center">
              Category
            </TableCell>
            <TableCell className="wrap" align="center">
              Registrations Closes
            </TableCell>
            <TableCell className="wrap" align="center">
              Price
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {congresDetails?.conference_registration?.length > 0 ? (
            congresDetails?.conference_registration?.map((item, index) => (
              <TableRow key={index} className="congress-table">
                <TableCell className="congress-title-cell" align="center">
                  {item.registration_heading}
                </TableCell>

                <TableCell className="congress-title-cell" align="center">
                  {item.registration_category}
                </TableCell>

                <TableCell className="congress-title-cell" align="center">
                  {item?.registration_end_date || "NA"}
                </TableCell>

                <TableCell className="congress-title-cell" align="center">
                  ${item.registration_fee}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow className="congress-table">
              <TableCell
                className="congress-title-cell"
                align="center"
                colSpan={4}
              >
                Registration Details not Available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {congresDetails?.more_information && (
        <div className="moreinfo-wrapper">
          <h6 className="heading my-5"> For More Information</h6>
          <div
            dangerouslySetInnerHTML={{
              __html: congresDetails?.more_information,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Registration;
