import React from "react";
import { FieldArray } from "formik";
import { Button } from "react-bootstrap";
import { MdRemoveCircle } from "react-icons/md";
import { useEventCoordinationContext } from "../../../context/EventCoordinationContext";
import FormikField from "../../../../../../shared/components/form/FormikField";
import FormikDatePicker from "../../../../../../shared/components/form/FormikDatePicker";
import ServiceQuestionController from "./ServiceQuestionController";
import RFPFormRecord from "../RFPFormRecord";
import { RFP_DETAILS_STATE_KEY } from "../../../utils/constants";
import { singleMeetingInitialValues } from "../../utils/initialValues";

const MeetingRooms = () => {
  const {
    [RFP_DETAILS_STATE_KEY]: { isEditing },
  } = useEventCoordinationContext();

  return (
    <ServiceQuestionController
      questionText="Do you require Meeting Rooms?"
      flag="meeting_rooms_flag"
    >
      <FieldArray
        name="rfpmeetingroom"
        render={({ form, push, remove }) => {
          return (
            <div className="w-100 mb-6">
              {form.values.rfpmeetingroom.map((_, index) => (
                <React.Fragment key={`meeting-room-${index}`}>
                  <div className="px-0 d-flex justify-content-between mb-6">
                    <div className="w-90 d-flex align-items-center pl-0" sm={6}>
                      <label>Meeting Room {index + 1}:</label>
                    </div>
                    {form.values.rfpmeetingroom.length > 1 && isEditing && (
                      <div className="w-10 d-flex justify-content-end align-items-center pr-0">
                        <MdRemoveCircle
                          size={20}
                          className="remove-icon"
                          onClick={() => remove(index)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="px-0 d-flex justify-content-between mb-6">
                    <div className="w-40 d-flex align-items-center pl-0">
                      <label className="pr-5">Date</label>
                      <FormikDatePicker
                        name={`rfpmeetingroom[${index}].meeting_date`}
                        placeholder="YYYY/MM/DD"
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="w-40 d-flex align-items-center pr-0">
                      <label className="pr-5">No. of Participants</label>
                      <FormikField
                        type="number"
                        name={`rfpmeetingroom[${index}].number_of_participants`}
                        placeholder="12"
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                  <div className="px-0 d-flex justify-content-between mb-6">
                    <div className="w-40 d-flex align-items-center pl-0">
                      <label className="pr-5">Start Time</label>
                      <FormikField
                        type="time"
                        name={`rfpmeetingroom[${index}].meeting_time_start`}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="w-40 d-flex align-items-center pr-0">
                      <label className="pr-5">End Time</label>
                      <FormikField
                        type="time"
                        name={`rfpmeetingroom[${index}].meeting_time_end`}
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                  <RFPFormRecord
                    label="Meeting Requirements/Setup"
                    labelClassName="w-40"
                    valueClassName="w-60"
                    removeLeftSpace
                  >
                    <FormikField
                      name={`rfpmeetingroom[${index}].remarks`}
                      textArea
                      rows={4}
                      disabled={!isEditing}
                    />
                  </RFPFormRecord>
                </React.Fragment>
              ))}
              {isEditing && (
                <Button
                  className="button small-button"
                  onClick={() => push(singleMeetingInitialValues)}
                >
                  + Add Meeting Room
                </Button>
              )}
            </div>
          );
        }}
      />
    </ServiceQuestionController>
  );
};

export default MeetingRooms;
