import React from "react";
import PageWrapper from "../../components/common/PageWrapper";
import Users from "./users";
import MyAccount from "./components/MyAccount";
import AddUser from "./components/AddUser";
import EditUser from "./components/EditUser";
import ChangePassword from "./components/ChangePassword";
import ManageAccountProvider, {
  ManageAccountContext,
} from "./context/ManageAccountContext";
import {
  ADD_USER,
  ADMIN,
  ALL_USERS,
  CHANGE_PASSWORD,
  EDIT_USER,
  MY_ACCOUNT,
  SUB_ADMIN,
} from "./utils/constants";
import "./index.scss";
import { useSelector } from "react-redux";

const ManageAccount = () => {
  const { user: loggedInUser = {} } = useSelector((state) => state.auth);

  return (
    <PageWrapper bannerTitle="Manage Account">
      {[ADMIN, SUB_ADMIN].includes(loggedInUser.profile.role) ? (
        <ManageAccountProvider>
          <ManageAccountContext.Consumer>
            {({ viewMode }) => (
              <div className="mt-10">
                {viewMode === MY_ACCOUNT && <MyAccount />}
                {viewMode === ALL_USERS && <Users />}
                {viewMode === ADD_USER && <AddUser />}
                {viewMode === EDIT_USER && <EditUser />}
                {viewMode === CHANGE_PASSWORD && <ChangePassword />}
              </div>
            )}
          </ManageAccountContext.Consumer>
        </ManageAccountProvider>
      ) : (
        <MyAccount />
      )}
    </PageWrapper>
  );
};

export default ManageAccount;
