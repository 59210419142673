import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import {
  getDesignListById,
  removeDesignFromList,
} from "../../../server/Filter";
import "./index.scss";

function getLink(type, code, name = "") {
  let link = "/concept_designs/";

  if (type === "stage") {
    link = link + "stage_designs/" + code;
  }
  if (type === "desk") {
    link = link + "hospitality_desk_designs/" + code;
  }
  if (type === "venue") {
    link = link + "venue_concept_designs/" + name;
  }
  if (type === "photo_booth") {
    link = link + "photo_booth_designs/" + code;
  }

  return link;
}

const PreferredDesignListItems = () => {
  const alert = useAlert();
  const { id } = useParams();
  const history = useHistory();
  const [conceptDesignList, setConceptDesignList] = useState({});

  useEffect(() => {
    getDesignListById(id)
      .then((data) => setConceptDesignList(data))
      .catch((err) => console.log(err));
  }, [id]);

  const designCards = conceptDesignList.designs
    ? conceptDesignList.designs.map((design) => ({
        id: design.id,
        source: design.thumbnail,
        code: design.code,
        type: design.design_type,
        name: design.design_name,
      }))
    : [];

  const [hoverIdentity, setHoverIdentity] = useState("");
  const [btnShow, setBtnShow] = useState("");

  const handleRemoveDesign = async (designId, designType, designCode) => {
    const designListCopy = [...conceptDesignList.designs];
    try {
      await removeDesignFromList(designId, designType);
      const updatedDesignList = designListCopy.filter(
        (list) => list.id !== designId
      );
      if (updatedDesignList.length < 1) {
        history.goBack();
      }
      setConceptDesignList({
        ...conceptDesignList,
        designs: updatedDesignList,
      });
      alert.show(`${designCode} removed from ${conceptDesignList.list_name}`, {
        type: "success",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const renderDesignCards = (designCard) => {
    return (
      <Row className="mb-5 mb-sm-7" key={designCard.id}>
        <Col
          xl={3}
          lg={4}
          sm={5}
          className="px-0"
          onMouseEnter={() => {
            setBtnShow("show");
            setHoverIdentity(designCard.id);
          }}
          onMouseLeave={() => setBtnShow("")}
        >
          <Link to={getLink(designCard.type, designCard.code, designCard.name)}>
            <Image
              src={designCard.source}
              fluid
              className="preffered_venue_img_single"
            />
          </Link>
          {btnShow === "show" && designCard.id === hoverIdentity ? (
            <div
              className="w-100 flex-column position-absolute"
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Row>
                <Col md={2} sm={3}></Col>
                <Col md={8} sm={6}>
                  <div className="new_exist_btn_row_link">
                    <button className="existing_rfp_button">
                      Add to existing RFP
                    </button>
                    <Link to="/event_form">
                      <button className="new_rfp_button">Add to New RFP</button>
                    </Link>
                  </div>
                </Col>
                <Col md={2} sm={3}></Col>
              </Row>
            </div>
          ) : null}
          <div
            className="position-absolute cursor-pointer"
            style={{
              width: "30px",
              top: "2%",
              right: "2%",
              transform: "translate(2%, 2%)",
            }}
            onClick={() => {
              handleRemoveDesign(
                designCard.id,
                designCard.type,
                designCard.code
              );
            }}
          >
            <FavoriteIcon style={{ color: "red" }} />
          </div>
        </Col>
        <Col xl={9} lg={8} sm={7} className="px-0 px-sm-5">
          <Link
            to={getLink(designCard.type, designCard.code)}
            className="text-decoration-none"
          >
            <p className="text-dark font-weight-bold text-lg-150 text-125 mb-2 mt-5 mt-md-0">
              {designCard.code}
            </p>
          </Link>
          <div className="d-flex">
            <p className="font-weight-bold mr-3">Type:</p>
            {designCard.type === "stage" && <p>Stage Design</p>}
            {designCard.type === "desk" && <p>Hospitality Desk Design</p>}
            {designCard.type === "venue" && <p>Venue Design</p>}
            {designCard.type === "photo_booth" && <p>Photo Booth Design</p>}
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <React.Fragment>
      <Header />
      <Container fluid className="px-0">
        <Row className="inner_width_div">
          <Col xs={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
            <Row className="my-5 align-items-center">
              <Col xs={6} className="px-0">
                <p className="font-weight-bold text-lg-150 text-125 mb-0">
                  {conceptDesignList.list_name}
                </p>
              </Col>
              <Col xs={6} className="text-right px-0">
                <Link to="/concept_designs/my_designs" className="back_button">
                  &lt;Back
                </Link>
              </Col>
            </Row>
            <div>{designCards.map(renderDesignCards)}</div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default PreferredDesignListItems;
