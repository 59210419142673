import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
// import { css } from "emotion";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import parse from "html-react-parser";

import "./Travelinfo.scss";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="right_arrow" onClick={onClick}>
      <BsChevronRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="left_arrow" onClick={onClick}>
      <BsChevronLeft />
    </div>
  );
}

function getSlidesToShow(windowWidth, noOfSlides) {
  if (windowWidth < 576) {
    return 1;
  } else if (windowWidth >= 576 && windowWidth < 768) {
    return noOfSlides < 2 ? noOfSlides : 2;
  } else {
    return noOfSlides < 3 ? noOfSlides : 3;
  }
}

const Travelinfo = ({ conference }) => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setwindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const conferenceHotels = conference.hotels ? [...conference.hotels] : [];

  if (conferenceHotels.length === 3) {
    conferenceHotels.push(conferenceHotels[1]);
  }

  const settings = {
    infinite: true,
    slidesToShow: getSlidesToShow(windowWidth, conference.hotels?.length),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: false,
    autoplaySpeed: 3000,
  };

  // const targetRef = useRef();
  // const [dimensions, setDimensions] = useState({});
  // const equal = css`
  //   height: ${(dimensions.width * 2) / 3 + "px"};
  // `;
  // // holds the timer for setTimeout and clearInterval
  // let movement_timer = null;
  // // the number of ms the window size must stay the same size before the
  // // dimension state variable is reset
  // const RESET_TIMEOUT = 100;
  // const test_dimensions = () => {
  //   // For some reason targetRef.current.getBoundingClientRect was not available
  //   // I found this worked for me, but unfortunately I can't find the
  //   // documentation to explain this experience
  //   if (targetRef.current) {
  //     setDimensions({
  //       width: targetRef.current.offsetWidth,
  //       height: targetRef.current.offsetHeight,
  //     });
  //   }
  // };
  // // This sets the dimensions on the first render
  // useLayoutEffect(() => {
  //   test_dimensions();
  // }, []);
  // // every time the window is resized, the timer is cleared and set again
  // // the net effect is the component will only reset after the window size
  // // is at rest for the duration set in RESET_TIMEOUT.  This prevents rapid
  // // redrawing of the component for more complex components such as charts
  // window.addEventListener("resize", () => {
  //   clearInterval(movement_timer);
  //   movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
  // });

  const hotelsArray = conferenceHotels?.map((card, index) => {
    return (
      <div className="travelinfo_slick_slider" key={index}>
        <Card
          className="travelinfo_card border-0"
          style={{
            backgroundColor: "#043e47",
            color: "#FFF",
            borderRadius: "unset",
            maxWidth: 400,
          }}
        >
          <a
            href={card.redirect_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card.Img
              variant="top"
              // ref={targetRef}
              src={card.hotel_images?.length > 0 && card.hotel_images[0].image}
              alt="hotel"
              className="conference-hotel-img"
            />
          </a>
          <Card.Body
            className="sliderCardBody"
            style={{ backgroundColor: "#48b2e8" }}
          >
            <div className="d-flex">
              <Col md={7} className="p-0 iconsCol">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  {card.hotel_name}
                </p>
                <p className="mb-0">{card.distance}</p>
              </Col>
              {/* <Col md={1}></Col> */}
              <Col md={5} className="iconsCol p-0">
                {card.star_rating && card.star_rating !== "unrated" && (
                  // <div className="divRating">
                  <img
                    src={`/assets/images/star-ratings/${card.star_rating}.png`}
                    className="rating"
                    alt=""
                  />
                  // </div>
                )}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={card.tripadvisor_link}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "flex-end" }}
                  >
                    {/* {card.trip_advisor_logo && (
                      <img
                        src={card.trip_advisor_logo}
                        className="tripAdvisor"
                        alt=""
                      />
                    )} */}
                    {card.tripadvisor_rating &&
                      card.tripadvisor_rating !== "unrated" && (
                        <div>
                          <img
                            src={`/assets/images/trip-advisor-ratings/${card.tripadvisor_rating}.png`}
                            className="circle"
                            alt=""
                          />
                        </div>
                      )}
                  </div>
                </a>
              </Col>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  });

  return (
    <React.Fragment>
      <Row className="travelInfoRow">
        <Col md={6} className="travelInfoCol">
          <img
            src="/assets/pages/testConference/mCongress/like.png"
            id="recommandedImg"
            alt=""
          />
          <p className="font-weight-bold text-lg-115 text-100 mb-0 mt-0">
            Recommended Hotels
          </p>
        </Col>
        <Col md={6} className="travelInfoCol travelInfoCol2">
          <img
            src="/assets/pages/testConference/mCongress/pin.png"
            id="recommandedImg"
            alt=""
          />
          <p className="font-weight-bold text-lg-115 text-100 mb-0 mt-0">
            {conference.address}
          </p>
        </Col>
      </Row>
      {/* <Row className="hotelsStarRow">
        <Col md={3} className="hotelsStarCol">
          <Star numOfStars={5} numOfHotels={13} />
        </Col>
        <Col md={3} className="hotelsStarCol">
          <Star numOfStars={4} numOfHotels={8} />
        </Col>
        <Col md={3} className="hotelsStarCol">
          <Star numOfStars={3} numOfHotels={5} />
        </Col>
      </Row> */}

      <Row className="my-3 my-sm-7 text-left conferenceSliders">
        <Col lg={12} md={12} className="px-0">
          <div className="w-100 m-auto">
            <Slider {...settings} className="ml-sm-7 mr-sm-7 ml-4 mr-4">
              {hotelsArray}
            </Slider>
          </div>
          <div className="sliderText">
            {conference.travel_info && parse(conference.travel_info.toString())}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Travelinfo;
