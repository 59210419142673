import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Tab,
  Nav,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Header from "../../components/header";
import OurServices from "./services";
import RequestsModule from "./components/Requests";
import Footer from "../../components/footer";
import {
  getHomeContent,
  getHomeServices,
  searchDestinations,
  getRFPRequests,
} from "../../server/Filter";
import "./index.scss";
import { CONGRESS, EVENTS } from "../../constants";

const Home = () => {
  const history = useHistory();
  const [homeFeaturedDestinations, setFeaturedDestinations] = useState([]);
  const [homeServices, setHomeServices] = useState([]);
  const [rfpRequests, setRFPRequests] = useState([]);
  const [homeContent, setHomeContent] = useState(null);

  useEffect(
    () => {
      (async () => {
        try {
          const generalContent = await getHomeContent();
          setHomeContent(generalContent[0]);

          const destinations = await searchDestinations(true);
          setFeaturedDestinations(destinations);

          const services = await getHomeServices();
          setHomeServices(services);

          const rfpRequestsResponse = await getRFPRequests();
          setRFPRequests(rfpRequestsResponse.results);
        } catch (error) {
          console.log(error);
        }
      })();
    },
    // eslint-disable-next-line
    []
  );

  const onSearch = ({ search }) => {
    history.push("/venue_sourcing/venues?search=" + encodeURIComponent(search));
  };

  const { register, handleSubmit } = useForm();

  const featuredDistinations = homeFeaturedDestinations
    .slice(0, 3)
    .map((item) => ({
      name: item.dest_city,
      image: item.thumbnail,
    }));
  const renderFeaturedDistination = (featuredDistination, index) => {
    return (
      <Col xl={4} lg={4} sm={6} className="px-0 px-sm-4 mb-5" key={index}>
        <NavLink to={`/destinations/${featuredDistination.name}`}>
          <Image src={featuredDistination.image} className="destinationImg" />
        </NavLink>
      </Col>
    );
  };

  if (!homeContent) return <Header />;

  const eventsRequests = rfpRequests.filter(
    (item) => item.category === EVENTS
  );
  const congressRequests = rfpRequests.filter(
    (item) => item.category === CONGRESS
  );

  return (
    <React.Fragment>
      <Header />
      <Container fluid className="px-0">
        <div className="position-relative">
          {homeContent?.banner_image?.map((item) => (
            <Image
              key={item.image}
              style={{ height: "calc(100vh - 148px)" }}
              className={"slick-slide-image w-100"}
              src={item.image}
              fluid
            />
          ))}

          <div>
            <div className="bannerContent">
              <Row className="bannerContentRow">
                <p className="text-uppercase mb-0">
                  {homeContent?.title?.split(" ")[0]}
                </p>
                <p className="explorers">{homeContent?.title?.split(" ")[1]}</p>
              </Row>
            </div>
          </div>
        </div>
        <Container className="CustomizeContainer">
          <Row className="ourServices">
            <h1 style={{ marginRight: "10px" }}>OUR</h1>
            <h1 style={{ borderBottom: " 5px solid #49B1E8" }}>SERVICES</h1>
          </Row>
          <Row>
            <OurServices services={homeServices} />
          </Row>
        </Container>
        <div className="mb-7">
          <Row className="ourServices">
            <h1 style={{ marginRight: "10px" }}>MY</h1>
            <h1 style={{ borderBottom: " 5px solid #49B1E8" }}>EVENTS</h1>
          </Row>
          <Container className="homeTabs CustomizeContainer">
            <Row className="mt-5">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Col sm={3} className="tabCol">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">All</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        Congress & <br />
                        Exhibition
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mb-0">
                      <Nav.Link eventKey="third">Events</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9} className="sliderCol">
                  <Tab.Content>
                    <Tab.Pane eventKey="first" className="w-100 h-100">
                      <RequestsModule requests={rfpRequests} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third" className="w-100 h-100">
                      <RequestsModule requests={eventsRequests} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second" className="w-100 h-100">
                      <RequestsModule requests={congressRequests} />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Tab.Container>
            </Row>
          </Container>
        </div>

        <Container className="CustomizeContainer">
          <div className="mb-20">
            <div
              className="mapDestination"
              style={{
                backgroundImage: "url(/assets/images/home/icons/map.png)",
              }}
            >
              <Row className="ourServices mt-10">
                <h1 style={{ marginRight: "10px" }}>Featured</h1>
                <h1
                  style={{
                    borderBottom: " 5px solid #49B1E8",
                  }}
                >
                  destinations
                </h1>
              </Row>
              <Row className="searchRow">
                <Form onSubmit={handleSubmit(onSearch)} inline>
                  <FormControl
                    name="search"
                    type="text"
                    placeholder="Search city/country"
                    ref={register}
                  />
                  <Button className="submitBtn" type="submit">
                    <img src="/assets/images/home/icons/7.png" alt="" />
                  </Button>
                </Form>
              </Row>
            </div>
            <Row className="destinationRow">
              {featuredDistinations.map(renderFeaturedDistination)}
            </Row>
          </div>
        </Container>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Home;
