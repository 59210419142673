import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import Header from '../../components/header';
import Footer from '../../components/footer';
import './index.scss';

const BrandingGeneralRequest = () => {
  const BrandingGeneralforms = [
    {
      for: "account_name",
      text: "ACCOUNT NAME:",
      type: "text",
      name: "account_name",
      placeholder: "PZ001",
    },
    {
      for: "email_address",
      text: "EMAIL ADDRESS:",
      type: "email",
      name: "email_address",
      placeholder: "OPERATIONS3@LUXURYEXPLORERSME.COM"
    },
    { 
      for: "event_name", 
      text: "Event NAME:", 
      type: "text", 
      name: "event_name" 
    },
    {
      for: "event_date",
      text: "Event Date:",
      type: "date",
      name: "event_date",
    },
    {
      for: "location",
      text: "Location:",
      type: "text",
      name: "location",
    },
  ];
  const renderBrandingGeneralform = (BrandingGeneralform, index) => {
    return (
      <Row className="form_main" key={index}>
        <Col className="px-0" lg={3} md={4}>
          <label htmlFor={BrandingGeneralform.for}>{BrandingGeneralform.text}</label>
        </Col>
        <Col lg={6} md={7} className="px-0">
          <input
            type={BrandingGeneralform.type}
            name={BrandingGeneralform.name}
            placeholder={BrandingGeneralform.placeholder}
            // value={BrandingGeneralform.value ? BrandingGeneralform.value : " "}
            onChange={(e) => {
                  
             }}
          />
        </Col>
        <Col lg={3} md={1}></Col>
      </Row>
    );
  };
  return (
    <React.Fragment>
      <Header />
        <Container className="pl-sm-6 pl-5 inner_width_div" fluid>
          <div onSubmit={() => console.log("submitted")} className="mt-12">
            <Row>
              <Col xs={12} className="brand_gen_req_head px-0">
                <p className="text-lg-150 text-125 mb-2">
                  General Request Form
                </p>
              </Col>
            </Row>
            {BrandingGeneralforms.map(renderBrandingGeneralform)}
            <Row className="form_main">
              <Col className="px-0" lg={3} md={4}>
                <label htmlFor="upload_file">Upload file:</label>
              </Col>
              <Col lg={6} md={7} className="px-0">
                <label className="branding_general_form_file"> Choose File
                  <input className="file-upload__input" type="file" name="myFile[]" id="myFiles" multiple />
                </label>
              </Col>
              <Col lg={3} md={1}></Col>
            </Row>
            <Row className="form_main">
              <Col className="px-0" xl={4} lg={5} md={6}>
                <label htmlFor="preffered_currency">
                  PREFFERED CURRENCY FOR THE QUOTE:
                </label>
              </Col>
              <Col xl={5} lg={4} md={5} className="px-0">
                <input className="file-upload__input" type="text" name="preffered_currency" />
              </Col>
              <Col lg={3} md={1}></Col>
            </Row>
            <Row>
              <Col xs={12} className="branding_general_form_col1 px-0">
                <p className="text-lg-150 text-125 mb-2">
                  Requirements:
                </p>
                <textarea name="textarea" rows="7" className="text_area"></textarea>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="branding_general_form_col2 px-0">
                <Button className="Button">
                  Send Request
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      <Footer />
    </React.Fragment>
  )
}

export default BrandingGeneralRequest;
