import React from "react";
import { FieldArray } from "formik";
import { Table, Button } from "react-bootstrap";
import { MdRemoveCircle } from "react-icons/md";
import { useEventCoordinationContext } from "../../../context/EventCoordinationContext";
import FormikField from "../../../../../../shared/components/form/FormikField";
import FormikSelect from "../../../../../../shared/components/form/FormikSelect";
import ServiceQuestionController from "./ServiceQuestionController";
import TableData from "./TableData";
import { singleGroundTransportInitialValues } from "../../utils/initialValues";
import { tourEntertainmentCategortOptions } from "../../utils/constants";
import { RFP_DETAILS_STATE_KEY } from "../../../utils/constants";

const TourEntertainment = () => {
  const {
    [RFP_DETAILS_STATE_KEY]: { isEditing },
  } = useEventCoordinationContext();

  return (
    <div className="w-100">
      <ServiceQuestionController
        questionText="Do you require Tours/Entertainment?"
        flag="tour_entertainment_flag"
        stepNo={10}
      >
        <FieldArray
          name="tour_entertainment"
          render={({ form, push, remove }) => {
            return (
              <Table className="rfp-table">
                <thead>
                  <tr>
                    <td width="33.33%">Category</td>
                    <td width="33.33%">Description</td>
                    <td width="33%.33">No. of days</td>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {form.values.tour_entertainment.map((_, index) => (
                      <tr key={index}>
                        <TableData>
                          <FormikSelect
                            name={`tour_entertainment[${index}].category`}
                            placeholder="Category"
                            options={tourEntertainmentCategortOptions}
                            disabled={!isEditing}
                          />
                        </TableData>
                        <TableData>
                          <FormikField
                            name={`tour_entertainment[${index}].description`}
                            placeholder="Description"
                            disabled={!isEditing}
                          />
                        </TableData>
                        <TableData>
                          <FormikField
                            name={`tour_entertainment[${index}].no_of_days`}
                            type="number"
                            placeholder="No. of days"
                            disabled={!isEditing}
                          />
                        </TableData>
                        {form.values.tour_entertainment.length > 1 &&
                          isEditing && (
                            <MdRemoveCircle
                              size={20}
                              className="remove-icon table-rows-remove-icon"
                              onClick={() => remove(index)}
                            />
                          )}
                      </tr>
                    ))}
                    <tr className="d-flex">
                      {isEditing && (
                        <Button
                          className="mt-5 button small-button"
                          onClick={() =>
                            push(singleGroundTransportInitialValues)
                          }
                        >
                          + Add Tour/Entertainment
                        </Button>
                      )}
                    </tr>
                  </React.Fragment>
                </tbody>
              </Table>
            );
          }}
        />
      </ServiceQuestionController>
    </div>
  );
};

export default TourEntertainment;
