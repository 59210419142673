import { useEffect } from "react";
import { useState } from "react";

export const useTransformCountryList = (data) => {
  const [modifiedData, setModifiedData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const modifiedData = data.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      setModifiedData(modifiedData);
    }
  }, [data]);

  return modifiedData;
};
