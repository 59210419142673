import * as Yup from "yup";

const generateServiceContentSchema = (
  initialFieldSchema,
  dependentFieldName,
  conditionalFieldSchema
) =>
  initialFieldSchema.when(dependentFieldName, {
    is: true,
    then: conditionalFieldSchema,
  });

export const eventDetailsValidationSchema = {
  account_name: Yup.string().required("Required"),
  account_email: Yup.string()
    .email()
    .typeError("Invalid Email")
    .required("Required"),
  category: Yup.string().required("Required"),
  event_name: Yup.string().required("Required"),
  no_of_attendee: Yup.number()
    .typeError("Must be a number")
    .min(1, "Must be at least 1")
    .required("Required"),
  required_date_start: Yup.date()
    .typeError("Invalid Date")
    .required("Required"),
  required_date_end: Yup.date()
    .min(Yup.ref("required_date_start"), "End date must be after start date")
    .typeError("Invalid Date")
    .required("Required"),
  alternative_date_start: Yup.date().typeError("Invalid Date"),
  alternative_date_end: Yup.date()
    .min(Yup.ref("alternative_date_start"), "End date must be after start date")
    .typeError("Invalid Date"),
  rfpregistration: generateServiceContentSchema(
    Yup.object(),
    "registration_flag",
    Yup.object().shape({
      category: Yup.string().required("Required"),
      number_of_delegates: Yup.number()
        .typeError("Must be a number")
        .required("Required"),
    })
  ),
};

export const registrationValidationSchema = {
  rfpregistration: generateServiceContentSchema(
    Yup.array(),
    "registration_flag",
    Yup.array().of(
      Yup.object().shape({
        category: Yup.string().required("Required"),
        number_of_delegates: Yup.number()
          .typeError("Must be a number")
          .required("Required"),
      })
    )
  ),
};

export const flightTicketValidationSchema = {
  rfpflightticket: generateServiceContentSchema(
    Yup.array(),
    "flight_ticket_flag",
    Yup.array().of(
      Yup.object().shape({
        departure_place: Yup.string().required("Required"),
        number_of_pax: Yup.number()
          .typeError("Must be a number")
          .required("Required"),
      })
    )
  ),
};

export const visaAssistanceValidationSchema = {
  rfpvisaassistance: generateServiceContentSchema(
    Yup.object(),
    "visa_assistance_flag",
    Yup.object().shape({
      nationality: Yup.string().required("Required"),
      remarks: Yup.string(),
    })
  ),
};

export const accommodationValidationSchema = {
  rfpaccomodation: generateServiceContentSchema(
    Yup.object(),
    "accomodation_flag",
    Yup.object().shape({
      hotel_rating: Yup.array()
        .min(1, "Please select at least 1 option")
        .required("Required"),
      check_in: Yup.date()
        .nullable()
        .typeError("Invalid Date")
        .required("Required"),
      check_out: Yup.date()
        .nullable()
        .typeError("Invalid Date")
        .required("Required"),
      accomodation_rooms: Yup.array().of(
        Yup.object().shape({
          no_of_rooms: Yup.number()
            .typeError("Must be a number")
            .required("Required"),
          occupancy: Yup.string().required("Required"),
          meal_plan: Yup.string().required("Required"),
          remarks: Yup.string(),
        })
      ),
    })
  ),
};

export const meetingRoomsValidationSchema = {
  rfpmeetingroom: generateServiceContentSchema(
    Yup.array(),
    "meeting_rooms_flag",
    Yup.array().of(
      Yup.object().shape({
        meeting_date: Yup.date()
          .nullable()
          .typeError("Invalid Date")
          .required("Required"),
        number_of_participants: Yup.number()
          .typeError("Must be a number")
          .min(1, "Must be at least 1")
          .required("Required"),
        meeting_time_start: Yup.string(),
        meeting_time_end: Yup.string(),
        remarks: Yup.string(),
      })
    )
  ),
};

export const groundTransportationValidationSchema = {
  rfpgroundtransportation: generateServiceContentSchema(
    Yup.array(),
    "ground_transportation_flag",
    Yup.array().of(
      Yup.object().shape({
        category: Yup.string().required("Required"),
        type_of_service: Yup.string().required("Required"),
        remarks: Yup.string(),
      })
    )
  ),
};

export const fAndBArrangementValidationSchema = {
  f_and_b_arrangement: generateServiceContentSchema(
    Yup.array(),
    "f_and_b_arrangement_flag",
    Yup.array().of(
      Yup.object().shape({
        category: Yup.string().required("Required"),
        description: Yup.string(),
        no_of_days: Yup.number().required("Required"),
      })
    )
  ),
};

export const tourEntertainmentValidationSchema = {
  tour_entertainment: generateServiceContentSchema(
    Yup.array(),
    "tour_entertainment_flag",
    Yup.array().of(
      Yup.object().shape({
        category: Yup.string().required("Required"),
        description: Yup.string(),
        no_of_days: Yup.number().required("Required"),
      })
    )
  ),
};

export const otherRequirementsValidationSchema = {
  other_requirements: generateServiceContentSchema(
    Yup.string(),
    "other_requirement_flag",
    Yup.string().required("Required")
  ),
};

export const productionValidationSchema = {
  rfpproduction: generateServiceContentSchema(
    Yup.object(),
    "production_flag",
    Yup.object().shape({
      uploaded_design: Yup.object().nullable(),
      requested_design: Yup.string(),
    })
  ),
};

export default Yup.object().shape({
  ...eventDetailsValidationSchema,
  ...registrationValidationSchema,
  ...flightTicketValidationSchema,
  ...visaAssistanceValidationSchema,
  ...accommodationValidationSchema,
  ...meetingRoomsValidationSchema,
  ...groundTransportationValidationSchema,
  ...fAndBArrangementValidationSchema,
  ...tourEntertainmentValidationSchema,
  ...otherRequirementsValidationSchema,
  ...productionValidationSchema,
});
