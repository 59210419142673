import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../components/header';
import MainForm from './main_form';
import Flight from './flight';
import Accomodate from './accomodate';
import MeetingRoom from './meeting_room';
import Program from './program';
import Branding from './branding';
import Transportation from './transportation';
import Food from './food';
import Others from './others';
import Footer from '../../components/footer';
import './index.scss';

const GeneralInquiry = () => {
    const [ tab, setTab ] = useState("MainForm")
    return (
        <React.Fragment>
            <Header />
            <Container fluid className="px-0">
                <Row className="inner_width_div">
                    <Col xl={12} className="pl-sm-6 pl-5">
                        <MainForm setTab={setTab} />
                        { 
                            tab === "Flight" ?
                            <div>
                                <Flight setTab={setTab} />
                            </div> :  
                            tab === "Accomodate" ? 
                            <div>
                                <Flight />
                                <Accomodate setTab={setTab} />
                            </div> : 
                            tab === "MeetingRoom" ?
                            <div>
                                <Flight />
                                <Accomodate />
                                <MeetingRoom setTab={setTab} />
                            </div> : 
                            tab === "Program" ?
                            <div>
                                <Flight />
                                <Accomodate />
                                <MeetingRoom />
                                <Program setTab={setTab} />
                            </div> : 
                            tab === "Branding" ?
                            <div>
                                <Flight />
                                <Accomodate />
                                <MeetingRoom />
                                <Program />
                                <Branding setTab={setTab} />
                            </div> : 
                            tab === "Transportation" ?
                            <div>
                                <Flight />
                                <Accomodate />
                                <MeetingRoom />
                                <Program />
                                <Branding />
                                <Transportation setTab={setTab} />
                            </div> : 
                            tab === "Food" ?
                            <div>
                                <Flight />
                                <Accomodate />
                                <MeetingRoom />
                                <Program />
                                <Branding />
                                <Transportation />
                                <Food setTab={setTab} />
                            </div> : 
                            tab === "Others" ?
                            <div>
                                <Flight />
                                <Accomodate />
                                <MeetingRoom />
                                <Program />
                                <Branding />
                                <Transportation />
                                <Food />
                                <Others setTab={setTab} />
                            </div> : ""
                        }
                    </Col>
                </Row>
            </Container>
            <Footer />
        </React.Fragment>
    )
}


export default GeneralInquiry;


  
