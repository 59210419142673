import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Text = (props) => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} className="px-0">
                    <div className="gen_inquiry_text">
                        <p className="text-lg-150 text-125 mb-2">
                            {props.heading}
                        </p>
                    </div>
                    <textarea name="textarea" rows="7" className="text_area"></textarea>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Text;
