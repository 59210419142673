import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Pagination from "react-js-pagination";

import BrandingPackage from "../branding_package";
import { getBrandingPackages } from "../../../server/Filter";
import "./index.scss";

const BrandingPackages = () => {
  const [brandingPackages, setBrandingPackages] = useState(null);

  useEffect(() => {
    getBrandingPackages()
      .then((data) => setBrandingPackages(data))
      .catch((err) => console.log(err));
  }, []);

  const brandingPackagesArray = brandingPackages?.map((brandingPackage) => ({
    id: brandingPackage.id,
    name: brandingPackage.package_name,
    sliderImages: brandingPackage.slider_images,
  }));

  const brandingPackagesArrayPerPage = 8;
  const [activePage, setCurrentPage] = useState(1);
  const indexOfLastbrandingPackage = activePage * brandingPackagesArrayPerPage;
  const indexOfFirstbrandingPackage =
    indexOfLastbrandingPackage - brandingPackagesArrayPerPage;
  const currentbrandingPackagesArray = brandingPackagesArray?.slice(
    indexOfFirstbrandingPackage,
    indexOfLastbrandingPackage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <React.Fragment>
      <Row className="branding_category_row">
        {brandingPackagesArray?.length > 0 &&
          currentbrandingPackagesArray.map((brandingPackage) => (
            <BrandingPackage
              key={brandingPackage.id}
              brandingPackage={brandingPackage}
            />
          ))}
        {brandingPackagesArray?.length < 1 && (
          <p className="text-lg-100 text-75">No package found!</p>
        )}
      </Row>
      {brandingPackagesArray?.length > 8 && (
        <div className="paginations branding_paginations">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={8}
            totalItemsCount={brandingPackagesArray?.length}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default BrandingPackages;
