import { useEffect, useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { roomTypes } from "../utilities/helpers";
import { STORAGE_URL } from "../../../constants";
import { formatDecimalValue, getDummyVenueImage } from "../../../utils/helpers";

export const useGetTransformedVenueBasicDetails = (data) => {
  const [transformedData, setTransformedData] = useState([]);

  useEffect(() => {
    if (Object.values(data)?.length > 0) {
      const modifiedData = [
        {
          label: "Chain",
          value: data.venue_highlights?.chain_name || "-",
        },
        {
          label: "Built",
          value: data.venue_highlights?.build_year || "-",
        },
        {
          label: "Guest Rooms",
          value: data.venue_highlights?.total_sleeping_room || "-",
        },
        {
          label: "Total meeting space",
          value: `${
            Math.round(data.venue_highlights?.meeting_space) || "0.0"
          } sq. m`,
        },
        {
          label: "Brand",
          value: data.venue_highlights?.brand_name || "-",
        },
        {
          label: "Renovated",
          value: data.venue_highlights?.renovation_year || "-",
        },
        {
          label: "Venue Type",
          value: data?.venue_type || "-",
        },
        {
          label: "Largest Meeting Room",
          value: `${formatDecimalValue(data?.largest_meeting_room)} sq. m`,
        },
        {
          label: "Second Largest Meeting Room",
          value: `${formatDecimalValue(
            data?.second_largest_meeting_room
          )} sq.m`,
        },
        {
          label: "Total Meeting Rooms",
          value: data.venue_highlights?.total_meeting_room || "-",
        },
      ];

      setTransformedData(modifiedData);
    }
  }, [data]);

  return transformedData;
};

export const useGetVenueSpaceTableData = (data = []) => {
  const [modifiedData, setModifiedData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const dummyImage = "/assets/images/dummy-card-image.jpg";

      const transformedData = data.map((item) => {
        const banquetRounds = item?.meeting_space_capacity?.find(
          (room) => room.type === roomTypes?.bankquetRounds
        )?.capacity;

        const theater = item?.meeting_space_capacity?.find(
          (room) => room.type === roomTypes?.theater
        )?.capacity;

        const classRoom = item?.meeting_space_capacity?.find(
          (room) => room.type === roomTypes?.classroom
        )?.capacity;

        const boardRoom = item?.meeting_space_capacity?.find(
          (room) => room.type === roomTypes?.boardRoomConference
        )?.capacity;

        const uShape = item?.meeting_space_capacity?.find(
          (room) => room.type === roomTypes?.uShaped
        )?.capacity;

        return {
          id: item?.id,
          venue_id: item?.venue_id,
          name: item.meeting_room_name,
          room_type: item.room_type,
          capacity: item.capacity,
          dimension: item.dimension,
          ceiling_height: item.ceiling_height,
          floor_plan: item.floor_plan,
          description: item.description,
          height: item.height,
          width: item.width,
          length: item.length,
          total_space: item.total_space,
          image: item?.main_image
            ? `${STORAGE_URL}${item.main_image}`
            : dummyImage,
          banquetRounds,
          theater,
          classRoom,
          boardRoom,
          uShape,
        };
      });

      setModifiedData(transformedData);
    }
  }, [data]);

  return modifiedData;
};

export const useGetTransformedLocationDetails = (data = {}) => {
  const [trnasformedData, setTransformedData] = useState({});

  useEffect(() => {
    if (data?.longitude && data?.latitude) {
      const modifiedData = {
        latitude: data?.latitude,
        longitude: data?.longitude,
        venue_name: data?.venue_name,
        property_logo: data?.property_logo,
      };

      setTransformedData(modifiedData);
    }
  }, [data]);

  return trnasformedData;
};

export const useGetBasicInfoGridImages = (data = null) => {
  const venueAllImages = useMemo(() => {
    if (data?.venue_images?.length > 0) {
      return data.venue_images.map((item) => ({
        image: STORAGE_URL + item?.image,
      }));
    }

    return [];
  }, [data]);

  const venueMainImage = useMemo(() => {
    if (data?.venue_images?.length > 0) {
      const mainImage = data.venue_images?.find(
        (item) => item?.image_order === 1
      );

      if (mainImage) {
        return mainImage?.image;
      }

      return data?.venue_images[0]?.image;
    }

    return "";
  }, [data]);

  const gridImages = useMemo(() => {
    if (data?.venue_images?.length > 0) {
      const filteredImages = data.venue_images.filter(
        (item) =>
          item?.image_order === 2 ||
          item?.image_order === 3 ||
          item?.image_order === 4 ||
          item?.image_order === 5
      );

      if (filteredImages?.length === 4) {
        return filteredImages?.slice(0, 4)?.map((item) => ({
          image: item?.image,
        }));
      }

      return data.venue_images.slice(1, 5)?.map((item) => ({
        image: item?.image,
      }));
    }

    return [];
  }, [data]);

  return { gridImages, allImages: venueAllImages, mainImage: venueMainImage };
};

export const useGetGuestRoomImages = (data = null) => {
  const mainImage = useMemo(() => {
    if (data?.venue_images?.length > 0) {
      return `${STORAGE_URL}${data?.venue_images[0]?.image}`;
    }

    return getDummyVenueImage(null);
  }, [data]);

  const gridImages = useMemo(() => {
    const dummyImage = getDummyVenueImage(null);

    if (data?.venue_images?.length > 6) {
      return data?.venue_images?.slice(1, 7)?.map((item) => ({
        id: item?.id,
        image: `${STORAGE_URL}${item?.image}`,
      }));
    }

    const dummyImages = new Array(6)
      .fill({})
      .map((_, idx) => ({ id: idx, image: dummyImage }));

    const images = data?.venue_images
      ?.map((item) => ({
        id: uuidv4(),
        image: getDummyVenueImage(item?.image),
      }))
      ?.concat(dummyImages);

    return images?.slice(1, 7);
  }, [data]);

  const sliderAllImages = useMemo(() => {
    if (data?.venue_images?.length > 0) {
      return data?.venue_images?.map((item) => ({
        image: `${STORAGE_URL}${item?.image}`,
      }));
    }

    return [];
  }, [data]);

  const isModalDisable = useMemo(() => {
    if (data?.venue_images?.length >= 2) {
      return false;
    }

    return true;
  }, [data]);

  return { gridImages, mainImage, sliderAllImages, isModalDisable };
};
