// import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";

// ACTIONS
import { increaseCartCount } from "../redux/actions/cartActions";
import {
  addToCongressCart,
  addToEventCart,
  removeFromCart,
} from "../server/Filter";

function useGetCartHandlers(venue) {
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const alert = useAlert();

  const handleEventCart = async () => {
    try {
      if (state && state.id) {
        await removeFromCart(state.modelName, state.modelID);
      }
      await addToEventCart("Venue", venue.id);
      if (state && state.id) {
        history.replace({
          ...history.location,
          state: null,
        });
        history.push("/cart");
        alert.success(
          `${state.descriptionFields.title} replaced with ${venue.name}`
        );
      } else {
        dispatch(increaseCartCount());
        if (venue?.name) {
          alert.success(`${venue.name} successfully added to events cart`);
        } else {
          alert.success(`Venue successfully added to events cart`);
        }
      }
    } catch (err) {
      if (err.response) {
        alert.error(err.response.data);
      }
    }
  };

  const handleCongressCart = async (thumbnailImage = null, isVenue) => {
    try {
      if (state && state.id) {
        await removeFromCart(state.modelName, state.modelID);
      }

      await addToCongressCart(
        isVenue ? "Venue" : "Conference",
        venue.id,
        thumbnailImage
      );
      if (state && state.id) {
        history.replace({
          ...history.location,
          state: null,
        });
        history.push("/cart");
        alert.show(
          `${state.descriptionFields.title} replaced with ${venue.name}`,
          {
            type: "success",
          }
        );
        history.push({
          ...history.location,
          state: null,
        });
      } else {
        dispatch(increaseCartCount());
        alert.success(
          `${venue?.name || "Conference"} successfully added to congress cart`
        );
      }
    } catch (err) {
      if (err.response) {
        alert.show(err.response.data, {
          type: "error",
        });
      }
    }
  };

  return { handleEventCart, handleCongressCart };
}

export default useGetCartHandlers;
