import { useSelector } from "react-redux";

function useAuth() {
  const { token, isAuthenticated, isLoading, user } = useSelector(
    (state) => state.auth
  );
  return { token, isAuthenticated, isLoading, user };
}

export default useAuth;
