import React, { memo, useRef, useEffect, useMemo } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import { ratingFieldOptions } from "../../../utils/selectOptions";
import useFilterHandlers from "../custom-hooks/useFilterHandlers";
import useQuery from "../../../custom-hooks/useQuery";
import { getSearchParamObj } from "../../../utils/helpers";
import FilterSelectField from "./FilterSelectField";

function Filters() {
  const locationRef = useRef(null);
  const cityRef = useRef(null);
  const searchParams = useQuery();
  const searchParamsObj = useMemo(
    () => getSearchParamObj(searchParams),
    [searchParams]
  );

  useEffect(() => {
    if (!searchParams.get("search") && locationRef.current) {
      locationRef.current.clear();
    }

    if (!searchParams.get("city") && cityRef.current) {
      cityRef.current.clear();
    }
  }, [searchParams, locationRef, cityRef]);

  const {
    cityOptions,
    handleSearchCity,
    handleSearchLocation,
    handleSelectCity,
    handleSelectLocation,
    isCityLoading,
    isCitySuccess,
    isLocationLoading,
    isLocationSuccess,
    locationOptions,
    venueTypeOptions,
    hasParams,
    handleResetParams,
  } = useFilterHandlers(searchParams, searchParamsObj);

  return (
    <div className="row align-items-center mb-4">
      <div className="col pl-0 pr-1">
        <AsyncTypeahead
          ref={locationRef}
          id="search"
          filterBy={() => true}
          isLoading={isLocationSuccess && isLocationLoading}
          onChange={handleSelectLocation}
          onSearch={handleSearchLocation}
          options={locationOptions}
          placeholder="Location"
          minLength={2}
        />
      </div>

      <div className="col px-1">
        <AsyncTypeahead
          id="city"
          ref={cityRef}
          filterBy={() => true}
          isLoading={isCityLoading && isCitySuccess}
          onChange={handleSelectCity}
          onSearch={handleSearchCity}
          options={cityOptions}
          placeholder="City"
          minLength={2}
        />
      </div>

      <div className="col px-1">
        <FilterSelectField
          name="venue_type"
          options={venueTypeOptions}
          placeholder="Venue Type"
        />
      </div>

      <div className="col px-1">
        <FilterSelectField
          name="star_rating"
          options={ratingFieldOptions}
          placeholder="Rating"
        />
      </div>

      {hasParams && (
        <div className="col-auto">
          <button
            type="button"
            className="btn-main-secondary md"
            onClick={handleResetParams}
          >
            Reset
          </button>
        </div>
      )}
    </div>
  );
}

export default memo(Filters);
