import React from "react";
import { Controller } from "react-hook-form";
import DropZoneUploader from "../../components/dropzoneUploader";

export default function ({
  control,
  name,
  uploadUrl,
  deleteImage,
  multiple,
  maxFiles,
  defaultImages,
  info,
}) {
  return (
    <Controller
      control={control}
      id={name}
      name={name}
      defaultValue={[]}
      render={(props) => (
        <DropZoneUploader
          uploadUrl={uploadUrl}
          onChange={props.onChange}
          deleteImage={deleteImage}
          multiple={multiple}
          maxFiles={maxFiles}
          defaultImages={defaultImages}
          info={info}
        />
      )}
    />
  );
}
