import React from "react";
import { Row, Col } from "react-bootstrap";

const RFPFieldWrapper = ({ label, children }) => {
  return (
    <Row className="justify-content-center align-items-center mb-6">
      <Col className="px-0" md={5}>
        <label className="mb-0">{label}</label>
      </Col>
      <Col md={7} className="px-0">
        {children}
      </Col>
    </Row>
  );
};

export default RFPFieldWrapper;
