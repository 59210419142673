import React from "react";
import { Row, Col } from "react-bootstrap";

const MedServiceType = ({ tab, setTab, disabledServiceTypes }) => {
  const selections = [
    { text: "", value: "" },
    {
      text: "Registration",
      value: "Registration",
      disabled: disabledServiceTypes.registrationDisabled,
    },
    {
      text: "Flight Ticket",
      value: "Flight",
      disabled: disabledServiceTypes.flightDisabled,
    },
    {
      text: "Accomodation",
      value: "Accomodate",
      disabled: disabledServiceTypes.accomodateDisabled,
    },
    {
      text: "Transportation",
      value: "Transportation",
      disabled: disabledServiceTypes.transportationDisabled,
    },
    {
      text: "Food and Beverages",
      value: "Food",
      disabled: disabledServiceTypes.foodDisabled,
    },
    {
      text: "Others",
      value: "Others",
    },
  ];

  return (
    <React.Fragment>
      <Row
        className="form_main gen_inquiry_selectbar"
        style={{ clear: "both" }}
      >
        <Col className="px-0" md={3}>
          <label htmlFor="service_type">SERVICE TYPE:</label>
        </Col>
        <Col className="form_column_sel pl-0" md={6}>
          <select value={tab} onChange={(e) => setTab(e.target.value)}>
            {selections.map((o, index) => (
              <option
                key={index}
                value={o.value}
                hidden={o.value === "" ? "hidden" : ""}
                disabled={o.disabled}
              >
                {o.text}
              </option>
            ))}
          </select>
        </Col>
        <Col md={3}></Col>
      </Row>
    </React.Fragment>
  );
};

export default MedServiceType;
