import { useState } from "react";

const DEFAULT_PAGE = 0;
const DEFAULT_ROWS_PER_PAGE = 5;
const DEFAULT_ROWS_PER_PAGE_OPTIONS = [5, 10, 20];

export const DEFAULT_PAGINATION_OPTIONS = {
  count: 0,
  page: DEFAULT_PAGE,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  onPageChange: (newPage, rowsPerPage) => null,
  onRowsPerPageChange: (newRowsPerPage, page) => null,
  rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
};

function useTablePagination({
  count,
  page: defaultPage,
  rowsPerPage: defaultRowsPerPage,
  onPageChange = () => null,
  onRowsPerPageChange = () => null,
  rowsPerPageOptions = [],
}) {
  const [page, setPage] = useState(defaultPage || DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(
    defaultRowsPerPage || rowsPerPageOptions[0] || DEFAULT_ROWS_PER_PAGE
  );

  const handlePageChange = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    onPageChange(newPage, rowsPerPage);
  };

  const handleRowsPerPageChange = (event) => {
    event.preventDefault();
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    onRowsPerPageChange(newRowsPerPage, page);
  };

  return {
    count: count || 0,
    page,
    rowsPerPage,
    onChangePage: handlePageChange,
    onChangeRowsPerPage: handleRowsPerPageChange,
    rowsPerPageOptions: rowsPerPageOptions || DEFAULT_ROWS_PER_PAGE_OPTIONS,
  };
}

export default useTablePagination;
