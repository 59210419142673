import React from "react";
import { useHistory } from "react-router-dom";
import { Tab } from "react-bootstrap";
import useGetRfpRequests from "../../components/common/custom-hooks/useGetRfpRequests";
import useRFPEventTableHeadings from "../../components/common/rfp/custom-hooks/useRFPEventTableHeadings";
import RFPRequestsWrapper from "../../components/common/rfp/RFPRequestsWrapper";
import SimpleTable from "../../shared/components/table/SimpleTable";
import getSorting from "../../utils/getSorting";
import getFormatedDateRange from "../../utils/getFormatedDateRange";
import { tableHeadings as headings } from "./utils/headings";

const EVENTS = "EVENTS";
const CONGRESS = "CONGRESS";

const Requested = () => {
  const history = useHistory();
  const { isLoading, data } = useGetRfpRequests("Requested");
  const { tableHeadings } = useRFPEventTableHeadings(headings);

  const modifiedData = data
    .sort(getSorting("desc", "created_at"))
    .map((item) => ({
      ...item,
      event_date: getFormatedDateRange(
        item.required_date_start,
        item.required_date_end
      ),
    }));

  const eventsData = modifiedData.filter((item) => item.category === "Events");
  const congressData = modifiedData.filter(
    (item) => item.category === "Congress"
  );

  const handleRecordClick = (item) => {
    history.push(`/product_launch/${item.id}`);
  };

  return (
    <RFPRequestsWrapper pageHeading="Requested Events" defaultKey={EVENTS}>
      <Tab eventKey={EVENTS} title="Events">
        <SimpleTable
          headings={tableHeadings}
          data={eventsData}
          isLoading={isLoading}
          onRowLinkClick={handleRecordClick}
        />
      </Tab>
      <Tab eventKey={CONGRESS} title="Medical Congress & Exhibition">
        <SimpleTable
          headings={tableHeadings}
          data={congressData}
          isLoading={isLoading}
          onRowLinkClick={handleRecordClick}
        />
      </Tab>
    </RFPRequestsWrapper>
  );
};

export default Requested;
