import formatAmount from "../../../../../utils/formatAmount";
import groupArrayOfObjects from "../../../../../utils/groupArrayOfObjects";

export const groupBudgetItemsByType = (itemsData = [], currency = "AED") => {
  const formattedData = {};

  const result = groupArrayOfObjects(itemsData, "item");

  Object.entries(result).forEach(([serviceType, items]) => {
    const itemsTotal = items.reduce(
      (acc, val) => ({
        estimated_budget:
          acc.estimated_budget + (val.estimated_total_unit_cost || 0),
        reconciled_budget:
          acc.reconciled_budget + (val.reconciled_total_unit_cost || 0),
      }),
      {
        estimated_budget: 0,
        reconciled_budget: 0,
      }
    );
    formattedData[serviceType] = {
      data: items,
      formatted_estimated_budget: `${currency}${formatAmount(
        itemsTotal.estimated_budget
      )}`,
      formatted_reconciled_budget: `${currency}${formatAmount(
        itemsTotal.reconciled_budget
      )}`,
      formatted_savings: `${currency}${formatAmount(
        // Savings will be zero when reconciled budget is not entered yet
        itemsTotal.reconciled_budget > 0
          ? itemsTotal.estimated_budget - itemsTotal.reconciled_budget
          : 0
      )}`,
    };
  });

  return formattedData;
};
