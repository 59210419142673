/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TableCell, TableRow, Table, TableBody } from "@material-ui/core";
import { FieldArray, Formik, Form } from "formik";
import { useAlert } from "react-alert";
import propTypes from "prop-types";

import FormikSubmitBtn from "../../../shared/components/form/FormikSubmitBtn";
import {
  registrationFormInitValues,
  registrationFormInitObj,
  registrationFormValSchema,
} from "../utilities/formUtils";
import FormikModernField from "../../../shared/components/form/FormikModernField";
import FormikModernTextEditor from "../../../shared/components/form/FormikModrenTextEditor";
import {
  getCongressBasicDetailsQuery,
  updateCongressRegistrationForm,
} from "../../../services/congress-details";
import { getRegistrationFormValues } from "../utilities/helpers";
import { useCongressContext } from "../context/CongressContext";
import RegistrationTableHead from "./RegistrationTableHead";

function RegistrationForm({ congressId }) {
  const [initialValues, setInitValues] = useState(registrationFormInitValues);
  const { setCurrentTab, setPayload, isAddCongress, congressDetails } =
    useCongressContext();
  const alert = useAlert();

  useEffect(() => {
    (async () => {
      if (congressId && congressDetails) {
        const newValues = getRegistrationFormValues(congressDetails);

        setInitValues((prevState) => ({
          ...prevState,
          ...newValues,
        }));
      }
    })();
  }, [congressId, congressDetails]);

  return (
    <div className="congress-form-wrapper">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={registrationFormValSchema}
        onSubmit={async (values) => {
          if (!isAddCongress) {
            const updateDataResp = await updateCongressRegistrationForm(
              congressId,
              values
            );
            if (updateDataResp.status === 400) {
              alert.error("Error Updating Basic Info");
              return;
            }

            const getUpdateResp = await getCongressBasicDetailsQuery(
              congressId
            );
            const newValues = getRegistrationFormValues(getUpdateResp);

            setInitValues((prevState) => ({
              ...prevState,
              ...newValues,
            }));

            alert.success("Congress RegistrationFormUpdated Successfully");
            return;
          }

          setPayload((prevState) => ({ ...prevState, ...values }));
          setCurrentTab(2);
        }}
      >
        {({ values }) => (
          <Form className="d-flex align-items-start ">
            <Table>
              <RegistrationTableHead />

              <TableBody>
                <FieldArray name="conference_registration">
                  {({ remove, push }) => (
                    <>
                      {values.conference_registration?.length > 0 &&
                        values.conference_registration.map((_, index, arr) => (
                          <TableRow key={index}>
                            <TableCell>
                              <FormikModernField
                                name={`conference_registration[${index}].registration_heading`}
                              />
                            </TableCell>

                            <TableCell>
                              <FormikModernField
                                name={`conference_registration[${index}].registration_category`}
                              />
                            </TableCell>

                            <TableCell>
                              <FormikModernField
                                name={`conference_registration[${index}].registration_end_date`}
                                type="date"
                              />
                            </TableCell>

                            <TableCell>
                              <FormikModernField
                                name={`conference_registration[${index}].registration_fee`}
                                type="number"
                              />
                            </TableCell>

                            <TableCell>
                              <div className="d-flex align-items-center justify-content-center gap-1 ">
                                {arr.length > 1 && (
                                  <button
                                    type="button"
                                    className="btn-main-secondary sm"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </button>
                                )}

                                {arr.length - 1 === index && (
                                  <button
                                    type="button"
                                    className="btn-main-primary sm"
                                    onClick={() =>
                                      push(registrationFormInitObj)
                                    }
                                  >
                                    Add
                                  </button>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  )}
                </FieldArray>
              </TableBody>
            </Table>

            <div className="congress-content-label">Add more Information</div>

            <FormikModernTextEditor name="more_information" isRequired />

            <FormikSubmitBtn label={congressId ? "Next" : undefined} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

RegistrationForm.propTypes = {
  congressId: propTypes.string,
};

RegistrationForm.defaultProps = {
  congressId: null,
};

export default RegistrationForm;
