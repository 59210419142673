/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import { SYNCFUSION_DOCUMENT_SERVICES_URL } from './constants';

export const convertFileToSFDTFormat = async file => {
  const formData = new FormData();
  formData.append('file', file);

  // passing timestamp just to bypass cache
  const { data } = await axios.post(
    `${SYNCFUSION_DOCUMENT_SERVICES_URL}/Import`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
  return data;
};

export const removeSyncfusionLicenseAlert = () => {
  for (const child of document.body.children) {
    if (child.tagName !== 'SCRIPT' && !child.id && !child.className) {
      child.remove();
    }
  }
};
