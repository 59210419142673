import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";

import Map from "../../../components/map";
import { settings } from "../utilities/helpers";
import Card from "./Card";

function TravelInformation(congressDetailes) {
  const { congresDetails } = congressDetailes;

  const conferenceLocation = useMemo(() => {
    const { longitude, latitude } = congresDetails || {};

    if (longitude && latitude) {
      return { longitude, latitude };
    }

    return false;
  }, [congresDetails]);

  return (
    <div>
      <Row>
        <Col md={6} className="travelInfoCol">
          <img
            src="/assets/pages/testConference/mCongress/like.png"
            id="recommandedImg"
            alt=""
          />

          <p className="font-weight-bold text-lg-115 text-100 mb-0 mt-0">
            Recommended Hotels
          </p>
        </Col>

        {congresDetails?.address && (
          <Col md={6} className="travelInfoCol travelInfoCol2">
            <img
              src="/assets/pages/testConference/mCongress/pin.png"
              id="recommandedImg"
              alt=""
            />

            <p className="font-weight-bold text-lg-115 text-100 mb-0 mt-0">
              {congresDetails?.address}
            </p>
          </Col>
        )}
      </Row>

      <Row className="congress-details-cards-wrapper">
        {congresDetails?.hotels?.length <= 3 &&
          congresDetails?.hotels.map((items) => (
            <Card key={items?.hotel_id} items={items} />
          ))}
      </Row>

      <div className="recommended-hotels-slider mb-3">
        {congresDetails?.hotels?.length > 3 && (
          <Slider {...settings}>
            {congresDetails?.hotels.map((items) => (
              <Card
                className="slider-card"
                key={items?.hotel_id}
                items={items}
              />
            ))}
          </Slider>
        )}

        {congresDetails?.hotels?.length === 0 && (
          <div className="no-hotel-available-message">No hotel available.</div>
        )}
      </div>

      {conferenceLocation && (
        <Row className="mb-4">
          <h6 className="heading">Location</h6>

          <Map specificLocation={conferenceLocation} height="80vh" zoom={16} />
        </Row>
      )}
    </div>
  );
}

export default TravelInformation;
