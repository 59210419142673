import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Form } from "react-bootstrap";
import ImageUploader from "../../../utils/ReactImageUploader";
import {
  getPartnerLogo,
  updatePartnerLogo,
  uploadPartnerLogo,
} from "../../../server/Filter";

function MyProfile() {
  const { user } = useSelector((state) => state.auth);
  const alert = useAlert();
  const [isSubmiting, setSubmiting] = useState(false);
  const [image, setImage] = useState(false);
  const [userData, setUserData] = useState({});
  const [defaultImage, setDefaultImage] = useState(null);

  useEffect(() => {
    getPartnerLogo(user.id)
      .then((data) => {
        if (data?.length > 0) {
          setUserData(data[0]);
          setDefaultImage([{ image: data[0].image }]);
        }
      })
      .catch((err) => console.log(err));
  }, [user.id]);

  const handleChange = (file) => {
    setImage(file[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setSubmiting(true);
      if (userData.id) {
        await updatePartnerLogo(userData.id, image);
        alert.show("Logo Updated Successfully!", { type: "success" });
      } else {
        await uploadPartnerLogo(image);
        alert.show("Logo Uploaded Successfully!", { type: "success" });
      }
      setSubmiting(false);
    } catch (error) {
      setSubmiting(false);
      alert.show("Something went wrong, please try again later", {
        type: "error",
      });
      console.log(error);
    }
  };

  return (
    <div className="w-100 mb-5">
      <div className="mt-10 mb-5 d-flex">
        <p className="font-weight-bold text-lg-150 text-125 mb-2">My Profile</p>
      </div>
      <hr />
      <div className="w-50 mx-auto">
        <Form noValidate>
          <Form.Group controlId="formBasicText">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              defaultValue={user.profile.full_name}
              disabled
            />
          </Form.Group>
          <Form.Group controlId="formBasicText">
            <Form.Label>Username</Form.Label>
            <Form.Control
              name="username"
              type="text"
              defaultValue={user.username}
              disabled
            />
          </Form.Group>
          <Form.Group controlId="formBasicText">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              type="text"
              defaultValue={user.profile.email}
              disabled
            />
          </Form.Group>
        </Form>
        <form onSubmit={handleSubmit}>
          <ImageUploader
            name="image"
            label="Upload Company Logo"
            onChange={handleChange}
            defaultImages={defaultImage}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png"]}
            maxFileSize={10485760}
            fileSizeError={
              "file size is too big. It must be less than or equal to 10 MB."
            }
            buttonText="Choose Image"
            withPreview
            withLabel
            singleImage
            fileContainerStyle={{ backgroundColor: "#F2F4F5" }}
            buttonStyles={{ backgroundColor: "#071F4E" }}
          />
          <div className="text-center">
            <button
              type="submit"
              className="Button_continue mt-5 mb-12"
              disabled={isSubmiting}
            >
              {isSubmiting ? "Submiting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MyProfile;
