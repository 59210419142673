function getFormatedDateRange(rangeStartDate, rangeEndDate) {
  const startDateOptions = { month: "long", day: "2-digit", year: "numeric" };
  const endDateOptions = { month: "long", day: "2-digit", year: "numeric" };
  const startDate = new Date(rangeStartDate);
  const endDate = new Date(rangeEndDate);
  if (startDate.getFullYear() === endDate.getFullYear()) {
    delete startDateOptions.year;
  }
  const formatedStartDate = startDate.toLocaleDateString(
    "en-US",
    startDateOptions
  );
  let formatedEndDate = endDate.toLocaleDateString("en-US", endDateOptions);
  if (startDate.getMonth() === endDate.getMonth()) {
    formatedEndDate = `${endDate
      .getDate()
      .toString()
      .padStart(2, "0")}, ${endDate.getFullYear()}`;
  }

  return formatedStartDate + " - " + formatedEndDate;
}

export default getFormatedDateRange;
