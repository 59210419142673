import React from 'react';
import './index.scss';

const Spacing = () => {
    return (
        <div>
            
        </div>
    )
}

export default Spacing;
