// import React, { useState } from "react";
// import useDimensions from "react-cool-dimensions";
// import { Container, Row, Col, Image } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import Header from "../../components/header";
// import Footer from "../../components/footer";
// import { css } from "emotion";

// const Design = () => {
//   const designCards = [
//     {
//       id: 0,
//       source: "assets/pages/design/Capture1.PNG",
//       heading: "Stage Designs",
//       link: "/concept_designs/stage_designs",
//     },
//     {
//       id: 1,
//       source: "assets/pages/design/Capture2.PNG",
//       heading: "Hospitality Desk Designs",
//       link: "/concept_designs/hospitality_desk_designs",
//     },
//     {
//       id: 2,
//       source: "assets/pages/design/Capture3.PNG",
//       heading: "Venue Concepts",
//       link: "/concept_designs/venue_concept_designs",
//     },
//     {
//       id: 3,
//       source: "assets/pages/design/Capture4.PNG",
//       heading: "Photo Booth Ideas",
//       link: "#",
//     },
//   ];

//   const [heights, setHeights] = useState("");
//   const responsive = css`
//     height: ${heights};
//   `;

//   const { ref } = useDimensions({
//     onResize: ({ width }) => {
//       var screenHeight = (width * 9) / 16 + "px";
//       setHeights(screenHeight);
//     },
//   });

//   const renderDesignCard = (designCard, index) => {
//     return (
//       <Col sm={6} className="px-xl-6 px-lg-5 px-md-4 px-sm-3 px-0 mb-5" key={index}>
//         <div>
//           <Link to={designCard.link} className="text-decoration-none">
//             <Image
//               src={designCard.source}
//               className={"w-100 " + responsive}
//               ref={ref}
//             />
//             <p className="text-dark text-lg-150 text-125 text-center my-4">
//               {designCard.heading}
//             </p>
//           </Link>
//         </div>
//       </Col>
//     );
//   };
//   return (
//     <React.Fragment>
//       <Header />
//       <Container className="px-0" fluid>
//         <Row className="inner_width_div">
//           <Col xs={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
//             <div className="d-flex align-items-center justify-content-between mt-sm-7 mt-5">
//               <p className="font-weight-bold text-lg-125 text-100 mb-0">
//                 CONCEPT DESIGNS
//               </p>
//               <Link to="/concept_designs/my_designs" className="back_button">
//                 My Concept Designs List
//               </Link>
//             </div>
//             <Row className="mt-sm-7 mt-5">
//               {designCards.map(renderDesignCard)}
//             </Row>
//           </Col>
//         </Row>
//       </Container>
//       <Footer />
//     </React.Fragment>
//   );
// };

// export default Design;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.scss";

export default function (props) {
  const designCards = [
    {
      id: 0,
      source: "assets/pages/design/Capture1.PNG",
      heading: "Stage Designs",
      link: "/concept_designs/stage_designs",
    },
    {
      id: 1,
      source: "assets/pages/design/Capture2.PNG",
      heading: "Hospitality Desk Designs",
      link: "/concept_designs/hospitality_desk_designs",
    },
    {
      id: 2,
      source: "assets/pages/design/Capture3.PNG",
      heading: "Venue Concepts",
      link: "/concept_designs/venue_concept_designs",
    },
    {
      id: 3,
      source: "assets/pages/design/Capture4.PNG",
      heading: "Photo Booth Ideas",
      link: "/concept_designs/photo_booth_ideas",
    },
  ];
  return (
    <React.Fragment>
      <Header />
      <Container
        className="event_banner px-0 position-relative"
        style={{
          backgroundImage:
            'url("/assets/images/production/concept-design-banner.jpg")',

          backgroundColor: "rgb(37 50 190 / 50%)",
        }}
        fluid
      >
        <div
        // className="event_banner_image"
        // style={{ backgroundColor: "rgba(37, 50, 190, 0.4)" }}
        ></div>
        <div className="text-blocks">
          <p
            className="font-weight-bold  text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-250 mb-0"
            style={{ fontFamily: "'Poppins'" }}
          >
            Concept design
          </p>
        </div>
      </Container>
      <Row className="BackBtn">
        <Col xl={12} className="myback_button">
          <Link to="/production" className="back_button">
            <img
              src="/assets/images/icon-back.png"
              className="icon_back"
              alt=""
            />{" "}
            Back
          </Link>
        </Col>
      </Row>
      <Container className="CustomizeContainer">
        <Row className="plan-parentxxx">
          {designCards.map((data) => (
            <Col
              md={6}
              sm={12}
              className="mb-sm-7 mb-md-5 mb-lg-10 mt-sm-0 mt-md-5 conceptDesign_data"
            >
              <div className="App h-auto conceptDesign_slider">
                <Link to={data.link} className="text-decoration-none">
                  <div className="all_category">
                    <img src={data.source} alt="" />
                  </div>

                  <div className="conceptDesign_title">
                    <p className="pt-4 px-5">{data.heading}</p>
                  </div>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
