import React from "react";
import { useEventCoordinationContext } from "../../context/EventCoordinationContext";
import SectionWrapper from "./SectionWrapper";
import RFPRecord from "./RFPRecord";
import { RFP_DETAILS_STATE_KEY } from "../../utils/constants";

const EventManager = ({ role, name, email, contact, coverFullSection }) => (
  <SectionWrapper heading={role} coverFullSection={coverFullSection}>
    <RFPRecord label="Name:">{name}</RFPRecord>
    <RFPRecord label="Email Address:">{email}</RFPRecord>
    <RFPRecord label="Contact Number:">{contact}</RFPRecord>
  </SectionWrapper>
);

const Roles = () => {
  const {
    data: { [RFP_DETAILS_STATE_KEY]: rfpDetails },
  } = useEventCoordinationContext();

  const hasEventOwner = Boolean(rfpDetails.rfp_owner);
  const hasEventCoordinator = Boolean(rfpDetails.rfp_coordinator);

  return (
    <div className="d-flex justify-content-between mt-10">
      {rfpDetails.rfp_owner && (
        <EventManager
          role="Event Owner"
          name={rfpDetails.rfp_owner.name}
          email={rfpDetails.rfp_owner.email}
          contact={rfpDetails.rfp_owner.contact}
          coverFullSection={hasEventOwner && hasEventCoordinator}
        />
      )}
      {rfpDetails.rfp_coordinator && (
        <EventManager
          role="Event Coordinator"
          name={rfpDetails.rfp_coordinator.name}
          email={rfpDetails.rfp_coordinator.email}
          contact={rfpDetails.rfp_coordinator.contact}
          coverFullSection={hasEventOwner && hasEventCoordinator}
        />
      )}
    </div>
  );
};

export default Roles;
