/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import FileHandler, {
  withFileHandler,
  useFileHandlerContext,
  SPREADSHEET,
} from "../../../../components/common/file-handler";
import { useEventPlanningContext } from "../context/EventPlanningContext";
import { fetchRFPLogisticsDocumentBlob } from "./utils/helpers";
import { LOGISTICS_STATE_KEY } from "../utils/constants";
import "./styles/index.scss";

const Logistics = () => {
  const alert = useAlert();
  const { spreadSheetRef } = useFileHandlerContext();
  const {
    data: { [LOGISTICS_STATE_KEY]: logisticsFileURL },
  } = useEventPlanningContext();
  const [isActionsAllowed, setIsActionsAllowed] = useState(true);

  useEffect(() => {
    if (logisticsFileURL) {
      fetchRFPLogisticsDocumentBlob(logisticsFileURL)
        .then((fileBlob) => {
          const file = new File([fileBlob], "logistics.xlsx");
          if (spreadSheetRef.current) spreadSheetRef.current.open({ file });
        })
        .catch(() => {
          alert.error(
            "Something went wrong in opening file. Please try again!!!"
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logisticsFileURL]);

  const onSpreadSheetLoad = () => {
    if (spreadSheetRef.current) {
      spreadSheetRef.current.hideFileMenuItems(["File"], true);
      setIsActionsAllowed(false);
    }
  };

  return (
    <FileHandler
      id="logistics-spreadsheet"
      fileType={SPREADSHEET}
      disableActions={false}
      onLoad={onSpreadSheetLoad}
      allowEditing={isActionsAllowed}
      showFormulaBar={isActionsAllowed}
      allowFreezePane={isActionsAllowed}
      allowUndoRedo={isActionsAllowed}
      allowInsert={isActionsAllowed}
      allowSave={isActionsAllowed}
      enableClipboard={isActionsAllowed}
      allowFiltering={isActionsAllowed}
      height={600}
      readOnly
    />
  );
};

export default withFileHandler(Logistics);
