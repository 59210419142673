import React from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import SliderModal from "../sliderModal";
import {
  addToCongressCart,
  addToEventCart,
  removeFromCart,
} from "../../server/Filter";
import { increaseCartCount } from "../../redux/actions/cartActions";
import "./index.scss";

const ConceptDesign = ({ items, modelName }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  // const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);

  // const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  // const handleResize = () => {
  //   setwindowWidth(window.innerWidth);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // const [heights, setHeights] = useState("");
  // const responsive = css`
  //   height: ${heights};
  // `;

  // const { ref } = useDimensions({
  //   onResize: ({ width }) => {
  //     var screenHeightLaptop = (((width - 96) / 3) * 9) / 14 + "px";
  //     var screenHeightTablet = (((width - 64) / 2) * 9) / 14 + "px";
  //     var screenHeightMobile = (width * 9) / 14 + "px";
  //     if (windowWidth > 992) {
  //       setHeights(screenHeightLaptop);
  //     } else if (windowWidth > 576 && windowWidth <= 992) {
  //       setHeights(screenHeightTablet);
  //     } else {
  //       setHeights(screenHeightMobile);
  //     }
  //   },
  // });

  const { state } = useLocation();

  const handleEventCartIconClick = async (item) => {
    try {
      if (state && state.id) {
        await removeFromCart(state.modelName, state.modelID);
      }
      await addToEventCart(modelName, item.id);
      if (state && state.id) {
        history.replace({
          ...history.location,
          state: null,
        });
        history.push("/cart");
        alert.show(
          `${state.descriptionFields.title} replaced with ${item.code}`,
          {
            type: "success",
          }
        );
        history.push({
          ...history.location,
          state: null,
        });
      } else {
        dispatch(increaseCartCount());
        alert.show(`${item.code} successfully added to events cart`, {
          type: "success",
        });
      }
    } catch (err) {
      if (err.response) {
        alert.show(err.response.data, {
          type: "error",
        });
      }
    }
  };

  const handleCongressCartIconClick = async (item) => {
    try {
      if (state && state.id) {
        await removeFromCart(state.modelName, state.modelID);
      }
      await addToCongressCart(modelName, item.id);
      if (state && state.id) {
        history.replace({
          ...history.location,
          state: null,
        });
        history.push("/cart");
        alert.show(
          `${state.descriptionFields.title} replaced with ${item.code}`,
          {
            type: "success",
          }
        );
        history.push({
          ...history.location,
          state: null,
        });
      } else {
        dispatch(increaseCartCount());
        alert.show(`${item.code} successfully added to congress cart`, {
          type: "success",
        });
      }
    } catch (err) {
      if (err.response) {
        alert.show(err.response.data, {
          type: "error",
        });
      }
    }
  };

  if (items?.length < 1) {
    return (
      <Container style={{ minHeight: "200px" }}>
        <Row className="justify-content-center">
          <h5>No Items!</h5>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Container className={`CustomizeContainer ${modelName} min-height-300`}>
        <Row className="justify-content-center plan-parentxxx">
          <Row className="w-100">
            {items?.map((item, index) => (
              <>
                <Col
                  sm={6}
                  lg={4}
                  className="mb-7 conceptDesigns-activity-tile"
                >
                  <SliderModal
                    sliderImages={item.slider_images}
                    sliderClasses="conceptDesignsBuildingSlider"
                  />
                  {/* <Slider
                    {...settings}
                    className="conceptDesignsBuildingSlider"
                    >
                    {item.slider_images?.map((img) => (
                      <Image
                        key={img.image}
                        src={img.image}
                        className="w-100"
                        onDoubleClick={handleShow}
                      />
                    ))}
                  </Slider> */}
                  {modelName === "DeskDesign" ||
                  modelName === "StageDesign" ||
                  modelName === "ConceptPhotoBooth" ? (
                    <div
                      className="d-flex flex-column justify-content-center activity-tile-bottom-detail pl-5 py-3"
                      style={{ height: "50px" }}
                    >
                      <div>
                        <p className="font-weight-bold text-lg-120 text-100 mb-0">
                          {item.code}
                        </p>
                        {/* <p className="text-lg-120 text-100 mb-0" style={{ whiteSpace: "pre", width: "80%", textOverflow: "ellipsis", overflow: "hidden" }}>
                          {/* {parse(item.description.toString())} */}
                        {/* {item.description.toString().replace(/(<([^>]+)>)/gi, "")} */}
                        {/* </p> */}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-column justify-content-center activity-tile-bottom-detail pl-5 py-3">
                      <p className="font-weight-bold text-lg-120 text-100 mb-0">
                        {item.venue_name}
                      </p>
                      <p
                        className="text-lg-120 text-100 mb-0"
                        style={{
                          whiteSpace: "pre",
                          width: "80%",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {item.hotel_name}
                      </p>
                      {item.address && (
                        <p className="mb-0" style={{ fontSize: "0.9em" }}>
                          {item.address}
                        </p>
                      )}
                    </div>
                  )}

                  <div className="conceptDesignsSliderCartIcon">
                    <img src="/assets/images/exhibition/icon.png" alt="" />
                    <div className="hover_div">
                      <span
                        className="event_span cursor-pointer"
                        onClick={() => handleEventCartIconClick(item)}
                      >
                        Events
                      </span>
                      <span
                        className="cursor-pointer"
                        onClick={() => handleCongressCartIconClick(item)}
                      >
                        Congress
                      </span>
                    </div>
                  </div>
                </Col>
              </>
            ))}
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default ConceptDesign;
