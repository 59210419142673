import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row } from "react-bootstrap";
import Pagination from "react-js-pagination";

import BrandingCategory from "../branding_category";
import { getBrandingMaterials } from "../../../server/Filter";
import "./index.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BrandingCategories = () => {
  const query = useQuery();

  const [brandingMaterials, setBrandingMaterials] = useState(null);

  const category = query.get("category");

  useEffect(() => {
    getBrandingMaterials(category)
      .then((data) => setBrandingMaterials(data))
      .catch((err) => console.log(err));
  }, [category]);

  const brandingMaterialsArray = brandingMaterials?.map((material) => ({
    id: material.id,
    image: `${material.thumbnail}`,
    model: material.material_name,
    code: material.code,
    price: material.price,
    description: material.description,
    sliderImages: material.slider_images,
  }));

  const brandingMaterialsArrayPerPage = 8;
  const [activePage, setCurrentPage] = useState(1);
  const indexOfLastbrandingMaterial =
    activePage * brandingMaterialsArrayPerPage;
  const indexOfFirstbrandingMaterial =
    indexOfLastbrandingMaterial - brandingMaterialsArrayPerPage;
  const currentbrandingMaterialsArray = brandingMaterialsArray?.slice(
    indexOfFirstbrandingMaterial,
    indexOfLastbrandingMaterial
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <React.Fragment>
      <Row className="branding_category_row">
        {brandingMaterialsArray?.length > 0 &&
          currentbrandingMaterialsArray.map((brandingMaterial) => (
            <BrandingCategory
              key={brandingMaterial.code}
              brandingMaterial={brandingMaterial}
            />
          ))}
        {brandingMaterialsArray?.length < 1 && <h5 className="">No Item!</h5>}
      </Row>
      {brandingMaterialsArray?.length > 8 ? (
        <div className="paginations branding_paginations">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={8}
            totalItemsCount={brandingMaterialsArray?.length}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default BrandingCategories;
