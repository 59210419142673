import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ServeiceButtons from '../service_buttons';
import Text from '../text';

const Food = ({ setTab }) => {
    const Beverages = [
        { for: "attendees", text: "Number of Attendees:", type: "text", name: "attendees" },
        { for: "preffered_time", text: "Preffered Time:", type: "text", name: "preffered_time" },
        { for: "venue", text: "Preffered Restaurant/Vanue (if any):", type: "text", name: "venue" }
    ]
    const renderBeverage =  (Beverage, index) => {
        return(
            <React.Fragment key={index}>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor={Beverage.for}>{Beverage.text}</label>
                    </Col>
                    <Col className="px-0" md={6}>
                        <input name={Beverage.name} />
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Form>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor="sub_category">Sub-category:</label>
                    </Col>
                    <Col className="px-0" md={6}>
                        <select name="sub_category">
                            <option>Breakfast</option>
                            <option>Launch</option>
                            <option>Dinner</option>
                            <option>Gala Dinner</option>
                        </select>
                    </Col>
                    <Col md={3}></Col>
                </Row>
                {Beverages.map(renderBeverage)}
                <Text heading="Requirements:" />
                <ServeiceButtons setTab={setTab} />
            </Form>
        </React.Fragment>
    )
}

export default Food;
