export const isFolderKey = (key = "") => {
  return key.endsWith("/");
};

export const filterParentKeys = (folderKeys = []) => {
  let keys = [...folderKeys];

  keys.forEach((currentKey) => {
    if (keys.filter((i) => i.startsWith(currentKey)).length > 1) {
      keys = [currentKey, ...keys.filter((i) => !i.startsWith(currentKey))];
    }
  });

  return keys;
};

export const filterSelectedDocuments = (
  documents = [],
  folderKeys = [],
  fileKeys = []
) => {
  const parentFolderKeys = filterParentKeys(folderKeys);
  let selectedDocuments = [];

  // Matching the folder keys and selecting the documents
  parentFolderKeys.forEach((targetParentKey) => {
    const modifiedTargetDocuments = documents
      .filter((item) => item.key.startsWith(targetParentKey))
      .map((item) => {
        const keyParts = targetParentKey.split("/");
        const documentName = keyParts[keyParts.length - 2];
        const parentKey = targetParentKey.substring(
          0,
          targetParentKey.lastIndexOf(documentName)
        );

        return { ...item, key: item.key.replace(parentKey, "") };
      });
    selectedDocuments = selectedDocuments.concat(modifiedTargetDocuments);
  });

  // Matching files and selecting them
  fileKeys.forEach((targetFileKey) => {
    const targetFileDocument = documents.find(
      (item) => item.key === targetFileKey
    );
    const fileAlreadySelected = Boolean(
      selectedDocuments.find((item) => item.id === targetFileDocument.id)
    );
    if (!fileAlreadySelected) {
      selectedDocuments.push({
        ...targetFileDocument,
        // Slicing out file name only
        key: targetFileDocument.key.substring(
          targetFileDocument.key.lastIndexOf("/") + 1
        ),
      });
    }
  });

  return selectedDocuments;
};

export const extractErrorMessage = (
  error,
  defaultMessage = "Something went wrong"
) => {
  if (
    !error.response ||
    !error.response.data ||
    typeof error.response.data !== "object"
  )
    return defaultMessage;
  if ("key" in error.response.data) return error.response.data.key;
  if ("message" in error.response.data) return error.response.data.message;
  return defaultMessage;
};
