import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import PageWrapper from "../../../components/common/PageWrapper";

const ErrorAlert = ({ alertMessage = "Something went wrong" }) => {
  const history = useHistory();
  const { state } = useLocation();

  // State must be in below format
  // {
  //   returnData: { (Optional)
  //     url: 'https://example.com'
  //     state: {
  //       ... if have any state which needs to pass when going back
  //     }
  //   }
  // }

  const goBack = () => {
    if (state && state.returnData && state.returnData.url) {
      history.push(state.returnData.url, { ...state.returnData.state });
    } else {
      history.goBack();
    }
  };

  return (
    <PageWrapper bannerTitle="Documents" fluid>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="min-vh-50 d-flex align-items-center justify-content-center">
              <div className="d-flex flex-column align-items-center">
                <h5>{alertMessage}</h5>
                <span className="mt-3">
                  <Button color="primary" className="icon-btn" onClick={goBack}>
                    Go Back
                  </Button>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export default ErrorAlert;
