import React, { useMemo } from "react";
import { Link } from "react-router-dom";

function useServiceRequestsTable() {
  const tableHeadings = useMemo(
    () => [
      {
        id: "service_request_formatted_num",
        label: "Request No",
        Cell: ({ value, row }) => (
          <Link to={`/service_requests/${row.original.id}/`}>{value}</Link>
        ),
      },
      {
        id: "title",
        label: "Title",
      },
      {
        id: "description",
        label: "Description",
        maxWidth: 250,
        Cell: ({ value }) => <div className="text-truncate">{value}</div>,
      },
      {
        id: "status",
        label: "Status",
      },
    ],
    []
  );

  return { tableHeadings };
}

export default useServiceRequestsTable;
