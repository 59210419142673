import React, { useState } from "react";

const LargeText = ({ originalText = "", maxWords = 10 }) => {
  const wordsArray = originalText
    .trim()
    .split(" ")
    .filter((item) => !!item);
  const isLargeText = wordsArray.length > maxWords;
  const minifiedText = wordsArray.slice(0, 10).join(" ");
  const modifiedText = isLargeText ? minifiedText + "..." : originalText;

  const [isReadingMore, setIsReadingMore] = useState(false);

  const toggleReadingStatus = () => setIsReadingMore((prevState) => !prevState);

  return (
    <span>
      {isReadingMore ? originalText : modifiedText}
      {isLargeText && (
        <span className="large-text-link" onClick={toggleReadingStatus}>
          {isReadingMore ? "View Less" : "Read More"}
        </span>
      )}
    </span>
  );
};

export default LargeText;
