import axios from "axios";

export const fetchRFPLogisticsDocumentBlob = async (fileURL) => {
  // passing timestamp just to bypass cache
  const { data } = await axios.get(
    `${fileURL}?timestamp=${new Date().getTime()}`,
    {
      responseType: "blob",
    }
  );
  return data;
};
