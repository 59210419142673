import { useMemo } from "react";

function useSimpleReactTable(headings = [], data = []) {
  const memoizedColumns = useMemo(
    () => [
      ...headings.map(({ label, title, Header, id, key, ...rest }) => ({
        ...rest,
        Header: label || title || Header || "",
        accessor: id || key || "",
      })),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const memoizedData = useMemo(() => [...data], [data]);

  return {
    columns: memoizedColumns,
    data: memoizedData,
  };
}

export default useSimpleReactTable;
