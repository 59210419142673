import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageWrapper from "../../components/common/PageWrapper";
import EventCoordination from "./event_coordination";
import EventPlanning from "./event_planning";
import EventCloseOut from "./event_closeout";
import ServiceRequests from "./service_request";
import {
  COORDINATION,
  PLANNING,
  CLOSEOUT,
  SERVICE_REQUESTS,
} from "./utils/constants";
import { clearProductLaunchData } from "../../redux/actions/productLaunchActions";
import "./styles/index.scss";

const BANNER_IMG_SRC = 'url("/assets/page-3-banner.jpg")';

const ProductLaunch = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state = {}, pathname } = useLocation();
  const { isRequested, event_name: eventName } = useSelector(
    (state) => state.productLaunch.fetchedRFPDetails
  );
  const [activeTabKey, setActiveTabKey] = useState(COORDINATION);

  useEffect(() => {
    return () => {
      dispatch(clearProductLaunchData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.mainTab) {
      setActiveTabKey(state.mainTab);
    }
  }, [state]);

  const handleSwitchTab = (selectedTabKey) => {
    history.push({ pathname, state: { ...state, mainTab: selectedTabKey } });
    setActiveTabKey(selectedTabKey);
  };

  return (
    <PageWrapper bannerImgSrc={BANNER_IMG_SRC} bannerTitle={eventName} fluid>
      <div className="product-launch-main-wrapper">
        <Tabs
          id="product-launch-tabs"
          activeKey={activeTabKey}
          onSelect={handleSwitchTab}
          className="product-launch-tabs"
        >
          <Tab eventKey={COORDINATION} title="Event Coordination">
            <EventCoordination />
          </Tab>
          <Tab
            eventKey={PLANNING}
            title="Event Planning"
            disabled={isRequested}
          >
            <EventPlanning />
          </Tab>
          <Tab
            eventKey={CLOSEOUT}
            title="Event Closeout"
            disabled={isRequested}
          >
            <EventCloseOut />
          </Tab>
          <Tab eventKey={SERVICE_REQUESTS} title="Service Requests">
            <ServiceRequests />
          </Tab>
        </Tabs>
      </div>
    </PageWrapper>
  );
};

export default ProductLaunch;
