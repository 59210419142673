import React, { useState, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import SimpleReactTreeTable from "../../../../shared/components/table/SimpleReactTreeTable";
import LoadingButton from "../../../../components/common/LoadingButton";
import useCustomReasonModal from "../../../../components/common/custom-hooks/useCustomReasonModal";
import { useEventCoordinationContext } from "../context/EventCoordinationContext";
import useQuotations from "./custom-hooks/useQuotations";
import RFPActionController from "../../common/RFPActionController";
import { modifyRFPRequestStatus } from "../../../../server/Filter";
import {
  QUOTATIONS_STATE_KEY,
  CANCELLED as RFP_CANCELLED,
} from "../utils/constants";

const Quotations = () => {
  const alert = useAlert();
  const history = useHistory();
  const reason = useCustomReasonModal();
  const { rfpID } = useParams();
  const { tableHeadings } = useQuotations();
  const {
    data: { [QUOTATIONS_STATE_KEY]: rfpQuotations },
  } = useEventCoordinationContext();
  const [isCancellingEvent, setIsCancellingEvent] = useState(false);

  const handleCancelEvent = useCallback(
    async () => {
      try {
        const providedReason = await reason();
        try {
          setIsCancellingEvent(true);
          await modifyRFPRequestStatus(rfpID, {
            status: RFP_CANCELLED,
            request_status: RFP_CANCELLED,
            rfp_cancel_reason: providedReason,
          });
          alert.success("Event Cancelled Successfully");
          history.push("/cancelled");
        } catch (error) {}
      } finally {
        setIsCancellingEvent(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <div className="mb-6">
        <RFPActionController>
          <LoadingButton
            className="transparent-button rfp-action-button"
            onClick={handleCancelEvent}
            isLoading={isCancellingEvent}
          >
            {isCancellingEvent ? "Cancelling..." : "Cancel Event"}
          </LoadingButton>
        </RFPActionController>
      </div>
      <SimpleReactTreeTable
        headings={tableHeadings}
        data={rfpQuotations}
        rounded
      />
    </div>
  );
};

export default Quotations;
