import React from "react";
import UserForm from "./UserForm";
import { useManageAccountContext } from "../context/ManageAccountContext";

const EditUser = () => {
  const { selectedUser } = useManageAccountContext();

  return <UserForm selected={selectedUser} />;
};

export default EditUser;
