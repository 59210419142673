import React from "react";
import { useFormikContext } from "formik";
import FormikChoices from "../../../../../../shared/components/form/FormikChoices";
import { useEventCoordinationContext } from "../../../context/EventCoordinationContext";
import RFPFormRecord from "../RFPFormRecord";
import { RFP_DETAILS_STATE_KEY } from "../../../utils/constants";

const ServiceQuestionController = ({ questionText, flag, children }) => {
  const { values } = useFormikContext();
  const {
    [RFP_DETAILS_STATE_KEY]: { isEditing },
  } = useEventCoordinationContext();

  return (
    <React.Fragment>
      <RFPFormRecord
        label={questionText}
        labelClassName="w-40"
        valueClassName="w-55"
      >
        <FormikChoices
          name={flag}
          className="justify-content-start"
          disabled={!isEditing}
        />
      </RFPFormRecord>
      {values[flag] && (
        <RFPFormRecord labelClassName="w-0" valueClassName="w-60">
          {children}
        </RFPFormRecord>
      )}
    </React.Fragment>
  );
};

export default ServiceQuestionController;
