import React, { useMemo } from "react";
import { useTable, useExpanded } from "react-table";
import { Table } from "react-bootstrap";
import TablePagination from "@material-ui/core/TablePagination";
import useTablePagination, {
  DEFAULT_PAGINATION_OPTIONS,
} from "./custom-hooks/useTablePagination";
import useSimpleReactTreeTable from "./custom-hooks/useSimpleReactTreeTable";
import MiniLoader from "../../../components/common/MiniLoader";

const ROW_HEIGHT = 43;

const SimpleReactTreeTable = ({
  headings,
  data: unMemoizedData = [],
  isLoading = false,
  noRecordsText = "No records found",
  showPagination = true,
  paginationOptions = DEFAULT_PAGINATION_OPTIONS,
  getRowProps = () => ({}),
  rounded = false,
}) => {
  const paginationProps = useTablePagination(paginationOptions);
  const { columns, data } = useSimpleReactTreeTable(headings, unMemoizedData);

  const emptyRows = useMemo(
    () => paginationProps.rowsPerPage - data.length,
    [paginationProps.rowsPerPage, data]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        getSubRows: (row) => row.children,
      },
      useExpanded // Use the useExpanded plugin hook
    );

  return (
    <React.Fragment>
      <Table
        className={`simple-table ${rounded ? "rounded" : ""}`}
        bordered
        {...getTableProps()}
        style={{
          marginBottom: showPagination ? 0 : "inherit",
          border: showPagination ? "inherit" : "unset",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr className="header-row" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading ? (
            <tr className="loader-wrapper">
              <td className="text-center" colSpan={headings.length}>
                <MiniLoader />
              </td>
            </tr>
          ) : (
            <React.Fragment>
              {rows.length > 0 ? (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} {...getRowProps(row)}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                                maxWidth: cell.column.maxWidth,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center" colSpan={headings.length + 1}>
                    {noRecordsText}
                  </td>
                </tr>
              )}
              {emptyRows > 0 && (
                <tr
                  className="empty-row"
                  style={{ height: ROW_HEIGHT * emptyRows }}
                >
                  <td className="border-0" colSpan={headings.length + 1} />
                </tr>
              )}
            </React.Fragment>
          )}
        </tbody>
      </Table>
      {showPagination && (
        <TablePagination
          {...paginationProps}
          count={paginationProps.count || data.length}
          component="div"
          backIconButtonProps={{ "aria-label": "Previous Page" }}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          dir="ltr"
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
        />
      )}
    </React.Fragment>
  );
};

export default SimpleReactTreeTable;
