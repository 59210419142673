/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { useRFPFormContext } from "../context/RFPContext";
import FormikField from "../../../shared/components/form/FormikField";
import FormikSelect from "../../../shared/components/form/FormikSelect";
import FormikDateRangePicker from "../../../shared/components/form/FormikDateRangePicker";
import RFPFieldWrapper from "./RFPFieldWrapper";
import { CONGRESS, categoryOptions } from "../utils/constants";

const EventDetailsForm = ({ isTypeEvents }) => {
  const { setFieldValue } = useFormikContext();
  const {
    cart: { type: cartType, congress },
  } = useRFPFormContext();
  const { username, profile } = useSelector((state) => state.auth.user) || {};

  useEffect(() => {
    setFieldValue("account_name", username || "");
    setFieldValue("account_email", profile?.email || "");
    setFieldValue("crm_sales_account_id", profile?.crm_sales_account_id || "");
    setFieldValue(
      "crm_sales_customer_id",
      profile?.crm_sales_customer_id || ""
    );
  }, [profile, setFieldValue, username]);

  useEffect(() => {
    setFieldValue("category", cartType || "");
  }, [cartType, setFieldValue]);

  useEffect(() => {
    console.log("congress", congress?.conference_list);

    if (congress?.conference_list?.length > 0 && cartType === CONGRESS) {
      const { address, title, start_date, end_date } =
        congress?.conference_list[0]?.descriptionFields;

      setFieldValue("event_name", title);
      setFieldValue("congress_location", address);
      setFieldValue("required_date_start", start_date);
      setFieldValue("required_date_end", end_date);
    }
  }, [congress, cartType]);

  return (
    <div className="w-100">
      <RFPFieldWrapper label="Username">
        <FormikField name="account_name" disabled />
      </RFPFieldWrapper>

      <RFPFieldWrapper label="Email Address">
        <FormikField name="account_email" disabled />
      </RFPFieldWrapper>

      <RFPFieldWrapper label="Category">
        <FormikSelect
          name="category"
          options={categoryOptions}
          placeholder="Select Category"
        />
      </RFPFieldWrapper>

      <RFPFieldWrapper label="Event Name">
        <FormikField name="event_name" placeholder="Event Name" />
      </RFPFieldWrapper>

      {!isTypeEvents && (
        <RFPFieldWrapper label="Venue">
          <FormikField name="congress_location" placeholder="Venue" />
        </RFPFieldWrapper>
      )}

      <RFPFieldWrapper label="Number of Attendees">
        <FormikField
          type="number"
          name="no_of_attendee"
          placeholder="No of Attendees"
        />
      </RFPFieldWrapper>

      <RFPFieldWrapper label="Required Date">
        <FormikDateRangePicker
          name={["required_date_start", "required_date_end"]}
          placeholder="YYYY/MM/DD"
        />
      </RFPFieldWrapper>

      {isTypeEvents && (
        <RFPFieldWrapper label="Alternative Date">
          <FormikDateRangePicker
            name={["alternative_date_start", "alternative_date_end"]}
            placeholder="YYYY/MM/DD"
          />
        </RFPFieldWrapper>
      )}
    </div>
  );
};

export default EventDetailsForm;
