import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Datepick from '../requestquote/form/datepick';

const MainRFP = () => {
    const main_RFPs = [
        { for: "account_name", text: "ACCOUNT NAME:", type: "text", name: "account_name", placeholder: "PZ001" },
        { for: "email_address", text: "EMAIL ADDRESS:", type: "email", name: "email_address", placeholder: "OPERATIONS3@LUXURYEXPLORERSME.COM" },
        { for: "event_name", text: "Event Name:", type: "text", name: "event_name" },
        { for: "event_date", text: "EVENT DATE:", type: "date", name: "event_date" },
        { for: "alternate_date", text: "ALTERNATE DATE:", type: "date", name: "alternate_date" },
        { for: "city", text: "CITY:", type: "text", name: "city" },
        { for: "country", text: "COUNTRY:", type: "text", name: "country" },
        { for: "attendees", text: "NUMBER OF ATTENDEES:", type: "text", name: "attendees" },
    ]
    const rendermain_RFP =  (main_RFP, index) => {
        return (
          <div key={index}>
            { index < 3 || index > 4 ? 
              <Row className="form_main">
                <Col className="px-0" xl={4}md={5}>
                  <label htmlFor={main_RFP.for}>{main_RFP.text}</label>
                </Col>
                <Col xl={6} md={5} className="px-0">
                  <input
                    type={main_RFP.type}
                    name={main_RFP.name}
                    placeholder={main_RFP.placeholder}
                    value={main_RFP.value}
                    disabled = {( main_RFP.placeholder == null ? null : 'disabled' )}
                  />
                </Col> 
                <Col md={2}></Col>
              </Row>  
              : 
              <Row className="form_main">
                <Col className="px-0" xl={4} sm={5} xs={6}>
                  <div className="d-flex">
                    <label htmlFor={main_RFP.for}>{main_RFP.text}</label>
                    <img
                      style = {{ marginRight: '5px' }}
                      className="calendar ml-auto"
                      src={"assets/images/download.jpg"}
                      alt="Not Found"
                    />
                  </div>
                </Col>
                <Col xl={6} md={5} sm={7} xs={6} className="px-0">
                  <Datepick />
                </Col>
                <Col md={2}></Col>
              </Row> 
            }
          </div>
        );
    }
    return (
        <React.Fragment>
          {main_RFPs.map(rendermain_RFP)}
          <div className="float-right w-100 plan_event_div">
            <button className="Button gen_inquiry_func_buttons">Cancel RFP</button>
            <button className="Button gen_inquiry_func_buttons">Save</button>
            <button className="Button gen_inquiry_func_buttons">Edit</button>
          </div>
        </React.Fragment>
    )
}

export default MainRFP;
