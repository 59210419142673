import React from "react";
import FormikField from "../../../shared/components/form/FormikField";
import ServiceQuestionController from "./ServiceQuestionController";
import RFPFieldWrapper from "./RFPFieldWrapper";

const OtherRequirements = () => {
  return (
    <div className="w-100">
      <ServiceQuestionController
        questionText="Do you have other requirements?"
        flag="other_requirement_flag"
        stepNo={11}
      >
        <RFPFieldWrapper label="Requirements">
          <FormikField name="other_requirements" rows={5} textArea />
        </RFPFieldWrapper>
      </ServiceQuestionController>
    </div>
  );
};

export default OtherRequirements;
