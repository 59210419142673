import React from "react";
import { useEventCloseOutContext } from "../context/EventCloseOutContext";
import SimpleReactTreeTable from "../../../../shared/components/table/SimpleReactTreeTable";
import useProformaInvoices from "./custom-hooks/useProformaInvoices";
import { PROFORMA_INVOICES_STATE_KEY } from "../utils/constants";

const ProformaInvoices = () => {
  const {
    data: { [PROFORMA_INVOICES_STATE_KEY]: rfpProInvoices },
  } = useEventCloseOutContext();
  const { tableHeadings } = useProformaInvoices();

  return (
    <div>
      <SimpleReactTreeTable
        headings={tableHeadings}
        data={rfpProInvoices}
        rounded
      />
    </div>
  );
};

export default ProformaInvoices;
