import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const Datepick = ({ onChangeDate }) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <React.Fragment>
      <DatePicker
        className="datepick"
        selected={startDate}
        onChange={(date) =>{ setStartDate(date); onChangeDate(date);}}
      />
    </React.Fragment>
  );
};

export default Datepick;
