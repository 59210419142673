import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import Header from "../../components/header";
import Footer from "../../components/footer";
import EventMainForm from "./event_main_form";
import ServicesForms from "./service_types_forms";

const EventForm = () => {
  const [serviceForms, setServiceForms] = useState([]);
  const [disabledServiceTypes, setDisabledServiceTypes] = useState({
    flightDisabled: false,
    accomodateDisabled: false,
    meetingRoomDisabled: false,
    programDisabled: false,
    brandingDisabled: false,
    giftsDisabled: false,
    transportationDisabled: false,
    foodDisabled: false,
    engagementDisabled: false,
  });

  const addServiceForms = () => {
    const forms = [...serviceForms];
    forms.push({ id: uuidv4(), Forms: ServicesForms });
    setServiceForms(forms);
  };

  const deleteServiceForm = (id) => {
    // const forms = [...serviceForms];
    // forms.pop();
    const filteredForms = serviceForms.filter((service) => service.id !== id);
    setServiceForms(filteredForms);
  };

  const { register, handleSubmit, control, errors /*, reset */ } = useForm({
    mode: "onTouched",
    reValidateMode: "onBlur",
    defaultValues: {
      accomodate: {
        hotels: [{ hotel_name: "" }],
      },
      branding: {
        items: [{ code: "", quantiy: "", remarks: "" }],
      },
      gift: {
        items: [{ code: "", quantiy: "", remarks: "" }],
      },
      transportation: [
        {
          sub_category: "",
          type_of_car: "",
          type_of_service: "",
          no_of_cars: "",
          remarks: "",
        },
      ],
      food_and_bevarages: [
        {
          sub_category: "",
          date: "",
          no_of_attendees: "",
          preferred_time: "",
          preferred_restaurant: "",
          remarks: "",
        },
      ],
      engagement: [
        {
          sub_category: "",
          concept_design_code: "",
          remarks: "",
        },
      ],
    },
  });

  const [status, setStatus] = useState("");

  const onSubmit = async (data) => console.log(status, data);

  return (
    <React.Fragment>
      <Header />
      <Container fluid className="px-0">
        <Row className="inner_width_div">
          <Col xl={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <EventMainForm
                addServiceForms={addServiceForms}
                register={register}
                control={control}
                errors={errors}
              />
              {serviceForms.map((Service) => (
                <Service.Forms
                  key={Service.id}
                  addServiceForms={addServiceForms}
                  deleteServiceForm={() => deleteServiceForm(Service.id)}
                  disabledServiceTypes={disabledServiceTypes}
                  setDisabledServiceTypes={setDisabledServiceTypes}
                  setStatus={setStatus}
                  register={register}
                  control={control}
                  errors={errors}
                />
              ))}
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default EventForm;
