import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.scss";

const Engagemant = () => {
  const engagemant_sub_pages = [
    {
      source: "/assets/images/engagement/team-building-main.jpg",
      class: "engagemant_card1",
      text1: "Team Building",
      text2: "Activity",
      link: "/team/activities",
    },
    {
      source: "/assets/images/engagement/hybrid-meeting-main.jpg",
      class: "engagemant_card2",
      text1: "Hybrid",
      text2: "Meeting",
      link: "/hybrid_meetings",
    },
  ];

  const renderengagemant_sub_page = (engagemant_sub_page, index) => {
    return (
      <Col
        md={6}
        className={"engagemant_congress " + engagemant_sub_page.class}
        key={index}
      >
        <Card style={{ width: "30rem", border: "none" }}>
          <Link to={engagemant_sub_page.link}>
            <Card.Img variant="top" src={engagemant_sub_page.source} />
          </Link>
          <Card.Body className="text-style123">
            <Card.Title className="engagemant-title1">
              <span className="font-weight-bold  engagemant-details1 mr-2 text2">
                {engagemant_sub_page.text1}
              </span>
              <span className=" font-weight-bold  engagemant-details cart-border1 text2">
                {engagemant_sub_page.text2}
              </span>
            </Card.Title>

            <Link to={engagemant_sub_page.link}>
              <button className="explore_button123 text-white engagemant-details">
                Explore More
              </button>
            </Link>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <React.Fragment>
      <Header />
      <Container
        className="event_banner px-0 position-relative"
        style={{
          backgroundImage:
            'url("/assets/pages/engagement/engagement-banner.jpg")',
          backgroundColor: "rgb(37 50 190 / 50%)",
        }}
        fluid
      >
        <div className="text-blocks">
          <p
            className="font-weight-bold  text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-200 mb-0"
            style={{ fontFamily: "'Poppins'" }}
          >
            Engagement
          </p>
        </div>
      </Container>
      <Container className="CustomizeContainer">
        <Row className="align-items-center text-center text2">
          {engagemant_sub_pages.map(renderengagemant_sub_page)}
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Engagemant;
