import React, { useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { Table } from "react-bootstrap";
import MiniLoader from "../../../components/common/MiniLoader";
import LargeText from "../../../components/common/LargeText";
import { DocumentViewerPopup } from "../../../components/common/document-viewer";
import getFormatedTime from "../../../utils/getFormatedTime";
import formatAmount from "../../../utils/formatAmount";
import getSorting from "../../../utils/getSorting";
import "./styles/simple-table.scss";

const SimpleTable = ({
  headings,
  data = [],
  isLoading = false,
  onRowClick,
  onRowLinkClick,
  alternateText = "No record found",
  rounded = false,
  sortingOrder = "desc",
  sortByField = "created_at",
  renderCustomRow,
  groupedExtraHeadings = [],
  customRows,
  formatGroupedHeading,
}) => {
  const [selected, setSelected] = useState(null);
  const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] = useState(false);

  const toggleFilePreviewModal = () =>
    setIsFilePreviewModalOpen((prevState) => !prevState);

  const handleViewFile = (data) => {
    setSelected(data);
    toggleFilePreviewModal();
  };

  const formatField = (headingItem, data) => {
    if (!data) return "";
    if (headingItem.isAmountField)
      return `${headingItem.currency || "AED"}${formatAmount(data)}`;
    if (headingItem.isPercentField) return `${data}%`;
    if (headingItem.isDateField) {
      return moment(data).format(headingItem.customFormat || "DD MMM YYYY");
    }
    if (headingItem.isTimeField) {
      return getFormatedTime(data, headingItem.customFormat);
    }
    if (headingItem.isFileLink) {
      return (
        <span
          onClick={() =>
            handleViewFile({
              fileURL: data,
              fileName: "Attachment",
            })
          }
        >
          {headingItem.fileLinkText || "View Document"}
        </span>
      );
    }
    if (headingItem.hasLargeText) {
      return <LargeText originalText={data} maxWords={headingItem.maxWords} />;
    }
    return data;
  };

  const renderTableRecord = (row, recordIndex) => (
    <tr
      className={`${onRowClick ? "cursor-pointer" : ""}`}
      onClick={onRowClick ? () => onRowClick(row) : undefined}
      key={`simple-table-row-${recordIndex}`}
    >
      {headings.map((headingItem) => (
        <td
          key={`simple-table-data-${recordIndex}-${headingItem.id}`}
          align={headingItem.align || "center"}
          width={headingItem.width}
        >
          <span
            className={`${
              headingItem.isLink || headingItem.isFileLink
                ? "table-data-link-item"
                : ""
            } ${headingItem.hasLargeText ? "large-text-wrapper" : ""}`}
            onClick={
              headingItem.isLink && onRowLinkClick
                ? () => onRowLinkClick(row)
                : undefined
            }
          >
            {formatField(headingItem, row[headingItem.id])}
          </span>
        </td>
      ))}
    </tr>
  );

  const renderNoRecordsRow = () => (
    <tr>
      <td className="text-center" colSpan={headings.length}>
        {alternateText}
      </td>
    </tr>
  );

  return (
    <React.Fragment>
      <DocumentViewerPopup
        isOpen={isFilePreviewModalOpen}
        toggle={setIsFilePreviewModalOpen}
        fileURL={Boolean(selected) && selected.fileURL}
        fileName={Boolean(selected) && selected.fileName}
      />
      <Table className={`simple-table ${rounded ? "rounded" : ""}`} bordered>
        <thead>
          <tr className="header-row">
            {headings.map((item) => (
              <th
                key={item.id}
                style={{
                  textAlign: item.align || "center",
                  minWidth: item.minWidth,
                }}
              >
                {item.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr className="loader-wrapper">
              <td className="text-center" colSpan={headings.length}>
                <MiniLoader />
              </td>
            </tr>
          ) : (
            <React.Fragment>
              {!isEmpty(data) ? (
                <React.Fragment>
                  {/* For Array Data */}
                  {Array.isArray(data) && data.length > 0 ? (
                    <React.Fragment>
                      {[...data]
                        .sort(getSorting(sortingOrder, sortByField))
                        .map(renderCustomRow || renderTableRecord)}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {/* For Grouped Data */}
                      {Object.entries(data).map(
                        ([groupedHeading, groupedData]) => (
                          <React.Fragment>
                            {/* Header Row */}
                            <tr className="grouped-heading-row">
                              <td
                                align="left"
                                colSpan={
                                  headings.length - groupedExtraHeadings.length
                                }
                              >
                                {formatGroupedHeading
                                  ? formatGroupedHeading(groupedHeading)
                                  : groupedHeading}
                              </td>
                              {/* Extra Headings */}
                              {groupedExtraHeadings.map((headerItem) => (
                                <td
                                  className="font-weight-bold"
                                  align={headerItem.align || "center"}
                                  key={headerItem.key}
                                >
                                  {formatField(
                                    headerItem,
                                    groupedData[headerItem.id]
                                  )}
                                </td>
                              ))}
                            </tr>
                            {groupedData.data &&
                            Array.isArray(groupedData.data) &&
                            groupedData.data.length > 0
                              ? groupedData.data.map(
                                  renderCustomRow || renderTableRecord
                                )
                              : renderNoRecordsRow()}
                          </React.Fragment>
                        )
                      )}
                    </React.Fragment>
                  )}
                  {/* Render Custom Rows such as Total Row */}
                  {customRows}
                </React.Fragment>
              ) : (
                <React.Fragment>{renderNoRecordsRow()}</React.Fragment>
              )}
            </React.Fragment>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default SimpleTable;
