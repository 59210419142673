import React from "react";
import { FieldArray } from "formik";
import { Button, Row, Col } from "react-bootstrap";
import { MdRemoveCircle } from "react-icons/md";
import FormikField from "../../../shared/components/form/FormikField";
import FormikDatePicker from "../../../shared/components/form/FormikDatePicker";
import ServiceQuestionController from "./ServiceQuestionController";
import RFPFieldWrapper from "./RFPFieldWrapper";
import { singleMeetingInitialValues } from "../utils/initialValues";

const MeetingRooms = () => {
  return (
    <div className="w-100">
      <ServiceQuestionController
        questionText="Do you require Meeting Rooms?"
        flag="meeting_rooms_flag"
        stepNo={6}
      >
        <FieldArray
          name="rfpmeetingroom"
          render={({ form, push, remove }) => {
            return (
              <div className="w-100 mb-6">
                {form.values.rfpmeetingroom.map((_, index) => (
                  <React.Fragment key={`meeting-room-${index}`}>
                    <Row className="px-0 justify-content-between mb-6">
                      <Col className="d-flex align-items-center pl-0" sm={6}>
                        <label>Meeting Room {index + 1}:</label>
                      </Col>
                      {form.values.rfpmeetingroom.length > 1 && (
                        <Col
                          sm={1}
                          className="d-flex justify-content-end align-items-center pr-0"
                        >
                          <MdRemoveCircle
                            size={20}
                            className="remove-icon"
                            onClick={() => remove(index)}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row className="px-0 justify-content-between mb-6">
                      <Col md={5} className="d-flex align-items-center pl-0">
                        <label className="pr-5">Date</label>
                        <FormikDatePicker
                          name={`rfpmeetingroom.${index}.meeting_date`}
                          placeholder="YYYY/MM/DD"
                        />
                      </Col>
                      <Col md={5} className="d-flex align-items-center pr-0">
                        <label className="pr-5">No. of Participants</label>
                        <FormikField
                          type="number"
                          name={`rfpmeetingroom.${index}.number_of_participants`}
                          placeholder="12"
                        />
                      </Col>
                    </Row>
                    <Row className="px-0 justify-content-between mb-6">
                      <Col md={5} className="d-flex align-items-center pl-0">
                        <label className="pr-5">Start Time</label>
                        <FormikField
                          type="time"
                          name={`rfpmeetingroom.${index}.meeting_time_start`}
                        />
                      </Col>
                      <Col md={5} className="d-flex align-items-center pr-0">
                        <label className="pr-5">End Time</label>
                        <FormikField
                          type="time"
                          name={`rfpmeetingroom.${index}.meeting_time_end`}
                        />
                      </Col>
                    </Row>
                    <RFPFieldWrapper label="Meeting Requirements/Setup">
                      <FormikField
                        name={`rfpmeetingroom.${index}.remarks`}
                        textArea
                        rows={4}
                      />
                    </RFPFieldWrapper>
                  </React.Fragment>
                ))}
                <Button
                  className="button small-button"
                  onClick={() => push(singleMeetingInitialValues)}
                >
                  + Add Meeting Room
                </Button>
              </div>
            );
          }}
        />
      </ServiceQuestionController>
    </div>
  );
};

export default MeetingRooms;
