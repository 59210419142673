/* eslint-disable no-unused-vars */

import React, { useState, useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Col } from "react-bootstrap";
import { css } from "emotion";
import { useAlert } from "react-alert";
import { useLocation, useHistory } from "react-router-dom";
import SliderModal from "../../../components/sliderModal";
import {
  addToCongressCart,
  addToEventCart,
  removeFromCart,
} from "../../../server/Filter";
import { increaseCartCount } from "../../../redux/actions/cartActions";

export default function ({ brandingPackage }) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();

  const testDimensions = () => {
    // For some reason targetRef.current.getBoundingClientRect was not available
    // I found this worked for me, but unfortunately I can't find the
    // documentation to explain this experience
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  };

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({});
  const equal = css`
    height: ${(dimensions.width * 9) / 16 + "px"};
  `;
  // holds the timer for setTimeout and clearInterval
  let movementTimer = null;
  // the number of ms the window size must stay the same size before the
  // dimension state variable is reset
  const RESET_TIMEOUT = 100;
  // const testDimensions = () => {
  //   // For some reason targetRef.current.getBoundingClientRect was not available
  //   // I found this worked for me, but unfortunately I can't find the
  //   // documentation to explain this experience
  //   if (targetRef.current) {
  //     setDimensions({
  //       width: targetRef.current.offsetWidth,
  //       height: targetRef.current.offsetHeight
  //     });
  //   }
  // }
  // This sets the dimensions on the first render
  useLayoutEffect(() => {
    testDimensions();
  }, []);
  // every time the window is resized, the timer is cleared and set again
  // the net effect is the component will only reset after the window size
  // is at rest for the duration set in RESET_TIMEOUT.  This prevents rapid
  // redrawing of the component for more complex components such as charts
  // useEffect(() => {
  window.addEventListener("resize", () => {
    clearInterval(movementTimer);
    movementTimer = setTimeout(testDimensions, RESET_TIMEOUT);
  });
  // }, []);

  const { state } = useLocation();

  const handleEventCartIconClick = async () => {
    try {
      if (state && state.id) {
        await removeFromCart(state.modelName, state.modelID);
      }
      await addToEventCart("BrandingPackage", brandingPackage.id);
      if (state && state.id) {
        history.replace({
          ...history.location,
          state: null,
        });
        history.push('/cart');
        alert.show(
          `${state.descriptionFields.title} replaced with ${brandingPackage.name}`,
          {
            type: "success",
          }
        );
        history.push({
          ...history.location,
          state: null,
        });
      } else {
        dispatch(increaseCartCount());
        alert.show(
          `${brandingPackage.name} successfully added to events cart`,
          {
            type: "success",
          }
        );
      }
    } catch (err) {
      if (err.response) {
        alert.show(err.response.data, {
          type: "error",
        });
      }
    }
  };

  const handleCongressCartIconClick = async () => {
    try {
      if (state && state.id) {
        await removeFromCart(state.modelName, state.modelID);
      }
      await addToCongressCart("BrandingPackage", brandingPackage.id);
      if (state && state.id) {
        history.replace({
          ...history.location,
          state: null,
        });
        history.push('/cart');
        alert.show(
          `${state.descriptionFields.title} replaced with ${brandingPackage.name}`,
          {
            type: "success",
          }
        );
        history.push({
          ...history.location,
          state: null,
        });
      } else {
        dispatch(increaseCartCount());
        alert.show(
          `${brandingPackage.name} successfully added to congress cart`,
          {
            type: "success",
          }
        );
      }
    } catch (err) {
      if (err.response) {
        alert.show(err.response.data, {
          type: "error",
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Col
        md={6}
        sm={12}
        key={brandingPackage.id}
        className="mb-sm-7 mb-md-5 mb-lg-10 mt-sm-0 branding_data"
      >
        <div className="App h-auto branding_material_slider">
          <div className="cart-div">
            <img src="/assets/images/exhibition/icon.png" alt="" />
            <div className="hover_div">
              <span
                className="event_span cursor-pointer"
                onClick={handleEventCartIconClick}
              >
                Events
              </span>
              <span
                className="cursor-pointer"
                onClick={handleCongressCartIconClick}
              >
                Congress
              </span>
            </div>
          </div>
          {/* <SliderModal show={show} setShow={setShow} /> */}
          <div className="slider-wrapper">
            <SliderModal
              sliderImages={brandingPackage.sliderImages}
              sliderClasses="conceptDesignsBuildingSlider"
            />
            {/* <Slider {...settings}>
              {brandingPackage.sliderImages &&
                brandingPackage.sliderImages.map((slide) => (
                  <div className="slick-slide" key={slide.image}>
                    <Image
                      className={
                        "slick-slide-image branding_material_slider " + equal
                      }
                      src={`${API_URL}${slide.image}`}
                      ref={targetRef}
                      onDoubleClick={handleShow}
                      fluid
                    />
                  </div>
                ))}
            </Slider> */}
          </div>
          <div className="branding_title">
            <p>{brandingPackage.name}</p>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
}
