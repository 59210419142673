import React from 'react';
import { Row, Col, } from 'react-bootstrap';

const SelectBar = ({ tab, setTab }) => {
    const selections = [
        { text: "", value: "" },
        { text: "Flight Ticket", value: "Flight" },
        { text: "Accomodation", value: "Accomodate" },
        { text: "Meeting Room", value: "MeetingRoom" },
        { text: "Program", value: "Program" },
        { text: "Branding", value: "Branding" },
        { text: "Transportation", value: "Transportation" },
        { text: "Food and Beverages", value: "Food" },
        { text: "Others", value: "Others" },
    ]
    return (
        <React.Fragment>
                <Row 
                    className="form_main gen_inquiry_selectbar" 
                    style={{ clear: 'both' }}
                >
                    <Col className="px-0" md={3}>
                        <label htmlFor="service_type">SERVICE TYPE:</label>
                    </Col>
                    <Col className="px-0" md={6}>
                        <select
                            value={tab}
                            onChange={e => setTab(e.target.value)}>
                                {selections.map((o, index) => (
                                    <option 
                                        key={index} 
                                        value={o.value}
                                        hidden = {( o.value === "" ? 'hidden' : "" )}
                                    >{o.text}
                                    </option>
                                ))}
                        </select>
                    </Col>
                    <Col md={3}></Col>
                </Row>
        </React.Fragment>
    )
}

export default SelectBar;
