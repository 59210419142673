const convertURLToFile = async (url) => {
  try {
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], `${fileName}`, {
      type: blob.type,
    });
    file.src = url;
    return file;
  } catch (error) {
    console.log(error);
    const file = new File([""], "Dummy.jpg", { type: "image/jpg" });
    file.src = url;
    return file;
  }
};

export default convertURLToFile;
