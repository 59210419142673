import qs from "querystring";

const changeQueryParameters = (query, queryKey, queryValue) => {
  const params = {};
  query.forEach((value, key) => {
    params[key] = value;
  });
  const queryParams = { ...params, [queryKey]: queryValue };
  const queryString = qs.stringify(queryParams);
  return queryString;
};

export default changeQueryParameters;
