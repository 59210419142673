import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import ConceptDesign from "../../components/conceptDesign";
import FilterSelector from "./filter_selector";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { getVenueConceptDesigns } from "../../server/Filter";
import changeQueryParameters from "../../utils/changeQueryParameters";
import "./index.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const venueConceptDesignsPerPage = 9;

export default function VenueConceptDesign() {
  const history = useHistory();
  const query = useQuery();

  const [venueConceptDesigns, setVenueConceptDesigns] = useState({});
  const [activePage, setCurrentPage] = useState(1);

  const page = query.get("page");
  const city = query.get("city");

  useEffect(() => {
    const pageNo = page ? parseInt(page) : 1;
    setCurrentPage(pageNo);
    getVenueConceptDesigns(pageNo, city)
      .then((data) => setVenueConceptDesigns(data))
      .catch((err) => console.log(err));
  }, [page, city]);

  const { state } = useLocation();

  const handleCityChange = (selectedCity) => {
    if (selectedCity && selectedCity !== city) {
      history.push({
        pathname: "/concept_designs/venue_concept_designs",
        search: `?city=${selectedCity}`,
        state,
      });
    } else {
      history.push({
        pathname: "/concept_designs/venue_concept_designs",
        state: state,
      });
    }
  };

  const handlePageChange = (pageNumber) => {
    const queryParameters = changeQueryParameters(query, "page", pageNumber);
    setCurrentPage(pageNumber);
    if (pageNumber !== page) {
      history.push({
        pathname: "/concept_designs/venue_concept_designs",
        search: queryParameters,
        state,
      });
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Container
        className="event_banner px-0 position-relative venue_concept_container"
        style={{
          backgroundImage: 'url("/assets/pages/team/team.png")',
        }}
        fluid
      >
        <div className="text-blocks">
          <p className="font-weight-bold text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-250 mb-0 font-poppins">
            Venue Concept Designs
          </p>
        </div>
      </Container>
      <Row className="BackBtn">
        <Col xs={6} className="myback_button">
          <Link to="/concept_designs" className="back_button">
            <img
              src="/assets/images/icon-back.png"
              className="icon_back"
              alt=""
            />{" "}
            Back
          </Link>
        </Col>
        <div className="d-flex justify-content-end w-100 p-5 pb-0 mt-5 filterSetting">
          <FilterSelector tab={city} setTab={handleCityChange} />
        </div>
      </Row>
      <div className="venueConceptDesginCss">
        <ConceptDesign
          items={venueConceptDesigns.results}
          modelName="VenueDesign"
        />
      </div>
      {venueConceptDesigns?.count > venueConceptDesignsPerPage && (
        <Container>
          <Row className="text-center pagination123">
            <div className="paginations">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={venueConceptDesignsPerPage}
                totalItemsCount={venueConceptDesigns.count}
                pageRangeDisplayed={3}
                onChange={handlePageChange}
              />
            </div>
          </Row>
        </Container>
      )}
      <Footer />
    </React.Fragment>
  );
}
