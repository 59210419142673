import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { Row, Col, Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import DestinationSlider from "./destinationslider";
import { getDestinationByName, addToEventCart } from "../../server/Filter";
import { increaseCartCount } from "../../redux/actions/cartActions";

const DestinationDetails = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { destinationName } = useParams();
  const [destination, setDestination] = useState(null);
  const [destinationCity, setDestinationCity] = useState("");

  useEffect(() => {
    getDestinationByName(destinationName)
      .then((data) => {
        setDestinationCity(data.dest_city);
        setDestination(data);
      })
      .catch((err) => console.log(err));
  }, [destinationName]);

  const handleCartIconClick = async () => {
    try {
      await addToEventCart("Destination", destination.id);
      dispatch(increaseCartCount());
      alert.show(`${destinationName} successfully added to cart`, {
        type: "success",
      });
    } catch (err) {
      if (err.response) {
        alert.show(err.response.data, {
          type: "error",
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div style={{ minHeight: "90vh", overflowX: "hidden" }}>
        <Container
          className="event_banner px-0 position-relative"
          style={{
            backgroundImage:
              'url("/assets/images/events/destination-banner.jpg")',
          }}
          fluid
        >
          <div
            className="event_banner_image"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          ></div>
          <div className="text-blocks">
            <p className="font-weight-bold text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-250 mb-0 font-poppins">
              Destinations
            </p>
          </div>
        </Container>
        <Row className="BackBtn">
          <Col xl={12} className="myback_button">
            <Link to="/destinations" className="back_button">
              <img
                src="/assets/images/icon-back.png"
                className="icon_back"
                alt=""
              />{" "}
              Back
            </Link>
          </Col>
        </Row>
        <div className="w-100">
          <Row className="inner_width_div">
            <Col xs={12}>
              <div
                className="cart-div cursor-pointer"
                onClick={handleCartIconClick}
              >
                <img src="/assets/images/exhibition/icon.png" alt="" />
              </div>
              <Link to={`/venue_sourcing/venues?city=${destinationCity}`}>
                <div
                  className="find-more-venues"
                  style={{ position: "absolute", right: "-4%", top: "90px" }}
                >
                  <p
                    style={{
                      padding: "10px 29px 10px 20px",
                      backgroundColor: "#5AB3E8",
                      color: "#fff",
                      borderRadius: "5px",
                    }}
                  >
                    Find Venues
                  </p>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
        {destination && (
          <DestinationSlider
            destination={destination}
            className="plan-parentxxx"
          />
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default DestinationDetails;
