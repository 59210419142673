import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ServeiceButtons from '../service_buttons';
import Text from '../text';

const Flight = ({ setTab }) => {
    return (
        <React.Fragment>
            <Form>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor="airline">Preffered Airline (if any):</label>
                    </Col>
                    <Col className="px-0" md={6}>
                        <input name="airline" />
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Text heading="Requirements:" />
                <ServeiceButtons setTab={setTab} />
            </Form>
        </React.Fragment>
    )
}

export default Flight;
