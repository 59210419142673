import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import EventPlanningProvider from "./context/EventPlanningContext";
import ProductLaunchSubTabs from "../../../components/common/rfp/ProductLaunchSubTabs";
import Budget from "./budget";
import Document from "./document";
import Logistics from "./logistics";
import { BUDGET, DOCUMENT, LOGISTICS } from "./utils/constants";

const tabsContent = [
  {
    key: BUDGET,
    title: "Budget",
    content: <Budget />,
  },
  {
    key: DOCUMENT,
    title: "Document",
    content: <Document />,
  },
  {
    key: LOGISTICS,
    title: "Logistics",
    content: <Logistics />,
  },
];

const EventPlanning = () => {
  const { state = {} } = useLocation();

  const selectedTabKey = useMemo(() => {
    const selectedMainTab = tabsContent.find(
      (item) => item.key === state.subTab
    );
    if (selectedMainTab) return selectedMainTab.key;
    return BUDGET;
  }, [state]);

  return (
    <EventPlanningProvider>
      <ProductLaunchSubTabs
        defaultKey={selectedTabKey}
        tabsContent={tabsContent}
      />
    </EventPlanningProvider>
  );
};

export default EventPlanning;
