import React from "react";
import { Field, useFormikContext } from "formik";

const FormikChoices = ({ name, onChange, className, ...rest }) => {
  const { setFieldValue } = useFormikContext();

  const choices = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  return (
    <div className={`radio-choice-wrapper ${className}`}>
      <Field name={name}>
        {({
          field: {
            onChange: onFieldValueChange,
            value: fieldValue,
            ...restFieldProps
          },
        }) => {
          return (
            <React.Fragment>
              {choices.map((item, index) => {
                const isLastItem = index === choices.length - 1;

                return (
                  <React.Fragment key={item.label}>
                    <label
                      className={`radio-choice ${isLastItem ? "mr-0" : ""}`}
                    >
                      <input
                        type="radio"
                        id={item.label}
                        {...restFieldProps}
                        {...rest}
                        checked={fieldValue === item.value}
                        onChange={(event) => {
                          setFieldValue(name, item.value);
                          if (onChange) onChange(item.value);
                        }}
                      />
                      <span className="pl-3">{item.label}</span>
                    </label>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        }}
      </Field>
    </div>
  );
};

export default FormikChoices;
