import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import propTypes from "prop-types";
import { formatDecimalValue } from "../../../../utils/helpers";

function RoomSizeInfoIcons({ width, length, height, size }) {
  const dimensions = useMemo(() => {
    if (width && length) {
      return `${width} x ${length} sq. m`;
    }

    return "NA";
  }, [width, length]);

  return (
    <Row noGutters className="size-icons-wrapper">
      <Col className="info-icon">
        <img src="/assets/images/room-size-icon.svg" alt="room-size-icon" />

        <div>
          <p className="title">Size</p>

          <p className="value">{`${formatDecimalValue(size)} sq. m`}</p>
        </div>
      </Col>

      <Col className="info-icon">
        <img
          src="/assets/images/ceiling-height-icon.svg"
          alt="room-height-icon"
        />

        <div>
          <p className="title">Ceiling Height</p>

          <p className="value">
            {!!parseInt(height) ? `${formatDecimalValue(height)} m` : "NA"}
          </p>
        </div>
      </Col>

      <Col className="info-icon">
        <img
          src="/assets/images/room-dimensions-icon.svg"
          alt="room-dimension-icon"
        />

        <div>
          <p className="title">Dimensions</p>

          <p className="value">{dimensions}</p>
        </div>
      </Col>
    </Row>
  );
}

RoomSizeInfoIcons.propTypes = {
  width: propTypes.string,
  length: propTypes.string,
  height: propTypes.string,
  size: propTypes.string,
};

RoomSizeInfoIcons.defaultProps = {
  width: "",
  length: "",
  height: "",
  size: "",
};

export default RoomSizeInfoIcons;
