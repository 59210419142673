/* eslint-disable no-console */
import React, { useState, useMemo } from "react";
import moment from "moment";
import useToggle from "../../../../../components/common/custom-hooks/useToggle";
import getSorting from "../../../../../utils/getSorting";

function useInvoiceActionHandler(data) {
  const { isOpen: isPreviewModalOpen, toggle: togglePreview } = useToggle();
  const [selectedProInvoice, setSelectedProInvoice] = useState(null);

  const togglePreviewModal = (selected) => {
    setSelectedProInvoice(selected);
    togglePreview();
  };

  const modifiedData = useMemo(
    () =>
      data.sort(getSorting("desc", "id")).map((item) => ({
        ...item,
        invoice_time: moment(item.updated_time, "HH:mm:ss").format("hh:mm A"),
        invoice: (
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span
                className="text-primary cursor-pointer"
                onClick={() => togglePreviewModal(item)}
              >
                {item.invoice_formatted_number}
              </span>
            </div>
          </div>
        ),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, selectedProInvoice]
  );

  return {
    modifiedData,
    selectedProInvoice,
    isPreviewModalOpen,
    togglePreviewModal,
  };
}

export default useInvoiceActionHandler;
