import React, { useState } from "react";
import MedServiceType from "../med_service_type";
import MedRegistration from "../med_registration";
import MedFlight from "../med_flight";
import MedAccomodate from "../med_accomodate";
import MedTransportation from "../med_transportation";
import MedFood from "../med_food";
import MedOthers from "../med_others";

export default function ({
  addServiceForms,
  deleteServiceForm,
  disabledServiceTypes,
  setDisabledServiceTypes,
  setStatus,
  register,
  control,
  errors,
}) {
  const [tab, setTab] = useState("MedMainForm");
  return (
    <div>
      <MedServiceType
        setTab={setTab}
        tab={tab}
        disabledServiceTypes={disabledServiceTypes}
      />
      {tab === "Flight" ? (
        <div>
          <MedFlight
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "Accomodate" ? (
        <div>
          <MedAccomodate
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "Registration" ? (
        <div>
          <MedRegistration
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "Transportation" ? (
        <div>
          <MedTransportation
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            errors={errors}
          />
        </div>
      ) : tab === "Food" ? (
        <div>
          <MedFood
            addServiceForms={addServiceForms}
            deleteServiceForm={deleteServiceForm}
            setDisabledServiceTypes={setDisabledServiceTypes}
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : tab === "Others" ? (
        <div>
          <MedOthers
            setStatus={setStatus}
            register={register}
            control={control}
            errors={errors}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
