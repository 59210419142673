import React from "react";
import { Row, Col } from "react-bootstrap";
import MedServeiceButtons from "../med_service_buttons";
import Input from "../../../components/customInputField";

const MedTransportation = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
    register,
    errors,
  } = props;

  const disableTransportationType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      transportationDisabled: true,
    }));
  };

  const enableTransportationType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      transportationDisabled: false,
    }));
  };

  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="sub_category">Sub-category:</label>
        </Col>
        <Col className="form_column_sel pl-0" md={6}>
          <select name="transportation.sub_category" ref={register}>
            <option>Airport Transfer</option>
            <option>Dinner Transfer</option>
            <option>Other Transportation</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="px-0">
          <div className="gen_inquiry_text">
            <p className="text-lg-150 text-125 mb-2">
              Requirements
            </p>
          </div>
          <Input
            textArea
            name="transportation.requirements"
            ref={register}
            error={
              errors?.transportation?.requirements && "This field is required!"
            }
          />
        </Col>
      </Row>
      <MedServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableTransportationType}
        enableSelectedType={enableTransportationType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default MedTransportation;
