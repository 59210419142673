import React from "react";
import { FaFile } from "react-icons/fa";
import useToggle from "../../common/custom-hooks/useToggle";
import { DocumentViewerPopup } from "../../common/document-viewer";

const Attachment = ({ url, name }) => {
  const {
    isOpen: isAttachmentPreviewerOpen,
    toggle: toggleAttachmentPreviewer,
  } = useToggle();

  return (
    <React.Fragment>
      <DocumentViewerPopup
        isOpen={isAttachmentPreviewerOpen}
        toggle={toggleAttachmentPreviewer}
        fileURL={url}
        fileName={name}
      />
      <div className="attachment" onClick={toggleAttachmentPreviewer}>
        <div className="content">
          <FaFile size={24} />
          <small>{name}</small>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Attachment;
