import { createContext, useContext } from "react";

export const PropertiesTableContext = createContext({
  data: null,
  setData: () => {},
  fetchHandler: async () => {},
  statusHandler: async () => {},
  invalidateHandler: async () => {},
  deleteHandler: async () => {},
});

export const useGetPropertiesTableContext = () => {
  const context = useContext(PropertiesTableContext);

  return context;
};
