import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import SearchBar from '../selectbar';

const MainForm = ({ setTab }) => {
    const generalinquirys = [
        { for: "account_name", text: "ACCOUNT NAME:", type: "text", name: "account_name", placeholder: "PZ001", state: "disabled" },
        { for: "email_address", text: "EMAIL ADDRESS:", type: "email", name: "email_address", placeholder: "OPERATIONS3@LUXURYEXPLORERSME.COM", state: "disabled" },
        { for: "city", text: "CITY:", type: "text", name: "city" },
        { for: "country", text: "COUNTRY:", type: "text", name: "country" },
        { for: "preferred_date", text: "PREFERRED DATE:", type: "text", name: "preferred_date" },
        { for: "alternate_date", text: "ALTERNATE DATE:", type: "text", name: "alternate_date" },
        { for: "budget", text: "BUDGET PER PERSON:", type: "text", name: "budget" },
        { for: "currency", text: "PREFERRED CURRENCY FOR THE QUOTE:", type: "text", name: "currency" },
    ]
    const rendergeneralinquiry =  (generalinquiry, index) => {
        return (
          <Row className="form_main" key={index}>
            <Col className="px-0" xl={4} md={5}>
              <label htmlFor={generalinquiry.for}>{generalinquiry.text}</label>
            </Col>
            <Col xl={6} md={5} className="px-0">
              <input
                type={generalinquiry.type}
                name={generalinquiry.name}
                placeholder={generalinquiry.placeholder}
                value={generalinquiry.value}
                // onChange={(e) =>
                //   props.setInput(generalform.name, e.target.value)
                // }
                disabled = {( generalinquiry.placeholder == null ? null : 'disabled' )}
              />
            </Col>
            <Col md={2}></Col>
          </Row>
        );
    }
    const [showSearchBar, setShowSearchBar] = useState("")
    const onClicks = (e) => {
        e.preventDefault()
        setShowSearchBar("SearchBar")
    }
    return (
        <React.Fragment>
            <Form className="mt-12"> 
              {generalinquirys.map(rendergeneralinquiry)}
              <div className="float-right mb-4">
                <Row>
                  <Col xs={12} className="px-0">
                    <div className="w-100">
                      <button className="Button" onClick={onClicks}>Save</button>
                    </div>
                  </Col>
                </Row>
              </div>
              { showSearchBar === "SearchBar" ? <SearchBar setTab={setTab} /> : null }
            </Form>
        </React.Fragment>
    )
}

export default MainForm;
