import React, { useCallback } from "react";
import RFPServiceRequestsMainProvider, {
  RFPServiceRequestMainContext,
} from "./context/MainContext";
import useToggle from "../../../components/common/custom-hooks/useToggle";
import SimpleReactTable from "../../../shared/components/table/SimpleReactTable";
import Modal from "../../../components/common/CustomModal";
import useServiceRequestsTable from "./custom-hooks/useServiceRequestsTable";
import ServiceRequestForm from "./components/RFPServiceRequestForm";

const RFPServiceRequests = () => {
  const { isOpen: isFormModalOpen, toggle: toggleFormModal } = useToggle();

  const { tableHeadings } = useServiceRequestsTable();

  const getServiceRequestRowProps = useCallback(
    ({ original: record }) => ({
      className:
        record.unseen_user_messages_count > 0 ? "font-weight-bold" : undefined,
    }),
    []
  );

  return (
    <RFPServiceRequestsMainProvider>
      <RFPServiceRequestMainContext.Consumer>
        {({ isLoading, serviceRequests }) => (
          <React.Fragment>
            <Modal
              isOpen={isFormModalOpen}
              toggle={toggleFormModal}
              heading="Service Request Form"
              bodyClassName="p-5"
              size="lg"
            >
              <ServiceRequestForm toggle={toggleFormModal} />
            </Modal>
            <div className="d-flex justify-content-end">
              <button className="primary-button mb-6" onClick={toggleFormModal}>
                Raise a Request
              </button>
            </div>
            <SimpleReactTable
              headings={tableHeadings}
              data={serviceRequests}
              isLoading={isLoading}
              getRowProps={getServiceRequestRowProps}
              rounded
            />
          </React.Fragment>
        )}
      </RFPServiceRequestMainContext.Consumer>
    </RFPServiceRequestsMainProvider>
  );
};
export default RFPServiceRequests;
