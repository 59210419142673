const dummyImages = [
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/6d5c19ac91.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/6530fd4c8b.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/a3c68f67b5.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/6530fd4c8b.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/6d5c19ac91.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/6530fd4c8b.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/b52c39a0c6.jpeg",
];
export const getConferenceImage = (image) => {
  if (image) return image;

  const randomIndex = Math.floor(Math.random() * dummyImages.length);

  return dummyImages[randomIndex];
};
