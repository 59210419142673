export const tableHeadings = [
  {
    id: "action",
    label: "Action",
    align: "left",
  },
  {
    id: "action_made_by",
    label: "Action Made by",
  },
  {
    id: "action_date",
    label: "Date",
    isDateField: true,
  },
  {
    id: "action_time",
    label: "Time",
    isTimeField: true,
  },
];
