/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";

// COMPONENTS & STYLES
import PageWrapper from "../../components/common/PageWrapper";
import {
  deleteMultipleVenues,
  getNonVerifiedVenues,
  getVerifiedVenues,
  unverifyMultipleVenues,
  verifyMultipleVenues,
} from "../../services/venue-management";
import "./index.scss";
import { PropertiesTableContext } from "../../components/propertiesTable/context/PropertiesTableContext";
import PropertiesTable from "../../components/propertiesTable";
import Filters from "./components/Filters";
import useQuery from "../../custom-hooks/useQuery";
import { getSearchParamObj } from "../../utils/helpers";

function VenuesManagement() {
  const searchParams = useQuery();
  const [verifiedData, setVerifiedData] = useState(null);
  const [unverifiedData, setUnverifiedData] = useState(null);

  const searchParamsObj = useMemo(
    () => getSearchParamObj(searchParams),
    [searchParams]
  );

  useEffect(() => {
    (async () => {
      const [verifiedResp, unverifiedResp] = await Promise.all([
        getVerifiedVenues(searchParamsObj),
        getNonVerifiedVenues(searchParamsObj),
      ]);

      setVerifiedData(verifiedResp);
      setUnverifiedData(unverifiedResp);
    })();
  }, [searchParams]);

  const handleGetVerfiedData = useCallback(
    async (...args) => {
      const resp = await getVerifiedVenues(...args);

      setVerifiedData(resp);
    },
    [verifiedData]
  );

  const handleGetUnverfiedData = useCallback(
    async (...args) => {
      const resp = await getNonVerifiedVenues(...args);

      setUnverifiedData(resp);
    },
    [unverifiedData]
  );

  const verifyTableContextValue = useMemo(
    () => ({
      data: verifiedData,
      setData: setVerifiedData,
      fetchHandler: handleGetVerfiedData,
      statusHandler: unverifyMultipleVenues,
      invalidateHandler: handleGetUnverfiedData,
    }),
    [verifiedData]
  );

  const unverifyTableContextValue = useMemo(
    () => ({
      data: unverifiedData,
      setData: setUnverifiedData,
      fetchHandler: handleGetUnverfiedData,
      statusHandler: verifyMultipleVenues,
      invalidateHandler: handleGetVerfiedData,
      deleteHandler: deleteMultipleVenues,
    }),
    [unverifiedData]
  );

  return (
    <PageWrapper
      bannerTitle="Venues Management"
      bannerImgSrc="url(/assets/images/events/venue-sourcing-banner.jpg)"
      endBtnLink="/venues-management/create"
      endBtnLabel="Add New"
    >
      <Filters />

      <div className="properties-table-container">
        <PropertiesTableContext.Provider value={unverifyTableContextValue}>
          <PropertiesTable title="Non Verified" />
        </PropertiesTableContext.Provider>

        <PropertiesTableContext.Provider value={verifyTableContextValue}>
          <PropertiesTable title="Verified" isVerified />
        </PropertiesTableContext.Provider>
      </div>
    </PageWrapper>
  );
}

export default VenuesManagement;
