import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import "./styles/paroduct-launch-sub-tabs.scss";

const ProductLaunchSubTabs = ({ defaultKey, tabsContent = [] }) => {
  const history = useHistory();
  const { state = {}, pathname } = useLocation();
  const [activeTabKey, setActiveTabKey] = useState(defaultKey);

  const handleSwitchTab = (selectedTabKey) => {
    history.push({ pathname, state: { ...state, subTab: selectedTabKey } });
    setActiveTabKey(selectedTabKey);
  };

  return (
    <Tabs
      id="product-launch-sub-tabs"
      activeKey={activeTabKey}
      onSelect={handleSwitchTab}
      className="product-launch-sub-tabs"
    >
      {tabsContent.map((item) => (
        <Tab key={item.key} eventKey={item.key} title={item.title}>
          {item.content}
        </Tab>
      ))}
    </Tabs>
  );
};

export default ProductLaunchSubTabs;
