import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import ConceptDesign from "../../components/conceptDesign";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { getStageDesigns } from "../../server/Filter";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const stageDesignsPerPage = 9;

export default function StageDesign() {
  const history = useHistory();
  const query = useQuery();

  const [stageDesigns, setStageDesigns] = useState({});
  const [activePage, setCurrentPage] = useState(1);

  const page = query.get("page");

  useEffect(() => {
    const pageNo = page ? parseInt(page) : 1;
    setCurrentPage(pageNo);
    getStageDesigns(pageNo)
      .then((data) => setStageDesigns(data))
      .catch((err) => console.log(err));
  }, [page]);

  const { state } = useLocation();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (pageNumber !== page) {
      history.push({
        pathname: "/concept_designs/stage_designs",
        search: `?page=${pageNumber}`,
        state,
      });
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Container
        className="event_banner px-0 position-relative"
        style={{
          backgroundImage: 'url("/assets/pages/team/team.png")',
        }}
        fluid
      >
        <div className="text-blocks">
          <p className="font-weight-bold text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-250 mb-0 font-poppins">
            Stage Designs
          </p>
        </div>
      </Container>
      <Row className="BackBtn">
        <Col xl={12} className="myback_button">
          <Link to="/concept_designs" className="back_button">
            <img
              src="/assets/images/icon-back.png"
              className="icon_back"
              alt=""
            />{" "}
            Back
          </Link>
        </Col>
      </Row>
      <ConceptDesign items={stageDesigns.results} modelName="StageDesign" />
      {stageDesigns?.count > stageDesignsPerPage && (
        <Container>
          <Row className="text-center pagination123">
            <div className="paginations">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={stageDesignsPerPage}
                totalItemsCount={stageDesigns.count}
                pageRangeDisplayed={3}
                onChange={handlePageChange}
              />
            </div>
          </Row>
        </Container>
      )}
      <Footer />
    </React.Fragment>
  );
}
