import React from "react";
import moment from "moment";
import { Table, Button } from "react-bootstrap";
import { useFormikContext, FieldArray } from "formik";
import { MdRemoveCircle } from "react-icons/md";
import { useEventCoordinationContext } from "../../../context/EventCoordinationContext";
import FormikField from "../../../../../../shared/components/form/FormikField";
import FormikSelect from "../../../../../../shared/components/form/FormikSelect";
import FormikDatePicker from "../../../../../../shared/components/form/FormikDatePicker";
import FormikCheckboxes from "../../../../../../shared/components/form/FormikCheckboxes";
import ServiceQuestionController from "./ServiceQuestionController";
import RFPFormRecord from "../RFPFormRecord";
import TableData from "./TableData";
import getFormatedDate from "../../../../../../utils/getFormatedDate";
import { RFP_DETAILS_STATE_KEY } from "../../../utils/constants";
import {
  hotelRatingOptions,
  occupancyOptions,
  mealPlanOptions,
} from "../../utils/constants";
import { singleAccomodationRoom } from "../../utils/initialValues";

// Child
const AccomodationFormRecord = ({ children, ...rest }) => (
  <RFPFormRecord
    {...rest}
    labelClassName="w-40"
    valueClassName="w-60"
    removeLeftSpace
  >
    {children}
  </RFPFormRecord>
);

// Parent
const Accommodation = () => {
  const { values } = useFormikContext();
  const {
    [RFP_DETAILS_STATE_KEY]: { isEditing },
  } = useEventCoordinationContext();

  return (
    <ServiceQuestionController
      questionText="Do you require Accommodation?"
      flag="accomodation_flag"
    >
      <AccomodationFormRecord
        label="Hotel Rating"
        value={values.rfpaccomodation.hotel_rating.join(", ")}
      >
        <FormikCheckboxes
          name="rfpaccomodation.hotel_rating"
          options={hotelRatingOptions}
          disabled={!isEditing}
        />
      </AccomodationFormRecord>
      <AccomodationFormRecord
        label="Check-in"
        value={getFormatedDate(values.rfpaccomodation.check_in)}
      >
        <FormikDatePicker
          name="rfpaccomodation.check_in"
          placeholder="YYYY/MM/DD"
          minDate={moment().toDate()}
          disabled={!isEditing}
        />
      </AccomodationFormRecord>
      <AccomodationFormRecord
        label="Check-out"
        value={getFormatedDate(values.rfpaccomodation.check_out)}
      >
        <FormikDatePicker
          name="rfpaccomodation.check_out"
          placeholder="YYYY/MM/DD"
          disabled={!values.rfpaccomodation.check_in || !isEditing}
          minDate={
            values.rfpaccomodation.check_in
              ? moment(values.rfpaccomodation.check_in).toDate()
              : moment().toDate()
          }
        />
      </AccomodationFormRecord>
      <FieldArray
        name="rfpaccomodation.accomodation_rooms"
        render={({ form, push, remove }) => {
          return (
            <Table className="rfp-table">
              <thead>
                <tr>
                  <td width="25%">No. of Rooms</td>
                  <td width="25%">Occupancy</td>
                  <td width="25%">Meal Plan</td>
                  <td width="25%">Remarks</td>
                </tr>
              </thead>
              <tbody>
                <React.Fragment>
                  {form.values.rfpaccomodation.accomodation_rooms.map(
                    (_, index) => (
                      <tr key={index}>
                        <TableData width="25%">
                          <FormikField
                            type="number"
                            name={`rfpaccomodation.accomodation_rooms[${index}].no_of_rooms`}
                            placeholder="Rooms"
                            disabled={!isEditing}
                          />
                        </TableData>
                        <TableData width="25%">
                          <FormikSelect
                            name={`rfpaccomodation.accomodation_rooms[${index}].occupancy`}
                            options={occupancyOptions}
                            placeholder="Occupancy"
                            disabled={!isEditing}
                          />
                        </TableData>
                        <TableData width="25%">
                          <FormikSelect
                            name={`rfpaccomodation.accomodation_rooms[${index}].meal_plan`}
                            options={mealPlanOptions}
                            placeholder="Meal Plan"
                            disabled={!isEditing}
                          />
                        </TableData>
                        <TableData width="25%">
                          <FormikField
                            name={`rfpaccomodation.accomodation_rooms[${index}].remarks`}
                            placeholder="Remarks"
                            disabled={!isEditing}
                          />
                        </TableData>
                        {form.values.rfpaccomodation.accomodation_rooms.length >
                          1 &&
                          isEditing && (
                            <MdRemoveCircle
                              size={20}
                              className="remove-icon table-rows-remove-icon"
                              onClick={() => remove(index)}
                            />
                          )}
                      </tr>
                    )
                  )}
                  <tr className="d-flex">
                    {isEditing && (
                      <Button
                        className="mt-5 button small-button"
                        onClick={() => push(singleAccomodationRoom)}
                      >
                        + Add Room
                      </Button>
                    )}
                  </tr>
                </React.Fragment>
              </tbody>
            </Table>
          );
        }}
      />
    </ServiceQuestionController>
  );
};

export default Accommodation;
