import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import propTypes from "prop-types";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "./index.scss";
import { getDummyVenueImage } from "../../utils/helpers";

function Map({
  height,
  data,
  specificLocation,
  zoom,
  searchOption,
  setVenue,
  updateSpecificLocation,
}) {
  const [hoveredVenue, setHoveredVenue] = useState(null);
  const [selectedVenue, setSelectedVenue] = useState();

  useEffect(() => {
    if (specificLocation) {
      setSelectedVenue(specificLocation);
    }
  }, [specificLocation]);

  const containerStyle = {
    width: "100%",
    maxHeight: 600,
    height,
  };

  const center = {
    lat:
      parseFloat(selectedVenue?.latitude) ||
      parseFloat(specificLocation?.latitude) ||
      40.52,
    lng:
      parseFloat(selectedVenue?.longitude) ||
      parseFloat(specificLocation?.longitude) ||
      34.34,
  };

  // googleMapsApiKey: "AIzaSyDdUffp_HIdgbvTBUek5ZHIjx8X3XeFNus",
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const options = data
    ? data.map((venue) => ({
        id: venue.id,
        name: venue.venue_name,
        value: venue,
      }))
    : [];

  const handleSelectOption = (selectedItem) => {
    setVenue(selectedItem.value);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom || 5}
      options={{
        keyboardShortcuts: false,
        mapTypeControlOptions: {
          style: window.google.maps.MapTypeControlStyle.DEFAULT,
          position: window.google.maps.ControlPosition.TOP_CENTER,
        },
      }}
    >
      {data
        ?.filter(
          (item) => item.id !== (selectedVenue?.id || specificLocation?.id)
        )
        .map((item) => (
          <Marker
            key={item.id}
            position={{
              lat: parseFloat(item.latitude),
              lng: parseFloat(item.longitude),
            }}
            onMouseOver={() => setHoveredVenue(item)}
            onMouseOut={() => setHoveredVenue(null)}
            onClick={() => {
              setSelectedVenue(item);
              //For setting selected venue to change details box on Map
              setVenue(item);
            }}
            icon={{
              url: "/assets/pages/venue/icons/pin-icon.png",
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
        ))}
      {selectedVenue && (
        <Marker
          position={{
            lat:
              parseFloat(selectedVenue?.latitude) ||
              parseFloat(specificLocation?.latitude),
            lng:
              parseFloat(selectedVenue?.longitude) ||
              parseFloat(specificLocation?.longitude),
          }}
          icon={{
            url: "/assets/pages/venue/icons/pin-icon-1.png",
            scaledSize: new window.google.maps.Size(70, 70),
          }}
        />
      )}

      {/*================ Info Window for hovered venue ==================*/}
      {hoveredVenue && (
        <InfoWindow
          position={{
            lat: parseFloat(hoveredVenue?.latitude),
            lng: parseFloat(hoveredVenue?.longitude),
          }}
          onCloseClick={() => {
            setHoveredVenue(null);
          }}
        >
          <div className="d-flex" style={{ maxWidth: 400 }}>
            <img
              src={getDummyVenueImage(hoveredVenue?.property_logo, true)}
              alt=""
              height="50"
              width="50"
            />
            <p className="ml-3">{hoveredVenue?.venue_name}</p>
          </div>
        </InfoWindow>
      )}

      {/*================ Info Window for selected venue ==================*/}
      {(selectedVenue || specificLocation) && (
        <InfoWindow
          position={{
            lat:
              parseFloat(selectedVenue?.latitude) ||
              parseFloat(specificLocation?.latitude),
            lng:
              parseFloat(selectedVenue?.longitude) ||
              parseFloat(specificLocation?.longitude),
          }}
        >
          <div className="d-flex" style={{ maxWidth: 400 }}>
            <img
              src={getDummyVenueImage(
                selectedVenue?.property_logo || specificLocation?.property_logo,
                true
              )}
              alt=""
              height="50"
              width="50"
            />
            <p className="ml-3">
              {selectedVenue?.venue_name || specificLocation?.venue_name}
            </p>
          </div>
        </InfoWindow>
      )}

      {/*========= Input field for searching venues ============*/}

      {searchOption && (
        <div className="map-search-field">
          <ReactSearchAutocomplete
            items={options}
            placeholder="Search Nearby Venues"
            showIcon={false}
            onSelect={handleSelectOption}
            styling={{
              zIndex: 2,
              width: "240px",
              top: "10px",
              right: "60px",
            }} // To display it on top of the search box below
          />
        </div>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

Map.propTypes = {
  height: propTypes.string,
  data: propTypes.array,
  specificLocation: propTypes.shape({
    longitude: propTypes.string,
    latitude: propTypes.string,
  }),
  zoom: propTypes.number,
  searchOption: propTypes.bool,
  setVenue: propTypes.func,
};

Map.defaultProps = {
  height: "80vh",
  data: [],
  specificLocation: null,
  zoom: 14,
  searchOption: false,
  setVenue: () => {},
};

export default React.memo(Map);
