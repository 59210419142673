/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import useDimensions from "react-cool-dimensions";
import Pagination from "react-js-pagination";
import { Row, Col, Container } from "react-bootstrap";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useAlert } from "react-alert";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {
  getExhibitionBooths,
  addToCongressCart,
  removeFromCart,
} from "../../server/Filter";
import SliderModal from "../../components/sliderModal";
import { increaseCartCount } from "../../redux/actions/cartActions";
import "./index.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const boothsPerPage = 6;

function ExibitionBooth() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { state } = useLocation();

  const [booths, setBooths] = useState({});
  const [activePage, setCurrentPage] = useState(1);
  // const [boothSizeFilters, setBoothSizeFilters] = useState([]);

  const history = useHistory();
  const query = useQuery();

  const page = query.get("page");

  // const minSize = query.get("min_size");
  // const mmaxSize = query.get("max_size");
  // const standType = query.get("stand_type");

  // useEffect(() => {
  //   getBoothSizeFilters()
  //     .then((data) => setBoothSizeFilters(data))
  //     .catch((err) => console.log(err));
  // }, []);

  // useEffect(() => {
  //   searchBooths(minSize, mmaxSize, standType)
  //     .then((result) => {
  //       setBooths(result);
  //     })
  //     .catch((err) => console.log(err));
  // }, [minSize, mmaxSize, standType]);

  useEffect(() => {
    const pageNo = page ? parseInt(page) : 1;
    const offset = (pageNo - 1) * boothsPerPage;
    setCurrentPage(pageNo);
    getExhibitionBooths(page, offset)
      .then((data) => setBooths(data))
      .catch((err) => console.log(err));
  }, [page]);

  // Logic for displaying current booths
  // const indexOfLastbooth = activePage * boothsPerPage;
  // const indexOfFirstbooth = indexOfLastbooth - boothsPerPage;
  // const currentbooths = booths?.slice(indexOfFirstbooth, indexOfLastbooth);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (pageNumber !== page) {
      history.push({
        pathname: "/exhibition_booths",
        search: `?page=${pageNumber}`,
        state,
      });
    }
  };

  // eslint-disable-next-line
  const [heights, setHeights] = useState("");
  const { ref } = useDimensions({
    onResize: ({ width }) => {
      var screenMLHeight = (width - 100) / 3.75 + "px";
      var screenSMHeight = (width - 40) / 2 + "px";
      var screenHeight = width / 2 + "px";
      if (width > 750) {
        setHeights(screenMLHeight);
      } else if (width > 558 && width <= 750) {
        setHeights(screenSMHeight);
      } else {
        setHeights(screenHeight);
      }
    },
  });

  const handleCartIconClick = async (booth) => {
    try {
      if (state && state.id) {
        await removeFromCart(state.modelName, state.modelID);
      }
      await addToCongressCart("ExhibitionBooth", booth.id);
      if (state && state.id) {
        history.replace({
          ...history.location,
          state: null,
        });
        history.push("/cart");
        alert.show(
          `${state.descriptionFields.title} replaced with ${booth.booth_code}`,
          {
            type: "success",
          }
        );
        history.push({
          ...history.location,
          state: null,
        });
      } else {
        dispatch(increaseCartCount());
        alert.show(`${booth.booth_code} successfully added to cart`, {
          type: "success",
        });
      }
    } catch (err) {
      if (err.response) {
        alert.show(err.response.data, {
          type: "error",
        });
      }
    }
  };

  const renderbooths = (booth, index) => {
    return (
      <Col md={6} className="px-0 px-md-4 mb-7" key={index}>
        <SliderModal
          sliderImages={booth.slider_images}
          sliderClasses="exibitionBoothSlider"
        />
        {/* <SliderModal show={show} setShow={setShow} /> */}
        {/* <Slider {...settings} className="exibitionBoothSlider">
          {booth.slider_images.map((slider_image) => (
            <Image
              key={slider_image.image}
              src={slider_image.image}
              className="w-100"
              onDoubleClick={handleShow}
            />
          ))}
        </Slider> */}
        <div
          className="sliderCartIcon"
          onClick={() => handleCartIconClick(booth)}
        >
          <img src="/assets/images/exhibition/icon.png" alt="" />
        </div>
        {/* <Row className="mt-5">
          <Col xs={6} className="px-0">
            <p className="font-weight-bold text-lg-125 text-100 mb-0">
              {booth.booth_code}
            </p>
          </Col>
          <Col xs={6} className="px-0 text-right">
            <Link to={`${location.pathname}/${booth.id}/${booth.booth_code}`}>
              <Button className="Button">View Details</Button>
            </Link>
          </Col>
        </Row> */}
        {/* <p className="text-lg-100 text-75 mb-0">
          Exhibition Booth Size: {booth.booth_size} sqm
        </p> */}
      </Col>
    );
  };

  // const changeQueryParameters = (queryObject) => {
  //   const params = {};
  //   query.forEach((value, key) => {
  //     params[key] = value;
  //   });
  //   const queryParams = { ...params, ...queryObject };
  //   const queryString = qs.stringify(queryParams);
  //   return queryString;
  // };

  // const deleteQueryParameter = (queryKey) => {
  //   const params = {};
  //   query.forEach((value, key) => {
  //     params[key] = value;
  //   });
  //   delete params[queryKey];
  //   const queryString = qs.stringify(params);
  //   return queryString;
  // };

  // const changeStandType = (value) => {
  //   if (value) {
  //     const queryObject = { stand_type: value };
  //     const queryString = changeQueryParameters(queryObject);
  //     history.push(`${location.pathname}?${queryString}`);
  //   } else {
  //     const queryString = deleteQueryParameter("stand_type");
  //     history.push(`${location.pathname}?${queryString}`);
  //   }
  // };

  return (
    <React.Fragment>
      <Header />
      <Container
        className="event_banner px-0 position-relative "
        style={{
          backgroundImage:
            'url("/assets/images/congress-and-exhibition/exhibition-booth-banner.jpg")',

          backgroundColor: "rgb(37 50 190 / 50%)",
        }}
        fluid
      >
        <div
        // className="event_banner_image"
        // style={{ backgroundColor: "rgba(37, 50, 190, 0.4)" }}
        ></div>
        <div className="text-blocks">
          <p
            className="font-weight-bold  text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-250 mb-0"
            style={{ fontFamily: "'Poppins'" }}
          >
            Exhibition Booth
          </p>
        </div>
      </Container>
      <Row className="BackBtn">
        <Col xl={12} className="myback_button">
          <Link to="/congress" className="back_button">
            <img
              src="/assets/images/icon-back.png"
              className="icon_back"
              alt=""
            />{" "}
            Back
          </Link>
        </Col>
      </Row>
      <Container
        className="px-0 plan-parentxxx min-height-300 CustomizeContainer"
        ref={ref}
      >
        <Row>
          <Col xs={12}>
            {/* <Row className=" mt-5 align-items-center pb-5 fluid2_booth">
              <Col lg={3} md={3} sm={4} className="px-0">
                <p className="text-lg-125 text-100 mb-0">
                  Filter by stand type
                </p>
              </Col>
              <Col lg={5} md={6} sm={7}>
                <Form>
                  <RadioGroup
                    onChange={(e) => changeStandType(e)}
                    name="filter_radio"
                  >
                    <div className="d-flex text-center">
                      <div className="radio_border ml-0 pl-0">
                        <div className="radio_inner_div border-bottom-0">
                          <Radio value="row_stand" id="row_stand" />
                        </div>
                        <label className="radio_label" htmlFor="row_stand">
                          Row Stand
                        </label>
                      </div>
                      <div className="radio_border ml-0 pl-0">
                        <div className="radio_inner_div border-left-0 border-bottom-0">
                          <Radio value="corner" id="corner_stand" />
                        </div>
                        <label className="radio_label" htmlFor="corner_stand">
                          Corner Stand
                        </label>
                      </div>
                      <div className="radio_border ml-0 pl-0">
                        <div
                          className="radio_inner_div border-left-0 border-right-0 border-bottom-0"
                          style={{ backgroundColor: "#EEE" }}
                        >
                          <Radio value="two_corner" id="two_corner_stand" />
                        </div>
                        <label
                          className="radio_label"
                          htmlFor="two_corner_stand"
                        >
                          Two Corner Stand
                        </label>
                      </div>
                      <div className="radio_border ml-0 pl-0">
                        <div
                          className="radio_inner_div border-0"
                          style={{ backgroundColor: "#BBB" }}
                        >
                          <Radio value="island" id="island_stand" />
                        </div>
                        <label className="radio_label" htmlFor="island_stand">
                          Island Stand
                        </label>
                      </div>
                      <div className="radio_border ml-0 pl-0">
                        <div className="radio_inner_div ml-sm-0 border-0">
                          <Radio value="" id="all" />
                        </div>
                        <label
                          className="radio_label radio_label5"
                          htmlFor="all"
                        >
                          All
                        </label>
                      </div>
                    </div>
                  </RadioGroup>
                </Form>
              </Col>
              <Col lg={4} md={3} sm={1} className="mt-5 mt-sm-0">
                <button
                  className="back_button mb-sm-3 float-lg-right"
                  onClick={() => history.push("/exhibition_booths")}
                >
                  Reset Filters
                </button>
              </Col>
            </Row>
            <Row className="py-5 my-0 mx-auto">
              <Col md={3} className="px-0">
                <select
                  className="border-0"
                  onChange={(e) => {
                    const queryObject = JSON.parse(e.target.value);
                    const queryString = changeQueryParameters(queryObject);
                    history.push(`${location.pathname}?${queryString}`);
                  }}
                >
                  <option value="-1" hidden>
                    Exhibition Stand Size
                  </option>
                  {boothSizeFilters.map((item) => (
                    <option key={item.min_size} value={JSON.stringify(item)}>
                      {item.min_size} - {item.max_size} sqm
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={8}></Col>
            </Row> */}

            <Row>{booths?.results?.map(renderbooths)}</Row>
            {booths?.count > boothsPerPage && (
              <div className="paginations">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={boothsPerPage}
                  totalItemsCount={booths.count}
                  pageRangeDisplayed={3}
                  onChange={handlePageChange}
                />
              </div>
            )}
            {booths?.count === 0 && <div className="mb-5">No booths found</div>}
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
}

export default ExibitionBooth;
