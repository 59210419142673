import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Header from "../../components/header";
import Footer from "../../components/footer";
import PackagesSlider from "./packages_slider";
import "./index.scss";

const ViewPackages = (props) => {
  const { brandingPackage } = props.location.state;

  return (
    <React.Fragment>
      <Header />
      <Container fluid className="px-0">
        <Row className="inner_width_div my-5 pl-sm-6 pl-5">
          <Link to="/branding_materials" className="float-left back_button">
            &lt; Back
          </Link>
        </Row>
        <Row className="inner_width_div">
          <Col md={8} className="view_packages_col pl-sm-6 pl-5 pr-5 pr-sm-4">
            <PackagesSlider sliderImages={brandingPackage.sliderImages} />
            <p className="text-lg-150 text-125 mt-5 mb-2">
              Description:
            </p>
            {brandingPackage.description &&
              parse(brandingPackage.description.toString())}
          </Col>
          <Col md={4} className="view_packages_col pl-sm-6 pl-5 mb-7">
            <p className="text-lg-150 text-125 mb-2">
              {brandingPackage.heading}
            </p>
            <div className="my-5 mx-0 pl-5" style={{ lineHeight: "1" }}>
              <p>Code: {brandingPackage.code}</p>
              <p>Inclusions:</p>
              {brandingPackage.inclusions &&
                parse(brandingPackage.inclusions.toString())}
              <p>Price: {brandingPackage.price}AED</p>
            </div>
            <div className="d-flex">
              <Link to="/brandingRFP">
                <Button className="Button h-auto py-2 px-4">
                  Add to Existing RFP
                </Button>
              </Link>
              <Link to="/brandingRFP">
                <Button className="Button h-auto py-2 px-4 ml-5">
                  Create a New RFP
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default ViewPackages;
