import React from "react";
import MultiFilePickerProvider, {
  MultiFilePickerContext,
} from "../context/MultiFilePickerContext";

// Wrapping child component with Provider in which we can get data in props as well as
// from context

const withMultiFilePicker =
  (ChildComponent) =>
  ({ ...childComponentProps }) => {
    return (
      <MultiFilePickerProvider>
        <MultiFilePickerContext.Consumer>
          {(fileHandlerProps) => (
            <ChildComponent {...fileHandlerProps} {...childComponentProps} />
          )}
        </MultiFilePickerContext.Consumer>
      </MultiFilePickerProvider>
    );
  };

export default withMultiFilePicker;
