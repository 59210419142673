import React, { useState } from "react";
import { Container, Row, Col, Tabs } from "react-bootstrap";
import PageWrapper from "../PageWrapper";
import "./styles/requests.scss";

const RFPRequestsWrapper = ({
  pageHeading = "RFP",
  defaultKey = "EVENTS",
  children,
}) => {
  const [activeTabKey, setActiveTabKey] = useState(defaultKey);

  const handleSwitchTab = (selectedTabKey) => setActiveTabKey(selectedTabKey);

  return (
    <PageWrapper bannerTitle={pageHeading}>
      <Container className="px-0">
        <Row className="">
          <Col xs={12} className="">
            <Tabs
              id="rfp-requests-tabs"
              activeKey={activeTabKey}
              onSelect={handleSwitchTab}
              className="rfp-request-tabs"
            >
              {children}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export default RFPRequestsWrapper;
