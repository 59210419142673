import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomTooltip = ({ placement = "top", text, children }) => {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{text}</Tooltip>}>
      <span>{children}</span>
    </OverlayTrigger>
  );
};

export default CustomTooltip;
