export const SPREADSHEET = 'spreadsheet';
export const DOCUMENT = 'document';

export const SYNCFUSION_SPREADSHEET_SERVICES_URL =
  'https://ej2services.syncfusion.com/production/web-services/api/spreadsheet';
export const SYNCFUSION_SPREADSHEET_OPEN_FILE_URL = `${SYNCFUSION_SPREADSHEET_SERVICES_URL}/open`;
export const SYNCFUSION_SPREADSHEET_SAVE_FILE_URL = `${SYNCFUSION_SPREADSHEET_SERVICES_URL}/save`;
export const SYNCFUSION_DOCUMENT_SERVICES_URL =
  'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/';

export const XLSX = 'Xlsx';
export const XLX = 'Xlx';
export const CSV = 'Csv';
export const PDF = 'Pdf';
export const DOCX = 'Docx';
export const DOC = 'Doc';

export const SYNCFUSION_DOCUMENT_TOOLBAR_OPTIONS = [
  'Undo',
  'Redo',
  'Separator',
  'Image',
  'Table',
  'Hyperlink',
  'Bookmark',
  'Comments',
  'TableOfContents',
  'Separator',
  'Header',
  'Footer',
  'PageSetup',
  'PageNumber',
  'Break',
  'Separator',
  'Find',
  'Separator',
  'LocalClipboard',
  'RestrictEditing',
  'FormFields',
  'InsertEndnote',
  'InsertFootnote',
  'UpdateFields',
];
