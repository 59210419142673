import React from "react";
import { Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { removeFromCart, addToSaved, moveToCart } from "../../server/Filter";
import getFormatedDateRange from "../../utils/getFormatedDateRange";
import { decreaseCartCount } from "../../redux/actions/cartActions";

function CartItems(props) {
  const {
    title,
    fieldName,
    items,
    pathname,
    searchQuery,
    updateCartData,
    updateSavedCartList,
    register,
  } = props;

  const alert = useAlert();
  const dispatch = useDispatch();

  const handleDelete = async (item) => {
    try {
      await removeFromCart(item.modelName, item.modelID);
      dispatch(decreaseCartCount());
      if (item.is_saved) {
        await updateSavedCartList();
      } else {
        await updateCartData();
      }
      alert.show(`${item.descriptionFields?.title} deleted successfully`, {
        type: "success",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async (item) => {
    try {
      if (item.is_saved) {
        await moveToCart(item.modelName, item.modelID);
        alert.show(
          `${item.descriptionFields?.title} successfully moved to cart`,
          {
            type: "success",
          }
        );
      } else {
        await addToSaved(item.modelName, item.modelID);
        alert.show(
          `${item.descriptionFields?.title} successfully added to "Saved For Later" list`,
          {
            type: "success",
          }
        );
      }
      updateCartData();
      updateSavedCartList();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Row className="mt-10 mb-2">
        <h4>{title}</h4>
      </Row>
      <Row>
        <Table responsive className="cardTable h-100 mb-0">
          <tbody>
            {items?.map((item, index) => (
              <tr key={item.id} className="">
                <td className="firstColumn">
                  <h3>Items</h3>
                  <div className="d-flex">
                    <div>
                      <img src={item.descriptionFields?.image} alt="" />
                    </div>
                    <div className="img-data">
                      <h5 className="text-bold">
                        {item.descriptionFields?.title}
                      </h5>
                      {item.descriptionFields?.start_date && (
                        <h6>
                          {getFormatedDateRange(
                            item.descriptionFields?.start_date,
                            item.descriptionFields?.end_date
                          )}
                        </h6>
                      )}
                      <span
                        className="pl-0 cursor-pointer"
                        onClick={() => handleSave(item)}
                      >
                        {item.is_saved ? "Move to cart |" : "Save for later |"}
                      </span>
                      {!item.is_saved && (
                        <Link
                          to={{
                            pathname: pathname,
                            search: searchQuery,
                            state: item,
                          }}
                        >
                          <span className="cursor-pointer">Replace |</span>
                        </Link>
                      )}
                      <span
                        className="cursor-pointer"
                        onClick={() => handleDelete(item)}
                      >
                        Delete
                      </span>
                    </div>
                  </div>
                  <input
                    ref={register}
                    name={`${fieldName}[${index}].id`}
                    type="number"
                    className="d-none"
                    defaultValue={item.id}
                  />

                  <input
                    ref={register}
                    name={`${fieldName}[${index}].quantity`}
                    type="number"
                    className="d-none"
                    min="1"
                    defaultValue={item.quantity}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </React.Fragment>
  );
}

export default CartItems;
