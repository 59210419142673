import React from "react";
import RFPContext from "./context/RFPContext";
import FormStepper from "./components/FormStepper";
import MainForm from "./components/MainForm";
import "./styles/index.scss";

const RFP = () => {
  return (
    <RFPContext>
      <FormStepper />
      <MainForm />
    </RFPContext>
  );
};

export default RFP;
