import React from "react";
import { Row, Col } from "react-bootstrap";

const FormRecordWrapper = ({ label, required = true, children }) => {
  return (
    <Row className="my-5 align-items-center">
      <Col md={3}>
        <p className={`mb-0 ${required ? "required" : ""}`}>{label}</p>
      </Col>
      <Col md={9}>{children}</Col>
    </Row>
  );
};

export default FormRecordWrapper;
