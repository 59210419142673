import React, { useEffect, useRef } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useLocation, useHistory } from "react-router-dom";

// SERVICES
import useLazyFetchRequest from "../../../components/common/custom-hooks/useLazyFetchRequest";
import { getLocationSuggestionsQuery } from "../../../services/venue-sourcing";
import { useTransformSearchSuggestions } from "../../../custom-hooks/useTransformers";
import { useDebouncedCallback } from "use-debounce";

function AsyncSearchField() {
  const inputRef = useRef();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const paramsValue = searchParams.get("search");

  const [getSuggestions, { isLoading, isSuccess, data }] = useLazyFetchRequest(
    getLocationSuggestionsQuery
  );
  const options = useTransformSearchSuggestions(data);

  const handleDebounce = useDebouncedCallback(
    async (value) => {
      await getSuggestions(value);
    },
    [500]
  );

  useEffect(() => {
    if (!paramsValue) {
      // eslint-disable-next-line no-unused-expressions
      inputRef.current?.clear();
    }
  }, [paramsValue]);

  const handleSearch = (value) => {
    handleDebounce(value);
  };

  const handleChange = (item) => {
    const value = item[0]?.value;
    searchParams.set("search", encodeURIComponent(value));
    searchParams.delete("page");

    history.push({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <div className="async-field-wrapper w-100">
      <AsyncTypeahead
        ref={inputRef}
        className="async-filter-search"
        filterBy={() => true}
        isLoading={isLoading && isSuccess}
        id="Custom-Search"
        onChange={handleChange}
        onSearch={handleSearch}
        options={options}
        placeholder="Seach Location..."
        minLength={2}
      />
    </div>
  );
}

export default AsyncSearchField;
