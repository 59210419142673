import { useEffect, useState } from "react";
import { useAlert } from "react-alert";

const DEFAULT_OPTIONS = {
  skip: false,
  showErrorAlert: false,
  errorAlertMessage: "Something went wrong",
};

function useFetchRequest(fetchRequest, args, options = DEFAULT_OPTIONS) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  /* ========== Options ============ */
  const skip = options.skip || DEFAULT_OPTIONS.skip;
  const showErrorAlert =
    options.showErrorAlert || DEFAULT_OPTIONS.showErrorAlert;
  const errorAlertMessage =
    options.errorAlertMessage || DEFAULT_OPTIONS.errorAlertMessage;

  const fetchData = (...newArgs) => {
    setIsLoading(true);
    fetchRequest(...newArgs)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        if (showErrorAlert) alert.error(errorAlertMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!skip) fetchData(args);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    data: data || undefined,
    refetch: fetchData,
  };
}

export default useFetchRequest;
