import React from "react";
import { useField } from "formik";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import useToggle from "../../../components/common/custom-hooks/useToggle";

function FormikField({
  name,
  type = "text",
  textArea = false,
  textRight = false,
  className = "",
  inputClassName = "",
  onChange = null,
  onBlur = null,
  disabled = false,
  ...restProps
}) {
  const { isOpen: isShowingPassword, toggle: togglePasswordVisibility } =
    useToggle(false);
  const [field, meta] = useField(name || "");

  const { onChange: onValueChange, onBlur: onFieldBlur, value } = field;
  const { touched, error } = meta;

  const handleChange = (event) => {
    onValueChange(event);
    if (onChange) onChange(event.target.value);
  };

  const handleBlur = (event) => {
    onFieldBlur(event);
    if (onBlur) onBlur(name, event.target.value);
  };

  const isPasswordField = type === "password";
  const isErrorField = touched && error;
  const passwordFieldType = isShowingPassword ? "text" : "password";

  return (
    <div className={`w-100 ${className}`}>
      {textArea ? (
        <textarea
          rows={3}
          {...field}
          {...restProps}
          className={`${inputClassName} ${textRight ? "text-right" : ""} ${
            isErrorField ? "error-field" : ""
          }`}
          type={type}
          disabled={disabled}
          onChange={disabled ? undefined : handleChange}
          onBlur={handleBlur}
        />
      ) : (
        <div className="input-wrapper position-relative">
          <input
            {...field}
            {...restProps}
            className={`${inputClassName} ${textRight ? "text-right" : ""} ${
              isErrorField ? "error-field" : ""
            }`}
            type={isPasswordField ? passwordFieldType : type}
            disabled={disabled}
            value={value}
            onChange={disabled ? undefined : handleChange}
            onBlur={handleBlur}
          />
          {isPasswordField && (
            <span
              className="position-absolute cursor-pointer"
              style={{
                top: 0,
                bottom: 0,
                right: 10,
                margin: "auto 0",
                height: "fit-content",
              }}
              onClick={togglePasswordVisibility}
            >
              {isShowingPassword ? (
                <FaEyeSlash color="#c7c7c7" />
              ) : (
                <FaEye color="#c7c7c7" />
              )}
            </span>
          )}
        </div>
      )}
      {isErrorField && <span className="form__form-group-error">{error}</span>}
    </div>
  );
}

export default FormikField;
