import React from "react";
import { FieldArray } from "formik";
import { Table, Button } from "react-bootstrap";
import { MdRemoveCircle } from "react-icons/md";
import FormikField from "../../../shared/components/form/FormikField";
import FormikSelect from "../../../shared/components/form/FormikSelect";
import ServiceQuestionController from "./ServiceQuestionController";
import { singleGroundTransportInitialValues } from "../utils/initialValues";
import {
  transportCategoryOptions,
  serviceTypeOptions,
} from "../utils/constants";

const GroundTransportation = () => {
  return (
    <div className="w-100">
      <ServiceQuestionController
        questionText="Do you require Ground Transportation?"
        flag="ground_transportation_flag"
        stepNo={7}
      >
        <FieldArray
          name="rfpgroundtransportation"
          render={({ form, push, remove }) => {
            return (
              <Table className="rfp-table">
                <thead>
                  <tr>
                    <td width="33.33%">Category</td>
                    <td width="33.33%">Type Of Service</td>
                    <td width="33%.33">Remarks/Requirements</td>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {form.values.rfpgroundtransportation.map((_, index) => (
                      <tr key={index}>
                        <td>
                          <FormikSelect
                            name={`rfpgroundtransportation[${index}].category`}
                            placeholder="Category"
                            options={transportCategoryOptions}
                          />
                        </td>
                        <td>
                          <FormikSelect
                            name={`rfpgroundtransportation[${index}].type_of_service`}
                            placeholder="Service Type"
                            options={serviceTypeOptions}
                          />
                        </td>
                        <td>
                          <FormikField
                            name={`rfpgroundtransportation[${index}].remarks`}
                            placeholder="Remarks"
                          />
                        </td>
                        {form.values.rfpgroundtransportation.length > 1 && (
                          <MdRemoveCircle
                            size={20}
                            className="remove-icon table-rows-remove-icon"
                            onClick={() => remove(index)}
                          />
                        )}
                      </tr>
                    ))}
                    <tr className="d-flex">
                      <Button
                        className="mt-5 button small-button"
                        onClick={() => push(singleGroundTransportInitialValues)}
                      >
                        + Add Ground Transport
                      </Button>
                    </tr>
                  </React.Fragment>
                </tbody>
              </Table>
            );
          }}
        />
      </ServiceQuestionController>
    </div>
  );
};

export default GroundTransportation;
