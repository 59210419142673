import React from "react";
import Select from "../../../shared/components/Select";
import { useManageAccountContext } from "../context/ManageAccountContext";
import { ADD_USER, ALL_USERS, USERS_OPTIONS } from "../utils/constants";

const SELECT_STYLES = {
  control: (base) => ({
    ...base,
    backgroundColor: "#48b2e8",
    border: 0,
    boxShadow: "none",
  }),
  singleValue: (base) => ({
    ...base,
    color: "#fff",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#fff",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#fff",
  }),
};

const ViewToolbar = () => {
  const {
    viewMode,
    switchToView,
    switchToMyAccountView,
    switchToChangePasswordView,
  } = useManageAccountContext();

  return (
    <div className="d-flex gap-1">
      <button
        className="custom-secondary-button border-0"
        onClick={switchToMyAccountView}
      >
        My Account
      </button>
      <div style={{ minWidth: "200px" }}>
        <Select
          value={
            [ALL_USERS, ADD_USER].includes(viewMode)
              ? USERS_OPTIONS.find((i) => i.value === viewMode)
              : ""
          }
          options={USERS_OPTIONS}
          placeholder="Admin"
          onChange={(selectedOption) => switchToView(selectedOption.value)}
          styles={SELECT_STYLES}
        />
      </div>
      <button
        className="custom-secondary-button"
        onClick={switchToChangePasswordView}
      >
        Change Password
      </button>
    </div>
  );
};

export default ViewToolbar;
