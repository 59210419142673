export const ADMIN = "Admin";
export const SUB_ADMIN = "Sub Admin";
export const USER = "User";

export const MY_ACCOUNT = "my-account";
export const ALL_USERS = "all-users";
export const ADD_USER = "add-user";
export const EDIT_USER = "edit-user";
export const CHANGE_PASSWORD = "change-password";

export const ACCOUNT_VIEWS = [
  MY_ACCOUNT,
  ALL_USERS,
  ADD_USER,
  EDIT_USER,
  CHANGE_PASSWORD,
];
export const DEFAULT_VIEW = MY_ACCOUNT;

export const USERS_OPTIONS = [
  {
    label: "All Users",
    value: ALL_USERS,
  },
  {
    label: "Add New User",
    value: ADD_USER,
  },
];

export const userRoleOptions = [
  {
    label: "Sub Admin",
    value: SUB_ADMIN,
  },
  {
    label: "User",
    value: USER,
  },
];
