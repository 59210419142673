import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useRFPFormContext } from "../context/RFPContext";
import ErrorFocus from "../../../components/common/ErrorFocus";
import LoadingButton from "../../../components/common/LoadingButton";
import EventDetailsForm from "./EventDetailsForm";
import PrefferredDestinations from "./PrefferredDestinations";
import PrefferredVenues from "./PrefferredVenues";
import Registration from "./Registration";
import FlightTicket from "./FlightTicket";
import VisaAssistance from "./VisaAssistance";
import Accommodation from "./Accommodation";
import MeetingRooms from "./MeetingRooms";
import GroundTransportation from "./GroundTransportation";
import FandBArrangement from "./FandBArrangement";
import TourEntertainment from "./TourEntertainment";
import OtherRequirements from "./OtherRequirements";
import Production from "./Production";
import { getCartItems } from "../../../redux/actions/cartActions";
import { submitRFPForm, uploadRFPDesign } from "../../../server/Filter";
import initialValues from "../utils/initialValues";
import validationSchema from "../utils/validationSchema";
import { transformRFPPayload } from "../utils/helpers";
import { CONGRESS } from "../utils/constants";

const SEND_REQUEST_BUTTON = "send";
const SAVE_AND_SAVE_LATER_BUTTON = "saveAndSaveLater";

const MainForm = () => {
  const alert = useAlert();
  const history = useHistory();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const [clickedBy, setClickedBy] = useState(null);
  const {
    resetSteps,
    cart: { type: cartType },
  } = useRFPFormContext();

  const handleSubmitForm = async (values, { resetForm }) => {
    try {
      setSubmitting(true);

      // Uploading Design
      const uploadedDesign = values.rfpproduction.uploaded_design;
      if (uploadedDesign) {
        const response = await uploadRFPDesign(uploadedDesign);
        values.rfpproduction.uploaded_design = response.image;
      }

      // Submitting RFP
      const transformedPayload = transformRFPPayload({
        ...values,
        cart_type: cartType,
      });
      await submitRFPForm(transformedPayload);

      setSubmitting(false);
      setClickedBy(null);
      alert.show("Form submitted successfully!", {
        type: "success",
      });
      resetSteps();
      resetForm();

      dispatch(getCartItems());

      history.push("/requested");
    } catch (error) {
      setSubmitting(false);
      setClickedBy(null);
      console.log(error);
      alert.show("Something went wrong. Please try again.", {
        type: "error",
      });
    }
  };

  const handleSubmitButtonClick = (targetButton) => {
    if (formikRef.current) {
      setClickedBy(targetButton);
      const { setFieldValue, submitForm } = formikRef.current;
      setFieldValue(
        "status",
        targetButton === SEND_REQUEST_BUTTON ? "Requested" : "Pending"
      );
      submitForm();
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {({ values }) => {
        const isEventType = values?.category !== CONGRESS;

        return (
          <Form className="rfp-main-form mb-20">
            <Container>
              <Row className="px-0 justify-content-center">
                <Col
                  md={8}
                  className="w-100 px-0 d-flex flex-column align-items-center justify-content-center"
                >
                  <EventDetailsForm isTypeEvents={isEventType} />

                  {isEventType && <PrefferredDestinations />}

                  <PrefferredVenues />

                  <Registration />

                  {values.registration_flag !== "" && <FlightTicket />}

                  {values.flight_ticket_flag !== "" && <VisaAssistance />}

                  {values.visa_assistance_flag !== "" && <Accommodation />}

                  {values.accomodation_flag !== "" && <MeetingRooms />}

                  {values.meeting_rooms_flag !== "" && <GroundTransportation />}

                  {values.ground_transportation_flag !== "" && (
                    <FandBArrangement />
                  )}

                  {values.f_and_b_arrangement_flag !== "" && (
                    <TourEntertainment />
                  )}

                  {values.tour_entertainment_flag !== "" && (
                    <OtherRequirements />
                  )}

                  {values.other_requirement_flag !== "" && <Production />}

                  <div className="mt-5 d-flex justify-content-center">
                    {/* Show send request button when all questions are answered */}
                    {values.production_flag !== "" && (
                      <LoadingButton
                        className="button primary-button text-uppercase mr-3"
                        onClick={() =>
                          handleSubmitButtonClick(SEND_REQUEST_BUTTON)
                        }
                        isLoading={
                          isSubmitting && clickedBy === SEND_REQUEST_BUTTON
                        }
                      >
                        Send Request
                      </LoadingButton>
                    )}
                    <LoadingButton
                      className="custom-primary-button"
                      onClick={() =>
                        handleSubmitButtonClick(SAVE_AND_SAVE_LATER_BUTTON)
                      }
                      isLoading={
                        isSubmitting && clickedBy === SAVE_AND_SAVE_LATER_BUTTON
                      }
                    >
                      Save & Send Later
                    </LoadingButton>
                  </div>
                </Col>
              </Row>
            </Container>
            <ErrorFocus />
          </Form>
        );
      }}
    </Formik>
  );
};

export default MainForm;
