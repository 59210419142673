import {
  APPROVED,
  APPROVE_MODE,
  CANCELLED,
  CANCEL_MODE,
  DECLINED,
  DECLINE_MODE,
  REQUESTED_AGAIN,
  RESCHEDULE_MODE,
} from "./contants";

export const getStatusMessage = (targetStatus) => {
  if (targetStatus === APPROVED)
    return {
      successMessage: "Site Inspection approved successfully",
      errorMessage: "Something went wrong in approving site inspection",
      infoMessage: "Are you sure you want to approve this site inspection?",
    };
  if (targetStatus === DECLINED)
    return {
      successMessage: "Site Inspection declined successfully",
      errorMessage: "Something went wrong in declining site inspection",
      infoMessage: "Are you sure you want to decline this site inspection?",
    };
  return {
    successMessage: "",
    errorMessage: "",
    infoMessage: "",
  };
};

export const getPayloadAndRequestMessage = (selectedInspection, payload) => {
  if (selectedInspection) {
    // Approve Mode
    if (selectedInspection.mode === APPROVE_MODE)
      return {
        payload: {
          id: selectedInspection.id,
          comments: payload.comments,
          status: APPROVED,
        },
        successMessage: "Site Inspection approved successfully",
        errorMessage: "Something went wrong in approving site inspection",
      };
    // Decline Mode
    if (selectedInspection.mode === DECLINE_MODE)
      return {
        payload: {
          id: selectedInspection.id,
          comments: payload.comments,
          status: DECLINED,
        },
        successMessage: "Site Inspection declined successfully",
        errorMessage: "Something went wrong in declining site inspection",
      };
    // Cancel Mode
    if (selectedInspection.mode === CANCEL_MODE)
      return {
        payload: {
          id: selectedInspection.id,
          comments: payload.comments,
          status: CANCELLED,
        },
        successMessage: "Site Inspection cancelled successfully",
        errorMessage: "Something went wrong in cancelling site inspection",
      };
    // Reschedule Mode
    if (selectedInspection.mode === RESCHEDULE_MODE)
      return {
        payload: {
          ...payload,
          comments: "", // No comments for new request
          prev_comments: payload.comments, // Entered comments will be saved for rescheduled request
          prev_inspection_id: selectedInspection.id, // Backend team using it to change its status and adding prev_comments to it
        },
        successMessage: "Site Inspection rescheduled successfully",
        errorMessage: "Something went wrong in rescheduling site inspection",
      };

    return {
      payload: {
        ...payload,
        id: selectedInspection.id,
        status: REQUESTED_AGAIN,
      },
      successMessage: "Site Inspection updated successfully",
      errorMessage: "Something went wrong in updating site inspection",
    };
  }
  return {
    payload: {
      ...payload,
    },
    successMessage: "Site Inspection added successfully",
    errorMessage: "Something went wrong in adding site inspection",
  };
};
