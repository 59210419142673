const primary = '#071f4e';
const lightBlue = '#48b2e8';
const yellow = '#ffac33';
const error = '#df0505';

export const colors = {
  primary,
  lightBlue,
  yellow,
  error,
}