import { useContext } from "react";
import { createContext } from "react";

export const FormContext = createContext({
  venueId: null,
  payload: {},
  setPayload: () => {},
  currentTab: null,
  setCurrentTab: () => {},
});

export const useGetFormContext = () => {
  const context = useContext(FormContext);

  return context;
};
