import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ServeiceButtons from '../service_buttons';
import Text from '../text';

const Branding = ({ setTab }) => {
    return (
        <React.Fragment>
            <Form>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor="upload">Upload file:</label>
                    </Col>
                    <Col className="px-0"md={6}>
                    <label className="upload_file"> Choose File
                        <input className="file-upload__input" type="file" name="myFile[]" id="myFile" multiple />
                    </label>
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Text heading="Requirements:" />
                <ServeiceButtons setTab={setTab} />
            </Form>
        </React.Fragment>
    )
}

export default Branding;
