import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFieldArray } from "react-hook-form";
import Datepick from "../../../components/datepick";
import EventServeiceButtons from "../event_service_buttons";
import EventText from "../event_text";
import "./index.scss";

const EventAccomodate = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
    control,
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "accomodate.hotels",
  });

  const hotelsCards = [
    {
      source: "assets/pages/events/Capture6.PNG",
      head1: "Hotel name",
      head2: "Address:",
    },
    {
      source: "assets/pages/events/Capture6.PNG",
      head1: "Hotel name",
      head2: "Address:",
    },
    {
      source: "assets/pages/events/Capture6.PNG",
      head1: "Hotel name",
      head2: "Address:",
    },
  ];

  const disableAccomodateType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      accomodateDisabled: true,
    }));
  };

  const enableAccomodateType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      accomodateDisabled: false,
    }));
  };

  const renderhotelsCard = (hotelsCard, index) => {
    return (
      <Col xl={4} lg={4} className="px-2" key={index}>
        <div className="event_accomodate_div">
          <Image
            src={hotelsCard.source}
            className="w-100"
            style={{ height: "90px" }}
            fluid
          />
          <p className="text-lg-125 text-100 mb-1 mt-3">{hotelsCard.head1}</p>
          <p className="text-lg-100 text-75 mb-2">{hotelsCard.head2}</p>
          <Row>
            <Col xl={6} className="pl-0 pr-2">
              <button
                onClick={(e) => e.preventDefault()}
                style={{ fontSize: "14px" }}
              >
                Add to RFP
              </button>
            </Col>
            <Col xl={6} className="pr-0 pl-2">
              <button
                onClick={(e) => e.preventDefault()}
                style={{ fontSize: "14px" }}
              >
                View Venue
              </button>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };
  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="hotel">Preffered Hotel:</label>
        </Col>
        <Col md={9} className="px-0">
          {fields.map(({ id }, index) => (
            <Row key={id}>
              <Col xl={8} className="px-0 mb-3">
                <input name={`accomodate.hotels[${index}].hotel_name`} />
              </Col>
              <Col xl={3}>
                <div className="event_form_addmore_btn">
                  <button className="Button_hide" onClick={() => remove(index)}>
                    Delete Venue
                  </button>
                </div>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row className="form_main">
        <Col xl={3} />
        <Col xl={6} className="px-0">
          <button
            className="Button_hide"
            onClick={() => append({ hotel_name: "" })}
          >
            Add More
          </button>
        </Col>
      </Row>
      <Row>
        <Col lg={3}></Col>
        <Col lg={6} className="px-0">
          <Row>{hotelsCards.map(renderhotelsCard)}</Row>
        </Col>
        <Col lg={3} className="text-left my-auto">
          <Link to="/venue_sourcing" className="text-dark text-decoration-none">
            Explore More Venues
          </Link>
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3} xs={6}>
          <label htmlFor="date_in">Check-in Date:</label>
          <img
            className="calendar float-right mr-1"
            src={"assets/images/download.jpg"}
            alt="Not Found"
          />
        </Col>
        <Col className="px-0" xs={6}>
          <Datepick name="date_in" />
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3} xs={6}>
          <label htmlFor="date_out">Check-out Date:</label>
          <img
            className="calendar float-right mr-1"
            src={"assets/images/download.jpg"}
            alt="Not Found"
          />
        </Col>
        <Col className="px-0" xs={6}>
          <Datepick name="date_out" />
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="rooms">Number of rooms:</label>
        </Col>
        <Col className="px-0" md={6}>
          <input name="rooms" />
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="type">Type:</label>
        </Col>
        <Col className="pl-0" md={6}>
          <select name="type">
            <option>Room Only</option>
            <option>Bed and Breakfast</option>
            <option>Half Board</option>
            <option>Full Board</option>
          </select>
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="occupancy">Type:</label>
        </Col>
        <Col className="form_column_sel pl-0" md={6}>
          <select name="occupancy">
            <option>Single Occupancy</option>
            <option>Double Occupancy</option>
          </select>
        </Col>
        <Col md={3}></Col>
      </Row>
      <EventText heading="Requirements:" />
      <EventServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableAccomodateType}
        enableSelectedType={enableAccomodateType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default EventAccomodate;
