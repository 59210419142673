export const tableHeadings = [
  {
    id: "invoice",
    label: "Invoice Number",
    align: "left",
  },
  {
    id: "updated_date",
    label: "Date",
    isDateField: true,
  },
  {
    id: "invoice_time",
    label: "Time",
  },
];
