import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import "./steps.scss";

const MultiStepProgressBar = ({ currentStep, totalSteps }) => {
  const isFirstStep = currentStep === 1;
  const isLastStep = currentStep === totalSteps;
  const interval = 100 / (totalSteps - 1);
  const progressFilledPercentage = isLastStep
    ? 100
    : interval * (currentStep - 1);

  return (
    <ProgressBar
      percent={isFirstStep ? 0 : progressFilledPercentage}
      filledBackground="#183765"
    >
      {[...new Array(totalSteps)].map((_, stepIndex) => (
        <Step key={stepIndex}>
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? "accomplished" : ""}`}
            >
              {index + 1}
            </div>
          )}
        </Step>
      ))}
    </ProgressBar>
  );
};

export default MultiStepProgressBar;
