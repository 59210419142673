import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import { List, ListItem, Paper } from "@material-ui/core";

// COMPONENTS & UTILITIES
import ModalCarousel from "../../../components/common/modal-carousel";
import { getVenueBasicDetailsQuery } from "../../../services/venue-details";
import { useGetVenueDetailContext } from "../custom-hooks/useGetVenueDetailContext";
import {
  useGetBasicInfoGridImages,
  useGetTransformedVenueBasicDetails,
} from "../custom-hooks/useTransformers";
import useFetchRequest from "../../../components/common/custom-hooks/useFetchRequest";
import { getDummyVenueImage } from "../../../utils/helpers";
import useGetCartHandlers from "../../../custom-hooks/useGetCartHandlers";

function VenueBasicInfo() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const { venueId } = useGetVenueDetailContext();
  const { handleCongressCart, handleEventCart } = useGetCartHandlers({
    id: venueId,
  });
  const { data: basicDetails } = useFetchRequest(
    getVenueBasicDetailsQuery,
    venueId
  );

  // TRANSFORMERS
  const venueHighlights = useGetTransformedVenueBasicDetails(
    basicDetails || {}
  );
  const { allImages, gridImages, mainImage } =
    useGetBasicInfoGridImages(basicDetails);

  // HANDLERS
  const toggleModal = () => setModalOpen(!isModalOpen);

  const handleOpenMenu = () => {
    setAnchorEl(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(false);
  };

  return (
    <Container fluid className="basic-info-container">
      <ModalCarousel
        isOpen={isModalOpen}
        toggle={toggleModal}
        sliderImages={allImages}
      />

      {/* HEADER CONTAINER */}
      <Container>
        <Row className="main-header-row">
          <Col sm={9}>
            <div className="heading-row d-flex align-items-center mb-3">
              <h2 className="event-heading">
                {basicDetails?.venue_name || "NA"}
              </h2>

              <StarRatings
                rating={basicDetails?.star_rating || 0}
                starDimension="20px"
                starSpacing="0px"
                starRatedColor="#F2BB13"
              />
            </div>

            <div className="address-wrapper">
              <p>{`Address: ${basicDetails?.venue_address || "NA"}`}</p>
            </div>
          </Col>

          <Col sm={3} className="d-flex justify-content-end align-items-center">
            <div
              className="menu-wrapper"
              onMouseEnter={handleOpenMenu}
              onMouseLeave={handleCloseMenu}
            >
              <button className="btn-border-secondary cart-btn">
                Add to Cart
              </button>

              <Paper className={`menu-paper ${anchorEl ? "show-menu" : ""}`}>
                <List>
                  <ListItem
                    button
                    className="cart-menu-item"
                    onClick={() => {
                      handleEventCart();
                      handleCloseMenu();
                    }}
                  >
                    Event
                  </ListItem>

                  <ListItem
                    button
                    className="cart-menu-item"
                    onClick={() => {
                      handleCongressCart(
                        getDummyVenueImage(basicDetails?.property_logo, true),
                        true
                      );
                      handleCloseMenu();
                    }}
                  >
                    Congress
                  </ListItem>
                </List>
              </Paper>
            </div>
          </Col>
        </Row>
      </Container>

      {/* MAIN IMAGES GRID */}
      <Row noGutters className="gallery-row-wrapper">
        <Col className="grid-images-container row">
          {gridImages?.map((item) => (
            <Col key={item.image} className="grid-img-wrapper">
              <img
                className="img-fluid grid-img"
                src={getDummyVenueImage(item?.image)}
                alt="galary-item"
              />
            </Col>
          ))}
        </Col>

        <Col className="grid-main-wrapper">
          <img
            className="img-fluid main-image"
            src={getDummyVenueImage(mainImage)}
            alt="galary-main-item"
          />

          <button type="button" className="show-all-btn" onClick={toggleModal}>
            See More
          </button>
        </Col>
      </Row>

      {/* VENU MAIN INFO CONTAINER */}
      <Container className="info-container">
        <Row>
          {venueHighlights?.map((item) => (
            <Col xs={6} md={3} key={item?.label}>
              <p className="heading">{item?.label}</p>
              <p className="value">{item?.value}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default VenueBasicInfo;
