import React, { useEffect, useState } from "react";
import { getVenueConceptCities } from "../../../server/Filter";

const FilterSeletor = ({ tab, setTab }) => {
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    getVenueConceptCities()
      .then((data) => setFilterOptions(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <React.Fragment>
      <select value={tab!=null ? tab : ""} onChange={(e) => setTab(e.target.value)}>
        <option value="">All</option>
        {filterOptions.map((item) => (
          <option key={item.id} value={item.city_name}>
            {item.city_name}
          </option>
        ))}
      </select>
    </React.Fragment>
  );
};

export default FilterSeletor;
