export const BUDGET = "BUDGET";
export const DOCUMENT = "DOCUMENT";
export const LOGISTICS = "LOGISTICS";

export const BUDGET_STATE_KEY = "budgets";
export const PRIVATE_DOCS_STATE_KEY = "privateDocs";
export const SHARED_DOCS_STATE_KEY = "sharedDocs";
export const LOGISTICS_STATE_KEY = "logistics";

export const SYNCFUSION_OPEN_FILE_URL =
  "https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open";
