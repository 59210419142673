import React, { useState } from 'react';
import { Row, Col, Form, Container } from 'react-bootstrap';
import Header from '../../components/header';
import MainRFP from '../../components/Main_RFP';
import BrandSearchBar from './brandsearchbar';
import Branding from './branding';
import Meeting from './Meeting';
import Footer from '../../components/footer';

const BrandingRFP = () => {
    const [ tab, setTab ] = useState("")
    return (
        <React.Fragment>
            <Header />
            <Container fluid className="px-0">
                <Row className="inner_width_div">
                    <Col xl={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
                        <Form className="mt-12"> 
                            <MainRFP setTab={setTab} />
                            <BrandSearchBar setTab={setTab} />
                            { 
                                tab === "Branding" ?
                                <div>
                                    <Branding setTab={setTab} />
                                </div> : 
                                tab === "Meeting" ?
                                <div>
                                    <Branding setTab={setTab} />
                                    <Meeting setTab={setTab} />
                                </div> : null
                            }
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </React.Fragment>
    )
}

export default BrandingRFP;
