import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { getRFPRequests } from "../../../server/Filter";

function useGetRfpRequests(args) {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getRFPRequests(args)
      .then((res) => {
        setData(res.results);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        alert.error("Something went wrong");
      });
  }, [alert, args]);

  return {
    isLoading,
    data,
  };
}

export default useGetRfpRequests;
