import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './index.scss';

const AddMore = () => {
    return (
        <React.Fragment>
            <Row>
            <Col 
                xl={12} lg={12} md={12} sm={12} xs={12} 
                className="px-0"
            >
                <div className="d-flex addmore_div">  
                    <Button className="Button">
                        Edit
                    </Button>
                    <Link to="/branding_materials">
                        <Button className="Button">
                            Add More
                        </Button>
                    </Link>
                    <Button className="Button">
                        Send Request
                    </Button>
                </div>
              </Col>
            </Row>
        </React.Fragment>
    )
}

export default AddMore;
