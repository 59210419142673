import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.scss";

const Production = () => {
  const production_sub_pages = [
    {
      source: "/assets/images/production/av-production-main.jpg",
      class: "production_card1",
      text1: "A/V &",
      text2: "Production",
      text: "I'm a paragraph. Double click here or click Edit Text to add some text of your own or to change the font. Tell your visitors a bit about your services",
      link: "/av_production",
    },
    {
      source: "/assets/images/production/branding-main.jpg",
      class: "production_card2",
      text1: "Branding",
      text2: "Material",
      text: "I'm a paragraph. Double click here or click Edit Text to add some text of your own or to change the font. Tell your visitors a bit about your services",
      link: "/branding_materials",
    },
    {
      source: "/assets/images/production/concept-design-main.jpg",
      class: "production_card3",
      text1: "Concept",
      text2: "Design",
      text: "I'm a paragraph. Double click here or click Edit Text to add some text of your own or to change the font. Tell your visitors a bit about your services",
      link: "/concept_designs",
    },
  ];
  const renderproduction_sub_page = (production_sub_page, index) => {
    return (
      <Col
        md={6}
        className={"production_congress " + production_sub_page.class}
        key={index}
      >
        <Card style={{ width: "30rem", border: "none" }}>
          <Link to={production_sub_page.link}>
            <Card.Img variant="top" src={production_sub_page.source} />
          </Link>
          <Card.Body className="text-style123">
            <Card.Title className="production-title1">
              <span className="font-weight-bold  production-details1 mr-2 text2">
                {production_sub_page.text1}
              </span>
              <span className=" font-weight-bold  production-details cart-border1 text2">
                {production_sub_page.text2}
              </span>
            </Card.Title>

            <Link to={production_sub_page.link}>
              <button className="explore_button123 text-white production-details">
                Explore More
              </button>
            </Link>
          </Card.Body>
        </Card>
      </Col>
    );
  };
  return (
    <React.Fragment>
      <Header />
      <Container
        className="event_banner px-0 position-relative"
        style={{
          backgroundImage:
            'url("/assets/images/production/production-banner.jpg")',
          backgroundColor: "rgb(37 50 190 / 50%)",
        }}
        fluid
      >
        <div
        // className="event_banner_image"
        // style={{ backgroundColor: "rgba(37, 50, 190, 0.4)" }}
        ></div>
        <div className="text-blocks">
          <p
            className="font-weight-bold  text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-200 mb-0"
            style={{ fontFamily: "'Poppins'" }}
          >
            Production
          </p>
        </div>
      </Container>
      <Container className="CustomizeContainer">
        <Row className="align-items-center text-center text2">
          {production_sub_pages.map(renderproduction_sub_page)}
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Production;
