import React from "react";
import { useFormikContext } from "formik";
import propTypes from "prop-types";

function FormikSubmitBtn({ className = "", label }) {
  const { isSubmitting, errors, touched } = useFormikContext();

  const isDisabled =
    (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) ||
    isSubmitting;

  return (
    <div className="d-flex align-items-center justify-content-end w-100">
      <button
        className={`btn-main-primary ${className} ${
          isDisabled ? "hasError" : ""
        }`}
        type="submit"
        disabled={isDisabled}
      >
        {isSubmitting ? "Loading..." : label}
      </button>
    </div>
  );
}

FormikSubmitBtn.propTypes = {
  className: propTypes.string,
  label: propTypes.string,
};

FormikSubmitBtn.defaultProps = {
  className: "",
  label: "Submit",
};

export default FormikSubmitBtn;
