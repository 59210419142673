import { createContext, useContext } from "react";

export const CongressContext = createContext({
  payload: null,
  setPayload: () => {},
  currentTab: 0,
  setCurrentTab: () => {},
  isAddCongress: false,
  congressDetails: null,
  setCongressDetails: () => {},
});

export const useCongressContext = () => {
  const contextFormData = useContext(CongressContext);

  return contextFormData;
};
