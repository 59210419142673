import React from "react";
import { MdBlock } from "react-icons/md";
import PageWrapper from "../../components/common/PageWrapper";

const Forbidden = () => {
  return (
    <PageWrapper bannerTitle="Forbidden">
      <div className="min-vh-50 d-flex flex-column justify-content-center align-items-center">
        <MdBlock className="text-danger" size={200} />
        <h3 className="pt-3">You donot have permission</h3>
      </div>
    </PageWrapper>
  );
};

export default Forbidden;
