/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import { useField } from "formik";
import CreatableSelect from "react-select/creatable";
import propTypes from "prop-types";

// STYLES & COMPONENTS
import {
  DEFAULT_COMPONENTS,
  multiSelectStyles,
} from "../../../styles/components/select-styles";
import "../../../styles/components/formik-field.scss";

const FormikCreatableSelect = ({
  name,
  defaultOptions,
  customClass,
  disabled,
  placeholder,
  menuPosition,
  menuPlacement,
  menuShouldBlockScroll,
  formatOptionLabel,
  onChange,
  onBlur,
  isGrid,
  isRequired,
  label,
}) => {
  const [options, setOptions] = useState(defaultOptions);
  const [innerValue, setInnerValue] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [field, meta, helpers] = useField(name || "");
  const { setValue, setTouched } = helpers;

  const { onBlur: onFieldBlur, value, ...restFieldProps } = field;
  const { touched, error } = meta;

  useEffect(() => {
    if (value) {
      setInnerValue(value);
    }
  }, [value]);

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      setInnerValue(selectedOption);
      setValue(selectedOption);

      if (onChange) onChange(selectedOption);
    }
  };

  const handleKeyDown = (e) => {
    if (!inputValue) return;

    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault();

      if (inputValue) {
        setInputValue("");
        const newOption = { value: inputValue, label: inputValue };
        const newValue = [...innerValue, newOption];
        setOptions([...options, newOption]);
        setInnerValue(newValue);
        setValue(newValue);
      }
    }
  };

  const handleBlur = (event) => {
    onFieldBlur(event);
    setTouched(true);
    if (onBlur) onBlur(event.target.value);
  };

  const isErrorField = touched && error;

  return (
    <div
      className={`field-wrapper ${
        isGrid ? "col-grid" : "col-full"
      } ${customClass}`}
      id={name}
    >
      {label && (
        <div className="field-label">
          <p className={`${isRequired ? "required" : ""}`}>{label}</p>
        </div>
      )}

      <div className="input-wrapper">
        <CreatableSelect
          {...restFieldProps}
          options={options}
          isMulti
          inputValue={inputValue}
          onChange={handleChange}
          onInputChange={(newValue) => setInputValue(newValue)}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          value={innerValue}
          classNamePrefix="react-select"
          isDisabled={disabled}
          disabled={disabled}
          placeholder={placeholder}
          menuPosition={menuPosition}
          menuPlacement={menuPlacement}
          menuShouldBlockScroll={menuShouldBlockScroll}
          formatOptionLabel={formatOptionLabel}
          components={{ ...DEFAULT_COMPONENTS }}
          styles={{
            ...multiSelectStyles,
            control: (base) => ({
              ...base,
              borderColor: isErrorField ? "#df0505" : "#ccc",
              "&:hover": { borderColor: isErrorField ? "#df0505" : "#ccc" },
            }),
            multiValue: (base) => ({
              ...base,
              backgroundColor: "#f2f2f2",
            }),
            multiValueLabel: (base) => ({
              ...base,
              color: "#000",
            }),
          }}
        />
      </div>

      {touched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
    </div>
  );
};

FormikCreatableSelect.propTypes = {
  name: propTypes.string.isRequired,
  defaultOptions: propTypes.array,
  customClass: propTypes.string,
  disabled: propTypes.bool,
  placeholder: propTypes.string,
  menuPosition: propTypes.string,
  menuPlacement: propTypes.string,
  menuShouldBlockScroll: propTypes.bool,
  formatOptionLabel: propTypes.func,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  isGrid: propTypes.bool,
  label: propTypes.string,
  isRequired: propTypes.bool,
};

FormikCreatableSelect.defaultProps = {
  defaultOptions: [],
  customClass: "",
  disabled: false,
  placeholder: "",
  menuPosition: "absolute",
  menuPlacement: "auto",
  menuShouldBlockScroll: false,
  formatOptionLabel: null,
  onChange: null,
  onBlur: null,
  isGrid: false,
  label: "",
  isRequired: false,
};

export default memo(FormikCreatableSelect);
