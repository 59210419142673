import React from "react";

const Input = ({ name, error, inputRef, textArea, ...rest }) => {
  return (
    <>
      {textArea ? (
        <textarea
          {...rest}
          name={name}
          id={name}
          rows="7"
          ref={inputRef}
          className={
            error
              ? "text_area form-control is-invalid"
              : " text_area form-control"
          }
        />
      ) : (
        <input
          {...rest}
          name={name}
          id={name}
          ref={inputRef}
          className={error ? "form-control is-invalid" : "form-control"}
        />
      )}
      {error && <div className="invalid-feedback text-left">{error}</div>}
    </>
  );
};

export default Input;
