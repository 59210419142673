import React from "react";
import { useFormikContext } from "formik";
import { useEventCoordinationContext } from "../../../context/EventCoordinationContext";
import useToggle from "../../../../../../components/common/custom-hooks/useToggle";
import FormikField from "../../../../../../shared/components/form/FormikField";
import FormikFileInput from "../../../../../../shared/components/form/FormikFileInput";
import { DocumentViewerPopup } from "../../../../../../components/common/document-viewer";
import ServiceQuestionController from "./ServiceQuestionController";
import RFPFormRecord from "../RFPFormRecord";
import { RFP_DETAILS_STATE_KEY } from "../../../utils/constants";

// Child
const ProductionFormRecord = ({ children, ...rest }) => (
  <RFPFormRecord
    {...rest}
    labelClassName="w-40"
    valueClassName="w-60"
    removeLeftSpace
  >
    {children}
  </RFPFormRecord>
);

// Parent
const Production = () => {
  const { values } = useFormikContext();
  const {
    [RFP_DETAILS_STATE_KEY]: { isEditing },
  } = useEventCoordinationContext();
  const { isOpen: isPreviewModalOpen, toggle: togglePreviewModal } =
    useToggle();

  const uploadedDesign =
    values.rfpproduction && values.rfpproduction.uploaded_design;

  return (
    <ServiceQuestionController
      questionText="Do you require Production?"
      flag="production_flag"
    >
      <ProductionFormRecord label="Upload Design">
        {isEditing ? (
          <FormikFileInput name="rfpproduction.uploaded_design" />
        ) : (
          <React.Fragment>
            {uploadedDesign ? (
              <React.Fragment>
                <DocumentViewerPopup
                  isOpen={isPreviewModalOpen}
                  toggle={togglePreviewModal}
                  fileURL={uploadedDesign.src}
                  fileName="RequestedDesign"
                />
                <p
                  className="mb-0 text-primary cursor-pointer"
                  onClick={togglePreviewModal}
                >
                  Click to view design
                </p>
              </React.Fragment>
            ) : (
              <p className="mb-0">No Design Uploaded</p>
            )}
          </React.Fragment>
        )}
      </ProductionFormRecord>
      <ProductionFormRecord label="Request Design">
        <FormikField
          name="rfpproduction.requested_design"
          rows={5}
          textArea
          disabled={!isEditing}
        />
      </ProductionFormRecord>
    </ServiceQuestionController>
  );
};

export default Production;
