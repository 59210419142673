import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Datepick from '../../generalinquiryes/form/datepick'; 
import ServeiceButtons from '../service_buttons';
import Text from '../text';

const MeetingRoom = ({ setTab }) => {
    const Meetings = [
        { for: "preffered_time", text: "Preffered Time:", type: "text", name: "preffered_time" },
        { for: "attendees", text: "Number of Attendees:", type: "text", name: "attendees" }
    ]
    const renderMeeting =  (Meeting, index) => {
        return(
            <React.Fragment key={index}>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor={Meeting.for}>{Meeting.text}</label>
                    </Col>
                    <Col className="px-0" md={6}>
                        <input name={Meeting.name} />
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Form>
                <Row className="form_main">
                    <Col className="px-0" md={3} xs={6}>
                        <label htmlFor="meeting_date">Meeting Date:</label>
                        <img
                            style={{ marginRight: '5px' }}
                            className="calendar float-right"
                            src={"assets/images/download.jpg"}
                            alt="Not Found"
                        />
                    </Col>
                    <Col className="px-0" xs={6}>
                        <Datepick name="meeting_date" />
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor="meeting_type">Meeting Type:</label>
                    </Col>
                    <Col className="px-0" md={6}>
                    <select name="meeting_type">
                            <option>Full-day Meeting</option>
                            <option>Half-day Meeting</option>
                        </select>
                    </Col>
                    <Col md={3}></Col>
                </Row>
                {Meetings.map(renderMeeting)}
                <Text heading="Requirements:" />
                <ServeiceButtons setTab={setTab} />
            </Form>
        </React.Fragment>
    )
}

export default MeetingRoom;
