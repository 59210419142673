import React from "react";
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import propTypes from "prop-types";
import { Link } from "react-router-dom";

// CUSTOM HOOKS
import { useGetTableHandlers } from "./custom-hooks/useGetTableHandlers";
import { useGetPropertiesTableContext } from "./context/PropertiesTableContext";

function PropertiesTable({ className, title, isCongress, isVerified }) {
  const { data } = useGetPropertiesTableContext();

  const {
    selected,
    handleClearSelected,
    handleConfirmAll,
    handleSelectAll,
    handleSelectRow,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    handleDelete,
  } = useGetTableHandlers();

  // CONSTANTS
  const isRowSelected = (id) => selected.indexOf(id) !== -1;
  const isAllSelected =
    data?.results?.length > 0 && selected.length === data?.results?.length;

  return (
    <div className={`${className} table-wrapper`}>
      <Paper elevation={4}>
        <Toolbar className="table-toolbar">
          {selected?.length > 0 ? (
            <Typography variant="subtitle1">
              {selected?.length} Selected
            </Typography>
          ) : (
            <h2 className="title">{title}</h2>
          )}

          {selected?.length > 0 && (
            <Box className="d-flex align-items-center gap-1">
              {!isVerified && (
                <button
                  type="button"
                  className="btn-main-secondary sm"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              )}

              <button
                type="button"
                className="btn-main-primary sm"
                onClick={handleClearSelected}
              >
                Clear
              </button>

              <button
                type="button"
                className="btn-main-primary sm"
                onClick={handleConfirmAll}
              >
                {isVerified ? "Unverify" : "Verify"}
              </button>
            </Box>
          )}
        </Toolbar>

        <TableContainer className="p-3">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isAllSelected && data?.results !== 0}
                    onChange={handleSelectAll}
                    color="primary"
                    indeterminate={selected?.length > 0 && !isAllSelected}
                  />
                </TableCell>

                <TableCell>
                  {isCongress ? "Congress Name" : `Venue Name`}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.results?.map((row) => {
                const isSelected = isRowSelected(row?.id);

                return (
                  <TableRow key={row?.id} hover selected={isSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(e) => handleSelectRow(e, row?.id)}
                        checked={isSelected}
                        color="primary"
                      />
                    </TableCell>

                    <TableCell>
                      {/* LINK TO MOVE ONTO FORMS SECTION */}
                      {row?.venue_name && (
                        <Link to={`/venues-management/edit/${row?.id}`}>
                          {row.venue_name}
                        </Link>
                      )}

                      {row?.conference_name && (
                        <Link to={`/congress-management/edit/${row?.id}`}>
                          {row.conference_name}
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={data?.count || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
    </div>
  );
}

PropertiesTable.propTypes = {
  title: propTypes.string.isRequired,
  statusHandler: propTypes.func,
  className: propTypes.string,
  isCongress: propTypes.bool,
  isVerified: propTypes.bool,
};

PropertiesTable.defaultProps = {
  statusHandler: async () => {},
  className: "",
  isCongress: false,
  isVerified: false,
};

export default PropertiesTable;
