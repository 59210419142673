export const RFP_DETAILS = "RFP_DETAILS";
export const QUOTATIONS = "QUOTATIONS";
export const SITE_INSPECTION = "SITE_INSPECTION";
export const EVENT_ROLES = "EVENT_ROLES";
export const HISTORY = "HISTORY";

export const RFP_DETAILS_STATE_KEY = "rfpDetails";
export const QUOTATIONS_STATE_KEY = "rfpQuotations";
export const SITE_INSPECTION_STATE_KEY = "rfpSiteInspections";
export const EVENT_ROLES_STATE_KEY = "rfpEventRoles";
export const HISTORY_STATE_KEY = "historyRecords";
export const USERS_OPTIONS_STATE_KEY = "usersOptions";

export const REQUESTED = "Requested";
export const REQUESTED_AGAIN = "Requested Again";
export const PENDING = "Pending";
export const EDITED = "Edited";
export const CONFIRMED = "Confirmed";
export const CANCELLED = "Cancelled";
export const FINISHED = "Finished";
