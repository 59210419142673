import React, { useState, useEffect } from "react";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import { useAlert } from "react-alert";
import { BiCheck, BiPencil } from "react-icons/bi";
import { FcCancel } from "react-icons/fc";
import useCustomReasonModal from "../../../components/common/custom-hooks/useCustomReasonModal";
import Tooltip from "../../../components/common/CustomTooltip";
import { useManageAccountContext } from "../context/ManageAccountContext";
import {
  getUser,
  getUsers,
  sendActivationRequest,
} from "../../../server/Filter";
import { ACTIVE, INACTIVE } from "./utils/constants";
import "./index.scss";

const Admin = () => {
  const alert = useAlert();
  const reason = useCustomReasonModal();
  const {
    switchToAddUserView,
    setSelectedUser,
    switchToEditUserView,
    startLoading,
    stopLoading,
  } = useManageAccountContext();

  const [isShowingActiveUsers, setIsShowingActiveUsers] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [users, setUsers] = useState([]);

  const fetchTargetUsers = (params = {}) => {
    getUsers({
      search: searchString || undefined,
      active: isShowingActiveUsers,
      ...params,
    })
      .then((data) => {
        setUsers(data);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          alert.show("No data found!", { type: "error" });
          setUsers([]);
        }
        console.log(err);
      });
  };

  useEffect(() => {
    fetchTargetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowingActiveUsers]);

  const updateUserStatus = async (activateUser, user) => {
    try {
      const providedComment = await reason({
        title: "Add Comment",
        fieldLabel: "Comment",
      });
      await sendActivationRequest({
        user: user.id,
        activate: activateUser,
        comment: providedComment,
      });
      alert.success("Request has been sent to admin successfully");
    } catch (error) {
      alert.error("Something went wrong in updating user status");
    }
  };

  const handleResetSearch = () => {
    setSearchString("");
    fetchTargetUsers({ search: undefined });
  };

  const handleUsersInActivity = async () => {
    setIsShowingActiveUsers((prevState) => !prevState);
  };

  const handleEditUser = async (targetUser) => {
    startLoading();
    try {
      const selectedUserData = await getUser(targetUser.id);
      setSelectedUser({
        id: selectedUserData.id,
        account_name: selectedUserData.profile.account || "",
        username: selectedUserData.username || "",
        email: selectedUserData.profile.email || "",
        full_name: selectedUserData.profile.full_name || "",
        profession: selectedUserData.profile.profession || "",
        contact: selectedUserData.profile.contact || "",
        role: selectedUserData.profile.role || "",
      });
      switchToEditUserView();
    } catch (error) {
      alert.error("Something went wrong in fetching user data");
    } finally {
      stopLoading();
    }
  };

  return (
    <React.Fragment>
      <p className="font-weight-bold text130" style={{ color: "#183765" }}>
        All Users
      </p>
      <Row className="admin_container2" style={{ borderRadius: "5px" }}>
        <Col xl={7} lg={7} md={6} className="admin_column1">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              fetchTargetUsers();
            }}
          >
            <Row>
              <Col
                xs={8}
                className="admin_column1_col"
                style={{ borderRight: "1px solid #dee2e6" }}
              >
                <input
                  type="text"
                  name="admin_search"
                  value={searchString}
                  placeholder="Search by Username"
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </Col>
              <Col
                xl={4}
                lg={4}
                md={4}
                xs={4}
                className="admin_column1_col d-flex gap-1"
              >
                <button type="submit" className="custom-secondary-button">
                  Search
                </button>
                {searchString && (
                  <Button variant="secondary" onClick={handleResetSearch}>
                    Reset
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xl={3} md={4} xs={7} className="admin_column2 admin_column1_col">
          <p className="text-lg-100 text-75 mb-2">All Users</p>
          <div className="d-flex">
            <input
              type="checkbox"
              name="admin_inactive"
              checked={!isShowingActiveUsers}
              onChange={handleUsersInActivity}
            />
            <label>View InActive Users</label>
          </div>
        </Col>
        <Col md={2} xs={5} className="admin_column3 admin_column1_col">
          <button
            className="custom-secondary-button"
            onClick={switchToAddUserView}
          >
            Create New User
          </button>
        </Col>
      </Row>
      <Row className="admin_table">
        {/* <p className="text-lg-125 text-100 mb-2">Company Name:</p> */}
        <Table
          bordered
          hover
          style={{
            borderCollapse: "unset",
            border: "unset",
            borderSpacing: "0px",
          }}
        >
          <thead>
            <tr>
              <td style={{ borderTopLeftRadius: "10px" }}>Username</td>
              <td>Email Address</td>
              <td>User Group</td>
              <td>Status</td>
              <td style={{ borderTopRightRadius: "10px" }}>Action</td>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} style={{ backgroundColor: "transparent" }}>
                <td>{user.username}</td>
                <td>{user.profile?.email}</td>
                <td>{user.profile?.role}</td>
                <td>{user.profile?.status}</td>
                <td className="d-flex gap-1">
                  <Tooltip text="Edit User">
                    <BiPencil
                      size={24}
                      className="cursor-pointer"
                      onClick={() => handleEditUser(user)}
                    />
                  </Tooltip>
                  {/* Statuses has been dealt seaprately because we do not have to 
                        send activate request on "Pending status". We do not need to
                        display anything when status is pending. */}
                  {user.profile?.status === ACTIVE && (
                    <Tooltip text="Deactivate User">
                      <FcCancel
                        size={24}
                        className="cursor-pointer"
                        onClick={() => updateUserStatus(false, user)}
                      />
                    </Tooltip>
                  )}
                  {user.profile?.status === INACTIVE && (
                    <Tooltip text="Activate User">
                      <BiCheck
                        size={24}
                        className="cursor-pointer"
                        onClick={() => updateUserStatus(true, user)}
                      />
                    </Tooltip>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </React.Fragment>
  );
};

export default Admin;
