import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import Header from "../../components/header";
import MedMainForm from "./med_main_form";
import Footer from "../../components/footer";
import ServiceTypesForms from "./service_types_forms";
import { submitMedicalCongressForm } from "../../server/Filter";

const MedicalForm = () => {
  const alert = useAlert();

  const [serviceForms, setServiceForms] = useState([]);
  const [disabledServiceTypes, setDisabledServiceTypes] = useState({
    flightDisabled: false,
    accomodateDisabled: false,
    registrationDisabled: false,
    transportationDisabled: false,
    foodDisabled: false,
  });

  const addServiceForms = () => {
    const forms = [...serviceForms];
    forms.push(ServiceTypesForms);
    setServiceForms(forms);
  };

  const deleteServiceForm = () => {
    const forms = [...serviceForms];
    forms.pop();
    setServiceForms(forms);
  };

  const { register, handleSubmit, control, errors, reset } = useForm({
    mode: "onTouched",
    reValidateMode: "onBlur",
    defaultValues: {
      registration: {
        categories: [{ category: "", num_delegates: "" }],
        remarks: "",
      },
    },
  });

  const [status, setStatus] = useState("");

  const onSubmit = async (data) => {
    // const values = { ...data, status };
    try {
      await submitMedicalCongressForm(data, status);
      alert.show("Form submitted successfully!", {
        type: "success",
      });
      reset();
    } catch (err) {
      console.log(err);
      alert.show("Something went wrong. Please try again.", {
        type: "error",
      });
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Container fluid className="px-0">
        <Row className="inner_width_div">
          <Col xl={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <MedMainForm
                addServiceForms={addServiceForms}
                register={register}
                control={control}
                errors={errors}
              />
              {serviceForms.map((ServiceTypeForm, index) => (
                <ServiceTypeForm
                  key={index}
                  addServiceForms={addServiceForms}
                  deleteServiceForm={deleteServiceForm}
                  disabledServiceTypes={disabledServiceTypes}
                  setDisabledServiceTypes={setDisabledServiceTypes}
                  setStatus={setStatus}
                  register={register}
                  control={control}
                  errors={errors}
                />
              ))}
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default MedicalForm;
