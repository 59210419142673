import React from 'react';
import './index.scss';

const Borders = () => {
    return (
        <div>
            
        </div>
    )
}

export default Borders;