import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { css } from "emotion";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import BasicInfo from "../components/basic_info";
import GuestRoom from "../components/guest_room";
import VenueSpace from "../components/venue_space";
import Restaurant from "../components/restaurant";
import Location from "../components/location";
import { addPartnerVenue, editPartnerVenue } from "../../../server/Filter";

const initialTab = "#fff";

const defaultVal = {
  venue_rooms: [
    {
      room_name: "",
      dimensions: "",
      area: "",
      banquet: "",
      reception: "",
      theatre: "",
      class_room: "",
      board_room: "",
      u_shape: "",
    },
  ],
  restaurants: [
    {
      is_outlet: false,
      restaurant_name: "",
      operating_hours: "",
      max_capacity: "",
      cuisine_type: "",
      location: "",
      description: "",
      restaurant_images: [],
    },
  ],
  venue_guest_rooms: [
    {
      room_name: "",
      num_rooms: "",
      bed_type: "twin",
      description: "",
      guest_room_images: [],
    },
  ],
};

function setValues(data) {
  const values = {
    id: data.id,
    property_logo2: data.property_logo2 ? [data.property_logo2] : [],
    venue_name: data.venue_name ? data.venue_name : "",
    country: data.country ? data.country : "",
    city: data.city ? data.city : "",
    venue_address: data.venue_address ? data.venue_address : "",
    partner_email: data.partner_email ? data.partner_email : "",
    guest_rooms: data.guest_rooms ? data.guest_rooms : "",
    meeting_rooms: data.meeting_rooms ? data.meeting_rooms : "",
    total_meeting_space: data.total_meeting_space
      ? data.total_meeting_space
      : "",
    maximum_capacity: data.maximum_capacity ? data.maximum_capacity : "",
    star_rating: data.star_rating ? data.star_rating : "",
    overview: data.overview ? data.overview : "",
    amenities: data.amenities ? data.amenities : "",
    meeting_room_notes: data.meeting_room_notes ? data.meeting_room_notes : "",
    ddr_rate_half_day: data.ddr_rate_half_day ? data.ddr_rate_half_day : "",
    ddr_rate_full_day: data.ddr_rate_full_day ? data.ddr_rate_full_day : "",
    vr_tour_available: data.vr_tour_available
      ? `${data.vr_tour_available}`
      : "false",
    vr_tour_url: data.vr_tour_url ? data.vr_tour_url : "",
    // city_center_distance: data.city_center_distance
    //   ? data.city_center_distance
    //   : "",
    // nearest_attractions: data.nearest_attractions
    //   ? data.nearest_attractions
    //   : "",
    nearest_airport: data.nearest_airport ? data.nearest_airport : "",
    lat_long: data.lat_long ? data.lat_long : "",
    latitude: data.latitude ? data.latitude : "",
    longitude: data.longitude ? data.longitude : "",
    venue_airports: data.venue_airports ? data.venue_airports : [],
    venue_public_transports: data.venue_public_transportations
      ? data.venue_public_transportations
      : [],
    thumbnail: data.thumbnail ? [data.thumbnail] : [],
    slider_images: data.venue_slider_images,
    meeting_room_images: data.venue_meeting_room_images
      ? data.venue_meeting_room_images
      : [],
    floor_plan_images: data.venue_floor_plan_images
      ? data.venue_floor_plan_images
      : [],
    venue_guest_rooms: data.venue_guest_rooms
      ? data.venue_guest_rooms?.sort(
          (a, b) => a.guest_room_id - b.guest_room_id
        )
      : [],
    venue_rooms: data.venue_rooms ? data.venue_rooms : [],
    restaurants: data.restaurants
      ? data.restaurants?.sort((a, b) => a.restaurant_id - b.restaurant_id)
      : [],
    disclaimer: data.disclaimer ? data.disclaimer : "",
  };

  return values;
}

const NewProperty = () => {
  const alert = useAlert();
  const history = useHistory();

  const [tab, setTab] = useState("");
  const [color1, setColor1] = useState("#FFAC33");
  const [color2, setColor2] = useState(initialTab);
  const [color3, setColor3] = useState(initialTab);
  const [color4, setColor4] = useState(initialTab);
  const [color5, setColor5] = useState(initialTab);

  const setStyle = (color1, color2, color3, color4, color5, tab) => {
    setColor1(color1);
    setColor2(color2);
    setColor3(color3);
    setColor4(color4);
    setColor5(color5);
    setTab(tab);
  };
  const tab1 = css`
    color: ${color1};
  `;
  const tab2 = css`
    color: ${color2};
  `;
  const tab3 = css`
    color: ${color3};
  `;
  const tab4 = css`
    color: ${color4};
  `;
  const tab5 = css`
    color: ${color5};
  `;

  const {
    handleSubmit,
    register,
    control,
    errors,
    trigger,
    formState,
    reset,
    clearErrors,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onBlur",
    defaultValues: defaultVal,
  });

  useEffect(() => {
    const { errors } = formState;
    if (
      errors?.venue_name ||
      errors?.venue_address ||
      errors?.partner_email ||
      errors?.city ||
      errors?.country ||
      errors?.star_rating
    ) {
      setStyle("#FFAC33", initialTab, initialTab, initialTab, initialTab, "");
    } else if (errors?.venue_rooms) {
      // prettier-ignore
      setStyle(initialTab, initialTab, "#FFAC33", initialTab, initialTab, "venue");
    }
  }, [formState]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [property, setProperty] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (data) => {
    if (!data.thumbnail || data.thumbnail?.length < 1) {
      setStyle("#FFAC33", initialTab, initialTab, initialTab, initialTab, "");
      window.scrollTo(0, 1100);
      return alert.show('"Hotel Gallery Thumbnail" is required!', {
        type: "error",
      });
    }
    if (!data.property_logo2 || data.property_logo2?.length < 1) {
      setStyle("#FFAC33", initialTab, initialTab, initialTab, initialTab, "");
      window.scrollTo(0, 1100);
      return alert.show('"Property Logo" is required!', {
        type: "error",
      });
    }
    if (submitted) {
      data.status = "pending";
    }
    try {
      setIsSubmitting(true);
      if (property) {
        const submittedForm = await editPartnerVenue(data, property.id);
        setProperty(setValues(submittedForm));
        reset(setValues(submittedForm));
      } else {
        const submittedForm = await addPartnerVenue(data);
        setProperty(setValues(submittedForm));
        reset(setValues(submittedForm));
      }
      setIsSubmitting(false);
      if (submitted) {
        alert.show("Property submitted successfully!", { type: "success" });
        setTimeout(() => history.push("/dashboard"), 1000);
      } else {
        alert.show("Property saved successfully!", { type: "success" });
      }
    } catch (err) {
      console.error(err);
      setStyle("#FFAC33", initialTab, initialTab, initialTab, initialTab, "");
      if (err.response?.status === 400) {
        Object.entries(err.response?.data).forEach(([key, value]) => {
          if (Array.isArray(value) && typeof value[0] === "string") {
            alert.show(`${key}: ${value[0]}`, { type: "error" });
          }
        });
      } else {
        alert.show("Something went wrong. Please try again later.", {
          type: "error",
        });
      }
      setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <p className="font-weight-bold text-lg-150 text-125 my-7">Add Property</p>
      <div className="d-xl-flex" style={{ backgroundColor: "#071F4E" }}>
        <div className="add_property_tab_lg1 d-md-flex">
          <div className="add_property_tab_sm">
            <button
              onClick={() => {
                if (submitted) {
                  setSubmitted(false);
                  clearErrors("disclaimer");
                }
                setStyle(
                  "#FFAC33",
                  initialTab,
                  initialTab,
                  initialTab,
                  initialTab,
                  ""
                );
              }}
              className={"add_property_tab_button " + tab1}
            >
              Basic Info
            </button>
          </div>
          <div className="add_property_tab_sm">
            <button
              onClick={() => {
                trigger();
                if (submitted) {
                  setSubmitted(false);
                  clearErrors("disclaimer");
                }
                setStyle(
                  initialTab,
                  "#FFAC33",
                  initialTab,
                  initialTab,
                  initialTab,
                  "guest"
                );
              }}
              className={"add_property_tab_button " + tab2}
            >
              Guest Rooms
            </button>
          </div>
          <div className="add_property_tab_sm">
            <button
              onClick={() => {
                trigger();
                if (submitted) {
                  setSubmitted(false);
                  clearErrors("disclaimer");
                }
                setStyle(
                  initialTab,
                  initialTab,
                  "#FFAC33",
                  initialTab,
                  initialTab,
                  "venue"
                );
              }}
              className={
                "add_property_tab_button add_property_tab_meeting_button " +
                tab3
              }
            >
              Meeting Venue Space
            </button>
          </div>
        </div>
        <div className="add_property_tab_lg2 d-md-flex">
          <div className="add_property_tab_sm">
            <button
              onClick={() => {
                trigger();
                if (submitted) {
                  setSubmitted(false);
                  clearErrors("disclaimer");
                }
                setStyle(
                  initialTab,
                  initialTab,
                  initialTab,
                  "#FFAC33",
                  initialTab,
                  "restaurant"
                );
              }}
              className={"add_property_tab_button " + tab4}
            >
              Restaurants & Outlet Stores
            </button>
          </div>
          <div className="add_property_tab_sm">
            <button
              onClick={() => {
                trigger();
                if (submitted) {
                  setSubmitted(false);
                  clearErrors("disclaimer");
                }
                setStyle(
                  initialTab,
                  initialTab,
                  initialTab,
                  initialTab,
                  "#FFAC33",
                  "location"
                );
              }}
              className={"add_property_tab_button border-0 " + tab5}
            >
              Location
            </button>
          </div>
        </div>
      </div>
      <div>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <BasicInfo
            setStyle={setStyle}
            hideComponent={tab !== ""}
            register={register}
            control={control}
            errors={errors}
            trigger={trigger}
            isSubmitting={isSubmitting}
          />
          <GuestRoom
            setStyle={setStyle}
            hideComponent={tab !== "guest"}
            register={register}
            control={control}
            property={property}
            setProperty={setProperty}
            isSubmitting={isSubmitting}
          />
          <VenueSpace
            setStyle={setStyle}
            hideComponent={tab !== "venue"}
            register={register}
            control={control}
            errors={errors}
            isSubmitting={isSubmitting}
          />
          <Restaurant
            setStyle={setStyle}
            hideComponent={tab !== "restaurant"}
            register={register}
            control={control}
            property={property}
            setProperty={setProperty}
            isSubmitting={isSubmitting}
          />
          <Location
            setStyle={setStyle}
            hideComponent={tab !== "location"}
            register={register}
            control={control}
            errors={errors}
            submitted={submitted}
          />
          {tab === "location" && (
            <>
              <div className="text-center mt-7">
                <hr />
                <p>
                  <strong>Important Note: </strong>
                  Before submitting property, please check all sections and make
                  sure that none of the image is in pending state.
                </p>
                <hr />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="Button_continue mt-5 mb-12"
                  disabled={isSubmitting}
                  onClick={() => setSubmitted(true)}
                >
                  {isSubmitting ? "Submitting..." : "Submit Property"}
                </button>
              </div>
            </>
          )}
        </Form>
      </div>
    </React.Fragment>
  );
};

export default NewProperty;
