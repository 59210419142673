import React, { useState } from "react";
import { useFormikContext } from "formik";
import DataModal from "./DataModal";
import DataCard from "./DataCard";

const FIELD_KEY = "rfpvenue";

const PrefferredVenues = () => {
  const { values, setFieldValue } = useFormikContext();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const toggleAddModal = () => setIsAddModalOpen((prevState) => !prevState);

  const handleAddNewVenue = (venueName) => {
    const addedVenue = {
      placeID: Math.random(),
      image: null,
      title: venueName,
      is_custom: true,
    };
    setFieldValue(FIELD_KEY, [...values[FIELD_KEY], addedVenue]);
  };

  const handleDelete = (selectedItemID) => {
    const filteredVenues = [...values[FIELD_KEY]].filter(
      (item) => item.placeID !== selectedItemID
    );
    setFieldValue(FIELD_KEY, filteredVenues);
  };

  return (
    <div className="w-100">
      <DataModal
        title="Add Preferred Hotel"
        isOpen={isAddModalOpen}
        toggle={toggleAddModal}
        onAdd={handleAddNewVenue}
      />
      <DataCard
        title="Preferred Hotels"
        items={values[FIELD_KEY]}
        buttonTitle="Add Hotel"
        onAddButtonClick={toggleAddModal}
        onDelete={handleDelete}
      />
    </div>
  );
};

export default PrefferredVenues;
