import React, { useState } from "react";
import { useField } from "formik";
import TextEditor from "./SimpleEditor";

function FormikTextEditor({
  name,
  className = "",
  onChange = null,
  onBlur = null,
  onEnter,
  disabled = false,
}) {
  const [editorValue, setEditorValue] = useState("");
  const [{ value: fieldValue }, { touched, error }, { setValue }] = useField(
    name || ""
  );

  const handleChange = (editorState) => {
    setEditorValue(editorState);
    if (onChange) onChange(editorState);
  };

  const handleBlur = (editorState) => {
    setValue(editorState);
    if (onBlur) onBlur(name, editorState);
  };

  const handleEnterClick = async (event) => {
    event.preventDefault();
    await setValue(editorValue);
    onEnter(editorValue);
  };

  const isErrorField = touched && error;

  return (
    <div className="form__form-group-input-wrap">
      <TextEditor
        wrapperClassName={`${isErrorField ? "error-field" : ""} ${className}`}
        value={fieldValue}
        onChange={disabled ? undefined : handleChange}
        onBlur={handleBlur}
        onEnter={onEnter ? handleEnterClick : undefined}
      />
      {touched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
    </div>
  );
}

export default FormikTextEditor;
