import { capitalize } from "lodash/string";

/* eslint-disable no-console */

/**
 *
 * @param {string} name
 * @returns {string} formatted name in the form of Test Name
 */
function formatString(name = "") {
  let separatedName = [];
  if (name.indexOf("_") > -1) separatedName = name.split("_");
  if (name.indexOf("-") > -1) separatedName = name.split("-");

  if (separatedName.length > 0) {
    const newName = separatedName.map((item) => capitalize(item));
    const formattedName = newName.join(" ");
    return formattedName;
  }
  return capitalize(name);
}

export default formatString;
