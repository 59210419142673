import React, { useState, useMemo } from "react";
import { useEventPlanningContext } from "../context/EventPlanningContext";
import useBudgetViewModeHandler from "./custom-hooks/useBudgetViewModeHandler";
import SimpleTable from "../../../../shared/components/table/SimpleTable";
import BudgetDetails from "./components/BudgetDetails";
import { BUDGET_STATE_KEY } from "../utils/constants";
import { budgetMainTableHeadings } from "./utils/headings";
import formatAmount from "../../../../utils/formatAmount";

const Budget = () => {
  const {
    data: { [BUDGET_STATE_KEY]: budgets },
  } = useEventPlanningContext();
  const [selectedBudget, setSelectedBudget] = useState(null);

  const { isMainMode, isDetailMode, switchToMainMode, switchToDetailMode } =
    useBudgetViewModeHandler();

  const handleBudgetRecordClick = (targetBudget) => {
    setSelectedBudget(targetBudget);
    switchToDetailMode();
  };

  const modifiedData = useMemo(
    () =>
      budgets.map((budget) => ({
        ...budget,
        formatted_estimated_budget: `${budget.currency}${formatAmount(
          budget.estimated_budget
        )}`,
        formatted_reconciled_budget: `${budget.currency}${formatAmount(
          budget.reconciled_budget
        )}`,
        formatted_savings: `${budget.currency}${formatAmount(budget.savings)}`,
      })),
    [budgets]
  );

  return (
    <div>
      {/* Budget Main View */}
      {isMainMode && (
        <SimpleTable
          headings={budgetMainTableHeadings}
          data={modifiedData}
          alternateText="There are no budgets"
          onRowClick={handleBudgetRecordClick}
          rounded
        />
      )}
      {/* Budget Detail View */}
      {isDetailMode && (
        <BudgetDetails
          selectedBudget={selectedBudget}
          switchToMainMode={switchToMainMode}
        />
      )}
    </div>
  );
};

export default Budget;
