/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import propTypes from "prop-types";
import "./index.scss";
import { useDebouncedCallback } from "use-debounce";

function FilterCounter({ label, minParamKey, maxParamKey }) {
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const { search, pathname } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const minParamValue = searchParams.get(minParamKey);
  const maxParamValue = searchParams.get(maxParamKey);

  useEffect(() => {
    setMinValue(minParamValue ?? "");

    setMaxValue(maxParamValue ?? "");
  }, [minParamValue, maxParamValue]);

  const handleDebounce = useDebouncedCallback((value, key) => {
    searchParams.delete("page");
    searchParams.set(key, value);

    history.push({
      pathname,
      search: searchParams.toString(),
    });
  }, 500);

  const handleMinChange = useCallback(
    (e) => {
      const { value } = e.target;

      if (value >= 0) {
        setMinValue(value);
        handleDebounce(value, minParamKey);
      }
    },
    [minValue, minParamKey]
  );

  const handleMaxChange = useCallback(
    (e) => {
      const { value } = e.target;

      if (value >= 0) {
        setMaxValue(value);
        handleDebounce(value, maxParamKey);
      }
    },
    [maxValue, maxParamKey]
  );

  return (
    <div className="filter-counter-container">
      <div>
        <p className="filter-label">{label}:</p>
      </div>

      <div className="form-wrapper">
        <input
          type="text"
          placeholder="Min"
          className="input-field"
          value={minValue}
          onChange={handleMinChange}
          // onBlur={handleBlur}
        />
        -
        <input
          type="text"
          placeholder="Max"
          className="input-field"
          value={maxValue}
          onChange={handleMaxChange}
          // onBlur={handleBlur}
        />
      </div>
    </div>
  );
}

FilterCounter.propTypes = {
  label: propTypes.string.isRequired,
  minParamKey: propTypes.string.isRequired,
  maxParamKey: propTypes.string.isRequired,
};

export default memo(FilterCounter);
