import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MultiStepProgressBar from "../../../components/common/stepper/MultiStepProgressBar";
import { useRFPFormContext } from "../context/RFPContext";

const FormStepper = () => {
  const { currentStep } = useRFPFormContext();

  return (
    <Container className="steps-wrapper">
      <Row className="justify-content-center">
        <Col sm={9}>
          <MultiStepProgressBar currentStep={currentStep} totalSteps={12} />
        </Col>
      </Row>
    </Container>
  );
};

export default FormStepper;
