import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import StarRatings from "react-star-ratings";

// CUSTOM HOOKS
import useGetCartHandlers from "../../../custom-hooks/useGetCartHandlers";
import { formatDecimalValue, getDummyVenueImage } from "../../../utils/helpers";

const HotelCard = ({ venue }) => {
  const { handleCongressCart, handleEventCart } = useGetCartHandlers(venue);

  const propertyLogo = useMemo(
    () => getDummyVenueImage(venue.property_logo, true),
    [venue]
  );

  return (
    <div className="venue-card shadow mt-0">
      <div className="cart-icon-wrapper">
        <img src="/assets/images/exhibition/icon.png" alt="Cart" />

        <div className="hover_div">
          <span className="event_span cursor-pointer" onClick={handleEventCart}>
            Events
          </span>

          <span
            className="cursor-pointer"
            onClick={() => handleCongressCart(propertyLogo, true)}
          >
            Congress
          </span>
        </div>
      </div>

      <NavLink to={`/venue_sourcing/venues/${venue?.id}`}>
        <div className="img-wrapper">
          <img src={propertyLogo} width="100%" alt="Card" />
        </div>

        <div className="content-div">
          <Row>
            <div className="col-8 venue-name p-0">
              <h4 className="text-title m-0">{venue?.venue_name}</h4>
            </div>

            <div className="col-4 venue-rating-wrapper pr-0">
              <StarRatings
                rating={venue?.star_rating || 0}
                starSpacing="0px"
                starDimension="18px"
                starRatedColor="#F2BB13"
                starEmptyColor="white"
              />
            </div>
          </Row>

          <Row className="info-details">
            <Row className="w-100">
              <Col md={5} className="d-flex pl-0 pr-2">
                <p>Guest Rooms: {+venue?.total_guest_room || "NA"} </p>
              </Col>

              <Col md={7} className="d-flex pl-0 pr-0 noWrap">
                <p>
                  {`Total Meeting Space: ${Math.round(
                    venue?.total_meeting_space
                  )} sq. m`}
                </p>
              </Col>
            </Row>

            <Row className="w-100">
              <Col md={5} className="d-flex pl-0 pr-2">
                <p>
                  {`Meeting Rooms: ${Math.round(venue?.total_meeting_room)}`}
                </p>
              </Col>

              <Col md={7} className="d-flex pl-0 pr-0 noWrap">
                <p>
                  Largest Meeting Room:{" "}
                  {`${formatDecimalValue(
                    venue?.largest_meeting_room,
                    0
                  )} sq. m`}
                </p>
              </Col>
            </Row>
          </Row>
        </div>
      </NavLink>
    </div>
  );
};

export default HotelCard;
