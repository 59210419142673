import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { addToEventCart, removeFromCart } from "../../../server/Filter";
import { increaseCartCount } from "../../../redux/actions/cartActions";
import "./index.scss";

const Destination = ({ data }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const { state } = useLocation();

  const handleCartIconClick = async () => {
    try {
      if (state && state.id) {
        await removeFromCart(state.modelName, state.modelID);
      }
      await addToEventCart("Destination", data.id);
      if (state && state.id) {
        history.replace({
          ...history.location,
          state: null,
        });
        history.push("/cart");
        alert.show(
          `
          ${state.descriptionFields.title}
          replaced with
          ${data.dest_city}, ${data.dest_country}
          `,
          {
            type: "success",
          }
        );
      } else {
        dispatch(increaseCartCount());
        alert.show(
          `${data.dest_city}, ${data.dest_country} successfully added to cart`,
          {
            type: "success",
          }
        );
      }
    } catch (err) {
      if (err.response) {
        alert.show(err.response.data, {
          type: "error",
        });
      }
    }
  };

  return (
    <div className="plan-card shadow">
      <div className="cart-div" onClick={handleCartIconClick}>
        <img src="/assets/images/exhibition/icon.png" alt="" />
      </div>
      <NavLink
        className="plan_decoration"
        to={`/destinations/${data.dest_city}`}
      >
        <div className="img-dev">
          <img src={data.thumbnail} width="100%" alt="" />
        </div>
        {/* <div className="content-div">
          <h5 className="text-title m-0">{data.dest_city?.toUpperCase()}</h5>
        </div> */}
      </NavLink>
    </div>
  );
};

export default Destination;
