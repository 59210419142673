/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

// COMPONENTS & UTILITIES
import FormikModernField from "../../../shared/components/form/FormikModernField";
import FormikCreatableSelect from "../../../shared/components/form/FormikCreatableSelect";
import FormikSubmitBtn from "../../../shared/components/form/FormikSubmitBtn";
import { getVenueLocationDetailsQuery } from "../../../services/venue-details";
import { locationInitValues, locationValSchema } from "../utilities/formUtils";
import {
  createVenueMutation,
  updateLocationMutation,
} from "../../../services/venue-management";
import { useGetFormContext } from "../context/FormContext";

function LocationTabPanel() {
  const history = useHistory();
  const alert = useAlert();
  const [initValues, setInitValues] = useState(locationInitValues);
  const { venueId, setPayload, payload } = useGetFormContext();

  useEffect(() => {
    (async () => {
      if (venueId) {
        const resp = await getVenueLocationDetailsQuery(venueId);

        if (resp) {
          const airports = resp?.venue_airports?.map((item) => ({
            distance: item?.distance || "",
          }));
          const parking = resp?.venue_parking_rates?.map((item) => ({
            label: item?.parking_type || "",
            value: item?.parking_type || "",
          }));

          setInitValues((prevState) => ({
            ...prevState,
            lat: resp?.latitude || "",
            long: resp?.longitude || "",
            airports: airports?.length > 0 ? airports : [{ distance: "" }],
            parking,
          }));
        }
      }
    })();
  }, [venueId]);

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={locationValSchema}
      onSubmit={async (values) => {
        if (venueId) {
          const resp = await updateLocationMutation(venueId, values);

          if (resp.data) {
            alert.success("Location details updated successfully");
            return;
          }

          alert.error("Something went wrong, please try again later.");
          return;
        }

        const body = { ...payload, ...values };
        setPayload(body);
        const resp = await createVenueMutation(body);

        if (resp.data) {
          alert.success("Venue created successfully");
          history.push("/venues-management");
          return;
        }

        alert.error("Something went wrong!");
      }}
    >
      {({ values }) => (
        <Form className="d-flex align-items-start is-grid">
          <FormikModernField
            label="Latitude"
            name="lat"
            type="number"
            isGrid
            isRequired
          />

          <FormikModernField
            label="Longitude"
            name="long"
            type="number"
            isGrid
            isRequired
          />

          <FormikCreatableSelect label="Parking" name="parking" />

          <FieldArray name="airports">
            {({ push, remove }) => (
              <>
                {values?.airports?.map((airport, index, arr) => (
                  <div
                    key={index}
                    className="d-flex align-items-start w-100 is-grid"
                  >
                    <FormikModernField
                      label="Distance from airport (km)"
                      name={`airports[${index}].distance`}
                      type="number"
                      isRequired
                    />

                    <div className="d-flex align-items-center align-self-center gap-1">
                      {arr?.length > 1 && (
                        <button
                          type="button"
                          className="btn-main-secondary sm"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </button>
                      )}

                      {arr.length - 1 === index && (
                        <button
                          type="button"
                          className="btn-main-primary sm"
                          onClick={() => push({ distance: "" })}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </FieldArray>

          <FormikSubmitBtn />
        </Form>
      )}
    </Formik>
  );
}

export default LocationTabPanel;
