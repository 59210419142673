export const EVENTS = "Events";
export const CONGRESS = "Congress";

export const THREE_STAR = "3-star";
export const FOUR_STAR = "4-star";
export const FIVE_STAR = "5-star";

export const SINGLE = "single";
export const DOUBLE = "double";
export const TRIPLE = "triple";
export const FAMILY = "family";

export const ROOM_ONLY = "room-only";
export const BED_AND_BREAKFAST = "bed-and-breakfast";
export const HALF_BOARD = "half-board";
export const FULL_BOARD = "full-board";

export const AIRPORT_TRANSFER = "airport-transfer";
export const DINNER_TRANSFER = "dinner-transfer";
export const CONGRESS_TRANSFER = "congress-transfer";
export const FULL_DAY_DISPOSAL = "full-day-disposal";
export const HALF_DAY_DISPOSAL = "half-day-disposal";
export const ONE_WAY = "one-way";
export const ROUND_TRIP = "round-trip";
export const HALF_DAY_SERVICE = "half-day-service";
export const FULL_DAY_SERVICE = "full-day-service";

export const BREAKFAST = "breakfast";
export const LUNCH = "lunch";
export const DINNER = "dinner";

export const HALF_DAY_TOUR = "half-day-tour";
export const FULL_DAY_TOUR = "full-day-tour";
export const OTHER = "other";

export const categoryOptions = [
  {
    label: "Events",
    value: EVENTS,
  },
  {
    label: "Congress and Exibition",
    value: CONGRESS,
  },
];

export const choiceOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const hotelRatingOptions = [
  { value: THREE_STAR, label: THREE_STAR },
  { value: FOUR_STAR, label: FOUR_STAR },
  { value: FIVE_STAR, label: FIVE_STAR },
];

export const occupancyOptions = [
  { value: SINGLE, label: "Single" },
  { value: DOUBLE, label: "Double" },
  { value: TRIPLE, label: "Triple" },
  { value: FAMILY, label: "Family" },
];

export const mealPlanOptions = [
  { value: ROOM_ONLY, label: "Room Only" },
  { value: BED_AND_BREAKFAST, label: "Bed and BreakFast" },
  { value: HALF_BOARD, label: "Half Board" },
  { value: FULL_BOARD, label: "Full Board" },
];

export const transportCategoryOptions = [
  { value: AIRPORT_TRANSFER, label: "Airport Transfer" },
  { value: DINNER_TRANSFER, label: "Dinner Transfer" },
  { value: CONGRESS_TRANSFER, label: "Congress Transfer" },
  { value: FULL_DAY_DISPOSAL, label: "Full Day Disposal" },
  { value: HALF_DAY_DISPOSAL, label: "Half Day Disposal" },
];

export const serviceTypeOptions = [
  { value: ONE_WAY, label: "One Way" },
  { value: ROUND_TRIP, label: "Round Trip" },
  { value: HALF_DAY_SERVICE, label: "Half-day Service" },
  { value: FULL_DAY_SERVICE, label: "Full-day Service" },
];

export const fAndBArrangementCategortOptions = [
  { value: BREAKFAST, label: "Breakfast" },
  { value: LUNCH, label: "Lunch" },
  { value: DINNER, label: "Dinner" },
];

export const tourEntertainmentCategortOptions = [
  { value: HALF_DAY_TOUR, label: "Half Day Tour" },
  { value: FULL_DAY_TOUR, label: "Full Day Tour" },
  { value: OTHER, label: "Others" },
];
