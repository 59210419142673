import React, { useEffect, useState, createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import MiniLoader from "../../../../components/common/MiniLoader";
import {
  getRfpDetails,
  getRFPQuotations,
  getRFPSiteInspections,
  getRFPEventRoles,
  getRFPHistory,
  getAllUsersOptionsData,
} from "../../../../server/Filter";
import { saveRFPDetailsData } from "../../../../redux/actions/productLaunchActions";
import {
  getPromisesValues,
  getPromiseValue,
} from "../../../../utils/getPromiseValue";
import {
  RFP_DETAILS_STATE_KEY,
  QUOTATIONS_STATE_KEY,
  SITE_INSPECTION_STATE_KEY,
  EVENT_ROLES_STATE_KEY,
  HISTORY_STATE_KEY,
  USERS_OPTIONS_STATE_KEY,
  REQUESTED,
  CANCELLED,
  CONFIRMED,
  FINISHED,
} from "../utils/constants";
import {
  ADMIN_ACCESS_ROLE,
  OWNER_ACCESS_ROLE,
  PLANNER_ACCESS_ROLE,
} from "../../utils/constants";

export const EventCoordinationContext = createContext(null);

export const useEventCoordinationContext = () =>
  useContext(EventCoordinationContext);

const EventCoordinationProvider = ({ children }) => {
  const { rfpID } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [coordinationData, setCoordinationData] = useState({});
  const [isEditingRFP, setIsEditingRFP] = useState(false);

  const fetchCoordinationData = async () => {
    try {
      // Separate calls are made just becuase rfpDetailsResponse doesnot contain Array response whereas
      // all other responses does. getPromisesValues() function takes responsesArray as input and return
      // empty array against each one which is not resolved
      const [rfpDetailsRequestResponse, ...requestsWithArrayResponses] =
        await Promise.allSettled([
          getRfpDetails(rfpID),
          getRFPQuotations(rfpID),
          getRFPSiteInspections(rfpID),
          getRFPEventRoles(rfpID),
          getRFPHistory(rfpID),
          getAllUsersOptionsData(),
        ]);
      const rfpDetailsResponse = getPromiseValue(rfpDetailsRequestResponse, {});
      const [
        rfpQuotationsResponse,
        rfpSiteInspectionsResponse,
        rfpEventRolesResponse,
        rfpHistoryResponse,
        usersOptionsDataResponse,
      ] = getPromisesValues(requestsWithArrayResponses);
      const modifiedRFPDetails = {
        ...rfpDetailsResponse,
        isEventAdmin:
          rfpDetailsResponse.rfp_access.event_role === ADMIN_ACCESS_ROLE,
        isEventOwner:
          rfpDetailsResponse.rfp_access.event_role === OWNER_ACCESS_ROLE,
        isEventPlanner:
          rfpDetailsResponse.rfp_access.event_role === PLANNER_ACCESS_ROLE,
        isRequested: rfpDetailsResponse.request_status === REQUESTED,
        isConfirmed: rfpDetailsResponse.request_status === CONFIRMED,
        isCancelled: rfpDetailsResponse.request_status === CANCELLED,
        isFinished: rfpDetailsResponse.request_status === FINISHED,
      };
      dispatch(saveRFPDetailsData(modifiedRFPDetails));
      setCoordinationData((prevState) => ({
        ...prevState,
        [RFP_DETAILS_STATE_KEY]: modifiedRFPDetails,
        [QUOTATIONS_STATE_KEY]: rfpQuotationsResponse,
        [SITE_INSPECTION_STATE_KEY]: rfpSiteInspectionsResponse,
        [EVENT_ROLES_STATE_KEY]: rfpEventRolesResponse,
        [HISTORY_STATE_KEY]: rfpHistoryResponse,
        [USERS_OPTIONS_STATE_KEY]: usersOptionsDataResponse,
      }));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCoordinationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startEditingRFP = () => setIsEditingRFP(true);
  const stopEditingRFP = () => setIsEditingRFP(false);

  const updateRFPQuotations = () => {
    getRFPQuotations(rfpID).then((res) =>
      setCoordinationData((prevState) => ({
        ...prevState,
        [QUOTATIONS_STATE_KEY]: res,
      }))
    );
  };

  const updateSiteInspections = () => {
    getRFPSiteInspections(rfpID).then((res) =>
      setCoordinationData((prevState) => ({
        ...prevState,
        [SITE_INSPECTION_STATE_KEY]: res,
      }))
    );
  };

  const updateEventRoles = () => {
    getRFPEventRoles(rfpID).then((res) =>
      setCoordinationData((prevState) => ({
        ...prevState,
        [EVENT_ROLES_STATE_KEY]: res,
      }))
    );
  };

  return (
    <EventCoordinationContext.Provider
      value={{
        data: coordinationData,
        setData: setCoordinationData,
        modifiers: {
          updateRFPQuotations,
          updateSiteInspections,
          updateEventRoles,
        },
        [RFP_DETAILS_STATE_KEY]: {
          isEditing: isEditingRFP,
          startEditing: startEditingRFP,
          stopEditing: stopEditingRFP,
        },
      }}
    >
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <MiniLoader />
        </div>
      ) : (
        children
      )}
    </EventCoordinationContext.Provider>
  );
};

export default EventCoordinationProvider;
