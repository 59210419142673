import axios from "axios";
import { createMessage, returnErrors } from "./messagesActions";
import { API_URL } from "../../constants";

export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const UPDATE_USER = "UPDATE_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get toke from state
  const { token } = getState().auth;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
};

// CHECK TOKEN & LOAD USER
export const loadUser = () => async (dispatch, getState) => {
  // User Loading
  dispatch({
    type: USER_LOADING,
  });

  try {
    const res = await axios.get(`${API_URL}/auth/user`, tokenConfig(getState));
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    // dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// LOGIN USER
export const login = (values) => async (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const { account, username, password } = values;
  const body = JSON.stringify({
    account,
    username,
    password,
  });

  try {
    const res = await axios.post(`${API_URL}/auth/login`, body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// LOGOUT USER
export const logout = () => async (dispatch, getState) => {
  try {
    await axios.post(`${API_URL}/auth/logout`, null, tokenConfig(getState));
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (err) {
    // dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CHANGE PASSWORD
export const changePassword = (values) => async (dispatch, getState) => {
  // Request Body
  const { password } = values;
  const body = JSON.stringify({
    password,
  });

  try {
    await axios.patch(
      `${API_URL}/auth/changePassword`,
      body,
      tokenConfig(getState)
    );
    dispatch(
      createMessage({
        message: "Password Updated",
      })
    );
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const updateUserAction = (values) => ({
  type: UPDATE_USER,
  payload: values,
});
