/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../components/common/TabPanel";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

// COMPONENTS & ACTIONS & STYLES
import RegistrationTabPanel from "./components/RegistrationTabPanel";
import TravelInformationTabPanel from "./components/TravelInformationTabPanel";
import CongressOverViewTabPanel from "./components/CongressOverviewTabPanel";
import "./index.scss";
import { getCongressCardDetailsQuery } from "../../services/congress-details";
import PageWrapper from "./components/PageWrapper";
import { getCongressBannerImage } from "./utilities/helpers";

const MedicalCongresss = () => {
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState(0);
  const [congresDetails, setcongresDetails] = useState();

  useEffect(() => {
    (async () => {
      const basicDetailsResp = await getCongressCardDetailsQuery(id);
      setcongresDetails(basicDetailsResp);
    })();
  }, []);
  const handleChangeTab = (e, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <PageWrapper
      bannerTitle={congresDetails?.conference_name}
      // bannerImgSrc={`url(${
      //   congresDetails?.banner_image || "/assets/images/events/about-banner.jpg"
      // })`}
      bannerImgSrc={`url(${getCongressBannerImage(
        congresDetails?.banner_image
      )})`}
      congresDetails={congresDetails}
    >
      <Container>
        <Tabs
          className="venue-tabs"
          value={currentTab}
          onChange={handleChangeTab}
          TabIndicatorProps={{ hidden: true }}
        >
          <Tab className="venue-tab" label="Congress Overview" wrapped />

          <Tab className="venue-tab" label="Registration" wrapped />

          <Tab className="venue-tab" label="Travel Information" wrapped />
        </Tabs>
      </Container>

      <div className="tab-panel-wrapper">
        <TabPanel tabValue={currentTab} index={0}>
          <CongressOverViewTabPanel congresDetails={congresDetails} />
        </TabPanel>

        <TabPanel tabValue={currentTab} index={1}>
          <RegistrationTabPanel congresDetails={congresDetails} />
        </TabPanel>

        <TabPanel tabValue={currentTab} index={2}>
          <TravelInformationTabPanel congresDetails={congresDetails} />
        </TabPanel>
      </div>
    </PageWrapper>
  );
};

export default MedicalCongresss;
