import React from "react";
import { useFormikContext } from "formik";
import { useEventCoordinationContext } from "../../../context/EventCoordinationContext";
import FormikField from "../../../../../../shared/components/form/FormikField";
import ServiceQuestionController from "./ServiceQuestionController";
import RFPFormRecord from "../RFPFormRecord";
import { RFP_DETAILS_STATE_KEY } from "../../../utils/constants";

// Child
const VisaAssistanceFormRecord = ({ children, ...rest }) => (
  <RFPFormRecord
    {...rest}
    labelClassName="w-40"
    valueClassName="w-60"
    removeLeftSpace
  >
    {children}
  </RFPFormRecord>
);

// Parent
const VisaAssistance = () => {
  const { values } = useFormikContext();
  const {
    [RFP_DETAILS_STATE_KEY]: { isEditing },
  } = useEventCoordinationContext();

  return (
    <ServiceQuestionController
      questionText="Do you require Visa Assistance?"
      flag="visa_assistance_flag"
    >
      <VisaAssistanceFormRecord
        label="Nationality"
        value={values.rfpvisaassistance.nationality}
      >
        <FormikField
          name="rfpvisaassistance.nationality"
          placeholder="Nationality"
          disabled={!isEditing}
        />
      </VisaAssistanceFormRecord>
      <VisaAssistanceFormRecord
        label="Remarks"
        value={values.rfpvisaassistance.remarks}
      >
        <FormikField
          name="rfpvisaassistance.remarks"
          placeholder="Remarks"
          disabled={!isEditing}
          textArea
        />
      </VisaAssistanceFormRecord>
    </ServiceQuestionController>
  );
};

export default VisaAssistance;
