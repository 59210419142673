/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Table } from "react-bootstrap";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useAlert } from "react-alert";
import propTypes from "prop-types";

import FormikModernField from "../../../shared/components/form/FormikModernField";
import FormikSubmitBtn from "../../../shared/components/form/FormikSubmitBtn";
import FormikModrenTextEditor from "../../../shared/components/form/FormikModrenTextEditor";
import FormikCreatableSelect from "../../../shared/components/form/FormikCreatableSelect";
import {
  overviewFormInitValues,
  overviewFormValSchema,
} from "../utilities/formUtils";
import {
  getCongressBasicDetailsQuery,
  getCongressCountryList,
  updateCongressOverviewForm,
} from "../../../services/congress-details";
import { getOverviewFormValues } from "../utilities/helpers";
import { useCongressContext } from "../context/CongressContext";
import FormikFileGalleryField from "../../../shared/components/form/FormikFileGalleryField";
import FormikModernSelect from "../../../shared/components/form/FormikModernSelect";
import useFetchRequest from "../../../components/common/custom-hooks/useFetchRequest";
import { useTransformCountryList } from "../customHooks/useTransformer";

function CongressOverviewForm({ congressId }) {
  const alert = useAlert();
  const [initialValues, setInitValues] = useState(overviewFormInitValues);
  const { setCurrentTab, setPayload, isAddCongress, congressDetails } =
    useCongressContext();

  const { data } = useFetchRequest(getCongressCountryList);
  const countryOptions = useTransformCountryList(data);

  useEffect(() => {
    (async () => {
      if (congressId && congressDetails) {
        const newValues = await getOverviewFormValues(congressDetails);

        setInitValues((prevState) => ({
          ...prevState,
          ...newValues,
        }));
      }
    })();
  }, [congressId, congressDetails]);

  return (
    <div className="congress-form-wrapper">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={overviewFormValSchema}
        onSubmit={async (values) => {
          if (!isAddCongress) {
            const updateDataResp = await updateCongressOverviewForm(
              congressId,
              values
            );
            console.log(values);

            if (updateDataResp.status === 400) {
              alert.error("Error Updating Basic Info");
              return;
            }

            const getUpdateResp = await getCongressBasicDetailsQuery(
              congressId
            );
            const newValues = getOverviewFormValues(getUpdateResp);

            setInitValues((prevState) => ({
              ...prevState,
              ...newValues,
            }));

            alert.success("Congress Overview Updated Successfully");
            return;
          }

          setPayload((prevState) => ({ ...prevState, ...values }));
          setCurrentTab(1);
        }}
      >
        {() => (
          <Form className="d-flex align-items-start is-grid">
            <FormikModernField label="Name" name="conference_name" />

            <div className="w-100">
              <p className="mb-1 required">Banner Image</p>

              <FormikFileGalleryField name="banner_image" multiple={false} />
            </div>

            <div className="w-100">
              <p className="mb-1">Thumbnail Image</p>

              <FormikFileGalleryField name="thumbnail" multiple={false} />
            </div>

            <FormikModrenTextEditor
              name="description"
              isRequired
              label="Description"
            />

            <div className="congress-content-label">key Dates</div>

            <Table className="venue-space-container">
              <TableHead className="table-head venue-table">
                <TableRow className="table-row">
                  <TableCell className="wrap" align="center">
                    Registrations Open
                  </TableCell>
                  <TableCell className="wrap" align="center">
                    Registration Ends
                  </TableCell>
                  <TableCell className="wrap" align="center">
                    Event Start Date
                  </TableCell>
                  <TableCell className="wrap" align="center">
                    Event End Date
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow className="venue-table">
                  <TableCell align="center">
                    <FormikModernField
                      name="registration_open_date"
                      type="date"
                    />
                  </TableCell>

                  <TableCell align="center">
                    <FormikModernField
                      name="registration_close_date"
                      type="date"
                    />
                  </TableCell>

                  <TableCell align="center">
                    <FormikModernField name="start_date" type="date" />
                  </TableCell>

                  <TableCell align="center">
                    <FormikModernField name="end_date" type="date" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <FormikCreatableSelect
              name="target_audience"
              label="Target Audience"
            />

            <FormikCreatableSelect name="specialities" label="Specialities" />

            <FormikModernField label="Credits" name="credits" />

            <FormikModernSelect
              label="Country"
              name="country"
              options={countryOptions}
            />

            <FormikModernField
              label=" More Information Link"
              name="more_information_link"
              isRequired
            />

            <FormikModernField
              label="Adress"
              name="address"
              isRequired
              type="textarea"
            />

            <FormikSubmitBtn label={congressId ? "Next" : undefined} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

CongressOverviewForm.propTypes = {
  congressId: propTypes.string,
};

CongressOverviewForm.defaultProps = {
  congressId: null,
};

export default CongressOverviewForm;
