import React from 'react';
import { Row, Col } from 'react-bootstrap';

const GiftSearchBar = ({ tab, setTab }) => {
    const selections = [
        { text: "", value: "" },
        { text: "Souvenirs", value: "Souvenirs" },
        { text: "Meeting Room", value: "Meeting" },
    ]
    return (
        <React.Fragment>
            <Row 
                className="form_main gen_inquiry_selectbar" 
                style={{ clear: 'both' }}
            >
                    <Col className="px-0" md={3}>
                        <label htmlFor="service_type">
                            SERVICE TYPE:
                        </label>
                    </Col>
                    <Col className="px-0" md={6}>
                        <select
                            value={tab}
                            onChange={e => setTab(e.target.value)}>
                                {selections.map((o, index) => (
                                    <option 
                                        key={index} 
                                        value={o.value}
                                        hidden = {( o.value === "" ? 'hidden' : "" )}
                                    >{o.text}
                                    </option>
                                ))}
                        </select>
                    </Col>
                    <Col md={3}></Col>
                </Row>
        </React.Fragment>
    )
}

export default GiftSearchBar;
