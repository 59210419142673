import React, { useEffect, useMemo } from "react";
import useBudgetDetails from "../custom-hooks/useBudgetDetails";
import SimpleTable from "../../../../../shared/components/table/SimpleTable";
import Modal from "../../../../../components/common/CustomModal";
import BackButton from "../../../../../components/common/BackButton";
import BudgetItemDetails from "./BudgetItemDetails";
import { budgetDetailTableHeadings } from "../utils/headings";
import formatAmount from "../../../../../utils/formatAmount";

const BudgetDetails = ({ selectedBudget, switchToMainMode }) => {
  const {
    budgetDetails,
    selectedBudgetItem,
    showSubTotal,
    setShowSubTotal,
    isBudgetItemDetailModalOpen,
    toggleBudgetItemDetailModal,
    fetchBudgetDetails,
    isBudgetDetailsLoading,
    formattedBudgetDetailsData,
    budgetItemsTotal,
  } = useBudgetDetails();

  const budgetCurrency = useMemo(
    () => budgetDetails.currency || "AED",
    [budgetDetails]
  );

  useEffect(() => {
    if (selectedBudget && selectedBudget.id)
      fetchBudgetDetails(selectedBudget.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBudget]);

  const handleBudgetItemClick = (targetItem) => {
    toggleBudgetItemDetailModal(targetItem);
  };

  return (
    <div>
      <Modal
        isOpen={isBudgetItemDetailModalOpen}
        toggle={() => toggleBudgetItemDetailModal(null)}
        heading="Budget Item Details"
        bodyClassName="p-5"
        size="xl"
      >
        <BudgetItemDetails selectedBudgetItem={selectedBudgetItem} />
      </Modal>
      <BackButton className="mb-10" onClick={switchToMainMode}>
        Back to Budgets
      </BackButton>
      <SimpleTable
        headings={budgetDetailTableHeadings}
        data={formattedBudgetDetailsData}
        isLoading={isBudgetDetailsLoading}
        alternateText="There are no budgets items"
        groupedExtraHeadings={
          showSubTotal ? budgetDetailTableHeadings.slice(-3) : []
        }
        renderCustomRow={(record) => (
          <tr
            className="cursor-pointer"
            onClick={() => handleBudgetItemClick(record)}
          >
            <td align="left">
              <pre>{record.formatted_description}</pre>
            </td>
            <td align="center" className="v-align-middle">
              {record.formatted_currency}
            </td>
            <td align="center" className="v-align-middle">
              {record.formatted_estimated_budget}
            </td>
            <td align="center" className="v-align-middle">
              {record.formatted_reconciled_budget}
            </td>
            <td align="center" className="v-align-middle">
              {record.formatted_savings}
            </td>
          </tr>
        )}
        customRows={
          <tr className="totals-row">
            <td align="left">Grand Total</td>
            <td align="center">{budgetCurrency}</td>
            <td align="center">
              {budgetCurrency}
              {formatAmount(budgetItemsTotal.estimated)}
            </td>
            <td align="center">
              {budgetCurrency}
              {formatAmount(budgetItemsTotal.reconciled)}
            </td>
            <td align="center">
              {budgetCurrency}
              {formatAmount(budgetItemsTotal.savings)}
            </td>
          </tr>
        }
        rounded
      />
      {selectedBudget && (
        <label className="d-flex">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={showSubTotal}
            onChange={(e) => setShowSubTotal(e.target.checked)}
          />
          <span className="pl-3">Show Sub Total</span>
        </label>
      )}
    </div>
  );
};

export default BudgetDetails;
