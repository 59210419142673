/* eslint-disable react/prop-types */

import React from "react";
import { connect } from "formik";

function keyify(errors) {
  if (errors && typeof errors === "object") {
    const obj = Object.keys(errors).reduce((acc, valKey) => {
      if (Array.isArray(errors[valKey])) {
        errors[valKey].forEach((val, idx) => {
          if (typeof val === "object" && errors[valKey] !== null) {
            Object.keys(val).forEach((item) => {
              acc.push(`${valKey}.${idx}.${item}`);
            });
          } else {
            acc.push(valKey);
          }
        });
      } else if (
        typeof errors[valKey] === "object" &&
        errors[valKey] !== null
      ) {
        Object.keys(errors[valKey]).forEach((item) => {
          acc.push(`${valKey}.${item}`);
        });
      } else {
        acc.push(`${valKey}`);
      }
      return acc;
    }, []);
    return obj;
  }
  return [];
}

class ErrorFocus extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    if (isSubmitting) {
      const keys = keyify(errors);

      if (keys.length > 0 && !isValidating) {
        const idSelector = `[id="${keys[0]}"]`;
        const nameSelector = `[name="${keys[0]}"]`;
        let errorElement = document.querySelector(nameSelector);
        if (errorElement) {
          if (errorElement.type === "hidden") {
            errorElement = document.querySelector(idSelector);
            errorElement.scrollIntoView({ inline: "end" });
            window.scrollBy(0, -80);
          }
          errorElement.focus();
        }
      }
    }
  }

  render() {
    return null;
  }
}

export default connect(ErrorFocus);
