import React, { useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

function UploadFileForm({ toggleModal, onSubmit }) {
  const [documents, setDocuments] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    toggleModal();
    onSubmit(documents);
  };

  return (
    <Form onSubmit={handleSubmit} className="file-browser-docs-upload-form">
      <Row className="my-5">
        <Col md={12} className="px-0">
          <label for="file-upload" className="custom-file-upload">
            <CloudUploadIcon className="mr-1" /> Select File
          </label>
          <input
            type="file"
            id="file-upload"
            className="w-100 d-block"
            onChange={(e) => setDocuments(e.target.files)}
            required
            multiple
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col className="d-flex justify-content-start px-0">
          <Button type="submit" className="submit-btn px-5">
            Upload
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default UploadFileForm;
