import http from "./httpService";

export const getCongressBasicDetailsQuery = async (congressId) => {
  const response = await http.get(`/conferences/detail/${congressId}/`, {
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const searchCongressQuery = async () => {
  const response = await http.get(`/search_conference/`, {
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const getCongressCardsQuery = async (params) => {
  const response = await http.get(`/search_conference/`, {
    headers: http.getTokenHeaders(),
    params: params,
  });

  return response.data;
};

export const getCongressCardDetailsQuery = async (congressId) => {
  const response = await http.get(`/conferences/detail/${congressId}/`, {
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const updateCongressOverviewForm = async (id, payload) => {
  const {
    specialities,
    target_audience,
    thumbnail,
    banner_image,
    ...resetPayload
  } = payload;
  const formData = new FormData();

  Object.entries(resetPayload).forEach(([key, value]) => {
    formData.append(key, value);
  });

  if (specialities?.length > 0) {
    specialities.forEach((item, idx) => {
      formData.append(`specialities[${idx}][speciality]`, item?.value);
    });
  } else {
    formData.append(`specialities`, []);
  }

  if (target_audience?.length > 0) {
    target_audience.forEach((item, idx) => {
      formData.append(`target_audience[${idx}]`, item?.value);
    });
  } else {
    formData.append(`target_audience`, []);
  }

  if (thumbnail?.length > 0) {
    formData.append("thumbnail", thumbnail[0]);
  } else {
    formData.append("thumbnail", "");
  }

  if (banner_image?.length > 0) {
    formData.append("banner_image", banner_image[0]);
  } else {
    formData.append("banner_image", "");
  }

  const response = await http.put(`/conferences/detail/${id}/`, formData, {
    headers: http.getTokenHeaders(),
  });

  return response;
};

export const updateCongressRegistrationForm = async (id, payload) => {
  const response = await http.put(`/conferences/detail/${id}/`, payload, {
    headers: http.getTokenHeaders(),
  });

  return response;
};

export const updateTravelInformationForm = async (id, payload) => {
  const formData = new FormData();
  const { hotels } = payload;

  if (hotels?.length > 0) {
    for (let i = 0; i < hotels.length; i++) {
      if (hotels[i].hotel_id) {
        formData.append(`hotels[${i}][hotel_id]`, hotels[i].hotel_id);
      }

      formData.append(`hotels[${i}][hotel_name]`, hotels[i].hotel_name);
      formData.append(`hotels[${i}][distance]`, hotels[i].distance);
      formData.append(`hotels[${i}][star_rating]`, hotels[i].star_rating);
      formData.append(`hotels[${i}][logo]`, hotels[i].logo_2[0]);
    }
  }

  const response = await http.put(`/conferences/detail/${id}/`, formData, {
    headers: http.getTokenHeaders(),
  });

  return response;
};

export const addCongress = async (payload) => {
  const response = await http.put(`/conferences/detail/`, payload, {
    headers: http.getTokenHeaders(),
  });

  return response;
};

export const getCountrySuggestionsQuery = async (search) => {
  const response = await http.get(`/conferences/country-suggestion/`, {
    params: {
      country_name: search || "",
    },
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const createNewCongress = async (payload) => {
  const {
    specialities,
    target_audience,
    conference_registration,
    thumbnail,
    hotels,
    banner_image,
    ...resetPayload
  } = payload;
  const formData = new FormData();

  Object.entries(resetPayload).forEach(([key, value]) => {
    formData.append(key, value);
  });

  if (specialities?.length > 0) {
    specialities.forEach((item, idx) => {
      formData.append(`specialities[${idx}][speciality]`, item?.value);
    });
  } else {
    formData.append(`specialities`, []);
  }

  if (target_audience?.length > 0) {
    target_audience.forEach((item, idx) => {
      formData.append(`target_audience[${idx}]`, item?.value);
    });
  } else {
    formData.append(`target_audience`, []);
  }

  if (thumbnail?.length > 0) {
    formData.append("thumbnail", thumbnail[0]);
  } else {
    formData.append("thumbnail", "");
  }

  if (banner_image?.length > 0) {
    formData.append("banner_image", banner_image[0]);
  } else {
    formData.append("banner_image", "");
  }

  if (conference_registration?.length > 0) {
    conference_registration.forEach((item, idx) => {
      Object.entries(item).forEach(([key, value]) => {
        formData.append(`conference_registration[${idx}][${key}]`, value || "");
      });
    });
  }

  if (hotels?.length > 0) {
    for (let i = 0; i < hotels.length; i++) {
      formData.append(`hotels[${i}][hotel_name]`, hotels[i].hotel_name);
      formData.append(`hotels[${i}][distance]`, hotels[i].distance);
      formData.append(`hotels[${i}][star_rating]`, hotels[i].star_rating);
      formData.append(`hotels[${i}][logo]`, hotels[i].logo_2[0]);
    }
  }

  const response = await http.post(`/conferences/detail/`, formData, {
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const getCongressCountryList = async () => {
  const response = await http.get(`/conferences/country-list/`, {
    headers: http.getTokenHeaders(),
  });

  return response.data;
}