import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";

function Image({ img, deleteImage, maxFiles }) {
  const [hovered, setHovered] = useState(false);

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      onFocus={handleMouseOver}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      className="
        text-white
        d-flex
        justify-content-center
        align-items-center
        col-lg-3
        col-md-4
        col-sm-6
        mb-2
      "
    >
      <img
        className={
          hovered && maxFiles !== 1
            ? "blur img-fluid img-thumbnail"
            : "img-fluid img-thumbnail"
        }
        style={{ color: "#000000" }}
        src={img.thumbnail || img.file}
        alt=""
      />
      {maxFiles !== 1 && (
        <div className="card-img-overlay d-flex justify-content-center align-items-center">
          <div
            role="button"
            tabIndex={0}
            className="cursor-pointer"
            onClick={() => deleteImage(img.id)}
          >
            {hovered && <DeleteIcon />}
          </div>
        </div>
      )}
    </div>
  );
}

Image.propTypes = {
  img: PropTypes.object.isRequired,
  deleteImage: PropTypes.func.isRequired,
};
export default Image;
