/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useCallback } from "react";
import { NavDropdown } from "react-bootstrap";
import { useAlert } from "react-alert";
import { FaCheckCircle, FaMinusCircle, FaFileInvoice } from "react-icons/fa";
import useConfirmAlert from "../../../../../components/common/custom-hooks/useConfirmAlert";
import useCustomReasonModal from "../../../../../components/common/custom-hooks/useCustomReasonModal";
import useToggle from "../../../../../components/common/custom-hooks/useToggle";
import { DocumentViewerPopup } from "../../../../../components/common/document-viewer";
import Tooltip from "../../../../../components/common/CustomTooltip";
import SpinningCircles from "../../../../../shared/svg-icons/SpinningCircles";
import { useEventCoordinationContext } from "../../context/EventCoordinationContext";
import RFPActionController from "../../../common/RFPActionController";
import { modifyRFPQuotationAndRFP } from "../../../../../server/Filter";
import { CONFIRMED as RFP_CONFIRMED } from "../../utils/constants";
import {
  AWARDED,
  CANCELLED,
  APPROVED,
  REQUESTED_CHANGES,
  DECLINED,
  PROFORMA_INVOICED,
} from "../utils/constants";

const QuotationActionCell = ({ quotation }) => {
  const alert = useAlert();
  const confirm = useConfirmAlert();
  const reason = useCustomReasonModal();
  const {
    modifiers: { updateRFPQuotations },
  } = useEventCoordinationContext();
  const { isOpen: isPreviewModalOpen, toggle: togglePreviewModal } =
    useToggle();
  const [isLoading, setIsLoading] = useState(false);

  const {
    isQuotationAwarded,
    isQuotationProformaInvoiced,
    isQuotationCancelled,
    isQuotationChangesRequested,
  } = useMemo(
    () => ({
      isQuotationAwarded: quotation.operation_status === AWARDED,
      isQuotationProformaInvoiced:
        quotation.operation_status === PROFORMA_INVOICED,
      isQuotationCancelled: quotation.operation_status === CANCELLED,
      isQuotationChangesRequested:
        quotation.operation_status === REQUESTED_CHANGES,
    }),
    [quotation]
  );

  const handleAwardQuotation = useCallback(async () => {
    try {
      await confirm({
        message: "Are you sure you want to award this quotation?",
      });
      try {
        setIsLoading(true);
        await modifyRFPQuotationAndRFP({
          quotationID: quotation.id,
          rfp_id: quotation.rfp_id,
          quotation_num: quotation.quotation_formatted_number,
          quotation_name: quotation.quotation_name,
          quotation: {
            operation_status: AWARDED,
            status: APPROVED,
            operation_cancel_reason: "",
          },
          rfp: {
            request_status: RFP_CONFIRMED,
            status: RFP_CONFIRMED,
          },
        });
        alert.success("Quotation awarded successfully");
        updateRFPQuotations();
      } catch (error) {
        setIsLoading(false);
        alert.error("Something went wrong in awarding quotation");
      }
    } finally {
      setIsLoading(false);
    }
  }, [quotation]);

  const handleRequestChanges = useCallback(async () => {
    try {
      const providedReason = await reason();
      try {
        setIsLoading(true);
        await modifyRFPQuotationAndRFP({
          quotationID: quotation.id,
          rfp_id: quotation.rfp_id,
          quotation_num: quotation.quotation_formatted_number,
          quotation_name: quotation.quotation_name,
          quotation: {
            operation_status: REQUESTED_CHANGES,
            status: REQUESTED_CHANGES,
            operation_cancel_reason: providedReason,
          },
        });
        alert.success("Changes requested successfully");
        updateRFPQuotations();
      } catch (error) {
        setIsLoading(false);
        alert.error("Something went wrong in requesting changes");
      }
    } finally {
      setIsLoading(false);
    }
  }, [quotation]);

  const handleCancelQuotation = useCallback(async () => {
    try {
      const providedReason = await reason();
      try {
        setIsLoading(true);
        await modifyRFPQuotationAndRFP({
          quotationID: quotation.id,
          rfp_id: quotation.rfp_id,
          quotation_num: quotation.quotation_formatted_number,
          quotation_name: quotation.quotation_name,
          quotation: {
            operation_status: CANCELLED,
            status: DECLINED,
            operation_cancel_reason: providedReason,
          },
        });
        alert.success("Quotation cancelled successfully");
        updateRFPQuotations();
      } catch (error) {
        setIsLoading(false);
        alert.error("Something went wrong in cancelling quotation");
      }
    } finally {
      setIsLoading(false);
    }
  }, [quotation]);

  return (
    <React.Fragment>
      <DocumentViewerPopup
        isOpen={isPreviewModalOpen}
        toggle={togglePreviewModal}
        fileURL={quotation.quotation_file_link}
        fileName={quotation.quotation_name}
      />
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center gap-1">
          {isLoading ? (
            <SpinningCircles />
          ) : (
            <React.Fragment>
              {isQuotationAwarded && (
                <Tooltip text="Awarded">
                  <FaCheckCircle size={18} className="text-success" />
                </Tooltip>
              )}
              {isQuotationProformaInvoiced && (
                <Tooltip text="Proforma Invoiced">
                  <FaFileInvoice size={18} className="text-secondary" />
                </Tooltip>
              )}
              {isQuotationChangesRequested && (
                <Tooltip text="Requested Changes">
                  <FaMinusCircle size={18} className="text-secondary" />
                </Tooltip>
              )}
              {isQuotationCancelled && (
                <Tooltip text="Cancelled">
                  <FaMinusCircle size={18} className="text-danger" />
                </Tooltip>
              )}
            </React.Fragment>
          )}
          <span
            className="text-primary cursor-pointer"
            onClick={togglePreviewModal}
          >
            {quotation.quotation_name}
          </span>
        </div>
        {/* Menu */}
        {!isQuotationProformaInvoiced && (
          <RFPActionController>
            <NavDropdown className="custom-dropdown" title="">
              {!isQuotationAwarded && (
                <NavDropdown.Item onClick={handleAwardQuotation}>
                  Award Quotation
                </NavDropdown.Item>
              )}
              {!isQuotationChangesRequested && (
                <NavDropdown.Item onClick={handleRequestChanges}>
                  Request Changes
                </NavDropdown.Item>
              )}
              {!isQuotationCancelled && (
                <NavDropdown.Item onClick={handleCancelQuotation}>
                  Cancel Quotation
                </NavDropdown.Item>
              )}
            </NavDropdown>
          </RFPActionController>
        )}
      </div>
    </React.Fragment>
  );
};

export default QuotationActionCell;
