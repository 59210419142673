import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import propTypes from "prop-types";

import FormikField from "../../../../../../shared/components/form/FormikField";
import FormikSelect from "../../../../../../shared/components/form/FormikSelect";
import FormikDateRangePicker from "../../../../../../shared/components/form/FormikDateRangePicker";
import RFPFormRecord from "../RFPFormRecord";
import getFormatedDateRange from "../../../../../../utils/getFormatedDateRange";
import { categoryOptions } from "../../utils/constants";

// Child
const EventDetailsFormRecord = ({ children, ...rest }) => (
  <RFPFormRecord
    {...rest}
    labelClassName="w-40"
    valueClassName="w-60"
    removeLeftSpace
  >
    {children}
  </RFPFormRecord>
);

// Parent
const EventDetailsForm = ({ isTypeEvents }) => {
  const { setFieldValue, values } = useFormikContext();
  const { username, profile } = useSelector((state) => state.auth.user) || {};

  useEffect(() => {
    setFieldValue("account_name", username || "");
    setFieldValue("account_email", profile?.email || "");
    setFieldValue("crm_sales_account_id", profile?.crm_sales_account_id || "");
    setFieldValue(
      "crm_sales_customer_id",
      profile?.crm_sales_customer_id || ""
    );
  }, [profile, setFieldValue, username]);

  return (
    <RFPFormRecord labelClassName="w-0" valueClassName="w-60">
      <EventDetailsFormRecord label="Username" value={values.account_name}>
        <FormikField name="account_name" disabled />
      </EventDetailsFormRecord>
      <EventDetailsFormRecord
        label="Email Address"
        value={values.account_email}
      >
        <FormikField name="account_email" disabled />
      </EventDetailsFormRecord>
      <EventDetailsFormRecord label="Category" value={values.category}>
        <FormikSelect
          name="category"
          options={categoryOptions}
          placeholder="Select Category"
        />
      </EventDetailsFormRecord>
      <EventDetailsFormRecord label="Event Name" value={values.event_name}>
        <FormikField name="event_name" placeholder="Event Name" />
      </EventDetailsFormRecord>

      {!isTypeEvents && (
        <EventDetailsFormRecord label="Venue" value={values.congress_location}>
          <FormikField name="congress_location" placeholder="Venue" />
        </EventDetailsFormRecord>
      )}

      <EventDetailsFormRecord
        label="Number of Attendees"
        value={values.no_of_attendee}
      >
        <FormikField
          type="number"
          name="no_of_attendee"
          placeholder="No of Attendees"
        />
      </EventDetailsFormRecord>
      <EventDetailsFormRecord
        label="Required Date"
        value={getFormatedDateRange(
          values.required_date_start,
          values.required_date_end
        )}
      >
        <FormikDateRangePicker
          name={["required_date_start", "required_date_end"]}
          placeholder="YYYY/MM/DD"
        />
      </EventDetailsFormRecord>

      {isTypeEvents && (
        <EventDetailsFormRecord
          label="Alternative Date"
          value={getFormatedDateRange(
            values.alternative_date_start,
            values.alternative_date_end
          )}
        >
          <FormikDateRangePicker
            name={["alternative_date_start", "alternative_date_end"]}
            placeholder="YYYY/MM/DD"
          />
        </EventDetailsFormRecord>
      )}
    </RFPFormRecord>
  );
};

EventDetailsForm.propTypes = {
  isTypeEvents: propTypes.bool,
};

EventDetailsForm.defaultProps = {
  isTypeEvents: false,
};

export default EventDetailsForm;
