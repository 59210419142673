import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.scss";
import { create_booth_request_form } from "../../server/Filter";

const BoothRequestQuote = () => {
  const [formData, setformData] = useState({});
  const _handleSubmit = () => {
    create_booth_request_form(formData);
  };
  const Booth_form1s = [
    {
      for: "account_name",
      text: "ACCOUNT NAME:",
      type: "text",
      name: "account_name",
      placeholder: "PZ001",
    },
    {
      for: "email_address",
      text: "EMAIL ADDRESS:",
      type: "email",
      name: "email_address",
      placeholder: localStorage.getItem("user_email") || " ",
    },
    {
      for: "both_code",
      text: "EXHIBITION BOOTH CODE:",
      type: "text",
      name: "exhibition_booth",
      placeholder: "EXT0001",
    },
    {
      for: "both_size",
      text: "BOOTH SIZE:",
      type: "text",
      name: "stand_size",
      placeholder: "80 SQ M",
    },
    {
      for: "standard_type",
      text: "STANDARD TYPE:",
      type: "text",
      name: "standard_type",
      placeholder: "CORNER STAND",
    },
  ];
  const Booth_form2s = [
    {
      for: "exibition_name",
      text: "EXIBITION NAME:",
      type: "text",
      name: "name",
    },
    {
      for: "exibition_date",
      text: "EXIBITION DATE:",
      type: "text",
      name: "date",
    },
    {
      for: "exibition_country",
      text: "Country:",
      type: "text",
      name: "country",
    },
    { for: "exibition_City", text: "City:", type: "text", name: "city" },
  ];
  const Booth_form3s = [
    {
      for: "size_requirement",
      text: "EXIBITION STAND SIZE REQUIREMENT:",
      type: "text",
      name: "size_requirement",
    },
    {
      for: "budget",
      text: "APPROXIMATE BUDGET:",
      type: "text",
      name: "budget",
    },
    { for: "logo", text: "LOGO UPLOAD:", type: "text", name: "logo" },
  ];
  const renderBooth_form1 = (Booth_form1, index) => {
    return (
      <Row className="form_main" key={index}>
        <Col className="px-0" md={3}>
          <label htmlFor={Booth_form1.for}>{Booth_form1.text}</label>
        </Col>
        <Col md={7} className="px-0">
          <input
            type={Booth_form1.type}
            name={Booth_form1.name}
            placeholder={Booth_form1.placeholder}
            value={Booth_form1.value}
            onChange={(e) => {
              var input_data = {};
              if (Booth_form1.name === "exhibition_booth") {
                input_data[Booth_form1.name] = parseInt(e.target.value);
              } else {
                input_data[Booth_form1.name] = e.target.value;
              }
              setformData({
                ...formData,
                ...input_data,
              });
              console.log(formData);
            }}
            disabled = {( Booth_form1.placeholder == null ? null : 'disabled' )}
          />
        </Col>
        <Col xs={3} md={2}></Col>
      </Row>
    );
  };
  const renderBooth_form2 = (Booth_form2, index) => {
    return (
      <Row className="form_main" key={index}>
        <Col className="px-0" md={3} xs={12}>
          <label htmlFor={Booth_form2.for}>{Booth_form2.text}</label>
        </Col>
        <Col md={7} xs={12} className="px-0">
          <input
            type={Booth_form2.type}
            name={Booth_form2.name}
            placeholder={Booth_form2.placeholder}
            value={Booth_form2.value}
            onChange={(e) => {
              var input_data = {};
              input_data[Booth_form2.name] = e.target.value;
              setformData({
                ...formData,
                ...input_data,
              });
              console.log(formData);
            }}
          />
        </Col>
        <Col xs={3} md={2}></Col>
      </Row>
    );
  };
  const renderBooth_form3 = (Booth_form3, index) => {
    return (
      <Row className="form_main" key={index}>
        <Col className="px-0" xs={12}>
          <label htmlFor={Booth_form3.for}>{Booth_form3.text}</label>
        </Col>
        <Col md={7} xs={12} className="px-0">
          <input
            type={Booth_form3.type}
            name={Booth_form3.name}
            placeholder={Booth_form3.placeholder}
            value={Booth_form3.value}
            onChange={(e) => {
              var input_data = {};
              input_data[Booth_form3.name] = e.target.value;
              setformData({
                ...formData,
                ...input_data,
              });
              console.log(formData);
            }}
          />
        </Col>
        <Col xs={3} md={2}></Col>
      </Row>
    );
  };
  return (
    <React.Fragment>
      <Header />
      <div className="mt-12">
        <Container fluid className="px-0">
          <Row>
            <Col xs={12} className="pl-sm-6 pl-5">
              <div className="booth_form_container1 inner_width_div">
                {Booth_form1s.map(renderBooth_form1)}
              </div>
              <div className="booth_form_container2 inner_width_div">
                {Booth_form2s.map(renderBooth_form2)}
              </div>
              <div className="booth_form_container3 inner_width_div">
                {Booth_form3s.map(renderBooth_form3)}
                <div>
                  <label htmlFor="booth_textarea">
                    OTHER REQUIREMENTS:
                  </label>
                  <textarea
                    onChange={(e) => {
                      var input_data = {};
                      input_data["other_requirements"] = e.target.value;
                      setformData({
                        ...formData,
                        ...input_data,
                      });
                      console.log(formData);
                    }}
                    className="text_area"
                    // value="TEXT BOX"
                    name="booth_textarea"
                    value=""
                    rows="6"
                  >
                    TEXT BOX
                  </textarea>
                </div>
                <Row
                  className="booth_form_row">
                  <Col xs={12} className="px-0">
                    <button className="Button float-right" onClick={_handleSubmit}>
                      Send Request
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default BoothRequestQuote;
