import { combineReducers, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxThunk from "redux-thunk";
import {
  authReducer,
  messagesReducer,
  errorsReducer,
  cartReducer,
  productLaunchReducer,
} from "./redux/reducers/index";

const reducer = combineReducers({
  auth: authReducer,
  messages: messagesReducer,
  errors: errorsReducer,
  cart: cartReducer,
  productLaunch: productLaunchReducer,
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(reduxThunk))
);

export default store;
