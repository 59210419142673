import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import ProductLaunchSubTabs from "../../../components/common/rfp/ProductLaunchSubTabs";
import EventCloseOutProvider from "./context/EventCloseOutContext";
import ProformaInvoices from "./pro_invoices";
import Invoices from "./invoices";
import { PROFORMA_INVOICES, INVOICES } from "./utils/constants";

const tabsContent = [
  {
    key: PROFORMA_INVOICES,
    title: "Proforma Invoices",
    content: <ProformaInvoices />,
  },
  {
    key: INVOICES,
    title: "Invoices",
    content: <Invoices />,
  },
];

const EventCloseOut = () => {
  const { state = {} } = useLocation();

  const selectedTabKey = useMemo(() => {
    const selectedMainTab = tabsContent.find(
      (item) => item.key === state.subTab
    );
    if (selectedMainTab) return selectedMainTab.key;
    return PROFORMA_INVOICES;
  }, [state]);

  return (
    <EventCloseOutProvider>
      <ProductLaunchSubTabs
        defaultKey={selectedTabKey}
        tabsContent={tabsContent}
      />
    </EventCloseOutProvider>
  );
};

export default EventCloseOut;
