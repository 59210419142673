import { STORAGE_URL } from "../../../constants";
import { meetingRoomDefaultValues } from "./formUtils";

export const meetingRoomCapacityFormMappings = {
  BANQUET_ROUNDS: "banquet_rounds",
  BOARD_ROOM_CONFERENCE: "board_room_conference",
  CLASSROOM: "class_room",
  COCKTAIL_ROUNDS: "cocktail_rounds",
  CRESCENT_ROUNDS: "crescent_rounds",
  ESHAPED: "e_shaped",
  MAXIMUM_CAPACITY: "maximum_capacity",
  HOLLOW_SQUARE: "hollow_square",
  PERIMETER_SEATING: "perimeter_seating",
  ROYAL_CONFERENCE: "royal_conference",
  TALK_SHOW: "talk_show",
  THEATER: "theater",
  TSHAPED: "t_shaped",
  USHAPED: "u_shaped",
};

export const getUpdatedBasicInfoData = (data) => {
  const { venue_images, venue_highlights } = data || {};

  const venueImages = venue_images?.map((imageObj) => ({
    id: imageObj?.id,
    image_name: imageObj?.image || imageObj?.image_name,
    url: `${STORAGE_URL}${imageObj?.image}`,
  }));

  return {
    venue_images: venueImages,
    brand_name: venue_highlights?.brand_name,
    build_year: venue_highlights?.build_year,
    chain_name: venue_highlights?.chain_name,
    meeting_space: venue_highlights?.meeting_space,
    renovation_year: venue_highlights?.renovation_year,
    total_sleeping_rooms: venue_highlights?.total_sleeping_room,
    venue_address: data?.venue_address,
    star_rating: data?.star_rating,
    venue_name: data?.venue_name,
    venue_type: data?.venue_type,
    largest_meeting_room: data?.largest_meeting_room,
    second_largest_meeting_room: data?.second_largest_meeting_room,
    total_meeting_rooms: venue_highlights?.total_meeting_room,
  };
};

export const getUpdatedGuestRoomsData = (data) => {
  const guestRooms = data?.venue_guestroom?.guest_room_details || [];
  const singleRoom = guestRooms?.find((room) => room?.room_type === "SINGLE");
  const doubleRoom = guestRooms?.find((room) => room?.room_type === "DOUBLE");
  const suiteRoom = guestRooms?.find((room) => room?.room_type === "SUITE");
  const roomImages =
    data?.venue_images?.map((room) => ({
      id: room?.id,
      url: STORAGE_URL + room?.image,
      image_name: room?.image,
    })) || [];

  return {
    single_rooms: singleRoom?.room_count || 0,
    double_rooms: doubleRoom?.room_count || 0,
    suite_rooms: suiteRoom?.room_count || 0,
    guest_room_images: roomImages,
  };
};

export const getUpdatedMeetingVenuesData = (data) => {
  const meetingRooms = data?.venue_meeting_rooms?.map((room) => {
    const roomCapacity = {};
    const amenities = room?.meeting_room_amenities?.map((amenity) => ({
      label: amenity?.room_amenity_name,
      value: amenity?.room_amenity_name,
    }));

    if (room?.meeting_space_capacity?.length > 0) {
      room.meeting_space_capacity.forEach((capacity) => {
        roomCapacity[meetingRoomCapacityFormMappings[capacity?.type]] =
          capacity?.capacity;
      });
    }

    return {
      height: room?.height,
      length: room?.length,
      width: room?.width,
      room_name: room?.meeting_room_name,
      description: room?.meeting_room_description,
      total_space: room?.total_space,
      image: [
        ...(room?.main_image
          ? [
              {
                url: STORAGE_URL + room.main_image,
                image_name: room.main_image,
              },
            ]
          : []),
      ],
      ...roomCapacity,
      amenities,
    };
  });

  const floorPlans = data?.venue_docs?.map((item) => ({
    id: item?.id,
    url: STORAGE_URL + item?.document_path,
    fileName: item?.document_name,
  }));

  return {
    meeting_rooms:
      meetingRooms?.length >= 1 ? meetingRooms : [meetingRoomDefaultValues],
    floor_plans: floorPlans,
  };
};

export const getUpdatedLocalAttractionsData = (data) => {
  const attractions = data?.venue_attractions?.map((item) => {
    const { attraction_image } = item || {};

    return {
      attraction_images: [
        ...(attraction_image
          ? [
              {
                url: STORAGE_URL + attraction_image,
                image_name: attraction_image,
              },
            ]
          : []),
      ],
      name: item?.name || "",
      type: item?.attraction_type || "",
      distance: item?.distance || "",
      description: item?.description || "",
    };
  });

  return {
    venue_attractions: attractions,
  };
};
