import React, { createContext, useContext, useState } from "react";

export const MultiFilePickerContext = createContext({
  selectedDocument: null,
  successfulFiles: [],
  errorFiles: [],
  uploadingFiles: null,
  isUploadingFiles: false,
  setSelectedDocument: () => {},
  setSuccessfulFiles: () => {},
  setErrorFiles: () => {},
  setUploadingFiles: () => {},
  resetValues: () => {},
});

export const useMultiFilePickerContext = () =>
  useContext(MultiFilePickerContext);

const MultiFilePickerProvider = ({ children }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [successfulFiles, setSuccessfulFiles] = useState([]);
  const [errorFiles, setErrorFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState(null);

  const resetValues = () => {
    setSuccessfulFiles([]);
    setErrorFiles([]);
    setUploadingFiles(null);
  };

  return (
    <MultiFilePickerContext.Provider
      value={{
        selectedDocument,
        successfulFiles,
        errorFiles,
        uploadingFiles,
        isUploadingFiles: Boolean(uploadingFiles),
        setSelectedDocument,
        setSuccessfulFiles,
        setErrorFiles,
        setUploadingFiles,
        resetValues,
      }}
    >
      {children}
    </MultiFilePickerContext.Provider>
  );
};

export default MultiFilePickerProvider;
