import React, { useState, useEffect } from "react";
import useDimensions from "react-cool-dimensions";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Slider from "react-slick";

import { css } from "emotion";
// import "../venue_concept_design_detail/index.scss";

const DesignNewSix = () => {
  const [save, setSave] = useState("");
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "0px",
  };

  const stageSlidesData = [
    { id: 1, source: "assets/pages/design/venue/deluxe/Capture1.jpg" },
    { id: 2, source: "assets/pages/design/venue/deluxe/Capture2.jpg" },
    { id: 3, source: "assets/pages/design/venue/deluxe/Capture3.jpg" },
    { id: 4, source: "assets/pages/design/venue/deluxe/Capture4.jpg" },
    { id: 5, source: "assets/pages/design/venue/deluxe/Capture1.jpg" },
    { id: 6, source: "assets/pages/design/venue/deluxe/Capture2.jpg" },
  ];

  const [heights, setHeights] = useState("");
  const responsive = css`
    height: ${heights};
  `;

  const { ref } = useDimensions({
    onResize: ({ width }) => {
      let screenHeight = (width * 9) / 18 + "px";
      setHeights(screenHeight);
      console.log(width);
    },
  });

  const count = stageSlidesData.length;
  let row = [];
  let rows = [];

  if (count === 1) {
    for (let i = 0; i < 5; i++) {
      row.push(
        <div className="slick-slide" key={i}>
          <Image
            className={"w-100 " + responsive}
            ref={ref}
            src={stageSlidesData.map((slide) => slide.source)}
            fluid
          />
        </div>
      );
      rows.push(
        <div className="slick-slide venue_thumb_slider" key={i}>
          <Image
            className="w-100"
            style={{ height: "100px" }}
            src={stageSlidesData.map((slide) => slide.source)}
          />
        </div>
      );
    }
  } else if (count === 2) {
    for (let i = 0; i < 2; i++) {
      row.push(
        stageSlidesData.map((slide) => {
          return (
            <div className="slick-slide" key={i}>
              <Image
                className={"w-100 " + responsive}
                ref={ref}
                src={slide.source}
                fluid
              />
            </div>
          );
        })
      );
      rows.push(
        stageSlidesData.map((slide) => {
          return (
            <div className="slick-slide venue_thumb_slider" key={i}>
              <Image
                className="w-100"
                style={{ height: "100px" }}
                src={slide.source}
              />
            </div>
          );
        })
      );
    }
  } else {
    row.push(
      stageSlidesData.map((slide, index) => {
        return (
          <div className="slick-slide" key={index}>
            <Image
              className={"w-100 " + responsive}
              ref={ref}
              src={slide.source}
              fluid
            />
          </div>
        );
      })
    );
    rows.push(
      stageSlidesData.map((slide, index) => {
        return (
          <div className="slick-slide venue_thumb_slider" key={index}>
            <Image
              className="w-100"
              style={{ height: "100px" }}
              src={slide.source}
            />
          </div>
        );
      })
    );
  }

  return (
    <React.Fragment>
      <Header />
      <Container className="px-0" fluid>
        <Row className="inner_width_div">
          <Col xs={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
            <Row className="my-7">
              <Col xs={6} className="px-0">
                <Link
                  to="/concept_designs/venue_concept_designs"
                  className="back_button"
                >
                  &lt;Back
                </Link>
              </Col>
            </Row>
            <div className="App h-auto position-relative concept_detail_slider_div">
              <div className="slider-wrapper">
                <Slider
                  {...settingsMain}
                  asNavFor={nav2}
                  ref={(slider) => setSlider1(slider)}
                >
                  {row}
                </Slider>
                <div className="thumbnail-slider-wrap w-50 mx-auto">
                  <Slider
                    {...settingsThumbs}
                    asNavFor={nav1}
                    ref={(slider) => setSlider2(slider)}
                  >
                    {rows}
                  </Slider>
                </div>
                <div className="savelist_icon_block">
                  {save === "save" ? (
                    <FavoriteIcon style={{ color: "red" }} />
                  ) : (
                    <FavoriteIcon style={{ color: "#fff" }} />
                  )}
                </div>
              </div>
            </div>
            <Row className="my-5">
              <Col sm={8} className="px-0">
                <p className="font-weight-bold text-lg-150 text-125 mb-2">
                  Concept Code: DC1002
                </p>
                <p className="mb-0">Concept Category</p>
              </Col>
              <Col sm={4} className="pr-0">
                <div className="new_exist_btn_row d-flex flex-column align-items-end">
                  <button
                    onClick={() => setSave("save")}
                    style={{ width: "200px" }}
                  >
                    Add to existing RFP
                  </button>
                  <button style={{ width: "200px" }}>Add to New RFP</button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="py-5 text-white" style={{ backgroundColor: "#071F4E" }}>
          <Row className="inner_width_div">
            <Col xs={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
              <p className="text-lg-125 text-100 mb-0">
                Venue Concept Details:
              </p>
            </Col>
          </Row>
        </div>
        <Row className="inner_width_div my-5">
          <Col xs={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
            <p>
              I'm a paragraph. Double click here or click Edit Text to add some
              text of your own or to change the font. Tell your visitors a bit
              about your services.
            </p>
            <p className="font-weight-bold text-lg-125 text-100 mb-2">
              Starting from:&nbsp;&nbsp; $100.00/-
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default DesignNewSix;
