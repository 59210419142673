import React from 'react';
import { Row, Col } from 'react-bootstrap';

const EventText = (props) => {
    return (
        <React.Fragment>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="px-0">
                    <div className="gen_inquiry_text">
                        <p className="font-weight-bold text-lg-150 text-125 mb-0">
                            {props.heading}
                        </p>
                    </div>
                    <textarea name="textarea" rows="7" className="text_area"></textarea>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default EventText;
