/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import { KeyboardArrowDown } from "@material-ui/icons";
import propTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import "../../../styles/components/collapse.scss";
import { useGetVenuesContext } from "../custom-hooks/useGetVenuesContext";

function FilterCollapse({ listOptions, title, paramKey, isSearch }) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [collapsing, setCollapsing] = useState(false);
  const [selected, setSelected] = useState(null);
  const { pathname } = useLocation();
  const history = useHistory();
  const { searchParams } = useGetVenuesContext();
  const paramValue = searchParams.get(paramKey);

  useEffect(() => {
    setSelected(paramValue);
  }, [paramValue]);

  useEffect(() => {
    if (searchParams?.size === 0 && inputValue?.length > 0) {
      setInputValue("");
      setOpen(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (listOptions?.length > 0) {
      setOptions(listOptions);
    }
  }, [listOptions]);

  useEffect(() => {
    if (inputValue?.length === 0) {
      setOptions(listOptions);
    }
  }, [options, inputValue]);

  const handleToogle = () => {
    setCollapsing(true);

    setOpen(!isOpen);

    setTimeout(() => {
      setCollapsing(false);
    }, [300]);
  };

  const handleClick = (value) => {
    searchParams.set(paramKey, value);
    searchParams.delete("page");
    setSelected(paramKey, value);

    history.push({
      pathname,
      search: searchParams.toString(),
    });
  };

  const handleChange = (event) => {
    const searchText = event.target.value?.toLowerCase();
    const filteredOptions = listOptions?.filter((item) =>
      item?.value?.toLowerCase()?.includes(searchText)
    );

    setOptions(filteredOptions);
    setInputValue(searchText);
  };

  return (
    <div
      className={`custom-collapse-container ${isOpen ? "show" : ""} ${
        collapsing ? "updating" : "done"
      }`}
    >
      <div className="collapse-header" onClick={handleToogle}>
        <p className="title">{title}</p>

        <KeyboardArrowDown className="arrow" />
      </div>

      <div className="collapse-body">
        {isSearch && (
          <div className="search-field">
            <input
              value={inputValue}
              className="form-control filter-search"
              id="Custom-Search"
              onChange={handleChange}
              placeholder="Seach"
            />
          </div>
        )}

        <div className="list-wrapper">
          {options?.map((item) => (
            <button
              className={item?.value === selected ? "active" : ""}
              key={item?.value}
              onClick={() => handleClick(item?.value)}
            >
              {item?.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

FilterCollapse.propTypes = {
  title: propTypes.string.isRequired,
  paramKey: propTypes.string.isRequired,
  listOptions: propTypes.array,
  isSearch: propTypes.bool,
};

FilterCollapse.defaultProps = {
  isSearch: false,
  listOptions: [],
};

export default memo(FilterCollapse);
