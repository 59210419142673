import React from "react";
import DatePicker from "react-datepicker";
import { useField, useFormikContext } from "formik";
import moment from "moment";

const FormikDatePicker = ({
  name,
  placeholder = "",
  dateDisplayFormat = "yyyy/MM/dd",
  dateValueFormat = "YYYY-MM-DD",
  onChange = null,
  minDate,
}) => {
  const [startDateName, endDateName] = name;
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const [startField, startMeta] = useField(startDateName || "");
  const { value: startDateValue, ...restStartFieldProps } = startField;
  const { touched: startTouched, error: startError } = startMeta;

  const [endField, endMeta] = useField(endDateName || "");
  const { value: endDateValue, ...restEndFieldProps } = endField;
  const { touched: endTouched, error: endError } = endMeta;

  const handleChange = (name, date) => {
    if (date && moment(date).isValid()) {
      const selectedDate = moment(date).format(dateValueFormat);
      setFieldValue(name, selectedDate);
      if (onChange) onChange(selectedDate);
    }
  };

  const handleBlur = (name) => {
    setFieldTouched(name, true);
  };

  const selectedStartDate = startDateValue
    ? moment(startDateValue, dateValueFormat).toDate()
    : null;

  const selectedEndDate = endDateValue
    ? moment(endDateValue, dateValueFormat).toDate()
    : null;

  const isErrorField = (startTouched || endTouched) && (startError || endError);
  const error = startError || endError;

  return (
    <React.Fragment>
      <div className="date-range-picker">
        <DatePicker
          {...restStartFieldProps}
          className={isErrorField ? "error-field" : ""}
          name={startDateName}
          selected={selectedStartDate}
          endDate={selectedEndDate}
          minDate={minDate}
          onChange={(selectedDate) => handleChange(startDateName, selectedDate)}
          onBlur={() => handleBlur(startDateName)}
          showYearDropdown
          dateFormat={dateDisplayFormat}
          dropDownMode="select"
          popperPlacement="center"
          placeholderText={placeholder}
          autoComplete="off"
          selectsStart
        />
        <span className="date-separator">-</span>
        <DatePicker
          {...restEndFieldProps}
          name={endDateName}
          className={isErrorField ? "error-field" : ""}
          selected={selectedEndDate}
          startDate={selectedEndDate}
          endDate={selectedEndDate}
          minDate={selectedStartDate}
          onChange={(selectedDate) => handleChange(endDateName, selectedDate)}
          onBlur={() => handleBlur(endDateName)}
          showYearDropdown
          dateFormat={dateDisplayFormat}
          dropDownMode="select"
          popperPlacement="center"
          placeholderText={placeholder}
          autoComplete="off"
          selectsEnd
        />
      </div>
      {isErrorField && <span className="form__form-group-error">{error}</span>}
    </React.Fragment>
  );
};

export default FormikDatePicker;
