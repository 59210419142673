import React, { useState } from "react";
import { Col } from "react-bootstrap";
import propTypes from "prop-types";

// UTILITIES
import { distanceTypeKey, formatUnderscoreText } from "../utilities/helpers";
import { getDummyVenueImage } from "../../../utils/helpers";

function AttractionCardRow({ attraction }) {
  const [showDescription, setShowDescription] = useState(false);

  const toggleShowDescription = () => {
    setShowDescription((prevState) => !prevState);
  };

  const distanceType = distanceTypeKey[attraction?.distance_type];

  if (attraction?.attraction_type === "BLANK") return null;

  return (
    <Col md={12} className="attraction">
      <img src={getDummyVenueImage(attraction?.attraction_image)} alt="card" />

      <div className="content-wrapper">
        <p className="title custom-text-primary">{attraction?.name}</p>

        {attraction?.attraction_type && (
          <p className="my-2 type">
            {formatUnderscoreText(attraction.attraction_type)}
          </p>
        )}

        {distanceType && (
          <p className="distance custom-text-secondary">
            {`${attraction?.distance} ${distanceType} away`}
          </p>
        )}

        {attraction?.description && (
          <div className="d-flex align-items-start flex-column my-2">
            <p className={`${showDescription ? "show" : ""} description`}>
              {attraction.description}
            </p>

            {attraction?.description?.length > 275 && (
              <button
                type="button"
                className="show-btn"
                onClick={toggleShowDescription}
              >
                {showDescription ? "See less" : "See more"}
              </button>
            )}
          </div>
        )}
      </div>
    </Col>
  );
}

AttractionCardRow.propTypes = {
  attraction: propTypes.object,
};

AttractionCardRow.defaultProps = {
  attraction: null,
};

export default AttractionCardRow;
