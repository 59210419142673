const {
  REACT_APP_STORAGE_URL,
  REACT_APP_API_URL,
  REACT_APP_CRM_API_URL,
  REACT_APP_CRM_SOCKET_URL,
  REACT_APP_GOOGLE_API_KEY,
} = process.env;

// EMP URLs
export const API_URL = REACT_APP_API_URL;
export const STORAGE_URL = REACT_APP_STORAGE_URL;
export const GOOGLE_MAP_API_KEY = REACT_APP_GOOGLE_API_KEY;

// CRM URLs
export const CRM_API_URL = REACT_APP_CRM_API_URL;
export const CRM_SOCKET_URL = REACT_APP_CRM_SOCKET_URL;

export const EVENTS = "Events";
export const CONGRESS = "Congress";
