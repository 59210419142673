/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import { KeyboardArrowDown } from "@material-ui/icons";
import propTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";

// COMPONENTS & STYLES & UTILITIES
import useLazyFetchRequest from "../../../components/common/custom-hooks/useLazyFetchRequest";
import "../../../styles/components/collapse.scss";
import { useGetVenuesContext } from "../custom-hooks/useGetVenuesContext";
import { getCitySuggestionsQuery } from "../../../services/venue-sourcing";
import { useTransformCitySuggestions } from "../../venue/custom-hooks/useTransformers";
import { useDebouncedCallback } from "use-debounce";

function SearchCollapse({ title, paramKey }) {
  const [isOpen, setOpen] = useState(false);
  const [collapsing, setCollapsing] = useState(false);
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const { pathname } = useLocation();
  const history = useHistory();
  const { searchParams } = useGetVenuesContext();
  const paramValue = searchParams.get(paramKey);

  const [getSuggestions, { data, isSuccess }] = useLazyFetchRequest(
    getCitySuggestionsQuery
  );
  const options = useTransformCitySuggestions(data);

  useEffect(() => {
    if (!paramValue) {
      setSelected(null);
      setOpen(false);
      setInputValue("");
    }
  }, [paramValue]);

  const handleDebounce = useDebouncedCallback(
    async (value) => {
      await getSuggestions(value);
    },
    [300]
  );

  const handleToogle = () => {
    setCollapsing(true);

    setOpen(!isOpen);

    setTimeout(() => {
      setCollapsing(false);
    }, [300]);
  };

  const handleChange = async (ev) => {
    const { value } = ev.target;

    handleDebounce(value);
    setInputValue(value);
  };

  const handleClick = async (value) => {
    searchParams.set(paramKey, value);
    searchParams.delete("page");

    setSelected(value);
    setInputValue(value);

    history.push({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <div
      className={`custom-collapse-container ${isOpen ? "show" : ""} ${
        collapsing ? "updating" : "done"
      }`}
    >
      <div className="collapse-header" onClick={handleToogle}>
        <p className="title">{title}</p>

        <KeyboardArrowDown className="arrow" />
      </div>

      <div className="collapse-body">
        <div className="search-field">
          <input
            value={inputValue}
            className="form-control filter-search"
            id="Custom-Search"
            onChange={handleChange}
            placeholder="Seach Cities..."
          />
        </div>

        <div className="list-wrapper">
          {options?.length > 0 &&
            inputValue.length > 0 &&
            options?.map((item) => (
              <button
                className={item?.value === selected ? "active" : ""}
                key={item?.value}
                onClick={() => handleClick(item?.value)}
              >
                {item?.label}
              </button>
            ))}

          {options?.length === 0 && isSuccess && (
            <p className="no-results">No results found</p>
          )}
        </div>
      </div>
    </div>
  );
}

SearchCollapse.propTypes = {
  title: propTypes.string.isRequired,
  paramKey: propTypes.string.isRequired,
};

export default memo(SearchCollapse);
