import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Header from '../../components/header';
import Footer from '../../components/footer';
import BrandingCode from './branding_code';

const BrandingQuotation = ({ setMore }) => {
  const BrandingQuotationforms = [
    {
      for: "account_name",
      text: "ACCOUNT NAME:",
      type: "text",
      name: "account_name",
      placeholder: "PZ001",
    },
    {
      for: "email_address",
      text: "EMAIL ADDRESS:",
      type: "email",
      name: "email_address",
      placeholder: "OPERATIONS3@LUXURYEXPLORERSME.COM"
    },
    { 
      for: "event_name", 
      text: "Event NAME:", 
      type: "text", 
      name: "event_name" 
    },
    {
      for: "event_date",
      text: "Event Date:",
      type: "date",
      name: "event_date",
    },
    {
      for: "location",
      text: "Location:",
      type: "text",
      name: "location",
    }
  ]
  const renderBrandingQuotationform = (BrandingQuotationform, index) => {
    return (
      <Row className="form_main" key={index}>
        <Col className="px-0" lg={3} md={4}>
          <label htmlFor={BrandingQuotationform.for}>{BrandingQuotationform.text}</label>
        </Col>
        <Col lg={6} md={7} className="px-0">
          <input
            type={BrandingQuotationform.type}
            name={BrandingQuotationform.name}
            placeholder={BrandingQuotationform.placeholder}
            // value={BrandingQuotationform.value ? BrandingQuotationform.value : " "}
            onChange={(e) => {
                  
             }}
             disabled = {( BrandingQuotationform.placeholder == null ? null : 'disabled' )}
          />
        </Col>
        <Col lg={3} md={1}></Col>
      </Row>
    );
  };
  return (
    <React.Fragment>
      <Header />
        <Container className="pl-sm-6 pl-5 inner_width_div" fluid>
          <div onSubmit={() => console.log("submitted")} className="mt-12">
            {BrandingQuotationforms.map(renderBrandingQuotationform)}
            <Row className="form_main">
              <Col className="px-0" xl={4} lg={5} md={6}>
                <label htmlFor="preffered_currency">
                  PREFFERED CURRENCY FOR THE QUOTE:
                </label>
              </Col>
              <Col xl={5} lg={4} md={5} className="px-0">
                <input className="file-upload__input" type="text" name="preffered_currency" />
              </Col>
              <Col lg={3} md={1}></Col>
            </Row>
            <BrandingCode />
          </div>
        </Container>
      <Footer />
    </React.Fragment>
  )
}

export default BrandingQuotation;
