import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Datepick from './datepick';
import './index.scss';

function Logistic(props) {
    const [formData, setformData] = useState({})
    const _handleSubmit = () =>{
        props.onSubmit(formData)
    }
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    return (
      <React.Fragment>
        <div className="logistic_main">
          <p className="text-lg-125 text-100 mb-2">
            Logistics:
          </p>
          <div>
            <label className="big_checkbox">
              <p className="text-lg-125 text-100 mb-2">
                Registration
              </p>
              <input type="checkbox" id="registration" name="registration" />
              <span className="big_checkmark"></span>
            </label>
            <Row>
              <Col sm={1}></Col>
              <Col lg={2} md={3} xs={4}>
                <label className="small_checkbox">
                  <p className="text-lg-100 text-75 mb-2">
                    Member
                  </p>
                  <input type="checkbox" id="member" name="member" />
                  <span className="small_checkmark"></span>
                </label>
              </Col>
              <Col lg={4} md={5} sm={6} xs={8}>
                <input
                  type="text"
                  className="input_text"
                  onChange={(e) =>
                    setformData({
                      ...formData,
                      member_field: e.target.value,
                    })
                  }
                />
              </Col>
              <Col lg={5} md={3} sm={1}></Col>
            </Row>
            <Row className="logistic_member">
              <Col sm={1}></Col>
              <Col lg={2} md={3} xs={4} className="pr-0">
                <label className="small_checkbox">
                  <p className="text-lg-100 text-75 mb-2">
                    Non Member
                  </p>
                  <input type="checkbox" id="nonmember" name="nonmember" />
                  <span className="small_checkmark"></span>
                </label>
              </Col>
              <Col lg={4} md={5} sm={6} xs={8}>
                <input
                  type="text"
                  className="input_text"
                  onChange={(e) =>
                    setformData({
                      ...formData,
                      member_field: e.target.value,
                    })
                  }
                />
              </Col>
              <Col lg={5} md={3} sm={1}></Col>
            </Row>
            <label className="big_checkbox">
              <p className="text-lg-125 text-100 mb-2">
                Hotel Accomodation
              </p>
              <input
                type="checkbox"
                id="hotel_accomodation"
                name="hotel_accomodation"
              />
              <span className="big_checkmark"></span>
            </label>
            <Row>
              <Col sm={1}></Col>
              <Col md={3} xs={4} className="px-0">
                <div className="d-flex">
                  <p className="text-lg-100 text-75 mb-2">
                    Check-in Date:
                  </p>
                  <img
                    className="calendar ml-auto"
                    src={"assets/images/download.jpg"}
                    alt="Not Found"
                  />
                </div>
              </Col>
              <Col lg={4} md={5} sm={6} xs={8}>
                <Datepick
                  onChangeDate={(date) => {
                    setformData({
                      ...formData,
                      hotel_check_in_date: formatDate(date),
                    });
                  }}
                />
              </Col>
              <Col lg={4} md={3} sm={1}></Col>
            </Row>
            <Row className="clander_row">
              <Col sm={1}></Col>
              <Col md={3} xs={4} className="px-0">
                <div className="d-flex">
                  <p className="text-lg-100 text-75 mb-2">
                    Check-out Date:
                  </p>
                  <img
                    className="calendar ml-auto"
                    src={"assets/images/download.jpg"}
                    alt="Not Found"
                  />
                </div>
              </Col>
              <Col lg={4} md={5} sm={6} xs={8}>
                <Datepick
                  onChangeDate={(date) => {
                    setformData({
                      ...formData,
                      hotel_check_out_date: formatDate(date),
                    });
                  }}
                />
              </Col>
              <Col lg={4} md={3} sm={1}></Col>
            </Row>
            <Row className="mt-5">
              <Col sm={1}></Col>
              <Col sm={1} className="px-0">
                <p className="text-lg-100 text-75 mb-2">
                  Type:
                </p>
              </Col>
              <Col md={3} sm={5}>
                <label className="small_checkbox">
                  <p className="text-lg-100 text-75 mb-2">
                    Room Only
                  </p>
                  <input
                    type="checkbox"
                    id="room"
                    name="room"
                    value="room"
                    onChange={(e) => {
                      setformData({
                        ...formData,
                        hotel_type: e.target.value,
                      });
                      console.log(formData);
                    }}
                  />
                  <span className="small_checkmark"></span>
                </label>
              </Col>
              <Col md={7} sm={5}></Col>
            </Row>
            <Row>
              <Col sm={1}></Col>
              <Col sm={1}></Col>
              <Col md={3} sm={5}>
                <label className="small_checkbox">
                  <p className="text-lg-100 text-75 mb-2">
                    Bed and Breakfast
                  </p>
                  <input
                    type="checkbox"
                    id="bed_breakfast"
                    name="bed_breakfast"
                    value="bed_breakfast"
                    onChange={(e) =>
                      setformData({
                        ...formData,
                        hotel_type: e.target.value,
                      })
                    }
                  />
                  <span className="small_checkmark"></span>
                </label>
              </Col>
              <Col md={7} sm={5}></Col>
            </Row>
            <Row>
              <Col sm={1}></Col>
              <Col sm={1}></Col>
              <Col md={3} sm={5}>
                <label className="small_checkbox">
                  <p className="text-lg-100 text-75 mb-2">
                    Half Board
                  </p>
                  <input
                    type="checkbox"
                    id="half_board"
                    name="half_board"
                    value="half_board"
                    onChange={(e) =>
                      setformData({
                        ...formData,
                        hotel_type: e.target.value,
                      })
                    }
                  />
                  <span className="small_checkmark"></span>
                </label>
              </Col>
              <Col md={7} sm={5}></Col>
            </Row>
            <Row>
              <Col sm={1}></Col>
              <Col xl={2} sm={1}></Col>
              <Col md={3} sm={5}>
                <label className="small_checkbox">
                  <p className="text-lg-100 text-75 mb-2">
                    Full Board
                  </p>
                  <input
                    type="checkbox"
                    id="full_board"
                    name="full_board"
                    value="full_board"
                    onChange={(e) =>
                      setformData({
                        ...formData,
                        hotel_type: e.target.value === "on" ? true : false,
                      })
                    }
                  />
                  <span className="small_checkmark"></span>
                </label>
              </Col>
              <Col md={7} sm={5}></Col>
            </Row>
            <Row className="mt-5">
              <Col sm={1}></Col>
              <Col sm={2} className="px-0">
                <p className="text-lg-100 text-75 mb-2">
                  Occupancy:
                </p>
              </Col>
              <Col md={3} sm={5} className="px-0">
                <label className="small_checkbox">
                  <p className="text-lg-100 text-75 mb-2">
                    Single Occupancy
                  </p>
                  <input
                    type="checkbox"
                    id="single_occupancy"
                    name="single_occupancy"
                    value="single_occupancy"
                    onChange={(e) =>
                      setformData({
                        ...formData,
                        hotel_Occupancy: e.target.value,
                      })
                    }
                  />
                  <span className="small_checkmark"></span>
                </label>
              </Col>
              <Col md={6} sm={4}></Col>
            </Row>
            <Row>
              <Col sm={1}></Col>
              <Col sm={2}></Col>
              <Col md={3} sm={5} className="px-0">
                <label className="small_checkbox">
                  <p className="text-lg-100 text-75 mb-2">
                    Double Occupancy
                  </p>
                  <input
                    type="checkbox"
                    id="double_occupancy"
                    name="double_occupancy"
                    value="double_occupancy"
                    onChange={(e) =>
                      setformData({
                        ...formData,
                        hotel_Occupancy: e.target.value,
                      })
                    }
                  />
                  <span className="small_checkmark"></span>
                </label>
              </Col>
              <Col md={6} sm={4}></Col>
            </Row>
            <label className="big_checkbox">
              <p className="text-lg-125 text-100 mb-2">
                Car Transfer
              </p>
              <input type="checkbox" id="car_transfer" name="car_transfer" />
              <span className="big_checkmark"></span>
            </label>
            <Row>
              <Col sm={1}></Col>
              <Col md={3} sm={5}>
                <label className="small_checkbox">
                  <p className="text-lg-100 text-75 mb-2" >
                    Airport Transfer
                  </p>
                  <input
                    type="checkbox"
                    id="airport_transfer"
                    name="airport_transfer"
                    value="airport_transfer"
                    onChange={(e) => {
                      console.log(e);
                      setformData({
                        ...formData,
                        airport_transfer: true,
                      });
                    }}
                  />
                  <span className="small_checkmark"></span>
                </label>
              </Col>
              <Col md={8} sm={6}></Col>
            </Row>
            <Row>
              <Col sm={1}></Col>
              <Col md={3} sm={5}>
                <label className="small_checkbox">
                  <p className="text-lg-100 text-75 mb-2" >
                    Congress Transfer
                  </p>
                  <input
                    type="checkbox"
                    id="congress_transfer"
                    name="congress_transfer"
                    onChange={(e) => {
                      console.log(e);
                      setformData({
                        ...formData,
                        congress_transfer: true,
                      });
                    }}
                  />
                  <span className="small_checkmark"></span>
                </label>
              </Col>
              <Col md={8} sm={6}></Col>
            </Row>
            <Row>
              <Col xs={12} className="px-0">
                <textarea
                  name="textarea"
                  rows="7"
                  className="text_area"
                  value=""
                  onChange={(e) =>
                    setformData({ ...formData, remarks: e.target.value })
                  }
                >
                  Remarks/Other Requirements:
                </textarea>
              </Col>
            </Row>
            <Row className="logistic_submit">
              <Col xs={12} className="px-0">
                <button className="Button" onClick={_handleSubmit}>
                  Submit
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
}

export default Logistic;