import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ServeiceButtons from '../service_buttons';
import Text from '../text';

const Program = ({ setTab }) => {
    return (
        <React.Fragment>
            <Form>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor="attendees">Number of Attendees:</label>
                    </Col>
                    <Col className="px-0" md={6}>
                        <input name="attendees" />
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Row>
                    <Col xs={12} className="px-0">
                        <label className="big_checkbox">
                            <p className="text-lg-125 text-100 mb-2">
                                Same program as the brochure
                            </p>
                            <input type="checkbox" id="same_program" name="same_program" />
                            <span className="big_checkmark"></span>
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="px-0">
                        <label className="big_checkbox">
                            <p className="text-lg-125 text-100 mb-2">
                                Other program (please provide the detailed preffered day by day program in the text box below)
                            </p>
                            <input type="checkbox" id="other_program" name="other_program" />
                            <span className="big_checkmark"></span>
                        </label>
                    </Col>   
                </Row>
                <Text heading="" />
                <ServeiceButtons setTab={setTab} />
            </Form>
        </React.Fragment>
    )
}

export default Program;
