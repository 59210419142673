import React from "react";
import { useEventCoordinationContext } from "../../context/EventCoordinationContext";
import { RFP_DETAILS_STATE_KEY } from "../../utils/constants";

const RFPFormRecord = ({
  label,
  children,
  labelClassName = "w-20",
  valueClassName = "w-75",
  value,
  removeLeftSpace = false,
}) => {
  const {
    [RFP_DETAILS_STATE_KEY]: { isEditing },
  } = useEventCoordinationContext();

  return (
    <div className="my-7 d-flex align-items-center">
      {!removeLeftSpace && <span className="w-5" />}
      <div className={`${labelClassName} font-weight-bold`}>{label}</div>
      <div className={valueClassName}>
        {/* If Value is passed as prop then toggle it on basis of isEditing else render children */}
        {value ? (
          <React.Fragment>
            {isEditing ? children : <p className="mb-0">{value}</p>}
          </React.Fragment>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default RFPFormRecord;
