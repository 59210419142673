import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Map from "../../../components/map";
import useFetchRequest from "../../../components/common/custom-hooks/useFetchRequest";
import { getVenueLocationDetailsQuery } from "../../../services/venue-details";
import { useGetVenueDetailContext } from "../custom-hooks/useGetVenueDetailContext";
import { useGetTransformedLocationDetails } from "../custom-hooks/useTransformers";
import { formatUnderscoreText } from "../utilities/helpers";

function LocationTabPanel() {
  const { venueId } = useGetVenueDetailContext();
  const { data } = useFetchRequest(getVenueLocationDetailsQuery, venueId);
  const location = useGetTransformedLocationDetails(data);

  return (
    <Container className="location-container">
      <Row className="mb-4">
        <h6 className="heading">Location</h6>
      </Row>

      <Row className="map-wrapper position-relative">
        <Map height="80vh" specificLocation={location} zoom={16} />
      </Row>

      <Row className="my-5 distance-parking-container">
        <Col md={4}>
          <div className="d-flex align-items-center gap-1">
            <img src="/assets/images/airport-icon.svg" alt="airport-icon" />

            <h6>Distance from airport</h6>
          </div>

          <div className="list-items">
            {data?.venue_airports?.map((item) => (
              <p key={item?.id}>{`${parseFloat(item?.distance).toFixed(
                2
              )} mi. from venue`}</p>
            ))}
          </div>
        </Col>

        <Col md={4}>
          <div className="d-flex align-items-center gap-1">
            <img src="/assets/images/parking-icon.svg" alt="parking-icon" />

            <h6>Parking</h6>
          </div>

          <div className="list-items">
            {data?.venue_parking_rates?.map((item) => (
              <p key={item?.id} className="text-capitalize">
                {formatUnderscoreText(item?.parking_type)}
              </p>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default LocationTabPanel;
