import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';

const ContactUs = () => {
    return (
        <React.Fragment>
            <Header />
            Contact Us
            <Footer />
        </React.Fragment>
    )
}

export default ContactUs;
