import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import FileHandler, {
  useFileHandlerContext,
  SPREADSHEET,
  XLSX,
  XLX,
} from "../../../../components/common/file-handler";
import { saveGlobalDocument } from "../../../../server/Filter";
import {
  downloadFileWithFileObject,
  injectSpinner,
  removeSpinner,
} from "../../../../utils/helpers";
import { fetchDocumentBlob } from "../utils/helpers";
import { SPREADHEET_DOWNLOAD_AS_OPTIONS } from "../utils/constants";

const SPREADSHEET_EDITOR_ID = "document-viewer-spreadsheet";

const SpreadsheetEditor = ({
  fileURL,
  fileName = "Document",
  saveParams = {},
}) => {
  const alert = useAlert();
  const history = useHistory();
  const { state } = useLocation();
  const { spreadSheetRef } = useFileHandlerContext();
  const [isActionAllowed, setIsActionAllowed] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (fileURL) {
      injectSpinner(`#${SPREADSHEET_EDITOR_ID}`);
      fetchDocumentBlob(fileURL)
        .then((fileBlob) => {
          const file = new File([fileBlob], `${fileName}.xlsx`);
          if (spreadSheetRef.current && spreadSheetRef.current.open) {
            spreadSheetRef.current.open({ file });
            setIsActionAllowed(true);
          }
        })
        .catch(() => {
          setIsActionAllowed(false);
          alert.error(
            "Something went wrong in opening file. Please try again!!!"
          );
        })
        .finally(() => removeSpinner());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileURL]);

  const goBack = () => {
    if (state && state.returnData && state.returnData.url) {
      history.push(state.returnData.url, { ...state.returnData.state });
    } else {
      history.goBack();
    }
  };

  const onSpreadSheetLoad = () => {
    if (spreadSheetRef.current)
      spreadSheetRef.current.hideFileMenuItems(["File"], true);
  };

  const onSaveComplete = async ({ status, blobData, saveType }) => {
    if (status === "Success") {
      const updatedFile = new File(
        [blobData],
        `${fileName}.${saveType.toLowerCase()}`
      );

      // Save Excel file on server and download all other files
      if ([XLSX, XLX].includes(saveType)) {
        try {
          setIsSaving(true);
          await saveGlobalDocument({ ...saveParams, file: updatedFile });
          setIsSaving(false);
          alert.success("Document updated successfully");
          goBack();
        } catch (error) {
          setIsSaving(false);
          alert.error("Something went wrong in saving file");
        }
      } else {
        downloadFileWithFileObject(updatedFile);
      }
    } else {
      alert.error("Cannot save document. Please try again");
    }
  };

  const onSaveButtonClick = () => {
    if (spreadSheetRef.current) {
      spreadSheetRef.current.save({
        fileName,
        saveType: XLSX, // we will always save document in xlsx format
      });
    }
  };

  return (
    <FileHandler
      id="document-viewer-spreadsheet"
      fileType={SPREADSHEET}
      disableActions={!isActionAllowed}
      downloadAsOptions={SPREADHEET_DOWNLOAD_AS_OPTIONS}
      downloadedFileName={fileName}
      onLoad={onSpreadSheetLoad}
      onSaveButtonClick={onSaveButtonClick}
      isSaving={isSaving}
      onSaveComplete={onSaveComplete}
      height={800}
    />
  );
};

export default SpreadsheetEditor;
