import { useEffect } from "react";

function usePreventRefresh() {
  useEffect(() => {
    const handlePreventRefresh = (e) => {
      // e.preventDefault();
      e.returnValue = "No Refresh!";
    };

    window.addEventListener("beforeunload", handlePreventRefresh);

    return () => {
      window.removeEventListener("beforeunload", handlePreventRefresh);
    };
  }, []);

  return null;
}

export default usePreventRefresh;
