import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useAlert } from "react-alert";
import { useEventCoordinationContext } from "../context/EventCoordinationContext";
import ErrorFocus from "../../../../components/common/ErrorFocus";
import StatusHandler from "./components/StatusHandler";
import GeneralInformation from "./components/GeneralInformation";
import Roles from "./components/Roles";
import RFPForm from "./components/RFPForm";
import defaultFormInitialValues from "./utils/initialValues";
import validationSchema from "./utils/validationSchema";
import { updateRFPForm, uploadRFPDesign } from "../../../../server/Filter";
import {
  transformDataToInitialValues,
  transformRFPPayload,
} from "./utils/helpers";
import "./styles/index.scss";
import { EDITED, RFP_DETAILS_STATE_KEY } from "../utils/constants";

const RFPDetails = () => {
  const alert = useAlert();
  const {
    data: { [RFP_DETAILS_STATE_KEY]: rfpDetails },
    setData,
    [RFP_DETAILS_STATE_KEY]: { stopEditing },
  } = useEventCoordinationContext();
  const [initialValues, setInitialValues] = useState(defaultFormInitialValues);

  useEffect(() => {
    transformDataToInitialValues(rfpDetails).then((transformedData) => {
      setInitialValues(transformedData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let payload = { ...values, status: EDITED };
      setSubmitting(true);

      // Uploading Design
      const uploadedDesign = payload.rfpproduction.uploaded_design;
      if (uploadedDesign) {
        const response = await uploadRFPDesign(uploadedDesign);
        payload = {
          ...payload,
          rfpproduction: {
            ...payload.rfpproduction,
            uploaded_design: response.image,
          },
        };
      }

      // Submitting RFP
      const transformedPayload = transformRFPPayload(payload);
      await updateRFPForm(transformedPayload);

      // Update Context Data
      setData((prevState) => ({
        ...prevState,
        rfpDetails: { ...values, status: EDITED },
      }));

      alert.show("Form saved successfully!", {
        type: "success",
      });
      stopEditing();
    } catch (error) {
      setSubmitting(false);
      console.log(error);
      alert.show("Something went wrong. Please try again.", {
        type: "error",
      });
    }
  };

  const isRFPRequested = rfpDetails.isRequested;

  return (
    <div className="rfp-details">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className="rfp-main-form">
          {isRFPRequested && <StatusHandler />}
          <GeneralInformation />
          <Roles />
          <RFPForm />
          <ErrorFocus />
        </Form>
      </Formik>
    </div>
  );
};

export default RFPDetails;
