import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useLazyFetchRequest from "../../../../components/common/custom-hooks/useLazyFetchRequest";
import { getAllServiceRequests } from "../../../../server/Filter";
import getSorting from "../../../../utils/getSorting";

export const RFPServiceRequestMainContext = createContext({
  isLoading: true,
  serviceRequests: [],
  updateServiceRequests: () => {},
});

export const useRFPServiceRequestMainContext = () =>
  useContext(RFPServiceRequestMainContext);

const ServiceRequestMainProvider = ({ children }) => {
  const { rfpID } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [serviceRequests, setServiceRequests] = useState([]);
  const [
    fetchServiceRequests,
    { isLoading: isLoadingServiceRequests, data: serviceRequestsData = [] },
  ] = useLazyFetchRequest(getAllServiceRequests);

  const transformServiceRequestsData = (newData) => {
    setIsLoading(true);
    const sortedData = [...newData].sort(getSorting("desc", "updated_at"));
    setServiceRequests(sortedData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchServiceRequests({ rfp_id: rfpID });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    transformServiceRequestsData([...serviceRequestsData]);
  }, [serviceRequestsData]);

  const updateServiceRequests = () => {
    fetchServiceRequests({ rfp_id: rfpID });
  };

  return (
    <RFPServiceRequestMainContext.Provider
      value={{
        isLoading: isLoadingServiceRequests || isLoading,
        serviceRequests,
        updateServiceRequests,
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </RFPServiceRequestMainContext.Provider>
  );
};

export default ServiceRequestMainProvider;
