import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
// import TextEditor from "../../../../components/textEditor";
import "./index.scss";

const Location = ({
  hideComponent,
  register,
  errors,
  submitted,
  // control,
  // property,
}) => {
  return (
    <React.Fragment>
      <div className="mt-5" style={{ display: hideComponent ? "none" : "" }}>
        {errors?.venue_airports && (
          <div className="mb-5 text-danger">
            <p>Please fill required fields in order to proceed.</p>
          </div>
        )}
        {/* <Form.Group
          controlId="formBasicText"
          className="d-sm-flex align-items-center"
        >
          <Form.Label className="mb-sm-0 add_property_loc_label">
            Latitude/Longitude:
          </Form.Label>
          <div className="w-100">
            <Form.Control name="lat_long" type="text" ref={register} />
            <div className="text-success">
              <small>This field is required for the appearence of map</small>
            </div>
          </div>
        </Form.Group> */}
        <Row className="lat-long-fields">
          <Col xl={6} className="px-0 pr-xl-4">
            <Form.Group controlId="formBasicText mb-0">
              <Form.Label>Longitude:</Form.Label>
              <div className="w-100">
                <Form.Control
                  name="longitude"
                  type="number"
                  ref={register({ pattern: /^\d{0,9}(\.\d{6,})$/ })}
                  isInvalid={errors.longitude}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.longitude?.type === "pattern" &&
                    "Invalid No! It must contain a decimal point and atleast 6 digits after decimal"}
                </Form.Control.Feedback>
                <div className="text-success">
                  <small>
                    This field is required for the appearence of map
                  </small>
                </div>
              </div>
            </Form.Group>
          </Col>
          <Col xl={6} className="px-0">
            <Form.Group controlId="formBasicText mb-0">
              <Form.Label>Latitude:</Form.Label>
              <div className="w-100">
                <Form.Control
                  name="latitude"
                  type="number"
                  ref={register({ pattern: /^\d{0,9}(\.\d{6,})$/ })}
                  isInvalid={errors.latitude}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.latitude?.type === "pattern" &&
                    "Invalid No! It must contain a decimal point and atleast 6 digits after decimal"}
                </Form.Control.Feedback>
                <div className="text-success">
                  <small>
                    This field is required for the appearence of map
                  </small>
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <a
          target="blank"
          href="https://www.youtube.com/watch?v=0jIehhUyjUc"
          className="lat-long-link"
        >
          Click here to see how you can find Longitude and Latitude of your
          hotel's location?
        </a>
        {/* <Form.Group
          controlId="formBasicText"
          className="d-sm-flex align-items-center"
        >
          <Form.Label className="mb-sm-0 add_property_loc_label">
            Distance from city center:
          </Form.Label>
          <Form.Control
            name="city_center_distance"
            type="text"
            ref={register}
          />
        </Form.Group>
        <Form.Group controlId="formBasicText">
          <Form.Label className="w-100">Nearest Attractions:</Form.Label>
          <TextEditor
            name="nearest_attractions"
            control={control}
            defaultValue={property?.nearest_attractions}
          />
        </Form.Group> */}
        <Row>
          <Col xl={8} className="px-0 pr-xl-4">
            <Form.Group
              controlId="formBasicText"
              className="d-sm-flex align-items-center"
            >
              <Form.Label className="mb-sm-0 add_property_loc_label">
                Nearest Airport*:
              </Form.Label>
              <div className="w-100">
                <Form.Control
                  name="venue_airports[0].airport_name"
                  type="text"
                  ref={register}
                  // isInvalid={
                  //   errors?.venue_airports &&
                  //   errors?.venue_airports[0]?.airport_name
                  // }
                />
                {/* <Form.Control.Feedback type="invalid">
                  {errors?.venue_airports &&
                    errors?.venue_airports[0]?.airport_name &&
                    "This field is required!"}
                </Form.Control.Feedback> */}
              </div>
            </Form.Group>
          </Col>
          <Col xl={4} className="px-0">
            <Form.Group
              controlId="formBasicText"
              className="d-sm-flex align-items-center"
            >
              <Form.Label className="mb-sm-0 add_property_loc_label">
                Distance:
              </Form.Label>
              <Form.Control
                name="venue_airports[0].distance"
                type="text"
                ref={register}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={8} className="px-0 pr-xl-4">
            <Form.Group
              controlId="formBasicText"
              className="d-sm-flex align-items-center"
            >
              <Form.Label className="mb-sm-0 add_property_loc_label">
                Nearest Train Station:
              </Form.Label>
              <Form.Control
                style={{ display: "none" }}
                name="venue_public_transports[0].transportation_type"
                type="text"
                ref={register}
                defaultValue="train"
              />
              <div className="w-100">
                <Form.Control
                  name="venue_public_transports[0].nearest_location"
                  type="text"
                  ref={register}
                />
              </div>
            </Form.Group>
          </Col>
          <Col xl={4} className="px-0">
            <Form.Group
              controlId="formBasicText"
              className="d-sm-flex align-items-center"
            >
              <Form.Label className="mb-sm-0 add_property_loc_label">
                Distance:
              </Form.Label>
              <Form.Control
                name="venue_public_transports[0].distance"
                type="text"
                ref={register}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={8} className="px-0 pr-xl-4">
            <Form.Group
              controlId="formBasicText"
              className="d-sm-flex align-items-center"
            >
              <Form.Label className="mb-sm-0 add_property_loc_label">
                Nearest Tram Station:
              </Form.Label>
              <Form.Control
                style={{ display: "none" }}
                name="venue_public_transports[1].transportation_type"
                type="text"
                ref={register}
                defaultValue="tram"
              />
              <div className="w-100">
                <Form.Control
                  name="venue_public_transports[1].nearest_location"
                  type="text"
                  ref={register}
                />
              </div>
            </Form.Group>
          </Col>
          <Col xl={4} className="px-0">
            <Form.Group
              controlId="formBasicText"
              className="d-sm-flex align-items-center"
            >
              <Form.Label className="mb-sm-0 add_property_loc_label">
                Distance:
              </Form.Label>
              <Form.Control
                name="venue_public_transports[1].distance"
                type="text"
                ref={register}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={8} className="px-0 pr-xl-4">
            <Form.Group
              controlId="formBasicText"
              className="d-sm-flex align-items-center"
            >
              <Form.Label className="mb-sm-0 add_property_loc_label">
                Nearest Bus Station:
              </Form.Label>
              <Form.Control
                style={{ display: "none" }}
                name="venue_public_transports[2].transportation_type"
                type="text"
                ref={register}
                defaultValue="bus"
              />
              <div className="w-100">
                <Form.Control
                  name="venue_public_transports[2].nearest_location"
                  type="text"
                  ref={register}
                />
              </div>
            </Form.Group>
          </Col>
          <Col xl={4} className="px-0">
            <Form.Group
              controlId="formBasicText"
              className="d-sm-flex align-items-center"
            >
              <Form.Label className="mb-sm-0 add_property_loc_label">
                Distance:
              </Form.Label>
              <Form.Control
                name="venue_public_transports[2].distance"
                type="text"
                ref={register}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-5">
          <div className="alert alert-warning mb-0">
            <div className="d-flex">
              <label className="mt-2">
                <input
                  name="disclaimer"
                  type="checkbox"
                  className="mr-1"
                  ref={register({ required: submitted })}
                />
                This is to agree that we are allowing Events Explorers to use
                the provided information and pictures in their event platform.
              </label>
            </div>
          </div>
          {errors.disclaimer && (
            <div className="text-danger">
              <small>
                <ErrorIcon style={{ fontSize: 20, marginBottom: 5 }} />
                <span className="ml-1">
                  Please check this button in order to submit form
                </span>
              </small>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Location;
