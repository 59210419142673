import React from "react";
import FormikField from "../../../shared/components/form/FormikField";
import FormikFileInput from "../../../shared/components/form/FormikFileInput";
import ServiceQuestionController from "./ServiceQuestionController";
import RFPFieldWrapper from "./RFPFieldWrapper";

const Production = () => {
  return (
    <div className="w-100">
      <ServiceQuestionController
        questionText="Do you require Production?"
        flag="production_flag"
        stepNo={12}
      >
        <RFPFieldWrapper label="Upload Design">
          <FormikFileInput name="rfpproduction.uploaded_design" />
        </RFPFieldWrapper>
        <RFPFieldWrapper label="Request Design">
          <FormikField
            name="rfpproduction.requested_design"
            rows={5}
            textArea
          />
        </RFPFieldWrapper>
      </ServiceQuestionController>
    </div>
  );
};

export default Production;
