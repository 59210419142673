import React from "react";

const RFPRecord = ({ label, children }) => {
  return (
    <div className="my-2 d-flex">
      <span className="w-10" />
      <div className="w-30 font-weight-bold">{label}</div>
      <div className="w-60">{children}</div>
    </div>
  );
};

export default RFPRecord;
