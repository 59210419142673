import { useSelector } from "react-redux";
import { ADMIN, OWNER, SUB_ADMIN, USER } from "../userGroups";

const useGetRoles = () => {
  const { user } = useSelector((state) => state.auth) || {};
  const { role } = user?.profile || {};

  const isOwner = role === OWNER;
  const isAdmin = role === ADMIN;
  const isUser = role === USER;
  const isSubAdmin = role === SUB_ADMIN;

  return {
    isOwner,
    isAdmin,
    isUser,
    isSubAdmin,
    user,
    profile: user?.profile || {},
    role,
  };
};

export default useGetRoles;
