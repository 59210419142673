export const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

const dummyImages = [
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/1fbc536b91.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/83b0b97a43.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/08628eef33.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/ae79e916b1.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/1fbc536b91.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/83b0b97a43.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/08628eef33.jpeg",
  "https://emp-production.s3.ap-south-1.amazonaws.com/media/conference_images/ae79e916b1.jpeg",
];

export const getCongressBannerImage = (image) => {
  if (image) return image;

  const randomIndex = Math.floor(Math.random() * dummyImages.length);

  return dummyImages[randomIndex];
};
