import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./index.scss";

const FilterPrinting = () => {
  return (
    <React.Fragment>
      <Container fluid className="px-0">
        <div className="inner_width_div d-flex align-items-center justify-content-between pl-sm-6 pl-5 pr-5 pr-sm-4 mt-5">
          <Link to="/production" className="back_button branding_backbtn">
            <img
              src="/assets/images/icon-back.png"
              className="icon_back"
              alt=""
            />{" "}
            Back
          </Link>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default FilterPrinting;
