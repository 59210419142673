import React, { useState, useEffect } from "react";
import useDimensions from "react-cool-dimensions";
import { useParams } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Slider from "react-slick";
import { getExhibitionBoothById } from "../../server/Filter";
import { css } from "emotion";
import "./index.scss";

var parse = require("html-react-parser");
function ExibitionBoothDetails() {
  const { id } = useParams();

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [booth, setbooth] = useState([]);
  const [slidesData, setSlidesData] = useState([]);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  useEffect(() => {
    getExhibitionBoothById(id)
      .then((booth) => {
        setbooth(booth);
        setSlidesData(
          booth.slider_images.map((i) => {
            return i.image;
          })
        );
      })
      .catch((err) => console.log(err));
  }, [id]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "0px",
  };

  const [heights, setHeights] = useState("");
  const responsive = css`
    height: ${heights};
  `;

  const { ref } = useDimensions({
    onResize: ({ width }) => {
      var screenSMHeight = ((width - 40) * 9) / 17 + "px";
      var screenHeight = (width * 3) / 5 + "px";
      if (width > 559) {
        setHeights(screenSMHeight);
      } else {
        setHeights(screenHeight);
      }
    },
  });

  return (
    <React.Fragment>
      <Header />
      <Container
        fluid
        className="inner_width_div pl-sm-6 pl-5 pr-5 pr-sm-4"
        ref={ref}
      >
        <Row className="my-5">
          <Col xl={6} className="px-0">
            <Link to="/exhibition_booths" className="float-left back_button">
              &lt; Back
            </Link>
          </Col>
        </Row>
        <Row className="mb-7">
          <Col lg={12} md={12} className="px-0">
            <div className="slider-wrapper exhibition_booth_details_wrapper">
              <Slider
                {...settingsMain}
                asNavFor={nav2}
                ref={(slider) => setSlider1(slider)}
              >
                {slidesData.map((slide, index) => (
                  <div className="slick-slide" key={index}>
                    <Image
                      className={"slick-slide-image w-100 " + responsive}
                      src={slide}
                    />
                  </div>
                ))}
              </Slider>
              <div className="thumbnail-slider-wrap mt-1 mt-sm-3 mx-auto mw-50">
                <Slider
                  {...settingsThumbs}
                  asNavFor={nav1}
                  ref={(slider) => setSlider2(slider)}
                >
                  {slidesData.map((slide, index) => (
                    <div className="slick-slide" key={index}>
                      <Image
                        className="slick-slide-image mw-90"
                        src={slide}
                        fluid
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </Col>
        </Row>
        <div className="pb-4">
          <Row>
            <Col lg={3} md={3} className="px-0">
              <p className="text-lg-150 text-125 font-weight-bold mb-2 mb-lg-4">
                {booth.booth_code}
              </p>
            </Col>
            <Col lg={9} md={9}></Col>
          </Row>
          <Row>
            <Col lg={6} md={6} className="px-0">
              <p className="text-lg-125 text-100 mb-2 mb-lg-4">
                Exhibition Booth Size: {booth.booth_size}sqm
              </p>
            </Col>
            <Col lg={1} md={1}></Col>
            <Col lg={5} md={5} className="px-0">
              <p className="text-lg-125 text-100 mb-2 mb-lg-4">
                Stand Type: {booth.stand_type}
              </p>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid className="px-0">
        <Row
          style={{
            height: "70px",
            backgroundColor: "#071F4E",
            color: "#fff",
            fontSize: "25px",
          }}
        >
          <Col xs={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4 my-auto">
            <div className="inner_width_div">Booth Details</div>
          </Col>
        </Row>
        <Row className="inner_width_div my-5">
          <Col xs={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
            {booth.description && parse(booth.description)}
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
}

export default ExibitionBoothDetails;
