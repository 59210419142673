import React, {useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AddMore from '../add_more';
import './index.scss';

function BrandingCode() {
    const BrandingCodes = [
        {
            for: "branding_code",
            text: "BRANDING CODE:",
            type: "text",
            name: "branding_code",
            placeholder: "BP0001"
          },
          {
            for: "quantity",
            text: "Quantity:",
            type: "text",
            name: "quantity",
          }
      ]
    const renderBrandingCode = (BrandingCode, index) => {
        return (
          <Row className="form_main" key={index}>
            <Col className="px-0" lg={3} md={4}>
              <label htmlFor={BrandingCode.for}>
                {BrandingCode.text}
              </label>
            </Col>
            <Col lg={6} md={7} className="px-0">
              <input
                type={BrandingCode.type}
                name={BrandingCode.name}
                placeholder={BrandingCode.placeholder}
                // value={BrandingCode.value ? BrandingCode.value : " "}
                onChange={(e) => {
                      
                 }}
              />
            </Col>
            <Col lg={3} md={1}></Col>
          </Row>
        );
    };
    const [ more, setMore ] = useState(false)
    return (
        <React.Fragment>
          <div className="branding_code_div">
            {BrandingCodes.map(renderBrandingCode)}
            <Row>
              <Col xs={12} className="branding_general_form_col1 px-0">
                <p className="text-lg-150 text-125 mb-2">
                  Requirements:
                </p>
                <textarea name="textarea" rows="7" className="text_area"></textarea>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="branding_general_form_col2 px-0">
                <div className="d-flex float-right">
                  <Link to="/branding_materials">
                    <Button className="Button branding_code_cancel">
                      Cancel
                    </Button>
                  </Link>
                  <Button className="Button" onClick={() => setMore(true)}>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
            { more === true ? <AddMore /> : "" }
          </div>
        </React.Fragment>
    )
}

export default BrandingCode;
