import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ButtonBase from "@material-ui/core/ButtonBase";
// import Link from "@material-ui/core/Link";
// Import css files

import Slider from "react-slick";
import "./index.scss";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

class DestinationSlider extends React.Component {
  renderArrows = () => {
    return (
      <div className="slider-arrow">
        <ButtonBase
          className="arrow-btn prev "
          onClick={() => this.slider.slickPrev()}
        >
          <BsChevronLeft />
        </ButtonBase>
        <ButtonBase
          className="arrow-btn next"
          onClick={() => this.slider.slickNext()}
        >
          <BsChevronRight />
        </ButtonBase>
      </div>
    );
  };

  render() {
    const { destination } = this.props;

    var settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      ref: (c) => (this.slider = c),
      arrows: false,
      autoplay: false,
      autoplaySpeed: 3000,
    };

    const destinationCards = destination?.slider_images
      ? destination.slider_images.map((item) => ({
          image: item.image,
        }))
      : [];

    const renderDestinationCard = (destinationCard) => {
      return (
        <Image
          key={destinationCard.image}
          src={`${destinationCard.image}`}
          fluid
        />
      );
    };

    return (
      <React.Fragment>
        <Container className="mt-10">
          <Row className="clearfix">
            <Col md={1} sm={2}></Col>
            <Col
              md={10}
              sm={8}
              xs={10}
              className="dest_slider_col my-7 inner_width_div destination_col"
            >
              {this.renderArrows()}
              <Slider {...settings} className="travelinfo_slider">
                {destinationCards.map(renderDestinationCard)}
              </Slider>
            </Col>
            <Col md={1} sm={2}></Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default DestinationSlider;
