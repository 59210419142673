import http from "./httpService";

export const getVerifiedVenues = async (params) => {
  const response = await http.get(`/sourcing/venues/venue-search/`, {
    params: {
      is_approved: "True",
      page: params?.page || undefined,
      limit: params?.limit || undefined,
      ...params,
    },
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const getNonVerifiedVenues = async (params) => {
  const response = await http.get(`/sourcing/venues/venue-search/`, {
    params: {
      is_approved: "False",
      page: params?.page || undefined,
      limit: params?.limit || undefined,
      ...params,
    },
    headers: http.getTokenHeaders(),
  });

  return response.data;
};

export const updateVenueStatus = async (id, status) => {
  const response = await http.put(
    `/sourcing/venues/approve/${id}/`,
    {
      is_approved: status,
    },
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const verifyMultipleVenues = async (arr) => {
  const response = await http.put(
    `/sourcing/venues/bulk-approve/`,
    {
      ids: arr,
      is_approved: true,
    },
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const deleteMultipleVenues = async (arr) => {
  const response = await http.post(
    `/sourcing/venues/bulk-approve/`,
    {
      ids: arr,
    },
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const unverifyMultipleVenues = async (arr) => {
  const response = await http.put(
    `/sourcing/venues/bulk-approve/`,
    {
      ids: arr,
      is_approved: false,
    },
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const updateBasicInfoMutation = async (id, payload) => {
  const response = await http.put(
    `/sourcing/venues/venue-detail/${id}/update_basic_profile/`,
    payload,
    { headers: http.getTokenHeaders() }
  );

  return response;
};

export const updateOverviewDetailsMutation = async (id, payload) => {
  const response = await http.put(
    `/sourcing/venues/venue-detail/${id}/update_overview/`,
    payload,
    { headers: http.getTokenHeaders() }
  );

  return response;
};

export const updateGuestRoomDetailsMutation = async (id, payload) => {
  const response = await http.put(
    `/sourcing/venues/venue-detail/${id}/update_guest_room/`,
    payload,
    { headers: http.getTokenHeaders() }
  );

  return response;
};

export const updateVenueSpaceMutation = async (id, payload) => {
  const response = await http.put(
    `/sourcing/venues/venue-detail/${id}/update_meeting_venues/`,
    payload,
    { headers: http.getTokenHeaders() }
  );

  return response;
};

export const updateLocalAttractionsMutation = async (id, payload) => {
  const response = await http.put(
    `/sourcing/venues/venue-detail/${id}/update_attractions/`,
    payload,
    { headers: http.getTokenHeaders() }
  );

  return response;
};

export const updateLocationMutation = async (id, payload) => {
  const response = await http.put(
    `/sourcing/venues/venue-detail/${id}/update_location/`,
    payload,
    { headers: http.getTokenHeaders() }
  );

  return response;
};

export const createVenueMutation = async (payload) => {
  const response = await http.post(
    `/sourcing/venues/venue-detail/create_venue/`,
    payload,
    { headers: http.getTokenHeaders() }
  );

  return response;
};
