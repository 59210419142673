import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../../../redux/actions/authActions";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Button, Accordion, Card } from "react-bootstrap";
import "./index.scss";
import useGetRoles from "../../../custom-hooks/useGetRoles";
import { useDispatch } from "react-redux";

const MyAccount = ({ setDropdown }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [arrow, setArrow] = useState("");
  const { user, isOwner } = useGetRoles();

  const handleLogout = async () => {
    dispatch(logout());
  };

  return (
    <React.Fragment>
      <div className="header_myaccount pt-4 px-4">
        <p className="float-left titleHeader pb-4">
          {user && user.username ? user.username : ""}
        </p>

        <Link to="/manageaccount" onClick={() => setDropdown("")}>
          <p>Manage Account</p>
        </Link>

        {isOwner && (
          <Accordion defaultActiveKey="1" className="mb-3">
            <Card className="border-0">
              <Card.Header className="border-0 p-0 m-0 myAccountCardheader">
                <Accordion.Toggle
                  as={Button}
                  className="accordianHeader"
                  variant="link"
                  eventKey="0"
                  onClick={() =>
                    setArrow((arrow) => (arrow === "" ? "ChangeArrows" : ""))
                  }
                >
                  Management
                  {arrow === "ChangeArrows" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </Accordion.Toggle>
              </Card.Header>

              <Accordion.Collapse eventKey="0">
                <Card.Body className="myAccountcardBody py-2 px-3">
                  <p
                    className="mt-0 pt-2 cursor-pointer"
                    onClick={() => {
                      setDropdown(false);
                      history.push("/venues-management");
                    }}
                  >
                    Venues Management
                  </p>

                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      setDropdown(false);
                      history.push("/congress_management");
                    }}
                  >
                    Congress Management
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}

        <Link to="/notifications" onClick={() => setDropdown("")}>
          <p>Notifications</p>
        </Link>

        <Link to="/rfp" onClick={() => setDropdown("")}>
          <p>Create RFP</p>
        </Link>

        <Link to="/service_requests" onClick={() => setDropdown("")}>
          <p>Service Requests</p>
        </Link>

        <Accordion defaultActiveKey="1" className="mb-3">
          <Card className="border-0">
            <Card.Header className="border-0 p-0 m-0 myAccountCardheader">
              <Accordion.Toggle
                as={Button}
                className="accordianHeader"
                variant="link"
                eventKey="0"
                onClick={() =>
                  setArrow((arrow) => (arrow === "" ? "ChangeArrow" : ""))
                }
              >
                My Events
                {arrow === "ChangeArrow" ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey="0">
              <Card.Body className="myAccountcardBody py-2 px-3">
                <p
                  className="mt-0 pt-2 cursor-pointer"
                  onClick={() => {
                    setDropdown(false);
                    history.push("/requested");
                  }}
                >
                  Requested
                </p>

                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setDropdown(false);
                    history.push("/confirmed");
                  }}
                >
                  Confirmed
                </p>

                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setDropdown(false);
                    history.push("/finished");
                  }}
                >
                  Finished
                </p>

                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setDropdown(false);
                    history.push("/cancelled");
                  }}
                >
                  Cancelled
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Link to="" onClick={handleLogout}>
          <p>Log Out</p>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default MyAccount;
