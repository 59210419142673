import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.scss";

const Congress = () => {
  const congress_sub_pages = [
    {
      source:
        "/assets/images/congress-and-exhibition/medical-congress-main.jpg",
      text1: "Medical",
      text2: "Congress",
      text: "I'm a paragraph. Double click here or click Edit Text to add some text of your own or to change the font. Tell your visitors a bit about your services",
      link: "/medical_congress",
    },
    {
      source:
        "/assets/images/congress-and-exhibition/exhibition-booth-main.jpg",

      text1: "Exhibiton",
      text2: "Booth",
      text: "I'm a paragraph. Double click here or click Edit Text to add some text of your own or to change the font. Tell your visitors a bit about your services",
      link: "/exhibition_booths",
    },
  ];
  const rendercongress_sub_page = (congress_sub_page, index) => {
    return (
      <Col md={6} className="card_congress" key={index}>
        <Card style={{ width: "30rem", border: "none" }}>
          <Link to={congress_sub_page.link}>
            <Card.Img variant="top" src={congress_sub_page.source} />
          </Link>
          <Card.Body className="text-style">
            <Card.Title className="card-title1">
              <span className="font-weight-bold card-details1 mr-2 text2">
                {congress_sub_page.text1}
              </span>
              <span className=" font-weight-bold card-details cart-border1 text2">
                {congress_sub_page.text2}
              </span>
            </Card.Title>

            <Link to={congress_sub_page.link}>
              <button className="explore_button1 text-white card-details">
                Explore More
              </button>
            </Link>
          </Card.Body>
        </Card>
      </Col>
    );
  };
  return (
    <React.Fragment>
      <Header />
      <Container
        className="event_banner px-0 position-relative"
        style={{
          backgroundImage:
            'url("/assets/images/congress-and-exhibition/congress-and-exhibition-banner.jpg")',

          backgroundColor: "rgb(37 50 190 / 50%)",
        }}
        fluid
      >
        <div
        // className="event_banner_image"
        // style={{ backgroundColor: "rgba(37, 50, 190, 0.4)" }}
        ></div>
        <div className="text-blocks">
          <p
            className="font-weight-bold  text-center text-xl-500 text-lg-425 text-md-375 text-sm-300 text-200 mb-0"
            style={{ fontFamily: "'Poppins'" }}
          >
            Congress & Exhibition
          </p>
        </div>
      </Container>
      <Container className="CustomizeContainer">
        <Row className="align-items-center text-center text2">
          {congress_sub_pages.map(rendercongress_sub_page)}
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Congress;
