import Axios from "axios";
import { API_URL } from "../constants";

Axios.defaults.baseURL = `${API_URL}`;

const getTokenHeaders = () => {
  const token = localStorage.getItem("token");

  return {
    Authorization: `Token ${token}`,
  };
};

export default {
  get: Axios.get,
  post: Axios.post,
  put: Axios.put,
  delete: Axios.delete,
  patch: Axios.patch,
  getTokenHeaders,
};
