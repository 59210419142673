import React, { useState, useEffect } from "react";
import { Row, Col, Image, Modal, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { getPartnerVenues, deletePartnerVenue } from "../../../server/Filter";

const MyProperty = () => {
  const alert = useAlert();
  const history = useHistory();
  const [properties, setProperties] = useState([]);
  const [propertyId, setPropertyId] = useState("");

  const [show, setShow] = useState(false);

  useEffect(() => {
    getPartnerVenues()
      .then((data) => {
        const sortedData = data?.sort((a, b) => b.id - a.id);
        setProperties(sortedData || []);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleClose = () => setShow(false);

  const handleShow = (id) => {
    setPropertyId(id);
    setShow(true);
  };

  const handleDelete = async () => {
    try {
      await deletePartnerVenue(propertyId);
      const updatedVenues = await getPartnerVenues();
      alert.show("Property deleted successfully!", { type: "success" });
      const sortedUpdatedVenues = updatedVenues?.sort((a, b) => b.id - a.id);
      setProperties(sortedUpdatedVenues || []);
    } catch (err) {
      console.log(err);
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete this property?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mt-10 mb-5 d-flex">
        <p className="font-weight-bold text-lg-150 text-125 mb-2">
          My Properties
        </p>
        <button
          onClick={() => {
            history.push("/dashboard/property/add");
          }}
          className="ml-auto text-white border-0 px-4"
          style={{ backgroundColor: "#071F4E" }}
        >
          Add New Property
        </button>
      </div>
      <hr />
      {properties.map((property) => (
        <Row
          key={property.id}
          style={{ border: "1px solid #ced4da" }}
          className="my-10"
        >
          <Col xl={2} lg={3} md={4} className="px-0">
            <Image
              src={property.thumbnail?.thumbnail}
              className="w-100 myproperty_image"
              fluid
            />
          </Col>
          <Col xl={10} lg={9} md={8} className="p-lg-6 p-5">
            <Row>
              <Col lg={9} sm={8} xs={6} className="px-0">
                <Link
                  to={`/dashboard/property/${property.id}/edit`}
                  className="text-dark text-decoration-none"
                >
                  <p className="font-weight-bold text-lg-125 text-100 mb-2">
                    {property.venue_name}
                  </p>
                </Link>
              </Col>
              <Col lg={3} sm={4} xs={6} className="text-right px-0">
                <Link to={`/dashboard/property/${property.id}/edit`}>
                  <button className="Button_hide font-weight-bold pl-3 mr-3">
                    <EditIcon />
                  </button>
                </Link>
                <span className="font-weight-bold">|</span>
                <button
                  className="Button_hide font-weight-bold pl-3"
                  onClick={() => handleShow(property.id)}
                >
                  <DeleteIcon />
                </button>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="px-0">
                <div style={{ textTransform: "capitalize", marginTop: 10 }}>
                  <strong>Status:</strong> {property.status}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={9} className="px-0">
                <div className="mt-4">
                  <div className="d-flex mt-3">
                    <LocationOnIcon />
                    <address className="ml-1 mb-0">
                      {property.venue_address}
                    </address>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </React.Fragment>
  );
};

export default MyProperty;
