import { useLocation } from "react-router-dom";

function useSearchParams() {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return params;
}

export default useSearchParams;
