import React, { useState } from 'react';
import { Row, Col, Form, Container } from 'react-bootstrap';
import Header from '../../components/header';
import MainRFP from '../../components/Main_RFP';
import SelectBar from '../generalinquiry/selectbar';
import Flight from '../generalinquiry/flight';
import Accomodate from '../generalinquiry/accomodate';
import MeetingRoom from '../generalinquiry/meeting_room';
import Program from '../generalinquiry/program';
import Branding from '../generalinquiry/branding';
import Transportation from '../generalinquiry/transportation';
import Food from '../generalinquiry/food';
import Others from '../generalinquiry/others';
import Footer from '../../components/footer';
import './index.scss';

const PlanEvent = () => {
    const [ tab, setTab ] = useState("")
    return (
        <React.Fragment>
            <Header />
            <Container fluid className="px-0">
                <Row className="inner_width_div">
                    <Col xl={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
                        <Form className="mt-12"> 
                            <MainRFP />
                            <SelectBar setTab={setTab} />
                            { 
                            tab === "Flight" ?
                            <div>
                                <Flight setTab={setTab} />
                            </div> :  
                            tab === "Accomodate" ? 
                            <div>
                                <Flight />
                                <Accomodate setTab={setTab} />
                            </div> : 
                            tab === "MeetingRoom" ?
                            <div>
                                <Flight />
                                <Accomodate />
                                <MeetingRoom setTab={setTab} />
                            </div> : 
                            tab === "Program" ?
                            <div>
                                <Flight />
                                <Accomodate />
                                <MeetingRoom />
                                <Program setTab={setTab} />
                            </div> : 
                            tab === "Branding" ?
                            <div>
                                <Flight />
                                <Accomodate />
                                <MeetingRoom />
                                <Program />
                                <Branding setTab={setTab} />
                            </div> : 
                            tab === "Transportation" ?
                            <div>
                                <Flight />
                                <Accomodate />
                                <MeetingRoom />
                                <Program />
                                <Branding />
                                <Transportation setTab={setTab} />
                            </div> : 
                            tab === "Food" ?
                            <div>
                                <Flight />
                                <Accomodate />
                                <MeetingRoom />
                                <Program />
                                <Branding />
                                <Transportation />
                                <Food setTab={setTab} />
                            </div> : 
                            tab === "Others" ?
                            <div>
                                <Flight />
                                <Accomodate />
                                <MeetingRoom />
                                <Program />
                                <Branding />
                                <Transportation />
                                <Food />
                                <Others setTab={setTab} />
                            </div> : ""
                        }
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </React.Fragment>
    )
}

export default PlanEvent;
