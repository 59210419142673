import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { getVenueListById, removeVenueFromList } from "../../server/Filter";
import "./index.scss";

const PreferredVenueDetail = () => {
  const alert = useAlert();
  const { id } = useParams();
  const history = useHistory();
  const [venueList, setVenueList] = useState({});

  useEffect(() => {
    getVenueListById(id)
      .then((data) => setVenueList(data))
      .catch((err) => console.log(err));
  }, [id]);

  const venueCards = venueList.venues
    ? venueList.venues.map((venue) => ({
        id: venue.id,
        source: venue.thumbnail?.smaller_image || venue.thumbnail?.image,
        name: venue.venue_name,
        address: venue.venue_address,
        guestRooms: venue.guest_rooms,
        meetingSpace: venue.total_meeting_space,
        meetingRooms: venue.meeting_rooms,
        maxCapacity: venue.maximum_capacity,
      }))
    : [];

  const [hoverIdentity, setHoverIdentity] = useState("");
  const [btnShow, setBtnShow] = useState("");

  const handleRemoveVenue = async (venueId, venueName) => {
    const venueListCopy = [...venueList.venues];
    try {
      await removeVenueFromList(venueId);
      const updatedVenueList = venueListCopy.filter(
        (list) => list.id !== venueId
      );
      if (updatedVenueList.length < 1) {
        history.goBack();
      }
      setVenueList({
        ...venueList,
        venues: updatedVenueList,
      });
      alert.show(`${venueName} removed from ${venueList.venue_list_name}`, {
        type: "success",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const rendervenueCard = (venueCard) => {
    return (
      <Row className="mb-5 mb-sm-7" key={venueCard.id}>
        <Col
          xl={3}
          lg={4}
          sm={5}
          className="px-0"
          onMouseEnter={() => {
            setBtnShow("show");
            setHoverIdentity(venueCard.id);
          }}
          onMouseLeave={() => setBtnShow("")}
        >
          <Link
            to={{
              pathname: `/venue_sourcing/venues/${venueCard.name}`,
              state: { showHeart: true },
            }}
          >
            <Image
              src={venueCard.source}
              fluid
              className="preffered_venue_img_single"
            />
          </Link>
          {btnShow === "show" && venueCard.id === hoverIdentity ? (
            <div
              className="w-100 flex-column position-absolute"
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Row>
                <Col xl={2} lg={2} md={2} sm={3}></Col>
                <Col xl={8} lg={8} md={8} sm={6}>
                  <div className="new_exist_btn_row_link">
                    <button className="existing_rfp_button">
                      Add to existing RFP
                    </button>
                    <Link to="/event_form">
                      <button className="new_rfp_button">Add to New RFP</button>
                    </Link>
                  </div>
                </Col>
                <Col xl={2} lg={2} md={2} sm={3}></Col>
              </Row>
            </div>
          ) : null}
          <div
            className="position-absolute"
            style={{
              width: "30px",
              top: "2%",
              right: "2%",
              transform: "translate(2%, 2%)",
            }}
            onClick={() => {
              handleRemoveVenue(venueCard.id, venueCard.name);
            }}
          >
            <FavoriteIcon style={{ color: "red" }} />
          </div>
        </Col>
        <Col xl={9} lg={8} sm={7} className="px-0 px-sm-5">
          <Link
            to={{
              pathname: `/venue_sourcing/venues/${venueCard.name}`,
              state: { showHeart: true },
            }}
            className="text-decoration-none"
          >
            <p className="text-dark text-left font-weight-bold text-lg-150 text-125 mt-5 mt-md-0">
              {venueCard.name}
            </p>
          </Link>
          <div className="d-flex">
            <address className="font-weight-bold">Address:</address>
            &nbsp;&nbsp;&nbsp;
            <address>{venueCard.address}</address>
          </div>
          <Row>
            <Col xl={5} xs={6} className="px-0">
              <div className="d-flex">
                <div className="text-left mr-5">
                  <p className="text-lg-100 text-75 mb-2">Guest Rooms:</p>
                </div>
                <div className="text-right">
                  <p>{venueCard.guestRooms}</p>
                </div>
              </div>
            </Col>
            <Col xl={7} xs={6} className="pr-0">
              <div className="d-flex">
                <div className="text-left mr-3">
                  <p className="text-lg-100 text-75 mb-2">
                    Total Meeting Space:
                  </p>
                </div>
                <div className="text-right">
                  <p>{venueCard.meetingSpace}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={5} xs={6} className="px-0">
              <div className="d-flex">
                <div className="text-left mr-5">
                  <p className="text-lg-100 text-75 mb-2">Meeting rooms:</p>
                </div>
                <div className="text-right">
                  <p>{venueCard.meetingRooms}</p>
                </div>
              </div>
            </Col>
            <Col xl={7} xs={6} className="pr-0">
              <div className="d-flex">
                <div className="text-left mr-5">
                  <p className="text-lg-100 text-75 mb-2">Maximum Capacity:</p>
                </div>
                <div className="text-right">
                  <p>{venueCard.maxCapacity}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <React.Fragment>
      <Header />
      <Container fluid className="px-0">
        <Row className="inner_width_div">
          <Col xs={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
            <Row className="my-5 align-items-center">
              <Col xs={6} className="px-0">
                <p className="text-left font-weight-bold text-lg-150 text-125 mb-0">
                  {venueList.venue_list_name}
                </p>
              </Col>
              <Col xs={6} className="text-right px-0">
                <Link
                  to="/venue_sourcing/preferred_venues"
                  className="back_button"
                >
                  &lt;back
                </Link>
              </Col>
            </Row>
            <div>{venueCards.map(rendervenueCard)}</div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default PreferredVenueDetail;
