const FULFILLED = "fulfilled";

export const getPromiseValue = (promiseResponse, defaultValue = null) => {
  if (promiseResponse.status === FULFILLED) return promiseResponse.value;
  return defaultValue;
};

export const getPromisesValues = (promisesResponseArray = []) => {
  const responseArray = promisesResponseArray.map((item) =>
    item.status === FULFILLED ? item.value : []
  );
  return responseArray;
};
