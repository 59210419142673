import React, {useState} from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import BoothForm from './booth_form';
import { create_general_request_form } from '../../server/Filter'
function Quotegeneral(props) {const [formData, setformData] = useState({
  conference: "conference",
  no_of_delegates: "no_of_delegates",
  category: "category",
  member_field: "member_field",
  hotel_check_in_date: "2020-08-13",
  hotel_check_out_date: "2020-08-13",
  hotel_type: "hotel_type",
  hotel_Occupancy: "hotel_Occupancy",
  meeting_date: "2020-08-13",
  meeting_number_of_attendees: "meeting_number_of_attendees",
  meeting_time: "meeting_time",
  meeting_type: "meeting_type",
  airport_transfer: true,
  congress_transfer: true,
  dinner_transfer: false,
  lunch_date: "2020-08-13",
  dinner_on_site_date: "2020-08-13",
  dinner_off_site_date: "2020-08-13",
  on_site_assistance: true,
  remarks: "remarks",
});
function _onSubmit() {
  console.log(formData);
  create_general_request_form(formData).then(
    (res) => {
      console.log(res);
    }
  );
  alert("submitted");
}
    return (
      <React.Fragment>
        <Header />
        <BoothForm
          setInput={(name, val) => {
            console.log("INSETINput", name, val);
            var obj = {};
            obj[name] = val;
            setformData({ ...formData, ...obj });
          }}
          onSubmit={_onSubmit}
        />
        <Footer />
      </React.Fragment>
    );
}

export default Quotegeneral;
