import moment from "moment";

export const formatDate = (date) =>
  date ? moment(date).format("YYYY-MM-DD") : undefined;

export const transformRFPPayload = (payload) => {
  const {
    alternative_date_start,
    alternative_date_end,
    registration_flag,
    rfpregistration,
    flight_ticket_flag,
    rfpflightticket,
    visa_assistance_flag,
    rfpvisaassistance,
    accomodation_flag,
    rfpaccomodation,
    meeting_rooms_flag,
    rfpmeetingroom,
    ground_transportation_flag,
    rfpgroundtransportation,
    f_and_b_arrangement_flag,
    f_and_b_arrangement,
    tour_entertainment_flag,
    tour_entertainment,
    other_requirement_flag,
    other_requirements,
    production_flag,
    rfpproduction,
  } = payload;

  const formattedMeetingRooms = rfpmeetingroom.map((room) => ({
    ...room,
    meeting_time_start: room.meeting_time_start || undefined,
    meeting_time_end: room.meeting_time_start || undefined,
  }));

  return {
    ...payload,
    registration_flag: registration_flag || false,
    flight_ticket_flag: flight_ticket_flag || false,
    visa_assistance_flag: visa_assistance_flag || false,
    accomodation_flag: accomodation_flag || false,
    meeting_rooms_flag: meeting_rooms_flag || false,
    ground_transportation_flag: ground_transportation_flag || false,
    f_and_b_arrangement_flag: f_and_b_arrangement_flag || false,
    tour_entertainment_flag: tour_entertainment_flag || false,
    other_requirement_flag: other_requirement_flag || false,
    production_flag: production_flag || false,
    alternative_date_start: formatDate(alternative_date_start),
    alternative_date_end: formatDate(alternative_date_end),
    rfpregistration: registration_flag ? rfpregistration : undefined,
    rfpflightticket: flight_ticket_flag ? rfpflightticket : undefined,
    rfpvisaassistance: visa_assistance_flag ? rfpvisaassistance : undefined,
    rfpaccomodation: accomodation_flag ? rfpaccomodation : undefined,
    rfpmeetingroom: meeting_rooms_flag ? formattedMeetingRooms : undefined,
    rfpgroundtransportation: ground_transportation_flag
      ? rfpgroundtransportation
      : undefined,
    f_and_b_arrangement: f_and_b_arrangement_flag
      ? f_and_b_arrangement
      : undefined,
    tour_entertainment: tour_entertainment_flag
      ? tour_entertainment
      : undefined,
    other_requirements: other_requirement_flag ? other_requirements : undefined,
    rfpproduction: production_flag ? rfpproduction : undefined,
  };
};
