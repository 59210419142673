import React from 'react';
import FileHandlerProvider, {
  FileHandlerContext,
} from '../context/FileHandlerContext';

// Wrapping child component with Provider in which we can get data in props as well as
// from context

const withFileHandler =
  ChildComponent =>
  ({ ...childComponentProps }) => {
    return (
      <FileHandlerProvider>
        <FileHandlerContext.Consumer>
          {fileHandlerProps => (
            <ChildComponent {...fileHandlerProps} {...childComponentProps} />
          )}
        </FileHandlerContext.Consumer>
      </FileHandlerProvider>
    );
  };

export default withFileHandler;
