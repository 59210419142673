import React from "react";

const SectionWrapper = ({ heading, coverFullSection = false, children }) => {
  return (
    <div className="w-100 d-flex flex-column">
      <h4 className="main-heading">{heading}</h4>
      <div className={coverFullSection ? "w-100" : "w-50"}>{children}</div>
    </div>
  );
};

export default SectionWrapper;
