import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useServiceRequestChatContext } from "../context/ChatContext";
import FormikTextEditor from "../../../../shared/components/form/FormikTextEditor";
import LoadingButton from "../../../../components/common/LoadingButton";
import MultiFilePicker, {
  useMultiFilePickerContext,
} from "../../../../components/common/multifile-picker";
import { extractContentFromHTML } from "../../../../utils/helpers";

const MessageForm = () => {
  const { sendMessage } = useServiceRequestChatContext();
  const { isUploadingFiles, successfulFiles, resetValues } =
    useMultiFilePickerContext();

  return (
    <div className="d-flex flex-column">
      <Formik
        initialValues={{ content: "", attachments: [] }}
        validationSchema={Yup.object().shape({
          content: Yup.string()
            .trim()
            .test(
              "ContentTest",
              "Please add something",
              (value) =>
                successfulFiles.length > 0 || // verify if has 1 or more attachments
                Boolean(extractContentFromHTML(value)) || // verify if has text content
                /\p{Extended_Pictographic}/gu.test(value) // verify emoji existence
            ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const payload = {
            ...values,
            attachments: [...successfulFiles].map((item) => ({
              url: item.src,
              name: item.file.name,
            })),
          };
          sendMessage(JSON.stringify(payload));
          setSubmitting(false);
          resetValues();
          resetForm();
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form className="custom-form service-request-message-form">
            <div className="mt-5">
              <FormikTextEditor name="content" onEnter={submitForm} />
            </div>
            <div className="mt-5">
              <MultiFilePicker />
            </div>
            <div className="mt-5">
              <LoadingButton
                type="submit"
                className="primary-button"
                isLoading={isSubmitting}
                disabled={isSubmitting || isUploadingFiles}
              >
                {isSubmitting ? "Sending..." : "Send"}
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MessageForm;
