import { useEffect, useState } from "react";
import { formatUnderScoreText } from "../../../utils/helpers";

export const useGetLocationSuggestionOptions = (data = []) => {
  const [transformedData, setTransformedData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const transformedData = data.map((item) => ({
        label: item,
        value: item,
      }));

      setTransformedData(transformedData);
    }
  }, [data]);

  return transformedData;
};

export const useTransformListToOptions = (data = []) => {
  const [transformedData, setTransformedData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const transformedData = data.map((item) => ({
        label: formatUnderScoreText(item),
        value: item,
      }));

      setTransformedData(transformedData);
    }
  }, [data]);

  return transformedData;
};
