import React from "react";
import { Row, Col, Image, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFieldArray } from "react-hook-form";
import EventServeiceButtons from "../event_service_buttons";
import EventText from "../event_text";

const EventBranding = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
    control,
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "branding.items",
  });

  const brandsCards = [
    {
      source: "assets/images/BrandingCategory/Capture1.PNG",
      head1: 'Pen "Model V"',
      head2: "PN0001",
    },
    {
      source: "assets/images/BrandingCategory/Capture3.PNG",
      head1: "Spring Notebook",
      head2: "NT0001:",
    },
    {
      source: "assets/images/BrandingCategory/Capture2.PNG",
      head1: "Pen with Lanyard",
      head2: "PN0002:",
    },
  ];

  const disableBrandingType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      brandingDisabled: true,
    }));
  };

  const enableBrandingType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      brandingDisabled: false,
    }));
  };

  const renderbrandsCard = (brandsCard, index) => {
    return (
      <Col xl={4} className="px-2" key={index}>
        <div className="event_accomodate_div">
          <Image
            src={brandsCard.source}
            className="w-100"
            style={{ height: "90px" }}
            fluid
          />
          <p className="text-lg-125 text-100 mt-3 mb-1">{brandsCard.head1}</p>
          <p className="text-lg-100 text-75 mb-2">Code: {brandsCard.head2}</p>
          <address></address>
          <Row>
            <Col xl={12} className="px-0">
              <button onClick={(e) => e.preventDefault()}>Add to RFP</button>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };
  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" xl={3} lg={3} md={3}>
          <label htmlFor="upload">Upload file:</label>
        </Col>
        <Col className="px-0" xl={6} lg={6} md={6}>
          <label className="upload_file">
            {" "}
            Choose File
            <input
              className="file-upload__input"
              type="file"
              name="myFile[]"
              id="myFile"
              multiple
            />
          </label>
        </Col>
        <Col xl={3} lg={3} md={3}></Col>
      </Row>

      <Row className="form_main">
        <Col xl={3}></Col>
        <Col xl={6} className="px-0">
          <Table bordered hover>
            <thead className="text-center">
              <tr>
                <th>BRANDING CODE</th>
                <th>QUANTITY</th>
                <th>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((value, index) => (
                <tr key={value.id} className="reg_table">
                  <td className="p-0">
                    <input
                      type="text"
                      placeholder="PN0001"
                      className="bg-white px-3"
                      name={`branding.items[${index}].code`}
                    />
                  </td>
                  <td className="p-0">
                    <input
                      type="text"
                      placeholder=""
                      className="bg-white px-3"
                      name={`branding.items[${index}].quantity`}
                    />
                  </td>
                  <td className="p-0">
                    <input
                      type="text"
                      placeholder=""
                      className="bg-white px-3"
                      name={`branding.items[${index}].remarks`}
                    />
                    {index !== 0 && (
                      <div className="item-actions-container">
                        <div className="item-actions">
                          <button
                            className="Button_hide"
                            onClick={() => remove(index)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col xl={3} />
        <Col xl={6} className="px-0">
          <div className="event_form_addmore_btn">
            <button
              className="Button_hide mx-0 mb-3"
              onClick={() => append({ code: "", quantiy: "", remarks: "" })}
            >
              Add More
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={3}></Col>
        <Col xl={6} className="px-0">
          <Row>{brandsCards.map(renderbrandsCard)}</Row>
        </Col>
        <Col xl={3} className="text-left my-auto">
          <Link
            to="/branding_material"
            className="text-dark text-decoration-none"
          >
            Explore More Branding Materials
          </Link>
        </Col>
      </Row>
      <EventText heading="Other Requirements:" />
      <EventServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableBrandingType}
        enableSelectedType={enableBrandingType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default EventBranding;
