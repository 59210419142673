import React from "react";
import { Row, Col } from "react-bootstrap";
import EventServeiceButtons from "../event_service_buttons";
import EventText from "../event_text";

const EventProgram = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
  } = props;

  const disableProgramType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      programDisabled: true,
    }));
  };

  const enableProgramType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      programDisabled: false,
    }));
  };

  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="attendees">Number of Attendees:</label>
        </Col>
        <Col className="px-0" md={6}>
          <input name="attendees" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="px-0">
          <label className="big_checkbox">
            <p className="text-lg-125 text-100 mb-0">
              Same program as the brochure
            </p>
            <input type="checkbox" id="same_program" name="same_program" />
            <span className="big_checkmark"></span>
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="px-0">
          <label className="big_checkbox">
            <p className="text-lg-125 text-100 mb-0">
              Other program (please provide the detailed preffered day by day
              program in the text box below)
            </p>
            <input type="checkbox" id="other_program" name="other_program" />
            <span className="big_checkmark"></span>
          </label>
        </Col>
      </Row>
      <EventText heading="" />
      <EventServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableProgramType}
        enableSelectedType={enableProgramType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default EventProgram;
