import React from "react";
import Tooltip from "./CustomTooltip";

const ButtonWithToolTip = ({
  tooltipText,
  tooltipPosition,
  children,
  type = "button",
  ...restProps
}) => {
  return (
    <Tooltip placement={tooltipPosition} text={tooltipText}>
      <button type={type} {...restProps}>
        <span className="d-inline-block">{children}</span>
      </button>
    </Tooltip>
  );
};

export default ButtonWithToolTip;
