import React, {useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import GiftSearchBar from '../giftsearcbar';
import Text from '../../generalinquiry/text';

const Souvenirs = ({ setTab }) => {
    const [showResult, setShowResult] = useState(false)
    const onClicks = (e) => {
        e.preventDefault()
        setShowResult(true)
    }
    const [showResults, setShowResults] = useState(false)
    const onClick = (e) => {
        e.preventDefault()
        setShowResults(true)
    }
    const [showgiftsearch, setShowgiftsearch] = useState("")
    const onClickes = (e) => {
        e.preventDefault()
        setShowgiftsearch("GiftSearchBar")
    }
    return (
        <React.Fragment>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>SOUVENIRS CODE</th>
                        <th>QUANTITY</th>
                        <th>REMARKS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>PN0001</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
            <Row>
                <Col sm={12} className="px-0 text-75">
                    <div className="float-right">
                    <button className="Button_hide gen_inquiry_func_buttons">Cancel</button>
                        <button 
                            className="Button_hide gen_inquiry_func_buttons" 
                            onClick={onClicks}
                        >
                            Save
                        </button>
                    </div>
                </Col>
            </Row>
            { showResult ? 
                <Row id="result">
                    <Col sm={12} className="px-0 text-75">
                        <div className="float-right w-100">
                            <button className="Button_hide gen_inquiry_func_buttons">Edit</button>                          
                            <button className="Button_hide gen_inquiry_func_buttons">Delete</button>
                            <Link to="/gifts">
                                <button className="Button_hide gen_inquiry_func_buttons">AddMore</button>
                            </Link>
                        </div>
                    </Col>
                </Row> : null 
            }
            <Text heading="Requirements:" />
            <Row>
                <Col sm={12} className="px-0">
                    <div className="float-right">
                        <button className="Button" onClick={onClick}>Save</button>
                    </div>
                </Col>
            </Row>
            { showResults ? 
                <Row id="results">
                    <Col sm={12} className="px-0">
                        <div className="float-right w-100">
                            <button className="Button gen_inquiry_func_buttons">Send Request</button>
                            <button className="Button gen_inquiry_func_buttons">Delete Service</button>                          
                            <button className="Button gen_inquiry_func_buttons" onClick={onClickes}>Add Service</button>
                            <button className="Button gen_inquiry_func_buttons">Edit</button>
                        </div>
                    </Col>
                </Row> : null 
            }
            { showgiftsearch === "GiftSearchBar" ? <GiftSearchBar setTab={setTab} /> : null }
        </React.Fragment>
    )
}

export default Souvenirs;
