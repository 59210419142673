import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../components/header';
import Conferencehead from '../../components/conferencehead';
import Conferencetab from '../../components/conferencetab';
import Forms from '../generalinquiryes/form/form';
import LogisticAdmin from '../generalinquiryes/form/logisticadmin';
import Footer from '../../components/footer';

const RequestQuoteAdmin = () => {
    return(
        <React.Fragment>
            <Header />
            <Conferencehead />
            <Container fluid>
                <Row>
                    <Col md={8}>
                        <Conferencetab />
                    </Col>
                    <Col md={4}></Col>
                </Row> 
            </Container>
            <Container>
                <Row>
                    <Col className="mt-12" md={12}>
                        <Forms />
                        <LogisticAdmin />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </React.Fragment>
    )
}

export default RequestQuoteAdmin;