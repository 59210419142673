/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useAlert } from "react-alert";

// COMPONENTS & UTILITIES
import FormikModernField from "../../../shared/components/form/FormikModernField";
import FormikGalleryField from "../../../shared/components/form/FormikGalleryField";
import FormikSubmitBtn from "../../../shared/components/form/FormikSubmitBtn";
import { getVenueGuestRoomsDetailsQuery } from "../../../services/venue-details";
import { guestRoomInitValues } from "../utilities/formUtils";
import { updateGuestRoomDetailsMutation } from "../../../services/venue-management";
import { useGetFormContext } from "../context/FormContext";
import { getUpdatedGuestRoomsData } from "../utilities/helpers";

function GuestRoomsTabPanel() {
  const [initValues, setInitValues] = useState(guestRoomInitValues);
  const alert = useAlert();
  const { setCurrentTab, setPayload, venueId } = useGetFormContext();

  useEffect(() => {
    (async () => {
      if (venueId) {
        const roomDetailsResp = await getVenueGuestRoomsDetailsQuery(venueId);
        const newValues = getUpdatedGuestRoomsData(roomDetailsResp);

        setInitValues((prevState) => ({
          ...prevState,
          ...newValues,
        }));
      }
    })();
  }, [venueId]);

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      onSubmit={async (values) => {
        if (venueId) {
          const updateResp = await updateGuestRoomDetailsMutation(
            venueId,
            values
          );

          if (updateResp.status === 400) {
            alert.error("Error updating guest room details!");
            return;
          }

          const getUpdatedResp = await getVenueGuestRoomsDetailsQuery(venueId);
          const newValues = getUpdatedGuestRoomsData(getUpdatedResp);

          setInitValues((prevState) => ({
            ...prevState,
            ...newValues,
          }));

          alert.success("Venue guest room details updated successfully!");
          return;
        }

        setCurrentTab((prevState) => prevState + 1);
        setPayload((prevState) => ({ ...prevState, ...values }));
      }}
    >
      {({ values }) => (
        <Form className="d-flex align-items-start is-grid">
          <FormikModernField
            label="Single Guest Rooms"
            name="single_rooms"
            isGrid
            type="number"
          />

          <FormikModernField
            label="Double Guest Rooms"
            name="double_rooms"
            isGrid
            type="number"
          />

          <FormikModernField
            label="Suite Guest Rooms"
            name="suite_rooms"
            type="number"
          />

          <FormikGalleryField
            name="guest_room_images"
            uploadURL="/sourcing/venues/manage-bucket/"
            deleteURL="/sourcing/venues/manage-bucket/"
          />

          <FormikSubmitBtn label={venueId ? "Submit" : "Next"} />
        </Form>
      )}
    </Formik>
  );
}

export default GuestRoomsTabPanel;
