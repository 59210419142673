import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Row, Col } from "react-bootstrap";
import FormikField from "../../../shared/components/form/FormikField";
import LoadingButton from "../LoadingButton";

const ReasonForm = ({ label = "Reason", onSubmit }) => {
  return (
    <Formik
      initialValues={{ reason: "" }}
      validationSchema={Yup.object().shape({
        reason: Yup.string().required("Required"),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        onSubmit(values.reason);
        resetForm();
      }}
    >
      {({ isSubmitting }) => (
        <Form className="custom-form">
          <Row>
            <Col md={3}>
              <p className="text-100 mb-0 required">{label}</p>
            </Col>
            <Col md={9} className="pl-0">
              <FormikField name="reason" textArea rows={5} />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="d-flex justify-content-center">
              <LoadingButton
                type="submit"
                className="button primary-button"
                isLoading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ReasonForm;
