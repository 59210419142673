import React from "react";
import propTypes from "prop-types";

const handleNavigateBack = () => {
  window.history.back();
};

const BackButton = ({ children, className, onClick, ...restProps }) => {
  return (
    <div className="BackBtn">
      <button
        onClick={onClick}
        {...restProps}
        className={`back_button ${className}`}
      >
        <img
          height={20}
          src="/assets/images/icon-back.png"
          className="mr-2"
          alt="Back Button"
        />
        {children}
      </button>
    </div>
  );
};

BackButton.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
  onClick: propTypes.func,
};

BackButton.defaultProps = {
  children: "Back",
  className: "",
  onClick: handleNavigateBack,
};

export default BackButton;
