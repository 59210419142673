import React, { createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import useFetchRequest from "../../../../components/common/custom-hooks/useFetchRequest";
import { getServiceRequestDetails } from "../../../../server/Filter";
import { CLOSED, OPEN } from "../utils/constants";

export const ServiceRequestDetailsContext = createContext({
  isLoading: true,
  serviceRequestDetails: null,
  isRequestOpen: false,
  isRequestClosed: false,
});

export const useServiceRequestDetailsContext = () =>
  useContext(ServiceRequestDetailsContext);

const DetailsProvider = ({ children }) => {
  const { requestID } = useParams();
  const {
    data: serviceRequestDetailsData = {},
    isLoading: isLoadingServiceRequestDetails,
  } = useFetchRequest(getServiceRequestDetails, requestID);

  return (
    <ServiceRequestDetailsContext.Provider
      value={{
        isLoading: isLoadingServiceRequestDetails,
        serviceRequestDetails: serviceRequestDetailsData,
        isRequestOpen: serviceRequestDetailsData.status === OPEN,
        isRequestClosed: serviceRequestDetailsData.status === CLOSED,
      }}
    >
      <div>{children}</div>
    </ServiceRequestDetailsContext.Provider>
  );
};

export default DetailsProvider;
