import React from "react";
import DatePicker from "react-datepicker";
import { useField, useFormikContext } from "formik";
import moment from "moment";

const FormikDatePicker = ({
  name,
  placeholder = "",
  dateDisplayFormat = "yyyy/MM/dd",
  dateValueFormat = "YYYY-MM-DD",
  onChange = null,
  onBlur = null,
  ...restProps
}) => {
  const [field, meta] = useField(name || "");
  const { setFieldValue } = useFormikContext();

  const { onBlur: onFieldBlur, value } = field;
  const { touched, error } = meta;

  const handleChange = (date) => {
    if (date && moment(date).isValid()) {
      const selectedDate = moment(date).format(dateValueFormat);
      setFieldValue(name, selectedDate);
      if (onChange) onChange(selectedDate);
    }
  };

  const handleBlur = (event) => {
    onFieldBlur(event);
    if (onBlur) onBlur(name, event.target.value);
  };

  const isErrorField = touched && error;
  const selectedDate = value ? moment(value, dateValueFormat).toDate() : null;

  return (
    <React.Fragment>
      <div className="date-picker">
        <DatePicker
          {...restProps}
          className={isErrorField ? "error-field" : ""}
          name={name}
          selected={selectedDate}
          onChange={handleChange}
          onBlur={handleBlur}
          showYearDropdown
          dateFormat={dateDisplayFormat}
          dropDownMode="select"
          popperPlacement="center"
          placeholderText={placeholder}
          shouldCloseOnSelect
          autoComplete="off"
        />
        {touched && error && (
          <span className="form__form-group-error">{error}</span>
        )}
      </div>
    </React.Fragment>
  );
};

export default FormikDatePicker;
