import React from "react";
import { useEventCoordinationContext } from "../context/EventCoordinationContext";
import SimpleTable from "../../../../shared/components/table/SimpleTable";
import { HISTORY_STATE_KEY } from "../utils/constants";
import { tableHeadings } from "./utils/headings";

const History = () => {
  const {
    data: { [HISTORY_STATE_KEY]: historyRecords },
  } = useEventCoordinationContext();

  return (
    <SimpleTable
      headings={tableHeadings}
      data={historyRecords}
      alternateText="There are no history records"
      rounded
    />
  );
};

export default History;
