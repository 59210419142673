import React from "react";
import { Form, Row, Col, Image } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import TextEditor from "../../../../components/textEditor";
import ImagesUploader from "../../../../components/imageUploader";
// import Input from "../../../../components/customInputField";
import { API_URL } from "../../../../constants";
import {
  deleteFloorPlanImage,
  deleteMeetingRoomImage,
} from "../../../../server/Filter";
import "./index.scss";

const { PUBLIC_URL } = process.env;

const initialTab = "#fff";

const VenueSpace = ({
  setStyle,
  hideComponent,
  register,
  control,
  errors,
  property,
  // setProperty,
  isSubmitting,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "venue_rooms",
  });

  // const handleDeleteMeetingImage = (id) => {
  //   const filteredImages = property.meeting_room_images.filter(
  //     (item) => item.id !== id
  //   );
  //   const newValues = {
  //     ...property,
  //     meeting_room_images: filteredImages,
  //   };
  //   setProperty(newValues);
  //   deleteMeetingRoomImage(id).catch((err) => console.log(err));
  // };

  const handleDeleteMeetingImage = (id) => {
    deleteMeetingRoomImage(id).catch((err) => console.log(err));
  };

  // const handleDeleteFloorImage = (id) => {
  //   const filteredImages = property.floor_plan_images.filter(
  //     (item) => item.id !== id
  //   );
  //   const newValues = {
  //     ...property,
  //     floor_plan_images: filteredImages,
  //   };
  //   setProperty(newValues);
  //   deleteFloorPlanImage(id).catch((err) => console.log(err));
  // };

  const handleDeleteFloorImage = (id) => {
    deleteFloorPlanImage(id).catch((err) => console.log(err));
  };

  return (
    <React.Fragment>
      <div className="my-5" style={{ display: hideComponent ? "none" : "" }}>
        {errors?.venue_rooms && (
          <div className="mt-3 text-danger">
            <p>Please fill required fields in order to proceed.</p>
          </div>
        )}
        <div>
          <label>Capacity Chart</label>
        </div>
        <Row className="venue_space_row_icon">
          <Col xs={2} className="venue_space_table_column"></Col>
          <Col xs={2} className="venue_space_table_column"></Col>
          <Col xs={1} className="venue_space_table_column"></Col>
          <Col xs={1} className="venue_space_table_column">
            <Image
              src={`${PUBLIC_URL}/assets/pages/venue/icons/capture1.PNG`}
              fluid
            />
          </Col>
          <Col xs={1} className="venue_space_table_column">
            <Image
              src={`${PUBLIC_URL}/assets/pages/venue/icons/capture2.PNG`}
              fluid
            />
          </Col>
          <Col xs={1} className="venue_space_table_column">
            <Image
              src={`${PUBLIC_URL}/assets/pages/venue/icons/capture3.PNG`}
              fluid
            />
          </Col>
          <Col xs={1} className="venue_space_table_column">
            <Image
              src={`${PUBLIC_URL}/assets/pages/venue/icons/capture4.PNG`}
              fluid
            />
          </Col>
          <Col xs={1} className="venue_space_table_column">
            <Image
              src={`${PUBLIC_URL}/assets/pages/venue/icons/capture5.PNG`}
              fluid
            />
          </Col>
          <Col xs={1} className="venue_space_table_column">
            <Image
              src={`${PUBLIC_URL}/assets/pages/venue/icons/capture6.PNG`}
              fluid
            />
          </Col>
          <Col xl={1} className="venue_space_table_column px-0"></Col>
        </Row>
        <Row className="venue_space_row">
          <Col xs={2} className="venue_space_table_column">
            <p className="text-xl-100 text-lg-75 text-sm-50 text-25 mb-2">
              ROOM NAME
            </p>
          </Col>
          <Col xs={2} className="venue_space_table_column">
            <p className="text-xl-100 text-lg-75 text-sm-50 text-25 mb-2">
              Dimension
              <br />L x W x H
            </p>
          </Col>
          <Col xs={1} className="venue_space_table_column">
            <p className="text-xl-100 text-lg-75 text-sm-50 text-25 mb-2">
              Area M<sup>2</sup>
            </p>
          </Col>
          <Col xs={1} className="venue_space_table_column">
            <p className="text-xl-100 text-lg-75 text-sm-50 text-25 mb-2">
              Banquet
            </p>
          </Col>
          <Col xs={1} className="venue_space_table_column">
            <p className="text-xl-100 text-lg-75 text-sm-50 text-25 mb-2">
              Reception
            </p>
          </Col>
          <Col xs={1} className="venue_space_table_column">
            <p className="text-xl-100 text-lg-75 text-sm-50 text-25 mb-2">
              Theatre
            </p>
          </Col>
          <Col xs={1} className="venue_space_table_column">
            <p className="text-xl-100 text-lg-75 text-sm-50 text-25 mb-2">
              Class
              <br />
              Room
            </p>
          </Col>
          <Col xs={1} className="venue_space_table_column">
            <p className="text-xl-100 text-lg-75 text-sm-50 text-25 mb-2">
              Board
              <br />
              Room
            </p>
          </Col>
          <Col xs={1} className="venue_space_table_column">
            <p className="text-xl-100 text-lg-75 text-sm-50 text-25 mb-2">
              U<br />
              Shape
            </p>
          </Col>
          <Col xl={1} className="venue_space_table_column px-0"></Col>
        </Row>
        {fields.map((value, index) => (
          <Row key={value.id} className="mb-1">
            <Col xs={2} className="venue_space_table_column px-0">
              <input
                type="text"
                className="w-100"
                name={`venue_rooms[${index}].room_name`}
                defaultValue={value.room_name}
                style={{
                  height: "50px",
                  border: "1px solid #ced4da",
                  backgroundColor: "#e9ecef",
                }}
                ref={register()}
                // inputRef={register({ required: true })}
                // error={
                //   errors?.venue_rooms &&
                //   errors?.venue_rooms[index]?.room_name &&
                //   "Room name is required!"
                // }
              />
            </Col>
            <Col xs={2} className="venue_space_table_column px-0">
              <input
                type="text"
                className="w-100 px-4"
                defaultValue={value.dimensions}
                style={{
                  height: "50px",
                  border: "1px solid #ced4da",
                  backgroundColor: "#e9ecef",
                }}
                ref={register()}
                name={`venue_rooms[${index}].dimensions`}
              />
            </Col>
            <Col xs={1} className="venue_space_table_column px-0">
              <input
                type="text"
                className="w-100 px-4"
                defaultValue={value.area}
                style={{
                  height: "50px",
                  border: "1px solid #ced4da",
                  backgroundColor: "#e9ecef",
                }}
                ref={register()}
                name={`venue_rooms[${index}].area`}
              />
            </Col>
            <Col xs={1} className="venue_space_table_column px-0">
              <input
                type="text"
                className="w-100 px-4"
                defaultValue={value.banquet}
                style={{
                  height: "50px",
                  border: "1px solid #ced4da",
                  backgroundColor: "#e9ecef",
                }}
                ref={register()}
                name={`venue_rooms[${index}].banquet`}
              />
            </Col>
            <Col xs={1} className="venue_space_table_column px-0">
              <input
                type="text"
                className="w-100 px-4"
                defaultValue={value.reception}
                style={{
                  height: "50px",
                  border: "1px solid #ced4da",
                  backgroundColor: "#e9ecef",
                }}
                ref={register()}
                name={`venue_rooms[${index}].reception`}
              />
            </Col>
            <Col xs={1} className="venue_space_table_column px-0">
              <input
                type="text"
                className="w-100 px-4"
                defaultValue={value.theatre}
                style={{
                  height: "50px",
                  border: "1px solid #ced4da",
                  backgroundColor: "#e9ecef",
                }}
                ref={register()}
                name={`venue_rooms[${index}].theatre`}
              />
            </Col>
            <Col xs={1} className="venue_space_table_column px-0">
              <input
                type="text"
                className="w-100 px-4"
                defaultValue={value.class_room}
                style={{
                  height: "50px",
                  border: "1px solid #ced4da",
                  backgroundColor: "#e9ecef",
                }}
                ref={register()}
                name={`venue_rooms[${index}].class_room`}
              />
            </Col>
            <Col xs={1} className="venue_space_table_column px-0">
              <input
                type="text"
                className="w-100 px-4"
                defaultValue={value.board_room}
                style={{
                  height: "50px",
                  border: "1px solid #ced4da",
                  backgroundColor: "#e9ecef",
                }}
                ref={register()}
                name={`venue_rooms[${index}].board_room`}
              />
            </Col>
            <Col xs={1} className="venue_space_table_column px-0">
              <input
                type="text"
                className="w-100 px-4"
                defaultValue={value.u_shape}
                style={{
                  height: "50px",
                  border: "1px solid #ced4da",
                  backgroundColor: "#e9ecef",
                }}
                ref={register()}
                name={`venue_rooms[${index}].u_shape`}
              />
            </Col>
            <Col xs={1} className="venue_space_table_column px-0 my-auto">
              {index !== 0 && (
                <span
                  tabIndex="0"
                  role="button"
                  className="action ml-2"
                  onClick={(e) => {
                    e.preventDefault();
                    remove(index);
                  }}
                >
                  <CancelIcon />
                </span>
              )}
            </Col>
          </Row>
        ))}
        <div className="my-5">
          <button
            type="button"
            className="Button_hide"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              append({
                room_name: "",
                dimensions: "",
                area: "",
                banquet: "",
                reception: "",
                theatre: "",
                class_room: "",
                board_room: "",
                u_shape: "",
              });
            }}
          >
            + Add Room
          </button>
        </div>
        <Form.Group controlId="formBasicText">
          <Form.Label>
            Meeting Rooms Gallery (Please include a picture of the Foyer Area as
            well)
          </Form.Label>
          <ImagesUploader
            name="meeting_room_images"
            label="Upload Meeting Room Images"
            control={control}
            uploadUrl={API_URL + "/venueMeetingRoomImages/"}
            defaultImages={property?.meeting_room_images}
            deleteImage={handleDeleteMeetingImage}
          />
        </Form.Group>
        <Form.Group controlId="formBasicText" className="mb-2">
          <Form.Label>DDR Inclusions:</Form.Label>
          <TextEditor
            name="meeting_room_notes"
            control={control}
            defaultValue={property?.meeting_room_notes}
          />
        </Form.Group>
        <small className="mb-5 text-success">
          <strong>Note:</strong> To paste the text inside text editor, Please
          use this command <strong>(ctrl + shift + v)</strong>
        </small>
        <Form.Group controlId="formBasicText" className="mt-5">
          <Form.Label>DDR Rate Starting from:</Form.Label>
        </Form.Group>
        <Row>
          <Col lg={6} className="px-0 pr-lg-4">
            <Form.Group controlId="formBasicText" className="d-md-flex">
              <Form.Label className="pr-2 add_property_venue_label mt-2 text-nowrap">
                Half-day
              </Form.Label>
              <Form.Control
                name="ddr_rate_half_day"
                type="text"
                ref={register}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6} className="px-0">
            <Form.Group controlId="formBasicText" className="d-md-flex">
              <Form.Label className="add_property_venue_label mt-2 text-nowrap">
                Full-day
              </Form.Label>
              <Form.Control
                name="ddr_rate_full_day"
                type="text"
                ref={register}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formBasicText">
          <Form.Label>
            Do you have a virtual tour of the hotel/meeting rooms?
          </Form.Label>
          <span className="d-flex">
            <Form.Check
              type="radio"
              name="vr_tour_available"
              className="ml-5"
              ref={register}
              value="true"
            />{" "}
            Yes
            <Form.Check
              type="radio"
              name="vr_tour_available"
              className="ml-5"
              ref={register}
              value="false"
              defaultChecked
            />{" "}
            No
          </span>
        </Form.Group>
        <Form.Group controlId="formBasicText">
          <Form.Label>
            If yes, can you please share with us the link?
          </Form.Label>
          <Form.Control name="vr_tour_url" type="url" ref={register} />
        </Form.Group>
        <Form.Group controlId="formBasicText" className="mb-0">
          <Form.Label>Floor Plans Images:</Form.Label>
          <ImagesUploader
            name="floor_plan_images"
            label="Upload Floor Plan Images"
            control={control}
            uploadUrl={API_URL + "/venueFloorPlanImages/"}
            defaultImages={property?.floor_plan_images}
            deleteImage={handleDeleteFloorImage}
          />
        </Form.Group>
        <div className="text-center mt-7">
          <hr />
          <p>
            <strong className="mr-2">Important Note! </strong>
            Before saving changes, Please make sure all the images must be
            uploaded successfully.
          </p>
          <hr />
        </div>
        <div className="w-100 d-flex justify-content-end">
          <button
            className="Button_continue mt-7 mb-4 mr-5"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Saving..." : "Save"}
          </button>
          <button
            className="Button_continue mt-7 mb-4"
            type="submit"
            onClick={() => {
              setStyle(
                initialTab,
                initialTab,
                initialTab,
                "#FFAC33",
                initialTab,
                "restaurant"
              );
              window.scrollTo(0, 0);
            }}
          >
            Save & Continue
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VenueSpace;
