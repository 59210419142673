import React from "react";
import { BsFillCaretRightFill } from "react-icons/bs";

function NextArrow({ currentSlide, slideCount, onClick }) {
  return (
    <div className="icons iconRight" onClick={onClick}>
      <BsFillCaretRightFill
        className={
          currentSlide + 1 === slideCount ? "slider-disabled-button" : ""
        }
      />
    </div>
  );
}

export default NextArrow;
