export const REQUESTED = "Requested";
export const REQUESTED_AGAIN = "Requested Again";
export const RESCHEDULED = "Rescheduled";
export const APPROVED = "Approved";
export const DECLINED = "Declined";
export const CANCELLED = "Cancelled";

export const CANCEL_MODE = "cancel";
export const APPROVE_MODE = "approve";
export const DECLINE_MODE = "decline";
export const RESCHEDULE_MODE = "reschedule";

export const categoryOptions = [
  {
    label: "On Site",
    value: "on-site",
  },
  {
    label: "Virtual",
    value: "virtual",
  },
];
