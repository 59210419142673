import React, { useMemo } from "react";
import SimpleTable from "../../../../../shared/components/table/SimpleTable";
import formatAmount from "../../../../../utils/formatAmount";
import { budgetItemTableHeadings } from "../utils/headings";

// Child
const BudgetItemRecord = ({ heading, value }) => (
  <div className="row">
    <strong className="col-3 px-0">{heading}</strong>
    <pre className="col-9 px-0">{value}</pre>
  </div>
);

// Parent
const BudgetItemDetails = ({ selectedBudgetItem }) => {
  const formattedItemData = useMemo(() => {
    if (selectedBudgetItem) {
      return [
        {
          cost_details: "No of Units",
          estimated_budget: selectedBudgetItem.estimated_num_units || 0,
          reconciled_budget: selectedBudgetItem.reconciled_num_units || 0,
        },
        {
          cost_details: "No of Nights",
          estimated_budget: selectedBudgetItem.estimated_num_nights || 0,
          reconciled_budget: selectedBudgetItem.reconciled_num_nights || 0,
        },
        {
          cost_details: "Unit Cost",
          format: selectedBudgetItem.formatted_currency || "AED",
          estimated_budget: formatAmount(
            selectedBudgetItem.estimated_unit_cost || 0
          ),
          reconciled_budget: formatAmount(
            selectedBudgetItem.reconciled_unit_cost || 0
          ),
        },
        {
          cost_details: "VAT Rate",
          format:
            selectedBudgetItem.reconciled_vat_rate_label ||
            selectedBudgetItem.estimated_vat_rate_label,
          estimated_budget: formatAmount(
            selectedBudgetItem.estimated_vat_amount || 0
          ),
          reconciled_budget: formatAmount(
            selectedBudgetItem.reconciled_vat_amount || 0
          ),
        },
        {
          cost_details: "Total",
          estimated_budget: formatAmount(
            selectedBudgetItem.estimated_total_unit_cost || 0
          ),
          reconciled_budget: formatAmount(
            selectedBudgetItem.reconciled_total_unit_cost || 0
          ),
        },
      ];
    }
    return [];
  }, [selectedBudgetItem]);

  if (!selectedBudgetItem) return null;

  return (
    <React.Fragment>
      <div className="mb-10">
        <BudgetItemRecord
          heading="Category"
          value={selectedBudgetItem.item || "-"}
        />
        <BudgetItemRecord
          heading="Description"
          value={selectedBudgetItem.item_description || "-"}
        />
        <BudgetItemRecord
          heading="Currency"
          value={selectedBudgetItem.formatted_currency || "AED"}
        />
      </div>
      <SimpleTable
        headings={budgetItemTableHeadings}
        data={formattedItemData}
        alternateText="There are no item details"
        rounded
        customRows={
          <tr>
            <td colSpan={3} />
            <td align="center">
              <span className="font-weight-bold pr-5">Savings:</span>
              <span className="text-success font-weight-bold">
                {formatAmount(selectedBudgetItem.savings || 0)}
              </span>
            </td>
          </tr>
        }
      />
    </React.Fragment>
  );
};

export default BudgetItemDetails;
