import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import "./index.scss";

const OurServices = ({ services }) => (
  <Row className="">
    {services?.slice(0, 4).map((service) => (
      <Col xl={3} md={3} className="px-2 px-sm-1" key={service.service_id}>
        <div
          className="d-flex align-items-center mt-8"
          style={{ border: "1px solid #e9ecef" }}
        >
          <a href={service.service_link}>
            <Card className="serviceCard">
              <Card.Img variant="top" src={service.service_image} />
              <Card.Body className="cardBodyService">
                <img src={service.service_icon} alt="" />
                <Card.Title className="cardTitleService">
                  {service.service_name}
                </Card.Title>
              </Card.Body>
            </Card>
          </a>
        </div>
      </Col>
    ))}
  </Row>
);

export default OurServices;
