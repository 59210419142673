export const tableHeadings = [
  {
    id: "assigned_to",
    label: "User",
    align: "left",
  },
  {
    id: "event_role",
    label: "Role",
  },
  {
    id: "assigned_by",
    label: "Assigned by",
  },
  {
    id: "created_at",
    label: "Assigned Date",
  },
];
