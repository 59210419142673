/* eslint-disable camelcase */

import React, { Component, Fragment } from "react";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { keys } from "lodash/object";
import { forEach } from "lodash/collection";
import { startCase } from "lodash/string";
import { isArray } from "lodash/lang";

class Alerts extends Component {
  static propTypes = {
    alert: PropTypes.shape({
      show: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.shape({}),
    message: PropTypes.shape({}),
  };

  static defaultProps = {
    error: {},
    message: {},
  };

  componentDidUpdate(prevProps) {
    const { error, message, alert } = this.props;
    if (error !== prevProps.error) {
      if (error.msg.non_field_errors) {
        alert.show(error.msg.non_field_errors.join(), { type: "error" });
      } else {
        if (error.msg.profile) {
          const { profile } = error.msg;
          const p = keys(profile);
          if (p.length > 0) {
            forEach(p, (s) => {
              const msg = isArray(profile[s]) ? profile[s].join() : profile[s];
              alert.show(`${startCase(s)} - ${msg}`, {
                type: "error",
              });
            });
          }
          delete error.msg.profile;
        }
        if (error.msg.quotation_notes) {
          const { quotation_notes } = error.msg;
          if (quotation_notes.length > 0) {
            forEach(quotation_notes, (note) => {
              const p = keys(note);
              if (p.length > 0) {
                forEach(p, (s) => {
                  const msg = isArray(note[s]) ? note[s].join() : note[s];
                  alert.show(`QUOTATION NOTES - ${startCase(s)} - ${msg}`, {
                    type: "error",
                  });
                });
              }
            });
          }
          delete error.msg.quotation_notes;
        }
        const k = keys(error.msg);
        if (k.length > 0) {
          forEach(k, (s) => {
            const msg = isArray(error.msg[s])
              ? error.msg[s].join()
              : error.msg[s];
            alert.show(`${startCase(s)} - ${msg}`, {
              type: "error",
            });
          });
        } else {
          // console.log(error.msg);
        }
      }
    }

    if (message !== prevProps.message) {
      alert.show(message.message, { type: "success" });
    }
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(withAlert()(Alerts));
