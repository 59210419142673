import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Datepick from '../../generalinquiryes/form/datepick';  
import ServeiceButtons from '../service_buttons';
import Text from '../text';
import './index.scss';

const Accomodate = ({ setTab }) => {
    return (
        <React.Fragment>
            <Form>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor="hotel">Preffered Hotel:</label>
                    </Col>
                    <Col className="px-0" md={6}>
                        <input name="hotel" />
                    </Col>
                    <Col lg={3} md={3}></Col>
                </Row>
                <Row className="form_main">
                    <Col className="px-0" md={3} xs={6}>
                        <label htmlFor="date_in">Check-in Date:</label>
                        <img
                            style={{ marginRight: '5px' }}
                            className="calendar float-right"
                            src={"assets/images/download.jpg"}
                            alt="Not Found"
                            />
                    </Col>
                    <Col className="px-0" xs={6}>
                        <Datepick name="date_in" />
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Row className="form_main">
                    <Col className="px-0" md={3} xs={6}>
                        <label htmlFor="date_out">Check-out Date:</label>
                        <img
                            style={{ marginRight: '5px' }}
                            className="calendar float-right"
                            src={"assets/images/download.jpg"}
                            alt="Not Found"
                        />
                    </Col>
                    <Col className="px-0" xs={6}>
                        <Datepick name="date_out" />
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor="rooms">Number of rooms:</label>
                    </Col>
                    <Col className="px-0" md={6}>
                        <input name="rooms" />
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor="type">Type:</label>
                    </Col>
                    <Col className="px-0" md={6}>
                    <select name="type">
                        <option>Room Only</option>
                        <option>Bed and Breakfast</option>
                        <option>Half Board</option>
                        <option>Full Board</option>
                    </select>
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Row className="form_main">
                    <Col className="px-0" md={3}>
                        <label htmlFor="occupancy">Type:</label>
                    </Col>
                    <Col className="px-0" md={6}>
                    <select name="occupancy">
                        <option>Single Occupancy</option>
                        <option>Double Occupancy</option>
                    </select>
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Text heading="Requirements:" />
                <ServeiceButtons setTab={setTab} />
            </Form>
        </React.Fragment>
    )
}

export default Accomodate;
