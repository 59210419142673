export const myAccountFormInitialValues = {
  username: "",
  email: "",
  full_name: "",
  profession: "",
  contact: "",
};

export const userFormInitialValues = {
  account_name: "",
  username: "",
  email: "",
  full_name: "",
  profession: "",
  contact: "",
  password: "",
  role: "",
  comment: "",
};

export const changePasswordFormInitialValues = {
  password: "",
  confirm_password: "",
};
