import React, { useRef, useLayoutEffect, useState } from "react";
import { Row, Col, Button, Image, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { css } from "emotion";
import Slider from "react-slick";
import "./index.scss";

function Gift({ gift, setDisplay }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({});
  const equal = css`
    height: ${(dimensions.width * 2) / 3 + "px"};
  `;
  // holds the timer for setTimeout and clearInterval
  let movementTimer = null;
  // the number of ms the window size must stay the same size before the
  // dimension state variable is reset
  const RESET_TIMEOUT = 100;
  const testDimensions = () => {
    // For some reason targetRef.current.getBoundingClientRect was not available
    // I found this worked for me, but unfortunately I can't find the
    // documentation to explain this experience
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  };
  // This sets the dimensions on the first render
  useLayoutEffect(() => {
    testDimensions();
  }, []);
  // every time the window is resized, the timer is cleared and set again
  // the net effect is the component will only reset after the window size
  // is at rest for the duration set in RESET_TIMEOUT.  This prevents rapid
  // redrawing of the component for more complex components such as charts
  window.addEventListener("resize", () => {
    clearInterval(movementTimer);
    movementTimer = setTimeout(testDimensions, RESET_TIMEOUT);
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={true}
        className="venue_loc_modal_gallery central_modal"
      >
        <Modal.Header className="venue_modal_header" closeButton></Modal.Header>
        <Modal.Body>
          <div className="App">
            <div className="slider-wrapper">
              <Slider {...settings}>
                {gift.sliderImages &&
                  gift.sliderImages.map((slide) => (
                    <div className="slick-slide" key={slide.image}>
                      <Image
                        className="slick-slide-image modal_slider_img w-100"
                        src={slide.image}
                        fluid
                      />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Row className="gift_row mb-7" key={gift.id}>
        <Col xl={5} lg={5} md={6} className="px-0 px-md-4">
          <div className="App h-auto">
            <div className="slider-wrapper">
              <Slider {...settings}>
                {gift.sliderImages &&
                  gift.sliderImages.map((slide) => (
                    <div className="slick-slide" key={slide.image}>
                      <Image
                        className={
                          "slick-slide-image souvenirs_slider w-100 " + equal
                        }
                        src={slide.image}
                        fluid
                        ref={targetRef}
                      />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          <div className="fullscreen_branding position-absolute">
            <Button
              variant="primary"
              onClick={handleShow}
              className="p-0 bg-transparent border-0 shadow-none"
            >
              <FullscreenIcon
                style={{
                  fill: "#000",
                  width: "2em",
                  height: "2em",
                }}
              />
            </Button>
          </div>
        </Col>
        <Col lg={7} md={6} className="px-0 px-md-4 mt-3 mt-md-0">
          <p className="text-lg-150 text-125 mb-2">{gift.heading}</p>
          <div className="d-flex">
            <p className="mb-lg-4 mb-md-2 mb-4">Code: {gift.code}</p>
            <p className="ml-lg-20 ml-md-12 ml-20 mb-lg-4 mb-md-2 mb-4">
              Price: {gift.price}AED
            </p>
          </div>
          <div className="mb-lg-4 mb-md-2 mb-4">
            Description:
            {gift.description && parse(gift.description.toString())}
          </div>
          <div>
            <Button className="Button" onClick={() => setDisplay(true)}>
              Add to Existing RFP
            </Button>
            <Link to="/giftRFP">
              <Button className="Button ml-xl-7 ml-lg-5 ml-3">
                Create a New RFP
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Gift;
