import React, { memo } from "react";
import { Row, Col } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import { getDummyVenueImage } from "../../../utils/helpers";

const HotelMapCard = ({ venue, handleSelectVenue }) => {
  return (
    <Row
      className="pt-5 pb-5"
      onClick={() => handleSelectVenue(venue)}
      style={{ borderBottom: "1px solid lightgray", cursor: "pointer" }}
    >
      <Col md={4} className="pr-0">
        <img
          src={getDummyVenueImage(venue?.property_logo)}
          className="w-100 imageFirst"
          alt=""
          style={{ height: "75px" }}
        />
      </Col>

      <Col md={8}>
        <Row className="hotelName">
          <p
            style={{
              whiteSpace: "pre",
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {venue.venue_name}
          </p>
        </Row>

        <Row className="my-2">
          <Col className="pl-0">
            <StarRatings
              rating={venue?.star_rating || 0.0}
              starRatedColor="#F2BB13"
              numberOfStars={5}
              starDimension="20px"
              starSpacing="0px"
              name="rating"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default memo(HotelMapCard);
