import React from "react";
import { FieldArray } from "formik";
import { Table, Button } from "react-bootstrap";
import { MdRemoveCircle } from "react-icons/md";
import FormikField from "../../../shared/components/form/FormikField";
import ServiceQuestionController from "./ServiceQuestionController";
import { singleRegistrationInitialValues } from "../utils/initialValues";

const Registration = () => {
  return (
    <div className="w-100">
      <ServiceQuestionController
        questionText="Do you require registration?"
        flag="registration_flag"
        stepNo={2}
      >
        <FieldArray
          name="rfpregistration"
          render={({ form, push, remove }) => {
            return (
              <Table className="rfp-table">
                <thead>
                  <tr>
                    <td width="50%">Category</td>
                    <td width="50%">Number of Delegates</td>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {form.values.rfpregistration.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <FormikField
                            name={`rfpregistration[${index}].category`}
                            placeholder="Category"
                          />
                        </td>
                        <td>
                          <FormikField
                            type="number"
                            name={`rfpregistration[${index}].number_of_delegates`}
                            placeholder="Number of Delegates"
                          />
                        </td>
                        {form.values.rfpregistration.length > 1 && (
                          <MdRemoveCircle
                            size={20}
                            className="remove-icon table-rows-remove-icon"
                            onClick={() => remove(index)}
                          />
                        )}
                      </tr>
                    ))}
                    <tr className="d-flex">
                      <Button
                        className="mt-5 button small-button"
                        onClick={() => push(singleRegistrationInitialValues)}
                      >
                        + Add Registration
                      </Button>
                    </tr>
                  </React.Fragment>
                </tbody>
              </Table>
            );
          }}
        />
      </ServiceQuestionController>
    </div>
  );
};

export default Registration;
