import React from "react";
import { Row, Col } from "react-bootstrap";
import DatePicker from "../../../components/customDatePicker";
import MedServeiceButtons from "../med_service_buttons";
import Input from "../../../components/customInputField";

const MedFlight = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
    register,
    control,
    errors,
  } = props;

  const disableFlightType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      flightDisabled: true,
    }));
  };

  const enableFlightType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      flightDisabled: false,
    }));
  };

  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="pl-0" md={3}>
          <label htmlFor="ticket_from">From:</label>
          <Input
            name="flight_ticket.ticket_from"
            inputRef={register({ required: true })}
            error={
              errors?.flight_ticket?.ticket_from && "This field is required!"
            }
          />
        </Col>
        <Col className="pl-0" md={3}>
          <label htmlFor="ticket_to">To:</label>
          <Input
            name="flight_ticket.ticket_to"
            inputRef={register({ required: true })}
            error={
              errors?.flight_ticket?.ticket_to && "This field is required!"
            }
          />
        </Col>
        <Col className="pl-0" md={3}>
          <label htmlFor="departure_date">Departure:</label>
          <DatePicker name="flight_ticket.departure_date" control={control} />
          <img
            style={{
              marginRight: "5px",
              position: "absolute",
              bottom: "5px",
              right: "15px",
              zIndex: "-1",
            }}
            className="calendar float-right"
            src={"assets/images/download.jpg"}
            alt="Not Found"
          />
        </Col>
        <Col className="pl-0" md={3}>
          <label htmlFor="return_date">Return:</label>
          <DatePicker name="flight_ticket.return_date" control={control} />
          <img
            style={{
              marginRight: "5px",
              position: "absolute",
              bottom: "5px",
              right: "15px",
              zIndex: "-1",
            }}
            className="calendar float-right"
            src={"assets/images/download.jpg"}
            alt="Not Found"
          />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="pl-0" md={3}>
          <label htmlFor="preferred_airline">Preffered Airline (if any):</label>
          <Input
            name="flight_ticket.preferred_airline"
            inputRef={register({ required: true })}
            error={
              errors?.flight_ticket?.preferred_airline &&
              "This field is required!"
            }
          />
        </Col>
        <Col className="pl-0" md={3}>
          <label htmlFor="num_passengers">Number of passengers:</label>
          <Input
            name="flight_ticket.num_passengers"
            inputRef={register({ required: true })}
            error={
              errors?.flight_ticket?.num_passengers && "This field is required!"
            }
          />
        </Col>
        <Col md={6}></Col>
      </Row>
      <Row>
        <Col xs={12} className="px-0">
          <div className="gen_inquiry_text">
            <p className="text-lg-150 text-125 mb-2">
              Requirements:
            </p>
          </div>
          <Input
            textArea
            name="flight_ticket.requirements"
            inputRef={register}
          />
        </Col>
      </Row>
      <MedServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableFlightType}
        enableSelectedType={enableFlightType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default MedFlight;
