import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
// import MedServiceType from '../med_service_type';

const MedServeiceButtons = ({
  addServiceForms,
  deleteServiceForm,
  disableSelectedType,
  enableSelectedType,
  setStatus,
}) => {
  const [showResults, setShowResults] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const onClick = (e) => {
    e.preventDefault();
    setShowResults(true);
    disableSelectedType();
  };
  //   const [showSearchType, setShowSearchType] = useState("");
  const onClicks = (e) => {
    e.preventDefault();
    // setShowSearchType("MedServiceType")
    addServiceForms();
    setDisabled(true);
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    deleteServiceForm();
    enableSelectedType();
  };

  return (
    <React.Fragment>
      <Row>
        {showResults === false ? (
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="px-0">
            <div className="float-right">
              <button className="Button" onClick={onClick}>
                Save
              </button>
            </div>
          </Col>
        ) : null}
      </Row>
      {showResults ? (
        <Row id="results">
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="px-0">
            <div className="float-right w-100">
              <button
                onClick={() => setStatus("sent")}
                type="submit"
                className="Button gen_inquiry_func_buttons"
              >
                Send Request
              </button>
              <button
                onClick={() => setStatus("saved")}
                type="submit"
                className="Button gen_inquiry_func_buttons"
              >
                Send Later
              </button>
              <button
                onClick={onDeleteClick}
                className="Button gen_inquiry_func_buttons"
                disabled={disabled}
              >
                Delete Service
              </button>
              <button
                className="Button gen_inquiry_func_buttons"
                onClick={onClicks}
                disabled={disabled}
              >
                Add Service
              </button>
              <button className="Button gen_inquiry_func_buttons">Edit</button>
            </div>
          </Col>
        </Row>
      ) : null}
      {/* { showSearchType === "MedServiceType" ? <MedServiceType setTab={setTab} /> : null } */}
    </React.Fragment>
  );
};

export default MedServeiceButtons;
