// import axios from "axios";
// import { API_URL } from "../constants";
import http from "./httpService";

export const getVenueBasicDetailsQuery = async (venueId) => {
  const response = await http.get(
    `/sourcing/venues/venue-detail/${venueId}/basic_profile/`,
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const getVenueOverviewDetailsQuery = async (venueId) => {
  const response = await http.get(
    `/sourcing/venues/venue-detail/${venueId}/overview/`,
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const getVenueGuestRoomsDetailsQuery = async (venueId) => {
  const response = await http.get(
    `/sourcing/venues/venue-detail/${venueId}/guest_room/`,
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const getVenueMeetingSpaceDetailsQuery = async (venueId) => {
  const response = await http.get(
    `/sourcing/venues/venue-detail/${venueId}/meeting_venues/`,
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const getRoomDetailsByIdQuery = async (roomId) => {
  const response = await http.get(
    `/sourcing/venues/get-meeting-room-detail/${roomId}/`,
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const getVenueAttractionsDetailsQuery = async (venueId) => {
  const response = await http.get(
    `/sourcing/venues/venue-detail/${venueId}/attractions/`,
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};

export const getVenueLocationDetailsQuery = async (venueId) => {
  const response = await http.get(
    `/sourcing/venues/venue-detail/${venueId}/location/`,
    { headers: http.getTokenHeaders() }
  );

  return response.data;
};
