import React from "react";
import { FieldArray } from "formik";
import { Table, Button } from "react-bootstrap";
import { MdRemoveCircle } from "react-icons/md";
import FormikField from "../../../shared/components/form/FormikField";
import FormikSelect from "../../../shared/components/form/FormikSelect";
import ServiceQuestionController from "./ServiceQuestionController";
import { singleFAandBArrangementInitialValues } from "../utils/initialValues";
import { fAndBArrangementCategortOptions } from "../utils/constants";

const FandBArrangement = () => {
  return (
    <div className="w-100">
      <ServiceQuestionController
        questionText="Do you require F&B Arrangement?"
        flag="f_and_b_arrangement_flag"
        stepNo={9}
      >
        <FieldArray
          name="f_and_b_arrangement"
          render={({ form, push, remove }) => {
            return (
              <Table className="rfp-table">
                <thead>
                  <tr>
                    <td width="33.33%">Category</td>
                    <td width="33.33%">Description</td>
                    <td width="33%.33">No. of days</td>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {form.values.f_and_b_arrangement.map((_, index) => (
                      <tr key={index}>
                        <td>
                          <FormikSelect
                            name={`f_and_b_arrangement[${index}].category`}
                            placeholder="Category"
                            options={fAndBArrangementCategortOptions}
                          />
                        </td>
                        <td>
                          <FormikField
                            name={`f_and_b_arrangement[${index}].description`}
                            placeholder="Description"
                          />
                        </td>
                        <td>
                          <FormikField
                            name={`f_and_b_arrangement[${index}].no_of_days`}
                            type="number"
                            placeholder="No. of days"
                          />
                        </td>
                        {form.values.f_and_b_arrangement.length > 1 && (
                          <MdRemoveCircle
                            size={20}
                            className="remove-icon table-rows-remove-icon"
                            onClick={() => remove(index)}
                          />
                        )}
                      </tr>
                    ))}
                    <tr className="d-flex">
                      <Button
                        className="mt-5 button small-button"
                        onClick={() =>
                          push(singleFAandBArrangementInitialValues)
                        }
                      >
                        + Add F&B Arrangement
                      </Button>
                    </tr>
                  </React.Fragment>
                </tbody>
              </Table>
            );
          }}
        />
      </ServiceQuestionController>
    </div>
  );
};

export default FandBArrangement;
