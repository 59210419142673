import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import TextEditor from "../../../../components/textEditor";
import ImagesUploader from "../../../../components/imageUploader";
import { API_URL } from "../../../../constants";
import {
  deleteSliderImage,
  deleteVenueThumbnailImage,
} from "../../../../server/Filter";
import countries from "../../../../utils/location-data/countriesList";
import "./index.scss";

const initialTab = "#fff";

const ratingOptions = [
  { value: "3-star", label: "3-Star" },
  { value: "4-star", label: "4-Star" },
  { value: "5-star", label: "5-Star" },
  { value: "unrated", label: "Unrated" },
];

const BasicInfo = ({
  setStyle,
  hideComponent,
  register,
  control,
  errors,
  property,
  setProperty,
  isSubmitting,
}) => {
  // const handleDeleteDefaultImage = (id) => {
  //   const filteredImages = property.slider.filter((item) => item.id !== id);
  //   const newValues = {
  //     ...property,
  //     slider: filteredImages,
  //   };
  //   setProperty(newValues);
  //   deleteSliderImage(id).catch((err) => console.log(err));
  // };

  const handleDeleteSliderImage = (id) => {
    deleteSliderImage(id).catch((err) => console.log(err));
  };

  const handleRemovePropertyLogo = (id) => {
    if (setProperty) {
      setProperty({
        ...property,
        property_logo2: null,
      });
    }
  };

  const handleDeleteVenueThumbnailImage = (id) => {
    if (setProperty) {
      setProperty({
        ...property,
        thumbnail: null,
      });
    }

    deleteVenueThumbnailImage(id).catch((err) => console.log(err));
  };

  return (
    <React.Fragment>
      {(errors?.venue_name ||
        errors?.venue_address ||
        errors?.partner_email ||
        errors?.city ||
        errors?.country ||
        errors?.star_rating) && (
        <div className="mt-3 text-danger">
          <p>Please fill required fields in order to proceed.</p>
        </div>
      )}
      <div style={{ display: hideComponent ? "none" : "" }} className="my-7">
        <Row>
          <Col sm={12} className="px-0">
            <Form.Group controlId="formBasicText">
              <Form.Label>Property Logo</Form.Label>
              <ImagesUploader
                name="property_logo2"
                control={control}
                uploadUrl={API_URL + "/file/upload/"}
                deleteImage={handleRemovePropertyLogo}
                multiple={false}
                maxFiles={1}
                defaultImages={property?.property_logo2}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="px-0 pr-xl-4">
            <Form.Group controlId="formBasicText">
              <Form.Label>Property Name*</Form.Label>
              <Form.Control
                name="venue_name"
                type="text"
                ref={register({ required: true })}
                isInvalid={errors.venue_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.venue_name && "Property name is required!"}
              </Form.Control.Feedback>
              <div className="text-success">
                <small>Info: Property/Venue Name must be unique.</small>
              </div>
            </Form.Group>
          </Col>
          <Col xl={6} className="px-0">
            <Form.Group controlId="formBasicText">
              <Form.Label>Email Address*</Form.Label>
              <Form.Control
                name="partner_email"
                type="text"
                ref={register({ required: true, pattern: /^\S+@\S+\.\S+$/i })}
                isInvalid={errors.partner_email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.partner_email?.type === "required" &&
                  "Email address is required!"}
                {errors.partner_email?.type === "pattern" &&
                  "Invalid Email Address"}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="px-0 pr-xl-4">
            <Form.Group controlId="formBasicText">
              <Form.Label>Property Address*</Form.Label>
              <Form.Control
                name="venue_address"
                type="text"
                ref={register({ required: true })}
                isInvalid={errors.venue_address}
              />
              <Form.Control.Feedback type="invalid">
                {errors.venue_address && "Property address is required!"}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xl={3} className="px-0 pr-xl-4">
            <Form.Group controlId="formBasicText">
              <Form.Label>City*</Form.Label>
              <Form.Control
                name="city"
                type="text"
                ref={register({ required: true })}
                isInvalid={errors.city}
              />
              <Form.Control.Feedback type="invalid">
                {errors.city?.type === "required" && "City is required!"}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xl={3} className="px-0">
            <Form.Group controlId="formBasicText">
              <Form.Label>Country*</Form.Label>
              <Form.Control
                as="select"
                name="country"
                type="text"
                ref={register({ required: true })}
                isInvalid={errors.country}
              >
                <option value="" />
                {countries.map((country) => (
                  <option
                    key={country}
                    value={country}
                    className="country-options"
                  >
                    {country}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.country?.type === "required" && "Country is required!"}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="px-0">
            <Form.Group controlId="formBasicText" as={Row}>
              <Form.Label className="w-100 px-0" column sm={5}>
                No. of Guest Rooms:
              </Form.Label>
              <Col sm={7}>
                <Form.Control name="guest_rooms" type="number" ref={register} />
              </Col>
            </Form.Group>
          </Col>
          <Col xl={6} className="px-0">
            <Form.Group controlId="formBasicText" as={Row}>
              <Form.Label className="w-100" column sm={5}>
                Total Meeting Space:
              </Form.Label>
              <Col sm={7} className="px-0">
                <Form.Control
                  name="total_meeting_space"
                  type="text"
                  ref={register}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="px-0">
            <Form.Group controlId="formBasicText" as={Row}>
              <Form.Label className="w-100 px-0" column sm={5}>
                No. of Meeting Rooms:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  name="meeting_rooms"
                  type="number"
                  ref={register}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col xl={6} className="px-0">
            <Form.Group controlId="formBasicText" as={Row}>
              <Form.Label className="w-100" column sm={5}>
                Maximum Capacity:
              </Form.Label>
              <Col sm={7} className="px-0">
                <Form.Control
                  name="maximum_capacity"
                  type="number"
                  ref={register}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="px-0">
            <Form.Group controlId="star_rating" as={Row}>
              <Form.Label className="w-100 px-0" column sm={5}>
                Rating*:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  as="select"
                  name="star_rating"
                  type="text"
                  ref={register({ required: true })}
                  isInvalid={errors.star_rating}
                >
                  <option value="" />
                  {ratingOptions.map((rating) => (
                    <option
                      key={rating.value}
                      value={rating.value}
                      className="country-options"
                    >
                      {rating.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.star_rating?.type === "required" &&
                    "Rating is required!"}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formBasicText" className="mb-2">
          <Form.Label className="w-100">Property Overview:</Form.Label>
          <TextEditor
            name="overview"
            control={control}
            defaultValue={property?.overview}
          />
        </Form.Group>
        <Row>
          <small className="mb-7 text-success">
            <strong>Note:</strong> To paste the text inside text editor, Please
            use this command <strong>(ctrl + shift + v)</strong>
          </small>
        </Row>
        <Form.Group controlId="formBasicText" className="mb-2">
          <Form.Label className="w-100">Amenities:</Form.Label>
          <TextEditor
            name="amenities"
            control={control}
            defaultValue={property?.amenities}
          />
        </Form.Group>
        <Row>
          <small className="mb-7 text-success">
            <strong>Note:</strong> To paste the text inside text editor, Please
            use this command <strong>(ctrl + shift + v)</strong>
          </small>
        </Row>
        <Form.Group controlId="formBasicText">
          <Form.Label>Hotel Gallery Thumbnail:</Form.Label>
          <ImagesUploader
            name="thumbnail"
            control={control}
            uploadUrl={API_URL + "/venueThumbnailImages/"}
            deleteImage={handleDeleteVenueThumbnailImage}
            multiple={false}
            maxFiles={1}
            defaultImages={property?.thumbnail}
            info="Info: Thumbnail Image is required."
          />
        </Form.Group>
        <Form.Group controlId="formBasicText" className="mb-0">
          <Form.Label>Hotel Gallery Slider:</Form.Label>
          <ImagesUploader
            name="slider_images"
            control={control}
            uploadUrl={API_URL + "/venueSliderImages/"}
            deleteImage={handleDeleteSliderImage}
            defaultImages={property?.slider_images}
          />
        </Form.Group>
        <div className="text-center mt-7">
          <hr />
          <p>
            <strong className="mr-2">Important Note! </strong>
            Before saving changes, Please make sure all the images must be
            uploaded successfully.
          </p>
          <hr />
        </div>
        <div className="w-100 d-flex justify-content-end">
          <button
            className="Button_continue mt-7 mb-4 mr-5"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Saving..." : "Save"}
          </button>
          <button
            className="Button_continue mt-7 mb-4"
            type="submit"
            onClick={() => {
              setStyle(
                initialTab,
                "#FFAC33",
                initialTab,
                initialTab,
                initialTab,
                "guest"
              );
              window.scrollTo(0, 0);
            }}
          >
            Save & Continue
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BasicInfo;
