export const roomTypes = {
  bankquetRounds: "BANQUET_ROUNDS",
  classroom: "CLASSROOM",
  hollowRquare: "HOLLOW_SQUARE",
  boardRoomConference: "BOARD_ROOM_CONFERENCE",
  theater: "THEATER",
  cocktailRounds: "COCKTAIL_ROUNDS",
  crescentRounds: "CRESCENT_ROUNDS",
  eShaped: "ESHAPED",
  maximumCapacity: "MAXIMUM_CAPACITY",
  perimeterSeating: "PERIMETER_SEATING",
  royalConference: "ROYAL_CONFERENCE",
  talkShow: "TALK_SHOW",
  tShaped: "TSHAPED",
  uShaped: "USHAPED",
};

export const getRoomImageTitleByType = (type) => {
  const url = "/assets/images/";
  const defaultObj = { image: "dummy-card-image.jpg", title: "NA" };

  switch (type) {
    case roomTypes.bankquetRounds:
      defaultObj.image = "banquet-rounds-icon.png";
      defaultObj.title = "Banquet Rounds";
      break;

    case roomTypes.classroom:
      defaultObj.image = "classs-room-icon.png";
      defaultObj.title = "Classroom";
      break;

    case roomTypes.hollowRquare:
      defaultObj.image = "hollow-Square-icon.png";
      defaultObj.title = "Hollow square";
      break;

    case roomTypes.boardRoomConference:
      defaultObj.image = "boardroom-icon.png";
      defaultObj.title = "Boardroom";
      break;

    case roomTypes.theater:
      defaultObj.image = "theater-icon.png";
      defaultObj.title = "Theater";
      break;

    case roomTypes.cocktailRounds:
      defaultObj.image = "cocktail-rounds-icon.png";
      defaultObj.title = "Cocktail rounds";
      break;

    case roomTypes.crescentRounds:
      defaultObj.image = "crescent-rounds-icon.png";
      defaultObj.title = "Crescent rounds (Cabaret)";
      break;

    case roomTypes.uShaped:
      defaultObj.image = "u-shape-icon.png";
      defaultObj.title = "U-Shape";
      break;

    case roomTypes.eShaped:
      // defaultObj.image = "-----";
      defaultObj.title = "E-Shape";
      break;

    case roomTypes.maximumCapacity:
      // defaultObj.image = '';
      defaultObj.title = "Maximum Capacity";
      break;

    case roomTypes.perimeterSeating:
      // defaultObj.image = "-----";
      defaultObj.title = "perimeterSeating";
      break;

    case roomTypes.royalConference:
      // defaultObj.image = "-----";
      defaultObj.title = "royalConference";
      break;

    case roomTypes.talkShow:
      // defaultObj.image = "-----";
      defaultObj.title = "talkShow";
      break;

    case roomTypes.tShaped:
      // defaultObj.image = "-----";
      defaultObj.title = "tShaped";
      break;

    default:
      defaultObj.image = "";
      defaultObj.title = "NA";
  }

  return {
    image: url + (defaultObj?.image || "dummy-image.jpg"),
    title: defaultObj.title,
  };
};
