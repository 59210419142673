import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Datepick from "../../../components/datepick";

const text = "Save";
const newText = "Edit";

const EventMainForm = ({ addServiceForms }) => {
  const [edit, setEdit] = useState(text);

  const changeType = (e) => {
    e.preventDefault();
    setEdit((edit) => (edit === text ? newText : text));
    if (edit === text) {
      addServiceForms();
    }
  };

  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" xl={3} md={4}>
          <label htmlFor="account_name">ACCOUNT NAME:</label>
        </Col>
        <Col xl={6} md={5} className="px-0">
          <input type="text" name="account_name" />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" xl={3} md={4}>
          <label htmlFor="email_address">EMAIL ADDRESS:</label>
        </Col>
        <Col xl={6} md={5} className="px-0">
          <input type="email" name="email_address" />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" xl={3} md={4}>
          <label htmlFor="event_name">EVENT NAME:</label>
        </Col>
        <Col xl={6} md={5} className="px-0">
          <input type="text" name="event_name" />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" xl={3} md={4} sm={5} xs={6}>
          <div className="d-flex">
            <label htmlFor="alternate_date">ALTERNATE DATE:</label>
            <img
              className="calendar ml-auto mr-1"
              src={"assets/images/download.jpg"}
              alt="Not Found"
            />
          </div>
        </Col>
        <Col xl={6} md={5} sm={7} xs={6} className="px-0">
          <Datepick />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" xl={3} md={4} sm={5} xs={6}>
          <div className="d-flex">
            <label htmlFor="event_date">EVENT DATE:</label>
            <img
              className="calendar ml-auto mr-1"
              src={"assets/images/download.jpg"}
              alt="Not Found"
            />
          </div>
        </Col>
        <Col xl={6} md={5} sm={7} xs={6} className="px-0">
          <Datepick />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" xl={3} md={4}>
          <label htmlFor="attendees">NUMBER OF ATTENDEES:</label>
        </Col>
        <Col xl={6} md={5} className="px-0">
          <input type="text" name="attendees" />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" xl={3} md={4}>
          <label htmlFor="city">CITY</label>
        </Col>
        <Col xl={2} md={5} className="px-0">
          <input type="text" name="city" />
        </Col>
        <Col className="pl-0 text-right mt-auto" xl={2} lg={4} md={4}>
          <label htmlFor="country">COUNTRY</label>
        </Col>
        <Col xl={2} md={5} className="px-0">
          <input type="text" name="country" />
        </Col>
      </Row>
      <div className="float-right w-100 plan_event_div">
        <button
          className="Button gen_inquiry_func_buttons"
          onClick={changeType}
        >
          {edit}
        </button>
        {edit === text ? (
          <button className="Button gen_inquiry_func_buttons">
            Cancel RFP
          </button>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default EventMainForm;
