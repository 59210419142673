import React from "react";
import { Form, Row } from "react-bootstrap";
import TextEditor from "../../../../../components/textEditor";
import ImagesUploader from "../../../../../components/imageUploader";
import { API_URL } from "../../../../../constants";
import { deleteRestaurantImage } from "../../../../../server/Filter";

const RestaurantOption = ({
  register,
  control,
  value,
  index,
  property,
  setProperty,
}) => {
  // const handleDeleteDefaultImage = (index, id) => {
  //   let restaurant = property.restaurants[index];
  //   const filteredImages = restaurant.restaurant_images.filter(
  //     (item) => item.id !== id
  //   );
  //   restaurant = { ...restaurant, restaurant_images: filteredImages };
  //   const newValues = {
  //     ...property,
  //     restaurants: property.restaurants.map((room, roomIndex) =>
  //       roomIndex === index ? restaurant : room
  //     ),
  //   };
  //   setProperty(newValues);
  //   deleteRestaurantImage(id).then((err) => console.log(err));
  // };

  const handleDeleteImage = (id) => {
    deleteRestaurantImage(id).catch((err) => console.log(err));
  };

  return (
    <React.Fragment>
      <Form.Group controlId="formBasicText">
        <Form.Label>Restaurant Name:</Form.Label>
        {property?.restaurants && (
          <Form.Control
            style={{ display: "none" }}
            name={`restaurants[${index}].restaurant_id`}
            type="number"
            ref={register()}
            defaultValue={value.restaurant_id || 0}
          />
        )}
        <Form.Control
          name={`restaurants[${index}].restaurant_name`}
          type="text"
          defaultValue={value.restaurant_name}
          ref={register()}
        />
      </Form.Group>
      <div className="d-sm-flex align-items-center mb-4">
        <label className="mb-sm-0 pr-5">Operation hours:</label>
        <input
          type="text"
          defaultValue={value.operating_hours}
          className="border-0 add_property_room_type_sel"
          ref={register()}
          name={`restaurants[${index}].operating_hours`}
        />
      </div>
      <div className="d-sm-flex align-items-center mb-4">
        <label className="mb-sm-0 pr-5">Restaurant's Maximum Capacity:</label>
        <input
          type="text"
          defaultValue={value.max_capacity}
          className="border-0 add_property_room_type_sel"
          ref={register()}
          name={`restaurants[${index}].max_capacity`}
        />
      </div>
      <div className="d-sm-flex align-items-center mb-4">
        <label className="mb-sm-0 pr-5">Type of Cuisine:</label>
        <input
          type="text"
          defaultValue={value.cuisine_type}
          className="border-0 add_property_room_type_sel"
          ref={register()}
          name={`restaurants[${index}].cuisine_type`}
        />
      </div>
      <div className="d-sm-flex align-items-center mb-4">
        <label className="mb-sm-0 pr-5">Location:</label>
        <input
          type="text"
          defaultValue={value.location}
          className="border-0 add_property_room_type_sel"
          ref={register()}
          name={`restaurants[${index}].location`}
        />
      </div>
      <Form.Group controlId="formBasicText" className="mb-2">
        <Form.Label className="w-100">Restaurant Description:</Form.Label>
        <TextEditor
          name={`restaurants[${index}].description`}
          control={control}
          defaultValue={
            property?.restaurants && property?.restaurants[index]?.description
          }
        />
      </Form.Group>
      <Row>
        <small className="mb-7 text-success">
          <strong>Note:</strong> To paste the text inside text editor, Please
          use this command <strong>(ctrl + shift + v)</strong>
        </small>
      </Row>
      <Form.Group controlId="formBasicText">
        <Form.Label>Restaurant Images:</Form.Label>
        <ImagesUploader
          name={`restaurants[${index}].restaurant_images`}
          control={control}
          uploadUrl={API_URL + "/restaurantImages/"}
          defaultImages={
            property?.restaurants &&
            property?.restaurants[index]?.restaurant_images
          }
          deleteImage={(id) => handleDeleteImage(id)}
        />
      </Form.Group>
    </React.Fragment>
  );
};

export default RestaurantOption;
