import React from "react";
import { useParams } from "react-router-dom";
import { useEventPlanningContext } from "../../context/EventPlanningContext";
import FileBrowser from "../../../../../components/fileBrowser";
import {
  addRFPSharedDocs,
  copyRFPDocuments,
  deleteRFPSharedDoc,
  moveRFPDocuments,
  updateRFPSharedDoc,
  uploadRFPDoc,
} from "../../../../../server/Filter";
import { DOCUMENT, SHARED_DOCS_STATE_KEY } from "../../utils/constants";
import { PLANNING } from "../../../utils/constants";

const SharedDocuments = () => {
  const { rfpID } = useParams();
  const {
    data: { [SHARED_DOCS_STATE_KEY]: sharedDocs },
    modifiers: { updateSharedDocs, updatePrivateDocs },
  } = useEventPlanningContext();

  const handleAddNewDocs = async (data) => {
    await addRFPSharedDocs(data.map((item) => ({ ...item, rfp_id: rfpID })));
    await updateSharedDocs();
  };

  const handleRenameDoc = async (data) => {
    await updateRFPSharedDoc({
      ...data,
      rfp_id: rfpID,
    });
    await updateSharedDocs();
  };

  const handleDeleteDoc = async (data) => {
    await deleteRFPSharedDoc({ ...data, rfp_id: rfpID });
    await updateSharedDocs();
  };

  const handleCopyDocs = async (selectedDocuments) => {
    const modifiedDocuments = selectedDocuments.map((item) => ({
      key: item.key,
      modified: new Date().getTime(),
      size: item.size,
      url: item.url,
      doc_type: item.doc_type,
      rfp: item.rfp_id,
    }));
    await copyRFPDocuments({ target_type: "private", data: modifiedDocuments });
    await updatePrivateDocs();
  };

  const handleMoveDocs = async (selectedDocuments) => {
    const modifiedDocuments = [];
    // Separating deletions ids
    const deletionIDs = [];

    selectedDocuments.forEach((item) => {
      deletionIDs.push(item.id);
      modifiedDocuments.push({
        key: item.key,
        modified: new Date().getTime(),
        size: item.size,
        url: item.url,
        doc_type: item.doc_type,
        rfp: item.rfp_id,
      });
    });

    await moveRFPDocuments({
      current_type: "shared",
      deletion_ids: deletionIDs,
      target_type: "private",
      data: modifiedDocuments,
    });

    await updateSharedDocs();
    await updatePrivateDocs();
  };

  return (
    <FileBrowser
      documents={sharedDocs}
      uploadButtonClassName="rfp-action-button"
      onUploadDoc={uploadRFPDoc}
      onAddNewFiles={handleAddNewDocs}
      onCreateFolders={handleAddNewDocs}
      onRenameFile={handleRenameDoc}
      onRenameFolder={handleRenameDoc}
      onDeleteFile={handleDeleteDoc}
      onDeleteFolder={handleDeleteDoc}
      copyTextLabel="Copy To Private"
      onCopy={handleCopyDocs}
      moveTextLabel="Move To Private"
      onMove={handleMoveDocs}
      // For File Editing in Preview
      editDocumentOptions={{
        returnData: {
          url: `/product_launch/${rfpID}`,
          state: { mainTab: PLANNING, subTab: DOCUMENT },
        },
        saveParams: { module: "documents", module_type: "shared" },
      }}
    />
  );
};

export default SharedDocuments;
