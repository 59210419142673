import React from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Col, Row } from "react-bootstrap";
import { useRFPServiceRequestMainContext } from "../context/MainContext";
import FormikField from "../../../../shared/components/form/FormikField";
import LoadingButton from "../../../../components/common/LoadingButton";
import FormRecordWrapper from "./FormRecordWrapper";
import { requestFormInitialValues } from "../utils/initialValues";
import { requestFormValidationSchema } from "../utils/validationSchema";
import { createNewServiceRequest } from "../../../../server/Filter";

const RFPServiceRequestForm = ({ selected, toggle }) => {
  const alert = useAlert();
  const { rfpID } = useParams();
  const { updateServiceRequests } = useRFPServiceRequestMainContext();

  return (
    <Formik
      enableReinitialize
      initialValues={requestFormInitialValues}
      validationSchema={requestFormValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await createNewServiceRequest({ ...values, rfp: rfpID });
          updateServiceRequests();
          setSubmitting(false);
          toggle();
          resetForm();
          alert.success("New request raised successfully");
        } catch (error) {
          setSubmitting(false);
          toggle();
          alert.error("Something went wrong in raising new request");
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form className="custom-form">
          <FormRecordWrapper label="Title">
            <FormikField name="title" placeholder="Enter Request Title" />
          </FormRecordWrapper>
          <FormRecordWrapper label="Description">
            <FormikField
              name="description"
              placeholder="Enter Description"
              textArea
            />
          </FormRecordWrapper>
          <Row className="mt-5">
            <Col>
              <LoadingButton
                type="submit"
                className="button primary-button"
                isLoading={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </LoadingButton>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
RFPServiceRequestForm.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default RFPServiceRequestForm;
