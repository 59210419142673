/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, memo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Row } from "react-bootstrap";
import Pagination from "react-js-pagination";
import HotelCard from "./HotelCard";
import MapModal from "./MapModal";
import { useGetVenuesContext } from "../custom-hooks/useGetVenuesContext";
import { searchVenuesQuery } from "../../../services/venue-sourcing";
import { getSearchParams } from "../../../utils/helpers";

const HotelCards = ({ setMapButtonDisability }) => {
  const { searchParamsObj, searchParams } = useGetVenuesContext();
  const history = useHistory();
  const { state } = useLocation();
  const [venues, setVenues] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const page = searchParams.get("page");

  // SEARCH PARAMS
  const getAsyncVenues = async () => {
    const response = await searchVenuesQuery(searchParamsObj);

    setVenues(response);

    if (response?.results?.length > 0) {
      setMapButtonDisability(false);
    } else setMapButtonDisability(true);
  };

  useEffect(() => {
    if (page) {
      setActivePage(parseInt(page));
    } else {
      setActivePage(1);
    }
  }, [page]);

  useEffect(() => {
    getAsyncVenues();
  }, [searchParams]);

  const handlePageChange = (newPage) => {
    setActivePage(newPage);

    const queryParams = getSearchParams({
      ...searchParamsObj,
      page: newPage,
    });

    if (newPage !== activePage) {
      history.push({
        pathname: "/venue_sourcing/venues",
        search: queryParams,
        state,
      });
    }
  };

  // CONSTANTS
  const venuesPerPage = useMemo(() => 10, []);
  const totalCount = useMemo(() => {
    return venues?.count ?? 0;
  }, [venues]);

  return (
    <div className="hotel-cards-container">
      <MapModal venues={venues?.results} />

      <Row className="cards-wrapper">
        {venues?.results?.map((item) => (
          <HotelCard key={item.id} venue={item} />
        ))}

        {venues?.results?.length < 1 && (
          <h5 className="mt-5">No venues found!</h5>
        )}
      </Row>

      {venues?.results?.length > 0 && (
        <Row className="pagination-wrapper justify-content-center mt-5">
          <div className="paginations">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={venuesPerPage}
              totalItemsCount={totalCount}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
            />
          </div>
        </Row>
      )}
    </div>
  );
};

export default memo(HotelCards);
