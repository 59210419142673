import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { useAlert } from "react-alert";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Input from "../../components/customInputField";
import { submitExhibitionBoothForm } from "../../server/Filter";
import "./index.scss";

const ExhibitionForm = () => {
  const alert = useAlert();
  const profile = useSelector((state) => state.auth.user.profile);
  const [status, setStatus] = useState("");

  const { register, handleSubmit, control, errors, reset, watch } = useForm({
    mode: "onTouched",
    reValidateMode: "onBlur",
  });

  const watchAttachment = watch("attachment");
  const selectedFileName = watchAttachment && watchAttachment[0]?.name;

  const onSubmit = async (data) => {
    const values = { ...data, status };
    try {
      await submitExhibitionBoothForm(values);
      alert.show("Form submitted successfully!", {
        type: "success",
      });
      reset();
    } catch (err) {
      console.log(err);
      alert.show("Something went wrong. Please try again.", {
        type: "error",
      });
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Container fluid className="px-0">
        <Row className="inner_width_div">
          <Col xl={12} className="pl-sm-6 pl-5 pr-5 pr-sm-4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="form_main">
                <Col className="px-0" xl={3} md={5}>
                  <label htmlFor="account_name">ACCOUNT NAME:</label>
                </Col>
                <Col xl={6} md={5} className="px-0">
                  <Input
                    type="text"
                    name="account_name"
                    value={profile.account}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="form_main">
                <Col className="px-0" xl={3} md={5}>
                  <label htmlFor="email_address">EMAIL ADDRESS:</label>
                </Col>
                <Col xl={6} md={5} className="px-0">
                  <Input
                    type="email"
                    name="email_address"
                    value={profile.email}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="form_main">
                <Col className="px-0" xl={3} md={5}>
                  <label htmlFor="exhibition_name">
                    EXHIBITION NAME:
                  </label>
                </Col>
                <Col xl={6} md={5} className="px-0">
                  <Input
                    type="text"
                    name="exhibition_name"
                    inputRef={register({ required: true })}
                    error={
                      errors.exhibition_name && "Exhibition Name is required!"
                    }
                  />
                </Col>
              </Row>
              <Row className="form_main">
                <Col className="px-0" xl={3} sm={5} xs={6}>
                  <div className="d-flex">
                    <label htmlFor="exhibition_date">EXHIBITION DATE:</label>
                    <img
                      style={{ marginRight: "5px" }}
                      className="calendar ml-auto"
                      src={"assets/images/download.jpg"}
                      alt="Not Found"
                    />
                  </div>
                </Col>
                <Col xl={6} md={5} sm={7} xs={6} className="px-0">
                  <Controller
                    control={control}
                    name="exhibition_date"
                    render={({ onChange, onBlur, value }) => (
                      <DatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                    defaultValue={new Date()}
                  />
                </Col>
              </Row>
              <Row className="form_main">
                <Col className="px-0" xl={3} md={5}>
                  <label htmlFor="booth_size">BOOTH SIZE:</label>
                </Col>
                <Col xl={2} md={5} className="px-0">
                  <Input type="number" name="booth_size" inputRef={register} />
                </Col>
                <Col className="pl-0 mt-auto text-right" xl={2} md={5}>
                  <label htmlFor="stand_type">STAND TYPE:</label>
                </Col>
                <Col xl={2} md={5} className="pl-0 form_column_sel">
                  <select name="stand_type" ref={register}>
                    <option>Stand Type</option>
                    <option value="row">Row Stand</option>
                    <option value="corner">Corner Stand</option>
                    <option value="two_corner">Two Corner Stand</option>
                    <option value="island">Island Stand</option>
                  </select>
                </Col>
                <Col xl={2} lg={2} md={2}></Col>
              </Row>
              <Row className="form_main">
                <Col className="px-0" xl={3} md={5}>
                  <label htmlFor="location">LOCATION:</label>
                </Col>
                <Col xl={6} md={5} className="px-0">
                  <Input type="text" name="location" inputRef={register} />
                </Col>
                <Col xl={3} md={2}></Col>
              </Row>
              <Row className="form_main">
                <Col className="px-0" xl={3} md={5}>
                  <label htmlFor="booth_code">EXHIBITION BOOTH CODE:</label>
                </Col>
                <Col xl={6} md={5} className="px-0">
                  <Input type="text" name="booth_code" inputRef={register} />
                </Col>
                <Col xl={3} md={2}></Col>
              </Row>
              <Row className="form_main">
                <Col className="px-0" xl={3} md={4}>
                  <label htmlFor="upload">Upload file:</label>
                </Col>
                <Col className="px-0" xl={6} md={5}>
                  <label className="upload_file">
                    Choose File
                    <input
                      className="file-upload__input"
                      type="file"
                      name="attachment"
                      id="attachment"
                      ref={register}
                      hidden
                    />
                    <span className="ml-3">{selectedFileName}</span>
                  </label>
                </Col>
                <Col md={3}></Col>
              </Row>
              <Row className="form_main">
                <Col xl={12} className="px-0">
                  <p className="text-left font-weight-bold text-lg-125 text-100 mb-0">
                    BOOTH REQUIREMENTS:
                  </p>
                  <textarea
                    name="exhibition_booth_requirements"
                    className="text_area"
                    ref={register}
                  />
                </Col>
              </Row>
              <Row className="form_main">
                <Col xl={12} className="px-0">
                  <p className="font-weight-bold text-lg-125 text-100 mb-0">
                    BOOTH COMMENTS/ADJUSTMENTS:
                  </p>
                  <p className="mb-0">
                    (Kindly write below your comments or adjustments based on
                    your preferred booth)
                  </p>
                  <textarea
                    name="other_requirements"
                    className="text_area"
                    ref={register}
                  />
                </Col>
              </Row>

              <div className="float-right w-100 plan_event_div">
                <button
                  type="submit"
                  className="Button gen_inquiry_func_buttons"
                  onClick={() => setStatus("sent")}
                >
                  Send Request
                </button>
                <button
                  type="submit"
                  className="Button gen_inquiry_func_buttons"
                >
                  Send Later
                </button>
                <button className="Button gen_inquiry_func_buttons">
                  Cancel
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default ExhibitionForm;
