import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

function CartCount() {
  const { count } = useSelector((state) => state.cart);

  return (
    <span>
      <Link to="/cart">
        <ShoppingCartIcon className="cartIcon mr-3" />
        <span className="cartNo">
          <span className="itemNo">{count}</span>
        </span>
      </Link>
    </span>
  );
}

export default CartCount;
