/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { loadServiceRequestChatMessages } from "../../../../server/Filter";
import { transformMessages } from "../utils/helpers";
import useOnScreen from "./useOnScreen";
import useKeepScrollPosition from "./useScrollPosition";
import getSorting from "../../../../utils/getSorting";

const LIMIT = 50;

const useMessages = () => {
  const { requestID } = useParams();
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [page, setPage] = useState(0);
  const [lastMessageRef, setLastMessageRef] = useState(null);

  const isIntersecting = useOnScreen({ current: lastMessageRef });
  const { containerRef } = useKeepScrollPosition([messages]);

  const loadMessages = () => {
    setIsLoading(true);
    loadServiceRequestChatMessages({
      offset: page * LIMIT,
      limit: LIMIT,
      service_request: requestID,
    })
      .then((res) => {
        const newMessages = [
          ...transformMessages(res.results).sort(
            getSorting("asc", "created_at")
          ),
          ...messages,
        ];
        setPage((prevState) => prevState + 1); // Set Page if messages of that page are loaded
        setHasMoreMessages(newMessages.length < res.count);
        setMessages(newMessages);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // Load messages for the first time without checking intersecting
    loadMessages();
  }, []);

  useEffect(() => {
    if (isIntersecting && hasMoreMessages) {
      loadMessages();
    }
  }, [isIntersecting]);

  const addNewMessage = (newMessage) => {
    setMessages((prevState) => [...prevState, newMessage]);
  };

  return {
    isLoading,
    messages,
    addNewMessage,
    setLastMessageRef,
    containerRef,
  };
};

export default useMessages;
