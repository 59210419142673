import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

// COMPONENTS & STYLES
import Header from "../../../components/header";
import BackButton from "../../../components/common/BackButton";
import Footer from "../../../components/footer";
import RoomSizeInfoIcons from "./components/RoomSizeInfoIcons";
import "./index.scss";
import { getRoomImageTitleByType } from "./utilities/helpers";
import useFetchRequest from "../../../components/common/custom-hooks/useFetchRequest";
import { getRoomDetailsByIdQuery } from "../../../services/venue-details";
import { getDummyVenueImage } from "../../../utils/helpers";

function RoomDetailPage() {
  const { roomId } = useParams();
  const { data } = useFetchRequest(getRoomDetailsByIdQuery, roomId);

  return (
    <>
      <Header />

      <Container className="room-details-container">
        <Row className="my-5">
          <Col md={12}>
            <BackButton />
          </Col>
        </Row>

        <Row className="room-basic-details">
          <Col className="room-main-image" md={6}>
            <img src={getDummyVenueImage(data?.main_image)} alt="main" />
          </Col>

          <Col md={6} className="px-0">
            <div className="room-content-wrapper">
              <h2>{data?.meeting_room_name}</h2>

              <RoomSizeInfoIcons
                height={data?.height}
                length={data?.length}
                size={data?.total_space}
                width={data?.width}
              />

              <p className="description">{data?.meeting_room_description}</p>
            </div>
          </Col>
        </Row>

        <Row className="mt-5 mb-4 pt-2">
          <Col md={12}>
            <h6 className="fw-bold">Meeting Room Capacities</h6>
          </Col>
        </Row>

        <Row className="room-capacities-wrapper">
          {data?.meeting_space_capacity?.length > 0 &&
            data.meeting_space_capacity.map((item) => {
              const { image, title } = getRoomImageTitleByType(item?.type);

              return (
                +item?.capacity > 0 && (
                  <Col key={item.id} md={6} className="room-capacity">
                    <div className="image-title-container">
                      <img src={image} alt="Icon" />

                      <p className="mb-0">{title}</p>
                    </div>

                    <p className="mb-0">{item?.capacity}</p>
                  </Col>
                )
              );
            })}
        </Row>

        <Row className="mt-5 mb-4 pt-2">
          <Col md={12}>
            <h6 className="fw-bold">Amenities</h6>
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <ul>
              {data?.meeting_room_amenities?.length > 0 &&
                data.meeting_room_amenities.map((item) => (
                  <li key={item?.id}>{item?.room_amenity_name}</li>
                ))}
            </ul>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
}

export default RoomDetailPage;
