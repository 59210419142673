import React from "react";
import { Link } from "react-router-dom";
import ServiceRequestsMainProvider, {
  ServiceRequestMainContext,
} from "./context/MainContext";
import useToggle from "../../../components/common/custom-hooks/useToggle";
import PageWrapper from "../../../components/common/PageWrapper";
import SimpleTable from "../../../shared/components/table/SimpleTable";
import Modal from "../../../components/common/CustomModal";
import ServiceRequestForm from "./components/ServiceRequestForm";
import { mainTableHeadings } from "./utils/headings";
import { getServiceRequestStatusColor } from "./utils/helpers";

const ServiceRequests = () => {
  const { isOpen: isFormModalOpen, toggle: toggleFormModal } = useToggle();

  const renderServiceRequestRow = (record, recordIndex) => (
    <tr
      className={`${
        record.unseen_user_messages_count > 0 ? "font-weight-bold" : ""
      }`}
      key={`service-request-table-row-${recordIndex}`}
    >
      <td align="left" width="15%">
        <Link to={`/service_requests/${record.id}`}>
          {record.service_request_formatted_num}
        </Link>
      </td>
      <td align="left" width="25%" className="single-line-text">
        <span>{record.title}</span>
      </td>
      <td align="left" width="45%" className="single-line-text">
        <span>{record.description}</span>
      </td>
      <td align="center" width="15%">
        <span
          style={{ color: getServiceRequestStatusColor(record.status) }}
        ></span>
        {record.status}
      </td>
    </tr>
  );

  return (
    <PageWrapper bannerTitle="Service Requests">
      <ServiceRequestsMainProvider>
        <ServiceRequestMainContext.Consumer>
          {({ isLoading, serviceRequests }) => (
            <React.Fragment>
              <Modal
                isOpen={isFormModalOpen}
                toggle={toggleFormModal}
                heading="Service Request Form"
                bodyClassName="p-5"
                size="lg"
              >
                <ServiceRequestForm toggle={toggleFormModal} />
              </Modal>
              <div className="d-flex justify-content-end">
                <button
                  className="primary-button mb-6"
                  onClick={toggleFormModal}
                >
                  Raise a Request
                </button>
              </div>
              <SimpleTable
                headings={mainTableHeadings}
                data={serviceRequests}
                isLoading={isLoading}
                rounded
                renderCustomRow={renderServiceRequestRow}
              />
            </React.Fragment>
          )}
        </ServiceRequestMainContext.Consumer>
      </ServiceRequestsMainProvider>
    </PageWrapper>
  );
};
export default ServiceRequests;
