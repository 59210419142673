import React from "react";
import { Field } from "formik";

const FormikCheckboxes = ({ name, options, onChange, ...rest }) => {
  return (
    <div className="w-100 d-flex justify-content-end">
      <Field name={name}>
        {({
          field: {
            onChange: onFieldValueChange,
            value: fieldValue,
            ...restFieldProps
          },
          meta: { touched, error },
        }) => {
          const isErrorField = touched && error;

          return (
            <div className="d-flex flex-column">
              <div className="d-flex">
                {options.map((option, index) => {
                  const isLastItem = index === options.length - 1;

                  return (
                    <React.Fragment key={option.label}>
                      <label
                        className={`radio-choice ${isLastItem ? "mr-0" : ""}`}
                      >
                        <input
                          type="checkbox"
                          id={option.value}
                          {...restFieldProps}
                          {...rest}
                          value={option.value}
                          checked={fieldValue.indexOf(option.value) > -1}
                          onChange={(event) => {
                            onFieldValueChange(event);
                            if (onChange) onChange(event);
                          }}
                        />
                        <span className="pl-3">{option.label}</span>
                      </label>
                    </React.Fragment>
                  );
                })}
              </div>
              {isErrorField && (
                <span className="form__form-group-error">{error}</span>
              )}
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default FormikCheckboxes;
