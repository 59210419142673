import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Row,
  Col,
  Image,
  Button,
  Accordion,
  Card,
} from "react-bootstrap";
import { Switch, Route, Link } from "react-router-dom";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import { css } from "emotion";
import MyProperty from "./my_property";
import NewProperty from "./New_property";
import EditProperty from "./edit_property";
import { logout } from "../../redux/actions/authActions";
import MyProfile from "./my_profile";
import "./index.scss";

const logoSource = `${process.env.PUBLIC_URL}/assets/logo/main_logo.png`;
// const color1 = "#FFAC33";
// const color2 = "#fff";

const Dashboard = () => {
  const dispatch = useDispatch();
  // const location = useLocation();
  const [arrow1, setArrow1] = useState("");
  const [arrow2, setArrow2] = useState("");

  // const tab1 = css`
  //   color: ${color1};
  //   cursor: pointer;
  // `;
  // const tab2 = css`
  //   color: ${color2};
  //   cursor: pointer;
  // `;

  const handleLogout = async () => {
    await dispatch(logout());
    window.location = "/partners-login";
  };

  return (
    <React.Fragment>
      <Row className="h-100">
        <Col sm={4} lg={3} xl={2} className="px-0">
          <div className="dashboard-sidebar">
            <div
              className="px-5 py-7"
              style={{ borderBottom: "3px solid #fff" }}
            >
              <Image src={logoSource} fluid />
            </div>
            <Accordion defaultActiveKey="1" className="px-5 pt-5">
              <Card className="border-0 accordionDashboard">
                <Card.Header className="border-0 p-0 m-0 ">
                  <Accordion.Toggle
                    as={Button}
                    className="Button_hide Button_hide1 text-lg-100 text-sm-75 text-decoration-none shadow-none accordionBtn"
                    variant="link"
                    eventKey="0"
                    onClick={() =>
                      setArrow1((arrow) => (arrow === "" ? "ChangeArrow" : ""))
                    }
                  >
                    Profile
                    {arrow1 === "ChangeArrow" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="pb-0 branding_body accordionBody"
                    style={{ lineHeight: "1" }}
                  >
                    <Link
                      className="text-decoration-none"
                      to="/dashboard/myprofile"
                    >
                      - My Profile
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey="1" className="px-5 pt-5">
              <Card className="border-0 accordionDashboard">
                <Card.Header className="border-0 p-0 m-0 ">
                  <Accordion.Toggle
                    as={Button}
                    className="Button_hide Button_hide1 text-lg-100 text-sm-75 text-decoration-none shadow-none accordionBtn"
                    variant="link"
                    eventKey="0"
                    onClick={() =>
                      setArrow2((arrow) => (arrow === "" ? "ChangeArrow" : ""))
                    }
                  >
                    Properties
                    {arrow2 === "ChangeArrow" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="branding_body accordionBody pb-0"
                    style={{ lineHeight: "1" }}
                  >
                    <ul className="list pl-0 mb-0">
                      <li>
                        <Link className="text-decoration-none" to="/dashboard">
                          - My Properties
                        </Link>
                      </li>
                      <li className="mt-5">
                        <Link
                          className="text-decoration-none"
                          to="/dashboard/property/add"
                        >
                          - Add New Property
                        </Link>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <div className="text-white px-5 pt-5">
              {/* <p className="text-lg-125 text-100 mb-2">Properties</p>
              <div className="pl-5">
                <Link className="text-decoration-none" to="/dashboard">
                  <p
                    className={
                      "text-100 mb-2 " +
                      (location.pathname === "/dashboard" ? tab1 : tab2)
                    }
                  >
                    {" "}
                    - My Properties
                  </p>
                </Link>
                <Link
                  className="text-decoration-none"
                  to="/dashboard/property/add"
                >
                  <p
                    className={
                      "text-100 mb-0 " +
                      (location.pathname === "/dashboard/property/add"
                        ? tab1
                        : tab2)
                    }
                  >
                    {" "}
                    - Add New Property
                  </p>
                </Link>
              </div>
              <p className="text-lg-125 text-100 mb-2">Profile</p> */}
              {/* <div className="pl-5">
                <Link
                  className="text-decoration-none"
                  to="/dashboard/myprofile"
                >
                  <p
                    className={
                      "text-100 mb-0 " +
                      (location.pathname === "/dashboard/myprofile"
                        ? tab1
                        : tab2)
                    }
                  >
                    {" "}
                    - My Profile
                  </p>
                </Link>
              </div> */}
              <div className="">
                <p
                  className="cursor-pointer text-lg-125 text-100 "
                  onClick={handleLogout}
                >
                  Logout
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={8} lg={9} xl={10} className="px-7 dashboard-main-content">
          <Switch>
            <Route
              path="/dashboard/property/:id/edit"
              component={EditProperty}
            />
            <Route path="/dashboard/property/add" component={NewProperty} />
            <Route path="/dashboard/myprofile" component={MyProfile} />
            <Route path="/dashboard" component={MyProperty} />
          </Switch>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Dashboard;
