/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { useParams, useLocation } from "react-router-dom";

// COMPONENTS & STYLES
import "./index.scss";
import PageWrapper from "../../components/common/PageWrapper";
import TabPanel from "../../components/common/TabPanel";
import usePreventRefresh from "../../custom-hooks/usePreventRefresh";
import CongressOverviewForm from "./components/CongressOverviewForm";
import RegistrationForm from "./components/RegistrationForm";
import TravelInformationForm from "./components/TravelInformationForm";
import { CongressContext } from "./context/CongressContext";
import { getCongressBasicDetailsQuery } from "../../services/congress-details";

function CongressManagementForm() {
  const [congressName, setCongressName] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [payload, setPayload] = useState({});
  const [congressDetails, setCongressDetails] = useState(null);
  const { id } = useParams();
  const { pathname } = useLocation();
  const isAddCongress = useMemo(() => pathname?.includes("add"), [pathname]);

  usePreventRefresh();

  const handleChangeTab = (e, newValue) => {
    setCurrentTab(newValue);
  };

  const contextValue = useMemo(
    () => ({
      currentTab,
      setCurrentTab,
      payload,
      setPayload,
      isAddCongress,
      congressDetails,
      setCongressDetails,
    }),
    [currentTab, isAddCongress, payload, congressDetails]
  );

  useEffect(() => {
    (async () => {
      if (id) {
        const basicDetailsResp = await getCongressBasicDetailsQuery(id);
        setCongressDetails(basicDetailsResp);
        setCongressName(basicDetailsResp?.conference_name);
      }
    })();
  }, []);

  return (
    <PageWrapper
      bannerTitle={id ? congressName : "Add Congress"}
      bannerImgSrc="url(/assets/images/events/venue-sourcing-banner.jpg)"
    >
      <CongressContext.Provider value={contextValue}>
        <div className="confress-management-wrapper">
          <Tabs
            className="tabs"
            value={currentTab}
            onChange={handleChangeTab}
            TabIndicatorProps={{ hidden: true }}
          >
            <Tab className="tab" label="Congress Overview" wrapped />

            <Tab className="tab" label="Registration" wrapped />

            <Tab className="tab" label="Travel Information" wrapped />
          </Tabs>

          <div className="tab-panel-wrapper">
            <TabPanel tabValue={currentTab} index={0}>
              <CongressOverviewForm congressId={id} />
            </TabPanel>

            <TabPanel tabValue={currentTab} index={1}>
              <RegistrationForm congressId={id} />
            </TabPanel>

            <TabPanel tabValue={currentTab} index={2}>
              <TravelInformationForm congressId={id} />
            </TabPanel>
          </div>
        </div>
      </CongressContext.Provider>
    </PageWrapper>
  );
}

export default CongressManagementForm;
