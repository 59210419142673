import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import { login } from "../../redux/actions/authActions";

const account = "partners";

const PartnerLoginPage = (props) => {
  const { doLogin, profile } = props;
  const { isAuthenticated } = props;
  const { location } = props;
  const { state } = location;
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [errors, setErrors] = useState({
    username: null,
    password: null,
  });

  function handleLogin(e) {
    e.preventDefault();
    if (!username)
      return setErrors((prevState) => ({
        ...prevState,
        username: "Please provide username",
      }));
    if (!password)
      return setErrors((prevState) => ({
        ...prevState,
        password: "Please provide password",
      }));
    doLogin({
      account,
      username,
      password,
    });
  }

  if (isAuthenticated && profile.account === account) {
    return (
      <Redirect
        to={{
          pathname: state
            ? state.from.pathname + state.from.search
            : "/dashboard",
        }}
      />
    );
  } else {
    return (
      <React.Fragment>
        <div id="container">
          <div className="homeIcon">
            <img src="/assets/loginpage/icon-home.png" alt="" />
          </div>

          <div id="info">
            <img
              src={"assets/logo/logo.png"}
              alt="Not Found"
              id="img-discusso"
            />
            {/* <p>
            Login using social media to get quick access Login using social
            media to get quick access Login using social media to get quick
            access Login using social media to get quick access
          </p> */}
          </div>

          <div id="form">
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="formBasicUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  onChange={(e) => setUsername(e.target.value)}
                  onFocus={() =>
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      username: null,
                    }))
                  }
                  isInvalid={errors.username}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={() =>
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      password: null,
                    }))
                  }
                  isInvalid={errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Button className="loginBtn mt-5" variant="primary" type="submit">
                Login
              </Button>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
};

PartnerLoginPage.propTypes = {
  doLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }),
};

PartnerLoginPage.defaultProps = {
  isAuthenticated: false,
  location: {
    state: {
      pathname: "",
      search: "",
    },
  },
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  profile: state?.auth?.user?.profile,
});

export default connect(mapStateToProps, {
  doLogin: login,
})(PartnerLoginPage);
