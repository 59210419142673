export const eventDetailFormInitialValues = {
  account_name: "",
  account_email: "",
  crm_sales_account_id: "",
  crm_sales_customer_id: "",
  status: "",
  category: "",
  event_name: "",
  congress_location: "",
  no_of_attendee: "",
  required_date_start: "",
  required_date_end: "",
  alternative_date_start: "",
  alternative_date_end: "",
  rfpdestination: [],
  rfpvenue: [],
};

// Registration
export const singleRegistrationInitialValues = {
  category: "",
  number_of_delegates: "",
};

export const registrationInitialValues = {
  registration_flag: "",
  rfpregistration: [singleRegistrationInitialValues],
};

// Flight Ticket
export const singleFlightTicketInitialValues = {
  departure_place: "",
  number_of_pax: "",
};

export const flightTicketInitialValues = {
  flight_ticket_flag: "",
  rfpflightticket: [singleFlightTicketInitialValues],
};

// Visa Assistance
export const visaAssistanceInitialValues = {
  visa_assistance_flag: "",
  rfpvisaassistance: {
    nationality: "",
    remarks: "",
  },
};

// Accommodation
export const singleAccomodationRoom = {
  no_of_rooms: "",
  occupancy: "",
  meal_plan: "",
  remarks: "",
};

export const accommodationInitialValues = {
  accomodation_flag: "",
  rfpaccomodation: {
    hotel_rating: [],
    check_in: "",
    check_out: "",
    accomodation_rooms: [singleAccomodationRoom],
  },
};

// Meeting Room
export const singleMeetingInitialValues = {
  meeting_date: "",
  number_of_participants: "",
  meeting_time_start: "",
  meeting_time_end: "",
  remarks: "",
};

export const meetingRoomsInitialValues = {
  meeting_rooms_flag: "",
  rfpmeetingroom: [singleMeetingInitialValues],
};

// Ground Transportation
export const singleGroundTransportInitialValues = {
  category: "",
  type_of_service: "",
  remarks: "",
};

export const groundTransportationInitialValues = {
  ground_transportation_flag: "",
  rfpgroundtransportation: [singleGroundTransportInitialValues],
};

// F&BArrangement
export const singleFAandBArrangementInitialValues = {
  category: "",
  description: "",
  no_of_days: "",
};

export const fAndBArrangementInitialValues = {
  f_and_b_arrangement_flag: "",
  f_and_b_arrangement: [singleFAandBArrangementInitialValues],
};

// Tours Entertainment
export const singleTourEntertainmentInitialValues = {
  category: "",
  description: "",
  no_of_days: "",
};

export const tourEntertainmentInitialValues = {
  tour_entertainment_flag: "",
  tour_entertainment: [singleTourEntertainmentInitialValues],
};

// Other Requirements
export const otherRequirementsInitialValues = {
  other_requirement_flag: "",
  other_requirements: "",
};

// Production
export const productionInitialValues = {
  production_flag: "",
  rfpproduction: {
    uploaded_design: null,
    requested_design: "",
  },
};

export default {
  ...eventDetailFormInitialValues,
  ...registrationInitialValues,
  ...flightTicketInitialValues,
  ...visaAssistanceInitialValues,
  ...accommodationInitialValues,
  ...meetingRoomsInitialValues,
  ...groundTransportationInitialValues,
  ...fAndBArrangementInitialValues,
  ...tourEntertainmentInitialValues,
  ...otherRequirementsInitialValues,
  ...productionInitialValues,
};
