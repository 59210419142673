import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ServiceRequestDetailsProvider, {
  ServiceRequestDetailsContext,
} from "./context/DetailsContext";
import PageWrapper from "../../../components/common/PageWrapper";
import Loader from "../../../components/common/MiniLoader";
import ServiceRequestChatContainer from "./components/ChatContainer";
import "./styles/main.scss";

// Child
const RequestHeaderRecord = ({ heading, value }) => (
  <Row className="my-2">
    <Col lg={3} className="pl-0">
      <span className="font-weight-bold">{heading}</span>
    </Col>
    <Col lg={9}>
      <span>{value}</span>
    </Col>
  </Row>
);

// Parent
const ServiceRequestDetails = () => {
  return (
    <PageWrapper bannerTitle="Service Request Details">
      <ServiceRequestDetailsProvider>
        <div className="w-100 service-request-details">
          <ServiceRequestDetailsContext.Consumer>
            {({ isLoading, serviceRequestDetails }) => (
              <Container className="p-0">
                {isLoading ? (
                  <div className="header-loader-wrapper">
                    <Loader />
                  </div>
                ) : (
                  <React.Fragment>
                    <RequestHeaderRecord
                      heading="Service Request No:"
                      value={
                        serviceRequestDetails.service_request_formatted_num
                      }
                    />
                    <RequestHeaderRecord
                      heading="Status:"
                      value={serviceRequestDetails.status}
                    />
                    <RequestHeaderRecord
                      heading="Title:"
                      value={serviceRequestDetails.title}
                    />
                    <RequestHeaderRecord
                      heading="Description:"
                      value={serviceRequestDetails.description}
                    />
                  </React.Fragment>
                )}
              </Container>
            )}
          </ServiceRequestDetailsContext.Consumer>
          <ServiceRequestChatContainer />
        </div>
      </ServiceRequestDetailsProvider>
    </PageWrapper>
  );
};

export default ServiceRequestDetails;
