export const budgetMainTableHeadings = [
  {
    id: "total_items",
    label: "Total Budget Items",
  },
  {
    id: "formatted_estimated_budget",
    label: "Estimated Budget",
  },
  {
    id: "formatted_reconciled_budget",
    label: "Reconciled Budget",
  },
  {
    id: "formatted_savings",
    label: "Savings",
  },
];

export const budgetDetailTableHeadings = [
  {
    id: "formatted_description",
    label: "Item Details",
  },
  {
    id: "formatted_currency",
    label: "Currency",
  },
  {
    id: "formatted_estimated_budget",
    label: "Estimated Budget",
  },
  {
    id: "formatted_reconciled_budget",
    label: "Reconciled Budget",
  },
  {
    id: "formatted_savings",
    label: "Savings",
  },
];

export const budgetItemTableHeadings = [
  {
    id: "cost_details",
    label: "Cost Details",
  },
  {
    id: "format",
    label: "Format",
  },
  {
    id: "estimated_budget",
    label: "Estimated Budget",
  },
  {
    id: "reconciled_budget",
    label: "Reconciled Budget",
  },
];
