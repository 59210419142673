import React from "react";
import { useFormikContext } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import useToggle from "../../../../../components/common/custom-hooks/useToggle";
import { useEventCoordinationContext } from "../../context/EventCoordinationContext";
import ConfirmationPopup from "../../../../../components/common/popups/ConfirmationPopup";
import ReasonModal from "../../../../../components/common/rfp/ReasonModal";
import { modifyRFPRequestStatus } from "../../../../../server/Filter";
import {
  CANCELLED,
  EDITED,
  PENDING,
  REQUESTED,
  RFP_DETAILS_STATE_KEY,
} from "../../utils/constants";

// Child
const StatusButton = ({ children, className, ...restProps }) => (
  <span className={`status-button ${className}`} {...restProps}>
    {children}
  </span>
);

// Child
const StatusButtonSeparator = () => (
  <span className="status-button-separator" />
);

// Parent
const StatusHandler = () => {
  const history = useHistory();
  const alert = useAlert();
  const { rfpID } = useParams();
  const {
    data: { [RFP_DETAILS_STATE_KEY]: rfpDetails },
    setData,
    [RFP_DETAILS_STATE_KEY]: { isEditing, startEditing, stopEditing },
  } = useEventCoordinationContext();
  const { handleSubmit, handleReset, isSubmitting, dirty } = useFormikContext();
  const {
    isOpen: isSendConfirmationModalOpen,
    toggle: toggleSendConfirmationModal,
  } = useToggle();
  const { isOpen: isCancelRFPModalOpen, toggle: toggleCancelRFPModal } =
    useToggle();

  const isRequestPending = rfpDetails.status === PENDING;
  const isRequestEdited = rfpDetails.status === EDITED;

  const handleCancel = () => {
    handleReset();
    stopEditing();
  };

  const handleSendConfirmation = () => {
    const modifiedStatus = REQUESTED;
    modifyRFPRequestStatus(rfpDetails.id, {
      status: modifiedStatus,
    })
      .then(() => {
        setData((prevState) => ({
          ...prevState,
          [RFP_DETAILS_STATE_KEY]: {
            ...prevState.rfpDetails,
            status: modifiedStatus,
          },
        }));
        alert.success("Request sent successfully");
      })
      .catch(() => alert.error("Something went wrong"));
  };

  const cancelRFPRequest = (cancellationReason) => {
    modifyRFPRequestStatus(rfpID, {
      status: CANCELLED,
      request_status: CANCELLED,
      rfp_cancel_reason: cancellationReason,
    })
      .then(() => {
        alert.success("Request cancelled successfully");
        history.push("/cancelled");
      })
      .catch(() => alert.error("Something went wrong"));
  };

  return (
    <div className="d-flex justify-content-end">
      <ConfirmationPopup
        isOpen={isSendConfirmationModalOpen}
        toggle={toggleSendConfirmationModal}
        message="Are you sure you want to send rfp?"
        onConfirm={handleSendConfirmation}
      />
      <ReasonModal
        isOpen={isCancelRFPModalOpen}
        toggle={toggleCancelRFPModal}
        onSubmit={cancelRFPRequest}
      />
      {isEditing ? (
        <div>
          <StatusButton
            onClick={dirty ? handleSubmit : undefined}
            className={dirty ? "" : "disabled"}
          >
            {isSubmitting ? "Saving..." : "Save"}
          </StatusButton>
          <StatusButtonSeparator />
          <StatusButton onClick={handleCancel}>Cancel</StatusButton>
        </div>
      ) : (
        <div>
          <StatusButton onClick={startEditing}>Edit</StatusButton>
          <StatusButtonSeparator />
          {/* Show send button only when request is in pending state or is edited */}
          {(isRequestPending || isRequestEdited) && (
            <React.Fragment>
              <StatusButton onClick={toggleSendConfirmationModal}>
                Send RFP
              </StatusButton>
              <StatusButtonSeparator />
            </React.Fragment>
          )}
          <StatusButton onClick={toggleCancelRFPModal}>Cancel RFP</StatusButton>
        </div>
      )}
    </div>
  );
};

export default StatusHandler;
