import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import EventCoordinationProvider from "./context/EventCoordinationContext";
import ProductLaunchSubTabs from "../../../components/common/rfp/ProductLaunchSubTabs";
import RFPDetails from "./rfp_details";
import SiteInspection from "./site_inspection";
import Quotations from "./quotations";
import EventRoles from "./event_roles";
import History from "./history";
import {
  RFP_DETAILS,
  QUOTATIONS,
  SITE_INSPECTION,
  EVENT_ROLES,
  HISTORY,
} from "./utils/constants";

const tabsContent = [
  {
    key: RFP_DETAILS,
    title: "RFP Details",
    content: <RFPDetails />,
  },
  {
    key: QUOTATIONS,
    title: "Quotations",
    content: <Quotations />,
  },
  {
    key: SITE_INSPECTION,
    title: "Site Inspection",
    content: <SiteInspection />,
  },
  {
    key: EVENT_ROLES,
    title: "Event Roles",
    content: <EventRoles />,
  },
  {
    key: HISTORY,
    title: "History",
    content: <History />,
  },
];

const EventCoordination = () => {
  const { state = {} } = useLocation();

  const selectedTabKey = useMemo(() => {
    const selectedMainTab = tabsContent.find(
      (item) => item.key === state.subTab
    );
    if (selectedMainTab) return selectedMainTab.key;
    return RFP_DETAILS;
  }, [state]);

  return (
    <EventCoordinationProvider>
      <ProductLaunchSubTabs
        defaultKey={selectedTabKey}
        tabsContent={tabsContent}
      />
    </EventCoordinationProvider>
  );
};

export default EventCoordination;
