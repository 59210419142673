import React from "react";
import { IconButton, LinearProgress } from "@material-ui/core";
import { Close, InsertDriveFile } from "@material-ui/icons";
import propTypes from "prop-types";

function FileItem({ file, index, isLoading, handleDelete }) {
  return (
    <div className="file-box">
      <div className="icon-wrapper">
        <InsertDriveFile style={{ fontSize: "60px" }} />

        <p>{file?.fileName || ""}</p>
      </div>

      <div className="action-wrapper">
        <LinearProgress
          variant={!isLoading ? "determinate" : "indeterminate"}
          value={!isLoading ? 100 : undefined}
          className="progress-bar"
        />

        <IconButton size="small" onClick={() => handleDelete(file)}>
          <Close fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
}

FileItem.propTypes = {
  file: propTypes.object.isRequired,
  index: propTypes.number.isRequired,
  handleDelete: propTypes.func.isRequired,
  isLoading: propTypes.bool,
};

FileItem.defaultProps = {
  isLoading: false,
};

export default FileItem;
