import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import ConfirmationPopup from "../../common/popups/ConfirmationPopup";

const FileDeleteRenderer = ({
  handleDeleteSubmit,
  resetSelection,
  children,
}) => {
  const [isModalOpen, setModalState] = useState(true);

  const toggleModal = () => {
    setModalState((prevState) => !prevState);
  };

  return (
    <>
      <Badge
        color=""
        className="p-0 d-flex align-items-center"
        style={{ fontSize: "100%", color: "#3EA3FC" }}
        onClick={toggleModal}
      >
        {children}
      </Badge>
      <ConfirmationPopup
        isOpen={isModalOpen}
        toggle={toggleModal}
        message="Are you sure you want to delete this file/folder?"
        onConfirm={handleDeleteSubmit}
        onCancel={resetSelection}
        passEventOnSuccess
      />
    </>
  );
};

export default FileDeleteRenderer;
