import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import { getConceptDesignLists } from "../../../server/Filter";
import "./index.scss";

const PreferredDesigns = () => {
  const [conceptDesignLists, setConceptDesignLists] = useState([]);

  useEffect(() => {
    getConceptDesignLists()
      .then((data) => setConceptDesignLists(data))
      .catch((err) => console.log(err));
  }, []);

  const preferredDesigns = conceptDesignLists.map((listItem) => ({
    id: listItem.id,
    source: listItem.designs.length > 0 ? listItem.designs[0].thumbnail : "",
    heading: listItem.venue_list_name,
    text: `${listItem.designs?.length} Design(s)`,
  }));

  const renderPreferredDesigns = (preferredDesign) => {
    return (
      <Col
        xl={3}
        md={4}
        sm={6}
        className="px-0 px-sm-4"
        key={preferredDesign.id}
      >
        <Link
          to={`/concept_designs/my_designs/${preferredDesign.id}`}
          className="text-decoration-none"
        >
          <Image
            src={preferredDesign.source}
            className="preffered_design_image"
            fluid
          />
          <p className="font-weight-bold text-lg-125 text-100 text-dark mb-2 mt-3">
            {preferredDesign.heading}
          </p>
          <p className="text-dark">{preferredDesign.text}</p>
        </Link>
      </Col>
    );
  };

  return (
    <React.Fragment>
      <Header />
      <Container fluid className="px-0">
        <Row className="inner_width_div">
          <Col xs={12} className="pl-sm-2 pl-5 pr-5 pr-sm-0">
            <div className="d-flex my-5 pl-sm-4 px-0 pr-sm-4 w-100">
              <p className="font-weight-bold text-lg-150 text-125 mb-0">
                My Preferred Designs
              </p>
              <Link to="/concept_designs" className="back_button ml-auto">
                &lt; Back
              </Link>
            </div>
            <Row>
              {preferredDesigns.length > 0 ? (
                preferredDesigns.map(renderPreferredDesigns)
              ) : (
                <p className="text-lg-125 text-100 mb-2 m-auto pb-5">
                  You don't have any Preferred Design
                </p>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default PreferredDesigns;
