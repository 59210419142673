import React from "react";
import { Oval } from "react-loader-spinner";

function SectionLoader() {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "30vh" }}
    >
      <Oval
        height={80}
        width={80}
        color="#071f4e"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#071f4e"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
}

export default SectionLoader;
