import React from "react";
import { FaSpinner } from "react-icons/fa";
import ChatMessage from "./components/Message";
import "./styles/main.scss";

const ChatModule = ({
  isLoading = true,
  messages = [],
  messagesContainerRef = null,
  setLastMessageRef = () => {},
}) => {
  return (
    <div className="chat-module-wrapper">
      <div ref={messagesContainerRef} className="chat-module">
        <div className="w-100 d-flex justify-content-center">
          {isLoading && (
            <FaSpinner
              className="fa-spin custom-text-secondary mb-5"
              size={40}
            />
          )}
        </div>
        {messages.map(
          ({ id, content, time, attachments, isMyMessage }, index) => (
            <ChatMessage
              key={id}
              messageRef={(ref) =>
                index === 0 ? setLastMessageRef(ref) : null
              }
              messageContent={content}
              messageTime={time}
              attachments={attachments}
              isMyMessage={isMyMessage}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ChatModule;
