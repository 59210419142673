import React from "react";
import { useField } from "formik";
import { MdFileUpload as FileIcon } from "react-icons/md";

const generateFileName = (target) => {
  if (Array.isArray(target)) {
    if (target.length > 1) {
      // If more than one files are selected
      return `${target[0].name}, ...`;
    }
    // If multiple is true and single file selected
    return target[0].name;
  }
  // If multiple is false
  return target.name;
};

const FormikFileInput = (props) => {
  const { name, className, multiple, onChange, ...restProps } = props;
  const [field, meta, helpers] = useField(props);
  const { value, onChange: onFieldValueChange, ...restFieldProps } = field;
  const { touched, error } = meta;
  const { setValue } = helpers;

  const hasAnySelectedFile = multiple ? value.length > 0 : !!value;
  const selectedFileName = hasAnySelectedFile
    ? generateFileName(multiple ? [...value] : value)
    : "Choose File";

  const handleRemoveFiles = () => {
    const modifiedTarget = multiple ? [] : null;
    // Removing the selected file(s)
    setValue(modifiedTarget);
    if (onChange) onChange(modifiedTarget);
  };

  return (
    <div className="w-100">
      <div style={{ textAlign: "center" }} className="file-upload-box">
        <input
          {...restProps}
          {...restFieldProps}
          type="file"
          id={name}
          multiple={multiple}
          className="form_file_uploader"
          onChange={(event) => {
            event.preventDefault();
            // Attaching Src to render selected images
            [...event.target.files].forEach((item) => {
              item.src = URL.createObjectURL(item);
            });
            const target = multiple
              ? [...event.target.files]
              : [...event.target.files][0];
            setValue(target);
            if (onChange) onChange(target);
          }}
        />
        <label htmlFor={name}>
          <figure>
            <FileIcon size={100} />
          </figure>
          <span className="text-controll">{selectedFileName}</span>
        </label>
        {hasAnySelectedFile && (
          <div
            className="text-danger cursor-pointer rounded p-2 border border-danger"
            onClick={handleRemoveFiles}
          >
            Remove File(s)
          </div>
        )}
      </div>
      {touched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
    </div>
  );
};

export default FormikFileInput;
