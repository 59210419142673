import React from "react";
import { IconButton, LinearProgress } from "@material-ui/core";
import { Close, DragHandle } from "@material-ui/icons";
import { Draggable } from "react-beautiful-dnd";
import propTypes from "prop-types";

function ImageItem({ file, index, isLoading, handleDelete }) {
  return (
    <Draggable draggableId={`${index}`} index={index}>
      {(provided) => (
        <div
          className="file-box"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="handler-wrapper">
            <DragHandle />

            <img src={file?.url} alt={file?.image_name || ""} />
          </div>

          <div className="action-wrapper">
            <LinearProgress
              variant={!isLoading ? "determinate" : "indeterminate"}
              value={!isLoading ? 100 : undefined}
              className="progress-bar"
            />

            <IconButton onClick={() => handleDelete(file)}>
              <Close />
            </IconButton>
          </div>
        </div>
      )}
    </Draggable>
  );
}

ImageItem.propTypes = {
  file: propTypes.object.isRequired,
  index: propTypes.number.isRequired,
  handleDelete: propTypes.func.isRequired,
  isLoading: propTypes.bool,
};

ImageItem.defaultProps = {
  isLoading: false,
};

export default ImageItem;
