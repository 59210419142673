import React from "react";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { useLocation, useHistory } from "react-router-dom";
import PageWrapper from "../../../components/common/PageWrapper";
import DocumentViewer from "../../../components/common/document-viewer";
import ButtonWithToolTip from "../../../components/common/ButtonWithToolTip";
import ErrorAlert from "../common/ErrorAlert";
import { downloadFileWithURL } from "../../../utils/helpers";

const GlobalDocumentPreviewer = () => {
  const history = useHistory();
  const { state } = useLocation();

  // State must be in below format
  // {
  //   fileData: {
  //     fileURL: 'https://documenturi.com'
  //     fileName: '' (Optional)
  //   },
  //   returnData: {  (Optional)
  //     url: 'https://example.com'
  //     state: {
  //       ... if have any state which needs to pass when going back
  //     }
  //   }
  // }

  if (!state || !state.fileData || !state.fileData.fileURL)
    return <ErrorAlert alertMessage="File data not found!!!!" />;

  const { fileURL, fileName = "Document" } = state.fileData;

  const goBack = () => {
    if (state && state.returnData && state.returnData.url) {
      history.push(state.returnData.url, { ...state.returnData.state });
    } else {
      history.goBack();
    }
  };

  return (
    <PageWrapper
      bannerTitle="Document Previewer"
      onBackButtonClick={goBack}
      fluid
    >
      <div className="min-vh-70">
        <div className="d-flex justify-content-end mb-3">
          {/* Download Button */}
          <ButtonWithToolTip
            tooltipText="Download File"
            className="transparent-button"
            onClick={() => downloadFileWithURL(fileURL, fileName)}
          >
            <CloudDownloadIcon className="mr-2" /> Download
          </ButtonWithToolTip>
        </div>
        <DocumentViewer fileURL={fileURL} fileName={fileName} />
      </div>
    </PageWrapper>
  );
};

export default GlobalDocumentPreviewer;
