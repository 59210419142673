import React, { useMemo } from "react";
import { FaChevronRight, FaChevronDown, FaCircle } from "react-icons/fa";

function useSimpleReactTreeTable(headings = [], data = []) {
  const memoizedColumns = useMemo(
    () => [
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        width: "1%",
        // eslint-disable-next-line react/prop-types
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <FaChevronDown size={16} />
            ) : (
              <FaChevronRight size={16} />
            )}
          </span>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                // We can even use the row.depth property
                // and paddingLeft to indicate the depth
                // of the row
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {row.canExpand ? (
              <>
                {row.isExpanded ? (
                  <FaChevronDown size={16} />
                ) : (
                  <FaChevronRight size={16} />
                )}
              </>
            ) : (
              <FaCircle size={8} />
            )}
          </span>
        ),
      },
      ...headings.map(({ label, title, Header, id, key, ...rest }) => ({
        ...rest,
        Header: label || title || Header || "",
        accessor: id || key || "",
      })),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const memoizedData = useMemo(() => [...data], [data]);

  return {
    columns: memoizedColumns,
    data: memoizedData,
  };
}

export default useSimpleReactTreeTable;
