import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Pagination from "react-js-pagination";
import "./index.scss";

export default function ({ categories, cartState }) {
  // const [activePage, setCurrentPage] = useState(1);

  // const handlePageChange = (pageNumber) => {
  //   console.log(`active page is ${pageNumber}`);
  //   setCurrentPage(pageNumber);
  // };

  return (
    <React.Fragment>
      <Row>
        {categories?.map((category) => (
          <Col
            md={6}
            sm={12}
            key={category.category_name}
            className="mb-sm-7 mb-md-5 mb-lg-10 mt-sm-0 avProduction_categories"
          >
            <Link
              to={{
                pathname: "/av_production",
                search: `?category=${category.category_name}`,
                state: cartState,
              }}
            >
              <div className="App avProduction_slider">
                <div className="all_category">
                  <img src={category.thumbnail} alt="" />
                </div>

                <div className="avProduction_title">
                  <p>{category.category_name}</p>
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </Row>

      {/* <div className="paginations">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={6}
          totalItemsCount={categories.length}
          pageRangeDisplayed={3}
          onChange={handlePageChange}
        />
      </div> */}
    </React.Fragment>
  );
}
