import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import EventServeiceButtons from "../event_service_buttons";

const EventTransportation = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
    control,
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "transportation",
  });

  const disableTransportationType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      transportationDisabled: true,
    }));
  };

  const enableTransportationType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      transportationDisabled: false,
    }));
  };

  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" xs={12}>
          <Table bordered hover>
            <thead className="text-center">
              <tr>
                <th style={{ width: "20%" }}>Sub Category</th>
                <th style={{ width: "20%" }}>Type of car</th>
                <th style={{ width: "20%" }}>Type of Service</th>
                <th style={{ width: "20%" }}>Number of Cars Needed</th>
                <th style={{ width: "20%" }}>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {fields.map(({ id }, index) => (
                <tr key={id} className="reg_table">
                  <td className="pl-0 pr-2 py-0">
                    <select
                      name={`transportation[${index}].sub_category`}
                      className="bg-white pl-2"
                    >
                      <option>Airport Transfer</option>
                      <option>Dinner Transfer</option>
                      <option>Congress Transfer</option>
                      <option>Other Transportation</option>
                    </select>
                  </td>
                  <td className="pl-0 pr-2 py-0">
                    <select
                      name={`transportation[${index}].type_of_car`}
                      className="bg-white pl-2"
                    >
                      <option>Sedan/E-class</option>
                      <option>S-Class</option>
                      <option>Viano</option>
                      <option>Sprinter Van</option>
                      <option>Mini Bus</option>
                      <option>Big Bus</option>
                    </select>
                  </td>
                  <td className="pl-0 pr-2 py-0">
                    <select
                      name={`transportation[${index}].type_of_service`}
                      className="bg-white pl-2"
                    >
                      <option>One-way</option>
                      <option>Roundtrip</option>
                      <option>Half-day Service</option>
                      <option>Full-day Service</option>
                    </select>
                  </td>
                  <td className="p-0">
                    <input
                      type="number"
                      name={`transportation[${index}].no_of_cars`}
                      className="bg-white px-3"
                    />
                  </td>
                  <td className="p-0">
                    <input
                      type="text"
                      name={`transportation[${index}].remarks`}
                      className="bg-white px-3"
                    />
                    {index !== 0 && (
                      <div className="item-actions-container">
                        <div className="item-actions">
                          <button
                            className="Button_hide"
                            onClick={() => remove(index)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <div className="event_form_addmore_btn text-right">
          <button
            className="Button_hide"
            onClick={() =>
              append({
                sub_category: "",
                type_of_car: "",
                type_of_service: "",
                no_of_cars: "",
                remarks: "",
              })
            }
          >
            Add More
          </button>
        </div>
      </Row>
      <EventServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableTransportationType}
        enableSelectedType={enableTransportationType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default EventTransportation;
