/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useState } from "react";
import propTypes from "prop-types";
import { useField } from "formik";

// STYLES & COMPONENTS
import "../../../styles/components/formik-field.scss";

function FormikModernField({
  name,
  onChange,
  type,
  disabled,
  className,
  label,
  isRequired,
  isGrid,
  placeholder,
}) {
  const [innerValue, setInnerValue] = useState("");
  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    if (value !== undefined && value !== null) setInnerValue(value);
  }, [value]);

  const handleChange = useCallback((e) => {
    setInnerValue(e?.target?.value);

    if (onChange) onChange(e?.target?.value, name);
  }, []);

  const handleBlur = useCallback(() => {
    onBlur(name);

    if (type === "date") {
      setValue(innerValue || null);
    } else {
      setValue(innerValue);
    }
  }, [innerValue]);

  return (
    <div
      className={`field-wrapper ${
        isGrid ? "col-grid" : "col-full"
      } ${className}`}
    >
      {label && (
        <div className="field-label">
          <p className={`${isRequired ? "required" : ""}`}>{label}</p>
        </div>
      )}

      <div className="input-wrapper">
        {type === "textarea" ? (
          <textarea
            name={name}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            value={innerValue}
          />
        ) : (
          <input
            placeholder={placeholder}
            name={name}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            type={type}
            value={innerValue}
          />
        )}
      </div>

      {touched && error && (
        <div className="error-wrapper">
          <p className="error">{error}</p>
        </div>
      )}
    </div>
  );
}

FormikModernField.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func,
  type: propTypes.string,
  disabled: propTypes.bool,
  className: propTypes.string,
  placeholder: propTypes.string,
  label: propTypes.string,
  isRequired: propTypes.bool,
  isGrid: propTypes.bool,
};

FormikModernField.defaultProps = {
  name: "",
  onChange: () => {},
  type: "text",
  disabled: false,
  className: "",
  label: "",
  isRequired: false,
  isGrid: false,
  placeholder: "",
};

export default memo(FormikModernField);
