import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";
import { useEventCoordinationContext } from "../../context/EventCoordinationContext";
import FormikField from "../../../../../shared/components/form/FormikField";
import FormikSelect from "../../../../../shared/components/form/FormikSelect";
import FormikDatePicker from "../../../../../shared/components/form/FormikDatePicker";
import LoadingButton from "../../../../../components/common/LoadingButton";
import FormRecordWrapper from "./FormRecordWrapper";
import {
  requestRFPSiteInspection,
  rescheduleSiteInspection,
  updateRFPSiteInspection,
} from "../../../../../server/Filter";
import { siteInspectionFormInitialValues } from "../utils/initialValues";
import {
  siteInspectionCommentsValidationSchema,
  siteInspectionDetailsValidationSchema,
} from "../utils/validationSchema";
import {
  categoryOptions,
  CANCEL_MODE,
  RESCHEDULE_MODE,
  APPROVE_MODE,
  DECLINE_MODE,
} from "../utils/contants";
import { getPayloadAndRequestMessage } from "../utils/helpers";

const SiteInspectionForm = ({ selected, toggle }) => {
  const alert = useAlert();
  const { rfpID } = useParams();
  const {
    modifiers: { updateSiteInspections },
  } = useEventCoordinationContext();
  const [initialValues, setInitialValues] = useState(
    siteInspectionFormInitialValues
  );

  const isApproveMode = selected && selected.mode === APPROVE_MODE;
  const isDeclineMode = selected && selected.mode === DECLINE_MODE;
  const isCancelMode = selected && selected.mode === CANCEL_MODE;
  const isRescheduleMode = selected && selected.mode === RESCHEDULE_MODE;

  useEffect(() => {
    setInitialValues((prevState) => ({
      ...prevState,
      venue_name: selected ? selected.venue_name : "",
      inspection_date: selected ? selected.inspection_date : "",
      inspection_time: selected ? selected.inspection_time : "",
      inspection_category: selected ? selected.inspection_category : "",
      comments: selected ? selected.comments : "",
    }));
  }, [selected]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { payload, successMessage, errorMessage } =
      getPayloadAndRequestMessage(selected, values);

    try {
      if (selected) {
        if (isRescheduleMode) {
          // For Reschedule Mode
          await rescheduleSiteInspection({ ...payload, rfp_id: rfpID });
        } else {
          // For Approve, Decline, Cancel and Edit Mode
          await updateRFPSiteInspection(payload);
        }
      } else {
        // Request New Inspection
        await requestRFPSiteInspection({ ...payload, rfp_id: rfpID });
      }
      updateSiteInspections();
      alert.success(successMessage);
      setSubmitting(false);
      toggle();
      resetForm();
    } catch (error) {
      setSubmitting(false);
      toggle();
      alert.error(errorMessage);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        ...siteInspectionDetailsValidationSchema,
        ...((isCancelMode || isDeclineMode || isRescheduleMode) &&
          siteInspectionCommentsValidationSchema),
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="custom-form">
          <FormRecordWrapper label="Property">
            <FormikField
              name="venue_name"
              placeholder="Enter Property Name"
              disabled={
                isApproveMode ||
                isDeclineMode ||
                isCancelMode ||
                isRescheduleMode
              }
            />
          </FormRecordWrapper>
          <FormRecordWrapper label="Preferred Date">
            <FormikDatePicker
              name="inspection_date"
              placeholder="YYYY/MM/DD"
              disabled={isApproveMode || isDeclineMode || isCancelMode}
            />
          </FormRecordWrapper>
          <FormRecordWrapper label="Preferred Time">
            <FormikField
              type="time"
              name="inspection_time"
              disabled={isApproveMode || isDeclineMode || isCancelMode}
            />
          </FormRecordWrapper>
          <FormRecordWrapper label="Category">
            <FormikSelect
              name="inspection_category"
              options={categoryOptions}
              placeholder="Select Inspection Category"
              disabled={isApproveMode || isDeclineMode || isCancelMode}
            />
          </FormRecordWrapper>
          {(isApproveMode ||
            isDeclineMode ||
            isCancelMode ||
            isRescheduleMode) && (
            <FormRecordWrapper label="Comments">
              <FormikField name="comments" textArea />
            </FormRecordWrapper>
          )}
          <Row className="mt-7">
            <Col className="d-flex justify-content-center">
              <LoadingButton
                type="submit"
                className="button primary-button"
                isLoading={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </LoadingButton>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default SiteInspectionForm;
