import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const Datepick = ({
  required,
  selected,
  onChangeDate,
  disabled,
  customClass,
  minDate,
  maxDate,
}) => {
  const [startDate, setStartDate] = useState(new Date());

  const selectedDate = selected ? selected : startDate;
  const modifiedSelectedDate =
    minDate && selectedDate < minDate ? minDate : selectedDate;

  return (
    <React.Fragment>
      <DatePicker
        required={required}
        className={`${customClass} datepick`}
        selected={modifiedSelectedDate}
        disabled={disabled}
        onChange={(date) => {
          setStartDate(date);
          onChangeDate(date);
        }}
        minDate={new Date(minDate)}
        maxDate={new Date(maxDate)}
      />
    </React.Fragment>
  );
};

export default Datepick;
