import React from "react";
import { Col, Row } from "react-bootstrap";

const FormFieldWrapper = ({ label, required, children }) => {
  return (
    <Row className="align-items-center mb-6">
      <Col className="px-0" md={3}>
        <label className={`mb-0 ${required ? "required" : ""}`}>{label}</label>
      </Col>
      <Col md={4} className="px-0">
        {children}
      </Col>
    </Row>
  );
};

export default FormFieldWrapper;
