import * as yup from "yup";

export const basicInfoInitValues = {
  venue_name: "",
  venue_address: "",
  venue_images: [],
  venue_type: "",
  star_rating: 1,
  chain_name: "",
  build_year: "",
  total_sleeping_rooms: 0,
  meeting_space: 0,
  brand_name: "",
  renovation_year: "",
  largest_meeting_room: "",
  second_largest_meeting_room: "",
  total_meeting_rooms: "",
};

export const basicInfoValSchema = yup.object({
  venue_name: yup.string().required("Required"),
  venue_address: yup.string().required("Required"),
  venue_images: yup
    .array()
    .of(yup.object())
    .min(5, "Minimum 5 images Required"),
  venue_type: yup.string(),
  star_rating: yup
    .number()
    .min(1, "Rating can't be less than 1")
    .max(5, "Rating can't be greater than 5"),
  chain_name: yup.string(),
  build_year: yup.number().min(1, "Value must be greater than 1"),
  total_sleeping_rooms: yup.number(),
  meeting_space: yup.number(),
  brand_name: yup.string(),
  renovation_year: yup.number(),
});

export const overviewInitValues = {
  description: "",
  venue_amenities: [
    {
      amenity_type: "",
      amenity_list: [],
    },
  ],
};

export const guestRoomInitValues = {
  single_rooms: "",
  double_rooms: "",
  suite_rooms: "",
  guest_room_images: [],
};

export const meetingRoomDefaultValues = {
  image: "",
  room_name: "",
  width: "",
  height: "",
  length: "",
  banquet_rounds: "",
  board_room_conference: "",
  class_room: "",
  cocktail_rounds: "",
  crescent_rounds: "",
  e_shaped: "",
  maximum_capacity: "",
  hollow_square: "",
  perimeter_seating: "",
  royal_conference: "",
  talk_show: "",
  theater: "",
  t_shaped: "",
  u_shaped: "",
  description: "",
  total_space: "",
};

export const meetingVenueSpaceInitValues = {
  meeting_rooms: [meetingRoomDefaultValues],
  floor_plans: [],
};

export const meetingVenueSpaceValSchema = yup.object({
  meeting_rooms: yup.array().of(
    yup.object({
      room_name: yup.string().required("Required"),
      width: yup.number().required("Required"),
      height: yup.number().required("Required"),
      length: yup.number().required("Required"),
      description: yup.string(),
    })
  ),
  floor_plans: yup.array().of(yup.object()),
});

export const attractionDefaultObj = {
  attraction_images: [],
  name: "",
  description: "",
  distance: "",
  type: "",
};

export const localAttractionsInitValues = {
  venue_attractions: [attractionDefaultObj],
};

export const localAttractionsValSchema = yup.object({
  venue_attractions: yup.array().of(
    yup.object({
      attraction_image: yup.array().min(1, "Required"),
      name: yup.string().required("Required"),
      description: yup.string().required("Required"),
      distance: yup.string().required("Required"),
      type: yup.string().required("Required"),
    })
  ),
});

export const locationInitValues = {
  lat: "",
  long: "",
  parking: [],
  airports: [{ distance: 0 }],
};

export const locationValSchema = yup.object({
  lat: yup.number().required("Required"),
  long: yup.number().required("Required"),
  parking: yup.array().of(yup.object()).min(1, "Required"),
  airports: yup
    .array()
    .of(yup.object({ distance: yup.number().required("Required") })),
});
