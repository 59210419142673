function formatAmount(amount = 0) {
  try {
    const formattedAmount = amount.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    if (formattedAmount) return formattedAmount;
    return amount;
  } catch (error) {
    console.log(error);
    return "0.00";
  }
}

export default formatAmount;
