import { useState } from "react";

const MAIN = "MAIN";
const DETAIL = "DETAIL";

function useBudgetViewModeHandler() {
  const [currentMode, setCurrentMode] = useState(MAIN);

  const switchToMainMode = () => setCurrentMode(MAIN);
  const switchToDetailMode = () => setCurrentMode(DETAIL);

  return {
    isMainMode: currentMode === MAIN,
    isDetailMode: currentMode === DETAIL,
    switchToMainMode,
    switchToDetailMode,
  };
}

export default useBudgetViewModeHandler;
