import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { Row, Col } from "react-bootstrap";
import { useRFPFormContext } from "../context/RFPContext";
import DataModal from "./DataModal";
import DataCard from "./DataCard";

const FIELD_KEY = "rfpdestination";

const PrefferredDestinations = () => {
  const { values, setFieldValue } = useFormikContext();
  const {
    cart: {
      event: { destination_list: eventCartDestinations = [] },
    },
  } = useRFPFormContext();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const toggleAddModal = () => setIsAddModalOpen((prevState) => !prevState);

  useEffect(() => {
    const modifiedDestinations = eventCartDestinations.map((item) => ({
      placeID: parseInt(item.modelID, 10),
      image: item.descriptionFields.image,
      title: item.descriptionFields.title,
      is_custom: false,
    }));
    setFieldValue(FIELD_KEY, modifiedDestinations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventCartDestinations]);

  const handleAddNewVenue = (venueName) => {
    const addedVenue = {
      placeID: Math.random(),
      image: null,
      title: venueName,
      is_custom: true,
    };
    setFieldValue(FIELD_KEY, [...values[FIELD_KEY], addedVenue]);
  };

  const handleDelete = (selectedItemID) => {
    const filteredDestinations = [...values[FIELD_KEY]].filter(
      (item) => item.placeID !== selectedItemID
    );
    setFieldValue(FIELD_KEY, filteredDestinations);
  };

  return (
    <Row className="w-100 px-0">
      <DataModal
        title="Add Preferred Destination"
        isOpen={isAddModalOpen}
        toggle={toggleAddModal}
        onAdd={handleAddNewVenue}
      />
      <Col md={12} className="px-0">
        <DataCard
          title="Preferred Destinations"
          items={values[FIELD_KEY]}
          buttonTitle="Add Destination"
          onAddButtonClick={toggleAddModal}
          onDelete={handleDelete}
        />
      </Col>
    </Row>
  );
};

export default PrefferredDestinations;
