import React, { createContext, useContext, useEffect } from "react";
import useMessages from "../custom-hooks/useMessages";
import { transformSocketData } from "../../../../utils/helpers";
import { transformSingleMessage } from "../utils/helpers";
import useChatSocket from "../custom-hooks/useChatSocket";

export const ServiceRequestChatContext = createContext({
  isLoading: true,
  messages: [],
  addNewMessage: () => {},
  setLastMessageRef: () => {},
  containerRef: null,
});

export const useServiceRequestChatContext = () =>
  useContext(ServiceRequestChatContext);

const ChatProvider = ({ children }) => {
  const { addNewMessage, ...restMessagesOptions } = useMessages();
  const chatSocketOptions = useChatSocket();
  const { lastJsonMessage } = chatSocketOptions;

  useEffect(() => {
    if (lastJsonMessage) {
      const { data, isSuccess } = transformSocketData(lastJsonMessage);
      if (isSuccess) addNewMessage(transformSingleMessage(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  return (
    <ServiceRequestChatContext.Provider
      value={{ addNewMessage, ...restMessagesOptions, ...chatSocketOptions }}
    >
      {children}
    </ServiceRequestChatContext.Provider>
  );
};

export default ChatProvider;
