import React, { useMemo, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

// COMPONENTS & UTILITIES
import useQuery from "../../custom-hooks/useQuery";
import HotelCards from "./components/HotelCards";
import { VenueContext } from "./custom-hooks/useGetVenuesContext";
import PageWrapper from "../../components/common/PageWrapper";
import VenueFilters from "./components/VenueFilter";
import { getSearchParamObj } from "../../utils/helpers";
import "./index.scss";

function Venues() {
  const query = useQuery();
  const [show, setShow] = useState(false);
  const [isMapBtnDisable, setMapBtnDisable] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const searchParamsObj = useMemo(() => getSearchParamObj(query), [query]);

  const contextValue = useMemo(
    () => ({
      isMapBtnDisable,
      searchParams: query,
      searchParamsObj,
      showMap: show,
      handleCloseMap: handleClose,
      handleShowMap: handleShow,
    }),
    [isMapBtnDisable, query, show, searchParamsObj]
  );

  return (
    <VenueContext.Provider value={contextValue}>
      <PageWrapper
        bannerImgSrc="url(/assets/images/events/venue-sourcing-banner.jpg)"
        bannerTitle="Venue Sourcing"
      >
        <Container className="px-0 venue-sourcing-container">
          <Row>
            <Col xl={3} lg={4} md={4} className="left-pane">
              <Row className="mb-5">
                <div className="googleMapDiv">
                  <img src="/assets/pages/venue/map.png" alt="" />

                  <div className="mapBtnDiv">
                    <Button
                      className="mapBtn"
                      onClick={handleShow}
                      disabled={isMapBtnDisable}
                    >
                      View Map
                    </Button>
                  </div>
                </div>
              </Row>

              <VenueFilters />
            </Col>

            <Col xl={9} lg={8} md={8}>
              <HotelCards setMapButtonDisability={setMapBtnDisable} />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </VenueContext.Provider>
  );
}

export default Venues;
