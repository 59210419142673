import React from "react";
import { Row, Col } from "react-bootstrap";
import MedServeiceButtons from "../med_service_buttons";
import Input from "../../../components/customInputField";

const MedFood = (props) => {
  const {
    addServiceForms,
    deleteServiceForm,
    setDisabledServiceTypes,
    setStatus,
    register,
    errors,
  } = props;

  const disableFoodType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      foodDisabled: true,
    }));
  };

  const enableFoodType = () => {
    setDisabledServiceTypes((prevState) => ({
      ...prevState,
      foodDisabled: false,
    }));
  };

  return (
    <React.Fragment>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="sub_category">Sub-category:</label>
        </Col>
        <Col className="form_column_sel pl-0" md={6}>
          <select name="food_and_beverages.sub_category" ref={register}>
            <option>Breakfast</option>
            <option>Lunch</option>
            <option>Dinner</option>
            <option>Gala Dinner</option>
          </select>
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="num_attendees">Number of Attendees:</label>
        </Col>
        <Col className="px-0" md={6}>
          <Input
            name="food_and_beverages.num_attendees"
            inputRef={register({ required: true })}
            error={
              errors?.food_and_beverages?.num_attendees &&
              "This field is required!"
            }
          />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="preferred_time">Preffered Time:</label>
        </Col>
        <Col className="px-0" md={6}>
          <Input
            name="food_and_beverages.preferred_time"
            inputRef={register({ required: true })}
            error={
              errors?.food_and_beverages?.preferred_time &&
              "This field is required!"
            }
          />
        </Col>
      </Row>
      <Row className="form_main">
        <Col className="px-0" md={3}>
          <label htmlFor="preferred_restaurant">
            Preferred Restaurant/Venue (if any):
          </label>
        </Col>
        <Col className="px-0" md={6}>
          <Input
            name="food_and_beverages.preferred_restaurant"
            inputRef={register({ required: true })}
            error={
              errors?.food_and_beverages?.preferred_restaurant &&
              "This field is required!"
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="px-0">
          <div className="gen_inquiry_text">
            <p className="text-lg-150 text-125 mb-2">
              Requirements
            </p>
          </div>
          <Input
            textArea
            name="food_and_beverages.requirements"
            inputRef={register({ required: true })}
            error={
              errors?.food_and_beverages?.requirements &&
              "This field is required!"
            }
          />
        </Col>
      </Row>
      <MedServeiceButtons
        addServiceForms={addServiceForms}
        deleteServiceForm={deleteServiceForm}
        disableSelectedType={disableFoodType}
        enableSelectedType={enableFoodType}
        setStatus={setStatus}
      />
    </React.Fragment>
  );
};

export default MedFood;
