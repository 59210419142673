import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getVenueOverviewDetailsQuery } from "../../../services/venue-details";
import { useGetVenueDetailContext } from "../custom-hooks/useGetVenueDetailContext";
import SectionLoaderWrapper from "../../../components/common/SectionLoaderWrapper";
import useFetchRequest from "../../../components/common/custom-hooks/useFetchRequest";

function OverviewTabPanel() {
  const { venueId } = useGetVenueDetailContext();
  const { data: overviewData, isLoading } = useFetchRequest(
    getVenueOverviewDetailsQuery,
    venueId
  );

  return (
    <SectionLoaderWrapper isLoading={isLoading}>
      <Container className="overview-container">
        <Row className="mb-4">
          <p>{`${overviewData?.venue_description ?? 'NA'}`}</p>
        </Row>

        <div>
          <h6 className="heading">Amenities</h6>

          <Row noGutters>
            {overviewData?.venue_amenities?.map((item) => (
              <Col key={item.id} md={4}>
                <p className="title">{item.amenity_type}</p>

                <ul className="list-items">
                  {item?.amenity_list?.map((amenity) => (
                    <li key={amenity}>{amenity}</li>
                  ))}
                </ul>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </SectionLoaderWrapper>
  );
}

export default OverviewTabPanel;
