/* eslint-disable no-param-reassign */
import React, { useLayoutEffect, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";
import { useFileHandlerContext } from "../context/FileHandlerContext";
import {
  SYNCFUSION_SPREADSHEET_OPEN_FILE_URL,
  SYNCFUSION_SPREADSHEET_SAVE_FILE_URL,
} from "../utils/constants";
import { removeSyncfusionLicenseAlert } from "../utils/helpers";

const SpreadsheetHandler = ({
  beforSave = null,
  onLoad = () => {},
  onSaveComplete = () => {},
  height = 600,
  ...restProps
}) => {
  const { spreadSheetRef, saveSpreadSheetRef } = useFileHandlerContext();
  const [isSheetCreated, setIsSheetCreated] = useState(false);

  useLayoutEffect(() => {
    removeSyncfusionLicenseAlert();
  }, []);

  useEffect(() => {
    if (spreadSheetRef.current && isSheetCreated) {
      onLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSheetCreated]);

  const handleBeforeSave = (args) => {
    if (beforSave) beforSave(args);
    else {
      args.needBlobData = true;
      args.isFullPost = false;
    }
  };

  const handleSheetCreated = () => {
    setIsSheetCreated(true);
  };

  return (
    <SpreadsheetComponent
      {...restProps}
      ref={saveSpreadSheetRef}
      created={handleSheetCreated}
      beforeSave={handleBeforeSave}
      saveComplete={onSaveComplete}
      openUrl={SYNCFUSION_SPREADSHEET_OPEN_FILE_URL}
      saveUrl={SYNCFUSION_SPREADSHEET_SAVE_FILE_URL}
      height={height}
    />
  );
};

SpreadsheetHandler.propTypes = {
  id: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  onSaveComplete: PropTypes.func,
  beforSave: PropTypes.func,
};

SpreadsheetHandler.defaultProps = {
  onLoad: () => {},
  onSaveComplete: () => {},
  beforSave: null,
};

export default SpreadsheetHandler;
