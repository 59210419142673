import React, { useState } from "react";
import { Modal, Image } from "react-bootstrap";
import { BsFillCaretRightFill, BsFillCaretLeftFill } from "react-icons/bs";
import Slider from "react-slick";
import "./index.scss";

function SampleNextArrow(props) {
  const { currentSlide, slideCount, onClick } = props;
  return (
    <div className="icons iconRight" onClick={onClick}>
      <BsFillCaretRightFill
        className={
          currentSlide + 1 === slideCount ? "slider-disabled-button" : ""
        }
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { currentSlide, onClick } = props;
  return (
    <div className="icons iconLeft" onClick={onClick}>
      <BsFillCaretLeftFill
        className={currentSlide === 0 ? "slider-disabled-button" : ""}
      />
    </div>
  );
}

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

function SliderModal({ sliderImages, sliderClasses, imageClasses, hide }) {
  const [show, setShow] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = (index) => {
    setClickedImageIndex(index);
    setShow(true);
  };

  const modalSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: clickedImageIndex,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        keyboard={true}
        className="venue_loc_modal_gallery central_modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="sliderModal">
            <Slider
              {...modalSliderSettings}
              ref={(slider) => {
                if (slider) {
                  slider.slickGoTo(clickedImageIndex);
                }
              }}
            >
              {sliderImages?.map((item) => (
                <div className="slick-slide" key={item.image}>
                  <Image
                    className="slick-slide-image modal_slider_img w-100"
                    src={item.smaller_image || item.image}
                    fluid
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Modal.Body>
      </Modal>
      {!hide && (
        <Slider {...settings} className={sliderClasses}>
          {sliderImages?.map((item, index) => (
            <Image
              key={item.image}
              src={item.smaller_image || item.image}
              className={"w-100 " + imageClasses}
              onDoubleClick={() => handleShow(index)}
            />
          ))}
        </Slider>
      )}
    </>
  );
}

export default SliderModal;
