import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Tab, Tabs } from "@material-ui/core";

// COMPONENTS & ACTIONS & STYLES
import Header from "../../components/header";
import Footer from "../../components/footer";
import VenueBasicInfo from "./components/VenueBasicInfo";
import { VenueDetailContext } from "./custom-hooks/useGetVenueDetailContext";
import TabPanel from "../../components/common/TabPanel";
import OverviewTabPanel from "./components/OverviewTabPanel";
import GuestRoomsTabPanel from "./components/GuestRoomsTabPanel";
import VenueSpaceTabPanel from "./components/VenueSpaceTabPanel";
import AttractionsTabPanel from "./components/AttractionsTabPanel";
import LocationTabPanel from "./components/LocationTabPanel";
import "./index.scss";

const VenueDetail = () => {
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = (e, newValue) => {
    setCurrentTab(newValue);
  };

  const contextValue = useMemo(
    () => ({
      venueId: id,
    }),
    [id]
  );

  return (
    <VenueDetailContext.Provider value={contextValue}>
      <Header />

      <VenueBasicInfo />

      <Container>
        <Tabs
          className="venue-tabs"
          value={currentTab}
          onChange={handleChangeTab}
          TabIndicatorProps={{ hidden: true }}
          centered
        >
          <Tab className="venue-tab" label="Overview" />

          <Tab className="venue-tab" label="Guest Rooms" />

          <Tab className="venue-tab" label="Meeting Venues Space" />

          <Tab className="venue-tab" label="Local Attractions" />

          <Tab className="venue-tab" label="Location" />
        </Tabs>
      </Container>
      <div className="tab-panel-wrapper">
        <TabPanel tabValue={currentTab} index={0}>
          <OverviewTabPanel />
        </TabPanel>

        <TabPanel tabValue={currentTab} index={1}>
          <GuestRoomsTabPanel />
        </TabPanel>

        <TabPanel tabValue={currentTab} index={2}>
          <VenueSpaceTabPanel />
        </TabPanel>

        <TabPanel tabValue={currentTab} index={3}>
          <AttractionsTabPanel />
        </TabPanel>

        <TabPanel tabValue={currentTab} index={4}>
          <LocationTabPanel />
        </TabPanel>
      </div>

      <Footer />
    </VenueDetailContext.Provider>
  );
};

export default VenueDetail;
