/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import propTypes from "prop-types";
import { useDebouncedCallback } from "use-debounce";
import { useGetVenuesContext } from "../custom-hooks/useGetVenuesContext";

function SearchField({ paramKey, placeholder, type }) {
  const [innerValue, setInnerValue] = useState("");
  const { pathname } = useLocation();
  const history = useHistory();
  const { searchParams } = useGetVenuesContext();
  const paramsValue = searchParams.get(paramKey);

  useEffect(() => {
    if (paramsValue) {
      setInnerValue(paramsValue);
    } else {
      setInnerValue("");
    }
  }, [paramsValue]);

  const handleDebounce = useDebouncedCallback((value) => {
    searchParams.delete("page");
    searchParams.set(paramKey, value);

    history.push({
      pathname,
      search: searchParams.toString(),
    });
  }, 500);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;

      handleDebounce(value);
      setInnerValue(value);
    },
    [innerValue]
  );

  return (
    <div className="w-100">
      <input
        className="form-control filter-search"
        placeholder={placeholder}
        value={innerValue}
        onChange={handleChange}
        type={type}
      />
    </div>
  );
}

SearchField.propTypes = {
  paramKey: propTypes.string.isRequired,
  placeholder: propTypes.string,
  type: propTypes.string,
};

SearchField.defaultProps = {
  type: "text",
  placeholder: "Search...",
};

export default memo(SearchField);
