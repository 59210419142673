/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useField } from "formik";
import propTypes from "prop-types";
import { v4 } from "uuid";

// STYLES & COMPONENTS & SERVICES
import "./index.scss";
import ImageItem from "./components/ImageItem";

function FormikFileGalleryField({ name, onChange, multiple, disable }) {
  const inputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [field, meta, helpers] = useField(name);

  const { value, onBlur } = field;
  const { error, touched } = meta;
  const { setValue, setTouched } = helpers;

  useEffect(() => {
    if (value?.length > 0) {
      setFiles(value);
    }
  }, [value]);

  const handleClick = useCallback(() => {
    if (inputRef) {
      inputRef.current.click();
    }
  }, []);

  const handleChange = useCallback(
    async (e) => {
      const filesArray = [...e.target.files];

      if (filesArray.length > 0) {
        const filesData = filesArray.map((file) => {
          const fileMeta = { src: URL.createObjectURL(file), uid: v4() };
          return Object.assign(file, fileMeta);
        });

        console.log("filesData ==>", filesData);
        const payload = [...files, ...filesData];
        console.log("FILES payload ==>", payload);

        setFiles(payload);
        setValue(payload);
      }

      setTouched(true);

      if (onChange) onChange(filesArray, name);
    },
    [value, files]
  );

  const handleDelete = useCallback(
    async (fileObj) => {
      if (!fileObj?.name) return;

      const newFiles = files.filter((file) => file?.uid !== fileObj?.uid);

      setFiles(newFiles);
      setValue(newFiles);
    },
    [files]
  );

  return (
    <div className="formik-gallery-container">
      {files.length === 0 && (
        <div
          className={`formik-box-wrapper ${disable ? "disabled" : ""} ${
            !multiple ? "small" : ""
          }`}
          onClick={!disable ? handleClick : undefined}
        >
          <p className="text-center">
            Please upload image upto 15MB. Also, Image file type should be JPG,
            JPEG or PNG.
          </p>

          <button type="button" className="btn-main-primary">
            Choose Image
          </button>
        </div>
      )}

      {files?.length > 0 && (
        <div className={`files-container ${!multiple ? "small" : ""}`}>
          <div className="files-wrapper">
            {files.map((file, index) => (
              <ImageItem
                file={file}
                index={index}
                key={index}
                isLoading={file?.isLoading}
                handleDelete={handleDelete}
              />
            ))}
          </div>

          {multiple && (
            <button
              type="button"
              className="btn-main-primary"
              onClick={handleClick}
            >
              Add Image
            </button>
          )}
        </div>
      )}

      <input
        ref={inputRef}
        type="file"
        name={name}
        hidden
        multiple={multiple}
        onChange={handleChange}
        onBlur={onBlur}
      />

      {touched && error && <div className="formik-error">{error}</div>}
    </div>
  );
}

FormikFileGalleryField.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func,
  multiple: propTypes.bool,
  disable: propTypes.bool,
};

FormikFileGalleryField.defaultProps = {
  onChange: () => {},
  multiple: true,
  disable: false,
};

export default memo(FormikFileGalleryField);
