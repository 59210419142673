import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import { useEventCoordinationContext } from "../context/EventCoordinationContext";
import useToggle from "../../../../components/common/custom-hooks/useToggle";
import Modal from "../../../../components/common/CustomModal";
import SimpleTable from "../../../../shared/components/table/SimpleTable";
import SpinningCircles from "../../../../shared/svg-icons/SpinningCircles";
import ConfirmationPopup from "../../../../components/common/popups/ConfirmationPopup";
import RFPActionController from "../../common/RFPActionController";
import EventRoleForm from "./components/EventRoleForm";
import { deleteRFPEventRole } from "../../../../server/Filter";
import formatString from "../../../../utils/formatString";
import { EVENT_ROLES_STATE_KEY } from "../utils/constants";
import { tableHeadings } from "./utils/headings";
import getFormatedDate from "../../../../utils/getFormatedDate";

const EventRoles = () => {
  const alert = useAlert();
  const { isEventAdmin, isEventOwner } = useSelector(
    (state) => state.productLaunch.fetchedRFPDetails
  );
  const {
    data: { [EVENT_ROLES_STATE_KEY]: rfpEventRoles },
    modifiers: { updateEventRoles },
  } = useEventCoordinationContext();
  const { isOpen: isDeleteConfirmationOpen, toggle: toggleDeleteConfirmation } =
    useToggle();
  const { isOpen: isFormModalOpen, toggle: toggleForm } = useToggle();
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const toggleFormModal = (selected) => {
    setSelectedRecord(selected);
    toggleForm();
  };

  const toggleDeleteConfirmationModal = (selected) => {
    setSelectedRecord(selected);
    toggleDeleteConfirmation();
  };

  const handleDeleteRFPRole = async () => {
    toggleDeleteConfirmation();
    setIsPerformingAction(true);

    try {
      await deleteRFPEventRole(selectedRecord.id);
      updateEventRoles();
      setIsPerformingAction(false);
      setSelectedRecord(null);
      alert.success("Role deleted successfully");
    } catch (error) {
      setIsPerformingAction(false);
      setSelectedRecord(null);
      alert.error("Something went wrong in deleting role");
    }
  };

  return (
    <div>
      <ConfirmationPopup
        isOpen={isDeleteConfirmationOpen}
        toggle={toggleDeleteConfirmationModal}
        message="Are you sure you want to delete this role?"
        toggleOnConfirm={false}
        onConfirm={handleDeleteRFPRole}
      />
      <Modal
        isOpen={isFormModalOpen}
        toggle={() => toggleFormModal(null)}
        heading="RFP Role Form"
        bodyClassName="p-5"
      >
        <EventRoleForm
          toggle={() => toggleFormModal(null)}
          selected={selectedRecord}
        />
      </Modal>
      <div className="mb-6">
        <RFPActionController disAllow={!(isEventAdmin || isEventOwner)}>
          <button
            className="transparent-button rfp-action-button"
            onClick={() => toggleFormModal(null)}
          >
            Assign Role
          </button>
        </RFPActionController>
      </div>
      <SimpleTable
        headings={tableHeadings}
        data={rfpEventRoles}
        alternateText="There are no roles"
        rounded
        renderCustomRow={(item) => (
          <tr key={item.id}>
            <td>
              <div className="d-flex justify-content-between">
                {isPerformingAction &&
                  selectedRecord &&
                  selectedRecord === item.id && <SpinningCircles />}
                <span>{item.assigned_to}</span>
                <span>
                  <RFPActionController disAllow={!isEventAdmin}>
                    <NavDropdown className="custom-dropdown" title="">
                      <NavDropdown.Item onClick={() => toggleFormModal(item)}>
                        Edit Role
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => toggleDeleteConfirmationModal(item)}
                      >
                        Delete Role
                      </NavDropdown.Item>
                    </NavDropdown>
                  </RFPActionController>
                </span>
              </div>
            </td>
            <td align="center">{formatString(item.event_role)}</td>
            <td align="center">{item.assigned_by}</td>
            <td align="center">{getFormatedDate(item.created_at)}</td>
          </tr>
        )}
      />
    </div>
  );
};

export default EventRoles;
